import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import * as claimActionTypes from '../../../redux/actionTypes/claimActionTypes';
import RadioButtonGroupFieldMain from '../../Custom/RadioButtonGroupFieldMain';
import InputFieldMain from '../../Custom/InputFieldMain';
import DatePickerFieldMain from '../../Custom/DatePickerFieldMain';
import SelectField from '../../Custom/SelectField';
import { Gender } from '../../../utils/nomen';
import { useFormikContext } from 'formik';
import { AppState } from "../../../redux/reducers/rootReducer";
import { Nomen } from '../../../utils';
import { Loading } from '../../Layout/Loading';
import { PreClaimPerson } from '../../../utils/claimTypes';

const DamagedPerson = () => {
    const { values }: any = useFormikContext();
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const isPhysical = values.tipKind === '3';
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[claimActionTypes.FETCH_KASKO_DATA_FROM_POLICY]);
    const person = useSelector<AppState, PreClaimPerson | null>((state: any) => state.claim.kasko?.damagedPerson);

    return (<>
        {isLoading
            ? <Loading />
            :
            <>
                <div className="wizardTitle">I Dëmtuari / Marrësi i Shërbimit</div>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div className="row">
                        <div className="col-xl-6">
                            <RadioButtonGroupFieldMain name="tipKind" type="radio" placeholder="Tip Kind" label="Tip" items={[{ id: '3', value: 'Fizik' }, { id: '2', value: 'Juridik' }]} newvalue={values?.tipkind || person?.tipkind} />
                        </div>
                        {!isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="nipt" label="Nipti" placeholder="Nipti" newvalue={values?.nipt || person?.nipt} />
                        </div>}
                        {isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="clientNo" label="Numri personal" placeholder="Numri personal" maxLength={16} newvalue={values?.clientno || person?.clientno} />
                        </div>}
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="title" label="Emri" placeholder="Emri" newvalue={values?.title || person?.title} capitalized />
                        </div>
                        {isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="lastName" label="Mbiemri" placeholder="Mbiemri" newvalue={values?.lastname || person?.lastname} capitalized />
                        </div>}

                        {!isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" newvalue={values?.address || person?.address} />
                        </div>}
                    </div>
                    <div className="row">
                        {isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" newvalue={values?.address || person?.address} />
                        </div>}
                        {!isPhysical &&
                            <div className="col-xl-6">
                                <InputFieldMain type="text" name="celphone" label="Nr. telefonit" placeholder="Nr. telefonit" newvalue={values?.celphone || person?.celphone} />
                            </div>}
                        <div className="col-xl-6">
                            <SelectField name="place" label="Vendi" items={places} newvalue={values?.place || (person?.place?.code ? person?.place?.code : '')} />
                        </div>
                    </div>
                    {isPhysical && <div className="row">
                        <div className="col-xl-6">
                            <SelectField name="sex" label="Gjinia" items={Gender} newvalue={values?.sex || person?.sex?.code} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="celphone" label="Nr. telefonit" placeholder="Nr. telefonit" newvalue={values?.celphone || person?.celphone} />
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="email" label="Email" placeholder="Email" newvalue={values?.email || person?.email} />
                        </div>
                        <div className="col-xl-6">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 addSpace">
                            <DatePickerFieldMain name="driverLicenseIssued" type="text" placeholder="Data e lëshimit të Patentë shoferit" newvalue={values?.driverLicenseIssued || (person?.driverLicenseIssued ? new Date(person?.driverLicenseIssued) : '')} />
                        </div>
                        {isPhysical && <div className="col-xl-6 addSpace">
                            <DatePickerFieldMain name="birthdate" type="text" placeholder="Datëlindja" newvalue={values?.birthdate || (person?.birthdate ? new Date(person?.birthdate) : '')} />
                        </div>}
                    </div>
                    <div className="row addSpace">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="drivingLicenceNo" label="Nr. i patentës" placeholder="Nr. i patentës" newvalue={values?.drivingLicenceNo || person?.drivingLicenceNo} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="drivingLicenceCat" label="Kategoria e patentë shoferit" placeholder="Kategoria e patentë shoferit" newvalue={values?.drivingLicenceCat || person?.drivingLicenceCat} />
                        </div>
                    </div>
                </div>
            </>}
    </>
    );
}
export default connect()(DamagedPerson);
