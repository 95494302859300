import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import * as healthPreClaimActionTypes from '../../../../redux/actionTypes/healthPreClaimActionTypes';
import * as healthPreClaimActions from '../../../../redux/actionCreators/healthPreClaimActionCreators';
import * as nomenActionTypes from '../../../../redux/actionTypes/nomenActionTypes';
import RadioButtonGroupFieldMain from '../../../Custom/RadioButtonGroupFieldMain';
import InputFieldMain from '../../../Custom/InputFieldMain';
import DatePickerFieldMain from '../../../Custom/DatePickerFieldMain';
import SelectField from '../../../Custom/SelectField';
import { Gender } from '../../../../utils/nomen';
import { AppState } from '../../../../redux/reducers/rootReducer';
import { Nomen } from '../../../../utils';
import { Loading } from '../../../Layout/Loading';
import { PreClaimPerson } from '../../../../utils/claimTypes';
import { useFormikWizard } from 'formik-wizard';
import store from '../../../../redux/configureStore';

const BeneficiaryPerson = () => {
    const { values, setValues }: any = useFormikContext();
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[healthPreClaimActionTypes.VALIDATE_HEALTH_CLAIM_POLICY]
        || state.isLoading[healthPreClaimActionTypes.VALIDATE_HEALTH_CLAIM_INSURED]
        || state.isLoading[nomenActionTypes.GET_COVER_CATEGORIES_FOR_POLICY]);

    const isPhysical = values.tipKind === '3';
    const banks = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Banks);
    const beneficiaryPerson = useSelector<AppState, PreClaimPerson | null>((state: any) => state.healthPreClaim.healthPreClaimPerson);

    const clearForm = () => {
        store.dispatch(healthPreClaimActions.validateHealthClaimInsuredReset());
        setValues(blankBeneficiary);
    }

    const blankBeneficiary = {
        tipKind: '3',
        nipt: '',
        clientNo: '',
        title: '',
        lastName: '',
        birthDate: '',
        email: '',
        celphone: '',
        address: '',
        place: '',
        sex: '',
        bankAccount: '',
        bank: ''
    }

    return (<>
        {isLoading
            ? <Loading />
            :
            <>
                <div className="wizardTitle">Përfituesi</div>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div className="row">
                        <div className="col-xl-9"></div>
                        <div className="col-xl-3">
                            <a>
                                <i className="flaticon2-rubbish-bin-delete-button text-muted icon-1x pull-right"
                                    onClick={clearForm} title="Hiq">
                                </i>
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <RadioButtonGroupFieldMain name="tipKind" type="radio" placeholder="Tip Kind" label="Tip" items={[{ id: '3', value: 'Fizik' }, { id: '2', value: 'Juridik' }]} newvalue={values?.tipkind || beneficiaryPerson?.tipkind} />
                        </div>
                        {!isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="nipt" label="Nipti" placeholder="Nipti" newvalue={values?.nipt || beneficiaryPerson?.nipt} />
                        </div>}
                        {isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="clientNo" label="Numri personal" placeholder="Numri personal" maxLength={16} newvalue={values?.clientno || beneficiaryPerson?.clientno} />
                        </div>}
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="title" label="Emri" placeholder="Emri" newvalue={values?.title || beneficiaryPerson?.title} capitalized />
                        </div>
                        {isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="lastName" label="Mbiemri" placeholder="Mbiemri" newvalue={values?.lastname || beneficiaryPerson?.lastname} capitalized />
                        </div>}

                        {!isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" newvalue={values?.address || beneficiaryPerson?.address} />
                        </div>}

                    </div>

                    <div className="row">
                        {isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" newvalue={values?.address || beneficiaryPerson?.address} />
                        </div>}
                        {!isPhysical &&
                            <div className="col-xl-6">
                                <InputFieldMain type="text" name="celphone" label="Nr. telefonit" placeholder="Nr. telefonit" newvalue={values?.celphone || beneficiaryPerson?.celphone} />
                            </div>}
                        <div className="col-xl-6">
                            <SelectField name="place" label="Vendi" items={places} newvalue={values?.place || (beneficiaryPerson?.place?.code ? beneficiaryPerson?.place?.code : '')} />
                        </div>
                    </div>
                    {isPhysical && <div className="row">
                        <div className="col-xl-6">
                            <SelectField name="sex" label="Gjinia" items={Gender} newvalue={values?.sex || beneficiaryPerson?.sex?.code} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="celphone" label="Nr. telefonit" placeholder="Nr. telefonit" newvalue={values?.celphone || beneficiaryPerson?.celphone} />
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="email" label="Email" placeholder="Email" newvalue={values?.email || beneficiaryPerson?.email} />
                        </div>
                        {isPhysical && <div className="col-xl-6 addSpace">
                            <DatePickerFieldMain name="birthDate" type="text" placeholder="Datëlindja" newvalue={values?.birthdate || (beneficiaryPerson?.birthdate ? new Date(beneficiaryPerson?.birthdate) : '')} />
                        </div>}
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <SelectField name="bank" label="Banka" items={banks} newvalue={values?.bank || beneficiaryPerson?.bank?.code} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="bankAccount" label="Llogari bankare" placeholder="Llogari bankare" newvalue={values?.bankAccount || beneficiaryPerson?.bankAccount} />
                        </div>
                        <div className="col-xl-6 hidden">
                            <InputFieldMain type="text" name="policyCode" label="Numri i policës" placeholder="Numri i policës" newvalue={values?.policyCode || beneficiaryPerson?.policyCode} />
                        </div>
                    </div>
                </div>
            </>}
    </>
    );
}
export default connect()(BeneficiaryPerson);
