import React from 'react';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import FormikWizard from 'formik-wizard';
import { HideModalAction } from '../../../redux/actionTypes/generalActionTypes';
import steps from './steps';
import { FormikWizardWrapperProps } from '../../../utils/wizardTypes';
import SimpleModal from '../../General/SimpleModal';
import Nav from './Nav';
import { hideModal } from '../../../redux/actionCreators/generalActionCreators';
import { RouteComponentProps } from '../../Layout/Content';
import { AppState } from '../../../redux/reducers/rootReducer';
import { prepareAifPolicy, ThInsQuotation, AifPolicy, prepareAifPolicyForQuotation } from '../../../utils/policyTypes';
import * as thActions from "../../../redux/actionCreators/thPolicyActionCreators";
import * as aifActions from "../../../redux/actionCreators/aifPolicyActionCreators";
import store from '../../../redux/configureStore';
import * as nomenActions from '../../../redux/actionCreators/nomenActionCreators';
import { BookAIFPolicyAction, GetAIFQuotationAction } from '../../../redux/actionTypes/aifPolicyActionTypes';
import { User } from '../../../redux/types';
import LoginModal from '../../Auth/LoginModal';
import RegistrationModal from '../../Auth/Registration/RegistrationModal';
import VerificationCodeModal from '../../Auth/Registration/VerificationCodeModal';
import * as generalActions from "../../../redux/actionCreators/generalActionCreators";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ChooseQuotationAction, ChooseQuotationResetAction } from '../../../redux/actionTypes/thPolicyActionTypes';
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import CreateAccountQuestionModal from '../../Auth/CreateAccountQuestionModal';

interface Props {
    closeModal(): void;
    onBookAIFPolicy(aifPolicy: AifPolicy): void;
    showModal: boolean;
    header: string;
    description: string;
    nextStep: string;
    history: History;
    user: User;
    chosenAIFQuotation: any;
    onGetAIFQuotation(customerId: string): void;
    quotationFetched: boolean;
}

interface PathProps {
    history: any;
}

function FormWrapper({
    children,
    isLastStep,
    goToPreviousStep,
    canGoBack,
    actionLabel,
    currentStep,
    values,
    wizard
}: FormikWizardWrapperProps<any, any>) {
    const dispatch = useDispatch();
    const aifQuotationErrored = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.aifQuotationErrored);
    const policyQuotationChosen = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.policyQuotationChosen);
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const showLoginModal = useSelector<AppState, boolean | null>((state: any) => state.general.showLoginModal);
    const showRegistrationModal = useSelector<AppState, boolean | null>((state: any) => state.general.showRegistrationModal);
    const showVerificationModal = useSelector<AppState, boolean | null>((state: any) => state.general.showVerificationModal);
    const continueWithoutAccount = useSelector<AppState, boolean | null>((state: any) => state.general.continueWithoutAccount);
    const showCreateAccountQuestionModal = useSelector<AppState, boolean | null>((state: any) => state.general.showCreateAccountQuestionModal);

    const chosenAIFQuotation = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.aifQuotation);
    const aifPackageQuotation = chosenAIFQuotation ? chosenAIFQuotation[0] : null;

    const hideNextBtn = currentStep === steps[0].id;

    const showSuccessToast = useSelector<AppState, boolean>((state: any) => state.general.showSuccessToast);
    const successToastText = useSelector<AppState, boolean>((state: any) => state.general.successToastText);

    var stepSkipped = false;

    const pixelScript = `
        !function(f,b,e,v,n,t,s){
            if(f.fbq)return;n=f.fbq=function(){
                n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)
            };
            if(!f._fbq) f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)
        }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');

        fbq('init', '2016267805471451');
        fbq('track', 'PageView');    `;

    React.useEffect(() => {
        const script = document.createElement("script");
        script.innerHTML = pixelScript;
        document.body.appendChild(script);
        
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    if (!!showSuccessToast) {
        toast.success(successToastText);
        dispatch({ type: "generalActionTypes/SHOW_SUCCESS_TOAST_RESET" });
    }

    if (currentStep === steps[1].id && aifQuotationErrored) {
        goToPreviousStep();
    }

    if (currentStep === steps[1].id && continueWithoutAccount) {
        wizard.next();
    }

    if (currentStep === steps[2].id && !user && !continueWithoutAccount) {
        goToPreviousStep();
        stepSkipped = true;
    }

    if (currentStep === steps[2].id && values.insuredPerson.passportPhoto.length === 0 && !stepSkipped) {
        store.dispatch(generalActions.showModal("Dështimi", "Duhet të ngarkoni foto të pasaportës!", ""));
        goToPreviousStep();
    }

    if (hideNextBtn && policyQuotationChosen) {
        store.dispatch(thActions.chooseQuotationReset());
        store.dispatch(nomenActions.getPlaces());

        wizard.next();
    }

    const handleCloseLoginModal = () => {
        store.dispatch(generalActions.showLoginModalReset());
    };

    const handleCloseRegistrationModal = () => {
        store.dispatch(generalActions.showRegistrationModalReset());
    };

    const handleCloseVerificationModal = () => {
        store.dispatch(generalActions.showVerificationModalReset());
    };

    const handleCloseCreateAccountQuestionModal = () => {
        store.dispatch(generalActions.showCreateAccountQuestionModalReset());
    };

    const goBack = () => {
        if (isLastStep) {
            store.dispatch(generalActions.continueWithoutAccountReset());
        }

        goToPreviousStep();
    }

    const useStyles = makeStyles(theme => ({
        customTooltip: {
            backgroundColor: '#3699FF',
            fontSize: '10px',
            opacity: '0.6 !important'
        }
    }));

    const classes = useStyles();

    return (
        <>
            <LoginModal show={showLoginModal} onClose={handleCloseLoginModal} onHide={() => { return; }} />
            <RegistrationModal show={showRegistrationModal} onClose={handleCloseRegistrationModal} onHide={() => { return; }} />
            <VerificationCodeModal show={showVerificationModal} onClose={handleCloseVerificationModal} onHide={() => { return; }} />
            <CreateAccountQuestionModal show={showCreateAccountQuestionModal} onClose={handleCloseCreateAccountQuestionModal} onHide={() => { return; }} />

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                theme="colored"
            />

            <div className="wizard wizard-4" id="kt_wizard_v4" data-wizard-state="step-first" data-wizard-clickable="true">
                <Nav currentStep={currentStep} />
                <div className="card card-custom card-shadowless rounded-top-0">
                    <div className="card-body p-0">
                        <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                            <div className="col-xl-12 col-xxl-7">
                                {children}
                                <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                    <div className="mr-2">
                                        {canGoBack && <button type="button" onClick={goBack} className="btn-wiz-back font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-prev">
                                            <i className="arrow left"></i>Kthehu
                                        </button>
                                        }
                                    </div>

                                    {!hideNextBtn &&
                                        <div>
                                            {isLastStep && <button type="submit" className="btn btn-wiz font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-submit">
                                                {actionLabel || 'Submit'}
                                            </button>}
                                            {!isLastStep && <button type="submit" className="btn btn-wiz font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-next">
                                                {actionLabel || 'Next'}
                                            </button>}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {aifPackageQuotation && <div className="col-xl-12 packageCard">
                <div className="card offcanvas offcanvas-right offcanvas-on card-c" >
                    <div className="card-body card-b-aside">
                        <h5 className="card-title">{aifPackageQuotation.packageName}</h5>
                        <p className="card-text pSum"><small>Shuma e siguruar: {aifPackageQuotation.insuredAmountV.toLocaleString()}€</small></p>
                        {aifPackageQuotation.covers.map((cover: any, indexC: number) => (
                            <p key={indexC + 1} className="card-text">
                                <span className='pCheck'>✔ </span>
                                <span dangerouslySetInnerHTML={{ __html: cover.replaceAll("#", "<br />") }}></span>
                            </p>
                        ))}
                    </div>

                    <div className="card-footer">
                        {aifPackageQuotation.packageDiscount > 0 &&
                            <p className='pDiscount'>
                                {aifPackageQuotation.totalVNoDiscount.toFixed(2)}{aifPackageQuotation.currencyName ? aifPackageQuotation.currencyName : '€'}

                                <Tooltip
                                    arrow
                                    title={"Aplikohet zbritja prej " + (aifPackageQuotation.packageDiscount) + "% ne kete police"}
                                    placement="top"
                                    classes={{
                                        tooltip: classes.customTooltip
                                    }}>
                                    <IconButton className="blue">
                                        <Info />
                                    </IconButton>
                                </Tooltip>
                            </p>}
                        <p className='pTotal'>
                            {parseFloat(aifPackageQuotation.totalV).toFixed(2)}  {aifPackageQuotation.currencyName ? aifPackageQuotation.currencyName : '€'}
                        </p>
                    </div>
                </div>
            </div>}
        <noscript>
                <img height="1" width="1" style={{ display: 'none' }} src="https://www.facebook.com/tr?id=2016267805471451&ev=PageView&noscript=1" />
        </noscript>
        </>
    );
}

class ForeignerPolicy extends React.Component<Props & RouteComponentProps<PathProps>, {}> {
    componentDidMount() {
        if (!this.props.quotationFetched) {
            const customerId = this.props.user?.customerId || "";
            this.props.onGetAIFQuotation(customerId);
        }
    };

    handleSubmit = (values: any) => {
        const customerId = this.props.user ? this.props.user.customerId : '';
        const packageId = this.props.chosenAIFQuotation && this.props.chosenAIFQuotation?.length > 0 ? this.props.chosenAIFQuotation[0].packageId : "";
        const policy = prepareAifPolicy(customerId, values, packageId);
        this.props.onBookAIFPolicy(policy);
    };

    handleCloseModal = () => {
        if (this.props.nextStep === "3") this.props.history.push('/');
        this.props.closeModal();
    };

    render() {
        return (
            <div className="d-flex flex-column-fluid">
                <SimpleModal show={this.props.showModal} header={this.props.header} text={this.props.description} onClose={this.handleCloseModal} onHide={() => { return; }} />
                <div className=" container ">
                    <div className="card card-custom">
                        <div className="card-body p-0">
                            <FormikWizard steps={steps} onSubmit={this.handleSubmit} render={FormWrapper} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch<HideModalAction | BookAIFPolicyAction | GetAIFQuotationAction | ChooseQuotationResetAction>) => ({
    onGetAIFQuotation: (customerId: string) => {
        const aifPolicy = prepareAifPolicyForQuotation(customerId);
        dispatch(aifActions.getAIFQuotation(aifPolicy));
        dispatch(thActions.chooseQuotationReset());
    },
    onBookAIFPolicy: (aifPolicy: AifPolicy) => {
        dispatch(aifActions.bookAIFPolicy(aifPolicy));
    },
    closeModal: () => { dispatch(hideModal()); }
});

const mapStateToProps = (state: any) => ({
    user: state.auth.user,
    chosenAIFQuotation: state.policy.aifQuotation,
    showModal: state.general.showModal,
    header: state.general.header,
    description: state.general.text,
    nextStep: state.general.nextStep,
    quotationFetched: state.policy.aifQuotation && state.policy.aifQuotation.length > 0
});

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)(ForeignerPolicy)));
