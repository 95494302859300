import { FormikWizardStepType } from '../../../utils/wizardTypes';
import { object, string, date, bool, number, StringSchema } from 'yup';
import store from '../../../redux/configureStore';
import * as piActions from "../../../redux/actionCreators/piPolicyActionCreators";
import moment from 'moment';
import PropertyPolicyMain from './PropertyPolicyMain';
import Packages from './Packages';
import { preparePiPolicy, preparePiPolicyForQuotation } from '../../../utils/policyTypes';
import * as generalActions from '../../../redux/actionCreators/generalActionCreators';
import { CheckProfileDto } from '../../../utils';
import Verification from './Verification';
import InsuredPerson from './InsuredPerson';
import IncludeAccident from './IncludeAccident';
import UploadDocuments from './UploadDocuments';

export default [
    {
        id: 'piMain',
        component: PropertyPolicyMain,
        name: 'Detajet e pronës',
        description: '',
        initialValues: {
            objectType: '',
            area: '',
            buildYear: '',
            objectAddress: '',
            couponAnswer: '',
            coupon: '',
            inventoryInsuredAmount: 'Jo',
            personalDataConsent: false
        },
        validationSchema: object().shape({
            objectType: string().required('E domosdoshme'),
            area: number().required('E domosdoshme')
                .test(
                    'validAreaValue',
                    'Vlera duhet të jetë midis 1 dhe 600',
                    function (this: any, value: any) {
                        return value >= 1 && value <= 600;
                    }),
            buildYear: number().required('E domosdoshme')
                .test(
                    'validBuildYear',
                    'Viti duhet të jetë midis 1970 dhe vitit aktual',
                    function (this: any, value: any) {
                        return value >= 1970 && value <= new Date().getFullYear();
                    }),
            objectAddress: string().required('E domosdoshme'),
            coupon: string().when('couponAnswer', (couponAnswer: string, schema: StringSchema<string | undefined>) => {
                return couponAnswer === '1' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            personalDataConsent: bool().oneOf([true], 'E domosdoshme')
        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
            if (sectionValues.objectType !== formValues.piMain.objectType
                || sectionValues.area !== formValues.piMain.area
                || sectionValues.buildYear !== formValues.piMain.buildYear
                || sectionValues.inventoryInsuredAmount !== formValues.piMain.inventoryInsuredAmount) {

                const state = store.getState();
                const customerId = state.auth.user?.customerId;

                const piPolicy = preparePiPolicyForQuotation(customerId, sectionValues);
                store.dispatch(piActions.getPIQuotation(piPolicy));
            }
        }
    },
    {
        id: 'package',
        component: Packages,
        name: 'Paketat',
        description: '',
        initialValues: {
            piPackageQuotation: ''
        },
        validationSchema: object().shape({

        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
        }
    },
    // {
    //     id: 'includeAccident',
    //     component: IncludeAccident,
    //     name: 'Përfshini aksidentin',
    //     description: '',
    //     initialValues: {
    //         includeAccident: '',
    //         insuredPersons: ''
    //     },
    //     validationSchema: object().shape({
    //         includeAccident: string().required('E domosdoshme'),
    //         insuredPersons: number()
    //             .when('includeAccident', (includeAccident: string, schema: StringSchema<string | undefined>) => {
    //                 return includeAccident === '1' ? (schema.required('E domosdoshme').test(
    //                     'validInsuredPersons',
    //                     'Vlera duhet të jetë midis 1 dhe 20',
    //                     function (this: any, value: any) {
    //                         return value >= 1 && value <= 20;
    //                     })) : schema.notRequired();
    //             }),
    //     }),
    //     actionLabel: 'Vazhdo',
    //     onAction: (sectionValues, formValues) => {
    //         if (sectionValues.includeAccident !== formValues.includeAccident.includeAccident
    //             || sectionValues.insuredPersons !== formValues.includeAccident.insuredPersons) {

    //             const state = store.getState();
    //             const customerId = state.auth.user?.customerId;

    //             const chosenPIQuotation = state.policy.chosenPIQuotation;
    //             const piPackageQuotation = chosenPIQuotation ? chosenPIQuotation[0] : null;
    //             const packageId = piPackageQuotation ? piPackageQuotation.packageId : '';

    //             const piPolicy = preparePiPolicyForQuotation(customerId, formValues.piMain, sectionValues.insuredPersons, sectionValues.includeAccident, packageId);

    //             store.dispatch(piActions.getPackagePIQuotation(piPolicy));
    //         }
    //     }
    // },
    {
        id: 'insuredPerson',
        component: InsuredPerson,
        name: 'I siguruari',
        description: '',
        initialValues: {
            tipKind: '',
            clientNo: '',
            nipt: '',
            title: '',
            lastName: '',
            sex: '',
            address: '',
            place: '',
            email: '',
            celphone: '+383',
            birthDate: ''
        },
        validationSchema: object().shape({
            clientNo: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            nipt: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '2' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            title: string().required('E domosdoshme'),
            lastName: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            sex: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            address: string().required('E domosdoshme'),
            place: string().required('E domosdoshme'),
            email: string()
                .email("Format I gabuar I email-it")
                .min(3, "Minimumi 3 simbole")
                .max(50, "Maksimumi 50 simbole")
                .required("E domosdoshme"),
            celphone: string().required('E domosdoshme').min(10, "Minimumi 10 simbole"),
            birthDate: date().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme').typeError('E domosdoshme')
                    .test(
                        'validBirthDate',
                        'Data e lindjes nuk mund të jetë pas ditës së sotme',
                        function (this: any, value: any) {
                            return moment(value, 'dd/MM/yyyy').isBefore(moment());
                        }) : schema.notRequired();
            })
        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
            const state = store.getState();

            const checkProfile = state.auth.checkProfile as CheckProfileDto;
            const user = state.auth.user;

            const continueWithoutAccount = state.general.continueWithoutAccount;
            if (continueWithoutAccount)
                return;

            if (!user && checkProfile.hasProfile && checkProfile.confirmedMail) {
                store.dispatch(generalActions.showLoginModal(sectionValues.email));
                return;
            }

            if (!user && checkProfile.hasProfile && !checkProfile.confirmedMail) {
                store.dispatch(generalActions.showVerificationModal());
                return;
            }

            if (!user && !checkProfile.hasProfile) {
                const insuredPerson = formValues.insuredPerson.tipKind === '3' ? sectionValues : null;

                store.dispatch(generalActions.showCreateAccountQuestionModal(insuredPerson));
                return;
            }
        }
    },
    {
        id: 'uploadDocuments',
        component: UploadDocuments,
        name: 'Dokumente',
        description: '',
        initialValues: {
            objectInventoryFiles: []
        },
        validationSchema: object().shape({
        }),
        actionLabel: 'Vazhdo'
    },
    {
        id: 'verification',
        component: Verification,
        name: 'Verifiko',
        description: '',
        initialValues: {
            acceptTerms: false,
            captchaGenerated: '',
            captchaInputted: ''
        },
        validationSchema: object().shape({
            acceptTerms: bool().oneOf([true], 'E domosdoshme'),
            captchaGenerated: string(),
            captchaInputted: string().required('E domosdoshme')
                .test(
                    'validCaptcha',
                    'Captcha eshte gabim',
                    function (this: any, value: any) {
                        if (value !== '')
                            return this.parent.captchaGenerated === value;

                        return true;
                    })
        }),
        actionLabel: 'Vazhdo',

    },
] as FormikWizardStepType[];
