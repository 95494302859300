import * as React from 'react';
import { useFormikWizard } from 'formik-wizard';
import { connect, useSelector } from 'react-redux';
import * as actionTypes from '../../../redux/actionTypes/gcPolicyActionTypes';
import InputFieldMain from '../../Custom/InputFieldMain';
import { AppState } from '../../../redux/reducers/rootReducer';
import SelectField from '../../Custom/SelectField';
import { Nomen } from '../../../utils';
import DatePickerFieldMain from '../../Custom/DatePickerFieldMain';

const VehicleMain = () => {
    const { values }: any = useFormikWizard();
    const erroredStep1 = useSelector<AppState, null | Error | string>((state: any) => state.error[actionTypes.GET_AMFGC_QUOTATION]);
    const insPeriods = useSelector<AppState, Nomen[]>((state: any) => state.nomen.GcPeriods);
    const isValid = useSelector<AppState, boolean | null>((state: any) => state.general.policyStartDateValidity);

    return (<div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
        <div className="mb-10 font-weight-bold text-dark"></div>
        <div className="row">
            <div className="col-xl-6">
                <InputFieldMain type="text" disabled={values.vehicleMain.plateNumber !== "" && (erroredStep1 === null || erroredStep1 === "")} name="plateNumber" label="Targa" placeholder="Targa" />
            </div>
            <div className="col-xl-6">
                <InputFieldMain type="text" name="chassis" disabled={values.vehicleMain.chassis !== "" && (erroredStep1 === null || erroredStep1 === "")} label="Numri i shasisë" placeholder="Numri i shasisë" />
            </div>
        </div>
        <div className="row">
            <div className="col-xl-6">
                <SelectField name="insuredPeriod" label="Periudha" disabled={values.vehicleMain.insuredPeriod !== "" && (erroredStep1 === null || erroredStep1 === "")} items={insPeriods} />
            </div>
            <div className="col-xl-6 addSpace" >
                <DatePickerFieldMain name="startdate" type="text" disabled={isValid !== null && isValid} placeholder="Fillon" newvalue={!!values?.vehicleMain?.startdate ? new Date(values?.vehicleMain?.startdate) : new Date() || new Date()} />
            </div>
        </div>
    </div>
    );
}
export default connect()(VehicleMain);
