import React from "react";
import { Route, RouteProps, RouteComponentProps } from "react-router-dom";
import Content from "./Content";

export function ContentRoute(props: RouteProps) {
    return (
        <Route {...props}>
            {routeProps => {
                if (typeof props.children === "function") {
                    return <Content>{props.children(routeProps)}</Content>;
                }

                if (!routeProps.match) {
                    return null;
                }

                if (props.children) {
                    return <Content>{props.children}</Content>;
                }

                if (props.component) {
                    return (
                        <Content>{React.createElement(props.component, routeProps)}</Content>
                    );
                }
                
                if (props.render) {
                    return <Content>{props.render(routeProps as RouteComponentProps<{}>)}</Content>;
                }

                return null;
            }}
        </Route>
    );
}
