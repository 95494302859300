import { Nomen } from './../../utils/generalTypes';

export const GET_TPL_VCCT = "nomenActionTypes/GET_TPL_VCCT";
export interface GetTplVcctAction {
  type: typeof GET_TPL_VCCT;
}

export const GET_TPL_VCCT_REQUEST = "nomenActionTypes/GET_TPL_VCCT_REQUEST";
export interface GetTplVcctRequestAction {
  type: typeof GET_TPL_VCCT_REQUEST;
}

export const GET_TPL_VCCT_SUCCESS = "nomenActionTypes/GET_TPL_VCCT_SUCCESS";
export interface GetTplVcctSuccessAction {
  type: typeof GET_TPL_VCCT_SUCCESS;
  data: Nomen[];
}

export const GET_TPL_VCCT_FAILURE = "nomenActionTypes/GET_TPL_VCCT_FAILURE";
export interface GetTplVcctFailureAction {
  type: typeof GET_TPL_VCCT_FAILURE;
  error: Error | string;
}

export const GET_GC_VCCT = "nomenActionTypes/GET_GC_VCCT";
export interface GetGcVcctAction {
  type: typeof GET_GC_VCCT;
}

export const GET_GC_VCCT_REQUEST = "nomenActionTypes/GET_GC_VCCT_REQUEST";
export interface GetGcVcctRequestAction {
  type: typeof GET_GC_VCCT_REQUEST;
}

export const GET_GC_VCCT_SUCCESS = "nomenActionTypes/GET_GC_VCCT_SUCCESS";
export interface GetGcVcctSuccessAction {
  type: typeof GET_GC_VCCT_SUCCESS;
  data: Nomen[];
}

export const GET_GC_VCCT_FAILURE = "nomenActionTypes/GET_GC_VCCT_FAILURE";
export interface GetGcVcctFailureAction {
  type: typeof GET_GC_VCCT_FAILURE;
  error: Error | string;
}

export const GET_B_VCCT = "nomenActionTypes/GET_B_VCCT";
export interface GetBVcctAction {
  type: typeof GET_B_VCCT;
}

export const GET_B_VCCT_REQUEST = "nomenActionTypes/GET_B_VCCT_REQUEST";
export interface GetBVcctRequestAction {
  type: typeof GET_B_VCCT_REQUEST;
}

export const GET_B_VCCT_SUCCESS = "nomenActionTypes/GET_B_VCCT_SUCCESS";
export interface GetBVcctSuccessAction {
  type: typeof GET_B_VCCT_SUCCESS;
  data: Nomen[];
}

export const GET_B_VCCT_FAILURE = "nomenActionTypes/GET_B_VCCT_FAILURE";
export interface GetBVcctFailureAction {
  type: typeof GET_B_VCCT_FAILURE;
  error: Error | string;
}

export const GET_VEHICLE_MARK = "nomenActionTypes/GET_VEHICLE_MARK";
export interface GetVehicleMarkAction {
  type: typeof GET_VEHICLE_MARK;
}

export const GET_VEHICLE_MARK_REQUEST = "nomenActionTypes/GET_VEHICLE_MARK_REQUEST";
export interface GetVehicleMarkRequestAction {
  type: typeof GET_VEHICLE_MARK_REQUEST;
}

export const GET_VEHICLE_MARK_SUCCESS = "nomenActionTypes/GET_VEHICLE_MARK_SUCCESS";
export interface GetVehicleMarkSuccessAction {
  type: typeof GET_VEHICLE_MARK_SUCCESS;
  data: Nomen[];
}

export const GET_VEHICLE_MARK_FAILURE = "nomenActionTypes/GET_VEHICLE_MARK_FAILURE";
export interface GetVehicleMarkFailureAction {
  type: typeof GET_VEHICLE_MARK_FAILURE;
  error: Error | string;
}

export const GET_TH_ZONE = "nomenActionTypes/GET_TH_ZONE";
export interface GetThZoneAction {
  type: typeof GET_TH_ZONE;
}

export const GET_TH_ZONE_REQUEST = "nomenActionTypes/GET_TH_ZONE_REQUEST";
export interface GetThZoneRequestAction {
  type: typeof GET_TH_ZONE_REQUEST;
}

export const GET_TH_ZONE_SUCCESS = "nomenActionTypes/GET_TH_ZONE_SUCCESS";
export interface GetThZoneSuccessAction {
  type: typeof GET_TH_ZONE_SUCCESS;
  data: Nomen[];
}

export const GET_TH_ZONE_FAILURE = "nomenActionTypes/GET_TH_ZONE_FAILURE";
export interface GetThZoneFailureAction {
  type: typeof GET_TH_ZONE_FAILURE;
  error: Error | string;
}

export const GET_GC_PERIOD = "nomenActionTypes/GET_GC_PERIOD";
export interface GetGcPeriodAction {
  type: typeof GET_GC_PERIOD;
}

export const GET_GC_PERIOD_REQUEST = "nomenActionTypes/GET_GC_PERIOD_REQUEST";
export interface GetGcPeriodRequestAction {
  type: typeof GET_GC_PERIOD_REQUEST;
}

export const GET_GC_PERIOD_SUCCESS = "nomenActionTypes/GET_GC_PERIOD_SUCCESS";
export interface GetGcPeriodSuccessAction {
  type: typeof GET_GC_PERIOD_SUCCESS;
  data: Nomen[];
}

export const GET_GC_PERIOD_FAILURE = "nomenActionTypes/GET_GC_PERIOD_FAILURE";
export interface GetGcPeriodFailureAction {
  type: typeof GET_GC_PERIOD_FAILURE;
  error: Error | string;
}

export const GET_B_PERIOD = "nomenActionTypes/GET_B_PERIOD";
export interface GetBPeriodAction {
  type: typeof GET_B_PERIOD;
}

export const GET_B_PERIOD_REQUEST = "nomenActionTypes/GET_B_PERIOD_REQUEST";
export interface GetBPeriodRequestAction {
  type: typeof GET_B_PERIOD_REQUEST;
}

export const GET_B_PERIOD_SUCCESS = "nomenActionTypes/GET_B_PERIOD_SUCCESS";
export interface GetBPeriodSuccessAction {
  type: typeof GET_B_PERIOD_SUCCESS;
  data: Nomen[];
}

export const GET_B_PERIOD_FAILURE = "nomenActionTypes/GET_B_PERIOD_FAILURE";
export interface GetBPeriodFailureAction {
  type: typeof GET_B_PERIOD_FAILURE;
  error: Error | string;
}

export const GET_POLICY_STATUS = "nomenActionTypes/GET_POLICY_STATUS";
export interface GetPolicyStatusAction {
  type: typeof GET_POLICY_STATUS;
}

export const GET_POLICY_STATUS_REQUEST = "nomenActionTypes/GET_POLICY_STATUS_REQUEST";
export interface GetPolicyStatusRequestAction {
  type: typeof GET_POLICY_STATUS_REQUEST;
}

export const GET_POLICY_STATUS_SUCCESS = "nomenActionTypes/GET_POLICY_STATUS_SUCCESS";
export interface GetPolicyStatusSuccessAction {
  type: typeof GET_POLICY_STATUS_SUCCESS;
  data: Nomen[];
}

export const GET_POLICY_STATUS_FAILURE = "nomenActionTypes/GET_POLICY_STATUS_FAILURE";
export interface GetPolicyStatusFailureAction {
  type: typeof GET_POLICY_STATUS_FAILURE;
  error: Error | string;
}

export const GET_DELIVERY_BRANCH_LOCATIONS = "nomenActionTypes/GET_DELIVERY_BRANCH_LOCATIONS";
export interface GetDeliveryBranchLocationsAction {
  type: typeof GET_DELIVERY_BRANCH_LOCATIONS;
}

export const GET_DELIVERY_BRANCH_LOCATIONS_REQUEST = "nomenActionTypes/GET_DELIVERY_BRANCH_LOCATIONS_REQUEST";
export interface GetDeliveryBranchLocationsRequestAction {
  type: typeof GET_DELIVERY_BRANCH_LOCATIONS_REQUEST;
}

export const GET_DELIVERY_BRANCH_LOCATIONS_SUCCESS = "nomenActionTypes/GET_DELIVERY_BRANCH_LOCATIONS_SUCCESS";
export interface GetDeliveryBranchLocationsSuccessAction {
  type: typeof GET_DELIVERY_BRANCH_LOCATIONS_SUCCESS;
  data: Nomen[];
}

export const GET_DELIVERY_BRANCH_LOCATIONS_FAILURE = "nomenActionTypes/GET_DELIVERY_BRANCH_LOCATIONS_FAILURE";
export interface GetDeliveryBranchLocationsFailureAction {
  type: typeof GET_DELIVERY_BRANCH_LOCATIONS_FAILURE;
  error: Error | string;
}

export const GET_PLACES = "nomenActionTypes/GET_PLACES";
export interface GetPlacesAction {
  type: typeof GET_PLACES;
}

export const GET_PLACES_REQUEST = "nomenActionTypes/GET_PLACES_REQUEST";
export interface GetPlacesRequestAction {
  type: typeof GET_PLACES_REQUEST;
}

export const GET_PLACES_SUCCESS = "nomenActionTypes/GET_PLACES_SUCCESS";
export interface GetPlacesSuccessAction {
  type: typeof GET_PLACES_SUCCESS;
  data: Nomen[];
}

export const GET_PLACES_FAILURE = "nomenActionTypes/GET_PLACES_FAILURE";
export interface GetPlacesFailureAction {
  type: typeof GET_PLACES_FAILURE;
  error: Error | string;
}

export const GET_NATURES = "nomenActionTypes/GET_NATURES";
export interface GetNaturesAction {
  type: typeof GET_NATURES;
}

export const GET_NATURES_REQUEST = "nomenActionTypes/GET_NATURES_REQUEST";
export interface GetNaturesRequestAction {
  type: typeof GET_NATURES_REQUEST;
}

export const GET_NATURES_SUCCESS = "nomenActionTypes/GET_NATURES_SUCCESS";
export interface GetNaturesSuccessAction {
  type: typeof GET_NATURES_SUCCESS;
  data: Nomen[];
}

export const GET_NATURES_FAILURE = "nomenActionTypes/GET_NATURES_FAILURE";
export interface GetNaturesFailureAction {
  type: typeof GET_NATURES_FAILURE;
  error: Error | string;
}

export const GET_TYPE_VEHICLE = "nomenActionTypes/GET_TYPE_VEHICLE";
export interface GetTypeVehicleAction {
  type: typeof GET_TYPE_VEHICLE;
}

export const GET_TYPE_VEHICLE_REQUEST = "nomenActionTypes/GET_TYPE_VEHICLE_REQUEST";
export interface GetTypeVehicleRequestAction {
  type: typeof GET_TYPE_VEHICLE_REQUEST;
}

export const GET_TYPE_VEHICLE_SUCCESS = "nomenActionTypes/GET_TYPE_VEHICLE_SUCCESS";
export interface GetTypeVehicleSuccessAction {
  type: typeof GET_TYPE_VEHICLE_SUCCESS;
  data: Nomen[];
}

export const GET_TYPE_VEHICLE_FAILURE = "nomenActionTypes/GET_TYPE_VEHICLE_FAILURE";
export interface GetTypeVehicleFailureAction {
  type: typeof GET_TYPE_VEHICLE_FAILURE;
  error: Error | string;
}

export const RESET_NOMEN = "nomenActionTypes/RESET_NOMEN";
export interface ResetNomenAction {
  type: typeof RESET_NOMEN;
}

export const GET_HEALTH_NATURES = "nomenActionTypes/GET_HEALTH_NATURES";
export interface GetHealthNaturesAction {
  type: typeof GET_HEALTH_NATURES;
}

export const GET_HEALTH_NATURES_REQUEST = "nomenActionTypes/GET_HEALTH_NATURES_REQUEST";
export interface GetHealthNaturesRequestAction {
  type: typeof GET_HEALTH_NATURES_REQUEST;
}

export const GET_HEALTH_NATURES_SUCCESS = "nomenActionTypes/GET_HEALTH_NATURES_SUCCESS";
export interface GetHealthNaturesSuccessAction {
  type: typeof GET_HEALTH_NATURES_SUCCESS;
  data: Nomen[];
}

export const GET_HEALTH_NATURES_FAILURE = "nomenActionTypes/GET_HEALTH_NATURES_FAILURE";
export interface GetHealthNaturesFailureAction {
  type: typeof GET_HEALTH_NATURES_FAILURE;
  error: Error | string;
}

export const GET_PROPERTY_NATURES = "nomenActionTypes/GET_PROPERTY_NATURES";
export interface GetPropertyNaturesAction {
  type: typeof GET_PROPERTY_NATURES;
}

export const GET_PROPERTY_NATURES_REQUEST = "nomenActionTypes/GET_PROPERTY_NATURES_REQUEST";
export interface GetPropertyNaturesRequestAction {
  type: typeof GET_PROPERTY_NATURES_REQUEST;
}

export const GET_PROPERTY_NATURES_SUCCESS = "nomenActionTypes/GET_PROPERTY_NATURES_SUCCESS";
export interface GetPropertyNaturesSuccessAction {
  type: typeof GET_PROPERTY_NATURES_SUCCESS;
  data: Nomen[];
}

export const GET_PROPERTY_NATURES_FAILURE = "nomenActionTypes/GET_PROPERTY_NATURES_FAILURE";
export interface GetPropertyNaturesFailureAction {
  type: typeof GET_PROPERTY_NATURES_FAILURE;
  error: Error | string;
}

export const GET_BANKS = "nomenActionTypes/GET_BANKS";
export interface GetBanksAction {
  type: typeof GET_BANKS;
}

export const GET_BANKS_REQUEST = "nomenActionTypes/GET_BANKS_REQUEST";
export interface GetBanksRequestAction {
  type: typeof GET_BANKS_REQUEST;
}

export const GET_BANKS_SUCCESS = "nomenActionTypes/GET_BANKS_SUCCESS";
export interface GetBanksSuccessAction {
  type: typeof GET_BANKS_SUCCESS;
  data: Nomen[];
}

export const GET_BANKS_FAILURE = "nomenActionTypes/GET_BANKS_FAILURE";
export interface GetBanksFailureAction {
  type: typeof GET_BANKS_FAILURE;
  error: Error | string;
}

export const GET_BANKS_LIFE = "nomenActionTypes/GET_BANKS_LIFE";
export interface GetBanksLifeAction {
  type: typeof GET_BANKS_LIFE;
}

export const GET_BANKS_LIFE_REQUEST = "nomenActionTypes/GET_BANKS_LIFE_REQUEST";
export interface GetBanksLifeRequestAction {
  type: typeof GET_BANKS_LIFE_REQUEST;
}

export const GET_BANKS_LIFE_SUCCESS = "nomenActionTypes/GET_BANKS_LIFE_SUCCESS";
export interface GetBanksLifeSuccessAction {
  type: typeof GET_BANKS_LIFE_SUCCESS;
  data: Nomen[];
}

export const GET_BANKS_LIFE_FAILURE = "nomenActionTypes/GET_BANKS_LIFE_FAILURE";
export interface GetBanksLifeFailureAction {
  type: typeof GET_BANKS_LIFE_FAILURE;
  error: Error | string;
}

export const GET_COUNTRIES = "nomenActionTypes/GET_COUNTRIES";
export interface GetCountriesAction {
  type: typeof GET_COUNTRIES;
  zone: string | null;
}

export const GET_COUNTRIES_REQUEST = "nomenActionTypes/GET_COUNTRIES_REQUEST";
export interface GetCountriesRequestAction {
  type: typeof GET_COUNTRIES_REQUEST;
}

export const GET_COUNTRIES_SUCCESS = "nomenActionTypes/GET_COUNTRIES_SUCCESS";
export interface GetCountriesSuccessAction {
  type: typeof GET_COUNTRIES_SUCCESS;
  data: Nomen[];
}

export const GET_COUNTRIES_FAILURE = "nomenActionTypes/GET_COUNTRIES_FAILURE";
export interface GetCountriesFailureAction {
  type: typeof GET_COUNTRIES_FAILURE;
  error: Error | string;
}

export const GET_COVER_CATEGORIES_FOR_POLICY = "nomenActionTypes/GET_COVER_CATEGORIES_FOR_POLICY";
export interface GetCoverCategoriesForPolicyAction {
  type: typeof GET_COVER_CATEGORIES_FOR_POLICY;
  policy: string,
  invoiceDate: string
}

export const GET_COVER_CATEGORIES_FOR_POLICY_REQUEST = "nomenActionTypes/GET_COVER_CATEGORIES_FOR_POLICY_REQUEST";
export interface GetCoverCategoriesForPolicyRequestAction {
  type: typeof GET_COVER_CATEGORIES_FOR_POLICY_REQUEST;
}

export const GET_COVER_CATEGORIES_FOR_POLICY_SUCCESS = "nomenActionTypes/GET_COVER_CATEGORIES_FOR_POLICY_SUCCESS";
export interface GetCoverCategoriesForPolicySuccessAction {
  type: typeof GET_COVER_CATEGORIES_FOR_POLICY_SUCCESS;
  data: Nomen[];
}

export const GET_COVER_CATEGORIES_FOR_POLICY_FAILURE = "nomenActionTypes/GET_COVER_CATEGORIES_FOR_POLICY_FAILURE";
export interface GetCoverCategoriesForPolicyFailureAction {
  type: typeof GET_COVER_CATEGORIES_FOR_POLICY_FAILURE;
  error: Error | string;
}

export const GET_COVER_GROUPS_FOR_POLICY = "nomenActionTypes/GET_COVER_GROUPS_FOR_POLICY";
export interface GetCoverGroupsForPolicyAction {
  type: typeof GET_COVER_GROUPS_FOR_POLICY;
  policy: string,
  invoiceDate: string,
  coverGroupType: string
}

export const GET_COVER_GROUPS_FOR_POLICY_REQUEST = "nomenActionTypes/GET_COVER_GROUPS_FOR_POLICY_REQUEST";
export interface GetCoverGroupsForPolicyRequestAction {
  type: typeof GET_COVER_GROUPS_FOR_POLICY_REQUEST;
}

export const GET_COVER_GROUPS_FOR_POLICY_SUCCESS = "nomenActionTypes/GET_COVER_GROUPS_FOR_POLICY_SUCCESS";
export interface GetCoverGroupsForPolicySuccessAction {
  type: typeof GET_COVER_GROUPS_FOR_POLICY_SUCCESS;
  data: Nomen[];
}

export const GET_COVER_GROUPS_FOR_POLICY_FAILURE = "nomenActionTypes/GET_COVER_GROUPS_FOR_POLICY_FAILURE";
export interface GetCoverGroupsForPolicyFailureAction {
  type: typeof GET_COVER_GROUPS_FOR_POLICY_FAILURE;
  error: Error | string;
}

export const GET_COVERS_FOR_POLICY = "nomenActionTypes/GET_COVERS_FOR_POLICY";
export interface GetCoversForPolicyAction {
  type: typeof GET_COVERS_FOR_POLICY;
  policy: string,
  invoiceDate: string,
  coverGroupType: string,
  coverGroup: string
}

export const GET_COVERS_FOR_POLICY_REQUEST = "nomenActionTypes/GET_COVERS_FOR_POLICY_REQUEST";
export interface GetCoversForPolicyRequestAction {
  type: typeof GET_COVERS_FOR_POLICY_REQUEST;
}

export const GET_COVERS_FOR_POLICY_SUCCESS = "nomenActionTypes/GET_COVERS_FOR_POLICY_SUCCESS";
export interface GetCoversForPolicySuccessAction {
  type: typeof GET_COVERS_FOR_POLICY_SUCCESS;
  data: Nomen[];
}

export const GET_COVERS_FOR_POLICY_FAILURE = "nomenActionTypes/GET_COVERS_FOR_POLICY_FAILURE";
export interface GetCoversForPolicyFailureAction {
  type: typeof GET_COVERS_FOR_POLICY_FAILURE;
  error: Error | string;
}

export type NomenAction =
  | GetTplVcctAction
  | GetTplVcctRequestAction
  | GetTplVcctSuccessAction
  | GetTplVcctFailureAction
  | GetGcVcctAction
  | GetGcVcctRequestAction
  | GetGcVcctSuccessAction
  | GetGcVcctFailureAction
  | GetBVcctAction
  | GetBVcctRequestAction
  | GetBVcctSuccessAction
  | GetBVcctFailureAction
  | GetVehicleMarkAction
  | GetVehicleMarkRequestAction
  | GetVehicleMarkSuccessAction
  | GetVehicleMarkFailureAction
  | GetThZoneAction
  | GetThZoneRequestAction
  | GetThZoneSuccessAction
  | GetThZoneFailureAction
  | GetGcPeriodAction
  | GetGcPeriodRequestAction
  | GetGcPeriodSuccessAction
  | GetGcPeriodFailureAction
  | GetBPeriodAction
  | GetBPeriodRequestAction
  | GetBPeriodSuccessAction
  | GetBPeriodFailureAction
  | GetPolicyStatusAction
  | GetPolicyStatusRequestAction
  | GetPolicyStatusSuccessAction
  | GetPolicyStatusFailureAction
  | GetDeliveryBranchLocationsAction
  | GetDeliveryBranchLocationsRequestAction
  | GetDeliveryBranchLocationsSuccessAction
  | GetDeliveryBranchLocationsFailureAction
  | GetPlacesAction
  | GetPlacesRequestAction
  | GetPlacesSuccessAction
  | GetPlacesFailureAction
  | GetNaturesAction
  | GetNaturesRequestAction
  | GetNaturesSuccessAction
  | GetNaturesFailureAction
  | GetTypeVehicleAction
  | GetTypeVehicleRequestAction
  | GetTypeVehicleSuccessAction
  | GetTypeVehicleFailureAction
  | ResetNomenAction
  | GetHealthNaturesAction
  | GetHealthNaturesRequestAction
  | GetHealthNaturesSuccessAction
  | GetHealthNaturesFailureAction
  | GetPropertyNaturesAction
  | GetPropertyNaturesRequestAction
  | GetPropertyNaturesSuccessAction
  | GetPropertyNaturesFailureAction
  | GetBanksAction
  | GetBanksRequestAction
  | GetBanksSuccessAction
  | GetBanksFailureAction
  | GetBanksLifeAction
  | GetBanksLifeRequestAction
  | GetBanksLifeSuccessAction
  | GetBanksLifeFailureAction
  | GetCountriesAction
  | GetCountriesRequestAction
  | GetCountriesSuccessAction
  | GetCountriesFailureAction
  | GetCoverCategoriesForPolicyAction
  | GetCoverCategoriesForPolicyRequestAction
  | GetCoverCategoriesForPolicySuccessAction
  | GetCoverCategoriesForPolicyFailureAction
  | GetCoverGroupsForPolicyAction
  | GetCoverGroupsForPolicyRequestAction
  | GetCoverGroupsForPolicySuccessAction
  | GetCoverGroupsForPolicyFailureAction
  | GetCoversForPolicyAction
  | GetCoversForPolicyRequestAction
  | GetCoversForPolicySuccessAction
  | GetCoversForPolicyFailureAction;