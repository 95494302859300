import React from "react";
import { connect, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { AppState } from '../../../../redux/reducers/rootReducer';
import * as nomenActionTypes from '../../../../redux/actionTypes/nomenActionTypes';
import { Loading } from "../../../Layout/Loading";
import InputFieldMain from "../../../Custom/InputFieldMain";
import SelectField from "../../../Custom/SelectField";
import DatePickerFieldInline from "../../../Custom/DatePickerFieldInline";
import { Gender } from '../../../../utils/nomen';
import { Nomen } from "../../../../utils";
import { makeStyles } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { IncomeSources } from '../../../../utils/nomen';
import { useFormikWizard } from "formik-wizard";
import CustomSelectField from "../../../Custom/CustomSelectField";

const BeneficiaryPerson = () => {
    const { values, setFieldValue }: any = useFormikContext();
    const { values: wizardValues }: any = useFormikWizard();
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[nomenActionTypes.GET_PLACES]);
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const showOtherSourcesField = values.sourceOfIncome === 'Te tjera specifiko';

    const useStyles = makeStyles(() => ({
        dropZone: {
            minHeight: '60px',
        },
        previewContainer: {
            container: 'true',
            width: '100%',
            height: '100%',
        },
        preview: {
            height: '100%',
            xs: '12',
        }
    }));

    const classes = useStyles();

    const saveIdCardPhoto = (files: any) => {
        setFieldValue('idCardBeneficiaryPhoto', files);
    }

    const sourceOfIncomeChanged = (value: any) => {
        if (value !== 'Te tjera specifiko') {
            wizardValues.insuredPerson.otherSources = '';
        }
    }

    return (<>
        {isLoading
            ? <Loading />
            : <>
                <div className="wizardTitle">Përfitues</div>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="clientNo" label="Numri personal" placeholder="Numri personal" maxLength={16} newvalue={values?.clientNo || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="title" label="Emri" placeholder="Emri" newvalue={values?.title || ''} inline capitalized />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="lastName" label="Mbiemri" placeholder="Mbiemri" newvalue={values?.lastName || ''} inline capitalized />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <SelectField name="sex" label="Gjinia" items={Gender} newvalue={values?.sex || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" newvalue={values?.address || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <SelectField name="place" label="Vendi" items={places} newvalue={values?.place || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="email" label="Email" placeholder="Email" newvalue={values?.email || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="celphone" label="Celulari" placeholder="Celulari" newvalue={values?.celphone || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <DatePickerFieldInline name="birthDate" type="text" placeholder="Datëlindja" newvalue={values?.birthDate || ''} />
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-xl-12 addSpace">
                            <CustomSelectField name="sourceOfIncome" label="Burimi i të ardhurave" items={IncomeSources} change={sourceOfIncomeChanged} newvalue={values?.sourceOfIncome || ''} inline />
                        </div>
                    </div>

                    {showOtherSourcesField && <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="otherSources" label="" placeholder="Burime të tjera" newvalue={values?.otherSources || ''} inline />
                        </div>
                    </div>} */}

                    {/* <div className="row">
                        <div className="col-xl-12 addSpace">
                            <div className="pb-5 pt-5" data-wizard-type="step-content" data-wizard-state="current">
                                <DropzoneArea
                                    dropzoneClass={classes.dropZone}
                                    dropzoneText='Foto e kartës së identitetit'
                                    onChange={saveIdCardPhoto}
                                    onDelete={saveIdCardPhoto}
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/jpeg']}
                                    filesLimit={1}
                                    maxFileSize={10485760}
                                    showPreviews={true}
                                    showPreviewsInDropzone={false}
                                    showFileNamesInPreview={true}
                                    showFileNames={true}
                                    initialFiles={values.idCardBeneficiaryPhoto || []}
                                    previewGridClasses={{
                                        container: classes.previewContainer,
                                        item: classes.preview
                                    }}
                                    getPreviewIcon={(file: any, classes: any) => {
                                        return (
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14 0C16.7614 0 19 2.23858 19 5V17C19 20.866 15.866 24 12 24C8.13401 24 5 20.866 5 17V9H7V17C7 19.7614 9.23858 22 12 22C14.7614 22 17 19.7614 17 17V5C17 3.34315 15.6569 2 14 2C12.3431 2 11 3.34315 11 5V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V6H15V17C15 18.6569 13.6569 20 12 20C10.3431 20 9 18.6569 9 17V5C9 2.23858 11.2386 0 14 0Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div> */}
                </div>
            </>
        }
    </>
    );
}
export default connect()(BeneficiaryPerson);
