import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import InputFieldMain from '../../Custom/InputFieldMain';
import { useFormikContext } from 'formik';
import CheckboxField from '../../Custom/CheckboxField';
import PersonalDataConsent from "../../../../src/InsuranceConditions/POLITIKA-E-PRIVATESISE-SIGAL.pdf";
import store from "../../../redux/configureStore";
import { Cover, prepareKaskoPolicyForQuotation, ThInsQuotation } from '../../../utils/policyTypes';
import * as kaskoActions from "../../../redux/actionCreators/kaskoPolicyActionCreators";
import { AppState } from '../../../redux/reducers/rootReducer';
import CheckboxFieldMain from '../../Custom/CheckboxFieldMain';
import * as generalActions from "../../../redux/actionCreators/generalActionCreators";
import RadioButtonGroupFieldMain from '../../Custom/RadioButtonGroupFieldMain';

const KaskoPolicyMain = () => {
    const { values, setValues }: any = useFormikContext();
    const chosenKaskoQuotation = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.kaskoQuotation);
    const kaskoPackageQuotation = chosenKaskoQuotation ? chosenKaskoQuotation[0] : null;
    const showCoupon = values.couponAnswer && values.couponAnswer === '1';

    const pixelScript = `
        !function(f,b,e,v,n,t,s){
            if(f.fbq)return;n=f.fbq=function(){
                n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)
            };
            if(!f._fbq) f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)
        }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');

        fbq('init', '2016267805471451');
        fbq('track', 'PageView');    `;

    React.useEffect(() => {
        const script = document.createElement("script");
        script.innerHTML = pixelScript;
        document.body.appendChild(script);
        
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    if (kaskoPackageQuotation && !values.coversFetched) {
        var currentValues = { ...values };
        currentValues.kaskoCovers = [...kaskoPackageQuotation.kaskoCovers];
        currentValues.coversFetched = true;
        setValues(currentValues);
    }

    const recalculateQuotation = (values: any) => {
        const state = store.getState();
        const customerId = state.auth.user?.customerId;

        const kaskoPolicy = prepareKaskoPolicyForQuotation(customerId, values);
        store.dispatch(kaskoActions.getKaskoQuotation(kaskoPolicy));
    }

    const getKaskoQuotation = (value: any) => {
        if (!value)
            return;

        if (value <= 5000 || value > 20000) {
            store.dispatch(generalActions.showModal("Dështimi", "Vlera duhet të jetë midis 5000 dhe 20 000!", ""));
            return;
        }

        recalculateQuotation(values);
    }

    const coverChanged = (value: any, index: number) => {
        var currentValues = { ...values };
        currentValues.kaskoCovers[index].selected = value;
        setValues(currentValues);

        recalculateQuotation(currentValues);
    }

    const couponChanged = (value: any) => {
        if (value === '1')
            return;

        var currentValues = { ...values };
        currentValues.coupon = '';
        setValues(currentValues);
    }

    return (<>
        <div className="wizardTitle">Primi</div>
        <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
            <div className="mb-10 font-weight-bold text-dark"></div>
            <div className="row">
                <div className="col-xl-12 addSpace">
                    <InputFieldMain type="number" name="vehicleInsuredAmount" label="Vlera e vetures (€)" placeholder="Vlera e vetures" blur={getKaskoQuotation} newvalue={values?.vehicleInsuredAmount || ''} inline />
                </div>
            </div>
            {values.kaskoCovers.length > 0 && <div className="addSpace">
                <label className="inlineLbl">Zgjidhni mbulimit</label>

                {values.kaskoCovers.map((cover: Cover, index: number) => (
                    <div className="row" key={index + 1}>
                        <div className="col-xl-4 addSpace"></div>
                        <div className="col-xl-8 addSpace">
                            <CheckboxFieldMain
                                name={cover.code}
                                type="checkbox"
                                label={cover.name + (cover.required ? '*' : '')}
                                index={index}
                                change={coverChanged}
                                newvalue={values.kaskoCovers[index].selected}
                                disabled={values.kaskoCovers[index].required}
                            />
                        </div>
                    </div>
                ))}
            </div>}

            <div className="row">
                <div className="col-xl-12 addSpace">
                    <label className="inlineLbl">A keni një kupon?</label>
                    <RadioButtonGroupFieldMain change={couponChanged} name="couponAnswer" type="radio" placeholder="" label="" items={[{ id: '1', value: 'Po' }, { id: '2', value: 'Jo' }]} newvalue={values?.couponAnswer || ''} inline />
                </div>
            </div>
            {showCoupon && <div className="row">
                <div className="col-xl-12 addSpace">
                    <InputFieldMain type="text" name="coupon" label="Kupon" placeholder="Kupon" inline maxLength={30} />
                </div>
            </div>}

            <hr />
            <div className="row">
                <div className="col-xl-12 addSpace">
                    <CheckboxField name="personalDataConsent" type="checkbox" label="Konfirmoj se kam lexuar dhe jam pajtuar e përcaktimet, kushtet, autorizimin dhe jap pëlqimin për marrjen dhe mbrojtjen e të dhënave personale" />
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 addSpace">
                    <a href={PersonalDataConsent} target="_blank">Politika e privatesisë Sigal Uniqa Group Austria Kosovë</a>
                </div>
            </div>
        </div>
        <noscript>
                <img height="1" width="1" style={{ display: 'none' }} src="https://www.facebook.com/tr?id=2016267805471451&ev=PageView&noscript=1" />
        </noscript>
    </>
    );
}
export default connect()(KaskoPolicyMain);
