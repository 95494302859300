import { put, call, takeEvery, fork, all } from 'redux-saga/effects';
import {
    bookTplPolicy, getAmfTplQuotation, getTplQuotation,
    bookGcPolicy, getAmfGcQuotation, getGcQuotation,
    getBQuotation, bookBPolicy, prepareBKTPayment,
    getThQuotation, bookThPolicy, createPaypalPayment,
    prepareUPCPayment, getPhQuotation, bookPhPolicy,
    getPiQuotation, bookPiPolicy, getAifQuotation,
    bookAifPolicy, getKaskoQuotation, bookKaskoPolicy,
    getCliQuotation, bookCliPolicy,
    getTLIQuotation, bookTLIPolicy
} from "../services/policyServices";
import * as tplPolicyActionCreators from "../actionCreators/tplPolicyActionCreators";
import * as gcPolicyActionCreators from "../actionCreators/gcPolicyActionCreators";
import * as bPolicyActionCreators from "../actionCreators/bPolicyActionCreators";
import * as generalActionCreators from "../actionCreators/generalActionCreators";
import * as thPolicyActionCreators from "../actionCreators/thPolicyActionCreators";
import * as claimActionCreators from "../actionCreators/claimActionCreators";
import * as tplPolicyActionTypes from "../actionTypes/tplPolicyActionTypes";
import * as gcPolicyActionTypes from "../actionTypes/gcPolicyActionTypes";
import * as bPolicyActionTypes from "../actionTypes/bPolicyActionTypes";
import * as thPolicyActionTypes from "../actionTypes/thPolicyActionTypes";
import * as phPolicyActionTypes from "../actionTypes/phPolicyActionTypes";
import * as phPolicyActionCreators from "../actionCreators/phPolicyActionCreators";
import * as piPolicyActionTypes from "../actionTypes/piPolicyActionTypes";
import * as piPolicyActionCreators from "../actionCreators/piPolicyActionCreators";
import * as aifPolicyActionTypes from "../actionTypes/aifPolicyActionTypes";
import * as aifPolicyActionCreators from "../actionCreators/aifPolicyActionCreators";
import * as kaskoPolicyActionTypes from "../actionTypes/kaskoPolicyActionTypes";
import * as kaskoPolicyActionCreators from "../actionCreators/kaskoPolicyActionCreators";
import * as cliPolicyActionTypes from "../actionTypes/cliPolicyActionTypes";
import * as cliPolicyActionCreators from "../actionCreators/cliPolicyActionCreators";
import * as tliPolicyActionTypes from "../actionTypes/tliPolicyActionTypes";
import * as tliPolicyActionCreators from "../actionCreators/tliPolicyActionCreators";

import { getPaymentReadyForm, getUPCPaymentReadyForm } from '../../utils/policyTypes';
import moment from 'moment';

function* onGetAmfTplQuotation({ customerId, platenumber, chassis, isCustomerVehicleOwner }: tplPolicyActionTypes.GetAMFTPLQuotationAction): any {
    try {
        yield put(tplPolicyActionCreators.getAMFTPLQuotationRequest());
        yield put(tplPolicyActionCreators.getAMFTPLQuotationReset());
        const response = yield call(getAmfTplQuotation, customerId, platenumber, chassis, isCustomerVehicleOwner);
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(tplPolicyActionCreators.getAMFTPLQuotationSuccess(response.data.data));
        }
        else {
            yield put(tplPolicyActionCreators.getAMFTPLQuotationFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(tplPolicyActionCreators.getAMFTPLQuotationFailure(error));
    }
}

function* watchOnGetAmfTplQuotation() {
    yield takeEvery(tplPolicyActionTypes.GET_AMFTPL_QUOTATION, onGetAmfTplQuotation);
}

function* onGetTplQuotation({ customerId, tplVehicleCategoryClass, prodyear, power }: tplPolicyActionTypes.GetTplQuotationAction): any {
    try {
        yield put(tplPolicyActionCreators.getTPLQuotationRequest());
        yield put(tplPolicyActionCreators.getTPLQuotationReset());
        const response = yield call(getTplQuotation, customerId, tplVehicleCategoryClass, prodyear, power);
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(tplPolicyActionCreators.getTPLQuotationSuccess(response.data.data));
        }
        else {
            yield put(tplPolicyActionCreators.getTPLQuotationSuccess(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(tplPolicyActionCreators.getTPLQuotationFailure(error));
    }
}

function* watchOnGetTplQuotation() {
    yield takeEvery(tplPolicyActionTypes.GET_TPL_QUOTATION, onGetTplQuotation)
}

function* onBookTplPolicy({ policy, payment }: tplPolicyActionTypes.BookTplPolicyAction): any {
    try {
        yield put(tplPolicyActionCreators.bookTPLPolicyRequest());
        const response = yield call(bookTplPolicy, policy);
        if (response.data.succeeded) {
            yield put(tplPolicyActionCreators.bookTPLPolicySuccess(response.data.data));
            if (payment === "1") { //handle bkt payment
                const responseBkt = yield call(prepareBKTPayment, response.data.data.bankOrderId, response.data.data.policyId, policy.customerId, response.data.data.total, true, "01", "01");
                if (responseBkt.data != null) {
                    const formToSubmit = getPaymentReadyForm(responseBkt.data);
                    formToSubmit.submit();
                }
                else {
                    yield put(tplPolicyActionCreators.bookTPLPolicyFailure(responseBkt.data.errorMessage));
                    yield put(generalActionCreators.showModal("Gabim", responseBkt.data.errorMessage, responseBkt.data.action));
                }
            }
            else if (payment === "2") { // Handle Paypal payment
                const responsePayPal = yield call(createPaypalPayment, response.data.data.bankOrderId, response.data.data.policyId, policy.customerId, response.data.data.total, true, "01", "01");
                if (responsePayPal.data.succeeded) {
                    const url = responsePayPal.data.data;
                    window.location = url;
                }
                else {
                    yield put(tplPolicyActionCreators.bookTPLPolicyFailure(responsePayPal.data.errorMessage));
                    yield put(generalActionCreators.showModal("Gabim", responsePayPal.data.errorMessage, responsePayPal.data.action));
                }
            }
        }
        else {
            yield put(tplPolicyActionCreators.bookTPLPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, response.data.action));
        }
    }
    catch (error: any) {
        yield put(tplPolicyActionCreators.bookTPLPolicyFailure(error));
    }
}

function* watchOnBookTplPolicy() {
    yield takeEvery(tplPolicyActionTypes.BOOK_TPL_POLICY, onBookTplPolicy);
}

function* onGetAmfGcQuotation({ customerId, platenumber, chassis, insuredPeriod, isCustomerVehicleOwner }: gcPolicyActionTypes.GetAMFGCQuotationAction): any {
    try {
        yield put(gcPolicyActionCreators.getAMFGCQuotationRequest());
        yield put(gcPolicyActionCreators.getAMFGCQuotationReset());
        const response = yield call(getAmfGcQuotation, customerId, platenumber, chassis, insuredPeriod, isCustomerVehicleOwner);
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(gcPolicyActionCreators.getAMFGCQuotationSuccess(response.data.data));
        }
        else {
            yield put(gcPolicyActionCreators.getAMFGCQuotationFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(gcPolicyActionCreators.getAMFGCQuotationFailure(error));
    }
}


function* watchOnGetAmfGcQuotation() {
    yield takeEvery(gcPolicyActionTypes.GET_AMFGC_QUOTATION, onGetAmfGcQuotation);
}

function* onGetGcQuotation({ customerId, gcVehicleCategoryClass, prodyear, power, insuredPeriod }: gcPolicyActionTypes.GetGCQuotationAction): any {
    try {
        yield put(gcPolicyActionCreators.getGCQuotationRequest());
        yield put(gcPolicyActionCreators.getGCQuotationReset());
        const response = yield call(getGcQuotation, customerId, gcVehicleCategoryClass, prodyear, power, insuredPeriod);
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(gcPolicyActionCreators.getGCQuotationSuccess(response.data.data));
        }
        else {
            yield put(gcPolicyActionCreators.getGCQuotationFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(gcPolicyActionCreators.getGCQuotationFailure(error));
    }
}

function* watchOnGetGcQuotation() {
    yield takeEvery(gcPolicyActionTypes.GET_GC_QUOTATION, onGetGcQuotation)
}

function* onBookGcPolicy({ policy, payment }: gcPolicyActionTypes.BookGCPolicyAction): any {
    try {
        yield put(gcPolicyActionCreators.bookGCPolicyRequest());
        const response = yield call(bookGcPolicy, policy);
        if (response.data.succeeded) {
            yield put(gcPolicyActionCreators.bookGCPolicySuccess(response.data.data));
            if (payment === "1") { //handle bkt payment
                const responseBkt = yield call(prepareBKTPayment, response.data.data.bankOrderId, response.data.data.policyId, policy.customerId, response.data.data.total, false, "02", "01");
                if (responseBkt.data != null) {
                    const formToSubmit = getPaymentReadyForm(responseBkt.data);
                    formToSubmit.submit();
                }
                else {
                    yield put(gcPolicyActionCreators.bookGCPolicyFailure(responseBkt.data.errorMessage));
                    yield put(generalActionCreators.showModal("Gabim", responseBkt.data.errorMessage, responseBkt.data.action));
                }
            }
            else if (payment === "2") { // Handle Paypal payment
                const responsePayPal = yield call(createPaypalPayment, response.data.data.bankOrderId, response.data.data.policyId, policy.customerId, response.data.data.total, false, "02", "01");
                if (responsePayPal.data.succeeded) {
                    const url = responsePayPal.data.data;
                    window.location = url;
                }
                else {
                    yield put(gcPolicyActionCreators.bookGCPolicyFailure(responsePayPal.data.errorMessage));
                    yield put(generalActionCreators.showModal("Gabim", responsePayPal.data.errorMessage, responsePayPal.data.action));
                }
            }
        }
        else {
            yield put(gcPolicyActionCreators.bookGCPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(gcPolicyActionCreators.bookGCPolicyFailure(error));
    }
}

function* watchOnBookGcPolicy() {
    yield takeEvery(gcPolicyActionTypes.BOOK_GC_POLICY, onBookGcPolicy);
}

function* onGetBQuotation({ customerId, bVehicleCategoryClass, prodyear, power, insuredPeriod }: bPolicyActionTypes.GetBQuotationAction): any {
    try {
        yield put(bPolicyActionCreators.getBQuotationRequest());
        yield put(bPolicyActionCreators.getBQuotationReset());
        const response = yield call(getBQuotation, customerId, bVehicleCategoryClass, prodyear, power, insuredPeriod);
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(bPolicyActionCreators.getBQuotationSuccess(response.data.data));
        }
        else {
            yield put(bPolicyActionCreators.getBQuotationFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(bPolicyActionCreators.getBQuotationFailure(error));
    }
}

function* watchOnGetBQuotation() {
    yield takeEvery(bPolicyActionTypes.GET_B_QUOTATION, onGetBQuotation)
}

function* onBookBPolicy({ policy, payment }: bPolicyActionTypes.BookBPolicyAction): any {
    try {
        yield put(bPolicyActionCreators.bookBPolicyRequest());
        const response = yield call(bookBPolicy, policy);
        if (response.data.succeeded) {
            yield put(bPolicyActionCreators.bookBPolicySuccess(response.data.data));
            if (payment === "1") { //handle bkt payment
                const responseBkt = yield call(prepareBKTPayment, response.data.data.bankOrderId, response.data.data.policyId, policy.customerId, response.data.data.total, false, "03", "01");
                if (responseBkt.data != null) {
                    const formToSubmit = getPaymentReadyForm(responseBkt.data);
                    formToSubmit.submit();
                }
                else {
                    yield put(bPolicyActionCreators.bookBPolicyFailure(responseBkt.data.errorMessage));
                    yield put(generalActionCreators.showModal("Gabim", responseBkt.data.errorMessage, responseBkt.data.action));
                }
            }
            else if (payment === "2") { // Handle Paypal payment
                const responsePayPal = yield call(createPaypalPayment, response.data.data.bankOrderId, response.data.data.policyId, policy.customerId, response.data.data.total, false, "03", "01");
                if (responsePayPal.data.succeeded) {
                    const url = responsePayPal.data.data;
                    window.location = url;
                }
                else {
                    yield put(bPolicyActionCreators.bookBPolicyFailure(responsePayPal.data.errorMessage));
                    yield put(generalActionCreators.showModal("Gabim", responsePayPal.data.errorMessage, responsePayPal.data.action));
                }
            }
        }
        else {
            yield put(bPolicyActionCreators.bookBPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(bPolicyActionCreators.bookBPolicyFailure(error));
    }
}

function* watchOnBookBPolicy() {
    yield takeEvery(bPolicyActionTypes.BOOK_B_POLICY, onBookBPolicy);
}

function* onGetThQuotation({ policy }: thPolicyActionTypes.GetTHQuotationAction): any {
    try {
        yield put(thPolicyActionCreators.getTHQuotationRequest());
        yield put(thPolicyActionCreators.getTHQuotationReset());
        const response = yield call(getThQuotation, createFormData(policy));
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(thPolicyActionCreators.getTHQuotationSuccess(response.data.data));
        }
        else {
            yield put(thPolicyActionCreators.getTHQuotationFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(thPolicyActionCreators.getTHQuotationFailure(error));
    }
}

function* watchOnGetThQuotation() {
    yield takeEvery(thPolicyActionTypes.GET_TH_QUOTATION, onGetThQuotation)
}

function* onBookThPolicy({ policy }: thPolicyActionTypes.BookTHPolicyAction): any {
    try {
        yield put(thPolicyActionCreators.bookTHPolicyRequest());
        const response = yield call(bookThPolicy, createFormData(policy));
        if (response.data.succeeded) {
            yield put(thPolicyActionCreators.bookTHPolicySuccess(response.data.data));
            const responseUPC = yield call(prepareUPCPayment, response.data.data.bankOrderId, response.data.data.policyId, policy.customerId, response.data.data.total, "05", "");
            if (responseUPC.data != null) {
                const formToSubmit = getUPCPaymentReadyForm(responseUPC.data);
                formToSubmit.submit();
            }
            else {
                yield put(thPolicyActionCreators.bookTHPolicyFailure(responseUPC.data.errorMessage));
                yield put(generalActionCreators.showModal("Gabim", responseUPC.data.errorMessage, responseUPC.data.action));
            }
        }
        else {
            yield put(thPolicyActionCreators.bookTHPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(thPolicyActionCreators.bookTHPolicyFailure(error));
    }
}

function* watchOnBookThPolicy() {
    yield takeEvery(thPolicyActionTypes.BOOK_TH_POLICY, onBookThPolicy);
}

function* onGetPackageThQuotation({ policy }: thPolicyActionTypes.GetPackageTHQuotationAction): any {
    try {
        yield put(thPolicyActionCreators.getPackageTHQuotationRequest());
        const response = yield call(getThQuotation, createFormData(policy));
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(thPolicyActionCreators.getPackageTHQuotationSuccess(response.data.data));
        }
        else {
            yield put(thPolicyActionCreators.getPackageTHQuotationFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(thPolicyActionCreators.getPackageTHQuotationFailure(error));
    }
}

function* watchOnGetPackageThQuotation() {
    yield takeEvery(thPolicyActionTypes.GET_PACKAGE_TH_QUOTATION, onGetPackageThQuotation)
}

function* onGetPhQuotation({ policy }: phPolicyActionTypes.GetPHQuotationAction): any {
    try {
        yield put(phPolicyActionCreators.getPHQuotationRequest());
        yield put(phPolicyActionCreators.getPHQuotationReset());
        const response = yield call(getPhQuotation, createFormData(policy));
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(phPolicyActionCreators.getPHQuotationSuccess(response.data.data));
        }
        else {
            yield put(phPolicyActionCreators.getPHQuotationFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(phPolicyActionCreators.getPHQuotationFailure(error));
    }
}

function* watchOnGetPhQuotation() {
    yield takeEvery(phPolicyActionTypes.GET_PH_QUOTATION, onGetPhQuotation)
}

function* onGetPackagePhQuotation({ policy }: phPolicyActionTypes.GetPackagePHQuotationAction): any {
    try {
        yield put(phPolicyActionCreators.getPackagePHQuotationRequest());
        const response = yield call(getPhQuotation, createFormData(policy));
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(phPolicyActionCreators.getPackagePHQuotationSuccess(response.data.data));
        }
        else {
            yield put(phPolicyActionCreators.getPackagePHQuotationFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(phPolicyActionCreators.getPackagePHQuotationFailure(error));
    }
}

function* watchOnGetPackagePhQuotation() {
    yield takeEvery(phPolicyActionTypes.GET_PACKAGE_PH_QUOTATION, onGetPackagePhQuotation)
}

function* onBookPhPolicy({ policy }: phPolicyActionTypes.BookPHPolicyAction): any {
    try {
        yield put(phPolicyActionCreators.bookPHPolicyRequest());
        const response = yield call(bookPhPolicy, createFormData(policy));
        if (response.data.succeeded) {
            yield put(phPolicyActionCreators.bookPHPolicySuccess(response.data.data));
            const responseUPC = yield call(prepareUPCPayment, response.data.data.bankOrderId, response.data.data.policyId, policy.customerId, response.data.data.total, "34", "");
            if (responseUPC.data != null) {
                const formToSubmit = getUPCPaymentReadyForm(responseUPC.data);
                formToSubmit.submit();
            }
            else {
                yield put(phPolicyActionCreators.bookPHPolicyFailure(responseUPC.data.errorMessage));
                yield put(generalActionCreators.showModal("Gabim", responseUPC.data.errorMessage, responseUPC.data.action));
            }
        }
        else {
            yield put(phPolicyActionCreators.bookPHPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(phPolicyActionCreators.bookPHPolicyFailure(error));
    }
}

function* watchOnBookPhPolicy() {
    yield takeEvery(phPolicyActionTypes.BOOK_PH_POLICY, onBookPhPolicy);
}

function* onGetPiQuotation({ policy }: piPolicyActionTypes.GetPIQuotationAction): any {
    try {
        yield put(piPolicyActionCreators.getPIQuotationRequest());
        yield put(piPolicyActionCreators.getPIQuotationReset());
        const response = yield call(getPiQuotation, createFormData(policy));
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(piPolicyActionCreators.getPIQuotationSuccess(response.data.data));
        }
        else {
            yield put(piPolicyActionCreators.getPIQuotationFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(piPolicyActionCreators.getPIQuotationFailure(error));
    }
}

function* watchOnGetPiQuotation() {
    yield takeEvery(piPolicyActionTypes.GET_PI_QUOTATION, onGetPiQuotation)
}

function* onGetPackagePiQuotation({ policy }: piPolicyActionTypes.GetPackagePIQuotationAction): any {
    try {
        yield put(piPolicyActionCreators.getPackagePIQuotationRequest());
        const response = yield call(getPiQuotation, createFormData(policy));
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(piPolicyActionCreators.getPackagePIQuotationSuccess(response.data.data));
        }
        else {
            yield put(piPolicyActionCreators.getPackagePIQuotationFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(piPolicyActionCreators.getPackagePIQuotationFailure(error));
    }
}

function* watchOnGetPackagePiQuotation() {
    yield takeEvery(piPolicyActionTypes.GET_PACKAGE_PI_QUOTATION, onGetPackagePiQuotation)
}

function* onBookPiPolicy({ policy }: piPolicyActionTypes.BookPIPolicyAction): any {
    try {
        yield put(piPolicyActionCreators.bookPIPolicyRequest());
        const response = yield call(bookPiPolicy, createFormData(policy));
        if (response.data.succeeded) {
            yield put(piPolicyActionCreators.bookPIPolicySuccess(response.data.data));
            const responseUPC = yield call(prepareUPCPayment, response.data.data.bankOrderId, response.data.data.policyId, policy.customerId, response.data.data.total, "09", "");
            if (responseUPC.data != null) {
                const formToSubmit = getUPCPaymentReadyForm(responseUPC.data);
                formToSubmit.submit();
            }
            else {
                yield put(piPolicyActionCreators.bookPIPolicyFailure(responseUPC.data.errorMessage));
                yield put(generalActionCreators.showModal("Gabim", responseUPC.data.errorMessage, responseUPC.data.action));
            }
        }
        else {
            yield put(piPolicyActionCreators.bookPIPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(piPolicyActionCreators.bookPIPolicyFailure(error));
    }
}

function* watchOnBookPiPolicy() {
    yield takeEvery(piPolicyActionTypes.BOOK_PI_POLICY, onBookPiPolicy);
}

function* onGetAifQuotation({ policy }: aifPolicyActionTypes.GetAIFQuotationAction): any {
    try {
        yield put(aifPolicyActionCreators.getAIFQuotationRequest());
        yield put(aifPolicyActionCreators.getAIFQuotationReset());
        const response = yield call(getAifQuotation, createFormData(policy));
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(aifPolicyActionCreators.getAIFQuotationSuccess(response.data.data));
        }
        else {
            yield put(aifPolicyActionCreators.getAIFQuotationFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(aifPolicyActionCreators.getAIFQuotationFailure(error));
    }
}

function* watchOnGetAifQuotation() {
    yield takeEvery(aifPolicyActionTypes.GET_AIF_QUOTATION, onGetAifQuotation)
}

function* onBookAifPolicy({ policy }: aifPolicyActionTypes.BookAIFPolicyAction): any {
    try {
        yield put(aifPolicyActionCreators.bookAIFPolicyRequest());
        const response = yield call(bookAifPolicy, createFormData(policy));
        if (response.data.succeeded) {
            yield put(aifPolicyActionCreators.bookAIFPolicySuccess(response.data.data));
            const responseUPC = yield call(prepareUPCPayment, response.data.data.bankOrderId, response.data.data.policyId, policy.customerId, response.data.data.total, "61", "");
            if (responseUPC.data != null) {
                const formToSubmit = getUPCPaymentReadyForm(responseUPC.data);
                formToSubmit.submit();
            }
            else {
                yield put(aifPolicyActionCreators.bookAIFPolicyFailure(responseUPC.data.errorMessage));
                yield put(generalActionCreators.showModal("Gabim", responseUPC.data.errorMessage, responseUPC.data.action));
            }
        }
        else {
            yield put(aifPolicyActionCreators.bookAIFPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(aifPolicyActionCreators.bookAIFPolicyFailure(error));
    }
}

function* watchOnBookAifPolicy() {
    yield takeEvery(aifPolicyActionTypes.BOOK_AIF_POLICY, onBookAifPolicy);
}

function* onGetKaskoQuotation({ policy }: kaskoPolicyActionTypes.GetKaskoQuotationAction): any {
    try {
        yield put(kaskoPolicyActionCreators.getKaskoQuotationRequest());
        yield put(kaskoPolicyActionCreators.getKaskoQuotationReset());
        const response = yield call(getKaskoQuotation, createFormData(policy));
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(kaskoPolicyActionCreators.getKaskoQuotationSuccess(response.data.data));
        }
        else {
            yield put(kaskoPolicyActionCreators.getKaskoQuotationFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(kaskoPolicyActionCreators.getKaskoQuotationFailure(error));
    }
}

function* watchOnGetKaskoQuotation() {
    yield takeEvery(kaskoPolicyActionTypes.GET_KASKO_QUOTATION, onGetKaskoQuotation)
}

function* onBookKaskoPolicy({ policy }: kaskoPolicyActionTypes.BookKaskoPolicyAction): any {
    try {
        yield put(kaskoPolicyActionCreators.bookKaskoPolicyRequest());
        const response = yield call(bookKaskoPolicy, createFormData(policy));
        if (response.data.succeeded) {
            yield put(kaskoPolicyActionCreators.bookKaskoPolicySuccess(response.data.data));
            const responseUPC = yield call(prepareUPCPayment, response.data.data.bankOrderId, response.data.data.policyId, policy.customerId, response.data.data.total, "04", "");
            if (responseUPC.data != null) {
                const formToSubmit = getUPCPaymentReadyForm(responseUPC.data);
                formToSubmit.submit();
            }
            else {
                yield put(kaskoPolicyActionCreators.bookKaskoPolicyFailure(responseUPC.data.errorMessage));
                yield put(generalActionCreators.showModal("Gabim", responseUPC.data.errorMessage, responseUPC.data.action));
            }
        }
        else {
            yield put(kaskoPolicyActionCreators.bookKaskoPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(kaskoPolicyActionCreators.bookKaskoPolicyFailure(error));
    }
}

function* watchOnBookKaskoPolicy() {
    yield takeEvery(kaskoPolicyActionTypes.BOOK_KASKO_POLICY, onBookKaskoPolicy);
}

function* onGetCliQuotation({ policy }: cliPolicyActionTypes.GetCLIQuotationAction): any {
    try {
        yield put(cliPolicyActionCreators.getCLIQuotationRequest());
        yield put(cliPolicyActionCreators.getCLIQuotationReset());
        const response = yield call(getCliQuotation, createFormData(policy));
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(cliPolicyActionCreators.getCLIQuotationSuccess(response.data.data));
        }
        else {
            yield put(cliPolicyActionCreators.getCLIQuotationFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(cliPolicyActionCreators.getCLIQuotationFailure(error));
    }
}

function* watchOnGetCliQuotation() {
    yield takeEvery(cliPolicyActionTypes.GET_CLI_QUOTATION, onGetCliQuotation)
}

function* onGetPackageCliQuotation({ policy }: cliPolicyActionTypes.GetPackageCLIQuotationAction): any {
    try {
        yield put(cliPolicyActionCreators.getPackageCLIQuotationRequest());
        const response = yield call(getCliQuotation, createFormData(policy));
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(cliPolicyActionCreators.getPackageCLIQuotationSuccess(response.data.data));
        }
        else {
            yield put(cliPolicyActionCreators.getPackageCLIQuotationFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(cliPolicyActionCreators.getPackageCLIQuotationFailure(error));
    }
}

function* watchOnGetPackageCliQuotation() {
    yield takeEvery(cliPolicyActionTypes.GET_PACKAGE_CLI_QUOTATION, onGetPackageCliQuotation)
}

function* onBookCliPolicy({ policy }: cliPolicyActionTypes.BookCLIPolicyAction): any {
    try {
        yield put(cliPolicyActionCreators.bookCLIPolicyRequest());
        const response = yield call(bookCliPolicy, createFormData(policy));
        if (response.data.succeeded) {
            yield put(cliPolicyActionCreators.bookCLIPolicySuccess(response.data.data));

            const responseUPC = yield call(prepareUPCPayment, response.data.data.bankOrderId, response.data.data.policyId, policy.customerId, response.data.data.total, "55", "01");
            if (responseUPC.data != null) {
                const formToSubmit = getUPCPaymentReadyForm(responseUPC.data);
                formToSubmit.submit();
            }
            else {
                yield put(cliPolicyActionCreators.bookCLIPolicyFailure(responseUPC.data.errorMessage));
                yield put(generalActionCreators.showModal("Gabim", responseUPC.data.errorMessage, responseUPC.data.action));
            }
        }
        else {
            yield put(cliPolicyActionCreators.bookCLIPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(cliPolicyActionCreators.bookCLIPolicyFailure(error));
    }
}

function* watchOnBookCliPolicy() {
    yield takeEvery(cliPolicyActionTypes.BOOK_CLI_POLICY, onBookCliPolicy);
}

function* onGetTLIQuotation({ policy }: tliPolicyActionTypes.GetTLIQuotationAction): any {
    try {
        yield put(tliPolicyActionCreators.getTLIQuotationRequest());
        yield put(tliPolicyActionCreators.getTLIQuotationReset());
        const response = yield call(getTLIQuotation, createFormData(policy));
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(tliPolicyActionCreators.getTLIQuotationSuccess(response.data.data));
        }
        else {
            yield put(tliPolicyActionCreators.getTLIQuotationFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(tliPolicyActionCreators.getTLIQuotationFailure(error));
    }
}

function* watchOnGetTLIQuotation() {
    yield takeEvery(tliPolicyActionTypes.GET_TLI_QUOTATION, onGetTLIQuotation)
}

function* onGetPackageTLIQuotation({ policy }: tliPolicyActionTypes.GetPackageTLIQuotationAction): any {
    try {
        yield put(tliPolicyActionCreators.getPackageTLIQuotationRequest());
        const response = yield call(getTLIQuotation, createFormData(policy));
        if (response.data.succeeded && response.data.action !== '1') {
            yield put(tliPolicyActionCreators.getPackageTLIQuotationSuccess(response.data.data));
        }
        else {
            yield put(tliPolicyActionCreators.getPackageTLIQuotationFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Information", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(tliPolicyActionCreators.getPackageTLIQuotationFailure(error));
    }
}

function* watchOnGetPackageTLIQuotation() {
    yield takeEvery(tliPolicyActionTypes.GET_PACKAGE_TLI_QUOTATION, onGetPackageTLIQuotation)
}

function* onBookTLIPolicy({ policy }: tliPolicyActionTypes.BookTLIPolicyAction): any {
    try {
        yield put(tliPolicyActionCreators.bookTLIPolicyRequest());
        const response = yield call(bookTLIPolicy, createFormData(policy));
        if (response.data.succeeded) {
            yield put(tliPolicyActionCreators.bookTLIPolicySuccess(response.data.data));

            const responseUPC = yield call(prepareUPCPayment, response.data.data.bankOrderId, response.data.data.policyId, policy.customerId, response.data.data.total, "34", "01");
            if (responseUPC.data != null) {
                const formToSubmit = getUPCPaymentReadyForm(responseUPC.data);
                formToSubmit.submit();
            }
            else {
                yield put(tliPolicyActionCreators.bookTLIPolicyFailure(responseUPC.data.errorMessage));
                yield put(generalActionCreators.showModal("Gabim", responseUPC.data.errorMessage, responseUPC.data.action));
            }
        }
        else {
            yield put(tliPolicyActionCreators.bookTLIPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(tliPolicyActionCreators.bookTLIPolicyFailure(error));
    }
}

function* watchOnBookTLIPolicy() {
    yield takeEvery(tliPolicyActionTypes.BOOK_TLI_POLICY, onBookTLIPolicy);
}

function* onLocationChange() {
    yield put(tplPolicyActionCreators.getTPLQuotationReset());
    yield put(gcPolicyActionCreators.getGCQuotationReset());
    yield put(bPolicyActionCreators.getBQuotationReset());
    yield put(thPolicyActionCreators.getTHQuotationReset());
    yield put(generalActionCreators.policyPreviewReset());
    yield put(generalActionCreators.searchPolicyReset());
    yield put(claimActionCreators.searchPreClaimReset());
    yield put(claimActionCreators.searchClaimReset());
    yield put(claimActionCreators.searchClaimHistoryReset());
    yield put(claimActionCreators.fetchVehicleFromPolicyReset());
    yield put(generalActionCreators.checkFValidReset());
    yield put(thPolicyActionCreators.chooseQuotationReset());
    yield put(thPolicyActionCreators.getPackageTHQuotationReset());
    yield put(phPolicyActionCreators.getPHQuotationReset());
    yield put(phPolicyActionCreators.getPackagePHQuotationReset());
    yield put(piPolicyActionCreators.getPIQuotationReset());
    yield put(piPolicyActionCreators.getPackagePIQuotationReset());
    yield put(aifPolicyActionCreators.getAIFQuotationReset());
    yield put(kaskoPolicyActionCreators.getKaskoQuotationReset());
    yield put(cliPolicyActionCreators.getCLIQuotationReset());
    yield put(cliPolicyActionCreators.getPackageCLIQuotationReset());
    yield put(tliPolicyActionCreators.getTLIQuotationReset());
    yield put(tliPolicyActionCreators.getPackageTLIQuotationReset());
    yield put(cliPolicyActionCreators.resetPoliticalForm());
}

function* watchOnLocationChange() {
    yield takeEvery('@@router/LOCATION_CHANGE', onLocationChange);
}

export default function* policySaga() {
    yield all([fork(watchOnGetAmfTplQuotation),
    fork(watchOnGetTplQuotation),
    fork(watchOnBookTplPolicy),
    fork(watchOnGetAmfGcQuotation),
    fork(watchOnGetGcQuotation),
    fork(watchOnBookGcPolicy),
    fork(watchOnGetBQuotation),
    fork(watchOnBookBPolicy),
    fork(watchOnLocationChange),
    fork(watchOnGetThQuotation),
    fork(watchOnGetPackageThQuotation),
    fork(watchOnBookThPolicy),
    fork(watchOnGetPhQuotation),
    fork(watchOnGetPackagePhQuotation),
    fork(watchOnBookPhPolicy),
    fork(watchOnGetPiQuotation),
    fork(watchOnGetPackagePiQuotation),
    fork(watchOnBookPiPolicy),
    fork(watchOnGetAifQuotation),
    fork(watchOnBookAifPolicy),
    fork(watchOnGetKaskoQuotation),
    fork(watchOnBookKaskoPolicy),
    fork(watchOnGetCliQuotation),
    fork(watchOnGetPackageCliQuotation),
    fork(watchOnBookCliPolicy),
    fork(watchOnGetTLIQuotation),
    fork(watchOnGetPackageTLIQuotation),
    fork(watchOnBookTLIPolicy)
    ]);
}

function createFormData(object: any, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();
    for (let property in object) {
        if (!object.hasOwnProperty(property) || !object[property]) {
            continue;
        }
        const formKey = namespace ? `${namespace}[${property}]` : property;
        if (object[property] instanceof Date) {
            formData.append(formKey, moment(object[property]).format('YYYY-MM-DD'));
        } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
            createFormData(object[property], formData, formKey);
        } else {
            formData.append(formKey, object[property]);
        }
    }
    return formData;
}