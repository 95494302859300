import React, { useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { AppState } from '../../../redux/reducers/rootReducer';
import Accordion from '../../Custom/Accordion';
import Driver from './Driver';
import { validateDriver } from "../../../utils/policyTypes";
import store from "../../../redux/configureStore";
import { User } from "../../../redux/types";
import * as authActions from "../../../redux/actionCreators/authActionCreators";

const Drivers = () => {
    const { values, setValues }: any = useFormikContext();
    const [key, setKey] = useState(0);
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const dispatch = useDispatch();

    const manageDrivers = () => {
        setKey(key + 1);

        if (values.drivers.length >= 5) {
            dispatch({
                type: "generalActionTypes/SHOW_MODAL",
                header: "Dështimi",
                text: "Mund të shtoni maksimumi 5 persona të siguruar!",
                next: ""
            });
            return;
        }

        const validity: any = validateDriver(values.drivers[0], values.drivers);
        if (validity.errored) {
            dispatch({
                type: "generalActionTypes/SHOW_MODAL",
                header: "Dështimi",
                text: validity.message,
                next: ""
            });
            return;
        }

        var currentValues = { ...values };
        currentValues.drivers.unshift(blankDriver);
        setValues(currentValues);

        const customerId = user?.customerId;

        if (!customerId) {
            store.dispatch(authActions.hasProfile(currentValues.drivers[currentValues.drivers.length - 1].email));
        }
    }

    const removeDriver = (index: any) => {
        setKey(key - 1);
        var currentValues = { ...values };

        const isFirstPerson = index === currentValues.drivers.length - 1;

        currentValues.drivers.splice(index, 1);
        setValues(currentValues);

        if (isFirstPerson) {
            const customerId = user?.customerId;

            if (!customerId) {
                store.dispatch(authActions.hasProfile(currentValues.drivers[currentValues.drivers.length - 1].email));
            }
        }
    }

    const updateDriver = (tipKind: any, index: any) => {
        var currentValues = { ...values };
        currentValues.drivers[index].tipKind = tipKind;

        if (tipKind === '2') {
            currentValues.drivers[index].clientNo = '';
            currentValues.drivers[index].lastName = '';
            currentValues.drivers[index].sex = '';
            currentValues.drivers[index].drivingLicenceNo = '';
            currentValues.drivers[index].birthDate = '';
        } else {
            currentValues.drivers[index].nipt = '';
        }

        setValues(currentValues);
    }

    const blankDriver = {
        tipKind: '3',
        clientNo: '',
        nipt: '',
        title: '',
        lastName: '',
        sex: '',
        address: '',
        place: '',
        email: '',
        celphone: '+383',
        drivingLicenceNo: '',
        birthDate: ''
    }

    return (<>
        <div className="wizardTitle">Kontraktor/Shofer</div>
        <div>
            <div className="row">
                <div className="col-xl-9"></div>
                <div className="col-xl-3"><button type="button" onClick={manageDrivers} className="btn btn-wiz right-btn" data-wizard-type="action-submit">
                    +  Shto shofer
                </button>
                </div>
            </div>
            <div className="accordion mt5">
                <Accordion title={values.drivers.length == 1 ? "Kontraktor/Shofer" : ("Shofer " + (values.drivers.length - 1))} subtitle={''} active={true} content={
                    <Driver key={0} data={values.drivers[0]} dropkey={key} index={0} enableDelete={values.drivers.length >= 2} removeDriver={removeDriver} tipKindChanged={updateDriver} />
                } />
            </div>
            <div className="accordion">
                {values.drivers.slice(1).map((driver: any, index: number) => (
                    <Accordion title={index == values.drivers.length - 2 ? "Kontraktor/Shofer " : "Shofer " + (values.drivers.length - index - 2)} subtitle={''} active={false} content={
                        <Driver key={index + 1} index={index + 1} dropkey={key} data={driver} enableDelete={values.drivers.length > 1 && index != values.drivers.length - 2} removeDriver={removeDriver} tipKindChanged={updateDriver} />
                    } />
                ))}
            </div>
        </div>
    </>
    );
}
export default connect()(Drivers);
