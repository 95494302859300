import { BookingData, PhPolicy, Question, ThInsQuotation } from "../../utils/policyTypes";

export const PH_QUESTIONS_FAILURE = "policyActionTypes/PH_QUESTIONS_FAILURE";
export interface PhQuestionsFailureAction {
  type: typeof PH_QUESTIONS_FAILURE;
}

export const PH_QUESTIONS_RESET = "policyActionTypes/PH_QUESTIONS_RESET";
export interface PhQuestionsResetAction {
  type: typeof PH_QUESTIONS_RESET;
}

export const GET_PH_QUOTATION = "policyActionTypes/GET_PH_QUOTATION";
export interface GetPHQuotationAction {
  type: typeof GET_PH_QUOTATION;
  policy: PhPolicy;
}

export const GET_PH_QUOTATION_REQUEST = "policyActionTypes/GET_PH_QUOTATION_REQUEST";
export interface GetPHQuotationRequestAction {
  type: typeof GET_PH_QUOTATION_REQUEST;
}

export const GET_PH_QUOTATION_RESET = "policyActionTypes/GET_PH_QUOTATION_RESET";
export interface GetPHQuotationResetAction {
  type: typeof GET_PH_QUOTATION_RESET;
}

export const GET_PH_QUOTATION_SUCCESS = "policyActionTypes/GET_PH_QUOTATION_SUCCESS";
export interface GetPHQuotationSuccessAction {
  type: typeof GET_PH_QUOTATION_SUCCESS;
  data: ThInsQuotation;
}

export const GET_PH_QUOTATION_FAILURE = "policyActionTypes/GET_PH_QUOTATION_FAILURE";
export interface GetPHQuotationFailureAction {
  type: typeof GET_PH_QUOTATION_FAILURE;
  error: Error | string;
}


export const GET_PACKAGE_PH_QUOTATION = "policyActionTypes/GET_PACKAGE_PH_QUOTATION";
export interface GetPackagePHQuotationAction {
  type: typeof GET_PACKAGE_PH_QUOTATION;
  policy: PhPolicy;
}

export const GET_PACKAGE_PH_QUOTATION_REQUEST = "policyActionTypes/GET_PACKAGE_PH_QUOTATION_REQUEST";
export interface GetPackagePHQuotationRequestAction {
  type: typeof GET_PACKAGE_PH_QUOTATION_REQUEST;
}

export const GET_PACKAGE_PH_QUOTATION_RESET = "policyActionTypes/GET_PACKAGE_PH_QUOTATION_RESET";
export interface GetPackagePHQuotationResetAction {
  type: typeof GET_PACKAGE_PH_QUOTATION_RESET;
}

export const GET_PACKAGE_PH_QUOTATION_SUCCESS = "policyActionTypes/GET_PACKAGE_PH_QUOTATION_SUCCESS";
export interface GetPackagePHQuotationSuccessAction {
  type: typeof GET_PACKAGE_PH_QUOTATION_SUCCESS;
  data: ThInsQuotation;
}

export const GET_PACKAGE_PH_QUOTATION_FAILURE = "policyActionTypes/GET_PACKAGE_PH_QUOTATION_FAILURE";
export interface GetPackagePHQuotationFailureAction {
  type: typeof GET_PACKAGE_PH_QUOTATION_FAILURE;
  error: Error | string;
}

export const BOOK_PH_POLICY = "policyActionTypes/BOOK_PH_POLICY";
export interface BookPHPolicyAction {
  type: typeof BOOK_PH_POLICY;
  policy: PhPolicy;
}

export const BOOK_PH_POLICY_REQUEST = "policyActionTypes/BOOK_PH_POLICY_REQUEST";
export interface BookPHPolicyRequestAction {
  type: typeof BOOK_PH_POLICY_REQUEST;
}

export const BOOK_PH_POLICY_SUCCESS = "policyActionTypes/BOOK_PH_POLICY_SUCCESS";
export interface BookPHPolicySuccessAction {
  type: typeof BOOK_PH_POLICY_SUCCESS;
  data: BookingData;
}

export const BOOK_PH_POLICY_FAILURE = "policyActionTypes/BOOK_PH_POLICY_FAILURE";
export interface BookPHPolicyFailureAction {
  type: typeof BOOK_PH_POLICY_FAILURE;
  error: Error | string;
}

export type PolicyAction =
  | PhQuestionsFailureAction
  | PhQuestionsResetAction
  | GetPHQuotationAction
  | GetPHQuotationRequestAction
  | GetPHQuotationResetAction
  | GetPHQuotationSuccessAction
  | GetPHQuotationFailureAction
  | GetPackagePHQuotationRequestAction
  | GetPackagePHQuotationResetAction
  | GetPackagePHQuotationSuccessAction
  | GetPackagePHQuotationFailureAction
  | BookPHPolicyAction
  | BookPHPolicyRequestAction
  | BookPHPolicySuccessAction
  | BookPHPolicyFailureAction;
