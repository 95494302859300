import { PreClaim, PreClaimRegisterData, PreClaimPreview, PreClaimGridItem, PreClaimFilter, ClaimGridItem, ClaimFilter, PreClaimVehicle, PreClaimPerson } from './../../utils/claimTypes';

export const REGISTER_PRE_CLAIM = "claimActionTypes/REGISTER_PRE_CLAIM";
export interface RegisterPreClaimAction {
  type: typeof REGISTER_PRE_CLAIM;
  preClaim: PreClaim;
}

export const REGISTER_PRE_CLAIM_REQUEST = "claimActionTypes/REGISTER_PRE_CLAIM_REQUEST";
export interface RegisterPreClaimRequestAction {
  type: typeof REGISTER_PRE_CLAIM_REQUEST;
}

export const REGISTER_PRE_CLAIM_SUCCESS = "claimActionTypes/REGISTER_PRE_CLAIM_SUCCESS";
export interface RegisterPreClaimSuccessAction {
  type: typeof REGISTER_PRE_CLAIM_SUCCESS;
  data: PreClaimRegisterData;
}

export const REGISTER_PRE_CLAIM_FAILURE = "claimActionTypes/REGISTER_PRE_CLAIM_FAILURE";
export interface RegisterPreClaimFailureAction {
  type: typeof REGISTER_PRE_CLAIM_FAILURE;
  error: Error | string;
}


export const SHOW_PRECLAIM_MODAL = "claimActionTypes/SHOW_PRECLAIM_MODAL";
export interface ShowPreClaimModalAction {
  type: typeof SHOW_PRECLAIM_MODAL;
}

export const SHOW_CLAIM_MODAL = "claimActionTypes/SHOW_CLAIM_MODAL";
export interface ShowClaimModalAction {
  type: typeof SHOW_CLAIM_MODAL;
}

export const PRECLAIM_PREVIEW = "claimActionTypes/PRECLAIM_PREVIEW";
export interface PreClaimPreviewAction {
  type: typeof PRECLAIM_PREVIEW;
  code: string;
}

export const PRECLAIM_PREVIEW_REQUEST = "claimActionTypes/PRECLAIM_PREVIEW_REQUEST";
export interface PreClaimPreviewRequestAction {
  type: typeof PRECLAIM_PREVIEW_REQUEST;
}

export const PRECLAIM_PREVIEW_SUCCESS = "claimActionTypes/PRECLAIM_PREVIEW_SUCCESS";
export interface PreClaimPreviewSuccessAction {
  type: typeof PRECLAIM_PREVIEW_SUCCESS;
  data: PreClaimPreview;
}

export const PRECLAIM_PREVIEW_FAILURE = "claimActionTypes/PRECLAIM_PREVIEW_FAILURE";
export interface PreClaimPreviewFailureAction {
  type: typeof PRECLAIM_PREVIEW_FAILURE;
  error: Error | string;
}

export const PRECLAIM_PREVIEW_RESET = "claimActionTypes/PRECLAIM_PREVIEW_RESET";
export interface PreClaimPreviewResetAction {
  type: typeof PRECLAIM_PREVIEW_RESET;
}

export const CLAIM_PREVIEW = "claimActionTypes/CLAIM_PREVIEW";
export interface ClaimPreviewAction {
  type: typeof CLAIM_PREVIEW;
  claimId: string;
}

export const CLAIM_PREVIEW_REQUEST = "claimActionTypes/CLAIM_PREVIEW_REQUEST";
export interface ClaimPreviewRequestAction {
  type: typeof CLAIM_PREVIEW_REQUEST;
}

export const CLAIM_PREVIEW_SUCCESS = "claimActionTypes/CLAIM_PREVIEW_SUCCESS";
export interface ClaimPreviewSuccessAction {
  type: typeof CLAIM_PREVIEW_SUCCESS;
  data: ClaimGridItem;
}

export const CLAIM_PREVIEW_FAILURE = "claimActionTypes/CLAIM_PREVIEW_FAILURE";
export interface ClaimPreviewFailureAction {
  type: typeof CLAIM_PREVIEW_FAILURE;
  error: Error | string;
}

export const CLAIM_PREVIEW_RESET = "claimActionTypes/CLAIM_PREVIEW_RESET";
export interface ClaimPreviewResetAction {
  type: typeof CLAIM_PREVIEW_RESET;
}

export const SEARCH_PRECLAIM = "claimActionTypes/SEARCH_PRECLAIM";
export interface SearchPreClaimAction {
  type: typeof SEARCH_PRECLAIM;
  filter: PreClaimFilter;
}

export const SEARCH_PRECLAIM_REQUEST = "claimActionTypes/SEARCH_PRECLAIM_REQUEST";
export interface SearchPreClaimRequestAction {
  type: typeof SEARCH_PRECLAIM_REQUEST;
}

export const SEARCH_PRECLAIM_SUCCESS = "claimActionTypes/SEARCH_PRECLAIM_SUCCESS";
export interface SearchPreClaimSuccessAction {
  type: typeof SEARCH_PRECLAIM_SUCCESS;
  data: PreClaimGridItem[];
  count: number;
}

export const SEARCH_PRECLAIM_RESET = "claimActionTypes/SEARCH_PRECLAIM_RESET";
export interface SearchPreClaimResetAction {
  type: typeof SEARCH_PRECLAIM_RESET;
}

export const SEARCH_PRECLAIM_FAILURE = "claimActionTypes/SEARCH_PRECLAIM_FAILURE";
export interface SearchPreClaimFailureAction {
  type: typeof SEARCH_PRECLAIM_FAILURE;
  error: Error | string;
}

export const SEARCH_CLAIM = "claimActionTypes/SEARCH_CLAIM";
export interface SearchClaimAction {
  type: typeof SEARCH_CLAIM;
  filter: ClaimFilter;
}

export const SEARCH_CLAIM_REQUEST = "claimActionTypes/SEARCH_CLAIM_REQUEST";
export interface SearchClaimRequestAction {
  type: typeof SEARCH_CLAIM_REQUEST;
}

export const SEARCH_CLAIM_SUCCESS = "claimActionTypes/SEARCH_CLAIM_SUCCESS";
export interface SearchClaimSuccessAction {
  type: typeof SEARCH_CLAIM_SUCCESS;
  data: ClaimGridItem[];
  count: number;
}

export const SEARCH_CLAIM_RESET = "claimActionTypes/SEARCH_CLAIM_RESET";
export interface SearchClaimResetAction {
  type: typeof SEARCH_CLAIM_RESET;
}

export const SEARCH_CLAIM_FAILURE = "claimActionTypes/SEARCH_CLAIM_FAILURE";
export interface SearchClaimFailureAction {
  type: typeof SEARCH_CLAIM_FAILURE;
  error: Error | string;
}

export const FETCH_VEHICLE_FROM_POLICY = "claimActionTypes/FETCH_VEHICLE_FROM_POLICY";
export interface FetchVehicleFromPolicyAction {
  type: typeof FETCH_VEHICLE_FROM_POLICY;
  policyType: string;
  policyCode: string;
}

export const FETCH_VEHICLE_FROM_POLICY_REQUEST = "claimActionTypes/FETCH_VEHICLE_FROM_POLICY_REQUEST";
export interface FetchVehicleFromPolicyRequestAction {
  type: typeof FETCH_VEHICLE_FROM_POLICY_REQUEST;
}

export const FETCH_VEHICLE_FROM_POLICY_RESET = "claimActionTypes/FETCH_VEHICLE_FROM_POLICY_RESET";
export interface FetchVehicleFromPolicyResetAction {
  type: typeof FETCH_VEHICLE_FROM_POLICY_RESET;
}

export const FETCH_VEHICLE_FROM_POLICY_SUCCESS = "claimActionTypes/FETCH_VEHICLE_FROM_POLICY_SUCCESS";
export interface FetchVehicleFromPolicySuccessAction {
  type: typeof FETCH_VEHICLE_FROM_POLICY_SUCCESS;
  data: PreClaimVehicle;
}

export const FETCH_VEHICLE_FROM_POLICY_FAILURE = "claimActionTypes/FETCH_VEHICLE_FROM_POLICY_FAILURE";
export interface FetchVehicleFromPolicyFailureAction {
  type: typeof FETCH_VEHICLE_FROM_POLICY_FAILURE;
  error: Error | string;
}

export const FETCH_INSURED_PERSON_FROM_POLICY = "claimActionTypes/FETCH_INSURED_PERSON_FROM_POLICY";
export interface FetchInsuredPersonFromPolicyAction {
  type: typeof FETCH_INSURED_PERSON_FROM_POLICY;
  policyType: string;
  policyCode: string;
  customerId: string
}

export const FETCH_INSURED_PERSON_FROM_POLICY_REQUEST = "claimActionTypes/FETCH_INSURED_PERSON_FROM_POLICY_REQUEST";
export interface FetchInsuredPersonFromPolicyRequestAction {
  type: typeof FETCH_INSURED_PERSON_FROM_POLICY_REQUEST;
}

export const FETCH_INSURED_PERSON_FROM_POLICY_RESET = "claimActionTypes/FETCH_INSURED_PERSON_FROM_POLICY_RESET";
export interface FetchInsuredPersonFromPolicyResetAction {
  type: typeof FETCH_INSURED_PERSON_FROM_POLICY_RESET;
}

export const FETCH_INSURED_PERSON_FROM_POLICY_SUCCESS = "claimActionTypes/FETCH_INSURED_PERSON_FROM_POLICY_SUCCESS";
export interface FetchInsuredPersonFromPolicySuccessAction {
  type: typeof FETCH_INSURED_PERSON_FROM_POLICY_SUCCESS;
  data: PreClaimPerson;
}

export const FETCH_INSURED_PERSON_FROM_POLICY_FAILURE = "claimActionTypes/FETCH_INSURED_PERSON_FROM_POLICY_FAILURE";
export interface FetchInsuredPersonFromPolicyFailureAction {
  type: typeof FETCH_INSURED_PERSON_FROM_POLICY_FAILURE;
  error: Error | string;
}

export const REGISTER_HEALTH_PRE_CLAIM = "claimActionTypes/REGISTER_HEALTH_PRE_CLAIM";
export interface RegisterHealthPreClaimAction {
  type: typeof REGISTER_HEALTH_PRE_CLAIM;
  preClaim: PreClaim;
}

export const REGISTER_HEALTH_PRE_CLAIM_REQUEST = "claimActionTypes/REGISTER_HEALTH_PRE_CLAIM_REQUEST";
export interface RegisterHealthPreClaimRequestAction {
  type: typeof REGISTER_HEALTH_PRE_CLAIM_REQUEST;
}

export const REGISTER_HEALTH_PRE_CLAIM_SUCCESS = "claimActionTypes/REGISTER_HEALTH_PRE_CLAIM_SUCCESS";
export interface RegisterHealthPreClaimSuccessAction {
  type: typeof REGISTER_HEALTH_PRE_CLAIM_SUCCESS;
  data: PreClaimRegisterData;
}

export const REGISTER_HEALTH_PRE_CLAIM_FAILURE = "claimActionTypes/REGISTER_HEALTH_PRE_CLAIM_FAILURE";
export interface RegisterHealthPreClaimFailureAction {
  type: typeof REGISTER_HEALTH_PRE_CLAIM_FAILURE;
  error: Error | string;
}

export const REGISTER_PROPERTY_PRE_CLAIM = "claimActionTypes/REGISTER_PROPERTY_PRE_CLAIM";
export interface RegisterPropertyPreClaimAction {
  type: typeof REGISTER_PROPERTY_PRE_CLAIM;
  preClaim: PreClaim;
}

export const REGISTER_PROPERTY_PRE_CLAIM_REQUEST = "claimActionTypes/REGISTER_PROPERTY_PRE_CLAIM_REQUEST";
export interface RegisterPropertyPreClaimRequestAction {
  type: typeof REGISTER_PROPERTY_PRE_CLAIM_REQUEST;
}

export const REGISTER_PROPERTY_PRE_CLAIM_SUCCESS = "claimActionTypes/REGISTER_PROPERTY_PRE_CLAIM_SUCCESS";
export interface RegisterPropertyPreClaimSuccessAction {
  type: typeof REGISTER_PROPERTY_PRE_CLAIM_SUCCESS;
  data: PreClaimRegisterData;
}

export const REGISTER_PROPERTY_PRE_CLAIM_FAILURE = "claimActionTypes/REGISTER_PROPERTY_PRE_CLAIM_FAILURE";
export interface RegisterPropertyPreClaimFailureAction {
  type: typeof REGISTER_PROPERTY_PRE_CLAIM_FAILURE;
  error: Error | string;
}

export const SEARCH_CLAIM_HISTORY = "claimActionTypes/SEARCH_CLAIM_HISTORY";
export interface SearchClaimHistoryAction {
  type: typeof SEARCH_CLAIM_HISTORY;
  filter: ClaimFilter;
}

export const SEARCH_CLAIM_HISTORY_REQUEST = "claimActionTypes/SEARCH_CLAIM_HISTORY_REQUEST";
export interface SearchClaimHistoryRequestAction {
  type: typeof SEARCH_CLAIM_HISTORY_REQUEST;
}

export const SEARCH_CLAIM_HISTORY_SUCCESS = "claimActionTypes/SEARCH_CLAIM_HISTORY_SUCCESS";
export interface SearchClaimHistorySuccessAction {
  type: typeof SEARCH_CLAIM_HISTORY_SUCCESS;
  data: ClaimGridItem[];
  count: number;
}

export const SEARCH_CLAIM_HISTORY_RESET = "claimActionTypes/SEARCH_CLAIM_HISTORY_RESET";
export interface SearchClaimHistoryResetAction {
  type: typeof SEARCH_CLAIM_HISTORY_RESET;
}

export const SEARCH_CLAIM_HISTORY_FAILURE = "claimActionTypes/SEARCH_CLAIM_HISTORY_FAILURE";
export interface SearchClaimHistoryFailureAction {
  type: typeof SEARCH_CLAIM_HISTORY_FAILURE;
  error: Error | string;
}

export const DOWNLOAD_CLAIM_HISTORY_DOCUMENT = "claimActionTypes/DOWNLOAD_CLAIM_HISTORY_DOCUMENT";
export interface DownloadClaimHistoryDocumentAction {
  type: typeof DOWNLOAD_CLAIM_HISTORY_DOCUMENT;
  filter: ClaimFilter;
}

export const DOWNLOAD_CLAIM_HISTORY_DOCUMENT_REQUEST = "claimActionTypes/DOWNLOAD_CLAIM_HISTORY_DOCUMENT_REQUEST";
export interface DownloadClaimHistoryDocumentRequestAction {
  type: typeof DOWNLOAD_CLAIM_HISTORY_DOCUMENT_REQUEST;
}

export const DOWNLOAD_CLAIM_HISTORY_DOCUMENT_SUCCESS = "claimActionTypes/DOWNLOAD_CLAIM_HISTORY_DOCUMENT_SUCCESS";
export interface DownloadClaimHistoryDocumentSuccessAction {
  type: typeof DOWNLOAD_CLAIM_HISTORY_DOCUMENT_SUCCESS;
}

export const DOWNLOAD_CLAIM_HISTORY_DOCUMENT_RESET = "claimActionTypes/DOWNLOAD_CLAIM_HISTORY_DOCUMENT_RESET";
export interface DownloadClaimHistoryDocumentResetAction {
  type: typeof DOWNLOAD_CLAIM_HISTORY_DOCUMENT_RESET;
}

export const DOWNLOAD_CLAIM_HISTORY_DOCUMENT_FAILURE = "claimActionTypes/DOWNLOAD_CLAIM_HISTORY_DOCUMENT_FAILURE";
export interface DownloadClaimHistoryDocumentFailureAction {
  type: typeof DOWNLOAD_CLAIM_HISTORY_DOCUMENT_FAILURE;
  error: Error | string;
}

export const DOWNLOAD_BONUS_MALUS_DOCUMENT = "claimActionTypes/DOWNLOAD_BONUS_MALUS_DOCUMENT";
export interface DownloadBonusMalusDocumentAction {
  type: typeof DOWNLOAD_BONUS_MALUS_DOCUMENT;
  customerId: string;
  plateNumber: string;
  chassis: string;
}

export const DOWNLOAD_BONUS_MALUS_DOCUMENT_REQUEST = "claimActionTypes/DOWNLOAD_BONUS_MALUS_DOCUMENT_REQUEST";
export interface DownloadBonusMalusDocumentRequestAction {
  type: typeof DOWNLOAD_BONUS_MALUS_DOCUMENT_REQUEST;
}

export const DOWNLOAD_BONUS_MALUS_DOCUMENT_SUCCESS = "claimActionTypes/DOWNLOAD_BONUS_MALUS_DOCUMENT_SUCCESS";
export interface DownloadBonusMalusDocumentSuccessAction {
  type: typeof DOWNLOAD_BONUS_MALUS_DOCUMENT_SUCCESS;
}

export const DOWNLOAD_BONUS_MALUS_DOCUMENT_RESET = "claimActionTypes/DOWNLOAD_BONUS_MALUS_DOCUMENT_RESET";
export interface DownloadBonusMalusDocumentResetAction {
  type: typeof DOWNLOAD_BONUS_MALUS_DOCUMENT_RESET;
}

export const DOWNLOAD_BONUS_MALUS_DOCUMENT_FAILURE = "claimActionTypes/DOWNLOAD_BONUS_MALUS_DOCUMENT_FAILURE";
export interface DownloadBonusMalusDocumentFailureAction {
  type: typeof DOWNLOAD_BONUS_MALUS_DOCUMENT_FAILURE;
  error: Error | string;
}

export const SEND_CLAIM_HISTORY_MAIL = "claimActionTypes/SEND_CLAIM_HISTORY_MAIL";
export interface SendClaimHistoryMailAction {
  type: typeof SEND_CLAIM_HISTORY_MAIL;
  data: any;
}

export const SEND_CLAIM_HISTORY_MAIL_REQUEST = "claimActionTypes/SEND_CLAIM_HISTORY_MAIL_REQUEST";
export interface SendClaimHistoryMailRequestAction {
  type: typeof SEND_CLAIM_HISTORY_MAIL_REQUEST;
}

export const SEND_CLAIM_HISTORY_MAIL_SUCCESS = "claimActionTypes/SEND_CLAIM_HISTORY_MAIL_SUCCESS";
export interface SendClaimHistoryMailSuccessAction {
  type: typeof SEND_CLAIM_HISTORY_MAIL_SUCCESS;
}

export const SEND_CLAIM_HISTORY_MAIL_FAILURE = "claimActionTypes/SEND_CLAIM_HISTORY_MAIL_FAILURE";
export interface SendClaimHistoryMailFailureAction {
  type: typeof SEND_CLAIM_HISTORY_MAIL_FAILURE;
  error: Error | string;
}

export const EXPORT_SEARCH_PRECLAIM = "claimActionTypes/EXPORT_SEARCH_PRECLAIM";
export interface ExportSearchPreClaimAction {
  type: typeof EXPORT_SEARCH_PRECLAIM;
  filter: PreClaimFilter;
}

export const EXPORT_SEARCH_PRECLAIM_REQUEST = "claimActionTypes/EXPORT_SEARCH_PRECLAIM_REQUEST";
export interface ExportSearchPreClaimRequestAction {
  type: typeof EXPORT_SEARCH_PRECLAIM_REQUEST;
}

export const EXPORT_SEARCH_PRECLAIM_SUCCESS = "claimActionTypes/EXPORT_SEARCH_PRECLAIM_SUCCESS";
export interface ExportSearchPreClaimSuccessAction {
  type: typeof EXPORT_SEARCH_PRECLAIM_SUCCESS;
}

export const EXPORT_SEARCH_PRECLAIM_RESET = "claimActionTypes/EXPORT_SEARCH_PRECLAIM_RESET";
export interface ExportSearchPreClaimResetAction {
  type: typeof EXPORT_SEARCH_PRECLAIM_RESET;
}

export const EXPORT_SEARCH_PRECLAIM_FAILURE = "claimActionTypes/EXPORT_SEARCH_PRECLAIM_FAILURE";
export interface ExportSearchPreClaimFailureAction {
  type: typeof EXPORT_SEARCH_PRECLAIM_FAILURE;
  error: Error | string;
}

export const GET_INSURED_PERSON_BY_ID = "claimActionTypes/GET_INSURED_PERSON_BY_ID";
export interface GetInsuredPersonByIdAction {
  type: typeof GET_INSURED_PERSON_BY_ID;
  id: string;
}

export const GET_INSURED_PERSON_BY_ID_REQUEST = "claimActionTypes/GET_INSURED_PERSON_BY_ID_REQUEST";
export interface GetInsuredPersonByIdRequestAction {
  type: typeof GET_INSURED_PERSON_BY_ID_REQUEST;
}

export const GET_INSURED_PERSON_BY_ID_RESET = "claimActionTypes/GET_INSURED_PERSON_BY_ID_RESET";
export interface GetInsuredPersonByIdResetAction {
  type: typeof GET_INSURED_PERSON_BY_ID_RESET;
}

export const GET_INSURED_PERSON_BY_ID_SUCCESS = "claimActionTypes/GET_INSURED_PERSON_BY_ID_SUCCESS";
export interface GetInsuredPersonByIdSuccessAction {
  type: typeof GET_INSURED_PERSON_BY_ID_SUCCESS;
  data: PreClaimPerson;
}

export const GET_INSURED_PERSON_BY_ID_FAILURE = "claimActionTypes/GET_INSURED_PERSON_BY_ID_FAILURE";
export interface GetInsuredPersonByIdFailureAction {
  type: typeof GET_INSURED_PERSON_BY_ID_FAILURE;
  error: Error | string;
}

export const GET_DAMAGED_PERSON_BY_ID = "claimActionTypes/GET_DAMAGED_PERSON_BY_ID";
export interface GetDamagedPersonByIdAction {
  type: typeof GET_DAMAGED_PERSON_BY_ID;
  id: string;
}

export const GET_DAMAGED_PERSON_BY_ID_REQUEST = "claimActionTypes/GET_DAMAGED_PERSON_BY_ID_REQUEST";
export interface GetDamagedPersonByIdRequestAction {
  type: typeof GET_DAMAGED_PERSON_BY_ID_REQUEST;
}

export const GET_DAMAGED_PERSON_BY_ID_RESET = "claimActionTypes/GET_DAMAGED_PERSON_BY_ID_RESET";
export interface GetDamagedPersonByIdResetAction {
  type: typeof GET_DAMAGED_PERSON_BY_ID_RESET;
}

export const GET_DAMAGED_PERSON_BY_ID_SUCCESS = "claimActionTypes/GET_DAMAGED_PERSON_BY_ID_SUCCESS";
export interface GetDamagedPersonByIdSuccessAction {
  type: typeof GET_DAMAGED_PERSON_BY_ID_SUCCESS;
  data: PreClaimPerson;
}

export const GET_DAMAGED_PERSON_BY_ID_FAILURE = "claimActionTypes/GET_DAMAGED_PERSON_BY_ID_FAILURE";
export interface GetDamagedPersonByIdFailureAction {
  type: typeof GET_DAMAGED_PERSON_BY_ID_FAILURE;
  error: Error | string;
}

export const GET_INSURED_COMPANY_BY_ID = "claimActionTypes/GET_INSURED_COMPANY_BY_ID";
export interface GetInsuredCompanyByIdAction {
  type: typeof GET_INSURED_COMPANY_BY_ID;
  id: string;
}

export const GET_INSURED_COMPANY_BY_ID_REQUEST = "claimActionTypes/GET_INSURED_COMPANY_BY_ID_REQUEST";
export interface GetInsuredCompanyByIdRequestAction {
  type: typeof GET_INSURED_COMPANY_BY_ID_REQUEST;
}

export const GET_INSURED_COMPANY_BY_ID_RESET = "claimActionTypes/GET_INSURED_COMPANY_BY_ID_RESET";
export interface GetInsuredCompanyByIdResetAction {
  type: typeof GET_INSURED_COMPANY_BY_ID_RESET;
}

export const GET_INSURED_COMPANY_BY_ID_SUCCESS = "claimActionTypes/GET_INSURED_COMPANY_BY_ID_SUCCESS";
export interface GetInsuredCompanyByIdSuccessAction {
  type: typeof GET_INSURED_COMPANY_BY_ID_SUCCESS;
  data: PreClaimPerson;
}

export const GET_INSURED_COMPANY_BY_ID_FAILURE = "claimActionTypes/GET_INSURED_COMPANY_BY_ID_FAILURE";
export interface GetInsuredCompanyByIdFailureAction {
  type: typeof GET_INSURED_COMPANY_BY_ID_FAILURE;
  error: Error | string;
}

export const GET_DAMAGED_COMPANY_BY_ID = "claimActionTypes/GET_DAMAGED_COMPANY_BY_ID";
export interface GetDamagedCompanyByIdAction {
  type: typeof GET_DAMAGED_COMPANY_BY_ID;
  id: string;
}

export const GET_DAMAGED_COMPANY_BY_ID_REQUEST = "claimActionTypes/GET_DAMAGED_COMPANY_BY_ID_REQUEST";
export interface GetDamagedCompanyByIdRequestAction {
  type: typeof GET_DAMAGED_COMPANY_BY_ID_REQUEST;
}

export const GET_DAMAGED_COMPANY_BY_ID_RESET = "claimActionTypes/GET_DAMAGED_COMPANY_BY_ID_RESET";
export interface GetDamagedCompanyByIdResetAction {
  type: typeof GET_DAMAGED_COMPANY_BY_ID_RESET;
}

export const GET_DAMAGED_COMPANY_BY_ID_SUCCESS = "claimActionTypes/GET_DAMAGED_COMPANY_BY_ID_SUCCESS";
export interface GetDamagedCompanyByIdSuccessAction {
  type: typeof GET_DAMAGED_COMPANY_BY_ID_SUCCESS;
  data: PreClaimPerson;
}

export const GET_DAMAGED_COMPANY_BY_ID_FAILURE = "claimActionTypes/GET_DAMAGED_COMPANY_BY_ID_FAILURE";
export interface GetDamagedCompanyByIdFailureAction {
  type: typeof GET_DAMAGED_COMPANY_BY_ID_FAILURE;
  error: Error | string;
}

export const GET_VEHICLE_BY_PLATE_NUMBER = "claimActionTypes/GET_VEHICLE_BY_PLATE_NUMBER";
export interface GetVehicleByPlateNumberAction {
  type: typeof GET_VEHICLE_BY_PLATE_NUMBER;
  plateNumber: string;
}

export const GET_VEHICLE_BY_PLATE_NUMBER_REQUEST = "claimActionTypes/GET_VEHICLE_BY_PLATE_NUMBER_REQUEST";
export interface GetVehicleByPlateNumberRequestAction {
  type: typeof GET_VEHICLE_BY_PLATE_NUMBER_REQUEST;
}

export const GET_VEHICLE_BY_PLATE_NUMBER_RESET = "claimActionTypes/GET_VEHICLE_BY_PLATE_NUMBER_RESET";
export interface GetVehicleByPlateNumberResetAction {
  type: typeof GET_VEHICLE_BY_PLATE_NUMBER_RESET;
}

export const GET_VEHICLE_BY_PLATE_NUMBER_SUCCESS = "claimActionTypes/GET_VEHICLE_BY_PLATE_NUMBER_SUCCESS";
export interface GetVehicleByPlateNumberSuccessAction {
  type: typeof GET_VEHICLE_BY_PLATE_NUMBER_SUCCESS;
  data: PreClaimVehicle;
}

export const GET_VEHICLE_BY_PLATE_NUMBER_FAILURE = "claimActionTypes/GET_VEHICLE_BY_PLATE_NUMBER_FAILURE";
export interface GetVehicleByPlateNumberFailureAction {
  type: typeof GET_VEHICLE_BY_PLATE_NUMBER_FAILURE;
  error: Error | string;
}

export const GET_VEHICLE_BY_CHASSIS = "claimActionTypes/GET_VEHICLE_BY_CHASSIS";
export interface GetVehicleByChassisAction {
  type: typeof GET_VEHICLE_BY_CHASSIS;
  chassis: string;
}

export const GET_VEHICLE_BY_CHASSIS_REQUEST = "claimActionTypes/GET_VEHICLE_BY_CHASSIS_REQUEST";
export interface GetVehicleByChassisRequestAction {
  type: typeof GET_VEHICLE_BY_CHASSIS_REQUEST;
}

export const GET_VEHICLE_BY_CHASSIS_RESET = "claimActionTypes/GET_VEHICLE_BY_CHASSIS_RESET";
export interface GetVehicleByChassisResetAction {
  type: typeof GET_VEHICLE_BY_CHASSIS_RESET;
}

export const GET_VEHICLE_BY_CHASSIS_SUCCESS = "claimActionTypes/GET_VEHICLE_BY_CHASSIS_SUCCESS";
export interface GetVehicleByChassisSuccessAction {
  type: typeof GET_VEHICLE_BY_CHASSIS_SUCCESS;
  data: PreClaimVehicle;
}

export const GET_VEHICLE_BY_CHASSIS_FAILURE = "claimActionTypes/GET_VEHICLE_BY_CHASSIS_FAILURE";
export interface GetVehicleByChassisFailureAction {
  type: typeof GET_VEHICLE_BY_CHASSIS_FAILURE;
  error: Error | string;
}

export const FETCH_KASKO_DATA_FROM_POLICY = "claimActionTypes/FETCH_KASKO_DATA_FROM_POLICY";
export interface FetchKaskoDataFromPolicyAction {
  type: typeof FETCH_KASKO_DATA_FROM_POLICY;
  policyCode: string;
}

export const FETCH_KASKO_DATA_FROM_POLICY_REQUEST = "claimActionTypes/FETCH_KASKO_DATA_FROM_POLICY_REQUEST";
export interface FetchKaskoDataFromPolicyRequestAction {
  type: typeof FETCH_KASKO_DATA_FROM_POLICY_REQUEST;
}

export const FETCH_KASKO_DATA_FROM_POLICY_RESET = "claimActionTypes/FETCH_KASKO_DATA_FROM_POLICY_RESET";
export interface FetchKaskoDataFromPolicyResetAction {
  type: typeof FETCH_KASKO_DATA_FROM_POLICY_RESET;
}

export const FETCH_KASKO_DATA_FROM_POLICY_SUCCESS = "claimActionTypes/FETCH_KASKO_DATA_FROM_POLICY_SUCCESS";
export interface FetchKaskoDataFromPolicySuccessAction {
  type: typeof FETCH_KASKO_DATA_FROM_POLICY_SUCCESS;
  data: PreClaim;
}

export const FETCH_KASKO_DATA_FROM_POLICY_FAILURE = "claimActionTypes/FETCH_KASKO_DATA_FROM_POLICY_FAILURE";
export interface FetchKaskoDataFromPolicyFailureAction {
  type: typeof FETCH_KASKO_DATA_FROM_POLICY_FAILURE;
  error: Error | string;
}

export const DOWNLOAD_PREVIEW_CLAIM_DOCUMENT = "claimActionTypes/DOWNLOAD_PREVIEW_CLAIM_DOCUMENT";
export interface DownloadPreviewClaimDocumentAction {
  type: typeof DOWNLOAD_PREVIEW_CLAIM_DOCUMENT;
  claimId: string;
}

export const DOWNLOAD_PREVIEW_CLAIM_DOCUMENT_REQUEST = "claimActionTypes/DOWNLOAD_PREVIEW_CLAIM_DOCUMENT_REQUEST";
export interface DownloadPreviewClaimDocumentRequestAction {
  type: typeof DOWNLOAD_PREVIEW_CLAIM_DOCUMENT_REQUEST;
}

export const DOWNLOAD_PREVIEW_CLAIM_DOCUMENT_SUCCESS = "claimActionTypes/DOWNLOAD_PREVIEW_CLAIM_DOCUMENT_SUCCESS";
export interface DownloadPreviewClaimDocumentSuccessAction {
  type: typeof DOWNLOAD_PREVIEW_CLAIM_DOCUMENT_SUCCESS;
}

export const DOWNLOAD_PREVIEW_CLAIM_DOCUMENT_RESET = "claimActionTypes/DOWNLOAD_PREVIEW_CLAIM_DOCUMENT_RESET";
export interface DownloadPreviewClaimDocumentResetAction {
  type: typeof DOWNLOAD_PREVIEW_CLAIM_DOCUMENT_RESET;
}

export const DOWNLOAD_PREVIEW_CLAIM_DOCUMENT_FAILURE = "claimActionTypes/DOWNLOAD_PREVIEW_CLAIM_DOCUMENT_FAILURE";
export interface DownloadPreviewClaimDocumentFailureAction {
  type: typeof DOWNLOAD_PREVIEW_CLAIM_DOCUMENT_FAILURE;
  error: Error | string;
}

export type ClaimAction =
  RegisterPreClaimAction
  | RegisterPreClaimRequestAction
  | RegisterPreClaimSuccessAction
  | RegisterPreClaimFailureAction
  | PreClaimPreviewAction
  | PreClaimPreviewRequestAction
  | PreClaimPreviewSuccessAction
  | PreClaimPreviewFailureAction
  | PreClaimPreviewResetAction
  | SearchClaimAction
  | SearchClaimRequestAction
  | SearchClaimSuccessAction
  | SearchClaimResetAction
  | SearchClaimFailureAction
  | SearchPreClaimAction
  | SearchPreClaimRequestAction
  | SearchPreClaimSuccessAction
  | SearchPreClaimResetAction
  | SearchPreClaimFailureAction
  | ShowPreClaimModalAction
  | ShowClaimModalAction
  | FetchVehicleFromPolicyAction
  | FetchVehicleFromPolicyRequestAction
  | FetchVehicleFromPolicySuccessAction
  | FetchVehicleFromPolicyResetAction
  | FetchVehicleFromPolicyFailureAction
  | FetchInsuredPersonFromPolicyAction
  | FetchInsuredPersonFromPolicyRequestAction
  | FetchInsuredPersonFromPolicySuccessAction
  | FetchInsuredPersonFromPolicyResetAction
  | FetchInsuredPersonFromPolicyFailureAction
  | RegisterHealthPreClaimAction
  | RegisterHealthPreClaimRequestAction
  | RegisterHealthPreClaimSuccessAction
  | RegisterHealthPreClaimFailureAction
  | RegisterPropertyPreClaimAction
  | RegisterPropertyPreClaimRequestAction
  | RegisterPropertyPreClaimSuccessAction
  | RegisterPropertyPreClaimFailureAction
  | SearchClaimHistoryAction
  | SearchClaimHistoryRequestAction
  | SearchClaimHistorySuccessAction
  | SearchClaimHistoryResetAction
  | SearchClaimHistoryFailureAction
  | DownloadClaimHistoryDocumentAction
  | DownloadClaimHistoryDocumentRequestAction
  | DownloadClaimHistoryDocumentSuccessAction
  | DownloadClaimHistoryDocumentResetAction
  | DownloadClaimHistoryDocumentFailureAction
  | DownloadBonusMalusDocumentAction
  | DownloadBonusMalusDocumentRequestAction
  | DownloadBonusMalusDocumentSuccessAction
  | DownloadBonusMalusDocumentResetAction
  | DownloadBonusMalusDocumentFailureAction
  | SendClaimHistoryMailAction
  | SendClaimHistoryMailRequestAction
  | SendClaimHistoryMailSuccessAction
  | SendClaimHistoryMailFailureAction
  | ExportSearchPreClaimAction
  | ExportSearchPreClaimRequestAction
  | ExportSearchPreClaimSuccessAction
  | ExportSearchPreClaimResetAction
  | ExportSearchPreClaimFailureAction
  | GetInsuredPersonByIdAction
  | GetInsuredPersonByIdRequestAction
  | GetInsuredPersonByIdSuccessAction
  | GetInsuredPersonByIdResetAction
  | GetInsuredPersonByIdFailureAction
  | GetDamagedPersonByIdAction
  | GetDamagedPersonByIdRequestAction
  | GetDamagedPersonByIdSuccessAction
  | GetDamagedPersonByIdResetAction
  | GetDamagedPersonByIdFailureAction
  | GetInsuredCompanyByIdAction
  | GetInsuredCompanyByIdRequestAction
  | GetInsuredCompanyByIdSuccessAction
  | GetInsuredCompanyByIdResetAction
  | GetInsuredCompanyByIdFailureAction
  | GetDamagedCompanyByIdAction
  | GetDamagedCompanyByIdRequestAction
  | GetDamagedCompanyByIdSuccessAction
  | GetDamagedCompanyByIdResetAction
  | GetDamagedCompanyByIdFailureAction
  | GetVehicleByPlateNumberAction
  | GetVehicleByPlateNumberRequestAction
  | GetVehicleByPlateNumberSuccessAction
  | GetVehicleByPlateNumberResetAction
  | GetVehicleByPlateNumberFailureAction
  | GetVehicleByChassisAction
  | GetVehicleByChassisRequestAction
  | GetVehicleByChassisSuccessAction
  | GetVehicleByChassisResetAction
  | GetVehicleByChassisFailureAction
  | FetchKaskoDataFromPolicyAction
  | FetchKaskoDataFromPolicyRequestAction
  | FetchKaskoDataFromPolicySuccessAction
  | FetchKaskoDataFromPolicyResetAction
  | FetchKaskoDataFromPolicyFailureAction
  | ClaimPreviewAction
  | ClaimPreviewRequestAction
  | ClaimPreviewSuccessAction
  | ClaimPreviewFailureAction
  | ClaimPreviewResetAction
  | DownloadPreviewClaimDocumentAction
  | DownloadPreviewClaimDocumentRequestAction
  | DownloadPreviewClaimDocumentSuccessAction
  | DownloadPreviewClaimDocumentResetAction
  | DownloadPreviewClaimDocumentFailureAction;
 