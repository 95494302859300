import { User, Customer } from './../types';
import * as actions from "../actionTypes/authActionTypes";
import { CheckProfileDto } from '../../utils';

export function setUser(user: User): actions.SetUserAction {
  return {
    type: actions.SET_USER,
    user
  };
}

export function loginUser(
  email: string,
  password: string,
  skipRedirect: boolean
): actions.LoginUserAction {
  return {
    type: actions.LOGIN_USER,
    email,
    password,
    skipRedirect
  };
}

export function loginUserRequest(): actions.LoginUserRequestAction {
  return {
    type: actions.LOGIN_USER_REQUEST
  };
}

export function loginUserSuccess(
  user: User
): actions.LoginUserSuccessAction {
  return {
    type: actions.LOGIN_USER_SUCCESS,
    user
  };
}

export function loginUserFailure(
  error: Error | string
): actions.LoginUserFailureAction {
  return {
    type: actions.LOGIN_USER_FAILURE,
    error
  };
}

export function resendMail(
  resend: boolean
): actions.ResendMailAction {
  return {
    type: actions.RESEND_MAIL,
    resend
  };
}

export function logoutUser(
  token: string
): actions.LogoutUserAction {
  return {
    type: actions.LOGOUT_USER,
    token
  };
}

export function logoutUserRequest(
): actions.LogoutUserRequestAction {
  return {
    type: actions.LOGOUT_USER_REQUEST,
  };
}

export function logoutUserSuccess(
): actions.LogoutUserSuccessAction {
  return {
    type: actions.LOGOUT_USER_SUCCESS,
  };
}

export function logoutUserFailure(
  error: Error | string
): actions.LogoutUserFailureAction {
  return {
    type: actions.LOGOUT_USER_FAILURE,
    error
  };
}

export function registerUser(
  tipKind: string,
  clientno: string,
  nipt: string,
  title: string,
  lastname: string,
  sex: string,
  email: string,
  mob: string,
  address: string,
  place: string,
  birthdate: string,
  password: string
): actions.RegisterUserAction {
  return {
    type: actions.REGISTER_USER,
    tipKind, clientno, nipt, title, lastname, sex, email, mob, address, place, birthdate, password
  };
}

export function registerUserRequest(): actions.RegisterUserRequestAction {
  return {
    type: actions.REGISTER_USER_REQUEST
  };
}

export function registerUserSuccess(
): actions.RegisterUserSuccessAction {
  return {
    type: actions.REGISTER_USER_SUCCESS,
  };
}

export function registerUserFailure(
  error: Error | string
): actions.RegisterUserFailureAction {
  return {
    type: actions.REGISTER_USER_FAILURE,
    error
  };
}

export function requestPassword(
  email: string
): actions.RequestPasswordAction {
  return {
    type: actions.REQUEST_PASSWORD,
    email
  };
}

export function requestPasswordRequest(): actions.RequestPasswordRequestAction {
  return {
    type: actions.REQUEST_PASSWORD_REQUEST
  };
}

export function requestPasswordSuccess(
): actions.RequestPasswordSuccessAction {
  return {
    type: actions.REQUEST_PASSWORD_SUCCESS,
  };
}

export function requestPasswordFailure(
  error: Error | string
): actions.RequestPasswordFailureAction {
  return {
    type: actions.REQUEST_PASSWORD_FAILURE,
    error
  };
}

export function updateCustomer(
  customer: Customer
): actions.UpdateCustomerAction {
  return {
    type: actions.UPDATE_CUSTOMER,
    customer
  };
}

export function updateCustomerRequest(): actions.UpdateCustomerRequestAction {
  return {
    type: actions.UPDATE_CUSTOMER_REQUEST
  };
}

export function updateCustomerSuccess(
): actions.UpdateCustomerSuccessAction {
  return {
    type: actions.UPDATE_CUSTOMER_SUCCESS,
  };
}

export function updateCustomerFailure(
  error: Error | string
): actions.UpdateCustomerFailureAction {
  return {
    type: actions.UPDATE_CUSTOMER_FAILURE,
    error
  };
}

export function changePassword(
  data: any
): actions.ChangePasswordAction {
  return {
    type: actions.CHANGE_PASSWORD,
    data
  };
}

export function changePasswordRequest(): actions.ChangePasswordRequestAction {
  return {
    type: actions.CHANGE_PASSWORD_REQUEST
  };
}

export function changePasswordSuccess(
): actions.ChangePasswordSuccessAction {
  return {
    type: actions.CHANGE_PASSWORD_SUCCESS,
  };
}

export function changePasswordFailure(
  error: Error | string
): actions.ChangePasswordFailureAction {
  return {
    type: actions.CHANGE_PASSWORD_FAILURE,
    error
  };
}

export function getCustomerRequest(
): actions.GetCustomerRequestAction {
  return {
    type: actions.GET_CUSTOMER_REQUEST
  };
}

export function getCustomer(
  customerId: string
): actions.GetCustomerAction {
  return {
    type: actions.GET_CUSTOMER,
    customerId
  };
}

export function getCustomerSuccess(
  data: Customer
): actions.GetCustomerSuccessAction {
  return {
    type: actions.GET_CUSTOMER_SUCCESS,
    data: data
  };
}

export function getCustomerFailure(
  error: Error | string
): actions.GetCustomerFailureAction {
  return {
    type: actions.GET_CUSTOMER_FAILURE,
    error
  };
}

export function checkActiveSession(
  token: string,
  customerId: string
): actions.CheckActiveSessionAction {
  return {
    type: actions.CHECK_ACTIVE_SESSION,
    token,
    customerId
  };
}

export function checkActiveSessionRequest(
): actions.CheckActiveSessionRequestAction {
  return {
    type: actions.CHECK_ACTIVE_SESSION_REQUEST
  };
}

export function checkActiveSessionSuccess(
  authenticated: boolean
): actions.CheckActiveSessionSuccessAction {
  return {
    type: actions.CHECK_ACTIVE_SESSION_SUCCESS,
    authenticated: authenticated
  };
}

export function checkActiveSessionFailure(
  error: Error | string
): actions.CheckActiveSessionFailureAction {
  return {
    type: actions.CHECK_ACTIVE_SESSION_FAILURE,
    error
  };
}

export function hasProfile(
  email: string
): actions.HasProfileAction {
  return {
    type: actions.HAS_PROFILE,
    email
  };
}

export function hasProfileRequest(
): actions.HasProfileRequestAction {
  return {
    type: actions.HAS_PROFILE_REQUEST
  };
}

export function hasProfileReset(
): actions.HasProfileResetAction {
  return {
    type: actions.HAS_PROFILE_RESET
  };
}

export function hasProfileSuccess(
  checkProfile: CheckProfileDto
): actions.HasProfileSuccessAction {
  return {
    type: actions.HAS_PROFILE_SUCCESS,
    checkProfile: checkProfile
  };
}

export function hasProfileFailure(
  error: Error | string
): actions.HasProfileFailureAction {
  return {
    type: actions.HAS_PROFILE_FAILURE,
    error
  };
}

export function confirmVerificationCode(
  verificationCode: string,
  email: string
): actions.ConfirmVerificationCodeAction {
  return {
    type: actions.CONFIRM_VERIFICATION_CODE,
    verificationCode,
    email
  };
}

export function confirmVerificationCodeRequest(
): actions.ConfirmVerificationCodeRequestAction {
  return {
    type: actions.CONFIRM_VERIFICATION_CODE_REQUEST
  };
}

export function confirmVerificationCodeReset(
): actions.ConfirmVerificationCodeResetAction {
  return {
    type: actions.CONFIRM_VERIFICATION_CODE_RESET
  };
}

export function confirmVerificationCodeSuccess(
): actions.ConfirmVerificationCodeSuccessAction {
  return {
    type: actions.CONFIRM_VERIFICATION_CODE_SUCCESS
  };
}

export function confirmVerificationCodeFailure(
  error: Error | string
): actions.ConfirmVerificationCodeFailureAction {
  return {
    type: actions.CONFIRM_VERIFICATION_CODE_FAILURE,
    error
  };
}

export function resendVerificationCode(
  email: string
): actions.ResendVerificationCodeAction {
  return {
    type: actions.RESEND_VERIFICATION_CODE,
    email
  };
}

export function resendVerificationCodeRequest(
): actions.ResendVerificationCodeRequestAction {
  return {
    type: actions.RESEND_VERIFICATION_CODE_REQUEST
  };
}

export function resendVerificationCodeReset(
): actions.ResendVerificationCodeResetAction {
  return {
    type: actions.RESEND_VERIFICATION_CODE_RESET
  };
}

export function resendVerificationCodeSuccess(
): actions.ResendVerificationCodeSuccessAction {
  return {
    type: actions.RESEND_VERIFICATION_CODE_SUCCESS
  };
}

export function resendVerificationCodeFailure(
  error: Error | string
): actions.ResendVerificationCodeFailureAction {
  return {
    type: actions.RESEND_VERIFICATION_CODE_FAILURE,
    error
  };
}

export function autoRegisterUser(
  tipKind: string,
  clientno: string,
  nipt: string,
  title: string,
  lastname: string,
  sex: string,
  email: string,
  mob: string,
  address: string,
  place: string,
  birthdate: string,
  password: string,
): actions.AutoRegisterUserAction {
  return {
    type: actions.AUTO_REGISTER_USER,
    tipKind, clientno, nipt, title, lastname, sex, email, mob, address, place, birthdate, password
  };
}

export function autoRegisterUserRequest(): actions.AutoRegisterUserRequestAction {
  return {
    type: actions.AUTO_REGISTER_USER_REQUEST
  };
}

export function autoRegisterUserReset(
): actions.AutoRegisterUserResetAction {
  return {
    type: actions.AUTO_REGISTER_USER_RESET,
  };
}

export function autoRegisterUserSuccess(
): actions.AutoRegisterUserSuccessAction {
  return {
    type: actions.AUTO_REGISTER_USER_SUCCESS
  };
}

export function autoRegisterUserFailure(
  error: Error | string
): actions.AutoRegisterUserFailureAction {
  return {
    type: actions.AUTO_REGISTER_USER_FAILURE,
    error
  };
}

export function autoLoginUser(
  email: string
): actions.AutoLoginUserAction {
  return {
    type: actions.AUTO_LOGIN_USER,
    email
  };
}

export function autoLoginUserRequest(
): actions.AutoLoginUserRequestAction {
  return {
    type: actions.AUTO_LOGIN_USER_REQUEST
  };
}

export function autoLoginUserReset(
): actions.AutoLoginUserResetAction {
  return {
    type: actions.AUTO_LOGIN_USER_RESET
  };
}

export function autoLoginUserSuccess(
  user: User
): actions.AutoLoginUserSuccessAction {
  return {
    type: actions.AUTO_LOGIN_USER_SUCCESS,
    user
  };
}

export function autoLoginUserFailure(
  error: Error | string
): actions.AutoLoginUserFailureAction {
  return {
    type: actions.AUTO_LOGIN_USER_FAILURE,
    error
  };
}