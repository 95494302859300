import ApiClient from '../../infrastructure/ApiClient';
import { PreClaimPerson, PreClaimRegisterData } from '../../utils/claimTypes';
import { HealthClaimGridItem, HealthClaimPreview } from '../../utils/healthPreClaimTypes';

export async function checkPolicy(
  policyCode: any
): Promise<any> {
  return await ApiClient.get('/api/healthclaim/CheckPolicy', { policyCode: policyCode });
}

export async function registerHealthPreClaim(
  formData: FormData
): Promise<PreClaimRegisterData> {
  return await ApiClient.post('/api/healthclaim/RegisterHealthPreClaim', formData);
}

export async function searchHealthClaim(
  data: any
): Promise<HealthClaimGridItem[]> {
  return await ApiClient.post('/api/healthclaim/SearchHealthClaim', data);
}

export async function healthClaimPreview(
  code: string
): Promise<HealthClaimPreview> {
  return await ApiClient.get('/api/healthclaim/PreviewHealthClaim', { code });
}

export async function exportSearchHealthClaim(
  data: any
): Promise<any> {
  return await ApiClient.post('/api/healthclaim/ExportHealthClaim', data, true);
}

export async function validateHealthClaimPolicy(
  accidentdate: string,
  customerId: string,
  policy: string
): Promise<PreClaimPerson> {
  return await ApiClient.get('/api/healthclaim/ValidateHealthClaimPolicy', { accidentdate, customerId, policy });
}

export async function validateHealthClaimInsured(
  accidentdate: string,
  customerId: string,
  insurePersonId: string
): Promise<PreClaimPerson> {
  return await ApiClient.get('/api/healthclaim/ValidateHealthClaimInsured', { accidentdate, customerId, insurePersonId });
}

export async function fileFirstLevelAppeal(
  data: any
): Promise<any[]> {
  return await ApiClient.post('/api/healthclaim/FileFirstLevelAppeal', data);
}

export async function fileSecondLevelAppeal(
  data: any
): Promise<any[]> {
  return await ApiClient.post('/api/healthclaim/FileSecondLevelAppeal', data);
}

export async function markAppealAsRead(
  claimId: string
): Promise<HealthClaimPreview> {
  return await ApiClient.get('/api/healthclaim/MarkAppealAsRead', { claimId });
}

export async function printFirstLevelAppeal(
  claimId: string,
): Promise<any> {
  return await ApiClient.get('/api/healthclaim/PrintFirstLevelAppeal', { claimId }, true);
}

export async function printSecondLevelAppeal(
  claimId: string,
): Promise<any> {
  return await ApiClient.get('/api/healthclaim/PrintSecondLevelAppeal', { claimId }, true);
}

export async function downloadHealthClaimFile(
  claimId: string,
): Promise<any> {
  return await ApiClient.get('/api/healthclaim/DownloadFile', { claimId }, true);
}

export async function getDocumentList(
  claimId: string,
): Promise<string> {
  return await ApiClient.get('/api/healthclaim/GetDocumentList', { claimId });
}

export async function uploadHealthClaimFile(
  data: FormData
): Promise<boolean> {
  return await ApiClient.post('/api/healthclaim/UploadClaimFile', data);
}