import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import InputFieldMain from '../../Custom/InputFieldMain';
import { AppState } from '../../../redux/reducers/rootReducer';
import { useFormikContext } from 'formik';
import SelectField from '../../Custom/SelectField';
import CheckboxField from '../../Custom/CheckboxField';
import DatePickerFieldMain from '../../Custom/DatePickerFieldMain';
import { Nomen } from '../../../utils';

const ThInsMain = () => {
    const { values }: any = useFormikContext();
    const zones = useSelector<AppState, Nomen[]>((state: any) => state.nomen.THZones);
    const currentPath = useSelector<AppState, string>((state: any) => state.router.location.pathname);

    let tomorrow = new Date();
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow.setDate(tomorrow.getDate() + 1);

    let startDate = values?.startDate !== '' ? new Date(values?.startDate) : tomorrow;
    let endDate = new Date(startDate);
    if (values.insuredNumDays !== '') endDate.setDate(startDate.getDate() + Number(values.insuredNumDays) - 1);

    const showIsOld = !currentPath.includes("student");

    return (<div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
        <div className="mb-10 font-weight-bold text-dark"></div>
        <div className="row">
            <div className="col-xl-6">
                <DatePickerFieldMain name="startDate" type="text" newvalue={startDate} placeholder="Fillon" hideHelper />
            </div>
            <div className="col-xl-6">
                <DatePickerFieldMain name="endDate" type="text" disabled newvalue={new Date(endDate)} placeholder="Mbaron" hideHelper />
            </div>
        </div>
        <div className="row empty-row">
        </div>
        <div className="row empty-row">
        </div>
        <div className="row empty-row">
        </div>
        <div className="row">
            <div className="col-xl-6">
                <InputFieldMain type="text" name="insuredNumDays" label="Nr. i ditëve të siguruara" placeholder="Nr. i ditëve të siguruara" />
            </div>
            {showIsOld && <div className="col-xl-6 checkboxCtrl">
                <CheckboxField name="isOld" type="checkbox" label="I siguruari është më i vjetër se 65 vjeç?" />
            </div>}
        </div>
        <div className="row">
            <div className="col-xl-6">
                <SelectField name="zone" label="Zona e udhëtimit" items={zones} />
            </div>
            <div className="col-xl-6">
                <InputFieldMain type="text" name="territory" label="Vendi i udhëtimit" placeholder="Vendi i udhëtimit" />
            </div>
        </div>
    </div>
    );
}
export default connect()(ThInsMain);