import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import './_metronic/_assets/plugins/keenthemes-icons/font/ki.css';
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
import 'react-datepicker/dist/react-datepicker.css';
import registerServiceWorker from './registerServiceWorker';
// import {
//     MetronicLayoutProvider
// } from "./_metronic/layout/_core/Layout";
// import {
//     MetronicSubheaderProvider
// } from "./_metronic/layout/_core/Subheader";
import {
    MetronicLayoutProvider,
    MetronicSplashScreenProvider,
    MetronicSubheaderProvider
  } from "./_metronic/layout";

ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
    <MetronicLayoutProvider>
        <MetronicSubheaderProvider>
            <App />
        </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
    ,
    document.getElementById("root")
);
registerServiceWorker();
