import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
import { AnimateLoading } from "../../../_partials/controls";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";

export function HeaderTop() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      )
    };
  }, [uiService]);

  return (
    <>
      {/*begin::Header*/}
      <div className="header-top">
        <div className="container">
          <div className="d-none d-lg-flex align-items-center mr-3">
            {/* <!--begin::Logo-->
            <div href="index.html" class="mr-10">
              <img alt="Logo" src="assets/media/logos/logo-letter-5.png" class="max-h-35px" />
            </a>
            <!--end::Logo--> */}
          </div>

          <Topbar />
        </div>
      </div>
      {/*end::Header*/}
    </>
  );
}
