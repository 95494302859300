import * as React from 'react';
import { connect } from 'react-redux';
import InputFieldMain from '../../../Custom/InputFieldMain';
import RadioButtonGroupFieldMain from '../../../Custom/RadioButtonGroupFieldMain';
import { useFormikContext } from 'formik';
import CheckboxField from "../../../Custom/CheckboxField";
import PersonalDataConsent from "../../../../../src/InsuranceConditions/Pelqimi per GDPR.pdf";

const TermLifePolicyMain = () => {
    const { values, setValues }: any = useFormikContext();
    const showCoupon = values.couponAnswer && values.couponAnswer === '1';

    const pixelScript = `
    !function(f,b,e,v,n,t,s){
        if(f.fbq)return;n=f.fbq=function(){
            n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)
        };
        if(!f._fbq) f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)
    }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');

    fbq('init', '2016267805471451');
    fbq('track', 'PageView');    `;

React.useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = pixelScript;
    document.body.appendChild(script);
    
    return () => {
        document.body.removeChild(script);
    };
}, []);

    const couponChanged = (value: any) => {
        if (value === '1')
            return;

        var currentValues = { ...values };
        currentValues.coupon = '';
        setValues(currentValues);
    }

    return (
        <>
            <div className="wizardTitle">Informata personale</div>
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                <div className="mb-10 font-weight-bold text-dark"></div>
                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <InputFieldMain type="text" name="insuredAge" label="Mosha juaj" placeholder="Mosha juaj" inline />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <label className="inlineLbl">1. Keni probleme shëndetësore?</label>
                        <RadioButtonGroupFieldMain name="firstAnswer" type="radio" placeholder="" label="" items={[{ id: '1', value: 'Po' }, { id: '2', value: 'Jo' }]} newvalue={values?.firstAnswer || ''} inline />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <label className="inlineLbl">2. A vuani nga ndonjë sëmundje kronike?</label>
                        <RadioButtonGroupFieldMain name="secondAnswer" type="radio" placeholder="" label="" items={[{ id: '1', value: 'Po' }, { id: '2', value: 'Jo' }]} newvalue={values?.secondAnswer || ''} inline />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <label className="inlineLbl">3. A jeni trajtuar në ndonjë skemë paaftësie të pjesshme ose të plotë?</label>
                        <RadioButtonGroupFieldMain name="thirdAnswer" type="radio" placeholder="" label="" items={[{ id: '1', value: 'Po' }, { id: '2', value: 'Jo' }]} newvalue={values?.thirdAnswer || ''} inline />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <label className="inlineLbl">A keni një kupon?</label>
                        <RadioButtonGroupFieldMain change={couponChanged} name="couponAnswer" type="radio" placeholder="" label="" items={[{ id: '1', value: 'Po' }, { id: '2', value: 'Jo' }]} newvalue={values?.couponAnswer || ''} inline />
                    </div>
                </div>
                {showCoupon && <div className="row">
                    <div className="col-xl-12 addSpace">
                        <InputFieldMain type="text" name="coupon" label="Kupon" placeholder="Kupon" inline maxLength={30} />
                    </div>
                </div>}

                <hr />
                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <CheckboxField name="personalDataConsent" type="checkbox" label="Konfirmoj se kam lexuar dhe jam pajtuar e përcaktimet, kushtet, autorizimin dhe jap pëlqimin për marrjen dhe mbrojtjen e të dhënave personale" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <a href={PersonalDataConsent} target="_blank">Politika e privatesisë Sigal Uniqa Group Austria Kosovë</a>
                    </div>
                </div>
            </div>
        <noscript>
                <img height="1" width="1" style={{ display: 'none' }} src="https://www.facebook.com/tr?id=2016267805471451&ev=PageView&noscript=1" />
        </noscript>
        </>
    );
}
export default connect()(TermLifePolicyMain);
