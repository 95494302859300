import { Person, Vehicle } from './policyTypes';
import { Customer } from './../redux/types';
import moment from 'moment';

export interface Nomen {
  code: string;
  name: string;
};

export const prepareCustomer = (values: any, operator: string, customerId: string) => {
  let customer: Customer = {
    tipkind: values.account.tipKind,
    nipt: values.account.nipt,
    clientno: values.account.clientno,
    title: values.account.title,
    lastname: values.account.lastName,
    mob: values.contact.mob,
    sex: values.account.sex,
    email: values.contact.email,
    address: values.contact.address,
    place: values.contact.place,
    birthdate: values.account.birthdate !== "" ? moment(values.account.birthdate).format('YYYY-MM-DD') : null,
    id: customerId,
    operatorId: operator
  }

  return customer;
}

export const prepareFilter = (values: any, customerId: string) => {
  let filter: Filter = {
    customerId: customerId,
    pageSize: values.pageSize,
    pageCount: values.pageCount,
    policyStatusId: values.policyStatusId,
    policyType: values.policyType,
    shownum: values.shownum,
    dog: values.dog,
    osi: values.osi,
    regnum: values.regnum,
    chassis: values.chassis,
    datesaleFrom: values.datesaleFrom !== "" && !!values.datesaleFrom ? moment(values.datesaleFrom).format('YYYY-MM-DD') : null,
    datesaleTo: values.datesaleTo !== "" && !!values.datesaleTo ? moment(values.datesaleTo).format('YYYY-MM-DD') : null,
    fvalidFrom: values.fValidFrom !== "" && !!values.fValidFrom ? moment(values.fValidFrom).format('YYYY-MM-DD') : null,
    fvalidTo: values.fValidTo !== "" && !!values.fValidTo ? moment(values.fValidTo).format('YYYY-MM-DD') : null,
    tvalidFrom: values.tValidFrom !== "" && !!values.tValidFrom ? moment(values.tValidFrom).format('YYYY-MM-DD') : null,
    tvalidTo: values.tValidTo !== "" && !!values.tValidTo ? moment(values.tValidTo).format('YYYY-MM-DD') : null,
    customerIsInsuredOrContractor: values.customerIsInsuredOrContractor
  }

  return filter;
}

export interface Filter {
  customerId: string;
  pageSize: number;
  pageCount: number;
  policyStatusId: string;
  policyType: string;
  shownum: string;
  dog: string;
  osi: string;
  regnum: string;
  chassis: string;
  datesaleFrom: string | null;
  datesaleTo: string | null;
  fvalidFrom: string | null;
  fvalidTo: string | null;
  tvalidFrom: string | null;
  tvalidTo: string | null;
  customerIsInsuredOrContractor: boolean;
}

export interface PolicyGridItem {
  policyId: string;
  datesale: string;
  fvalid: string;
  tvalid: string;
  policyType: string;
  shownum: string;
  dog: string;
  osi: string;
  regnum: string;
  chassis: string;
  policyStatus: string;
  valuta: string;
  totalV: string;
  packageName: string;
  coupon: string;
}

export interface PolicyPreview {
  datesale: string;
  fvalid: string;
  tvalid: string;
  policyType: string;
  shownum: string;
  insured: Person[];
  contractor: Person;
  vehicle: Vehicle;
  policyStatus: string;
  valuta: string;
  totalV: string;
  totalV1: string;
  totalV2: string;
  paytype: string;
  amfnumber: string;
  zone: string;
  teritory: string;
  insdays: number;
  period: string;
  category: string;
  packageName: string;
  coupon: string;
}

export interface CheckProfileDto {
  email: string;
  hasProfile: boolean;
  confirmedMail: boolean;
}

export interface AppInfo {
  saleMenus: SaleMenus;
  version: string;
}

export interface SaleMenus {
  enableSale04: boolean;
  enableSale05: boolean;
  enableSale09: boolean;
  enableSale34: boolean;
  enableSale61: boolean;
  enableSearchPolicy: boolean;
}

export const PaymentError: { [key: string]: string } = {
  "105": "The transaction is not allowed by the issuing bank",
  "116": "Insufficient funds",
  "111": "Non-existent card",
  "108": "Lost or stolen card",
  "101": "Invalid card expiration date",
  "130": "Exceeded the allowable spending limit",
  "290": "Issuer is not accessible",
  "291": "Technical or communication problem",
  "401": "Format errors",
  "402": "Errors in Acquirer / Merchant parameters",
  "403": "Errors when connecting to a payment system resource (DS)",
  "404": "Buyer authentication error",
  "405": "Signature error",
  "406": "Allowed transactions quota exceeded",
  "407": "The merchant is disconnected from the gateway",
  "408": "Transaction not found",
  "409": "Multiple transactions found",
  "410": "The order has already been successfully paid",
  "411": "Incorrect time in the request",
  "412": "Order parameters have already been received earlier",
  "420": "Exceeded daily transaction limit",
  "421": "The maximum allowed transaction amount has been exceeded",
  "430": "Transaction prohibited at the payment gateway level",
  "431": "Transaction is not allowed without full 3-D Secure authentication",
  "454": "The gateway is prohibited from using Ref3",
  "455": "Refund request denied by gateway",
  "501": "Transaction canceled by user",
  "502": "Browser session is deprecated",
  "503": "The transaction was canceled by the store",
  "504": "Transaction canceled by the gateway",
  "601": "Transaction not completed",
  "701": "Restricted by payment host/schema",
  "902": "Unable to process transaction",
  "909": "Unable to process transaction",
  "999": "Transaction in progress"
}