import { object, string, mixed, StringSchema, number, date } from 'yup';
import { FormikWizardStepType } from '../../../utils/wizardTypes';
import PreClaimMain from './PreClaimMain';
import DamagedVehicle from './DamagedVehicle';
import InsuredVehicle from './InsuredVehicle';
import DamagedPerson from './DamagedPerson';
import InsuredPerson from './InsuredPerson';
import UploadDocuments from './UploadDocuments';

import moment from 'moment';

export default [
    {
        id: 'claim',
        component: PreClaimMain,
        name: 'Detajet e Dëmtimit / Shërbimit',
        description: '',
        initialValues: {
            type_policy: '04',
            policy_code: '',
            date_accident: '',
            hour_accident: '00:00',
            place_accident: '',
            nature: '',
            description_accident: '',
            description_damage: '',
            policyreportnr: '',
            europeanreportnr: '',
            covercategory:''
        },
        validationSchema: object().shape({
            type_policy: string().required('E domosdoshme'),
            policy_code: string().required('E domosdoshme'),
            date_accident: date().required('E domosdoshme').typeError('E domosdoshme')
                .test(
                    'validDateAccident',
                    'Date accident cannot be after today',
                    function (this: any, value: any) {
                        return moment(value, 'dd/MM/yyyy').isBefore(moment());
                    }),
            hour_accident: string()
                .test(
                    'validTime',
                    'Time is not valid',
                    function (this: any, value: any) {
                        return moment(value, 'HH:mm').isValid();
                    }),
            place_accident: string().required('E domosdoshme'),
            nature: string().required('E domosdoshme'),
            description_accident: string().required('E domosdoshme'),
            description_damage: string().required('E domosdoshme'),
            policyreportnr: string(),
            europeanreportnr: string(),
            covercategory: string().required('E domosdoshme'),
        }),
        actionLabel: 'Vazhdo',
        onAction: () => { }
    },
    {
        id: 'damagedPerson',
        component: DamagedPerson,
        name: 'I Dëmtuari / Marrësi i Shërbimit',
        description: '',
        initialValues: {
            tipKind: '3',
            nipt: '',
            clientNo: '',
            title: '',
            lastName: '',
            birthdate: '',
            email: '',
            celphone: '',
            address: '',
            place: '',
            drivingLicenceCat: '',
            drivingLicenceNo: '',
            sex: '',
            driverLicenseIssued: '',
        },
        validationSchema: object().shape({
            tipKind: string().required('E domosdoshme'),
            clientNo: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme').max(16, "Maximum 16 symbols") : schema.notRequired();
            }),
            nipt: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '2' ? schema.required('E domosdoshme').max(16, "Maximum 16 symbols") : schema.notRequired();
            }),
            title: string()
                .min(3, "Minimumi 3 simbole")
                .max(50, "Maksimumi 50 simbole")
                .required("E domosdoshme"),
            lastName: string()
                .when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                    return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
                }),
            birthdate: mixed()
                .test(
                    'validBirthDate',
                    'Data e lindjes nuk mund te jete pas dates se sotshme',
                    function (this: any, value: any) {
                        if (moment(value, 'dd/MM/yyyy').isValid())
                            return moment(value, 'dd/MM/yyyy').isBefore(moment());
                        return true;
                    }),
            email: string().email().required('E domosdoshme'),
            celphone: string().required('E domosdoshme'),
            address: string(),
            place: string(),
            drivingLicenceCat: string(),
            drivingLicenceNo: string(),
            sex: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            driverLicenseIssued: mixed()
                .test(
                    'validDriverLicenseIssued',
                    'Date Driver License Issued cannot be after today',
                    function (this: any, value: any) {
                        if (moment(value, 'dd/MM/yyyy').isValid())
                            return moment(value, 'dd/MM/yyyy').isBefore(moment());
                        return true;
                    }),
        }),
        actionLabel: 'Vazhdo'
    },
    {
        id: 'damagedVehicle',
        component: DamagedVehicle,
        name: 'I Dëmtuari - Automjeti',
        description: '',
        initialValues: {
            regnum: '',
            chassis: '',
            type_vehicle: '',
            mark: '',
            tip: '',
            prodyear: '',
            placenr: '',
            colour: '',
            capacity: '',
            power: '',
            volume: '',
            used: '',
            vehiclelicence: '',
            damaged_parts: ''
        },
        validationSchema: object().shape({
            regnum: string().required('E domosdoshme'),
            chassis: string().required('E domosdoshme'),
            type_vehicle: string().required('E domosdoshme'),
            mark: string().required('E domosdoshme'),
            tip: string().required('E domosdoshme'),
            prodyear: number().required('E domosdoshme').test(
                'validYear',
                'Production year cannot be after current year',
                function (this: any, value: any) {
                    const current = moment().year();
                    if (value !== '')
                        return parseInt(value) <= current;
                    return true;
                }),
            placenr: number().required('E domosdoshme'),
            colour: string().required('E domosdoshme'),
            capacity: number(),
            power: number(),//.required('E domosdoshme'),
            volume: number(),
            used: string(),
            vehiclelicence: string(),
            damaged_parts: string().required('E domosdoshme'),
        }),
        actionLabel: 'Vazhdo',
        onAction: () => { }
    },
    {
        id: 'insuredPerson',
        component: InsuredPerson,
        name: 'I Siguruari / Shkaktari',
        description: '',
        initialValues: {
            tipKind: '3',
            nipt: '',
            clientNo: '',
            title: '',
            lastName: '',
            birthdate: '',
            email: '',
            celphone: '',
            address: '',
            place: '',
            drivingLicenceCat: '',
            drivingLicenceNo: '',
            sex: '',
            driverLicenseIssued: '',
        },
        validationSchema: object().shape({
            tipKind: string().required('E domosdoshme'),
            clientNo: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme').max(16, "Maximum 16 symbols") : schema.notRequired();
            }),
            nipt: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '2' ? schema.required('E domosdoshme').max(16, "Maximum 16 symbols") : schema.notRequired();
            }),
            title: string()
                .min(3, "Minimumi 3 simbole")
                .max(50, "Maksimumi 50 simbole")
                .required("E domosdoshme"),
            lastName: string()
                .when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                    return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
                }),
            birthdate: mixed()
                .test(
                    'validBirthDate',
                    'Data e lindjes nuk mund te jete pas dates se sotshme',
                    function (this: any, value: any) {
                        if (moment(value, 'dd/MM/yyyy').isValid())
                            return moment(value, 'dd/MM/yyyy').isBefore(moment());
                        return true;
                    }),
            email: string().email(),
            celphone: string().required('E domosdoshme'),
            address: string(),
            place: string(),
            drivingLicenceCat: string(),
            drivingLicenceNo: string(),
            sex: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            driverLicenseIssued: mixed()
                .test(
                    'validDriverLicenseIssued',
                    'Date Driver License Issued cannot be after today',
                    function (this: any, value: any) {
                        if (moment(value, 'dd/MM/yyyy').isValid())
                            return moment(value, 'dd/MM/yyyy').isBefore(moment());
                        return true;
                    }),
        }),
        actionLabel: 'Vazhdo'
    },
    {
        id: 'insuredVehicle',
        component: InsuredVehicle,
        name: 'I Siguruari - Automjeti',
        description: '',
        initialValues: {
            regnum: '',
            chassis: '',
            type_vehicle: '',
            mark: '',
            tip: '',
            prodYear: '',
            placenr: '',
            colour: '',
            capacity: '',
            power: '',
            volume: '',
            used: '',
            vehicleLicence: '',
            damaged_parts: ''
        },
        validationSchema: object().shape({
            regnum: string().required('E domosdoshme'),
            chassis: string().required('E domosdoshme'),
            type_vehicle: string().required('E domosdoshme'),
            mark: string().required('E domosdoshme'),
            tip: string().required('E domosdoshme'),
            prodYear: number().required('E domosdoshme').test(
                'validYear',
                'Production year cannot be after current year',
                function (this: any, value: any) {
                    const current = moment().year();
                    if (value !== '')
                        return parseInt(value) <= current;
                    return true;
                }),
            placenr: number().required('E domosdoshme'),
            colour: string().required('E domosdoshme'),
            capacity: number().required('E domosdoshme'),
            power: number(),//.required('E domosdoshme'),
            volume: number(),
            vehicleLicence: string(),
            damaged_parts: string(),
        }),
        actionLabel: 'Vazhdo',
        onAction: () => { }
    },
    {
        id: 'uploadDocuments',
        component: UploadDocuments,
        name: 'Dokumente',
        description: '',
        initialValues: {
            claimFiles: []
        },
        validationSchema: object().shape({
        }),
        actionLabel: 'Regjistro',
        onAction: () => { }
    }
] as FormikWizardStepType[];
