import React from 'react';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import FormikWizard from 'formik-wizard';
import { HideModalAction } from '../../../redux/actionTypes/generalActionTypes';
import steps from './steps';
import { FormikWizardWrapperProps } from '../../../utils/wizardTypes';
import SimpleModal from '../../General/SimpleModal';
import Nav from './Nav';
import { hideModal } from '../../../redux/actionCreators/generalActionCreators';
import { RouteComponentProps } from '../../Layout/Content';
import { AppState } from '../../../redux/reducers/rootReducer';
import { prepareKaskoPolicy, ThInsQuotation, KaskoPolicy as KaskoPolicyType, validateDriver, Cover } from '../../../utils/policyTypes';
import * as kaskoActions from "../../../redux/actionCreators/kaskoPolicyActionCreators";
import store from '../../../redux/configureStore';
import { BookKaskoPolicyAction } from '../../../redux/actionTypes/kaskoPolicyActionTypes';
import { User } from '../../../redux/types';
import LoginModal from '../../Auth/LoginModal';
import RegistrationModal from '../../Auth/Registration/RegistrationModal';
import VerificationCodeModal from '../../Auth/Registration/VerificationCodeModal';
import * as generalActions from "../../../redux/actionCreators/generalActionCreators";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loading } from '../../Layout/Loading';
import * as nomenActionTypes from '../../../redux/actionTypes/nomenActionTypes';
import {
    getVehicleMark,
    getTypeVehicle,
    getPlaces
} from '../../../redux/actionCreators/nomenActionCreators';
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import moment from 'moment';
import CreateAccountQuestionModal from '../../Auth/CreateAccountQuestionModal';

interface Props {
    closeModal(): void;
    onBookKaskoPolicy(kaskoPolicy: KaskoPolicyType): void;
    showModal: boolean;
    header: string;
    description: string;
    nextStep: string;
    history: History;
    user: User;
    chosenKaskoQuotation: any;
    onGetTypeVehicle(): void;
    onGetVehicleMark(): void;
    onGetPlaces(): void;
    nomenFetched: boolean;
}

interface PathProps {
    history: any;
}

function FormWrapper({
    children,
    isLastStep,
    goToPreviousStep,
    canGoBack,
    actionLabel,
    currentStep,
    values,
    wizard
}: FormikWizardWrapperProps<any, any>) {
    const dispatch = useDispatch();

    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[nomenActionTypes.GET_TYPE_VEHICLE]
        || state.isLoading[nomenActionTypes.GET_VEHICLE_MARK]
        || state.isLoading[nomenActionTypes.GET_PLACES]);

    const kaskoQuotationErrored = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.kaskoQuotationErrored);
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const showLoginModal = useSelector<AppState, boolean | null>((state: any) => state.general.showLoginModal);
    const showRegistrationModal = useSelector<AppState, boolean | null>((state: any) => state.general.showRegistrationModal);
    const showVerificationModal = useSelector<AppState, boolean | null>((state: any) => state.general.showVerificationModal);
    const continueWithoutAccount = useSelector<AppState, boolean | null>((state: any) => state.general.continueWithoutAccount);
    const showCreateAccountQuestionModal = useSelector<AppState, boolean | null>((state: any) => state.general.showCreateAccountQuestionModal);

    const chosenKaskoQuotation = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.kaskoQuotation);
    const kaskoPackageQuotation = chosenKaskoQuotation ? chosenKaskoQuotation[0] : null;

    const showSuccessToast = useSelector<AppState, boolean>((state: any) => state.general.showSuccessToast);
    const successToastText = useSelector<AppState, boolean>((state: any) => state.general.successToastText);

    if (!!showSuccessToast) {
        toast.success(successToastText);
        dispatch({ type: "generalActionTypes/SHOW_SUCCESS_TOAST_RESET" });
    }

    if (currentStep === steps[1].id && kaskoQuotationErrored) {
        goToPreviousStep();
    }

    if (currentStep === steps[1].id
        && (values.kaskoMain.kaskoCovers.length == 0
            || values.kaskoMain.kaskoCovers.filter((x: Cover) => x.required && !x.selected).length > 0)) {
        store.dispatch(generalActions.showModal("Dështimi", "Duhet të zgjedhë të paktën një kopertinë dhe të gjitha kapakët e kërkuar!", ""));
        goToPreviousStep();
    }

    if (currentStep === steps[2].id && values.insuredVehicle?.prodYear && ((moment().year() - values.insuredVehicle.prodYear > 10 || moment().year() - values.insuredVehicle.prodYear < 3))) {
        store.dispatch(generalActions.showModal("Dështimi", "Vjetersia e mjetit motorik duhet jete jo me i vjeter se 10 vjet dhe jo me i ri se 3 vjet!", ""));
        goToPreviousStep();
    }

    if (currentStep === steps[2].id && continueWithoutAccount) {
        wizard.next();
    }

    if (currentStep === steps[3].id
        && (validateDriver(values.insertDrivers.drivers[0], values.insertDrivers.drivers).errored || (!user && !continueWithoutAccount))) {
        goToPreviousStep();
    }

    if (currentStep === steps[4].id && values.uploadDocuments.policyFiles.length === 0) {
        store.dispatch(generalActions.showModal("Dështimi", "Kërkohet të paktën një skedar!", ""));
        goToPreviousStep();
    }

    const handleCloseLoginModal = () => {
        store.dispatch(generalActions.showLoginModalReset());
    };

    const handleCloseRegistrationModal = () => {
        store.dispatch(generalActions.showRegistrationModalReset());
    };

    const handleCloseVerificationModal = () => {
        store.dispatch(generalActions.showVerificationModalReset());
    };

    const handleCloseCreateAccountQuestionModal = () => {
        store.dispatch(generalActions.showCreateAccountQuestionModalReset());
    };

    const goBack = () => {
        if (currentStep === steps[3].id) {
            store.dispatch(generalActions.continueWithoutAccountReset());
        }

        goToPreviousStep();
    }

    const useStyles = makeStyles(theme => ({
        customTooltip: {
            backgroundColor: '#3699FF',
            fontSize: '10px',
            opacity: '0.6 !important'
        }
    }));

    const classes = useStyles();

    return (
        <>
            {isLoading
                ? <Loading />
                : <>
                    <LoginModal show={showLoginModal} onClose={handleCloseLoginModal} onHide={() => { return; }} />
                    <RegistrationModal show={showRegistrationModal} onClose={handleCloseRegistrationModal} onHide={() => { return; }} />
                    <VerificationCodeModal show={showVerificationModal} onClose={handleCloseVerificationModal} onHide={() => { return; }} />
                    <CreateAccountQuestionModal show={showCreateAccountQuestionModal} onClose={handleCloseCreateAccountQuestionModal} onHide={() => { return; }} />

                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={true}
                        newestOnTop={true}
                        closeOnClick={false}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                        theme="colored"
                    />

                    <div className="wizard wizard-4" id="kt_wizard_v4" data-wizard-state="step-first" data-wizard-clickable="true">
                        <Nav currentStep={currentStep} />
                        <div className="card card-custom card-shadowless rounded-top-0">
                            <div className="card-body p-0">
                                <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                                    <div className="col-xl-12 col-xxl-7">
                                        {children}
                                        <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                            <div className="mr-2">
                                                {canGoBack && <button type="button" onClick={goBack} className="btn-wiz-back font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-prev">
                                                    <i className="arrow left"></i>Kthehu
                                                </button>
                                                }
                                            </div>

                                            <div>
                                                {isLastStep && <button type="submit" className="btn btn-wiz font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-submit">
                                                    {actionLabel || 'Submit'}
                                                </button>}
                                                {!isLastStep && <button type="submit" className="btn btn-wiz font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-next">
                                                    {actionLabel || 'Next'}
                                                </button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {kaskoPackageQuotation && <div className="col-xl-12 packageCard">
                        <div className="card offcanvas offcanvas-right offcanvas-on card-c" >
                            <div className="card-body card-b-aside">
                                <h5 className="card-title">{kaskoPackageQuotation.packageName}</h5>
                                <p className="card-text pSum"><small>Shuma e siguruar: {kaskoPackageQuotation.insuredAmountV.toLocaleString()}€</small></p>
                                {/* covers */}
                                {kaskoPackageQuotation.covers.map((cover: any, indexC: number) => (
                                    <p key={indexC + 1} className="card-text">
                                        <span className='pCheck'>✔ </span>
                                        <span dangerouslySetInnerHTML={{ __html: cover.replaceAll("#", "<br />") }}></span>
                                    </p>
                                ))}

                                {/* kaskoCovers */}
                                {kaskoPackageQuotation.kaskoCovers.map((kaskoCover: any, index: number) => (
                                    <>{kaskoCover.selected && <p key={index + 1} className="card-text">
                                        <span className='pCheck'>✔ </span>
                                        <span>{kaskoCover.name}</span>
                                    </p>}</>
                                ))}
                            </div>

                            <div className="card-footer">
                                {kaskoPackageQuotation.packageDiscount > 0 &&
                                    <p className='pDiscount'>
                                        {kaskoPackageQuotation.totalVNoDiscount.toFixed(2)}{kaskoPackageQuotation.currencyName ? kaskoPackageQuotation.currencyName : '€'}
                                        <Tooltip
                                            arrow
                                            title={"Aplikohet zbritja prej " + (kaskoPackageQuotation.packageDiscount) + "% ne kete police"}
                                            placement="top"
                                            classes={{
                                                tooltip: classes.customTooltip
                                            }}>
                                            <IconButton className="blue">
                                                <Info />
                                            </IconButton>
                                        </Tooltip>
                                    </p>}
                                <p className='pTotal'>
                                    {parseFloat(kaskoPackageQuotation.totalV).toFixed(2)}  {kaskoPackageQuotation.currencyName ? kaskoPackageQuotation.currencyName : '€'}
                                </p>
                            </div>
                        </div>
                    </div>}
                </>}
        </>

    );
}

class KaskoPolicy extends React.Component<Props & RouteComponentProps<PathProps>, {}> {
    componentDidMount() {
        if (!this.props.nomenFetched) {
            this.props.onGetTypeVehicle();
            this.props.onGetVehicleMark();
            this.props.onGetPlaces();
        }
    }

    handleSubmit = (values: any) => {
        const customerId = this.props.user ? this.props.user.customerId : '';
        const packageId = this.props.chosenKaskoQuotation && this.props.chosenKaskoQuotation?.length > 0 ? this.props.chosenKaskoQuotation[0].packageId : "";
        const policy = prepareKaskoPolicy(customerId, values, packageId);
        this.props.onBookKaskoPolicy(policy);
    };

    handleCloseModal = () => {
        if (this.props.nextStep === "3") this.props.history.push('/');
        this.props.closeModal();
    };

    render() {
        return (
            <div className="d-flex flex-column-fluid">
                <SimpleModal show={this.props.showModal} header={this.props.header} text={this.props.description} onClose={this.handleCloseModal} onHide={() => { return; }} />
                <div className=" container ">
                    <div className="card card-custom">
                        <div className="card-body p-0">
                            <FormikWizard steps={steps} onSubmit={this.handleSubmit} render={FormWrapper} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch<nomenActionTypes.NomenAction | HideModalAction | BookKaskoPolicyAction>) => ({
    onBookKaskoPolicy: (kaskoPolicy: KaskoPolicyType) => {
        dispatch(kaskoActions.bookKaskoPolicy(kaskoPolicy));
    },
    onGetTypeVehicle: () => {
        dispatch(getTypeVehicle());
    },
    onGetVehicleMark: () => {
        dispatch(getVehicleMark());
    },
    onGetPlaces: () => {
        dispatch(getPlaces());
    },
    closeModal: () => { dispatch(hideModal()); }
});

const mapStateToProps = (state: any) => ({
    user: state.auth.user,
    chosenKaskoQuotation: state.policy.kaskoQuotation,
    showModal: state.general.showModal,
    header: state.general.header,
    description: state.general.text,
    nextStep: state.general.nextStep,
    nomenFetched: state.nomen.TypeVehicle.length > 0
        && state.nomen.VehicleMarks.length > 0
        && state.nomen.Places.length > 0
});

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)(KaskoPolicy)));
