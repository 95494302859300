import { FormikWizardStepType } from '../../../../utils/wizardTypes';
import { object, string, date, array, bool, number, StringSchema } from 'yup';
import store from '../../../../redux/configureStore';
import * as thActions from "../../../../redux/actionCreators/thPolicyActionCreators";
import moment from 'moment';
import TravelHealthPolicyMain from './TravelHealthPolicyMain';
import Packages from './Packages';
import Travellers from './Travellers';
import Verification from './Verification';
import { prepareThPolicyForQuotation } from '../../../../utils/policyTypes';
import { validateTraveller } from '../../../../utils/policyTypes';
import * as generalActions from '../../../../redux/actionCreators/generalActionCreators';
import { CheckProfileDto } from '../../../../utils';

export default [
    {
        id: 'thMain',
        component: TravelHealthPolicyMain,
        name: 'Detajet e udhëtimit',
        description: '',
        initialValues: {
            startDate: '',
            endDate: '',
            zone: '',
            insuredPersons: 1,
            couponAnswer: '',
            coupon: '',
            country: '',
            personalDataConsent: false
        },
        validationSchema: object().shape({
            startDate: date().required('E domosdoshme').typeError('E domosdoshme')
                .test(
                    'validStartDate',
                    'Data e fillimit nuk mund të jetë pas ditës së sotme',
                    function (this: any, value: any) {
                        return !moment(value, 'dd/MM/yyyy').isBefore(moment(), 'day');
                    }),
            endDate: date().required('E domosdoshme').typeError('E domosdoshme')
                .test(
                    'validEndDate',
                    'Data e përfundimit nuk mund të jetë përpara ditës së sotme',
                    function (this: any, value: any) {
                        return moment(value, 'dd/MM/yyyy').isAfter(moment());
                    }),
            zone: string().required('E domosdoshme'),
            insuredPersons: number().moreThan(0, 'Të paktën 1 person').required('E domosdoshme').typeError('Duhet të jetë një numër'),
            coupon: string().when('couponAnswer', (couponAnswer: string, schema: StringSchema<string | undefined>) => {
                return couponAnswer === '1' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            country: string().required('E domosdoshme'),
            personalDataConsent: bool().oneOf([true], 'E domosdoshme')
        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
            if (sectionValues.startDate !== formValues.thMain.startDate
                || sectionValues.endDate !== formValues.thMain.endDate
                || sectionValues.zone !== formValues.thMain.zone
                || sectionValues.country !== formValues.thMain.country
                || sectionValues.insuredPersons !== formValues.thMain.insuredPersons) {

                const state = store.getState();
                const customerId = state.auth.user?.customerId;

                const thPolicy = prepareThPolicyForQuotation(customerId, sectionValues);
                store.dispatch(thActions.getTHQuotation(thPolicy));
            }

        }
    },
    {
        id: 'package',
        component: Packages,
        name: 'Paketat',
        description: '',
        initialValues: {
            thPackageQuotation: ''
        },
        validationSchema: object().shape({

        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
        }
    },
    {
        id: 'insertTravelers',
        component: Travellers,
        name: 'Udhëtarët',
        description: '',
        initialValues: {
            travellers: [
                {
                    clientNo: '',
                    title: '',
                    lastName: '',
                    sex: '',
                    address: '',
                    place: '',
                    email: '',
                    celphone: '+383',
                    birthDate: '',
                    passport: '',
                    passportPhoto: []
                }
            ]
        },
        validationSchema: object().shape({
            travellers: array()
                .of(
                    object().shape({
                        clientNo: string().required('E domosdoshme'),
                        title: string().required('E domosdoshme'),
                        lastName: string().required('E domosdoshme'),
                        sex: string().required('E domosdoshme'),
                        address: string().required('E domosdoshme'),
                        place: string().required('E domosdoshme'),
                        email: string()
                            .email("Format I gabuar I email-it")
                            .min(3, "Minimumi 3 simbole")
                            .max(50, "Maksimumi 50 simbole")
                            .required("E domosdoshme"),
                        passport: string().required('E domosdoshme'),
                        celphone: string().required('E domosdoshme').min(10, "Minimumi 10 simbole"),
                        birthDate: date().required('E domosdoshme').typeError('E domosdoshme')
                            .test(
                                'validBirthDate',
                                'Data e lindjes nuk mund të jetë pas ditës së sotme',
                                function (this: any, value: any) {
                                    return moment(value, 'dd/MM/yyyy').isBefore(moment());
                                })
                            .test(
                                'validBirthDateValue',
                                'Të gjitha primet janë për personat nën 68 vjeç. Personat mbi moshën 68 vjeçare duhet të paraqiten në zyrat e SIGAL-it për t\'u pajisur me policën e sigurimit Shëndetit në udhëtim',
                                function (this: any, value: any) {
                                    const age = moment().diff(value, 'years');
                                    return age <= 68;
                                })
                    })
                )
        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
            const state = store.getState();

            //check if first is valid
            var validTraveller = sectionValues.travellers[0];
            var isValid = validateTraveller(validTraveller, sectionValues.travellers);

            if (isValid.errored) {
                store.dispatch(generalActions.showModal("Dështimi", isValid.message, ""));
                return;
            }

            const firstTraveller = sectionValues.travellers[sectionValues.travellers.length - 1];
            const checkProfile = state.auth.checkProfile as CheckProfileDto;
            const user = state.auth.user;

            const continueWithoutAccount = state.general.continueWithoutAccount;
            if (continueWithoutAccount)
                return;

            if (!user && checkProfile.hasProfile && checkProfile.confirmedMail) {
                store.dispatch(generalActions.showLoginModal(firstTraveller.email));
                return;
            }

            if (!user && checkProfile.hasProfile && !checkProfile.confirmedMail) {
                store.dispatch(generalActions.showVerificationModal());
                return;
            }

            if (!user && !checkProfile.hasProfile) {
                store.dispatch(generalActions.showCreateAccountQuestionModal(firstTraveller));
                return;
            }

        }
    },
    {
        id: 'verification',
        component: Verification,
        name: 'Verifiko',
        description: '',
        initialValues: {
            acceptTerms: false,
            captchaGenerated: '',
            captchaInputted: ''
        },
        validationSchema: object().shape({
            acceptTerms: bool().oneOf([true], 'E domosdoshme'),
            captchaGenerated: string(),
            captchaInputted: string().required('E domosdoshme')
                .test(
                    'validCaptcha',
                    'Captcha eshte gabim',
                    function (this: any, value: any) {
                        if (value !== '')
                            return this.parent.captchaGenerated === value;

                        return true;
                    })
        }),
        actionLabel: 'Vazhdo',

    },
] as FormikWizardStepType[];
