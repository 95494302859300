import store from '../redux/configureStore';
import { logoutUser, loginUserFailure } from "../redux/actionCreators/authActionCreators";
import { showModal } from "../redux/actionCreators/generalActionCreators";
import axios, { AxiosRequestConfig } from 'axios';

export default abstract class ApiClient {

    static async get<T, K = any>(route: string, data?: K, isFile: boolean = false): Promise<T> {
        try {
            return await axios.get(route, this.fetchWithOptions(data, isFile));
        }
        catch (error: any) {
            if (error.response.status === 401) {
                // store.dispatch(logoutUser());
                store.dispatch(loginUserFailure("Sesioni skadoi"));
            }
            else {
                store.dispatch(showModal("Gabim", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
            }
            return Promise.reject('Ndodhi nje gabim!');
        }
    }

    static async post<T, K = any>(route: string, data?: K, isFile: boolean = false): Promise<T> {
        try {
            return await axios.post(route, data, this.fetchWithOptions(null, isFile));
        }
        catch (error: any) {
            if (error.response.status === 401) {
                // store.dispatch(logoutUser());
                store.dispatch(loginUserFailure("Sesioni skadoi"));
            }
            else {
                store.dispatch(showModal("Gabim", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
            }
            return Promise.reject('Ndodhi nje gabim!');
        }
    }

    private static fetchWithOptions<T, K = never>(
        data?: K,
        isFile: boolean = false
    ): AxiosRequestConfig {
        const user = store.getState().auth.user;

        let finalOptions: AxiosRequestConfig = {};
        finalOptions = this.withBaseOptions(finalOptions);
        if (data !== null) finalOptions.params = data;
        if (isFile) finalOptions.responseType = 'blob';
        if (user !== null) finalOptions = this.withAuthorization(finalOptions, user.token);

        return finalOptions;
    }

    private static withBaseOptions(options: AxiosRequestConfig): AxiosRequestConfig {
        const headers = options.headers;

        return {
            ...options,
            headers: {
                ...headers,
                'Cache-Control': 'no-cache',
                "Pragma": 'no-cache',
            },
        }
    };

    private static withAuthorization = (options: AxiosRequestConfig, accessToken: string): AxiosRequestConfig => {
        const headers = options.headers;
        return { ...options, headers: { ...headers, Authorization: `Bearer ${accessToken}` } }
    };

    // private static withMethod = (options: RequestInit | undefined, method: string): RequestInit => {
    //     return { ...options, method  };
    // };

    // private static withBody<K>(options: RequestInit | undefined, data: K): any  {
    //     if (typeof data === 'string' || data instanceof FormData) {
    //         return { ...options, body: data };
    //     }

    //     const serialized = JSON.stringify(data);
    //     const headers = options!.headers || {};

    //     return {
    //         ...options,
    //         data: serialized,
    //         headers: { ...headers, 'Content-Type': 'application/json' },
    //     }
    // };

}

