/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import SVG from "react-inlinesvg";
import { useHistory, NavLink, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_helpers";
import Login from "../../../../../components/Auth/Login";

export function QuickUser() {
  const history = useHistory();
  const user = useSelector(state => state.auth.user);

  const logoutClick = () => {
    closePanel();
    history.push("/logout");
  };

  const closePanel = () => {
    const toggle = document.getElementById("kt_quick_user_close");
    if (toggle) {
      toggle.click();
    }
  }

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        {!!user ? <h3 className="font-weight-bold m-0">
          Profili
        </h3>
          :
          <Link to="/" className="login-logo pb-xl-20 pb-15">
            <img
              alt="Logo"
              className="max-h-70px"
              src={toAbsoluteUrl("/media/logos/sigal_new.png")}
            />
          </Link>}
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      {!!user ? <>
        <div className="offcanvas-content pr-5 mr-n5">
          <div className="d-flex align-items-center mt-5">
            <div className="d-flex flex-column">
              <a
                href="#"
                className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
                {user?.name + " " + user?.surname}
              </a>
              <div className="text-muted mt-1">{user?.address}</div>
              <div className="navi mt-2">
                <a href="#" className="navi-item">
                  <span className="navi-link p-0 pb-2">
                    <span className="navi-icon mr-1">
                      <span className="svg-icon-lg svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail-notification.svg"
                          )}
                        ></SVG>
                      </span>
                    </span>
                    <span className="navi-text text-muted text-hover-primary">
                      {user?.username}
                    </span>
                  </span>
                </a>
              </div>
              {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
              <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>Dil</button>
            </div>
          </div>

          <div className="separator separator-dashed mt-8 mb-5" />

          <div className="navi navi-spacer-x-0 p-0">
            <NavLink to="/update-profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label-updated">
                    <span className="svg-icon svg-icon-md svg-icon-success">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Notification2.svg"
                        )}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    Përditëso Profilin
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="navi navi-spacer-x-0 p-0">
            <NavLink to="/change-password" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label-updated">
                    <span className="svg-icon svg-icon-md svg-icon-success">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Notification2.svg"
                        )}
                      ></SVG>
                    </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    Ndrysho Fjalëkalimin
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
      </>
        : <Login withoutAside />}
    </div>
  );
}

