import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import RadioButtonGroupFieldMain from '../../Custom/RadioButtonGroupFieldMain';
import InputFieldMain from '../../Custom/InputFieldMain';
import DatePickerFieldMain from '../../Custom/DatePickerFieldMain';
import SelectField from '../../Custom/SelectField';
import { Gender } from '../../../utils/nomen';
import { useFormikContext } from 'formik';
import * as bActionTypes from "../../../redux/actionTypes/bPolicyActionTypes";
import * as generalActionTypes from "../../../redux/actionTypes/generalActionTypes";
import { AppState } from "../../../redux/reducers/rootReducer";
import { MotorInsQuotation } from '../../../utils/policyTypes';
import { Loading } from '../../Layout/Loading';

const Contractor = () => {
    const { values }: any = useFormikContext();
    const motorInsQuotation = useSelector<AppState, MotorInsQuotation | null>((state: any) => state.policy.motorInsQuotation);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[bActionTypes.GET_B_QUOTATION]);
    const isCheckFValidLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[generalActionTypes.CHECK_FVALID]);
    const isPhysical = values.tipKind === '3';

    return (<>
        {isLoading || isCheckFValidLoading
            ? <Loading />
            : <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                {motorInsQuotation ? <div className="alert alert-custom alert-notice alert-light-primary fade show boxTotal" role="alert">
                    <div className="alert-icon"><i className="far fa-credit-card"></i></div>
                    <div className="alert-text">Total Primi + Taksa per Blerje Online <span className="spanTotal">{motorInsQuotation.total} €</span></div>

                </div> : ''}
                <div className="row">
                    <div className="col-xl-6">
                        <RadioButtonGroupFieldMain name="tipKind" type="radio" newvalue={motorInsQuotation?.contractor?.tipkind || ''} placeholder="Tip Kind" label="Tip" items={[{ id: '3', value: 'Fizik' }, { id: '2', value: 'Juridik' }]} />
                    </div>
                    {!isPhysical && <div className="col-xl-6">
                        <InputFieldMain type="text" name="nipt" newvalue={motorInsQuotation?.contractor?.nipt || ''} label="Nipti" placeholder="Nipti" />
                    </div>}
                    {isPhysical && <div className="col-xl-6">
                        <InputFieldMain type="text" name="clientNo" maxLength={16} newvalue={motorInsQuotation?.contractor?.clientno || ''} label="Numri personal" placeholder="Numri personal" />
                    </div>}
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="title" label="Emri" newvalue={motorInsQuotation?.contractor?.title || ''} placeholder="Emri" capitalized />
                    </div>
                    {isPhysical && <div className="col-xl-6">
                        <InputFieldMain type="text" name="lastName" newvalue={motorInsQuotation?.contractor?.lastname || ''} label="Mbiemri" placeholder="Mbiemri" capitalized />
                    </div>}

                    {!isPhysical && <div className="col-xl-6">
                        <InputFieldMain type="text" name="address" label="Adresa" newvalue={motorInsQuotation?.contractor?.address || ''} placeholder="Adresa" />
                    </div>}

                </div>

                <div className="row">
                    {isPhysical && <div className="col-xl-6">
                        <InputFieldMain type="text" name="address" label="Adresa" newvalue={motorInsQuotation?.contractor?.address || ''} placeholder="Adresa" />
                    </div>}
                    {!isPhysical && <div className="col-xl-6">
                        <InputFieldMain type="text" name="celphone" label="Nr. telefonit" newvalue={motorInsQuotation?.contractor?.celphone || ''} placeholder="Nr. telefonit" />
                    </div>}
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="place" label="Vendi" newvalue={motorInsQuotation?.contractor?.place || ''} placeholder="Vendi" />
                    </div>
                </div>
                {isPhysical && <div className="row">
                    <div className="col-xl-6">
                        <SelectField name="sex" label="Gjinia" newvalue={motorInsQuotation?.contractor?.sex?.code || ''} items={Gender} />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="celphone" label="Nr. telefonit" newvalue={motorInsQuotation?.contractor?.celphone || ''} placeholder="Nr. telefonit" />
                    </div>
                </div>}
                {isPhysical && <div className="row">
                    <div className="col-xl-6">
                    </div>
                    <div className="col-xl-6 addSpace">
                        <DatePickerFieldMain name="birthDate" newvalue={!!motorInsQuotation?.contractor?.birthdate ? new Date(motorInsQuotation?.contractor?.birthdate) : '' || ''} type="text" placeholder="Datëlindja" />
                    </div>
                </div>}
            </div>
        }
    </>
    );
}
export default connect()(Contractor);
