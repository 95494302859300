/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkIsActive } from "../../../../_helpers";

export function HeaderMenuLeft({ layoutProps }) {
    const location = useLocation();
    const user = useSelector((state) => state.auth.user);
    const enableSearchPolicy = useSelector((state) => state.general.appInfo?.saleMenus?.enableSearchPolicy);
    const [selectedClaim, setSelectedClaim] = useState(false);
    const [selectedMotor, setSelectedMotor] = useState(false);
    const [selectedHealth, setSelectedHealth] = useState(false);

    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };

    const selectMenuClaim = () => {
        setSelectedClaim(!selectedClaim);
        deselectMenuMotor();
        deselectMenuHealth();
    };

    const deselectMenuClaim = () => {
        setSelectedClaim(false);
    };

    const selectMenuMotor = () => {
        setSelectedMotor(!selectedMotor);
        deselectMenuClaim();
        deselectMenuHealth();
    };

    const deselectMenuMotor = () => {
        setSelectedMotor(false);
    };

    const selectMenuHealth = () => {
        setSelectedHealth(!selectedHealth);
        deselectMenuClaim();
        deselectMenuMotor();
    };

    const deselectMenuHealth = () => {
        setSelectedHealth(false);
    };

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="menu-link" to="/dashboard">
                    <span className="menu-text">Dashboard</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>
            {/*end::1 Level*/}

            {/*Classic submenu*/}
            <li onClick={selectMenuClaim} className={`menu-item menu-item-submenu menu-item-rel ${selectedClaim && 'menu-item-hover'} 
                ${getMenuItemActive('/claims', true)}`} data-menu-toggle="click" aria-haspopup="true" onPointerLeave={deselectMenuClaim} >


                <div className="menu-link menu-toggle" >
                    <span className="menu-text menu-ipad-dsh">Dëmet shëndetsore</span><span
                        className="menu-desc"></span><i className="menu-arrow"></i>
                </div>
                <div className="menu-submenu  menu-submenu-fixed menu-submenu-center" style={{ width: '220px' }} >
                    <div className="menu-subnav">
                        <ul className="menu-content">
                            <li className="menu-item ">
                                <h3 className="menu-heading menu-toggle">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span></span></i>
                                    <span className="menu-text">Dëmet shendetsore</span>
                                    <i className="menu-arrow"></i>
                                </h3>
                                <ul className="menu-inner">
                                    <li className="menu-item " aria-haspopup="true">
                                        <NavLink to="/register-health-preclaim-ks"
                                            className="menu-link ">
                                            <i className="menu-bullet menu-bullet-line">
                                                <span></span>
                                            </i>
                                            <span className="menu-text">Parakërkesë për dëm (Shëndet)</span>
                                        </NavLink>
                                    </li>
                                    <li className="menu-item " aria-haspopup="true">
                                        <NavLink to="/register-health-preclaim"
                                            className="menu-link ">
                                            <i className="menu-bullet menu-bullet-line">
                                                <span></span>
                                            </i>
                                            <span className="menu-text">Parakërkesë për dëm (Shëndeti në udhëtim/Aksidente personale)</span>
                                        </NavLink>
                                    </li>
                                    <li className="menu-item " aria-haspopup="true">
                                        <NavLink to="/health-claim-search"
                                            className="menu-link "><i className="menu-bullet menu-bullet-line"><span></span></i><span
                                                className="menu-text">Statusi dhe historiati i kerkesave për rimbursim</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>

            {user?.enableMotorClaims && <li onClick={selectMenuMotor} className={`menu-item menu-item-submenu menu-item-rel ${selectedMotor && 'menu-item-hover'} 
                ${getMenuItemActive('/motor', true)}`} data-menu-toggle="click" aria-haspopup="true" onPointerLeave={deselectMenuMotor}>

                <div className="menu-link menu-toggle">
                    <span className="menu-text menu-ipad-dm">Demet motorike</span><span
                        className="menu-desc"></span><i className="menu-arrow"></i>
                </div>
                <div className="menu-submenu  menu-submenu-fixed menu-submenu-center" style={{ width: '220px' }} >
                    <div className="menu-subnav">
                        <ul className="menu-content">
                            <li className="menu-item ">
                                <h3 className="menu-heading menu-toggle">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span></span></i>
                                    <span className="menu-text">Demet motorike</span>
                                    <i className="menu-arrow"></i>
                                </h3>
                                <ul className="menu-inner">
                                    <li className="menu-item " aria-haspopup="true">
                                        <NavLink to="/register-preclaim"
                                            className="menu-link ">
                                            <i className="menu-bullet menu-bullet-line">
                                                <span></span>
                                            </i>
                                            <span className="menu-text">Parakërkesë për dëm (TPL/TPL Plus)</span>
                                        </NavLink>
                                    </li>
                                    <li className="menu-item " aria-haspopup="true">
                                        <NavLink to="/register-kasko-preclaim"
                                            className="menu-link ">
                                            <i className="menu-bullet menu-bullet-line">
                                                <span></span>
                                            </i>
                                            <span className="menu-text">Parakërkesë për dëm (Kasko)</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>}

            <li onClick={selectMenuHealth} className={`menu-item menu-item-submenu menu-item-rel ${selectedHealth && 'menu-item-hover'} 
                ${getMenuItemActive('/health', true)}`} data-menu-toggle="click" aria-haspopup="true" style={{ width: '110px' }} onPointerLeave={deselectMenuHealth} >


                <div className="menu-link menu-toggle">
                    <span className="menu-text">Kërko</span><span
                        className="menu-desc"></span><i className="menu-arrow"></i>
                </div>
                <div className="menu-submenu  menu-submenu-fixed menu-submenu-center" style={{ width: '220px' }} >
                    <div className="menu-subnav">
                        <ul className="menu-content">
                            <li className="menu-item ">
                                <h3 className="menu-heading menu-toggle">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span></span></i>
                                    <span className="menu-text">Kërko</span>
                                    <i className="menu-arrow"></i>
                                </h3>
                                <ul className="menu-inner">

                                    <li className="menu-item " aria-haspopup="true">
                                        <NavLink to="/preclaim-search"
                                            className="menu-link "><i className="menu-bullet menu-bullet-line"><span></span></i><span
                                                className="menu-text">Kërko kërkesën për dëm</span>
                                        </NavLink>
                                    </li>
                                </ul>
                                <ul className="menu-inner">

                                    <li className="menu-item " aria-haspopup="true">
                                        <NavLink to="/claim-search"
                                            className="menu-link "><i className="menu-bullet menu-bullet-line"><span></span></i><span
                                                className="menu-text">Kërko të gjitha dëmet</span>
                                        </NavLink>
                                    </li>
                                </ul>
                                {enableSearchPolicy && <ul className="menu-inner">

                                    <li className="menu-item " aria-haspopup="true">
                                        <NavLink to="/policy-search"
                                            className="menu-link "><i className="menu-bullet menu-bullet-line"><span></span></i><span
                                                className="menu-text">Kërko polica</span>
                                        </NavLink>
                                    </li>
                                </ul>}
                            </li>
                        </ul>
                    </div>
                </div>
            </li>

        </ul>
        {/*begin::Header Nav*/}
        {/*end::Header Nav*/}
    </div>;
}
