import * as React from 'react';
import { useSelector } from 'react-redux';
import InputFieldMain from '../../Custom/InputFieldMain';
import SelectField from '../../Custom/SelectField';
import { AppState } from '../../../redux/reducers/rootReducer';
import { Nomen } from '../../../utils';
import { PreClaimVehicle } from '../../../utils/claimTypes';
import { useFormikContext } from 'formik';
import store from '../../../redux/configureStore';
import * as actions from '../../../redux/actionCreators/claimActionCreators';
import * as claimActionTypes from '../../../redux/actionTypes/claimActionTypes';
import { Loading } from '../../Layout/Loading';
import * as generalActions from "../../../redux/actionCreators/generalActionCreators";

const InsuredVehicle = () => {
    const { values }: any = useFormikContext();
    const marks = useSelector<AppState, Nomen[]>((state: any) => state.nomen.VehicleMarks);
    const types = useSelector<AppState, Nomen[]>((state: any) => state.nomen.TypeVehicle);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[claimActionTypes.GET_VEHICLE_BY_PLATE_NUMBER]
        || state.isLoading[claimActionTypes.GET_VEHICLE_BY_CHASSIS]);
    const vehicle = useSelector<AppState, PreClaimVehicle | null>((state: any) => state.claim.vehicleByChassisOrPlate);

    const getVehicleByPlateNumber = (value: any) => {
        if (!value) {
            return;
        }

        store.dispatch(actions.getVehicleByPlateNumberReset());
        store.dispatch(actions.getVehicleByPlateNumber(value));
    };

    const getVehicleByChassis = (value: any) => {
        if (!value || value.length !== 17) {
            store.dispatch(generalActions.showModal("Dështimi", "Gjatesia e shasise duhet te jete 17 karaktere!", ""));
            return;
        }

        store.dispatch(actions.getVehicleByChassisReset());
        store.dispatch(actions.getVehicleByChassis(value));
    };

    return (<>
        {isLoading
            ? <Loading />
            :
            <>
                <div className="wizardTitle">I Siguruari - Automjeti</div>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="regnum" label="Targa" placeholder="Targa" blur={getVehicleByPlateNumber} newvalue={vehicle?.regnum || values?.regnum} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="chassis" label="Numri i shasisë" placeholder="Numri i shasisë" blur={getVehicleByChassis} newvalue={vehicle?.chassis || values?.chassis} inline maxLength={17} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <SelectField name="type_vehicle" label="Mjeti/Klasa" items={types} newvalue={'23'} inline disabled/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <SelectField name="mark" label="Prodhuesi" items={marks} newvalue={vehicle?.mark?.code || values?.mark} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="tip" label="Modeli" placeholder="Modeli" newvalue={vehicle?.tip || values?.tip} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="power" label="Fuqia(kw) " placeholder="Fuqia(kw)" newvalue={vehicle?.power?.toString() || values?.power} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="prodYear" label="Viti i prodhimit" placeholder="Viti i prodhimit" newvalue={vehicle?.prodyear?.toString() || values?.prodYear} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="placenr" label="Nr. I ulëseve" placeholder="Nr. I ulëseve" newvalue={vehicle?.placenr?.toString() || values?.placenr} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="capacity" label="Pesha e automjetit" placeholder="Pesha e automjetit" newvalue={vehicle?.capacity?.toString() || values?.capacity} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="colour" label="Ngjyra e automjetit" placeholder="Ngjyra e automjetit" newvalue={vehicle?.colour || values?.colour} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="volume" label="Kapaciteti (cm3)" placeholder="Kapaciteti (cm3)" newvalue={vehicle?.volume?.toString() || values?.volume} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="vehicleLicence" label="Leje qarkullimi" placeholder="Leje qarkullimi" newvalue={vehicle?.vehiclelicence || values?.vehicleLicence} inline />
                        </div>
                    </div>
                </div>
            </>}
    </>
    );
}

export default InsuredVehicle;
