import React, { useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { useFormikContext, validateYupSchema } from 'formik';
import { AppState } from '../../../../redux/reducers/rootReducer';
import Accordion from '../../../Custom/Accordion';
import Invoice from './Invoice';
import * as nomenActionTypes from '../../../../redux/actionTypes/nomenActionTypes';
import { Loading } from "../../../Layout/Loading";
import { validate, validateRecord } from "../../../../utils/healthPreClaimTypes";

const Invoices = () => {
    const { values, setValues }: any = useFormikContext();
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[nomenActionTypes.GET_COVER_GROUPS_FOR_POLICY]);
    const dispatch = useDispatch();
    const [showDocsPreview, setShowDocsPreview] = useState(true);
    const [key, setKey] = useState(0);

    const manageInvoices = () => {
        setKey(key + 1);
        setShowDocsPreview(false);

        const validity: any = validate(values.invoices[0]);
        if (validity.errored) {
            dispatch({
                type: "generalActionTypes/SHOW_MODAL",
                header: "Dështimi",
                text: validity.message,
                next: ""
            });
            return;
        }

        var currentValues = { ...values };
        currentValues.invoices.unshift(blankInvoice);
        setValues(currentValues);
        
        setShowDocsPreview(true);
    }

    const manageRecords = (invoiceIndex: any) => {
        const validity: any = validateRecord(values.invoices[invoiceIndex].records[0]);
        if (validity.errored) {
            dispatch({
                type: "generalActionTypes/SHOW_MODAL",
                header: "Dështimi",
                text: validity.message,
                next: ""
            });
            return;
        }

        var currentValues = { ...values };
        currentValues.invoices[invoiceIndex].records.unshift(blankRecord);
        setValues(currentValues);
    }

    const removeInvoice = (index: any) => {
        setKey(key - 1);
        setShowDocsPreview(false);

        var currentValues = { ...values };
        currentValues.invoices.splice(index, 1);
        setValues(currentValues);

        setShowDocsPreview(true);
    }

    const removeRecord = (invoiceIndex: any, index: any) => {
        var currentValues = { ...values };
        currentValues.invoices[invoiceIndex].records.splice(index, 1);
        setValues(currentValues);
    }

    const blankRecord = {
        covercategory: '',
        covergroup: '',
        invoicedAmountV: '',
        treating: ''
    }

    const blankInvoice = {
        invoicedate: '',
        invoicenr: '',
        invoiceFiscalnr: '',
        invoicedAmountV: '',
        countryEvent: '',
        hospitalId: '',
        hospitalName: '',
        fiscalFiles: [],
        medicalFiles: [],
        records: [{
            covercategory: '',
            covergroup: '',
            invoicedAmountV: '',
            treating: ''
        }]
    }

    return (<>
        {isLoading
            ? <Loading />
            : <>
                <div className="wizardTitle">Faturat</div>
                <div className="row">
                    <div className="col-xl-9"></div>
                    <div className="col-xl-3"><button type="button" onClick={manageInvoices} className="btn btn-wiz right-btn" data-wizard-type="action-submit">
                        +  Shto fature
                    </button>
                    </div>
                </div>
                <div className="accordion mt5">
                    <Accordion title={"Faturat " + (values.invoices[0]?.invoicenr)} subtitle={values.invoices[0].invoicedAmountV !== '' ? (Math.round(values.invoices[0].invoicedAmountV * 100) / 100).toFixed(2) + " €" : ''} active={true} content={
                        <Invoice key={0} data={values.invoices[0]} dropkey={key} index={0} enableDelete={values.invoices.length >= 2} removeInvoice={removeInvoice} setRecords={manageRecords} removeRecord={removeRecord} />
                    } />
                </div>
                <div className="accordion">
                    {values.invoices.slice(1).map((invoice: any, index: number) => (
                        <Accordion title={"Faturat " + (invoice.invoicenr)} subtitle={(Math.round(invoice.invoicedAmountV * 100) / 100).toFixed(2) + " €"} active={false} content={
                            <Invoice key={index + 1} index={index + 1} dropkey={key} data={invoice} enableDelete={values.invoices.length > 1} removeInvoice={removeInvoice} setRecords={manageRecords} removeRecord={removeRecord} />
                        } />
                    ))}
                </div>
                <br />
                <div className="invoiceDescriptionText">
                    Rekomandohet që të gjitha kërkesat për rimbursim me vlera të ulta duhet të grumbullohen deri sa të arrijnë shumën 30.00€ - maksimum 500.00€ dhe pastaj të raportohen online. Nëse kërkesat për rimbursim brenda 60 ditesh  nuk e arrijnë këtë shumë atëherë i siguruari mund të dorëzoj kërkesën per rimbursim edhe nën këtë vlerë. Mbi vlerën 500.00€ kërkesat duhet te dorëzohen me dokumentacionin fizik origjinal në SIGAL.
                </div>
            </>
        }
    </>
    );
}
export default connect()(Invoices);
