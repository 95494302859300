import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Formik } from "formik";
import { SearchPolicyTypes } from '../../../utils/nomen';
import { AppState } from '../../../redux/reducers/rootReducer';
import { Nomen } from '../../../utils';
import DataDisplay from './DataDisplay';
import { Dispatch } from "redux";
import { FormikProps } from "formik";
import * as Yup from "yup";
import * as actionTypes from "../../../redux/actionTypes/generalActionTypes";
import { HideModalAction, PolicyPreviewResetAction, DOWNLOAD_POLICY } from "../../../redux/actionTypes/generalActionTypes";
import { hideModal, policyPreviewReset, searchPolicy, exportSearchPolicy } from "../../../redux/actionCreators/generalActionCreators";
import SimpleModal from "../../General/SimpleModal";
import { User } from '../../../redux/types';
import DatePickerFieldMain from '../../Custom/DatePickerFieldMain';
import CheckboxField from '../../Custom/CheckboxField';
import { prepareFilter } from '../../../utils/generalTypes';
import { getPlaces, getPolicyStatus } from '../../../redux/actionCreators/nomenActionCreators';
import { GetPolicyStatusAction, GetPlacesAction } from '../../../redux/actionTypes/nomenActionTypes';
import PolicyModal from './PolicyModal';
import { Loading } from '../../Layout/Loading';
import { toast, ToastContainer } from 'react-toastify';

interface FormValues {
    datesaleFrom: Date | null;
    datesaleTo: Date | null;
    fValidFrom: Date | null;
    fValidTo: Date | null;
    tValidFrom: Date | null;
    tValidTo: Date | null;
    policyType: string | null;
    shownum: string | null;
    dog: string;
    osi: string;
    regnum: string;
    chassis: string;
    policyStatusId: string;
    pageSize: number;
    pageCount: number;
    customerIsInsuredOrContractor: boolean;
}

const initialValues = {
    datesaleFrom: null,
    datesaleTo: null,
    fValidFrom: null,
    fValidTo: null,
    tValidFrom: null,
    tValidTo: null,
    policyType: "",
    shownum: "",
    dog: "",
    osi: "",
    regnum: "",
    chassis: "",
    policyStatusId: "",
    pageSize: 5,
    pageCount: 1,
    customerIsInsuredOrContractor: false
};

interface Props {
    onSearch(filter: any): void;
    closeModal(): void;
    getPolicyStatuses(): void;
    resetSelectedPolicy(): void;
    onExport(filter: any): void;
    getPlaces(): void;
}

const PolicyGrid = (props: FormikProps<FormValues> & Props) => {
    const dispatch = useDispatch();
    const showModal = useSelector<AppState, boolean>((state: any) => state.general.showModal);
    const header = useSelector<AppState, string>((state: any) => state.general.header);
    const text = useSelector<AppState, string>((state: any) => state.general.text);
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const statuses = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Statuses);
    const showPolicyModal = useSelector<AppState, boolean>((state: any) => state.general.showPolicyModal);
    const downloadLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[DOWNLOAD_POLICY]);

    const showSuccessToast = useSelector<AppState, boolean>((state: any) => state.general.showSuccessToast);
    const successToastText = useSelector<AppState, boolean>((state: any) => state.general.successToastText);

    if (!!showSuccessToast) {
        toast.success(successToastText);
        dispatch({ type: "generalActionTypes/SHOW_SUCCESS_TOAST_RESET" });
    }

    React.useEffect(() => {
        props.getPolicyStatuses();
        props.getPlaces();

        handleSubmit(initialValues);
    }, []);

    const PolicyGridSchema = Yup.object().shape({
        datesaleFrom: Yup.mixed(),
        datesaleTo: Yup.mixed(),
        fValidFrom: Yup.mixed(),
        fValidTo: Yup.mixed(),
        tValidFrom: Yup.mixed(),
        tValidTo: Yup.mixed(),
        policyType: Yup.string(),
        shownum: Yup.string(),
        dog: Yup.string(),
        osi: Yup.string(),
        regnum: Yup.string(),
        chassis: Yup.string(),
        policyStatusId: Yup.string(),
        pageSize: Yup.number(),
        pageCount: Yup.number(),
        customerIsInsuredOrContractor: Yup.bool()
    });

    const handleSubmit = (values: any) => {
        const filter = prepareFilter(values, user!.customerId);
        props.onSearch(filter);
    };

    const handleExport = (values: any) => {
        const filter = prepareFilter(values, user!.customerId);
        props.onExport(filter);
    };

    const handleCloseModal = () => {
        props.closeModal();
    };

    const emptySelectedPolicy = () => {
        props.resetSelectedPolicy();
    };

    return (<>
        <div className="card card-custom">
            <div className="card-body p-0">
                {downloadLoading && <Loading />}
                <SimpleModal show={showModal} header={header} text={text} onClose={handleCloseModal} onHide={() => { return; }} />
                <PolicyModal show={showPolicyModal} header={header} text={text} onClose={emptySelectedPolicy} onHide={() => { return; }} />

                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={true}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                    theme="colored"
                />

                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={PolicyGridSchema}
                    onSubmit={handleSubmit}
                >
                    {({ getFieldProps, handleSubmit, setFieldValue, values }) => (
                        <form onSubmit={(e: any) => {
                            setFieldValue('pageSize', 5);
                            setFieldValue('pageCount', 1);
                            handleSubmit(e);
                        }}>
                            <div className="card-header flex-wrap border-0 pt-6 pb-0 bottom-line">
                                <div className="card-title">
                                    <h3 className="card-label">
                                        Kërko polica
                                        <span className="text-muted pt-2 font-size-sm d-block">
                                            Kërkimi dhe shikimi i policave
                                        </span>
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body ">
                                {/* <!--begin::Search Form--> */}
                                <div className="mb-7">
                                    <div className="row align-items-center">
                                        <div className="col-md-4 my-2 my-md-0">
                                            <div className="d-flex align-items-center">
                                                <label className="mr-3 mb-0 d-none d-md-block">Numri i policës:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Numri i policës"
                                                    {...getFieldProps("shownum")}
                                                    id="kt_datatable_search_query" />
                                            </div>
                                        </div>
                                        <div className="col-md-2 my-2 my-md-0">
                                            <div className="d-flex align-items-center">
                                                <label className="mr-3 mb-0 d-none d-md-block">Statusi:</label>
                                                <select {...getFieldProps("policyStatusId")} className="form-control" id="kt_datatable_search_status">
                                                    <option value="">-</option>
                                                    {statuses.map(item => (<option key={item.code + "_" + item.name} value={item.code}>{item.name}</option>))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-2 my-2 my-md-0">
                                            <div className="d-flex align-items-center">
                                                <label className="mr-3 mb-0 d-none d-md-block">Shasia:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Shasia"
                                                    {...getFieldProps("chassis")}
                                                    id="kt_datatable_search_query" />
                                            </div>
                                        </div>
                                        <div className="col-md-2 my-2 my-md-0">
                                            <DatePickerFieldMain name="datesaleFrom" type="text" hideHelper placeholder="Data e shitjes prej" />
                                        </div>
                                        <div className="col-md-2 my-2 my-md-0">
                                            <DatePickerFieldMain name="datesaleTo" hideHelper type="text" placeholder="deri" />
                                        </div>

                                    </div>
                                </div>
                                <div className="mb-7">
                                    <div className="row align-items-center">

                                        <div className="col-md-4 my-2 my-md-0">
                                            <div className="d-flex align-items-center">
                                                <label className="mr-3 mb-0 d-none d-md-block">I Siguruari:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Emri / Numri personal"
                                                    {...getFieldProps("osi")}
                                                    id="kt_datatable_search_query" />
                                            </div>
                                        </div>

                                        <div className="col-md-2 my-2 my-md-0">
                                            <div className="d-flex align-items-center">
                                                <label className="mr-3 mb-0 d-none d-md-block">Lloji i policës:</label>
                                                <select {...getFieldProps("policyType")} className="form-control" id="kt_datatable_search_status">
                                                    <option key={-1} value="">-</option>
                                                    {SearchPolicyTypes.map(item => (<option key={item.code + "_" + item.name} value={item.code}>{item.name}</option>))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-2 my-2 my-md-0">
                                            <div className="d-flex align-items-center">
                                                <label className="mr-3 mb-0 d-none d-md-block">Targa:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Targa"
                                                    {...getFieldProps("regnum")}
                                                    id="kt_datatable_search_query" />
                                            </div>
                                        </div>

                                        <div className="col-md-2 my-2 my-md-0">
                                            <DatePickerFieldMain name="fValidFrom" type="text" hideHelper placeholder="Fillon prej" />

                                        </div>
                                        <div className="col-md-2 my-2 my-md-0">
                                            <DatePickerFieldMain name="fValidTo" hideHelper type="text" placeholder="deri" />
                                        </div>


                                    </div>
                                </div>
                                <div className="mb-7">
                                    <div className="row align-items-center">
                                        <div className="col-md-4 my-2 my-md-0">
                                            <div className="d-flex align-items-center">
                                                <label className="mr-3 mb-0 d-none d-md-block">Kontraktuesi:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Emri / Numri personal"
                                                    {...getFieldProps("dog")}
                                                    id="kt_datatable_search_query" />
                                            </div>
                                        </div>
                                        <div className="col-md-4 my-2 my-md-0">
                                            <div className="d-flex align-items-center">
                                                {/* <CheckboxField name="customerIsInsuredOrContractor" type="checkbox" label="Policat e mia (policat e blera nga agjentët e tjerë)" /> */}
                                            </div>
                                        </div>
                                        <div className="col-md-2 my-2 my-md-0">
                                            <DatePickerFieldMain name="tValidFrom" type="text" hideHelper placeholder="Mbaron prej" />
                                        </div>
                                        <div className="col-md-2 my-2 my-md-0">
                                            <DatePickerFieldMain name="tValidTo" type="text" hideHelper placeholder="deri" />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-7  bottom-line">
                                    <div className="row align-items-center">


                                        <div className="col-md-3 my-2 my-md-0">
                                            <button type="submit" className="btn btn-light-primary px-6 font-weight-bold">
                                                Kërko
                                            </button>
                                        </div>
                                        <div className="col-md-3 my-2 my-md-0">
                                            <button type="button" onClick={() => handleExport(values)} className="btn btn-light-primary px-6 font-weight-bold">
                                                Shtype raportin në Excel
                                            </button>
                                        </div>
                                    </div>
                                    <div className="empty-row "></div>
                                </div>
                                {/* </div> */}
                                {/* <!--end::Search Form--> */}

                                {/* <!--begin: Datatable--> */}
                                <DataDisplay onSubmit={handleSubmit} />
                                {/* <div className="datatable datatable-bordered datatable-head-custom" id="kt_datatable"></div> */}
                                {/* <!--end: Datatable--> */}
                            </div>
                        </form >
                    )}
                </Formik>
            </div>
        </div>
    </>);
}

const mapDispatchToProps = (dispatch: Dispatch<actionTypes.SearchPolicyAction
    | HideModalAction | GetPolicyStatusAction | PolicyPreviewResetAction | actionTypes.ExportSearchPolicyAction | GetPlacesAction>) => ({
        onSearch: (filter: any) => {
            dispatch(searchPolicy(filter));
        },
        closeModal: () => { dispatch(hideModal()); },
        getPolicyStatuses: () => { dispatch(getPolicyStatus()); },
        getPlaces: () => { dispatch(getPlaces()); },
        resetSelectedPolicy: () => { dispatch(policyPreviewReset()); },
        onExport: (filter: any) => {
            dispatch(exportSearchPolicy(filter));
        },
    });

export default connect(null, mapDispatchToProps)(PolicyGrid);