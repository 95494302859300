import { FormikWizardStepType } from '../../../utils';
import { object, string, mixed, StringSchema, number, bool } from 'yup';
import moment from 'moment';

import Account from './Account';
import Contact from './Contact';
import Info from './Info';

export default [
    {
        id: 'account',
        component: Account,
        name: 'Llogaria',
        description: 'Enter account data',
        initialValues: {
            tipKind: '3',
            clientno: '',
            nipt: '',
            title: '',
            lastName: '',
            sex: '',
            birthdate: '',
            password: '',
            accept_terms: false
        },
        validationSchema: object().shape({
            tipKind: string().required('E domosdoshme'),
            clientno: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            nipt: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '2' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            title: string()
                .min(3, "Minimumi 3 simbole")
                .max(50, "Maksimumi 50 simbole")
                .required("E domosdoshme"),
            lastName: string()
                .when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                    return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
                }),
            sex: string(),
            birthdate: mixed().test(
                'validBirthDate',
                'Data e lindjes nuk mund te jete pas dates se sotshme',
                function (this: any, value: any) {
                    if (moment(value, 'dd/MM/yyyy').isValid())
                        return moment(value, 'dd/MM/yyyy').isBefore(moment());
                    return true;
                }).when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                    return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
                }),
            password: string()
                .min(6, "Minimum 6 symbols")
                .max(50, "Maksimumi 50 simbole")
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
                    "Duhet te permbaje 6 karaktere, nje shkronje te madhe, nje shkronje te vogel, nje numer dhe nje karakter"
                )
                .required('E domosdoshme'),
            accept_terms: bool().oneOf([true], 'E domosdoshme')
        }),
        actionLabel: 'Vazhdo',
    },
    {
        id: 'contact',
        component: Contact,
        name: 'Contact',
        description: 'Ju lutem vendosni te dhenat e kontaktit me poshte',
        initialValues: {
            address: '',
            place: '',
            mob: '',
            email: ''
        },
        validationSchema: object().shape({
            address: string(),
            place: string().required(),
            mob: number().required('E domosdoshme').typeError("Lejohen vetem numrat"),
            email: string()
                .email("Email jo I vlefshem")
                .required("E domosdoshme"),
        }),
        actionLabel: 'Vazhdo',
    },
    {
        id: 'info',
        component: Info,
        name: 'Info',
        description: 'Check data and submit',
        initialValues: {
            acceptTerms: false,
            captchaGenerated: '',
            captchaInputted: ''
        },
        validationSchema: object().shape({
            acceptTerms: bool().oneOf([true], 'E domosdoshme'),
            captchaGenerated: string(),
            captchaInputted: string().required('E domosdhme')
                .test(
                    'validCaptcha',
                    'Captcha eshte gabim',
                    function (this: any, value: any) {
                        if (value !== '')
                            return this.parent.captchaGenerated === value;
                        return true;
                    })
        }),
    },
] as FormikWizardStepType[];