import { CheckProfileDto } from '../../utils';
import { User, Customer } from './../types';

export const SET_USER = "authActionTypes/SET_USER";
export interface SetUserAction {
  type: typeof SET_USER;
  user: User;
}

export const LOGIN_USER = "authActionTypes/LOGIN_USER";
export interface LoginUserAction {
  type: typeof LOGIN_USER;
  email: string;
  password: string;
  skipRedirect: boolean;
}

export const LOGIN_USER_REQUEST = "authActionTypes/LOGIN_USER_REQUEST";
export interface LoginUserRequestAction {
  type: typeof LOGIN_USER_REQUEST;
}

export const LOGIN_USER_SUCCESS = "authActionTypes/LOGIN_USER_SUCCESS";
export interface LoginUserSuccessAction {
  type: typeof LOGIN_USER_SUCCESS;
  user: User;
}

export const LOGIN_USER_FAILURE = "authActionTypes/LOGIN_USER_FAILURE";
export interface LoginUserFailureAction {
  type: typeof LOGIN_USER_FAILURE;
  error: Error | string;
}

export const RESEND_MAIL = "authActionTypes/RESEND_MAIL";
export interface ResendMailAction {
  type: typeof RESEND_MAIL;
  resend: boolean;
}


export const REGISTER_USER = "authActionTypes/REGISTER_USER";
export interface RegisterUserAction {
  type: typeof REGISTER_USER;
  tipKind: string;
  clientno: string;
  nipt: string;
  title: string;
  lastname: string;
  sex: string;
  email: string;
  mob: string;
  address: string;
  place: string;
  birthdate: string;
  password: string;
}

export const REGISTER_USER_REQUEST = "authActionTypes/REGISTER_USER_REQUEST";
export interface RegisterUserRequestAction {
  type: typeof REGISTER_USER_REQUEST;
}

export const REGISTER_USER_SUCCESS = "authActionTypes/REGISTER_USER_SUCCESS";
export interface RegisterUserSuccessAction {
  type: typeof REGISTER_USER_SUCCESS;
}

export const REGISTER_USER_FAILURE = "authActionTypes/REGISTER_USER_FAILURE";
export interface RegisterUserFailureAction {
  type: typeof REGISTER_USER_FAILURE;
  error: Error | string;
}

export const REQUEST_PASSWORD = "authActionTypes/REQUEST_PASSWORD";
export interface RequestPasswordAction {
  type: typeof REQUEST_PASSWORD;
  email: string;
}

export const REQUEST_PASSWORD_REQUEST = "authActionTypes/REQUEST_PASSWORD_REQUEST";
export interface RequestPasswordRequestAction {
  type: typeof REQUEST_PASSWORD_REQUEST;
}

export const REQUEST_PASSWORD_SUCCESS = "authActionTypes/REQUEST_PASSWORD_SUCCESS";
export interface RequestPasswordSuccessAction {
  type: typeof REQUEST_PASSWORD_SUCCESS;
}

export const REQUEST_PASSWORD_FAILURE = "authActionTypes/REQUEST_PASSWORD_FAILURE";
export interface RequestPasswordFailureAction {
  type: typeof REQUEST_PASSWORD_FAILURE;
  error: Error | string;
}

export const UPDATE_CUSTOMER = "authActionTypes/UPDATE_CUSTOMER";
export interface UpdateCustomerAction {
  type: typeof UPDATE_CUSTOMER;
  customer: Customer;
}

export const UPDATE_CUSTOMER_REQUEST = "authActionTypes/UPDATE_CUSTOMER_REQUEST";
export interface UpdateCustomerRequestAction {
  type: typeof UPDATE_CUSTOMER_REQUEST;
}

export const UPDATE_CUSTOMER_SUCCESS = "authActionTypes/UPDATE_CUSTOMER_SUCCESS";
export interface UpdateCustomerSuccessAction {
  type: typeof UPDATE_CUSTOMER_SUCCESS;
}

export const UPDATE_CUSTOMER_FAILURE = "authActionTypes/UPDATE_CUSTOMER_FAILURE";
export interface UpdateCustomerFailureAction {
  type: typeof UPDATE_CUSTOMER_FAILURE;
  error: Error | string;
}

export const CHANGE_PASSWORD = "authActionTypes/CHANGE_PASSWORD";
export interface ChangePasswordAction {
  type: typeof CHANGE_PASSWORD;
  data: { id: string, oldPassword: string, newPassword: string, confirmNewPassword: string };
}

export const CHANGE_PASSWORD_REQUEST = "authActionTypes/CHANGE_PASSWORD_REQUEST";
export interface ChangePasswordRequestAction {
  type: typeof CHANGE_PASSWORD_REQUEST;
}

export const CHANGE_PASSWORD_SUCCESS = "authActionTypes/CHANGE_PASSWORD_SUCCESS";
export interface ChangePasswordSuccessAction {
  type: typeof CHANGE_PASSWORD_SUCCESS;
}

export const CHANGE_PASSWORD_FAILURE = "authActionTypes/CHANGE_PASSWORD_FAILURE";
export interface ChangePasswordFailureAction {
  type: typeof CHANGE_PASSWORD_FAILURE;
  error: Error | string;
}

export const GET_CUSTOMER = "authActionTypes/GET_CUSTOMER";
export interface GetCustomerAction {
  type: typeof GET_CUSTOMER;
  customerId: string;
}

export const GET_CUSTOMER_REQUEST = "authActionTypes/GET_CUSTOMER_REQUEST";
export interface GetCustomerRequestAction {
  type: typeof GET_CUSTOMER_REQUEST;
}

export const GET_CUSTOMER_RESET = "authActionTypes/GET_CUSTOMER_RESET";
export interface GetCustomerResetAction {
  type: typeof GET_CUSTOMER_RESET;
}

export const GET_CUSTOMER_SUCCESS = "authActionTypes/GET_CUSTOMER_SUCCESS";
export interface GetCustomerSuccessAction {
  type: typeof GET_CUSTOMER_SUCCESS;
  data: Customer;
}

export const GET_CUSTOMER_FAILURE = "authActionTypes/GET_CUSTOMER_FAILURE";
export interface GetCustomerFailureAction {
  type: typeof GET_CUSTOMER_FAILURE;
  error: Error | string;
}

export const CHECK_ACTIVE_SESSION = "authActionTypes/CHECK_ACTIVE_SESSION";
export interface CheckActiveSessionAction {
  type: typeof CHECK_ACTIVE_SESSION;
  token: string;
  customerId: string;
}

export const CHECK_ACTIVE_SESSION_REQUEST = "authActionTypes/CHECK_ACTIVE_SESSION_REQUEST";
export interface CheckActiveSessionRequestAction {
  type: typeof CHECK_ACTIVE_SESSION_REQUEST;
}

export const CHECK_ACTIVE_SESSION_RESET = "authActionTypes/CHECK_ACTIVE_SESSION_RESET";
export interface CheckActiveSessionResetAction {
  type: typeof CHECK_ACTIVE_SESSION_RESET;
}

export const CHECK_ACTIVE_SESSION_SUCCESS = "authActionTypes/CHECK_ACTIVE_SESSION_SUCCESS";
export interface CheckActiveSessionSuccessAction {
  type: typeof CHECK_ACTIVE_SESSION_SUCCESS;
  authenticated: boolean;
}

export const CHECK_ACTIVE_SESSION_FAILURE = "authActionTypes/CHECK_ACTIVE_SESSION_FAILURE";
export interface CheckActiveSessionFailureAction {
  type: typeof CHECK_ACTIVE_SESSION_FAILURE;
  error: Error | string;
}


export const LOGOUT_USER = "authActionTypes/LOGOUT_USER";
export interface LogoutUserAction {
  type: typeof LOGOUT_USER;
  token: string;
}

export const LOGOUT_USER_REQUEST = "authActionTypes/LOGOUT_USER_REQUEST";
export interface LogoutUserRequestAction {
  type: typeof LOGOUT_USER_REQUEST;
}

export const LOGOUT_USER_SUCCESS = "authActionTypes/LOGOUT_USER_SUCCESS";
export interface LogoutUserSuccessAction {
  type: typeof LOGOUT_USER_SUCCESS;
}

export const LOGOUT_USER_FAILURE = "authActionTypes/LOGOUT_USER_FAILURE";
export interface LogoutUserFailureAction {
  type: typeof LOGOUT_USER_FAILURE;
  error: Error | string;
}

export const HAS_PROFILE = "authActionTypes/HAS_PROFILE";
export interface HasProfileAction {
  type: typeof HAS_PROFILE;
  email: string;
}

export const HAS_PROFILE_REQUEST = "authActionTypes/HAS_PROFILE_REQUEST";
export interface HasProfileRequestAction {
  type: typeof HAS_PROFILE_REQUEST;
}

export const HAS_PROFILE_RESET = "authActionTypes/HAS_PROFILE_RESET";
export interface HasProfileResetAction {
  type: typeof HAS_PROFILE_RESET;
}

export const HAS_PROFILE_SUCCESS = "authActionTypes/HAS_PROFILE_SUCCESS";
export interface HasProfileSuccessAction {
  type: typeof HAS_PROFILE_SUCCESS;
  checkProfile: CheckProfileDto;
}

export const HAS_PROFILE_FAILURE = "authActionTypes/HAS_PROFILE_FAILURE";
export interface HasProfileFailureAction {
  type: typeof HAS_PROFILE_FAILURE;
  error: Error | string;
}

export const CONFIRM_VERIFICATION_CODE = "authActionTypes/CONFIRM_VERIFICATION_CODE";
export interface ConfirmVerificationCodeAction {
  type: typeof CONFIRM_VERIFICATION_CODE;
  verificationCode: string;
  email: string;
}

export const CONFIRM_VERIFICATION_CODE_RESET = "authActionTypes/CONFIRM_VERIFICATION_CODE_RESET";
export interface ConfirmVerificationCodeResetAction {
  type: typeof CONFIRM_VERIFICATION_CODE_RESET;
}

export const CONFIRM_VERIFICATION_CODE_REQUEST = "authActionTypes/CONFIRM_VERIFICATION_CODE_REQUEST";
export interface ConfirmVerificationCodeRequestAction {
  type: typeof CONFIRM_VERIFICATION_CODE_REQUEST;
}

export const CONFIRM_VERIFICATION_CODE_SUCCESS = "authActionTypes/CONFIRM_VERIFICATION_CODE_SUCCESS";
export interface ConfirmVerificationCodeSuccessAction {
  type: typeof CONFIRM_VERIFICATION_CODE_SUCCESS;
}

export const CONFIRM_VERIFICATION_CODE_FAILURE = "authActionTypes/CONFIRM_VERIFICATION_CODE_FAILURE";
export interface ConfirmVerificationCodeFailureAction {
  type: typeof CONFIRM_VERIFICATION_CODE_FAILURE;
  error: Error | string;
}

export const RESEND_VERIFICATION_CODE = "authActionTypes/RESEND_VERIFICATION_CODE";
export interface ResendVerificationCodeAction {
  type: typeof RESEND_VERIFICATION_CODE;
  email: string;
}

export const RESEND_VERIFICATION_CODE_RESET = "authActionTypes/RESEND_VERIFICATION_CODE_RESET";
export interface ResendVerificationCodeResetAction {
  type: typeof RESEND_VERIFICATION_CODE_RESET;
}

export const RESEND_VERIFICATION_CODE_REQUEST = "authActionTypes/RESEND_VERIFICATION_CODE_REQUEST";
export interface ResendVerificationCodeRequestAction {
  type: typeof RESEND_VERIFICATION_CODE_REQUEST;
}

export const RESEND_VERIFICATION_CODE_SUCCESS = "authActionTypes/RESEND_VERIFICATION_CODE_SUCCESS";
export interface ResendVerificationCodeSuccessAction {
  type: typeof RESEND_VERIFICATION_CODE_SUCCESS;
}

export const RESEND_VERIFICATION_CODE_FAILURE = "authActionTypes/RESEND_VERIFICATION_CODE_FAILURE";
export interface ResendVerificationCodeFailureAction {
  type: typeof RESEND_VERIFICATION_CODE_FAILURE;
  error: Error | string;
}

export const AUTO_REGISTER_USER = "authActionTypes/AUTO_REGISTER_USER";
export interface AutoRegisterUserAction {
  type: typeof AUTO_REGISTER_USER;
  tipKind: string;
  clientno: string;
  nipt: string;
  title: string;
  lastname: string;
  sex: string;
  email: string;
  mob: string;
  address: string;
  place: string;
  birthdate: string;
  password: string;
}

export const AUTO_REGISTER_USER_RESET = "authActionTypes/AUTO_REGISTER_USER_RESET";
export interface AutoRegisterUserResetAction {
  type: typeof AUTO_REGISTER_USER_RESET;
}

export const AUTO_REGISTER_USER_REQUEST = "authActionTypes/AUTO_REGISTER_USER_REQUEST";
export interface AutoRegisterUserRequestAction {
  type: typeof AUTO_REGISTER_USER_REQUEST;
}

export const AUTO_REGISTER_USER_SUCCESS = "authActionTypes/AUTO_REGISTER_USER_SUCCESS";
export interface AutoRegisterUserSuccessAction {
  type: typeof AUTO_REGISTER_USER_SUCCESS;
}

export const AUTO_REGISTER_USER_FAILURE = "authActionTypes/AUTO_REGISTER_USER_FAILURE";
export interface AutoRegisterUserFailureAction {
  type: typeof AUTO_REGISTER_USER_FAILURE;
  error: Error | string;
}

export const AUTO_LOGIN_USER = "authActionTypes/AUTO_LOGIN_USER";
export interface AutoLoginUserAction {
  type: typeof AUTO_LOGIN_USER;
  email: string;
}

export const AUTO_LOGIN_USER_RESET = "authActionTypes/AUTO_LOGIN_USER_RESET";
export interface AutoLoginUserResetAction {
  type: typeof AUTO_LOGIN_USER_RESET;
}

export const AUTO_LOGIN_USER_REQUEST = "authActionTypes/AUTO_LOGIN_USER_REQUEST";
export interface AutoLoginUserRequestAction {
  type: typeof AUTO_LOGIN_USER_REQUEST;
}

export const AUTO_LOGIN_USER_SUCCESS = "authActionTypes/AUTO_LOGIN_USER_SUCCESS";
export interface AutoLoginUserSuccessAction {
  type: typeof AUTO_LOGIN_USER_SUCCESS;
  user: User;
}

export const AUTO_LOGIN_USER_FAILURE = "authActionTypes/AUTO_LOGIN_USER_FAILURE";
export interface AutoLoginUserFailureAction {
  type: typeof AUTO_LOGIN_USER_FAILURE;
  error: Error | string;
}

export type AuthAction =
  | SetUserAction
  | LoginUserAction
  | LoginUserRequestAction
  | LoginUserSuccessAction
  | LoginUserFailureAction
  | RegisterUserAction
  | RegisterUserRequestAction
  | RegisterUserSuccessAction
  | RegisterUserFailureAction
  | RequestPasswordAction
  | RequestPasswordRequestAction
  | RequestPasswordSuccessAction
  | RequestPasswordFailureAction
  | LogoutUserAction
  | LogoutUserRequestAction
  | LogoutUserSuccessAction
  | LogoutUserFailureAction
  | UpdateCustomerAction
  | UpdateCustomerRequestAction
  | UpdateCustomerSuccessAction
  | UpdateCustomerFailureAction
  | ChangePasswordAction
  | ChangePasswordRequestAction
  | ChangePasswordSuccessAction
  | ChangePasswordFailureAction
  | GetCustomerAction
  | GetCustomerRequestAction
  | GetCustomerSuccessAction
  | GetCustomerFailureAction
  | ResendMailAction
  | CheckActiveSessionAction
  | CheckActiveSessionRequestAction
  | CheckActiveSessionSuccessAction
  | CheckActiveSessionFailureAction
  | HasProfileAction
  | HasProfileRequestAction
  | HasProfileResetAction
  | HasProfileSuccessAction
  | HasProfileFailureAction
  | ConfirmVerificationCodeAction
  | ConfirmVerificationCodeResetAction
  | ConfirmVerificationCodeRequestAction
  | ConfirmVerificationCodeSuccessAction
  | ConfirmVerificationCodeFailureAction
  | ResendVerificationCodeAction
  | ResendVerificationCodeResetAction
  | ResendVerificationCodeRequestAction
  | ResendVerificationCodeSuccessAction
  | ResendVerificationCodeFailureAction
  | AutoRegisterUserAction
  | AutoRegisterUserResetAction
  | AutoRegisterUserRequestAction
  | AutoRegisterUserSuccessAction
  | AutoRegisterUserFailureAction
  | AutoLoginUserAction
  | AutoLoginUserResetAction
  | AutoLoginUserRequestAction
  | AutoLoginUserSuccessAction
  | AutoLoginUserFailureAction
