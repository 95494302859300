import React, { FunctionComponent } from 'react';
import { useField, useFormikContext } from 'formik';

interface Props {
    name: string;
    type: string;
    label: string;
    newvalue?: string;
    index?: number;
    change?: (value: any, index: any) => void;
    disabled?: boolean | false
}

const CheckboxFieldMain: FunctionComponent<Props> = (props) => {
    const [
        field,
        { error, touched },
    ] = useField({
        name: props.name,
        type: props.type,
    });

    const { setFieldValue } = useFormikContext();

    React.useEffect(() => {
        if (props.newvalue !== undefined) {
            setFieldValue(props.name, props.newvalue);
        }
    }, [setFieldValue, props.name, props.newvalue]);

    const handleChange = (value: any) => {
        if (!!props.change && !props.disabled) {
            setFieldValue(props.name, value);
            props.change(value, props.index);
        }
    }

    return (
        <div className="form-group">
            <label className="checkbox">
                <input {...field} {...props}
                    className="m-1"
                    style={{ opacity: 1, zIndex: 5, position: "relative" }}
                    onChange={(event: any) => handleChange(event.target.checked)}
                />
                {props.label}
            </label>
            {touched && error !== "" ? (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{error}</div>
                </div>
            ) : null}
        </div>
    );
};

export default CheckboxFieldMain;