import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { useFormikWizard } from 'formik-wizard';
import { Loading } from '../../Layout/Loading';
import moment from 'moment';
import CheckboxField from '../../Custom/CheckboxField';
import * as authActionTypes from '../../../redux/actionTypes/authActionTypes';
import * as piActionTypes from '../../../redux/actionTypes/piPolicyActionTypes';
import { AppState } from '../../../redux/reducers/rootReducer';
import { Nomen } from '../../../utils';
import { Gender } from '../../../utils/nomen';
import PdfProperty from "../../../../src/InsuranceConditions/09_new.pdf";
import { TypeObjects } from '../../../utils/nomen';
import { ThInsQuotation } from '../../../utils/policyTypes';
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import CaptchaGenerator from '../../Custom/CaptchaGenerator';
import { useFormikContext } from "formik";
import InputField from '../../Custom/InputField';

const Verification = () => {
    const { values: wizardValues }: any = useFormikWizard();
    const { setFieldValue } = useFormikContext();
    const isLoadingHasProfile = useSelector<AppState, boolean>((state: any) => state.isLoading[authActionTypes.HAS_PROFILE]);
    const isLoadingBookPolicy = useSelector<AppState, boolean>((state: any) => state.isLoading[piActionTypes.BOOK_PI_POLICY]);
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const chosenPIQuotation = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.chosenPIQuotation);
    const piPackageQuotation = chosenPIQuotation ? chosenPIQuotation[0] : null;
    const travelDetails = wizardValues.piMain;
    const insuredPerson = wizardValues.insuredPerson;
    const isLegalEntity = wizardValues.insuredPerson.tipKind === '2';

    const useStyles = makeStyles(theme => ({
        customTooltip: {
            backgroundColor: '#3699FF',
            fontSize: '10px',
            opacity: '0.6 !important'
        }
    }));

    const classes = useStyles();

    const result = (text: string) => {
        setFieldValue('captchaGenerated', text);
    }

    return (<>
        {isLoadingHasProfile
            ? <Loading />
            : <>
                {isLoadingBookPolicy && <Loading />}
                <div className="text-dark-50 line-height-lg">
                    <div className="wizardTitle mb10">Verifikoni detajet e pronës</div>
                    <div className="verifyTitle">Lloji i objektit të sigurimit</div><div className="verifyValue">{TypeObjects.filter((p: Nomen) => p.code === travelDetails.objectType)[0]?.name}</div>
                    <div className="verifyTitle">Metër katro</div><div className="verifyValue">{travelDetails.area}</div>
                    <div className="verifyTitle">Viti i ndërtimit</div><div className="verifyValue"> {travelDetails.buildYear}</div>
                    <div className="verifyTitle">Adresa e objektit qe sigurohet</div><div className="verifyValue"> {travelDetails.objectAddress}</div>
                    <div className="verifyTitle">Kupon</div><div className="verifyValue"> {travelDetails.coupon ? travelDetails.coupon : '/'}</div>
                    <div className="verifyTitle">Pajisje shtesë</div><div className="verifyValue"> {travelDetails.inventoryInsuredAmount}</div>
                </div>

                <div className="text-dark-50 line-height-lg">
                    <div className="wizardTitle mt20 mb10">Verifikoni informacionin e personit të siguruar</div>
                    {!isLegalEntity && <><div className="verifyTitle">Numri personal</div><div className="verifyValue"> {insuredPerson.clientNo}</div></>}
                    {isLegalEntity && <><div className="verifyTitle">Nipt</div><div className="verifyValue"> {insuredPerson.nipt}</div></>}
                    <div className="verifyTitle">Emri</div><div className="verifyValue">{insuredPerson.title}</div>
                    {!isLegalEntity && <><div className="verifyTitle">Mbiemri</div><div className="verifyValue">{insuredPerson.lastName}</div></>}
                    {!isLegalEntity && <><div className="verifyTitle">Gjinia</div><div className="verifyValue">{Gender.filter((g: Nomen) => g.code === insuredPerson.sex)[0]?.name}</div></>}
                    <div className="verifyTitle">Adresa</div><div className="verifyValue">{insuredPerson.address}</div>
                    <div className="verifyTitle">Vendi</div><div className="verifyValue">{places.filter((p: Nomen) => p.code === insuredPerson.place)[0]?.name}</div>
                    <div className="verifyTitle">Email</div><div className="verifyValue">{insuredPerson.email}</div>
                    <div className="verifyTitle">Celulari</div><div className="verifyValue">{insuredPerson.celphone}</div>
                    {!isLegalEntity && <><div className="verifyTitle">Datëlindja</div><div className="verifyValue"> {moment(insuredPerson.birthDate).format('DD/MM/yyyy')}</div></>}
                </div>
                <hr />
                {piPackageQuotation &&
                    <div className="text-dark-50 line-height-lg packageInfoMobile">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">{piPackageQuotation.packageName}</h5>
                                <p className="card-text pSum"><small>Shuma e siguruar: {piPackageQuotation.insuredAmountV.toLocaleString()}€</small></p>
                                {piPackageQuotation.covers.map((cover: any, indexC: number) => (
                                    <p key={indexC + 1} className="card-text">
                                        <span className='pCheck'>✔</span> {cover}
                                    </p>
                                ))}
                            </div>
                            <div className="card-footer">
                                {piPackageQuotation.packageDiscount > 0 &&
                                    <p className='pDiscount'>
                                        {piPackageQuotation.totalVNoDiscount.toFixed(2)}{piPackageQuotation.currencyName ? piPackageQuotation.currencyName : '€'}

                                        <Tooltip
                                            arrow
                                            title={"Aplikohet zbritja prej " + (piPackageQuotation.packageDiscount) + "% ne kete police"}
                                            placement="top"
                                            classes={{
                                                tooltip: classes.customTooltip
                                            }}>
                                            <IconButton className="blue">
                                                <Info />
                                            </IconButton>
                                        </Tooltip>
                                    </p>}
                                <p className='pTotal'>
                                    {parseFloat(piPackageQuotation.totalV).toFixed(2)}  {piPackageQuotation.currencyName ? piPackageQuotation.currencyName : '€'}
                                </p>
                            </div>
                        </div>
                        <hr />
                    </div>}
                <div className="row">
                    <div className="col-xl-6">
                        <CheckboxField name="acceptTerms" type="checkbox" label="I kuptoj dhe I pranoj kushtet e sigurimit" />
                    </div>
                    <div className="col-xl-6">
                        <a href={PdfProperty} target="_blank">Kushtet e përgjithshme të sigurimit</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <CaptchaGenerator result={result} />
                    </div>
                    <div className="col-xl-12">
                        <InputField name="captchaInputted" type="text" placeholder="Shkruaj kodim këtu" label="Kodi i sigurise" />
                    </div>
                </div>
            </>
        }
    </>
    );
}
export default connect()(Verification);
