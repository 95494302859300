import { MotorInsQuotation, BookingData, MotorPolicy } from '../../utils/policyTypes';

export const GET_AMFGC_QUOTATION = "policyActionTypes/GET_AMFGC_QUOTATION";
export interface GetAMFGCQuotationAction {
  type: typeof GET_AMFGC_QUOTATION;
  customerId: string;
  platenumber: string;
  chassis: string;
  insuredPeriod: string;
  isCustomerVehicleOwner: boolean;
}

export const GET_AMFGC_QUOTATION_REQUEST = "policyActionTypes/GET_AMFGC_QUOTATION_REQUEST";
export interface GetAMFGCQuotationRequestAction {
  type: typeof GET_AMFGC_QUOTATION_REQUEST;
}

export const GET_AMFGC_QUOTATION_RESET = "policyActionTypes/GET_AMFGC_QUOTATION_RESET";
export interface GetAMFGCQuotationResetAction {
  type: typeof GET_AMFGC_QUOTATION_RESET;
}

export const GET_AMFGC_QUOTATION_SUCCESS = "policyActionTypes/GET_AMFGC_QUOTATION_SUCCESS";
export interface GetAMFGCQuotationSuccessAction {
  type: typeof GET_AMFGC_QUOTATION_SUCCESS;
  data: MotorInsQuotation;
}

export const GET_AMFGC_QUOTATION_FAILURE = "policyActionTypes/GET_AMFGC_QUOTATION_FAILURE";
export interface GetAMFGCQuotationFailureAction {
  type: typeof GET_AMFGC_QUOTATION_FAILURE;
  error: Error | string;
}

export const GET_GC_QUOTATION = "policyActionTypes/GET_GC_QUOTATION";
export interface GetGCQuotationAction {
  type: typeof GET_GC_QUOTATION;
  customerId: string;
  gcVehicleCategoryClass: string;
  prodyear: number;
  power: number;
  insuredPeriod: string;
}

export const GET_GC_QUOTATION_REQUEST = "policyActionTypes/GET_GC_QUOTATION_REQUEST";
export interface GetGCQuotationRequestAction {
  type: typeof GET_GC_QUOTATION_REQUEST;
}

export const GET_GC_QUOTATION_RESET = "policyActionTypes/GET_GC_QUOTATION_RESET";
export interface GetGCQuotationResetAction {
  type: typeof GET_GC_QUOTATION_RESET;
}

export const GET_GC_QUOTATION_SUCCESS = "policyActionTypes/GET_GC_QUOTATION_SUCCESS";
export interface GetGCQuotationSuccessAction {
  type: typeof GET_GC_QUOTATION_SUCCESS;
  data: MotorInsQuotation;
}

export const GET_GC_QUOTATION_FAILURE = "policyActionTypes/GET_GC_QUOTATION_FAILURE";
export interface GetGCQuotationFailureAction {
  type: typeof GET_GC_QUOTATION_FAILURE;
  error: Error | string;
}

export const BOOK_GC_POLICY = "policyActionTypes/BOOK_GC_POLICY";
export interface BookGCPolicyAction {
  type: typeof BOOK_GC_POLICY;
  policy: MotorPolicy;
  payment: string;
}

export const BOOK_GC_POLICY_REQUEST = "policyActionTypes/BOOK_GC_POLICY_REQUEST";
export interface BookGCPolicyRequestAction {
  type: typeof BOOK_GC_POLICY_REQUEST;
}

export const BOOK_GC_POLICY_SUCCESS = "policyActionTypes/BOOK_GC_POLICY_SUCCESS";
export interface BookGCPolicySuccessAction {
  type: typeof BOOK_GC_POLICY_SUCCESS;
  data: BookingData;
}

export const BOOK_GC_POLICY_FAILURE = "policyActionTypes/BOOK_GC_POLICY_FAILURE";
export interface BookGCPolicyFailureAction {
  type: typeof BOOK_GC_POLICY_FAILURE;
  error: Error | string;
}

export type PolicyAction =
  | GetAMFGCQuotationAction
  | GetAMFGCQuotationRequestAction
  | GetAMFGCQuotationResetAction
  | GetAMFGCQuotationSuccessAction
  | GetAMFGCQuotationFailureAction
  | GetGCQuotationAction
  | GetGCQuotationRequestAction
  | GetGCQuotationResetAction
  | GetGCQuotationSuccessAction
  | GetGCQuotationFailureAction
  | BookGCPolicyAction
  | BookGCPolicyRequestAction
  | BookGCPolicySuccessAction
  | BookGCPolicyFailureAction