import { FormikWizardStepType } from '../../../utils/wizardTypes';
import { object, string, date, bool } from 'yup';
import store from '../../../redux/configureStore';
import moment from 'moment';
import Packages from './Packages';
import * as generalActions from '../../../redux/actionCreators/generalActionCreators';
import { CheckProfileDto } from '../../../utils';
import Verification from './Verification';
import InsuredPerson from './InsuredPerson';

export default [
    {
        id: 'package',
        component: Packages,
        name: 'Paketat',
        description: '',
        initialValues: {
            aifPackageQuotation: '',
            personalDataConsent: false
        },
        validationSchema: object().shape({
        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
        }
    },
    {
        id: 'insuredPerson',
        component: InsuredPerson,
        name: 'I siguruari',
        description: '',
        initialValues: {
            clientNo: '',
            title: '',
            lastName: '',
            sex: '',
            address: '',
            place: '',
            email: '',
            celphone: '+383',
            birthDate: '',
            couponAnswer: '',
            coupon: '',
            passportPhoto: []
        },
        validationSchema: object().shape({
            clientNo: string().required('E domosdoshme'),
            title: string().required('E domosdoshme'),
            lastName: string().required('E domosdoshme'),
            sex: string().required('E domosdoshme'),
            address: string().required('E domosdoshme'),
            place: string().required('E domosdoshme'),
            email: string()
                .email("Format I gabuar I email-it")
                .min(3, "Minimumi 3 simbole")
                .max(50, "Maksimumi 50 simbole")
                .required("E domosdoshme"),
            celphone: string().required('E domosdoshme').min(10, "Minimumi 10 simbole"),
            birthDate: date().required('E domosdoshme').typeError('E domosdoshme')
                .test(
                    'validBirthDate',
                    'Data e lindjes nuk mund të jetë pas ditës së sotme',
                    function (this: any, value: any) {
                        return moment(value, 'dd/MM/yyyy').isBefore(moment());
                    })
                .test(
                    'validBirthDateValue',
                    'Personat mbi moshën 64 vjeçare duhet të paraqiten në zyrat e SIGAL-it për t\'u pajisur me policën e sigurimit per Lejeqëndrimi',
                    function (this: any, value: any) {
                        const age = moment().diff(value, 'years');
                        return age <= 64;
                    })
        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
            if (sectionValues.passportPhoto.length === 0) {
                store.dispatch(generalActions.showModal("Dështimi", "Duhet të ngarkoni foto të pasaportës!", ""));
                return;
            }

            const state = store.getState();
            const checkProfile = state.auth.checkProfile as CheckProfileDto;
            const user = state.auth.user;

            const continueWithoutAccount = state.general.continueWithoutAccount;
            if (continueWithoutAccount)
                return;

            if (!user && checkProfile.hasProfile && checkProfile.confirmedMail) {
                store.dispatch(generalActions.showLoginModal(sectionValues.email));
                return;
            }

            if (!user && checkProfile.hasProfile && !checkProfile.confirmedMail) {
                store.dispatch(generalActions.showVerificationModal());
                return;
            }

            if (!user && !checkProfile.hasProfile) {
                store.dispatch(generalActions.showCreateAccountQuestionModal(sectionValues));
                return;
            }
        }
    },
    {
        id: 'verification',
        component: Verification,
        name: 'Verifiko',
        description: '',
        initialValues: {
            acceptTerms: false,
            captchaGenerated: '',
            captchaInputted: ''
        },
        validationSchema: object().shape({
            acceptTerms: bool().oneOf([true], 'E domosdoshme'),
            captchaGenerated: string(),
            captchaInputted: string().required('E domosdoshme')
                .test(
                    'validCaptcha',
                    'Captcha eshte gabim',
                    function (this: any, value: any) {
                        if (value !== '')
                            return this.parent.captchaGenerated === value;

                        return true;
                    })
        }),
        actionLabel: 'Vazhdo',

    },
] as FormikWizardStepType[];
