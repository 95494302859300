import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { reduxBatch } from '@manaflair/redux-batch';
import rootReducer from './reducers/rootReducer';
import rootSaga from "./sagas/rootSaga";
import { persistStore, persistReducer,  } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { createBrowserHistory } from 'history';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import { Reducer } from 'react';

export const history = createBrowserHistory();
const routerMiddleware = createRouterMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middleware = [
    ...getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        thunk: true
    }),
    sagaMiddleware,
    routerMiddleware
];
  
const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['auth', 'nomen', 'policy'],
    stateReconciler: autoMergeLevel2
};
const store = configureStore({
    reducer:  persistReducer(persistConfig, rootReducer(history) as Reducer<any, any>),
    middleware,
    devTools: process.env.NODE_ENV !== "production",
    enhancers: [reduxBatch]
});


export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export default store;

