import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { DropzoneArea } from 'material-ui-dropzone';
import { useFormikContext } from 'formik';
import { AppState } from '../../../../redux/reducers/rootReducer';
import * as actionTypes from '../../../../redux/actionTypes/healthPreClaimActionTypes';
import { Loading } from '../../../Layout/Loading';
import CheckboxField from '../../../Custom/CheckboxField';

const UploadHealthDocuments = () => {
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.REGISTER_HEALTH_PRECLAIM]);
    const { values }: any = useFormikContext();

    const {
        setFieldValue,
    } = useFormikContext();

    const handleSave = (files: any) => {
        setFieldValue('claimFiles', files);
    }

    return (<>
        {isLoading
            ? <Loading />
            :
            <>
                <div className="wizardTitle">Dokumente</div>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div className="mb-10 font-weight-bold text-dark">Dokumente <a href="https://sigal-ks.com/wp-content/uploads/2022/08/DOKUMENTACIONI-I-NEVOJSHEM-ME-RASTIN-E-KERKESES-PER-RIMBURSIM-SH.P.-ONLINE.pdf" target="_blank">   (Dokumentet qe kërkohen)</a></div>
                    <div>
                        <DropzoneArea
                            onChange={handleSave}
                            onDelete={handleSave}
                            acceptedFiles={['application/pdf', 'application/msword',
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                'application/vnd.rar', 'text/plain', '	application/zip',
                                'application/x-7z-compressed', 'image/jpeg', 'image/png', 'image/jpeg']}
                            filesLimit={20}
                            maxFileSize={10485760}
                            showPreviews={true}
                            showPreviewsInDropzone={false}
                            showFileNamesInPreview={true}
                            showFileNames={true}
                            initialFiles={values.claimFiles}
                        />

                        <hr />
                        <CheckboxField name="accept_docs_terms" type="checkbox" label="Para se të dërgoni dokumentet mjeksore, kuponat fiskal etj, sigurohuni që të mos t’ju mungojë asgjë. Nëse kërkesat tuaja nuk i kanë të gjitha informacionet dhe dokumentet e nevojshme, ne mund t'ju kërkojmë të riparaqisni kërkesën tuaj. Gjithashtu, pajtoheni qe jeni të detyruar t’i mbani origjinalet për 12 muaj nga data paraqitjes se kërkesës. Në rast se ju kërkohët nga SIGAL Uniqa te prezantohën dokumentet, kuponat fiskal origjinal, atërherë të njejtat duhet të dorëzohën sipas kërkesës" />
                    </div>
                </div>
            </>
        }
    </>);
}

export default connect()(UploadHealthDocuments);
