import React, { useEffect } from "react";
import { Dispatch } from "redux";
import {
    Modal,
    ModalProps
} from "react-bootstrap";
import { Questions } from '../../../../utils/nomen';
import QuestionComponent from "./Question";
import { connect, useSelector } from 'react-redux';
import { Question } from "../../../../utils/policyTypes";
import { AppState } from "../../../../redux/reducers/rootReducer";
import * as cliActions from "../../../../redux/actionCreators/cliPolicyActionCreators";
import * as actionTypes from '../../../../redux/actionTypes/cliPolicyActionTypes';
import { Form, Formik, FormikProps } from "formik";

interface FormValues {
    questions: Question[] | null;
}

type Props = {
    onClose: () => void;
    onSubmit: (questions: Question[]) => void;
    isLoading: boolean;
    error: null | Error | string;
}
const PoliticalFormModal = (props: FormikProps<FormValues> & Props & ModalProps) => {
    const politicalFormQuestions = useSelector<AppState, Question[]>((state: any) => state.policy.politicalFormQuestions);
    const initialValues = !politicalFormQuestions ? JSON.parse(JSON.stringify(Questions)) : politicalFormQuestions;

    const handleSubmit = async (values: any) => {
        for (var i = 0; i < values.questions.length; i++) {
            const k = i;

            if (values.questions[i].type === 1 || values.questions[i].type === 3) {
                values.questions[i].value = values[k + 1];
            } else {
                values.questions[i].selected = values[k + 1];
            }
        }

        props.onSubmit(values.questions);
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className="text-black"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                {initialValues && <Formik
                    initialValues={{ questions: initialValues }}
                    onSubmit={values =>
                        handleSubmit(values)
                    }
                    render={({ values }) => (
                        <Form>
                            <div>
                                <h4 className="q-title">FORMULAR IDENTIFIKIMI I PRESONIT TË EKSPOZUAR POLITIKISHT (PEP)</h4>
                                <div>A keni qenë apo jeni: </div>
                                {values.questions && values.questions.length > 0 &&
                                    values.questions.map((question: Question, index: number) => (
                                        <QuestionComponent key={index + 1} index={index + 1} data={question} />
                                    ))
                                }

                                <hr />
                                <div className="mt20">
                                    <button type="submit" className="btn btn-wiz px-6 font-weight-bold pull-right">Ruaj</button>
                                    <button type="button" className="btn btn-wiz btn-default mr5 pull-right" onClick={props.onClose}>Anulo</button>
                                </div>
                            </div>
                        </Form>
                    )}
                />}

            </Modal.Body>
        </Modal >
    )
};

const mapDispatchToProps = (dispatch: Dispatch<actionTypes.SubmitPoliticalFormAction | actionTypes.HidePoliticalFormModalModalAction>) => ({
    onSubmit: (questions: Question[]) => {
        dispatch(cliActions.submitPoliticalForm(questions));
    },
});

const mapStateToProps = (state: any) => ({
    isLoading: state.isLoading[actionTypes.SUBMIT_POLITICAL_FORM],
    error: state.error[actionTypes.SUBMIT_POLITICAL_FORM]
});

export default connect(mapStateToProps, mapDispatchToProps)(PoliticalFormModal);