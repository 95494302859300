import React, { FunctionComponent } from 'react';
import { useField, useFormikContext } from 'formik';

interface Props {
    name: string;
    type: string;
    label: string;
    newvalue?: string;
}

const CheckboxField: FunctionComponent<Props> = (props) => {
    const [
        field,
        { error, touched },
    ] = useField({
        name: props.name,
        type: props.type,
    });
    
    const { setFieldValue } = useFormikContext();

    React.useEffect(() => {
        if (!!props.newvalue) {
          setFieldValue(props.name, props.newvalue);
        }
      }, [setFieldValue, props.name, props.newvalue]);

    return (
        <div className="form-group">
            <label className="checkbox">
                <input {...field} {...props} className="m-1" style={{ opacity: 1, zIndex: 5, position: "relative" }} />
                {props.label}
            </label>
            {touched && error !== "" ? (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{error}</div>
                </div>
            ) : null}
        </div>
    );
};

export default CheckboxField;