import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from "redux";
import { useFormik, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { AppState } from '../../../redux/reducers/rootReducer';
import * as claimActionTypes from "../../../redux/actionTypes/claimActionTypes";
import * as generalActionTypes from "../../../redux/actionTypes/generalActionTypes";
import SimpleModal from "../../General/SimpleModal";
import { User } from '../../../redux/types';
import { hideModal } from '../../../redux/actionCreators/generalActionCreators';
import { downloadBonusMalusDocument } from '../../../redux/actionCreators/claimActionCreators';
import { Loading } from '../../Layout/Loading';

interface FormValues {
  plateNumber: string;
  chassis: string;
}

const initialValues = {
  plateNumber: "",
  chassis: ""
};

interface Props {
  onDownload(customerId: string, plateNumber: string, chassis: string): void;
  closeModal(): void;
}

const Information = (props: FormikProps<FormValues> & Props) => {
  const showModal = useSelector<AppState, boolean>((state: any) => state.general.showModal);
  const header = useSelector<AppState, string>((state: any) => state.general.header);
  const text = useSelector<AppState, string>((state: any) => state.general.text);
  const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
  const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[claimActionTypes.DOWNLOAD_BONUS_MALUS_DOCUMENT]);

  const InformationSchema = Yup.object().shape({
    plateNumber: Yup.string(),
    chassis: Yup.string(),
  });

  const handleSubmit = (values: any) => {
    props.onDownload(user!.customerId, "", "");
  };

  const handleCloseModal = () => {
    props.closeModal();
  };

  return (
    <div className="card card-custom">
      {isLoading && <Loading />}
      <SimpleModal show={showModal} header={header} text={text} onClose={handleCloseModal} onHide={() => { return; }} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={InformationSchema}
        onSubmit={handleSubmit}
      >
        {({ getFieldProps, handleSubmit, getFieldMeta }) => (
          <form onSubmit={(e: any) => {
            handleSubmit(e);
          }}>
            <div className="card-header flex-wrap border-0 pt-6 pb-0 bottom-line">
              <div className="card-title">
                <h3 className="card-label">
                  Informacion Bonus/Malus
                </h3>
              </div>
            </div>
            <div className="card-body ">
              {/* <!--begin::Search Form--> */}
              <div className="mb-7  bottom-line">
                <div className="row align-items-center">
                  <div className="mb-7">
                    <div className="row align-items-center">
                      <div className="col-md-3 my-2 my-md-0">
                        <button type="submit" className="btn btn-light-primary px-6 font-weight-bold">
                          Bonus/Malus
										    </button>
                      </div>
                    </div>
                    <div className="row align-items-center">
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
              {/* <!--end::Search Form--> */}
            </div>
          </form >
        )}
      </Formik>
    </div >);
}

const mapDispatchToProps = (dispatch: Dispatch<claimActionTypes.DownloadBonusMalusDocumentAction
  | generalActionTypes.HideModalAction>) => ({
    onDownload: (customerId: string, plateNumber: string, chassis: string) => {
      dispatch(downloadBonusMalusDocument(customerId, plateNumber, chassis));
    },
    closeModal: () => { dispatch(hideModal()); }
  });

export default connect(null, mapDispatchToProps)(Information);