import { BookingData, PiPolicy, ThInsQuotation } from "../../utils/policyTypes";

export const GET_PI_QUOTATION = "policyActionTypes/GET_PI_QUOTATION";
export interface GetPIQuotationAction {
  type: typeof GET_PI_QUOTATION;
  policy: PiPolicy;
}

export const GET_PI_QUOTATION_REQUEST = "policyActionTypes/GET_PI_QUOTATION_REQUEST";
export interface GetPIQuotationRequestAction {
  type: typeof GET_PI_QUOTATION_REQUEST;
}

export const GET_PI_QUOTATION_RESET = "policyActionTypes/GET_PI_QUOTATION_RESET";
export interface GetPIQuotationResetAction {
  type: typeof GET_PI_QUOTATION_RESET;
}

export const GET_PI_QUOTATION_SUCCESS = "policyActionTypes/GET_PI_QUOTATION_SUCCESS";
export interface GetPIQuotationSuccessAction {
  type: typeof GET_PI_QUOTATION_SUCCESS;
  data: ThInsQuotation;
}

export const GET_PI_QUOTATION_FAILURE = "policyActionTypes/GET_PI_QUOTATION_FAILURE";
export interface GetPIQuotationFailureAction {
  type: typeof GET_PI_QUOTATION_FAILURE;
  error: Error | string;
}


export const GET_PACKAGE_PI_QUOTATION = "policyActionTypes/GET_PACKAGE_PI_QUOTATION";
export interface GetPackagePIQuotationAction {
  type: typeof GET_PACKAGE_PI_QUOTATION;
  policy: PiPolicy;
}

export const GET_PACKAGE_PI_QUOTATION_REQUEST = "policyActionTypes/GET_PACKAGE_PI_QUOTATION_REQUEST";
export interface GetPackagePIQuotationRequestAction {
  type: typeof GET_PACKAGE_PI_QUOTATION_REQUEST;
}

export const GET_PACKAGE_PI_QUOTATION_RESET = "policyActionTypes/GET_PACKAGE_PI_QUOTATION_RESET";
export interface GetPackagePIQuotationResetAction {
  type: typeof GET_PACKAGE_PI_QUOTATION_RESET;
}

export const GET_PACKAGE_PI_QUOTATION_SUCCESS = "policyActionTypes/GET_PACKAGE_PI_QUOTATION_SUCCESS";
export interface GetPackagePIQuotationSuccessAction {
  type: typeof GET_PACKAGE_PI_QUOTATION_SUCCESS;
  data: ThInsQuotation;
}

export const GET_PACKAGE_PI_QUOTATION_FAILURE = "policyActionTypes/GET_PACKAGE_PI_QUOTATION_FAILURE";
export interface GetPackagePIQuotationFailureAction {
  type: typeof GET_PACKAGE_PI_QUOTATION_FAILURE;
  error: Error | string;
}

export const BOOK_PI_POLICY = "policyActionTypes/BOOK_PI_POLICY";
export interface BookPIPolicyAction {
  type: typeof BOOK_PI_POLICY;
  policy: PiPolicy;
}

export const BOOK_PI_POLICY_REQUEST = "policyActionTypes/BOOK_PI_POLICY_REQUEST";
export interface BookPIPolicyRequestAction {
  type: typeof BOOK_PI_POLICY_REQUEST;
}

export const BOOK_PI_POLICY_SUCCESS = "policyActionTypes/BOOK_PI_POLICY_SUCCESS";
export interface BookPIPolicySuccessAction {
  type: typeof BOOK_PI_POLICY_SUCCESS;
  data: BookingData;
}

export const BOOK_PI_POLICY_FAILURE = "policyActionTypes/BOOK_PI_POLICY_FAILURE";
export interface BookPIPolicyFailureAction {
  type: typeof BOOK_PI_POLICY_FAILURE;
  error: Error | string;
}


export type PolicyAction =
  | GetPIQuotationAction
  | GetPIQuotationRequestAction
  | GetPIQuotationResetAction
  | GetPIQuotationSuccessAction
  | GetPIQuotationFailureAction
  | GetPackagePIQuotationRequestAction
  | GetPackagePIQuotationResetAction
  | GetPackagePIQuotationSuccessAction
  | GetPackagePIQuotationFailureAction
  | BookPIPolicyAction
  | BookPIPolicyRequestAction
  | BookPIPolicySuccessAction
  | BookPIPolicyFailureAction;
