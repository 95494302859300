import { ThPolicy, ThInsQuotation, PhPolicy } from './../../utils/policyTypes';
import { BookingData } from '../../utils/policyTypes';

export const GET_TH_QUOTATION = "policyActionTypes/GET_TH_QUOTATION";
export interface GetTHQuotationAction {
  type: typeof GET_TH_QUOTATION;
  policy: ThPolicy;
}

export const GET_TH_QUOTATION_REQUEST = "policyActionTypes/GET_TH_QUOTATION_REQUEST";
export interface GetTHQuotationRequestAction {
  type: typeof GET_TH_QUOTATION_REQUEST;
}

export const GET_TH_QUOTATION_RESET = "policyActionTypes/GET_TH_QUOTATION_RESET";
export interface GetTHQuotationResetAction {
  type: typeof GET_TH_QUOTATION_RESET;
}

export const GET_TH_QUOTATION_SUCCESS = "policyActionTypes/GET_TH_QUOTATION_SUCCESS";
export interface GetTHQuotationSuccessAction {
  type: typeof GET_TH_QUOTATION_SUCCESS;
  data: ThInsQuotation;
}

export const GET_TH_QUOTATION_FAILURE = "policyActionTypes/GET_TH_QUOTATION_FAILURE";
export interface GetTHQuotationFailureAction {
  type: typeof GET_TH_QUOTATION_FAILURE;
  error: Error | string;
}

export const BOOK_TH_POLICY = "policyActionTypes/BOOK_TH_POLICY";
export interface BookTHPolicyAction {
  type: typeof BOOK_TH_POLICY;
  policy: ThPolicy;
}

export const BOOK_TH_POLICY_REQUEST = "policyActionTypes/BOOK_TH_POLICY_REQUEST";
export interface BookTHPolicyRequestAction {
  type: typeof BOOK_TH_POLICY_REQUEST;
}

export const BOOK_TH_POLICY_SUCCESS = "policyActionTypes/BOOK_TH_POLICY_SUCCESS";
export interface BookTHPolicySuccessAction {
  type: typeof BOOK_TH_POLICY_SUCCESS;
  data: BookingData;
}

export const BOOK_TH_POLICY_FAILURE = "policyActionTypes/BOOK_TH_POLICY_FAILURE";
export interface BookTHPolicyFailureAction {
  type: typeof BOOK_TH_POLICY_FAILURE;
  error: Error | string;
}

export const CHOOSE_QUOTATION = "policyActionTypes/CHOOSE_QUOTATION";
export interface ChooseQuotationAction {
  type: typeof CHOOSE_QUOTATION;
}

export const CHOOSE_QUOTATION_FAILURE = "policyActionTypes/CHOOSE_QUOTATION_FAILURE";
export interface ChooseQuotationFailureAction {
  type: typeof CHOOSE_QUOTATION_FAILURE;
}

export const CHOOSE_QUOTATION_SUCCESS = "policyActionTypes/CHOOSE_QUOTATION_SUCCESS";
export interface ChooseQuotationSuccessAction {
  type: typeof CHOOSE_QUOTATION_SUCCESS;
}

export const CHOOSE_QUOTATION_RESET = "policyActionTypes/CHOOSE_QUOTATION_RESET";
export interface ChooseQuotationResetAction {
  type: typeof CHOOSE_QUOTATION_RESET;
}

export const GET_PACKAGE_TH_QUOTATION = "policyActionTypes/GET_PACKAGE_TH_QUOTATION";
export interface GetPackageTHQuotationAction {
  type: typeof GET_PACKAGE_TH_QUOTATION;
  policy: ThPolicy;
}

export const GET_PACKAGE_TH_QUOTATION_REQUEST = "policyActionTypes/GET_PACKAGE_TH_QUOTATION_REQUEST";
export interface GetPackageTHQuotationRequestAction {
  type: typeof GET_PACKAGE_TH_QUOTATION_REQUEST;
}

export const GET_PACKAGE_TH_QUOTATION_RESET = "policyActionTypes/GET_PACKAGE_TH_QUOTATION_RESET";
export interface GetPackageTHQuotationResetAction {
  type: typeof GET_PACKAGE_TH_QUOTATION_RESET;
}

export const GET_PACKAGE_TH_QUOTATION_SUCCESS = "policyActionTypes/GET_PACKAGE_TH_QUOTATION_SUCCESS";
export interface GetPackageTHQuotationSuccessAction {
  type: typeof GET_PACKAGE_TH_QUOTATION_SUCCESS;
  data: ThInsQuotation;
}

export const GET_PACKAGE_TH_QUOTATION_FAILURE = "policyActionTypes/GET_PACKAGE_TH_QUOTATION_FAILURE";
export interface GetPackageTHQuotationFailureAction {
  type: typeof GET_PACKAGE_TH_QUOTATION_FAILURE;
  error: Error | string;
}

export type PolicyAction =
  GetTHQuotationAction
  | GetTHQuotationRequestAction
  | GetTHQuotationResetAction
  | GetTHQuotationSuccessAction
  | GetTHQuotationFailureAction
  | BookTHPolicyAction
  | BookTHPolicyRequestAction
  | BookTHPolicySuccessAction
  | BookTHPolicyFailureAction
  | ChooseQuotationAction
  | ChooseQuotationFailureAction
  | ChooseQuotationSuccessAction
  | ChooseQuotationResetAction
  | GetPackageTHQuotationRequestAction
  | GetPackageTHQuotationResetAction
  | GetPackageTHQuotationSuccessAction
  | GetPackageTHQuotationFailureAction;
