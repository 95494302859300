import React from "react";
import {
    Button,
    Modal,
    ModalProps
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppState } from '../../../../redux/reducers/rootReducer';
import { Loading2 } from '../../../Layout/Loading2';
import * as actionTypes from '../../../../redux/actionTypes/claimActionTypes';
import { PreClaimPreview } from "../../../../utils/claimTypes";
import { HealthPreClaimInvoice, HealthPreClaimInvoiceRecords } from "../../../../utils/healthPreClaimTypes";

type Props = {
    header: string;
    text: string;
    onClose: () => void;
}
const PreClaimModal = (props: ModalProps & Props) => {
    const preclaim = useSelector<AppState, PreClaimPreview | null>((state: any) => state.claim.selectedPreClaim);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.PRECLAIM_PREVIEW]);

    const formatAmount = (value: any) => {
        if (value !== null && value !== undefined && value !== '') {
            return value.toFixed(3);
        }
        return value;
    }

    const getDate = (value: any) => {
        if (!value || value === "01.01.0001") {
            return '';
        }
        return value;
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {preclaim !== null ? "Parakerkese per dem    " + preclaim.preClaimNumber : "Procesoj..."}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? <Loading2 />
                    :
                    <>
                        {preclaim !== null && <>
                            <h6 className="font-weight-bolder mb-3">          {"Numri i policës: " + preclaim.policy_code} </h6>
                            <div className="text-dark-50 line-height-lg">

                                <div className="reviewTitle">Data e aksidentit</div><div className="reviewValue"> {preclaim.date_accident}</div>
                                <div className="reviewTitle">Klasa e demit</div><div className="reviewValue">{preclaim.class}</div>

                                <br />
                                <div className="reviewTitle">Data e raportimit</div><div className="reviewValue"> {preclaim.date_reported}</div>
                                <div className="reviewTitle">Natyra e dëmit </div><div className="reviewValue"> {preclaim.nature}</div>

                                <br />
                                <div className="reviewTitle">Ora e aksidentit </div><div className="reviewValue">{preclaim.hour_accident}</div>
                                <br />

                                <div className="reviewTitle">Përshkrimi i aksidentit </div><div className="reviewValue"> {preclaim.description_accident}</div>
                                <div className="reviewTitle">Përshkrimi i dëmit </div><div className="reviewValue">{preclaim.description_damage}</div>
                                <br />

                                <div className="reviewTitle">Nr. i raportit europian </div><div className="reviewValue"> {preclaim.europeanreportnr}</div>
                                <div className="reviewTitle">Nr. i raportit të Policisë </div><div className="reviewValue">{preclaim.policyreportnr}</div>
                                <br />
                            </div>
                            {preclaim.damagedPerson !== null && (preclaim.damagedPerson.tipkind === "3" || preclaim.damagedPerson.tipkind === "2")
                                && <><div className="separator separator-dashed my-5"></div>
                                    <h6 className="font-weight-bolder mb-3"> I Dëmtuari: </h6></>}
                            {preclaim.damagedPerson !== null && preclaim.damagedPerson.tipkind === "3" && <div className="text-dark-50 line-height-lg">

                                <div className="reviewTitle">Numri personal</div><div className="reviewValue"> {preclaim.damagedPerson.clientno}</div>
                                <div className="reviewTitle">Adresa</div><div className="reviewValue"> {preclaim.damagedPerson.address},{preclaim.damagedPerson.place.name}</div>
                                <br />
                                <div className="reviewTitle">Emri Mbiemri</div><div className="reviewValue"> {preclaim.damagedPerson.title} {preclaim.damagedPerson.lastname}</div>
                                <div className="reviewTitle">Nr. telefonit</div><div className="reviewValue"> {preclaim.damagedPerson.celphone}</div>
                                <br />
                                <div className="reviewTitle">Pasaporta</div><div className="reviewValue">{preclaim.damagedPerson.passport}</div>
                                <div className="reviewTitle">Datëlindja</div><div className="reviewValue">{preclaim.damagedPerson.birthdate}</div>
                            </div>}
                            {preclaim.damagedPerson !== null && preclaim.damagedPerson.tipkind === "2" && <div className="text-dark-50 line-height-lg">
                                <div className="reviewTitle">Nipti</div><div className="reviewValue"> {preclaim.damagedPerson.nipt}</div>
                                <div className="reviewTitle">Adresa</div><div className="reviewValue"> {preclaim.damagedPerson.address},{preclaim.damagedPerson.place.name}</div>
                                <br />
                                <div className="reviewTitle">Emri</div><div className="reviewValue"> {preclaim.damagedPerson.title} {preclaim.damagedPerson.lastname}</div>
                            </div>}
                            {preclaim.damagedVehicle !== null && preclaim.damagedVehicle.regnum !== null && <><div className="separator separator-dashed my-5"></div>
                                <h6 className="font-weight-bolder mb-3">I Dëmtuari - Automjeti: </h6>
                                <div className="text-dark-50 line-height-lg">
                                    <div className="reviewTitle">Targa</div><div className="reviewValue"> {preclaim.damagedVehicle.regnum}</div>
                                    <div className="reviewTitle">Prodhuesi/Modeli</div><div className="reviewValue"> {preclaim.damagedVehicle.mark.name} - {preclaim.damagedVehicle.tip}</div>

                                    <br />
                                    <div className="reviewTitle">Numri i shasisë</div><div className="reviewValue">{preclaim.damagedVehicle.chassis}</div>
                                    <div className="reviewTitle">Viti i prodhimit</div><div className="reviewValue">{preclaim.damagedVehicle.prodyear}</div>

                                    <br />
                                    <div className="reviewTitle">Pesha e automjetit</div><div className="reviewValue">{preclaim.damagedVehicle.capacity}</div>
                                    <div className="reviewTitle">Ngjyra e automjetit</div><div className="reviewValue">  {preclaim.damagedVehicle.colour}</div>
                                    <br />
                                    <div className="reviewTitle">Fuqia(kw)</div><div className="reviewValue"> {preclaim.damagedVehicle.power}</div><br />
                                </div>
                            </>}

                            {preclaim.insuredPerson !== null && (preclaim.insuredPerson.tipkind === "3" || preclaim.insuredPerson.tipkind === "2")
                                && <><div className="separator separator-dashed my-5"></div>
                                    <h6 className="font-weight-bolder mb-3"> I Siguruari: </h6></>}
                            {preclaim.insuredPerson !== null && preclaim.insuredPerson.tipkind === "3" && <div className="text-dark-50 line-height-lg">

                                <div className="reviewTitle">Numri personal</div><div className="reviewValue"> {preclaim.insuredPerson.clientno}</div>
                                <div className="reviewTitle">Adresa</div><div className="reviewValue"> {preclaim.insuredPerson.address},{preclaim.insuredPerson.place.name}</div>
                                <br />
                                <div className="reviewTitle">Emri Mbiemri</div><div className="reviewValue"> {preclaim.insuredPerson.title} {preclaim.insuredPerson.lastname}</div>
                                <div className="reviewTitle">Nr. telefonit</div><div className="reviewValue"> {preclaim.insuredPerson.celphone}</div>
                                <br />
                                <div className="reviewTitle">Pasaporta</div><div className="reviewValue">{preclaim.insuredPerson.passport}</div>
                                <div className="reviewTitle">Datëlindja</div><div className="reviewValue">{preclaim.insuredPerson.birthdate}</div>
                            </div>}
                            {preclaim.insuredPerson !== null && preclaim.insuredPerson.tipkind === "2" && <div className="text-dark-50 line-height-lg">
                                <div className="reviewTitle">Nipti</div><div className="reviewValue"> {preclaim.insuredPerson.nipt}</div>
                                <div className="reviewTitle">Adresa</div><div className="reviewValue"> {preclaim.insuredPerson.address},{preclaim.insuredPerson.place.name}</div>
                                <br />
                                <div className="reviewTitle">Emri</div><div className="reviewValue"> {preclaim.insuredPerson.title} {preclaim.insuredPerson.lastname}</div>
                            </div>}
                            {preclaim.insuredVehicle !== null && preclaim.insuredVehicle.regnum !== null && <><div className="separator separator-dashed my-5"></div>
                                <h6 className="font-weight-bolder mb-3">I Siguruari - Automjeti: </h6>
                                <div className="text-dark-50 line-height-lg">
                                    <div className="reviewTitle">Targa</div><div className="reviewValue"> {preclaim.insuredVehicle.regnum}</div>
                                    <div className="reviewTitle">Prodhuesi/Modeli</div><div className="reviewValue"> {preclaim.insuredVehicle.mark.name} - {preclaim.insuredVehicle.tip}</div>

                                    <br />
                                    <div className="reviewTitle">Numri i shasisë</div><div className="reviewValue">{preclaim.insuredVehicle.chassis}</div>
                                    <div className="reviewTitle">Viti i prodhimit</div><div className="reviewValue">{preclaim.insuredVehicle.prodyear}</div>

                                    <br />
                                    <div className="reviewTitle">Pesha e automjetit</div><div className="reviewValue">{preclaim.insuredVehicle.capacity}</div>
                                    <div className="reviewTitle">Ngjyra e automjetit</div><div className="reviewValue">  {preclaim.insuredVehicle.colour}</div>
                                    <br />
                                    <div className="reviewTitle">Fuqia(kw)</div><div className="reviewValue"> {preclaim.insuredVehicle.power}</div><br />
                                </div>
                            </>}
                            {preclaim.beneficiaryPerson !== null && (preclaim.beneficiaryPerson.tipkind === "3" || preclaim.beneficiaryPerson.tipkind === "2")
                                && <><div className="separator separator-dashed my-5"></div>
                                    <h6 className="font-weight-bolder mb-3"> Perfituesi: </h6></>}
                            {preclaim.beneficiaryPerson !== null && preclaim.beneficiaryPerson.tipkind === "3" && <div className="text-dark-50 line-height-lg">
                                <div className="reviewTitle">Numri personal</div><div className="reviewValue"> {preclaim.beneficiaryPerson.clientno}</div>
                                <div className="reviewTitle">Adresa</div><div className="reviewValue"> {preclaim.beneficiaryPerson.address},{preclaim.beneficiaryPerson.place.name}</div>
                                <br />
                                <div className="reviewTitle">Emri Mbiemri</div><div className="reviewValue"> {preclaim.beneficiaryPerson.title} {preclaim.beneficiaryPerson.lastname}</div>
                                <div className="reviewTitle">Nr. telefonit</div><div className="reviewValue"> {preclaim.beneficiaryPerson.celphone}</div>
                                <br />
                                <div className="reviewTitle">Email</div><div className="reviewValue">{preclaim.beneficiaryPerson.email}</div>
                                <div className="reviewTitle">Datëlindja</div><div className="reviewValue">{getDate(preclaim.beneficiaryPerson.birthdate)}</div>
                                <br />
                                <div className="reviewTitle">Banka</div><div className="reviewValue">{preclaim.beneficiaryPerson.bank?.name}</div>
                                <div className="reviewTitle">Llogari bankare</div><div className="reviewValue">{preclaim.beneficiaryPerson.bankAccount}</div>
                            </div>}
                            {preclaim.beneficiaryPerson !== null && preclaim.beneficiaryPerson.tipkind === "2" && <div className="text-dark-50 line-height-lg">
                                <div className="reviewTitle">Nipti</div><div className="reviewValue"> {preclaim.beneficiaryPerson.nipt}</div>
                                <div className="reviewTitle">Adresa</div><div className="reviewValue"> {preclaim.beneficiaryPerson.address},{preclaim.beneficiaryPerson.place.name}</div>
                                <br />
                                <div className="reviewTitle">Emri</div><div className="reviewValue"> {preclaim.beneficiaryPerson.title} {preclaim.beneficiaryPerson.lastname}</div>
                                <br />
                                <div className="reviewTitle">Banka</div><div className="reviewValue">{preclaim.beneficiaryPerson.bank?.name}</div>
                                <div className="reviewTitle">Llogari bankare</div><div className="reviewValue">{preclaim.beneficiaryPerson.bankAccount}</div>
                            </div>}

                            {preclaim.invoices !== null && preclaim.invoices.length > 0
                                && <><div className="separator separator-dashed my-5"></div>
                                    <h6 className="font-weight-bolder mb-3"> Faturat: </h6>

                                    {preclaim.invoices.map((invoice: HealthPreClaimInvoice, index: number) => (
                                        <div key={index} className="text-dark-50 line-height-lg">
                                            <div className="reviewTitle">Numri i fiskal</div><div className="reviewValue"> {invoice.invoicenr}</div>
                                            <div className="reviewTitle">Shuma e faturës (€)</div><div className="reviewValue"> {formatAmount(invoice.invoicedAmountV)}</div>
                                            <br />
                                            <div className="reviewTitle">Numri i kuponit fiskal</div><div className="reviewValue"> {invoice.invoiceFiscalnr}</div>
                                            <div className="reviewTitle">Data e faturës</div><div className="reviewValue"> {invoice.invoicedate}</div>
                                            <br />
                                            <div className="reviewTitle">Spitali</div><div className="reviewValue"> {invoice.hospitalName}</div>
                                            <div className="reviewTitle">Shteti</div><div className="reviewValue"> {invoice.countryEvent.name}</div>

                                            <div className="invoiceRecords">
                                                {invoice.records.map((record: HealthPreClaimInvoiceRecords, indexRec: number) => (
                                                    <ul key={indexRec}>
                                                        <li>
                                                            <div className="reviewTitle">Mbulimi</div><div className="reviewValue"> {record.covercategory.name}</div>
                                                            <div className="reviewTitle">Shuma (€)</div><div className="reviewValue"> {formatAmount(record.invoicedAmountV)}</div>
                                                            <br />
                                                            <div className="reviewTitle"></div><div className="reviewValue"> {record.covergroup?.name}</div>
                                                            <div className="reviewTitle">Trajtimi</div><div className="reviewValue"> {record.treating}</div>
                                                        </li>
                                                    </ul>
                                                ))}
                                            </div>
                                            <div className="separator separator-dashed my-5"></div>
                                            <div className="reviewTitle">Vrejtje</div><div className="reviewValue"> {invoice.comment}</div>
                                        </div>
                                    ))}</>}
                        </>}
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onClose}>Dalje</Button>
            </Modal.Footer>
        </Modal >
    )
};

export default PreClaimModal;