import React from "react";
import {
    Button,
    Modal,
    ModalProps
} from "react-bootstrap";
import { connect, useSelector } from 'react-redux';
import { AppState } from '../../../../redux/reducers/rootReducer';
import { Loading2 } from '../../../Layout/Loading2';
import * as actionTypes from '../../../../redux/actionTypes/healthClaimActionTypes';
import { HealthClaimPreview } from "../../../../utils/healthPreClaimTypes";
import { Dispatch } from "redux";
import * as healthClaimActionTypes from '../../../../redux/actionTypes/healthClaimActionTypes';
import { healthClaimPreview } from '../../../../redux/actionCreators/healthPreClaimActionCreators';
import { printSecondLevelAppeal } from "../../../../redux/actionCreators/healthPreClaimActionCreators";

type Props = {
    header: string;
    text: string;
    onClose: () => void;
    onPreview: (code: string) => void;
    onPrint: (code: string) => void;
}
const ReadSecondLevelAppealModal = (props: ModalProps & Props) => {
    const healthClaim = useSelector<AppState, HealthClaimPreview | null>((state: any) => state.healthClaim.selectedHealthClaim);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.READ_SECOND_LEVEL_APPEAL]);
    const isPrinting = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.PRINT_SECOND_LEVEL_APPEAL]);

    const formatAmount = (value: any) => {
        if (value !== null && value !== undefined && value !== '') {
            return value.toFixed(3);
        }
        return value;
    }

    const getDate = (value: any) => {
        if (!value || value === "01.01.0001") {
            return '';
        }
        return value;
    }

    const openHealthClaim = (code: any) => {
        props.onClose();
        props.onPreview(code);
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {healthClaim !== null ? "Ankese ndaj vendimit te shkalles se dyte    " + healthClaim.claimNumber : "Procesoj..."}
                    {healthClaim !== null ? (getDate(healthClaim.appeal?.secondLevelAppealDate)) : ''}

                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="claimModal">
                {isLoading || isPrinting ? <Loading2 />
                    :
                    <>
                        {healthClaim !== null && <>
                            <h6 className="font-weight-bolder mb-3">          {"Numri i policës: " + healthClaim.policy_code} </h6>
                            <div className="text-dark-50 line-height-lg">

                                <div className="reviewTitle">Data e aksidentit</div><div className="reviewValue"> {healthClaim.date_accident}</div>
                                <div className="reviewTitle">Klasa e demit</div><div className="reviewValue">{healthClaim.class}</div>

                                <br />
                                <div className="reviewTitle">Data e raportimit</div><div className="reviewValue"> {healthClaim.date_reported}</div>
                                <div className="reviewTitle">Statusi</div><div className="reviewValue">{healthClaim.status}</div>

                                <br />
                                <div className="reviewTitle">Data e refuzuar</div><div className="reviewValue"> {getDate(healthClaim.date_rejected)}</div>
                                <div className="reviewTitle">Shuma e kërkuar </div><div className="reviewValue">(€){formatAmount(healthClaim.amount_requestedv)}</div>

                                <br />
                                <div className="reviewTitle">Përshkrimi i aksidentit </div><div className="reviewValue"> {healthClaim.description_accident}</div>
                                <div className="reviewTitle">Shuma e miratuar </div><div className="reviewValue">(€){formatAmount(healthClaim.approved_amountv)}</div>

                                <br />
                                <div className="reviewTitle">Përshkrimi i dëmit </div><div className="reviewValue">{healthClaim.description_damage}</div>
                                <div className="reviewTitle">Shuma e paguar </div><div className="reviewValue">(€){formatAmount(healthClaim.paid_amountv)}</div>
                                <br />
                            </div>
                        </>}

                        {healthClaim !== null && healthClaim.appeal !== null && <>
                            <div className="separator separator-dashed my-5"></div>
                            <h6 className="font-weight-bolder mb-3">          {"Vlersimi: " + (getDate(healthClaim.appeal?.secondLevelReviewDate))} </h6>
                            <div className="text-dark-50 line-height-lg">
                                {healthClaim.appeal?.secondLevelReview}
                                <br />
                            </div>
                        </>}

                        {healthClaim !== null && healthClaim.appeal !== null && <>
                            <div className="separator separator-dashed my-5"></div>
                            <h6 className="font-weight-bolder mb-3">          {"Statusi: " + healthClaim.appeal?.secondLevelReviewStatus} </h6>
                        </>}

                        {healthClaim !== null && healthClaim.appeal !== null && healthClaim.appeal.referencedClaimId !== '00000000-0000-0000-0000-000000000000' && <>
                            <div className="separator separator-dashed my-5"></div>
                            <a onClick={() => openHealthClaim(healthClaim.appeal.referencedClaimId)} className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5" title="">
                                Klikoni ketu per te pare demin e ri te hapur si rezultat I pranimit te ankeses.
                            </a>
                        </>}
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                < Button onClick={() => props.onPrint(healthClaim!.claimId)}>Shtyp</Button>
                <Button onClick={props.onClose}>Dalje</Button>
            </Modal.Footer>
        </Modal >
    )
};

const mapDispatchToProps = (dispatch: Dispatch<healthClaimActionTypes.HealthClaimPreviewAction
    | actionTypes.PrintSecondLevelAppealAction>) => ({
        onPreview: (code: string) => {
            dispatch(healthClaimPreview(code, false));
        },
        onPrint: (code: string) => {
            dispatch(printSecondLevelAppeal(code));
        }
    });

export default connect(null, mapDispatchToProps)(ReadSecondLevelAppealModal);