import React from 'react';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import FormikWizard from 'formik-wizard';
import { HideModalAction } from '../../../../redux/actionTypes/generalActionTypes';
import steps from './healthPreClaimSteps';
import { FormikWizardWrapperProps } from '../../../../utils/wizardTypes';
import SimpleModal from '../../../General/SimpleModal';
import NavKS from './NavKS';
import { getHealthNatures, getPlaces, getBanks, getCountries } from '../../../../redux/actionCreators/nomenActionCreators';
import * as nomenActionTypes from '../../../../redux/actionTypes/nomenActionTypes';
import * as healthPreClaimActionTypes from '../../../../redux/actionTypes/healthPreClaimActionTypes';
import { hideModal } from '../../../../redux/actionCreators/generalActionCreators';
import { RouteComponentProps } from '../../../Layout/Content';
import { Loading } from '../../../Layout/Loading';
import { HealthPreClaim, prepareHealthOrPropertyPreClaim, validate, validateInvoices } from '../../../../utils/healthPreClaimTypes';
import { registerHealthPreClaim } from '../../../../redux/actionCreators/healthPreClaimActionCreators';
import { User } from '../../../../redux/types';
import { AppState } from '../../../../redux/reducers/rootReducer';

interface Props {
    onGetHealthNatures(): void;
    onGetPlaces(): void;
    onRegisterHealthPreClaim(healthPreClaim: HealthPreClaim): void;
    closeModal(): void;
    showModal: boolean;
    description: string;
    header: string;
    nextStep: string;
    history: History;
    isLoading: boolean;
    nomenFetched: boolean;
    user: User | null;
    onGetBanks(): void,
    onGetCountries(): void,
}

interface PathProps {
    history: any;
}

function FormWrapper({
    children,
    isLastStep,
    status,
    goToPreviousStep,
    canGoBack,
    actionLabel,
    currentStep,
    values
}: FormikWizardWrapperProps<any, any>) {
    const erroredValidatePolicy = useSelector<AppState, null | Error | string>((state: any) => state.error[healthPreClaimActionTypes.VALIDATE_HEALTH_CLAIM_POLICY]);
    const erroredValidateInsured = useSelector<AppState, null | Error | string>((state: any) => state.error[healthPreClaimActionTypes.VALIDATE_HEALTH_CLAIM_INSURED]);
    const erroredStepGetCoverCategories = useSelector<AppState, null | Error | string>((state: any) => state.error[nomenActionTypes.GET_COVER_CATEGORIES_FOR_POLICY]);
    const dispatch = useDispatch();

    var stepSkipped = false;

    if (currentStep === steps[1].id
        && (!!erroredValidatePolicy
            || !!erroredValidateInsured
            || !!erroredStepGetCoverCategories)) {
        goToPreviousStep()
    };

    if (currentStep === steps[3].id && validate(values.insertInvoices.invoices[0]).errored) {
        goToPreviousStep();
        stepSkipped = true;
    }

    if (currentStep === steps[3].id && !stepSkipped) {
        const validationResult = validateInvoices(values.insertInvoices.invoices);
        if (validationResult.errored) {
            dispatch({
                type: "generalActionTypes/SHOW_MODAL",
                header: "Dështimi",
                text: validationResult.message,
                next: ""
            });

            goToPreviousStep();
        }
    }

    return (
        <div className="wizard wizard-4" id="kt_wizard_v4" data-wizard-state="step-first" data-wizard-clickable="true">
            <NavKS currentStep={currentStep} />
            <div className="card card-custom card-shadowless rounded-top-0">
                <div className="card-body p-0">
                    <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                        <div className="col-xl-12 col-xxl-7">
                            {children}
                            <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                <div className="mr-2">
                                    {canGoBack && <button type="button" onClick={goToPreviousStep} className="btn-wiz-back font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-prev">
                                        <i className="arrow left"></i>Kthehu
                                    </button>
                                    }
                                </div>
                                <div>
                                    {isLastStep && <button type="submit" className="btn btn-wiz font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-submit">
                                        {actionLabel || 'Submit'}
                                    </button>}
                                    {!isLastStep && <button type="submit" className="btn btn-wiz font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-next">
                                        {actionLabel || 'Next'}
                                    </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

class RegisterHealthPreClaimKS extends React.Component<Props & RouteComponentProps<PathProps>, {}> {

    componentDidMount() {
        if (!this.props.nomenFetched) {
            this.props.onGetHealthNatures();
            this.props.onGetPlaces();
            this.props.onGetBanks();
            this.props.onGetCountries();
        }
    }

    handleSubmit = (values: any) => {
        const customerId = this.props.user!.customerId;
        const healthPreClaim = prepareHealthOrPropertyPreClaim(customerId, values);
        this.props.onRegisterHealthPreClaim(healthPreClaim);
    };

    handleCloseModal = () => {
        if (this.props.nextStep === "3") this.props.history.push('/');
        this.props.closeModal();
    };

    render() {
        return (
            <div className="d-flex flex-column-fluid">
                {this.props.isLoading
                    ? <Loading />
                    : <>
                        <SimpleModal show={this.props.showModal} header={this.props.header} text={this.props.description} onClose={this.handleCloseModal} onHide={() => { return; }} />
                        <div className=" container ">
                            <div className="card card-custom">
                                <div className="card-body p-0">
                                    <FormikWizard steps={steps} onSubmit={this.handleSubmit} render={FormWrapper} />
                                </div>
                            </div>
                        </div>
                    </>}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch<nomenActionTypes.NomenAction | HideModalAction | healthPreClaimActionTypes.RegisterHealthPreClaimAction>) => ({
    onGetHealthNatures: () => {
        dispatch(getHealthNatures());
    },
    onGetPlaces: () => {
        dispatch(getPlaces());
    },
    onRegisterHealthPreClaim: (healthPreClaim: HealthPreClaim) => {
        dispatch(registerHealthPreClaim(healthPreClaim));
    },
    onGetBanks: () => {
        dispatch(getBanks());
    },
    onGetCountries: () => {
        dispatch(getCountries(''));
    },
    closeModal: () => { dispatch(hideModal()); }
});

const mapStateToProps = (state: any) => ({
    user: state.auth.user,
    showModal: state.general.showModal,
    header: state.general.header,
    description: state.general.text,
    nextStep: state.general.nextStep,
    isLoading: state.isLoading[nomenActionTypes.GET_HEALTH_NATURES]
        || state.isLoading[nomenActionTypes.GET_PLACES]
        || state.isLoading[nomenActionTypes.GET_BANKS]
        || state.isLoading[nomenActionTypes.GET_COUNTRIES],
    nomenFetched: state.nomen.HealthNatures.length > 0
        && state.nomen.Places.length > 0
        && state.nomen.Banks.length > 0
        && state.nomen.Countries.length > 0
});

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterHealthPreClaimKS)));
