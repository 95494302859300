import { ConfirmData, ThPolicy } from './../../utils/policyTypes';
import { Filter, PolicyGridItem, PolicyPreview } from './../../utils/generalTypes';
import * as actions from "../actionTypes/generalActionTypes";

export function showModal(
  header: string,
  text: string,
  next: string,
  renderAsHtml?: boolean
): actions.ShowModalAction {
  return {
    type: actions.SHOW_MODAL,
    header,
    text,
    next,
    renderAsHtml
  };
}

export function hideModal(
): actions.HideModalAction {
  return {
    type: actions.HIDE_MODAL,
  };
}

export function showSuccessToast(
  text: string,
): actions.ShowSuccessToastAction {
  return {
    type: actions.SHOW_SUCCESS_TOAST,
    text
  };
}

export function showSuccessToastReset(
): actions.ShowSuccessToastResetAction {
  return {
    type: actions.SHOW_SUCCESS_TOAST_RESET
  };
}

export function showFailureToast(
  text: string,
): actions.ShowFailureToastAction {
  return {
    type: actions.SHOW_FAILURE_TOAST,
    text
  };
}

export function showFailureToastReset(
): actions.ShowFailureToastResetAction {
  return {
    type: actions.SHOW_FAILURE_TOAST_RESET
  };
}

export function searchPolicy(
  filter: Filter
): actions.SearchPolicyAction {
  return {
    type: actions.SEARCH_POLICY,
    filter
  };
}

export function searchPolicyRequest(
): actions.SearchPolicyRequestAction {
  return {
    type: actions.SEARCH_POLICY_REQUEST
  };
}

export function searchPolicyReset(
): actions.SearchPolicyResetAction {
  return {
    type: actions.SEARCH_POLICY_RESET
  };
}

export function searchPolicySuccess(
  items: PolicyGridItem[],
  count: number
): actions.SearchPolicySuccessAction {
  return {
    type: actions.SEARCH_POLICY_SUCCESS,
    data: items,
    count
  };
}

export function searchPolicyFailure(
  error: Error | string
): actions.SearchPolicyFailureAction {
  return {
    type: actions.SEARCH_POLICY_FAILURE,
    error
  };
}

export function policyPreview(
  policyId: string
): actions.PolicyPreviewAction {
  return {
    type: actions.POLICY_PREVIEW,
    policyId
  };
}

export function policyPreviewRequest(
): actions.PolicyPreviewRequestAction {
  return {
    type: actions.POLICY_PREVIEW_REQUEST
  };
}

export function policyPreviewSuccess(
  policy: PolicyPreview
): actions.PolicyPreviewSuccessAction {
  return {
    type: actions.POLICY_PREVIEW_SUCCESS,
    data: policy
  };
}

export function policyPreviewFailure(
  error: Error | string
): actions.PolicyPreviewFailureAction {
  return {
    type: actions.POLICY_PREVIEW_FAILURE,
    error
  };
}

export function policyPreviewReset(
): actions.PolicyPreviewResetAction {
  return {
    type: actions.POLICY_PREVIEW_RESET
  };
}


export function showPolicyModal(
): actions.ShowPolicyModalAction {
  return {
    type: actions.SHOW_POLICY_MODAL,
  };
}

export function confirmPolicy(
  confirmData: ConfirmData
): actions.ConfirmPolicyAction {
  return {
    type: actions.CONFIRM_POLICY,
    confirmData
  };
}

export function confirmPolicyRequest(
): actions.ConfirmPolicyRequestAction {
  return {
    type: actions.CONFIRM_POLICY_REQUEST
  };
}

export function confirmPolicySuccess(
  response: any
): actions.ConfirmPolicySuccessAction {
  return {
    type: actions.CONFIRM_POLICY_SUCCESS,
    data: response
  };
}

export function confirmPolicyFailure(
  error: Error | string
): actions.ConfirmPolicyFailureAction {
  return {
    type: actions.CONFIRM_POLICY_FAILURE,
    error
  };
}

export function confirmPolicyReset(
): actions.ConfirmPolicyResetAction {
  return {
    type: actions.CONFIRM_POLICY_RESET
  };
}

export function getPaymentResponse(
  bankOrderId: string
): actions.GetPaymentResponseAction {
  return {
    type: actions.GET_PAYMENT_RESPONSE,
    bankOrderId
  };
}

export function getPaymentResponseRequest(
): actions.GetPaymentResponseRequestAction {
  return {
    type: actions.GET_PAYMENT_RESPONSE_REQUEST
  };
}

export function getPaymentResponseSuccess(
  response: string
): actions.GetPaymentResponseSuccessAction {
  return {
    type: actions.GET_PAYMENT_RESPONSE_SUCCESS,
    data: response
  };
}

export function getPaymentResponseFailure(
  error: Error | string
): actions.GetPaymentResponseFailureAction {
  return {
    type: actions.GET_PAYMENT_RESPONSE_FAILURE,
    error
  };
}

export function getPaymentResponseReset(
): actions.GetPaymentResponseResetAction {
  return {
    type: actions.GET_PAYMENT_RESPONSE_RESET
  };
}

export function bookingDataReset(
): actions.BookingDataResetAction {
  return {
    type: actions.BOOKING_DATA_RESET
  };
}

export function checkFValid(
  customerId: string,
  typePolicy: string,
  subtype: string,
  fvalid: string,
  plateNumber: string,
  chassis: string,
  categoryClassTypeVehicle: string,
  prodYear: number | null,
  power: number | null,
  insuredPeriod: string,
  thPolicy: ThPolicy | null,
  isCustomerVehicleOwner: boolean
): actions.CheckFValidAction {
  return {
    type: actions.CHECK_FVALID,
    customerId, typePolicy, subtype, fvalid, plateNumber, chassis, categoryClassTypeVehicle, prodYear, power, insuredPeriod, thPolicy, isCustomerVehicleOwner
  };
}

export function checkFValidRequest(
): actions.CheckFValidRequestAction {
  return {
    type: actions.CHECK_FVALID_REQUEST
  };
}

export function checkFValidSuccess(
  valid: boolean
): actions.CheckFValidSuccessAction {
  return {
    type: actions.CHECK_FVALID_SUCCESS,
    data: valid
  };
}

export function checkFValidFailure(
  error: Error | string
): actions.CheckFValidFailureAction {
  return {
    type: actions.CHECK_FVALID_FAILURE,
    error
  };
}

export function checkFValidReset(
): actions.CheckFValidResetAction {
  return {
    type: actions.CHECK_FVALID_RESET
  };
}

export function downloadPolicy(
  policyId: string,
): actions.DownloadPolicyAction {
  return {
    type: actions.DOWNLOAD_POLICY,
    policyId
  };
}

export function downloadPolicyRequest(
): actions.DownloadPolicyRequestAction {
  return {
    type: actions.DOWNLOAD_POLICY_REQUEST
  };
}

export function downloadPolicySuccess(
): actions.DownloadPolicySuccessAction {
  return {
    type: actions.DOWNLOAD_POLICY_SUCCESS
  };
}

export function downloadPolicyFailure(
  error: Error | string
): actions.DownloadPolicyFailureAction {
  return {
    type: actions.DOWNLOAD_POLICY_FAILURE,
    error
  };
}

export function exportSearchPolicy(
  filter: Filter
): actions.ExportSearchPolicyAction {
  return {
    type: actions.EXPORT_SEARCH_POLICY,
    filter
  };
}

export function exportSearchPolicyRequest(
): actions.ExportSearchPolicyRequestAction {
  return {
    type: actions.EXPORT_SEARCH_POLICY_REQUEST
  };
}

export function exportSearchPolicyReset(
): actions.ExportSearchPolicyResetAction {
  return {
    type: actions.EXPORT_SEARCH_POLICY_RESET
  };
}

export function exportSearchPolicySuccess(
): actions.ExportSearchPolicySuccessAction {
  return {
    type: actions.EXPORT_SEARCH_POLICY_SUCCESS
  };
}

export function exportSearchPolicyFailure(
  error: Error | string
): actions.ExportSearchPolicyFailureAction {
  return {
    type: actions.EXPORT_SEARCH_POLICY_FAILURE,
    error
  };
}

export function showLoginModal(
  email: string
): actions.ShowLoginModalAction {
  return {
    type: actions.SHOW_LOGIN_MODAL,
    email
  };
}

export function showLoginModalReset(
): actions.ShowLoginModalResetAction {
  return {
    type: actions.SHOW_LOGIN_MODAL_RESET
  };
}

export function showRegistrationModal(
  insuredPerson: any
): actions.ShowRegistrationModalAction {
  return {
    type: actions.SHOW_REGISTRATION_MODAL,
    insuredPerson
  };
}

export function showRegistrationModalReset(
): actions.ShowRegistrationModalResetAction {
  return {
    type: actions.SHOW_REGISTRATION_MODAL_RESET
  };
}

export function showVerificationModal(
): actions.ShowVerificationModalAction {
  return {
    type: actions.SHOW_VERIFICATION_MODAL
  };
}

export function showVerificationModalReset(
): actions.ShowVerificationModalResetAction {
  return {
    type: actions.SHOW_VERIFICATION_MODAL_RESET
  };
}

export function getAppInfo(
): actions.GetAppInfoAction {
  return {
    type: actions.GET_APP_INFO
  };
}

export function getAppInfoRequest(
): actions.GetAppInfoRequestAction {
  return {
    type: actions.GET_APP_INFO_REQUEST
  };
}

export function getAppInfoSuccess(
  data: any
): actions.GetAppInfoSuccessAction {
  return {
    type: actions.GET_APP_INFO_SUCCESS,
    data
  };
}

export function continueWithoutAccount(
): actions.ContinueWithoutAccountAction {
  return {
    type: actions.CONTINUE_WITHOUT_ACCOUNT
  };
}

export function continueWithoutAccountReset(
): actions.ContinueWithoutAccountResetAction {
  return {
    type: actions.CONTINUE_WITHOUT_ACCOUNT_RESET
  };
}


export function showCreateAccountQuestionModal(
  insuredPerson: any
): actions.ShowCreateAccountQuestionModalAction {
  return {
    type: actions.SHOW_CREATE_ACCOUNT_QUESTION_MODAL,
    insuredPerson
  };
}

export function showCreateAccountQuestionModalReset(
): actions.ShowCreateAccountQuestionModalResetAction {
  return {
    type: actions.SHOW_CREATE_ACCOUNT_QUESTION_MODAL_RESET
  };
}