import { BookingData, PhPolicy, Question, ThInsQuotation } from "../../utils/policyTypes";
import * as actions from "../actionTypes/tliPolicyActionTypes";

export function tliQuestionsFailure(
): actions.TLIQuestionsFailureAction {
    return {
        type: actions.TLI_QUESTIONS_FAILURE
    };
}

export function tliQuestionsReset(
): actions.TLIQuestionsResetAction {
    return {
        type: actions.TLI_QUESTIONS_RESET
    };
}

export function getTLIQuotation(
    policy: PhPolicy
): actions.GetTLIQuotationAction {
    return {
        type: actions.GET_TLI_QUOTATION,
        policy
    };
}

export function getTLIQuotationRequest(
): actions.GetTLIQuotationRequestAction {
    return {
        type: actions.GET_TLI_QUOTATION_REQUEST
    };
}

export function getTLIQuotationReset(
): actions.GetTLIQuotationResetAction {
    return {
        type: actions.GET_TLI_QUOTATION_RESET
    };
}

export function getTLIQuotationSuccess(
    values: ThInsQuotation
): actions.GetTLIQuotationSuccessAction {
    return {
        type: actions.GET_TLI_QUOTATION_SUCCESS,
        data: values
    };
}

export function getTLIQuotationFailure(
    error: Error | string
): actions.GetTLIQuotationFailureAction {
    return {
        type: actions.GET_TLI_QUOTATION_FAILURE,
        error
    };
}

export function getPackageTLIQuotation(
    policy: PhPolicy
): actions.GetPackageTLIQuotationAction {
    return {
        type: actions.GET_PACKAGE_TLI_QUOTATION,
        policy
    };
}

export function getPackageTLIQuotationRequest(
): actions.GetPackageTLIQuotationRequestAction {
    return {
        type: actions.GET_PACKAGE_TLI_QUOTATION_REQUEST
    };
}

export function getPackageTLIQuotationReset(
): actions.GetPackageTLIQuotationResetAction {
    return {
        type: actions.GET_PACKAGE_TLI_QUOTATION_RESET
    };
}

export function getPackageTLIQuotationSuccess(
    values: ThInsQuotation
): actions.GetPackageTLIQuotationSuccessAction {
    return {
        type: actions.GET_PACKAGE_TLI_QUOTATION_SUCCESS,
        data: values
    };
}

export function getPackageTLIQuotationFailure(
    error: Error | string
): actions.GetPackageTLIQuotationFailureAction {
    return {
        type: actions.GET_PACKAGE_TLI_QUOTATION_FAILURE,
        error
    };
}

export function bookTLIPolicy(
    policy: PhPolicy,
): actions.BookTLIPolicyAction {
    return {
        type: actions.BOOK_TLI_POLICY,
        policy
    };
}

export function bookTLIPolicyRequest(
): actions.BookTLIPolicyRequestAction {
    return {
        type: actions.BOOK_TLI_POLICY_REQUEST
    };
}

export function bookTLIPolicySuccess(
    values: BookingData
): actions.BookTLIPolicySuccessAction {
    return {
        type: actions.BOOK_TLI_POLICY_SUCCESS,
        data: values
    };
}

export function bookTLIPolicyFailure(
    error: Error | string
): actions.BookTLIPolicyFailureAction {
    return {
        type: actions.BOOK_TLI_POLICY_FAILURE,
        error
    };
}
