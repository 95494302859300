import * as React from 'react';
import { useSelector } from 'react-redux';
import InputFieldMain from '../../Custom/InputFieldMain';
import SelectField from '../../Custom/SelectField';
import { AppState } from '../../../redux/reducers/rootReducer';
import { Nomen } from '../../../utils';
import InputAreaFieldMain from '../../Custom/InputAreaFieldMain';
import * as claimActionTypes from '../../../redux/actionTypes/claimActionTypes';
import { Loading } from '../../Layout/Loading';
import { PreClaimVehicle } from '../../../utils/claimTypes';
import { useFormikContext } from 'formik';
import store from '../../../redux/configureStore';
import * as actions from '../../../redux/actionCreators/claimActionCreators';

const DamagedVehicle = () => {
    const { values }: any = useFormikContext();
    const marks = useSelector<AppState, Nomen[]>((state: any) => state.nomen.VehicleMarks);
    const types = useSelector<AppState, Nomen[]>((state: any) => state.nomen.TypeVehicle);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[claimActionTypes.FETCH_VEHICLE_FROM_POLICY]
        || state.isLoading[claimActionTypes.GET_VEHICLE_BY_PLATE_NUMBER]
        || state.isLoading[claimActionTypes.GET_VEHICLE_BY_CHASSIS]);
    const vehicle = useSelector<AppState, PreClaimVehicle | null>((state: any) => state.claim.vehicleByChassisOrPlate);

    const getVehicleByPlateNumber = (value: any) => {
        store.dispatch(actions.getVehicleByPlateNumberReset());
        store.dispatch(actions.getVehicleByPlateNumber(value));
    };

    const getVehicleByChassis = (value: any) => {
        store.dispatch(actions.getVehicleByChassisReset());
        store.dispatch(actions.getVehicleByChassis(value));
    };

    return (<>

        {isLoading
            ? <Loading />
            :
            <>
                <div className="wizardTitle">I Dëmtuari - Automjeti</div>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="regnum" label="Targa e mjetit dëmtuar" placeholder="Targa e mjetit dëmtuar" blur={getVehicleByPlateNumber} newvalue={vehicle?.regnum || values?.regnum} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="chassis" label="Numri i shasisë" placeholder="Numri i shasisë" blur={getVehicleByChassis} newvalue={vehicle?.chassis || values?.chassis} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <SelectField name="type_vehicle" label="Mjeti/Klasa" items={types} newvalue={(vehicle?.type_vehicle?.code ? vehicle?.type_vehicle?.code : values?.type_vehicle)} />
                        </div>
                        <div className="col-xl-6">
                            <SelectField name="mark" label="Prodhuesi" items={marks} newvalue={(vehicle?.mark?.code ? vehicle?.mark?.code : values?.mark) || values?.mark} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="tip" label="Modeli" placeholder="Modeli" newvalue={vehicle?.tip || values?.tip} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="power" label="Fuqia(kw) " placeholder="Fuqia(kw)" newvalue={vehicle?.power || values?.power} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="prodyear" label="Viti i prodhimit" placeholder="Viti i prodhimit" newvalue={vehicle?.prodyear || values?.prodyear} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="placenr" label="Nr. I ulëseve" placeholder="Nr. I ulëseve" newvalue={vehicle?.placenr || values?.placenr} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="capacity" label="Pesha e automjetit" placeholder="Pesha e automjetit" newvalue={vehicle?.capacity || values?.capacity} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="colour" label="Ngjyra e automjetit" placeholder="Ngjyra e automjetit" newvalue={vehicle?.colour || values?.colour} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="volume" label="Kapaciteti (cm3)" placeholder="Kapaciteti (cm3)" newvalue={vehicle?.volume || values?.volume} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="vehiclelicence" label="Leje qarkullimi" placeholder="Leje qarkullimi" newvalue={vehicle?.vehiclelicence || values?.vehiclelicence} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                        </div>
                        <div className="col-xl-6">
                            <InputAreaFieldMain type="text" name="damaged_parts" label="Mjeti Dëmtime" placeholder="Mjeti Dëmtime" newvalue={vehicle?.damaged_parts || values?.damaged_parts} />
                        </div>
                    </div>
                </div>
            </>}
    </>
    );
}

export default DamagedVehicle;
