import { BookingData, KaskoPolicy, ThInsQuotation } from "../../utils/policyTypes";

export const GET_KASKO_QUOTATION = "policyActionTypes/GET_KASKO_QUOTATION";
export interface GetKaskoQuotationAction {
  type: typeof GET_KASKO_QUOTATION;
  policy: KaskoPolicy;
}

export const GET_KASKO_QUOTATION_REQUEST = "policyActionTypes/GET_KASKO_QUOTATION_REQUEST";
export interface GetKaskoQuotationRequestAction {
  type: typeof GET_KASKO_QUOTATION_REQUEST;
}

export const GET_KASKO_QUOTATION_RESET = "policyActionTypes/GET_KASKO_QUOTATION_RESET";
export interface GetKaskoQuotationResetAction {
  type: typeof GET_KASKO_QUOTATION_RESET;
}

export const GET_KASKO_QUOTATION_SUCCESS = "policyActionTypes/GET_KASKO_QUOTATION_SUCCESS";
export interface GetKaskoQuotationSuccessAction {
  type: typeof GET_KASKO_QUOTATION_SUCCESS;
  data: ThInsQuotation;
}

export const GET_KASKO_QUOTATION_FAILURE = "policyActionTypes/GET_KASKO_QUOTATION_FAILURE";
export interface GetKaskoQuotationFailureAction {
  type: typeof GET_KASKO_QUOTATION_FAILURE;
  error: Error | string;
}

export const BOOK_KASKO_POLICY = "policyActionTypes/BOOK_KASKO_POLICY";
export interface BookKaskoPolicyAction {
  type: typeof BOOK_KASKO_POLICY;
  policy: KaskoPolicy;
}

export const BOOK_KASKO_POLICY_REQUEST = "policyActionTypes/BOOK_KASKO_POLICY_REQUEST";
export interface BookKaskoPolicyRequestAction {
  type: typeof BOOK_KASKO_POLICY_REQUEST;
}

export const BOOK_KASKO_POLICY_SUCCESS = "policyActionTypes/BOOK_KASKO_POLICY_SUCCESS";
export interface BookKaskoPolicySuccessAction {
  type: typeof BOOK_KASKO_POLICY_SUCCESS;
  data: BookingData;
}

export const BOOK_KASKO_POLICY_FAILURE = "policyActionTypes/BOOK_KASKO_POLICY_FAILURE";
export interface BookKaskoPolicyFailureAction {
  type: typeof BOOK_KASKO_POLICY_FAILURE;
  error: Error | string;
}


export type PolicyAction =
  | GetKaskoQuotationAction
  | GetKaskoQuotationRequestAction
  | GetKaskoQuotationResetAction
  | GetKaskoQuotationSuccessAction
  | GetKaskoQuotationFailureAction
  | BookKaskoPolicyAction
  | BookKaskoPolicyRequestAction
  | BookKaskoPolicySuccessAction
  | BookKaskoPolicyFailureAction;
