import { MotorInsQuotation, BookingData, MotorPolicy } from '../../utils/policyTypes';

export const GET_AMFTPL_QUOTATION = "policyActionTypes/GET_AMFTPL_QUOTATION";
export interface GetAMFTPLQuotationAction {
  type: typeof GET_AMFTPL_QUOTATION;
  customerId: string;
  platenumber: string;
  chassis: string;
  isCustomerVehicleOwner: boolean;
}

export const GET_AMFTPL_QUOTATION_REQUEST = "policyActionTypes/GET_AMFTPL_QUOTATION_REQUEST";
export interface GetAMFTPLQuotationRequestAction {
  type: typeof GET_AMFTPL_QUOTATION_REQUEST;
}

export const GET_AMFTPL_QUOTATION_RESET = "policyActionTypes/GET_AMFTPL_QUOTATION_RESET";
export interface GetAMFTPLQuotationResetAction {
  type: typeof GET_AMFTPL_QUOTATION_RESET;
}

export const GET_AMFTPL_QUOTATION_SUCCESS = "policyActionTypes/GET_AMFTPL_QUOTATION_SUCCESS";
export interface GetAMFTPLQuotationSuccessAction {
  type: typeof GET_AMFTPL_QUOTATION_SUCCESS;
  data: MotorInsQuotation;
}

export const GET_AMFTPL_QUOTATION_FAILURE = "policyActionTypes/GET_AMFTPL_QUOTATION_FAILURE";
export interface GetAMFTPLQuotationFailureAction {
  type: typeof GET_AMFTPL_QUOTATION_FAILURE;
  error: Error | string;
}

export const GET_TPL_QUOTATION = "policyActionTypes/GET_TPL_QUOTATION";
export interface GetTplQuotationAction {
  type: typeof GET_TPL_QUOTATION;
  customerId: string;
  tplVehicleCategoryClass: string;
  prodyear: number;
  power: number;
}

export const GET_TPL_QUOTATION_REQUEST = "policyActionTypes/GET_TPL_QUOTATION_REQUEST";
export interface GetTplQuotationRequestAction {
  type: typeof GET_TPL_QUOTATION_REQUEST;
}

export const GET_TPL_QUOTATION_RESET = "policyActionTypes/GET_TPL_QUOTATION_RESET";
export interface GetTplQuotationResetAction {
  type: typeof GET_TPL_QUOTATION_RESET;
}

export const GET_TPL_QUOTATION_SUCCESS = "policyActionTypes/GET_TPL_QUOTATION_SUCCESS";
export interface GetTplQuotationSuccessAction {
  type: typeof GET_TPL_QUOTATION_SUCCESS;
  data: MotorInsQuotation;
}

export const GET_TPL_QUOTATION_FAILURE = "policyActionTypes/GET_TPL_QUOTATION_FAILURE";
export interface GetTplQuotationFailureAction {
  type: typeof GET_TPL_QUOTATION_FAILURE;
  error: Error | string;
}

export const BOOK_TPL_POLICY = "policyActionTypes/BOOK_TPL_POLICY";
export interface BookTplPolicyAction {
  type: typeof BOOK_TPL_POLICY;
  policy: MotorPolicy;
  payment: string;
}

export const BOOK_TPL_POLICY_REQUEST = "policyActionTypes/BOOK_TPL_POLICY_REQUEST";
export interface BookTplPolicyRequestAction {
  type: typeof BOOK_TPL_POLICY_REQUEST;
}

export const BOOK_TPL_POLICY_SUCCESS = "policyActionTypes/BOOK_TPL_POLICY_SUCCESS";
export interface BookTplPolicySuccessAction {
  type: typeof BOOK_TPL_POLICY_SUCCESS;
  data: BookingData;
}

export const BOOK_TPL_POLICY_FAILURE = "policyActionTypes/BOOK_TPL_POLICY_FAILURE";
export interface BookTplPolicyFailureAction {
  type: typeof BOOK_TPL_POLICY_FAILURE;
  error: Error | string;
}

export type PolicyAction =
  | GetAMFTPLQuotationAction
  | GetAMFTPLQuotationRequestAction
  | GetAMFTPLQuotationResetAction
  | GetAMFTPLQuotationSuccessAction
  | GetAMFTPLQuotationFailureAction
  | GetTplQuotationAction
  | GetTplQuotationRequestAction
  | GetTplQuotationResetAction
  | GetTplQuotationSuccessAction
  | GetTplQuotationFailureAction
  | BookTplPolicyAction
  | BookTplPolicyRequestAction
  | BookTplPolicySuccessAction
  | BookTplPolicyFailureAction
  