import { BookingData, CliPolicy, Question, ThInsQuotation } from "../../utils/policyTypes";

export const CLI_QUESTIONS_FAILURE = "policyActionTypes/CLI_QUESTIONS_FAILURE";
export interface CLIQuestionsFailureAction {
  type: typeof CLI_QUESTIONS_FAILURE;
}

export const CLI_QUESTIONS_RESET = "policyActionTypes/CLI_QUESTIONS_RESET";
export interface CLIQuestionsResetAction {
  type: typeof CLI_QUESTIONS_RESET;
}

export const GET_CLI_QUOTATION = "policyActionTypes/GET_CLI_QUOTATION";
export interface GetCLIQuotationAction {
  type: typeof GET_CLI_QUOTATION;
  policy: CliPolicy;
}

export const GET_CLI_QUOTATION_REQUEST = "policyActionTypes/GET_CLI_QUOTATION_REQUEST";
export interface GetCLIQuotationRequestAction {
  type: typeof GET_CLI_QUOTATION_REQUEST;
}

export const GET_CLI_QUOTATION_RESET = "policyActionTypes/GET_CLI_QUOTATION_RESET";
export interface GetCLIQuotationResetAction {
  type: typeof GET_CLI_QUOTATION_RESET;
}

export const GET_CLI_QUOTATION_SUCCESS = "policyActionTypes/GET_CLI_QUOTATION_SUCCESS";
export interface GetCLIQuotationSuccessAction {
  type: typeof GET_CLI_QUOTATION_SUCCESS;
  data: ThInsQuotation;
}

export const GET_CLI_QUOTATION_FAILURE = "policyActionTypes/GET_CLI_QUOTATION_FAILURE";
export interface GetCLIQuotationFailureAction {
  type: typeof GET_CLI_QUOTATION_FAILURE;
  error: Error | string;
}


export const GET_PACKAGE_CLI_QUOTATION = "policyActionTypes/GET_PACKAGE_CLI_QUOTATION";
export interface GetPackageCLIQuotationAction {
  type: typeof GET_PACKAGE_CLI_QUOTATION;
  policy: CliPolicy;
}

export const GET_PACKAGE_CLI_QUOTATION_REQUEST = "policyActionTypes/GET_PACKAGE_CLI_QUOTATION_REQUEST";
export interface GetPackageCLIQuotationRequestAction {
  type: typeof GET_PACKAGE_CLI_QUOTATION_REQUEST;
}

export const GET_PACKAGE_CLI_QUOTATION_RESET = "policyActionTypes/GET_PACKAGE_CLI_QUOTATION_RESET";
export interface GetPackageCLIQuotationResetAction {
  type: typeof GET_PACKAGE_CLI_QUOTATION_RESET;
}

export const GET_PACKAGE_CLI_QUOTATION_SUCCESS = "policyActionTypes/GET_PACKAGE_CLI_QUOTATION_SUCCESS";
export interface GetPackageCLIQuotationSuccessAction {
  type: typeof GET_PACKAGE_CLI_QUOTATION_SUCCESS;
  data: ThInsQuotation;
}

export const GET_PACKAGE_CLI_QUOTATION_FAILURE = "policyActionTypes/GET_PACKAGE_CLI_QUOTATION_FAILURE";
export interface GetPackageCLIQuotationFailureAction {
  type: typeof GET_PACKAGE_CLI_QUOTATION_FAILURE;
  error: Error | string;
}

export const BOOK_CLI_POLICY = "policyActionTypes/BOOK_CLI_POLICY";
export interface BookCLIPolicyAction {
  type: typeof BOOK_CLI_POLICY;
  policy: CliPolicy;
}

export const BOOK_CLI_POLICY_REQUEST = "policyActionTypes/BOOK_CLI_POLICY_REQUEST";
export interface BookCLIPolicyRequestAction {
  type: typeof BOOK_CLI_POLICY_REQUEST;
}

export const BOOK_CLI_POLICY_SUCCESS = "policyActionTypes/BOOK_CLI_POLICY_SUCCESS";
export interface BookCLIPolicySuccessAction {
  type: typeof BOOK_CLI_POLICY_SUCCESS;
  data: BookingData;
}

export const BOOK_CLI_POLICY_FAILURE = "policyActionTypes/BOOK_CLI_POLICY_FAILURE";
export interface BookCLIPolicyFailureAction {
  type: typeof BOOK_CLI_POLICY_FAILURE;
  error: Error | string;
}

export const SHOW_POLITICAL_FORM_MODAL = "generalActionTypes/SHOW_POLITICAL_FORM_MODAL";
export interface ShowPoliticalFormModalModalAction {
  type: typeof SHOW_POLITICAL_FORM_MODAL;
}

export const HIDE_POLITICAL_FORM_MODAL = "generalActionTypes/HIDE_POLITICAL_FORM_MODAL";
export interface HidePoliticalFormModalModalAction {
  type: typeof HIDE_POLITICAL_FORM_MODAL;
}

export const SUBMIT_POLITICAL_FORM = "policyActionTypes/SUBMIT_POLITICAL_FORM";
export interface SubmitPoliticalFormAction {
  type: typeof SUBMIT_POLITICAL_FORM;
  data: Question[];
}

export const RESET_POLITICAL_FORM = "policyActionTypes/RESET_POLITICAL_FORM";
export interface ResetPoliticalFormAction {
  type: typeof RESET_POLITICAL_FORM;
}

export type PolicyAction =
  | CLIQuestionsFailureAction
  | CLIQuestionsResetAction
  | GetCLIQuotationAction
  | GetCLIQuotationRequestAction
  | GetCLIQuotationResetAction
  | GetCLIQuotationSuccessAction
  | GetCLIQuotationFailureAction
  | GetPackageCLIQuotationRequestAction
  | GetPackageCLIQuotationResetAction
  | GetPackageCLIQuotationSuccessAction
  | GetPackageCLIQuotationFailureAction
  | BookCLIPolicyAction
  | BookCLIPolicyRequestAction
  | BookCLIPolicySuccessAction
  | BookCLIPolicyFailureAction
  | ShowPoliticalFormModalModalAction
  | HidePoliticalFormModalModalAction
  | SubmitPoliticalFormAction
  | ResetPoliticalFormAction;