import { BookingData, PhPolicy, Question, ThInsQuotation } from "../../utils/policyTypes";

export const TLI_QUESTIONS_FAILURE = "policyActionTypes/TLI_QUESTIONS_FAILURE";
export interface TLIQuestionsFailureAction {
  type: typeof TLI_QUESTIONS_FAILURE;
}

export const TLI_QUESTIONS_RESET = "policyActionTypes/TLI_QUESTIONS_RESET";
export interface TLIQuestionsResetAction {
  type: typeof TLI_QUESTIONS_RESET;
}

export const GET_TLI_QUOTATION = "policyActionTypes/GET_TLI_QUOTATION";
export interface GetTLIQuotationAction {
  type: typeof GET_TLI_QUOTATION;
  policy: PhPolicy;
}

export const GET_TLI_QUOTATION_REQUEST = "policyActionTypes/GET_TLI_QUOTATION_REQUEST";
export interface GetTLIQuotationRequestAction {
  type: typeof GET_TLI_QUOTATION_REQUEST;
}

export const GET_TLI_QUOTATION_RESET = "policyActionTypes/GET_TLI_QUOTATION_RESET";
export interface GetTLIQuotationResetAction {
  type: typeof GET_TLI_QUOTATION_RESET;
}

export const GET_TLI_QUOTATION_SUCCESS = "policyActionTypes/GET_TLI_QUOTATION_SUCCESS";
export interface GetTLIQuotationSuccessAction {
  type: typeof GET_TLI_QUOTATION_SUCCESS;
  data: ThInsQuotation;
}

export const GET_TLI_QUOTATION_FAILURE = "policyActionTypes/GET_TLI_QUOTATION_FAILURE";
export interface GetTLIQuotationFailureAction {
  type: typeof GET_TLI_QUOTATION_FAILURE;
  error: Error | string;
}


export const GET_PACKAGE_TLI_QUOTATION = "policyActionTypes/GET_PACKAGE_TLI_QUOTATION";
export interface GetPackageTLIQuotationAction {
  type: typeof GET_PACKAGE_TLI_QUOTATION;
  policy: PhPolicy;
}

export const GET_PACKAGE_TLI_QUOTATION_REQUEST = "policyActionTypes/GET_PACKAGE_TLI_QUOTATION_REQUEST";
export interface GetPackageTLIQuotationRequestAction {
  type: typeof GET_PACKAGE_TLI_QUOTATION_REQUEST;
}

export const GET_PACKAGE_TLI_QUOTATION_RESET = "policyActionTypes/GET_PACKAGE_TLI_QUOTATION_RESET";
export interface GetPackageTLIQuotationResetAction {
  type: typeof GET_PACKAGE_TLI_QUOTATION_RESET;
}

export const GET_PACKAGE_TLI_QUOTATION_SUCCESS = "policyActionTypes/GET_PACKAGE_TLI_QUOTATION_SUCCESS";
export interface GetPackageTLIQuotationSuccessAction {
  type: typeof GET_PACKAGE_TLI_QUOTATION_SUCCESS;
  data: ThInsQuotation;
}

export const GET_PACKAGE_TLI_QUOTATION_FAILURE = "policyActionTypes/GET_PACKAGE_TLI_QUOTATION_FAILURE";
export interface GetPackageTLIQuotationFailureAction {
  type: typeof GET_PACKAGE_TLI_QUOTATION_FAILURE;
  error: Error | string;
}

export const BOOK_TLI_POLICY = "policyActionTypes/BOOK_TLI_POLICY";
export interface BookTLIPolicyAction {
  type: typeof BOOK_TLI_POLICY;
  policy: PhPolicy;
}

export const BOOK_TLI_POLICY_REQUEST = "policyActionTypes/BOOK_TLI_POLICY_REQUEST";
export interface BookTLIPolicyRequestAction {
  type: typeof BOOK_TLI_POLICY_REQUEST;
}

export const BOOK_TLI_POLICY_SUCCESS = "policyActionTypes/BOOK_TLI_POLICY_SUCCESS";
export interface BookTLIPolicySuccessAction {
  type: typeof BOOK_TLI_POLICY_SUCCESS;
  data: BookingData;
}

export const BOOK_TLI_POLICY_FAILURE = "policyActionTypes/BOOK_TLI_POLICY_FAILURE";
export interface BookTLIPolicyFailureAction {
  type: typeof BOOK_TLI_POLICY_FAILURE;
  error: Error | string;
}

export type PolicyAction =
  | TLIQuestionsFailureAction
  | TLIQuestionsResetAction
  | GetTLIQuotationAction
  | GetTLIQuotationRequestAction
  | GetTLIQuotationResetAction
  | GetTLIQuotationSuccessAction
  | GetTLIQuotationFailureAction
  | GetPackageTLIQuotationRequestAction
  | GetPackageTLIQuotationResetAction
  | GetPackageTLIQuotationSuccessAction
  | GetPackageTLIQuotationFailureAction
  | BookTLIPolicyAction
  | BookTLIPolicyRequestAction
  | BookTLIPolicySuccessAction
  | BookTLIPolicyFailureAction;