import React, { FunctionComponent } from 'react';
import { useField, useFormikContext } from 'formik';
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";

interface Props {
    name: string;
    type: string;
    placeholder: string;
    label: string;
    items: { id: string, value: string }[]
    newvalue?: string;
    change?: (value: any) => void;
    inline?: boolean;
}

const RadioButtonGroupFieldMain: FunctionComponent<Props> = (props) => {
    const [
        field,
        { error, touched }
    ] = useField({
        name: props.name,
        type: props.name,
    });

    const {
        setFieldValue,
    } = useFormikContext();

    React.useEffect(() => {
        if (!!props.newvalue) {
            setFieldValue(props.name, props.newvalue);
        }
    }, [setFieldValue, props.name, props.newvalue]);


    const handleChange = (value: any) => {
        if (!!props.change) {
            setFieldValue(props.name, value);
            props.change(value);
        }
    }

    const formClass = 'form-group ' + (props.inline ? 'inlineField' : '');

    return (
        <div className={formClass}>
            <RadioGroup
                className="form-control form-control-solid h-auto px-6 border-0 rounded-lg font-size-h6"
                aria-label={props.label}
                {...props}
                {...field}
                row
            >
                {props.items.map((item) =>
                    <FormControlLabel
                        key={item.value}
                        control={<Radio checked={item.id === field.value}/>}
                        label={item.value}
                        value={item.id}
                        onChange={(event: any) => handleChange(event.target.value)}
                    />
                )}
            </RadioGroup>
            {touched && error !== "" ? (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{error}</div>
                </div>
            ) : null}
        </div>
    );
};

export default RadioButtonGroupFieldMain;