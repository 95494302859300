import { ClaimFilter, ClaimGridItem, PreClaim, PreClaimFilter, PreClaimGridItem, PreClaimPreview, PreClaimRegisterData, PreClaimVehicle, PreClaimPerson } from './../../utils/claimTypes';
import * as actions from "../actionTypes/claimActionTypes";

export function registerPreClaim(
  preClaim: PreClaim
): actions.RegisterPreClaimAction {
  return {
    type: actions.REGISTER_PRE_CLAIM,
    preClaim
  };
}

export function registerPreClaimRequest(
): actions.RegisterPreClaimRequestAction {
  return {
    type: actions.REGISTER_PRE_CLAIM_REQUEST
  };
}

export function registerPreClaimSuccess(
  values: PreClaimRegisterData
): actions.RegisterPreClaimSuccessAction {
  return {
    type: actions.REGISTER_PRE_CLAIM_SUCCESS,
    data: values
  };
}

export function registerPreClaimFailure(
  error: Error | string
): actions.RegisterPreClaimFailureAction {
  return {
    type: actions.REGISTER_PRE_CLAIM_FAILURE,
    error
  };
}

export function searchPreClaim(
  filter: PreClaimFilter
): actions.SearchPreClaimAction {
  return {
    type: actions.SEARCH_PRECLAIM,
    filter
  };
}

export function searchPreClaimRequest(
): actions.SearchPreClaimRequestAction {
  return {
    type: actions.SEARCH_PRECLAIM_REQUEST
  };
}

export function searchPreClaimReset(
): actions.SearchPreClaimResetAction {
  return {
    type: actions.SEARCH_PRECLAIM_RESET
  };
}

export function searchPreClaimSuccess(
  items: PreClaimGridItem[],
  count: number
): actions.SearchPreClaimSuccessAction {
  return {
    type: actions.SEARCH_PRECLAIM_SUCCESS,
    data: items,
    count
  };
}

export function searchPreClaimFailure(
  error: Error | string
): actions.SearchPreClaimFailureAction {
  return {
    type: actions.SEARCH_PRECLAIM_FAILURE,
    error
  };
}

export function preClaimPreview(
  code: string
): actions.PreClaimPreviewAction {
  return {
    type: actions.PRECLAIM_PREVIEW,
    code
  };
}

export function preClaimPreviewRequest(
): actions.PreClaimPreviewRequestAction {
  return {
    type: actions.PRECLAIM_PREVIEW_REQUEST
  };
}

export function preClaimPreviewSuccess(
  preClaim: PreClaimPreview
): actions.PreClaimPreviewSuccessAction {
  return {
    type: actions.PRECLAIM_PREVIEW_SUCCESS,
    data: preClaim
  };
}

export function preClaimPreviewFailure(
  error: Error | string
): actions.PreClaimPreviewFailureAction {
  return {
    type: actions.PRECLAIM_PREVIEW_FAILURE,
    error
  };
}

export function preClaimPreviewReset(
): actions.PreClaimPreviewResetAction {
  return {
    type: actions.PRECLAIM_PREVIEW_RESET
  };
}

export function showPreClaimModal(
): actions.ShowPreClaimModalAction {
  return {
    type: actions.SHOW_PRECLAIM_MODAL,
  };
}

export function showClaimModal(
): actions.ShowClaimModalAction {
  return {
    type: actions.SHOW_CLAIM_MODAL,
  };
}

export function claimPreview(
  claimId: string
): actions.ClaimPreviewAction {
  return {
    type: actions.CLAIM_PREVIEW,
    claimId
  };
}

export function claimPreviewRequest(
): actions.ClaimPreviewRequestAction {
  return {
    type: actions.CLAIM_PREVIEW_REQUEST
  };
}

export function claimPreviewSuccess(
  claim: ClaimGridItem
): actions.ClaimPreviewSuccessAction {
  return {
    type: actions.CLAIM_PREVIEW_SUCCESS,
    data: claim
  };
}

export function claimPreviewFailure(
  error: Error | string
): actions.ClaimPreviewFailureAction {
  return {
    type: actions.CLAIM_PREVIEW_FAILURE,
    error
  };
}

export function claimPreviewReset(
): actions.ClaimPreviewResetAction {
  return {
    type: actions.CLAIM_PREVIEW_RESET
  };
}

export function searchClaim(
  filter: ClaimFilter
): actions.SearchClaimAction {
  return {
    type: actions.SEARCH_CLAIM,
    filter
  };
}

export function searchClaimRequest(
): actions.SearchClaimRequestAction {
  return {
    type: actions.SEARCH_CLAIM_REQUEST
  };
}

export function searchClaimReset(
): actions.SearchClaimResetAction {
  return {
    type: actions.SEARCH_CLAIM_RESET
  };
}

export function searchClaimSuccess(
  items: ClaimGridItem[],
  count: number
): actions.SearchClaimSuccessAction {
  return {
    type: actions.SEARCH_CLAIM_SUCCESS,
    data: items,
    count
  };
}

export function searchClaimFailure(
  error: Error | string
): actions.SearchClaimFailureAction {
  return {
    type: actions.SEARCH_CLAIM_FAILURE,
    error
  };
}

export function fetchVehicleFromPolicy(
  policyType: string,
  policyCode: string,
): actions.FetchVehicleFromPolicyAction {
  return {
    type: actions.FETCH_VEHICLE_FROM_POLICY,
    policyType, policyCode
  };
}

export function fetchVehicleFromPolicyRequest(
): actions.FetchVehicleFromPolicyRequestAction {
  return {
    type: actions.FETCH_VEHICLE_FROM_POLICY_REQUEST
  };
}

export function fetchVehicleFromPolicyReset(
): actions.FetchVehicleFromPolicyResetAction {
  return {
    type: actions.FETCH_VEHICLE_FROM_POLICY_RESET
  };
}

export function fetchVehicleFromPolicySuccess(
  vehicle: PreClaimVehicle
): actions.FetchVehicleFromPolicySuccessAction {
  return {
    type: actions.FETCH_VEHICLE_FROM_POLICY_SUCCESS,
    data: vehicle
  };
}

export function fetchVehicleFromPolicyFailure(
  error: Error | string
): actions.FetchVehicleFromPolicyFailureAction {
  return {
    type: actions.FETCH_VEHICLE_FROM_POLICY_FAILURE,
    error
  };
}

export function fetchInsuredPersonFromPolicy(
  policyType: string,
  policyCode: string,
  customerId: string,
): actions.FetchInsuredPersonFromPolicyAction {
  return {
    type: actions.FETCH_INSURED_PERSON_FROM_POLICY,
    policyType, policyCode, customerId
  };
}

export function fetchInsuredPersonFromPolicyRequest(
): actions.FetchInsuredPersonFromPolicyRequestAction {
  return {
    type: actions.FETCH_INSURED_PERSON_FROM_POLICY_REQUEST
  };
}

export function fetchInsuredPersonFromPolicyReset(
): actions.FetchInsuredPersonFromPolicyResetAction {
  return {
    type: actions.FETCH_INSURED_PERSON_FROM_POLICY_RESET
  };
}

export function fetchInsuredPersonFromPolicySuccess(
  insuredPerson: PreClaimPerson
): actions.FetchInsuredPersonFromPolicySuccessAction {
  return {
    type: actions.FETCH_INSURED_PERSON_FROM_POLICY_SUCCESS,
    data: insuredPerson
  };
}

export function fetchInsuredPersonFromPolicyFailure(
  error: Error | string
): actions.FetchInsuredPersonFromPolicyFailureAction {
  return {
    type: actions.FETCH_INSURED_PERSON_FROM_POLICY_FAILURE,
    error
  };
}

export function registerHealthPreClaim(
  preClaim: PreClaim
): actions.RegisterHealthPreClaimAction {
  return {
    type: actions.REGISTER_HEALTH_PRE_CLAIM,
    preClaim
  };
}

export function registerHealthPreClaimRequest(
): actions.RegisterHealthPreClaimRequestAction {
  return {
    type: actions.REGISTER_HEALTH_PRE_CLAIM_REQUEST
  };
}

export function registerHealthPreClaimSuccess(
  values: PreClaimRegisterData
): actions.RegisterHealthPreClaimSuccessAction {
  return {
    type: actions.REGISTER_HEALTH_PRE_CLAIM_SUCCESS,
    data: values
  };
}

export function registerHealthPreClaimFailure(
  error: Error | string
): actions.RegisterHealthPreClaimFailureAction {
  return {
    type: actions.REGISTER_HEALTH_PRE_CLAIM_FAILURE,
    error
  };
}

export function registerPropertyPreClaim(
  preClaim: PreClaim
): actions.RegisterPropertyPreClaimAction {
  return {
    type: actions.REGISTER_PROPERTY_PRE_CLAIM,
    preClaim
  };
}

export function registerPropertyPreClaimRequest(
): actions.RegisterPropertyPreClaimRequestAction {
  return {
    type: actions.REGISTER_PROPERTY_PRE_CLAIM_REQUEST
  };
}

export function registerPropertyPreClaimSuccess(
  values: PreClaimRegisterData
): actions.RegisterPropertyPreClaimSuccessAction {
  return {
    type: actions.REGISTER_PROPERTY_PRE_CLAIM_SUCCESS,
    data: values
  };
}

export function registerPropertyPreClaimFailure(
  error: Error | string
): actions.RegisterPropertyPreClaimFailureAction {
  return {
    type: actions.REGISTER_PROPERTY_PRE_CLAIM_FAILURE,
    error
  };
}

export function searchClaimHistory(
  filter: ClaimFilter
): actions.SearchClaimHistoryAction {
  return {
    type: actions.SEARCH_CLAIM_HISTORY,
    filter
  };
}

export function searchClaimHistoryRequest(
): actions.SearchClaimHistoryRequestAction {
  return {
    type: actions.SEARCH_CLAIM_HISTORY_REQUEST
  };
}

export function searchClaimHistoryReset(
): actions.SearchClaimHistoryResetAction {
  return {
    type: actions.SEARCH_CLAIM_HISTORY_RESET
  };
}

export function searchClaimHistorySuccess(
  items: ClaimGridItem[],
  count: number
): actions.SearchClaimHistorySuccessAction {
  return {
    type: actions.SEARCH_CLAIM_HISTORY_SUCCESS,
    data: items,
    count
  };
}

export function searchClaimHistoryFailure(
  error: Error | string
): actions.SearchClaimHistoryFailureAction {
  return {
    type: actions.SEARCH_CLAIM_HISTORY_FAILURE,
    error
  };
}

export function downloadClaimHistoryDocument(
  filter: ClaimFilter
): actions.DownloadClaimHistoryDocumentAction {
  return {
    type: actions.DOWNLOAD_CLAIM_HISTORY_DOCUMENT,
    filter
  };
}

export function downloadClaimHistoryDocumentRequest(
): actions.DownloadClaimHistoryDocumentRequestAction {
  return {
    type: actions.DOWNLOAD_CLAIM_HISTORY_DOCUMENT_REQUEST
  };
}

export function downloadClaimHistoryDocumentSuccess(
): actions.DownloadClaimHistoryDocumentSuccessAction {
  return {
    type: actions.DOWNLOAD_CLAIM_HISTORY_DOCUMENT_SUCCESS
  };
}

export function downloadClaimHistoryDocumentFailure(
  error: Error | string
): actions.DownloadClaimHistoryDocumentFailureAction {
  return {
    type: actions.DOWNLOAD_CLAIM_HISTORY_DOCUMENT_FAILURE,
    error
  };
}

export function downloadBonusMalusDocument(
  customerId: string,
  plateNumber: string,
  chassis: string
): actions.DownloadBonusMalusDocumentAction {
  return {
    type: actions.DOWNLOAD_BONUS_MALUS_DOCUMENT,
    customerId, plateNumber, chassis
  };
}

export function downloadBonusMalusDocumentRequest(
): actions.DownloadBonusMalusDocumentRequestAction {
  return {
    type: actions.DOWNLOAD_BONUS_MALUS_DOCUMENT_REQUEST
  };
}

export function downloadBonusMalusDocumentSuccess(
): actions.DownloadBonusMalusDocumentSuccessAction {
  return {
    type: actions.DOWNLOAD_BONUS_MALUS_DOCUMENT_SUCCESS
  };
}

export function downloadBonusMalusDocumentFailure(
  error: Error | string
): actions.DownloadBonusMalusDocumentFailureAction {
  return {
    type: actions.DOWNLOAD_BONUS_MALUS_DOCUMENT_FAILURE,
    error
  };
}

export function sendClaimHistoryMail(
  data: any,
): actions.SendClaimHistoryMailAction {
  return {
    type: actions.SEND_CLAIM_HISTORY_MAIL,
    data
  };
}

export function sendClaimHistoryMailRequest(
): actions.SendClaimHistoryMailRequestAction {
  return {
    type: actions.SEND_CLAIM_HISTORY_MAIL_REQUEST
  };
}

export function sendClaimHistoryMailSuccess(
): actions.SendClaimHistoryMailSuccessAction {
  return {
    type: actions.SEND_CLAIM_HISTORY_MAIL_SUCCESS
  };
}

export function sendClaimHistoryMailFailure(
  error: Error | string
): actions.SendClaimHistoryMailFailureAction {
  return {
    type: actions.SEND_CLAIM_HISTORY_MAIL_FAILURE,
    error
  };
}

export function exportSearchPreClaim(
  filter: PreClaimFilter
): actions.ExportSearchPreClaimAction {
  return {
    type: actions.EXPORT_SEARCH_PRECLAIM,
    filter
  };
}

export function exportSearchPreClaimRequest(
): actions.ExportSearchPreClaimRequestAction {
  return {
    type: actions.EXPORT_SEARCH_PRECLAIM_REQUEST
  };
}

export function exportSearchPreClaimReset(
): actions.ExportSearchPreClaimResetAction {
  return {
    type: actions.EXPORT_SEARCH_PRECLAIM_RESET
  };
}

export function exportSearchPreClaimSuccess(
): actions.ExportSearchPreClaimSuccessAction {
  return {
    type: actions.EXPORT_SEARCH_PRECLAIM_SUCCESS,
  };
}

export function exportSearchPreClaimFailure(
  error: Error | string
): actions.ExportSearchPreClaimFailureAction {
  return {
    type: actions.EXPORT_SEARCH_PRECLAIM_FAILURE,
    error
  };
}

export function getInsuredPersonById(
  id: string
): actions.GetInsuredPersonByIdAction {
  return {
    type: actions.GET_INSURED_PERSON_BY_ID,
    id
  };
}

export function getInsuredPersonByIdRequest(
): actions.GetInsuredPersonByIdRequestAction {
  return {
    type: actions.GET_INSURED_PERSON_BY_ID_REQUEST
  };
}

export function getInsuredPersonByIdReset(
): actions.GetInsuredPersonByIdResetAction {
  return {
    type: actions.GET_INSURED_PERSON_BY_ID_RESET
  };
}

export function getInsuredPersonByIdSuccess(
  personById: PreClaimPerson
): actions.GetInsuredPersonByIdSuccessAction {
  return {
    type: actions.GET_INSURED_PERSON_BY_ID_SUCCESS,
    data: personById
  };
}

export function getInsuredPersonByIdFailure(
  error: Error | string
): actions.GetInsuredPersonByIdFailureAction {
  return {
    type: actions.GET_INSURED_PERSON_BY_ID_FAILURE,
    error
  };
}

export function getDamagedPersonById(
  id: string
): actions.GetDamagedPersonByIdAction {
  return {
    type: actions.GET_DAMAGED_PERSON_BY_ID,
    id
  };
}

export function getDamagedPersonByIdRequest(
): actions.GetDamagedPersonByIdRequestAction {
  return {
    type: actions.GET_DAMAGED_PERSON_BY_ID_REQUEST
  };
}

export function getDamagedPersonByIdReset(
): actions.GetDamagedPersonByIdResetAction {
  return {
    type: actions.GET_DAMAGED_PERSON_BY_ID_RESET
  };
}

export function getDamagedPersonByIdSuccess(
  personById: PreClaimPerson
): actions.GetDamagedPersonByIdSuccessAction {
  return {
    type: actions.GET_DAMAGED_PERSON_BY_ID_SUCCESS,
    data: personById
  };
}

export function getDamagedPersonByIdFailure(
  error: Error | string
): actions.GetDamagedPersonByIdFailureAction {
  return {
    type: actions.GET_DAMAGED_PERSON_BY_ID_FAILURE,
    error
  };
}

export function getInsuredCompanyById(
  id: string
): actions.GetInsuredCompanyByIdAction {
  return {
    type: actions.GET_INSURED_COMPANY_BY_ID,
    id
  };
}

export function getInsuredCompanyByIdRequest(
): actions.GetInsuredCompanyByIdRequestAction {
  return {
    type: actions.GET_INSURED_COMPANY_BY_ID_REQUEST
  };
}

export function getInsuredCompanyByIdReset(
): actions.GetInsuredCompanyByIdResetAction {
  return {
    type: actions.GET_INSURED_COMPANY_BY_ID_RESET
  };
}

export function getInsuredCompanyByIdSuccess(
  company: PreClaimPerson
): actions.GetInsuredCompanyByIdSuccessAction {
  return {
    type: actions.GET_INSURED_COMPANY_BY_ID_SUCCESS,
    data: company
  };
}

export function getInsuredCompanyByIdFailure(
  error: Error | string
): actions.GetInsuredCompanyByIdFailureAction {
  return {
    type: actions.GET_INSURED_COMPANY_BY_ID_FAILURE,
    error
  };
}

export function getDamagedCompanyById(
  id: string
): actions.GetDamagedCompanyByIdAction {
  return {
    type: actions.GET_DAMAGED_COMPANY_BY_ID,
    id
  };
}

export function getDamagedCompanyByIdRequest(
): actions.GetDamagedCompanyByIdRequestAction {
  return {
    type: actions.GET_DAMAGED_COMPANY_BY_ID_REQUEST
  };
}

export function getDamagedCompanyByIdReset(
): actions.GetDamagedCompanyByIdResetAction {
  return {
    type: actions.GET_DAMAGED_COMPANY_BY_ID_RESET
  };
}

export function getDamagedCompanyByIdSuccess(
  company: PreClaimPerson
): actions.GetDamagedCompanyByIdSuccessAction {
  return {
    type: actions.GET_DAMAGED_COMPANY_BY_ID_SUCCESS,
    data: company
  };
}

export function getDamagedCompanyByIdFailure(
  error: Error | string
): actions.GetDamagedCompanyByIdFailureAction {
  return {
    type: actions.GET_DAMAGED_COMPANY_BY_ID_FAILURE,
    error
  };
}

export function getVehicleByPlateNumber(
  plateNumber: string
): actions.GetVehicleByPlateNumberAction {
  return {
    type: actions.GET_VEHICLE_BY_PLATE_NUMBER,
    plateNumber
  };
}

export function getVehicleByPlateNumberRequest(
): actions.GetVehicleByPlateNumberRequestAction {
  return {
    type: actions.GET_VEHICLE_BY_PLATE_NUMBER_REQUEST
  };
}

export function getVehicleByPlateNumberReset(
): actions.GetVehicleByPlateNumberResetAction {
  return {
    type: actions.GET_VEHICLE_BY_PLATE_NUMBER_RESET
  };
}

export function getVehicleByPlateNumberSuccess(
  vehicleByChassisOrPlate: PreClaimVehicle
): actions.GetVehicleByPlateNumberSuccessAction {
  return {
    type: actions.GET_VEHICLE_BY_PLATE_NUMBER_SUCCESS,
    data: vehicleByChassisOrPlate
  };
}

export function getVehicleByPlateNumberFailure(
  error: Error | string
): actions.GetVehicleByPlateNumberFailureAction {
  return {
    type: actions.GET_VEHICLE_BY_PLATE_NUMBER_FAILURE,
    error
  };
}

export function getVehicleByChassis(
  chassis: string
): actions.GetVehicleByChassisAction {
  return {
    type: actions.GET_VEHICLE_BY_CHASSIS,
    chassis
  };
}

export function getVehicleByChassisRequest(
): actions.GetVehicleByChassisRequestAction {
  return {
    type: actions.GET_VEHICLE_BY_CHASSIS_REQUEST
  };
}

export function getVehicleByChassisReset(
): actions.GetVehicleByChassisResetAction {
  return {
    type: actions.GET_VEHICLE_BY_CHASSIS_RESET
  };
}

export function getVehicleByChassisSuccess(
  vehicleByChassisOrPlate: PreClaimVehicle
): actions.GetVehicleByChassisSuccessAction {
  return {
    type: actions.GET_VEHICLE_BY_CHASSIS_SUCCESS,
    data: vehicleByChassisOrPlate
  };
}

export function getVehicleByChassisFailure(
  error: Error | string
): actions.GetVehicleByChassisFailureAction {
  return {
    type: actions.GET_VEHICLE_BY_CHASSIS_FAILURE,
    error
  };
}

export function fetchKaskoDataFromPolicy(
  policyCode: string,
): actions.FetchKaskoDataFromPolicyAction {
  return {
    type: actions.FETCH_KASKO_DATA_FROM_POLICY,
    policyCode
  };
}

export function fetchKaskoDataFromPolicyRequest(
): actions.FetchKaskoDataFromPolicyRequestAction {
  return {
    type: actions.FETCH_KASKO_DATA_FROM_POLICY_REQUEST
  };
}

export function fetchKaskoDataFromPolicyReset(
): actions.FetchKaskoDataFromPolicyResetAction {
  return {
    type: actions.FETCH_KASKO_DATA_FROM_POLICY_RESET
  };
}

export function fetchKaskoDataFromPolicySuccess(
  kasko: PreClaim
): actions.FetchKaskoDataFromPolicySuccessAction {
  return {
    type: actions.FETCH_KASKO_DATA_FROM_POLICY_SUCCESS,
    data: kasko
  };
}

export function fetchKaskoDataFromPolicyFailure(
  error: Error | string
): actions.FetchKaskoDataFromPolicyFailureAction {
  return {
    type: actions.FETCH_KASKO_DATA_FROM_POLICY_FAILURE,
    error
  };
}

export function downloadPreviewClaimDocument(
  claimId: string,
): actions.DownloadPreviewClaimDocumentAction {
  return {
    type: actions.DOWNLOAD_PREVIEW_CLAIM_DOCUMENT,
    claimId
  };
}

export function downloadPreviewClaimDocumentRequest(
): actions.DownloadPreviewClaimDocumentRequestAction {
  return {
    type: actions.DOWNLOAD_PREVIEW_CLAIM_DOCUMENT_REQUEST
  };
}

export function downloadPreviewClaimDocumentSuccess(
): actions.DownloadPreviewClaimDocumentSuccessAction {
  return {
    type: actions.DOWNLOAD_PREVIEW_CLAIM_DOCUMENT_SUCCESS
  };
}

export function downloadPreviewClaimDocumentFailure(
  error: Error | string
): actions.DownloadPreviewClaimDocumentFailureAction {
  return {
    type: actions.DOWNLOAD_PREVIEW_CLAIM_DOCUMENT_FAILURE,
    error
  };
}