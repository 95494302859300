import { put, call, takeEvery, fork, all, select } from 'redux-saga/effects';
import moment from 'moment';
import * as healthPreClaimActionCreators from "../actionCreators/healthPreClaimActionCreators";
import * as healthPreClaimActionTypes from "../actionTypes/healthPreClaimActionTypes";
import * as healthClaimActionTypes from "../actionTypes/healthClaimActionTypes";
import {
    checkPolicy, registerHealthPreClaim, searchHealthClaim, healthClaimPreview,
    exportSearchHealthClaim, validateHealthClaimPolicy, validateHealthClaimInsured,
    fileFirstLevelAppeal, fileSecondLevelAppeal, markAppealAsRead, printFirstLevelAppeal,
    downloadHealthClaimFile, getDocumentList, uploadHealthClaimFile, printSecondLevelAppeal
} from "../services/healthPreClaimServices";
import * as generalActionCreators from "../actionCreators/generalActionCreators";
import * as nomenActionCreators from "../actionCreators/nomenActionCreators";

import { history } from '../configureStore';
import { getFileExtension } from '../../utils/healthPreClaimTypes';

function* onCheckPolicy({ policyCode }: healthPreClaimActionTypes.CheckPolicyAction): any {
    try {
        yield put(healthPreClaimActionCreators.checkPolicyRequest());
        const response = yield call(checkPolicy, policyCode);
        if (response.data.succeeded) {
            yield put(healthPreClaimActionCreators.checkPolicySuccess());
        }
        else {
            yield put(healthPreClaimActionCreators.checkPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.checkPolicyFailure(error));
        yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
    }
}

function* watchOnCheckPolicy() {
    yield takeEvery(healthPreClaimActionTypes.CHECK_POLICY, onCheckPolicy);
}

function* onRegisterHealthClaim({ healthPreClaim }: healthPreClaimActionTypes.RegisterHealthPreClaimAction): any {
    try {
        yield put(healthPreClaimActionCreators.registerHealthPreClaimRequest());

        const response = yield call(registerHealthPreClaim, createFormData(healthPreClaim));
        if (response.data.succeeded) {
            yield put(healthPreClaimActionCreators.registerHealthPreClaimSuccess(response.data.data));
            history.push('/preclaim-health-ks/success');
        }
        else {
            yield put(healthPreClaimActionCreators.registerHealthPreClaimFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.registerHealthPreClaimFailure(error));
    }
}

function* watchOnRegisterHealthClaim() {
    yield takeEvery(healthPreClaimActionTypes.REGISTER_HEALTH_PRECLAIM, onRegisterHealthClaim);
}

function createFormData(object: any, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();
    for (let property in object) {
        if (!object.hasOwnProperty(property) || !object[property]) {
            continue;
        }
        const formKey = namespace ? `${namespace}[${property}]` : property;
        if (object[property] instanceof Date) {
            formData.append(formKey, moment(object[property]).format('YYYY-MM-DD'));
        } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
            createFormData(object[property], formData, formKey);
        } else {
            formData.append(formKey, object[property]);
        }
    }
    return formData;
}

function* onSearchHealthClaim({ filter }: healthClaimActionTypes.SearchHealthClaimAction): any {
    try {
        yield put(healthPreClaimActionCreators.searchHealthClaimRequest());
        let response = yield call(searchHealthClaim, filter);
        if (response.data.succeeded) {
            yield put(healthPreClaimActionCreators.searchHealthClaimSuccess(response.data.data, response.data.count));
        }
        else {
            yield put(healthPreClaimActionCreators.searchHealthClaimFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.searchHealthClaimFailure('Ndodhi nje gabim I papritur!'));
    }
}

function* watchOnSearchHealthClaim() {
    yield takeEvery(healthClaimActionTypes.SEARCH_HEALTH_CLAIM, onSearchHealthClaim);
}

function* onhealthClaimPreview({ code, fetchDocuments }: healthClaimActionTypes.HealthClaimPreviewAction): any {
    try {
        yield put(healthPreClaimActionCreators.showHealthClaimModal());
        yield put(healthPreClaimActionCreators.healthClaimPreviewRequest());
        const response = yield call(healthClaimPreview, code);
        if (response.data.succeeded) {
            yield put(healthPreClaimActionCreators.healthClaimPreviewSuccess(response.data.data));

            if (fetchDocuments) {
                yield put(healthPreClaimActionCreators.getDocumentList(code));
            }
        }
        else {
            yield put(healthPreClaimActionCreators.healthClaimPreviewFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.healthClaimPreviewFailure(error));
    }
}

function* watchOnhealthClaimPreview() {
    yield takeEvery(healthClaimActionTypes.HEALTH_CLAIM_PREVIEW, onhealthClaimPreview)
}

function* onhealthClaimPreviewFirstLevel({ code }: healthClaimActionTypes.HealthClaimPreviewFirstLevelAction): any {
    try {
        yield put(healthPreClaimActionCreators.showHealthClaimFirstLevelModal());
        yield put(healthPreClaimActionCreators.healthClaimPreviewFirstLevelRequest());
        const response = yield call(healthClaimPreview, code);
        if (response.data.succeeded) {
            yield put(healthPreClaimActionCreators.healthClaimPreviewFirstLevelSuccess(response.data.data));
        }
        else {
            yield put(healthPreClaimActionCreators.healthClaimPreviewFirstLevelFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.healthClaimPreviewFirstLevelFailure(error));
    }
}

function* watchOnhealthClaimPreviewFirstLevel() {
    yield takeEvery(healthClaimActionTypes.HEALTH_CLAIM_PREVIEW_FIRST_LEVEL, onhealthClaimPreviewFirstLevel)
}

function* onExportSearchHealthClaim({ filter }: healthClaimActionTypes.ExportSearchHealthClaimAction): any {
    try {
        yield put(healthPreClaimActionCreators.exportSearchHealthClaimRequest());
        const response = yield call(exportSearchHealthClaim, filter);
        if (response !== null && response.status === 200 && response.data !== "" && response.data !== null) {
            yield put(healthPreClaimActionCreators.exportSearchHealthClaimSuccess());
            let url = window.URL.createObjectURL(new Blob([response.data]));
            saveAs(url, "Statusi dhe historiati i kerkesave për rimbursim.xlsx");
        }
        else {
            yield put(healthPreClaimActionCreators.exportSearchHealthClaimFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.exportSearchHealthClaimFailure(error));
        yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
    }
}

function* watchOnExportSearchHealthClaim() {
    yield takeEvery(healthClaimActionTypes.EXPORT_SEARCH_HEALTH_CLAIM, onExportSearchHealthClaim)
}

function* onValidateHealthClaimPolicy({ accidentdate, customerId, policy }: healthPreClaimActionTypes.ValidateHealthClaimPolicyAction): any {
    try {
        yield put(healthPreClaimActionCreators.validateHealthClaimPolicyRequest());
        const response = yield call(validateHealthClaimPolicy, accidentdate, customerId, policy);
        if (response.data.succeeded) {
            yield put(healthPreClaimActionCreators.validateHealthClaimPolicySuccess(response.data.data));
        }
        else {
            yield put(healthPreClaimActionCreators.validateHealthClaimPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.validateHealthClaimPolicyFailure(error));
    }
}

function* watchOnValidateHealthClaimPolicy() {
    yield takeEvery(healthPreClaimActionTypes.VALIDATE_HEALTH_CLAIM_POLICY, onValidateHealthClaimPolicy)
}

function* onValidateHealthClaimInsured({ accidentdate, customerId, insurePersonId }: healthPreClaimActionTypes.ValidateHealthClaimInsuredAction): any {
    try {
        yield put(healthPreClaimActionCreators.validateHealthClaimInsuredRequest());
        const response = yield call(validateHealthClaimInsured, accidentdate, customerId, insurePersonId);
        if (response.data.succeeded) {
            yield put(healthPreClaimActionCreators.validateHealthClaimInsuredSuccess(response.data.data));
            if (response.data.data && response.data.data.policyCode !== "")
                yield put(nomenActionCreators.getCoverCategoriesForPolicy(response.data.data.policyCode, moment(accidentdate).format('YYYY-MM-DD')));
        }
        else {
            yield put(healthPreClaimActionCreators.validateHealthClaimInsuredFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.validateHealthClaimInsuredFailure(error));
    }
}

function* watchOnValidateHealthClaimInsured() {
    yield takeEvery(healthPreClaimActionTypes.VALIDATE_HEALTH_CLAIM_INSURED, onValidateHealthClaimInsured)
}

function* onLocationChange() {
    yield put(healthPreClaimActionCreators.validateHealthClaimPolicyReset());
    yield put(healthPreClaimActionCreators.validateHealthClaimInsuredReset());
    yield put(healthPreClaimActionCreators.searchHealthClaimReset());
}

function* watchOnLocationChange() {
    yield takeEvery('@@router/LOCATION_CHANGE', onLocationChange);
}

function* onFileFirstLevelAppeal({ appeal }: healthClaimActionTypes.FileFirstLevelAppealAction): any {
    try {
        yield put(healthPreClaimActionCreators.fileFirstLevelAppealRequest());
        const response = yield call(fileFirstLevelAppeal, appeal);
        if (response.data.succeeded) {
            yield put(healthPreClaimActionCreators.fileFirstLevelAppealSuccess());
            yield put(generalActionCreators.showSuccessToast(response.data.data));
        }
        else {
            yield put(healthPreClaimActionCreators.fileFirstLevelAppealFailure(response.data.errorMessage));
            yield put(generalActionCreators.showFailureToast(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.fileFirstLevelAppealFailure(error));
        yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
    }
}

function* watchOnFileFirstLevelAppeal() {
    yield takeEvery(healthClaimActionTypes.FILE_FIRST_LEVEL_APPEAL, onFileFirstLevelAppeal);
}


function* onhealthClaimPreviewSecondLevel({ code }: healthClaimActionTypes.HealthClaimPreviewSecondLevelAction): any {
    try {
        yield put(healthPreClaimActionCreators.showHealthClaimSecondLevelModal());
        yield put(healthPreClaimActionCreators.healthClaimPreviewSecondLevelRequest());
        const response = yield call(healthClaimPreview, code);
        if (response.data.succeeded) {
            yield put(healthPreClaimActionCreators.healthClaimPreviewSecondLevelSuccess(response.data.data));
        }
        else {
            yield put(healthPreClaimActionCreators.healthClaimPreviewSecondLevelFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.healthClaimPreviewSecondLevelFailure(error));
    }
}

function* watchOnhealthClaimPreviewSecondLevel() {
    yield takeEvery(healthClaimActionTypes.HEALTH_CLAIM_PREVIEW_SECOND_LEVEL, onhealthClaimPreviewSecondLevel)
}

function* onFileSecondLevelAppeal({ appeal }: healthClaimActionTypes.FileSecondLevelAppealAction): any {
    try {
        yield put(healthPreClaimActionCreators.fileSecondLevelAppealRequest());
        const response = yield call(fileSecondLevelAppeal, appeal);
        if (response.data.succeeded) {
            yield put(healthPreClaimActionCreators.fileSecondLevelAppealSuccess());
            yield put(generalActionCreators.showSuccessToast(response.data.data));
        }
        else {
            yield put(healthPreClaimActionCreators.fileSecondLevelAppealFailure(response.data.errorMessage));
            yield put(generalActionCreators.showFailureToast(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.fileSecondLevelAppealFailure(error));
        yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
    }
}

function* watchOnFileSecondLevelAppeal() {
    yield takeEvery(healthClaimActionTypes.FILE_SECOND_LEVEL_APPEAL, onFileSecondLevelAppeal);
}

function* onReadFirstFirstLevelAppeal({ code }: healthClaimActionTypes.ReadFirstLevelAppealAction): any {
    try {
        yield put(healthPreClaimActionCreators.showReadFirstLevelAppealModal());
        yield put(healthPreClaimActionCreators.readFirstLevelAppealRequest());
        const response = yield call(healthClaimPreview, code);
        if (response.data.succeeded) {
            yield put(healthPreClaimActionCreators.readFirstLevelAppealSuccess(response.data.data));
        }
        else {
            yield put(healthPreClaimActionCreators.readFirstLevelAppealFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.readFirstLevelAppealFailure(error));
    }
}

function* watchOnReadFirstFirstLevelAppeal() {
    yield takeEvery(healthClaimActionTypes.READ_FIRST_LEVEL_APPEAL, onReadFirstFirstLevelAppeal)
}

function* onReadFirstSecondLevelAppeal({ code }: healthClaimActionTypes.ReadSecondLevelAppealAction): any {
    try {
        yield put(healthPreClaimActionCreators.showReadSecondLevelAppealModal());
        yield put(healthPreClaimActionCreators.readSecondLevelAppealRequest());
        const response = yield call(healthClaimPreview, code);
        if (response.data.succeeded) {
            yield put(healthPreClaimActionCreators.readSecondLevelAppealSuccess(response.data.data));
        }
        else {
            yield put(healthPreClaimActionCreators.readSecondLevelAppealFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.readSecondLevelAppealFailure(error));
    }
}

function* watchOnReadFirstSecondLevelAppeal() {
    yield takeEvery(healthClaimActionTypes.READ_SECOND_LEVEL_APPEAL, onReadFirstSecondLevelAppeal)
}

function* onMarkAppealAsRead({ claimId }: healthClaimActionTypes.MarkAppealAsReadAction): any {
    try {
        yield put(healthPreClaimActionCreators.markAppealAsReadRequest());
        const response = yield call(markAppealAsRead, claimId);
        if (response.data.succeeded) {
            yield put(healthPreClaimActionCreators.markAppealAsReadSuccess());
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.markAppealAsReadFailure(error));
    }
}

function* watchOnMarkAppealAsRead() {
    yield takeEvery(healthClaimActionTypes.MARK_APPEAL_AS_READ, onMarkAppealAsRead)
}

function* onPrintFirstLevelAppeal({ claimId }: healthClaimActionTypes.PrintFirstLevelAppealAction): any {
    try {
        yield put(healthPreClaimActionCreators.printFirstLevelAppealRequest());
        const response = yield call(printFirstLevelAppeal, claimId);
        if (response !== null && response.status === 200 && response.data !== "" && response.data !== null) {
            yield put(healthPreClaimActionCreators.printFirstLevelAppealSuccess());
            let url = window.URL.createObjectURL(new Blob([response.data]));
            saveAs(url, "First_Level_Appeal_" + claimId + ".pdf");
        }
        else {
            yield put(healthPreClaimActionCreators.printFirstLevelAppealFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.printFirstLevelAppealFailure(error));
        yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
    }
}

function* watchOnPrintFirstLevelAppeal() {
    yield takeEvery(healthClaimActionTypes.PRINT_FIRST_LEVEL_APPEAL, onPrintFirstLevelAppeal)
}

function* onPrintSecondLevelAppeal({ claimId }: healthClaimActionTypes.PrintSecondLevelAppealAction): any {
    try {
        yield put(healthPreClaimActionCreators.printSecondLevelAppealRequest());
        const response = yield call(printSecondLevelAppeal, claimId);
        if (response !== null && response.status === 200 && response.data !== "" && response.data !== null) {
            yield put(healthPreClaimActionCreators.printSecondLevelAppealSuccess());
            let url = window.URL.createObjectURL(new Blob([response.data]));
            saveAs(url, "Second_Level_Appeal_" + claimId + ".pdf");
        }
        else {
            yield put(healthPreClaimActionCreators.printSecondLevelAppealFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.printSecondLevelAppealFailure(error));
        yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
    }
}

function* watchOnPrintSecondLevelAppeal() {
    yield takeEvery(healthClaimActionTypes.PRINT_SECOND_LEVEL_APPEAL, onPrintSecondLevelAppeal)
}

function* onDownloadHealthClaimFile({ fileId, name }: healthClaimActionTypes.DownloadHealthClaimFileAction): any {
    try {
        yield put(healthPreClaimActionCreators.downloadHealthClaimFileRequest());
        const response = yield call(downloadHealthClaimFile, fileId);
        if (response !== null && response.status === 200 && response.data !== "" && response.data !== null) {
            yield put(healthPreClaimActionCreators.downloadHealthClaimFileSuccess());
            let url = window.URL.createObjectURL(new Blob([response.data]));
            let extension = getFileExtension(response.data.type);

            saveAs(url, name + extension);
        }
        else {
            yield put(healthPreClaimActionCreators.downloadHealthClaimFileFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.downloadHealthClaimFileFailure(error));
        yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
    }
}

function* watchOnDownloadHealthClaimFile() {
    yield takeEvery(healthClaimActionTypes.DOWNLOAD_HEALTH_CLAIM_FILE, onDownloadHealthClaimFile)
}

function* onGetDocumentList({ claimId }: healthClaimActionTypes.GetDocumentListAction): any {
    try {
        yield put(healthPreClaimActionCreators.getDocumentListRequest());
        const response = yield call(getDocumentList, claimId);

        if (response !== null && response.status === 200) {
            yield put(healthPreClaimActionCreators.getDocumentListSuccess(response.data));
        }
        else {
            yield put(healthPreClaimActionCreators.getDocumentListFailure("Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!"));
            yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.getDocumentListFailure("Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!"));
    }
}

function* watchOnGetDocumentList() {
    yield takeEvery(healthClaimActionTypes.GET_DOCUMENT_LIST, onGetDocumentList);
}

function* onUploadHealthClaimFile({ data }: healthClaimActionTypes.UploadHealthClaimFileAction): any {
    try {
        yield put(healthPreClaimActionCreators.uploadHealthClaimFileRequest());
        const response = yield call(uploadHealthClaimFile, createFormData(data));
        if (response.data.succeeded) {
            yield put(healthPreClaimActionCreators.uploadHealthClaimFileSuccess(response.data.data));
            yield put(healthPreClaimActionCreators.getDocumentList(data.claimId));
            yield put(generalActionCreators.showSuccessToast("Dokumenti i pretendimit shëndetësor u ngarkua me sukses"));
        }
        else {
            yield put(healthPreClaimActionCreators.uploadHealthClaimFileFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, response.data.action));
        }
    } catch (error: any) {
        yield put(healthPreClaimActionCreators.uploadHealthClaimFileFailure(error));
    }
}

function* watchOnUploadHealthClaimFile() {
    yield takeEvery(healthClaimActionTypes.UPLOAD_HEALTH_CLAIM_FILE, onUploadHealthClaimFile);
}

export default function* healthClaimSaga() {
    yield all([
        fork(watchOnCheckPolicy),
        fork(watchOnRegisterHealthClaim),
        fork(watchOnSearchHealthClaim),
        fork(watchOnhealthClaimPreview),
        fork(watchOnExportSearchHealthClaim),
        fork(watchOnValidateHealthClaimPolicy),
        fork(watchOnValidateHealthClaimInsured),
        fork(watchOnLocationChange),
        fork(watchOnhealthClaimPreviewFirstLevel),
        fork(watchOnFileFirstLevelAppeal),
        fork(watchOnhealthClaimPreviewSecondLevel),
        fork(watchOnFileSecondLevelAppeal),
        fork(watchOnReadFirstFirstLevelAppeal),
        fork(watchOnReadFirstSecondLevelAppeal),
        fork(watchOnMarkAppealAsRead),
        fork(watchOnPrintSecondLevelAppeal),
        fork(watchOnPrintFirstLevelAppeal),
        fork(watchOnDownloadHealthClaimFile),
        fork(watchOnGetDocumentList),
        fork(watchOnUploadHealthClaimFile)
    ]);
}