import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { DropzoneArea } from 'material-ui-dropzone';
import { AppState } from '../../../../redux/reducers/rootReducer';
import * as actionTypes from '../../../../redux/actionTypes/healthPreClaimActionTypes';
import { Loading } from '../../../Layout/Loading';
import Record from './Record';
import InputFieldMain from '../../../Custom/InputFieldMain';
import SelectField from '../../../Custom/SelectField';
import DatePickerFieldMain from '../../../Custom/DatePickerFieldMain';
import { Nomen } from '../../../../utils';
import { makeStyles } from '@material-ui/core';

type Props = {
    data?: any | null;
    index: number;
    enableDelete: boolean;
    setRecords: (index: any) => void;
    removeRecord: (invoiceIndex: any, index: any) => void;
    removeInvoice: (invoiceIndex: any) => void;
    dropkey: number;
}

const Invoice = (props: Props) => {
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.REGISTER_HEALTH_PRECLAIM]);
    const countries = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Countries);
    const { values, setFieldValue }: any = useFormikContext();

    const manageRecords = () => {
        props.setRecords(props.index);
    }

    const removeRecord = (index: number) => {
        if (index !== -1) {
            props.removeRecord(props.index, index);
        }
    }

    const handleClick: React.MouseEventHandler<
        HTMLButtonElement | HTMLAnchorElement
    > = e => {
        if (props.removeInvoice && props.index >= 0) props.removeInvoice(props.index);
    }

    const handleSaveFiscal = (files: any) => {
        setFieldValue(FiscalFiles, files);
    }

    const handleSaveMedical = (files: any) => {
        setFieldValue(MedicalFiles, files);
    }

    const InvoiceNr: string = `invoices[${props.index}].invoicenr`;
    const InvoicedAmountV: string = `invoices[${props.index}].invoicedAmountV`;
    const HospitalName: string = `invoices[${props.index}].hospitalName`;
    const InvoiceFiscalNr: string = `invoices[${props.index}].invoiceFiscalnr`;
    const HospitalId: string = `invoices[${props.index}].hospitalId`;
    const InvoiceDate: string = `invoices[${props.index}].invoicedate`;
    const CountryEvent: string = `invoices[${props.index}].countryEvent`;
    const FiscalFiles: string = `invoices[${props.index}].fiscalFiles`;
    const MedicalFiles: string = `invoices[${props.index}].medicalFiles`;

    const useStyles = makeStyles(() => ({
        dropZone: {
            minHeight: '60px',
        },
        previewContainer: {
            container: 'true',
            width: '100%',
            height: '100%',
        },
        preview: {
            height: '100%',
            xs: '12',
        }
    }));

    const classes = useStyles();

    return (<>
        {isLoading
            ? <Loading />
            :
            <>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    {props.index !== -1 && !!props.removeInvoice && props.enableDelete
                        && <div className="row">
                            <div className="col-xl-9"></div>
                            <div className="col-xl-3">
                                <a>
                                    <i className="flaticon2-rubbish-bin-delete-button text-muted icon-1x pull-right"
                                        onClick={handleClick} title="Hiq"></i>
                                </a></div></div>}
                    <div className="row">
                        <div className="col-xl-3">
                            <InputFieldMain type="text" name={InvoiceNr} newvalue={props.data?.invoicenr || ''} showInfo imageName="Numri i kuponit.png" label="Numri i kuponit" placeholder="Numri i kuponit" />
                        </div>
                        <div className="col-xl-3">
                            <InputFieldMain type="text" name={InvoicedAmountV} newvalue={props.data?.invoicedAmountV || ''} showInfo imageName="Vlera e kuponit fiskal (Totali në EURO.png" label="Shuma (€)" placeholder="Shuma e faturës" />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name={HospitalName} newvalue={props.data?.hospitalName || ''} label="Emri i spitalit" placeholder="Emri i spitalit" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name={InvoiceFiscalNr} newvalue={props.data?.invoiceFiscalnr || ''} showInfo imageName="Numri i kuponit Fiskal.png" label="Numri i kuponit fiskal" placeholder="Numri i kuponit fiskal" />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name={HospitalId} newvalue={props.data?.hospitalId || ''} showInfo imageName="NUI-Numri Fiskal.png" label="Numri Fiskal/Nipt i spitalit" placeholder="Numri Fiskal/Nipt i spitalit" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 addSpace">
                            <DatePickerFieldMain name={InvoiceDate} newvalue={(props.data?.invoicedate) || ''} showInfo imageName="Data e kuponit Fiskal.png" type="text" placeholder="Data e faturës" />
                        </div>
                        <div className="col-xl-6">
                            <SelectField name={CountryEvent} newvalue={props.data?.countryEvent || ''} label="Vendi (Shteti ku eshte mare sherbimi mjeksor)" items={countries} />
                        </div>
                    </div>
                    <br />
                    <br />
                    <Record key={'00'} index={0} data={values.invoices[props.index].records[0]} enableDelete={values.invoices[props.index].records.length > 1} invoiceIndex={props.index} setRecords={manageRecords} removeRecord={removeRecord} />
                    {values.invoices[props.index].records.slice(1).map((record: any, index: number) => (
                        <Record key={index + 1} data={record} index={index + 1} invoiceIndex={props.index} enableDelete={values.invoices[props.index].records.length > 1} setRecords={manageRecords} removeRecord={removeRecord} />
                    ))}
                    <div key={props.index > 0 ? props.dropkey + props.index * 100 : props.dropkey} className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                        <div className="mb-10 font-weight-bold text-dark">Dokumente <a href="https://sigal-ks.com/wp-content/uploads/2022/08/DOKUMENTACIONI-I-NEVOJSHEM-ME-RASTIN-E-KERKESES-PER-RIMBURSIM-SH.P.-ONLINE.pdf" target="_blank">   (Dokumentet qe kërkohen)</a></div>
                        <div>
                            <DropzoneArea
                                dropzoneClass={classes.dropZone}
                                dropzoneText='Kuponi fiskal'
                                onChange={handleSaveFiscal}
                                onDelete={handleSaveFiscal}
                                acceptedFiles={['application/pdf', 'application/msword',
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                    'application/vnd.rar', 'text/plain', '	application/zip',
                                    'application/x-7z-compressed', 'image/jpeg', 'image/png', 'image/jpeg']}
                                filesLimit={20}
                                maxFileSize={10485760}
                                showPreviews={true}
                                showPreviewsInDropzone={false}
                                showFileNamesInPreview={true}
                                showFileNames={true}
                                initialFiles={props.data?.fiscalFiles || []}
                                previewGridClasses={{
                                    container: classes.previewContainer,
                                    item: classes.preview
                                }}
                                getPreviewIcon={(file: any, classes: any) => {
                                    return (
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M14 0C16.7614 0 19 2.23858 19 5V17C19 20.866 15.866 24 12 24C8.13401 24 5 20.866 5 17V9H7V17C7 19.7614 9.23858 22 12 22C14.7614 22 17 19.7614 17 17V5C17 3.34315 15.6569 2 14 2C12.3431 2 11 3.34315 11 5V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V6H15V17C15 18.6569 13.6569 20 12 20C10.3431 20 9 18.6569 9 17V5C9 2.23858 11.2386 0 14 0Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    );
                                }}
                            />
                            <hr />
                        </div>
                        <div>
                            <DropzoneArea
                                dropzoneClass={classes.dropZone}
                                dropzoneText='Raportet mjeksore'
                                onChange={handleSaveMedical}
                                onDelete={handleSaveMedical}
                                acceptedFiles={['application/pdf', 'application/msword',
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                    'application/vnd.rar', 'text/plain', '	application/zip',
                                    'application/x-7z-compressed', 'image/jpeg', 'image/png', 'image/jpeg']}
                                filesLimit={20}
                                maxFileSize={10485760}
                                showPreviews={true}
                                showPreviewsInDropzone={false}
                                showFileNamesInPreview={true}
                                showFileNames={true}
                                initialFiles={props.data?.medicalFiles || []}
                                previewGridClasses={{
                                    container: classes.previewContainer,
                                    item: classes.preview
                                }}
                                getPreviewIcon={(file: any, classes: any) => {
                                    return (
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M14 0C16.7614 0 19 2.23858 19 5V17C19 20.866 15.866 24 12 24C8.13401 24 5 20.866 5 17V9H7V17C7 19.7614 9.23858 22 12 22C14.7614 22 17 19.7614 17 17V5C17 3.34315 15.6569 2 14 2C12.3431 2 11 3.34315 11 5V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V6H15V17C15 18.6569 13.6569 20 12 20C10.3431 20 9 18.6569 9 17V5C9 2.23858 11.2386 0 14 0Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    );
                                }}
                            />
                            <hr />
                        </div>
                    </div>
                </div>
            </>

        }
    </>);
}

export default connect()(Invoice);
