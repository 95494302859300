import React from "react";
import { useSelector } from "react-redux";
import { PreClaimRegisterData } from '../../../../utils/claimTypes';
import { AppState } from '../../../../redux/reducers/rootReducer';

const SuccessfulHealthRegistrationKS = () => {
    const preClaimRegisterData = useSelector<AppState, PreClaimRegisterData | null>((state: any) => state.healthPreClaim.healthPreClaimRegisterData);

    return (
        <div>
            <div className="card card-custom overflow-hidden">
                <div className="card-body p-0">
                    <div className="row justify-content-center bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0" style={{ backgroundImage: "url(../media/bg/bg-8.jpg)" }}>
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                                <h1 className="display-4 text-white font-weight-boldest mb-10">
                                    Keni regjistruar me sukses parakërkesën e dëmit.
                                    <br />
                                    Pas kontrollimit dhe evidentimit nga SIGAL Uniqa Group Austria Sh.a. Kosovë,  kërkesa do të regjistrohet ne programin e dëmeve.
                                </h1>
                            </div>
                            <div className="border-bottom w-100 opacity-20"></div>

                            <div className="d-flex align-items-center">
                                <div className="display-4 text-white">
                                    Ju lutëm, pas regjistrimit të dëmit ju mund ta monitoroni kërkesën tuaj tek rubrika "Statusi dhe historiati i kërkesave për rimbursim"
                                </div>
                            </div>

                            <div className="border-bottom w-100 opacity-20"></div>
                            {preClaimRegisterData !== null && !!preClaimRegisterData &&
                                <>
                                    <div className="d-flex align-items-center">
                                        <br />
                                        <div className="display-4 text-white">
                                            Numri i kërkesës:  <b>{preClaimRegisterData.preClaimId}</b>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuccessfulHealthRegistrationKS;