import { MotorInsQuotation, BookingData, MotorPolicy, ThPolicy, ThInsQuotation } from './../../utils/policyTypes';
import ApiClient from '../../infrastructure/ApiClient';

export async function getAmfTplQuotation(
  customerId: string,
  platenumber: string,
  chassis: string,
  isCustomerVehicleOwner: boolean
): Promise<MotorInsQuotation> {
  return await ApiClient.get('/api/policy/GetAMFTPLQuotation', { customerId, platenumber, chassis, isCustomerVehicleOwner });
}

export async function getTplQuotation(
  customerId: string,
  tplVehicleCategoryClass: string,
  prodyear: number,
  power: number
): Promise<MotorInsQuotation> {
  return await ApiClient.get('/api/policy/GetTPLQuotation', { customerId, tplVehicleCategoryClass, prodyear, power });
}

export async function bookTplPolicy(
  policy: MotorPolicy
): Promise<BookingData> {
  return await ApiClient.post('/api/policy/BookTPLPolicy', policy);
}

export async function getAmfGcQuotation(
  customerId: string,
  platenumber: string,
  chassis: string,
  insuredPeriod: string,
  isCustomerVehicleOwner: boolean
): Promise<MotorInsQuotation> {
  return await ApiClient.get('/api/policy/GetAMFGCQuotation', { customerId, platenumber, chassis, insuredPeriod, isCustomerVehicleOwner });
}

export async function getGcQuotation(
  customerId: string,
  gcVehicleCategoryClass: string,
  prodyear: number,
  power: number,
  insuredPeriod: string
): Promise<MotorInsQuotation> {
  return await ApiClient.get('/api/policy/GetGCQuotation', { customerId, gcVehicleCategoryClass, prodyear, power, insuredPeriod });
}

export async function bookGcPolicy(
  policy: MotorPolicy
): Promise<BookingData> {
  return await ApiClient.post('/api/policy/BookGCPolicy', policy);
}

export async function getBQuotation(
  customerId: string,
  bVehicleCategoryClass: string,
  prodyear: number,
  power: number,
  insuredPeriod: string
): Promise<MotorInsQuotation> {
  return await ApiClient.get('/api/policy/GetBQuotation', { customerId, bVehicleCategoryClass, prodyear, power, insuredPeriod });
}

export async function bookBPolicy(
  policy: MotorPolicy
): Promise<BookingData> {
  return await ApiClient.post('/api/policy/BookBPolicy', policy);
}

export async function prepareBKTPayment(
  bankOrderId: string,
  policyId: string,
  operatorId: string,
  total: number,
  isLek: boolean,
  policyType: string,
  policySubtype: string
): Promise<BookingData> {
  return await ApiClient.get('/api/policy/PrepareBKTPayment', { bankOrderId, policyId, operatorId, total, isLek, policyType, policySubtype });
}

export async function prepareUPCPayment(
  bankOrderId: string,
  policyId: string,
  customerId: string,
  total: number,
  policyType: string,
  policySubtype: string
): Promise<BookingData> {
  return await ApiClient.get('/api/policy/PrepareUPCPayment', { bankOrderId, policyId, customerId, total, policyType, policySubtype });
}

export async function createPaypalPayment(
  bankOrderId: string,
  policyId: string,
  operatorId: string,
  total: number,
  isLek: boolean,
  policyType: string,
  policySubtype: string
): Promise<any> {
  return await ApiClient.get('/api/policy/PreparePaypalPayment', { bankOrderId, policyId, operatorId, total, isLek, policyType, policySubtype });
}

export async function getThQuotation(
  formData: FormData
): Promise<ThInsQuotation> {
  return await ApiClient.post('/api/th/GetTHQuotation', formData);
}

export async function bookThPolicy(
  policy: FormData
): Promise<BookingData> {
  return await ApiClient.post('/api/th/BookTHPolicy', policy);
}

export async function getPhQuotation(
  formData: FormData
): Promise<ThInsQuotation> {
  return await ApiClient.post('/api/ph/GetPHQuotation', formData);
}

export async function bookPhPolicy(
  policy: FormData
): Promise<BookingData> {
  return await ApiClient.post('/api/ph/BookPHPolicy', policy);
}

export async function getPiQuotation(
  formData: FormData
): Promise<ThInsQuotation> {
  return await ApiClient.post('/api/pi/GetPIQuotation', formData);
}

export async function bookPiPolicy(
  policy: FormData
): Promise<BookingData> {
  return await ApiClient.post('/api/pi/BookPIPolicy', policy);
}

export async function getAifQuotation(
  formData: FormData
): Promise<ThInsQuotation> {
  return await ApiClient.post('/api/aif/GetAIFQuotation', formData);
}

export async function bookAifPolicy(
  policy: FormData
): Promise<BookingData> {
  return await ApiClient.post('/api/aif/BookAIFPolicy', policy);
}

export async function getKaskoQuotation(
  formData: FormData
): Promise<ThInsQuotation> {
  return await ApiClient.post('/api/kasko/GetKaskoQuotation', formData);
}

export async function bookKaskoPolicy(
  policy: FormData
): Promise<BookingData> {
  return await ApiClient.post('/api/kasko/BookKaskoPolicy', policy);
}

export async function getCliQuotation(
  formData: FormData
): Promise<ThInsQuotation> {
  return await ApiClient.post('/api/cli/GetCLIQuotation', formData);
}

export async function bookCliPolicy(
  policy: FormData
): Promise<BookingData> {
  return await ApiClient.post('/api/cli/BookCLIPolicy', policy);
}

export async function getTLIQuotation(
  formData: FormData
): Promise<ThInsQuotation> {
  return await ApiClient.post('/api/tli/GetTLIQuotation', formData);
}

export async function bookTLIPolicy(
  policy: FormData
): Promise<BookingData> {
  return await ApiClient.post('/api/tli/BookTLIPolicy', policy);
}
