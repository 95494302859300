import { ConfirmData, ThPolicy } from './../../utils/policyTypes';
import { PolicyGridItem, Filter, PolicyPreview } from './../../utils/generalTypes';
export const SHOW_MODAL = "generalActionTypes/SHOW_MODAL";
export interface ShowModalAction {
  type: typeof SHOW_MODAL;
  header: string;
  text: string;
  next: string;
  renderAsHtml?: boolean;
}

export const HIDE_MODAL = "generalActionTypes/HIDE_MODAL";
export interface HideModalAction {
  type: typeof HIDE_MODAL;
}

export const SHOW_SUCCESS_TOAST = "generalActionTypes/SHOW_SUCCESS_TOAST";
export interface ShowSuccessToastAction {
  type: typeof SHOW_SUCCESS_TOAST;
  text: string;
}

export const SHOW_SUCCESS_TOAST_RESET = "generalActionTypes/SHOW_SUCCESS_TOAST_RESET";
export interface ShowSuccessToastResetAction {
  type: typeof SHOW_SUCCESS_TOAST_RESET;
}

export const SHOW_FAILURE_TOAST = "generalActionTypes/SHOW_FAILURE_TOAST";
export interface ShowFailureToastAction {
  type: typeof SHOW_FAILURE_TOAST;
  text: string;
}

export const SHOW_FAILURE_TOAST_RESET = "generalActionTypes/SHOW_FAILURE_TOAST_RESET";
export interface ShowFailureToastResetAction {
  type: typeof SHOW_FAILURE_TOAST_RESET;
}
export const SEARCH_POLICY = "generalActionTypes/SEARCH_POLICY";
export interface SearchPolicyAction {
  type: typeof SEARCH_POLICY;
  filter: Filter;
}

export const SEARCH_POLICY_REQUEST = "generalActionTypes/SEARCH_POLICY_REQUEST";
export interface SearchPolicyRequestAction {
  type: typeof SEARCH_POLICY_REQUEST;
}

export const SEARCH_POLICY_SUCCESS = "generalActionTypes/SEARCH_POLICY_SUCCESS";
export interface SearchPolicySuccessAction {
  type: typeof SEARCH_POLICY_SUCCESS;
  data: PolicyGridItem[];
  count: number;
}

export const SEARCH_POLICY_RESET = "generalActionTypes/SEARCH_POLICY_RESET";
export interface SearchPolicyResetAction {
  type: typeof SEARCH_POLICY_RESET;
}

export const SEARCH_POLICY_FAILURE = "generalActionTypes/SEARCH_POLICY_FAILURE";
export interface SearchPolicyFailureAction {
  type: typeof SEARCH_POLICY_FAILURE;
  error: Error | string;
}

export const POLICY_PREVIEW = "generalActionTypes/POLICY_PREVIEW";
export interface PolicyPreviewAction {
  type: typeof POLICY_PREVIEW;
  policyId: string;
}

export const POLICY_PREVIEW_REQUEST = "generalActionTypes/POLICY_PREVIEW_REQUEST";
export interface PolicyPreviewRequestAction {
  type: typeof POLICY_PREVIEW_REQUEST;
}

export const POLICY_PREVIEW_SUCCESS = "generalActionTypes/POLICY_PREVIEW_SUCCESS";
export interface PolicyPreviewSuccessAction {
  type: typeof POLICY_PREVIEW_SUCCESS;
  data: PolicyPreview;
}

export const POLICY_PREVIEW_FAILURE = "generalActionTypes/POLICY_PREVIEW_FAILURE";
export interface PolicyPreviewFailureAction {
  type: typeof POLICY_PREVIEW_FAILURE;
  error: Error | string;
}

export const POLICY_PREVIEW_RESET = "generalActionTypes/POLICY_PREVIEW_RESET";
export interface PolicyPreviewResetAction {
  type: typeof POLICY_PREVIEW_RESET;
}

export const CONFIRM_POLICY = "generalActionTypes/CONFIRM_POLICY";
export interface ConfirmPolicyAction {
  type: typeof CONFIRM_POLICY;
  confirmData: ConfirmData;
}

export const CONFIRM_POLICY_REQUEST = "generalActionTypes/CONFIRM_POLICY_REQUEST";
export interface ConfirmPolicyRequestAction {
  type: typeof CONFIRM_POLICY_REQUEST;
}

export const CONFIRM_POLICY_SUCCESS = "generalActionTypes/CONFIRM_POLICY_SUCCESS";
export interface ConfirmPolicySuccessAction {
  type: typeof CONFIRM_POLICY_SUCCESS;
  data: any;
}

export const CONFIRM_POLICY_FAILURE = "generalActionTypes/CONFIRM_POLICY_FAILURE";
export interface ConfirmPolicyFailureAction {
  type: typeof CONFIRM_POLICY_FAILURE;
  error: Error | string;
}

export const CONFIRM_POLICY_RESET = "generalActionTypes/CONFIRM_POLICY_RESET";
export interface ConfirmPolicyResetAction {
  type: typeof CONFIRM_POLICY_RESET;
}

export const SHOW_POLICY_MODAL = "generalActionTypes/SHOW_POLICY_MODAL";
export interface ShowPolicyModalAction {
  type: typeof SHOW_POLICY_MODAL;
}


export const GET_PAYMENT_RESPONSE = "generalActionTypes/GET_PAYMENT_RESPONSE";
export interface GetPaymentResponseAction {
  type: typeof GET_PAYMENT_RESPONSE;
  bankOrderId: string;
}

export const GET_PAYMENT_RESPONSE_REQUEST = "generalActionTypes/GET_PAYMENT_RESPONSE_REQUEST";
export interface GetPaymentResponseRequestAction {
  type: typeof GET_PAYMENT_RESPONSE_REQUEST;
}

export const GET_PAYMENT_RESPONSE_SUCCESS = "generalActionTypes/GET_PAYMENT_RESPONSE_SUCCESS";
export interface GetPaymentResponseSuccessAction {
  type: typeof GET_PAYMENT_RESPONSE_SUCCESS;
  data: string;
}

export const GET_PAYMENT_RESPONSE_FAILURE = "generalActionTypes/GET_PAYMENT_RESPONSE_FAILURE";
export interface GetPaymentResponseFailureAction {
  type: typeof GET_PAYMENT_RESPONSE_FAILURE;
  error: Error | string;
}

export const GET_PAYMENT_RESPONSE_RESET = "generalActionTypes/GET_PAYMENT_RESPONSE_RESET";
export interface GetPaymentResponseResetAction {
  type: typeof GET_PAYMENT_RESPONSE_RESET;
}

export const BOOKING_DATA_RESET = "generalActionTypes/BOOKING_DATA_RESET";
export interface BookingDataResetAction {
  type: typeof BOOKING_DATA_RESET;
}

export const CHECK_FVALID = "generalActionTypes/CHECK_FVALID";
export interface CheckFValidAction {
  type: typeof CHECK_FVALID;
  customerId: string;
  typePolicy: string;
  subtype: string;
  fvalid: string;
  plateNumber: string;
  chassis: string;
  categoryClassTypeVehicle: string;
  prodYear: number | null;
  power: number | null;
  insuredPeriod: string;
  thPolicy: ThPolicy | null;
  isCustomerVehicleOwner: boolean;
}

export const CHECK_FVALID_REQUEST = "generalActionTypes/CHECK_FVALID_REQUEST";
export interface CheckFValidRequestAction {
  type: typeof CHECK_FVALID_REQUEST;
}

export const CHECK_FVALID_SUCCESS = "generalActionTypes/CHECK_FVALID_SUCCESS";
export interface CheckFValidSuccessAction {
  type: typeof CHECK_FVALID_SUCCESS;
  data: boolean;
}

export const CHECK_FVALID_FAILURE = "generalActionTypes/CHECK_FVALID_FAILURE";
export interface CheckFValidFailureAction {
  type: typeof CHECK_FVALID_FAILURE;
  error: Error | string;
}

export const CHECK_FVALID_RESET = "generalActionTypes/CHECK_FVALID_RESET";
export interface CheckFValidResetAction {
  type: typeof CHECK_FVALID_RESET;
}


export const DOWNLOAD_POLICY = "generalActionTypes/DOWNLOAD_POLICY";
export interface DownloadPolicyAction {
  type: typeof DOWNLOAD_POLICY;
  policyId: string;
}

export const DOWNLOAD_POLICY_REQUEST = "generalActionTypes/DOWNLOAD_POLICY_REQUEST";
export interface DownloadPolicyRequestAction {
  type: typeof DOWNLOAD_POLICY_REQUEST;
}

export const DOWNLOAD_POLICY_SUCCESS = "generalActionTypes/DOWNLOAD_POLICY_SUCCESS";
export interface DownloadPolicySuccessAction {
  type: typeof DOWNLOAD_POLICY_SUCCESS;
}

export const DOWNLOAD_POLICY_FAILURE = "generalActionTypes/DOWNLOAD_POLICY_FAILURE";
export interface DownloadPolicyFailureAction {
  type: typeof DOWNLOAD_POLICY_FAILURE;
  error: Error | string;
}

export const EXPORT_SEARCH_POLICY = "generalActionTypes/EXPORT_SEARCH_POLICY";
export interface ExportSearchPolicyAction {
  type: typeof EXPORT_SEARCH_POLICY;
  filter: Filter;
}

export const EXPORT_SEARCH_POLICY_REQUEST = "generalActionTypes/EXPORT_SEARCH_POLICY_REQUEST";
export interface ExportSearchPolicyRequestAction {
  type: typeof EXPORT_SEARCH_POLICY_REQUEST;
}

export const EXPORT_SEARCH_POLICY_SUCCESS = "generalActionTypes/EXPORT_SEARCH_POLICY_SUCCESS";
export interface ExportSearchPolicySuccessAction {
  type: typeof EXPORT_SEARCH_POLICY_SUCCESS;
}

export const EXPORT_SEARCH_POLICY_RESET = "generalActionTypes/EXPORT_SEARCH_POLICY_RESET";
export interface ExportSearchPolicyResetAction {
  type: typeof EXPORT_SEARCH_POLICY_RESET;
}

export const EXPORT_SEARCH_POLICY_FAILURE = "generalActionTypes/EXPORT_SEARCH_POLICY_FAILURE";
export interface ExportSearchPolicyFailureAction {
  type: typeof EXPORT_SEARCH_POLICY_FAILURE;
  error: Error | string;
}

export const SHOW_LOGIN_MODAL = "generalActionTypes/SHOW_LOGIN_MODAL";
export interface ShowLoginModalAction {
  type: typeof SHOW_LOGIN_MODAL;
  email: string
}

export const SHOW_LOGIN_MODAL_RESET = "generalActionTypes/SHOW_LOGIN_MODAL_RESET";
export interface ShowLoginModalResetAction {
  type: typeof SHOW_LOGIN_MODAL_RESET;
}

export const SHOW_REGISTRATION_MODAL = "generalActionTypes/SHOW_REGISTRATION_MODAL";
export interface ShowRegistrationModalAction {
  type: typeof SHOW_REGISTRATION_MODAL;
  insuredPerson: any
}

export const SHOW_REGISTRATION_MODAL_RESET = "generalActionTypes/SHOW_REGISTRATION_MODAL_RESET";
export interface ShowRegistrationModalResetAction {
  type: typeof SHOW_REGISTRATION_MODAL_RESET;
}

export const SHOW_VERIFICATION_MODAL = "generalActionTypes/SHOW_VERIFICATION_MODAL";
export interface ShowVerificationModalAction {
  type: typeof SHOW_VERIFICATION_MODAL;
}

export const SHOW_VERIFICATION_MODAL_RESET = "generalActionTypes/SHOW_VERIFICATION_MODAL_RESET";
export interface ShowVerificationModalResetAction {
  type: typeof SHOW_VERIFICATION_MODAL_RESET;
}

export const GET_APP_INFO = "generalActionTypes/GET_APP_INFO";
export interface GetAppInfoAction {
  type: typeof GET_APP_INFO;
}

export const GET_APP_INFO_REQUEST = "generalActionTypes/GET_APP_INFO_REQUEST";
export interface GetAppInfoRequestAction {
  type: typeof GET_APP_INFO_REQUEST;
}

export const GET_APP_INFO_SUCCESS = "generalActionTypes/GET_APP_INFO_SUCCESS";
export interface GetAppInfoSuccessAction {
  type: typeof GET_APP_INFO_SUCCESS;
  data: any;
}

export const CONTINUE_WITHOUT_ACCOUNT = "generalActionTypes/CONTINUE_WITHOUT_ACCOUNT";
export interface ContinueWithoutAccountAction {
  type: typeof CONTINUE_WITHOUT_ACCOUNT;
}

export const CONTINUE_WITHOUT_ACCOUNT_RESET = "generalActionTypes/CONTINUE_WITHOUT_ACCOUNT_RESET";
export interface ContinueWithoutAccountResetAction {
  type: typeof CONTINUE_WITHOUT_ACCOUNT_RESET;
}

export const SHOW_CREATE_ACCOUNT_QUESTION_MODAL = "generalActionTypes/SHOW_CREATE_ACCOUNT_QUESTION_MODAL";
export interface ShowCreateAccountQuestionModalAction {
  type: typeof SHOW_CREATE_ACCOUNT_QUESTION_MODAL;
  insuredPerson: any
}

export const SHOW_CREATE_ACCOUNT_QUESTION_MODAL_RESET = "generalActionTypes/SHOW_CREATE_ACCOUNT_QUESTION_MODAL_RESET";
export interface ShowCreateAccountQuestionModalResetAction {
  type: typeof SHOW_CREATE_ACCOUNT_QUESTION_MODAL_RESET;
}

export type GeneralAction =
  | ShowModalAction
  | HideModalAction
  | SearchPolicyAction
  | SearchPolicyRequestAction
  | SearchPolicySuccessAction
  | SearchPolicyResetAction
  | SearchPolicyFailureAction
  | PolicyPreviewAction
  | PolicyPreviewRequestAction
  | PolicyPreviewSuccessAction
  | PolicyPreviewFailureAction
  | PolicyPreviewResetAction
  | ShowPolicyModalAction
  | ConfirmPolicyAction
  | ConfirmPolicyRequestAction
  | ConfirmPolicySuccessAction
  | ConfirmPolicyFailureAction
  | ConfirmPolicyResetAction
  | GetPaymentResponseAction
  | GetPaymentResponseRequestAction
  | GetPaymentResponseSuccessAction
  | GetPaymentResponseFailureAction
  | GetPaymentResponseResetAction
  | BookingDataResetAction
  | CheckFValidAction
  | CheckFValidRequestAction
  | CheckFValidSuccessAction
  | CheckFValidFailureAction
  | CheckFValidResetAction
  | DownloadPolicyAction
  | DownloadPolicyRequestAction
  | DownloadPolicySuccessAction
  | DownloadPolicyFailureAction
  | ExportSearchPolicyAction
  | ExportSearchPolicyRequestAction
  | ExportSearchPolicySuccessAction
  | ExportSearchPolicyFailureAction
  | ExportSearchPolicyResetAction
  | ShowSuccessToastAction
  | ShowSuccessToastResetAction
  | ShowFailureToastAction
  | ShowFailureToastResetAction
  | ShowLoginModalAction
  | ShowLoginModalResetAction
  | ShowRegistrationModalAction
  | ShowRegistrationModalResetAction
  | ShowVerificationModalAction
  | ShowVerificationModalResetAction
  | GetAppInfoAction
  | GetAppInfoRequestAction
  | GetAppInfoSuccessAction
  | ContinueWithoutAccountAction
  | ContinueWithoutAccountResetAction
  | ShowCreateAccountQuestionModalAction
  | ShowCreateAccountQuestionModalResetAction
  ;

