import React, { FunctionComponent } from 'react';
import { useField, useFormikContext } from 'formik';
import { IconButton, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { toAbsoluteUrl } from '../../_metronic/_helpers';

const getFieldCSSClasses = (touched: boolean, error: string | undefined) => {
    const classes = ["form-control"];
    if (touched && error) {
        classes.push("is-invalid");
    }

    if (touched && !error) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

interface Props {
    name: string;
    type: string;
    placeholder: string;
    label: string;
    newvalue?: string;
    disabled?: boolean;
    blur?: (value: any) => void;
    showInfo?: boolean;
    imageName?: string;
    inline?: boolean;
    maxLength?: number | undefined;
    capitalized?: boolean;
}

const InputFieldMain: FunctionComponent<Props> = (props) => {
    const [
        field,
        { error, touched }
    ] = useField({
        name: props.name,
        type: props.type
    });

    const {
        setFieldValue,
    } = useFormikContext();

    React.useEffect(() => {
        if (!!props.newvalue) {
            setFieldValue(props.name, props.newvalue);
        }
    }, [setFieldValue, props.name, props.newvalue]);

    const handleBlur = (value: any) => {
        if (!!props.blur) {
            setFieldValue(props.name, value);
            props.blur(value);
        }
    }

    const handleChange = (value: any) => {
        const newValue = !!props.capitalized
            ? value.charAt(0).toUpperCase() + value.slice(1)
            : value;

        setFieldValue(props.name, newValue);
    }


    return (<>
        {!props.inline ?
            <div className="form-group">
                <label>{props.label}</label>
                {props.showInfo && <Tooltip title={
                    <React.Fragment>
                        <img
                            alt="Logo"
                            className="max-h-700px"
                            src={toAbsoluteUrl("/media/logos/" + props.imageName)}
                        />
                    </React.Fragment>
                }>
                    <IconButton>
                        <Info />
                    </IconButton>
                </Tooltip>}
                <input {...field} {...props} maxLength={props.maxLength} disabled={props.disabled} onBlur={(event: any) => handleBlur(event.target.value)} className={"form-control form-control-solid form-control-lg" + getFieldCSSClasses(touched, error)} />
                {touched && error !== "" ? (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{error}</div>
                    </div>
                ) : null}
            </div>
            :
            <div className="form-group">
                <label className="inlineLbl">{props.label}</label>
                <div className="inlineField">
                    {props.showInfo && <Tooltip title={
                        <React.Fragment>
                            <img
                                alt="Logo"
                                className="max-h-700px"
                                src={toAbsoluteUrl("/media/logos/" + props.imageName)}
                            />
                        </React.Fragment>
                    }>
                        <IconButton>
                            <Info />
                        </IconButton>
                    </Tooltip>}
                    <input {...field} {...props}
                        maxLength={props.maxLength}
                        disabled={props.disabled}
                        onBlur={(event: any) => handleBlur(event.target.value)}
                        onChange={(event: any) => handleChange(event.target.value)}
                        className={"b-dark form-control form-control-solid form-control-lg" + getFieldCSSClasses(touched, error)} />
                    {touched && error !== "" ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{error}</div>
                        </div>
                    ) : null}
                </div>
            </div>}
    </>
    );
};

export default InputFieldMain;