import { Nomen } from './generalTypes';
import moment from 'moment';
import { PreClaimPerson, PreClaimVehicle } from './claimTypes';

export interface HealthClaimGridItem {
    claimId: string;
    claimStatus: string;
    claimNumber: string;
    preClaimNumber: string;
    typePolicy: string;
    policyCode: string;
    dateAccident: string;
    dateReported: string;
    dateRejected: string;
    nature: string;
    insuredName: string;
    insuredId: string;
    insuredAddress: string;
    insuredPhone: string;
    insuredVehicleRegnum: string;
    insuredVehicleChassis: string;
    damagedName: string;
    damagedId: string;
    damagedAddress: string;
    damagedPhone: string;
    damagedVehicleRegnum: string;
    damagedVehicleChassis: string;
    beneficiary: string;
    insured: string;
    contractor: string
    invoicedAmount: string;
    approvedAmount: string;
    customerServiceActions: any[];
}

export interface HealthPreClaimInvoiceRecords {
    invoicedAmountV: string;
    comment: string | null;
    treating: string | null;
    covercategory: Nomen;
    covergroup: Nomen | null;
    cover: Nomen | null;
}

export interface HealthPreClaimInvoice {
    invoicedate: string;
    invoicenr: string;
    invoiceFiscalnr: string;
    comment: string;
    approvedAmountV: string;
    invoicedAmountV: string;
    countryEvent: Nomen;
    placeEvent: Nomen | null;
    hospitalId: string;
    hospitalName: string;
    records: HealthPreClaimInvoiceRecords[]
}

export interface HealthPreClaim {
    customerId: string;
    type_policy: Nomen;
    policy_code: string;
    date_accident: string | null;
    remark: string;
    befPerson: PreClaimPerson;
    invoices: HealthPreClaimInvoice[];
    claimFiles: any[];
    insurePersonId: string | null;
}

export interface HealthClaimFilter {
    customerId: string;
    pageSize: number;
    pageCount: number;
    dateAccidentFrom: string | null;
    dateAccidentTo: string | null;
    shownum: string;
}

export interface HealthClaimPreview {
    claimId: string;
    policy_code: string;
    class: string;
    nature: string;
    date_rejected: string;
    date_reported: string;
    date_accident: string;
    hour_accident: string;
    place_accident: string;
    description_accident: string;
    description_damage: string;
    policyreportnr: string;
    europeanreportnr: string;
    preClaimNumber: string;
    claimNumber: string;
    status: string;
    paid_amountv: string;
    amount_requestedv: string;
    approved_amountv: string;
    damagedPerson: PreClaimPerson;
    damagedVehicle: PreClaimVehicle;
    insuredPerson: PreClaimPerson;
    insuredVehicle: PreClaimVehicle;
    beneficiaryPerson: PreClaimPerson;
    invoices: HealthPreClaimInvoice[];
    appeal: HealthClaimAppeal;
}

export interface HealthClaimAppeal {
    customerId: string;
    claimId: string;
    appealDescription: string | null;
    firstLevelAppeal: string | null;
    firstLevelReview: string | null;
    firstLevelAppealDate: string | null;
    firstLevelReviewDate: string | null;
    secondLevelAppealDate: string | null;
    secondLevelReviewDate: string | null;
    secondLevelReview: string | null;
    secondLevelReviewStatus: string | null;
    referencedClaimId: string | null;
}

export const prepareHealthOrPropertyPreClaim = (customerId: string, formValues: any): HealthPreClaim => {
    var resultClaim = {
        customerId: customerId,
        type_policy: { code: "34", name: "" },
        policy_code: !!formValues.claim.policy_code ? formValues.claim.policy_code : formValues.beneficiaryPerson.policyCode,
        date_accident: moment(formValues.claim.date_accident).format('YYYY-MM-DD'),
        remark: '', // ??
        insurePersonId: formValues.claim.insure_person_id,
        befPerson: {
            tipkind: formValues.beneficiaryPerson.tipKind,
            nipt: formValues.beneficiaryPerson.nipt,
            clientno: formValues.beneficiaryPerson.clientNo,
            title: formValues.beneficiaryPerson.title,
            lastname: formValues.beneficiaryPerson.lastName,
            fathername: '',
            passport: '',
            email: formValues.beneficiaryPerson.email,
            place: { code: formValues.beneficiaryPerson.place, name: "" },
            address: formValues.beneficiaryPerson.address,
            celphone: formValues.beneficiaryPerson.celphone,
            birthdate: formValues.beneficiaryPerson.birthDate === '' || !formValues.beneficiaryPerson.birthDate || formValues.beneficiaryPerson.tipKind === '2' ? null : moment(formValues.beneficiaryPerson.birthDate).format('YYYY-MM-DD'),
            sex: { code: formValues.beneficiaryPerson.sex, name: "" },
            drivingLicenceNo: '',
            drivingLicenceCat: '',
            driverLicenseIssued: null,
            remark: '',
            bank: { code: formValues.beneficiaryPerson.bank, name: "" },
            bankAccount: formValues.beneficiaryPerson.bankAccount,
            policyCode: ''
        },
        invoices: formValues.insertInvoices.invoices.map((invoice: any, index: number) => {
            return {
                invoicedate: moment(invoice.invoicedate).format('YYYY-MM-DD'),
                invoicenr: invoice.invoicenr,
                invoiceFiscalnr: invoice.invoiceFiscalnr,
                comment: '',
                approvedAmountV: '',
                invoicedAmountV: invoice.invoicedAmountV,
                countryEvent: { code: invoice.countryEvent, name: "" },
                hospitalId: invoice.hospitalId,
                hospitalName: invoice.hospitalName,
                fiscalFiles: getRenamedFiles(invoice.fiscalFiles, "1", index),
                medicalFiles: getRenamedFiles(invoice.medicalFiles, "2", index),
                records: invoice.records.map((record: any) => {
                    return {
                        invoicedAmountV: record.invoicedAmountV,
                        comment: '',
                        treating: record.treating,
                        covercategory: { code: record.covercategory, name: "" },
                        covergroup: { code: record.covergroup, name: "" }
                    }
                })
            }
        }),
        claimFiles: formValues.uploadDocuments.claimFiles
    }

    return resultClaim;
}

const getRenamedFiles = (files: any, type: string, index: number) => {
    let recreated: File[] = [];
    files.forEach((file: File) => recreated.push(new File([file], getFileName(file.name, type, index + 1))));
    return recreated;
}

const getFileName = (originalName: string, type: string, identifier: number): string => {
    return identifier + "#" + type + "#" + originalName;
}

export const prepareHealthClaimFilter = (values: any, customerId: string) => {
    let filter: HealthClaimFilter = {
        customerId: customerId,
        pageSize: values.pageSize,
        pageCount: values.pageCount,
        dateAccidentFrom: values.dateAccidentFrom !== '' && !!values.dateAccidentFrom ? moment(values.dateAccidentFrom).format('YYYY-MM-DD') : null,
        dateAccidentTo: values.dateAccidentTo !== '' && !!values.dateAccidentTo ? moment(values.dateAccidentTo).format('YYYY-MM-DD') : null, //values.dateAccidentTo,
        shownum: values.shownum,
    }

    return filter;
}

export const validate = (invoice: any) => {
    var message: string = "Mungon fushë e domosdoshme ";
    var errored = false;
    if (invoice.invoicenr === '') {
        message += " - " + invoiceLabels['InvoiceNr'] as string + " - ";
        errored = true;
    }
    if (invoice.invoiceFiscalnr === '') {
        message += " - " + invoiceLabels['InvoiceFiscalNr'] as string + " - ";
        errored = true;
    }
    if (invoice.invoicedAmountV === '') {
        message += " - " + invoiceLabels['InvoicedAmountV'] as string + " - ";
        errored = true;
    }
    if (invoice.countryEvent === '') {
        message += " - " + invoiceLabels['CountryEvent'] as string + " - ";
        errored = true;
    }
    if (invoice.hospitalId === '') {
        message += " - " + invoiceLabels['HospitalId'] as string + " - ";
        errored = true;
    }
    if (invoice.hospitalName === '') {
        message += " - " + invoiceLabels['HospitalName'] as string + " - ";
        errored = true;
    }
    if (invoice.hospitalName === '') {
        message += " - " + invoiceLabels['HospitalName'] as string + " - ";
        errored = true;
    }
    if (invoice.invoicedate === '') {
        message += " - " + invoiceLabels['InvoiceDate'] as string + " - ";
        errored = true;
    }
    if (!invoice.fiscalFiles || invoice.fiscalFiles.length === 0) {
        message += " - " + invoiceLabels['FiscalFiles'] as string + " - ";
        errored = true;
    }
    if (!invoice.medicalFiles || invoice.medicalFiles.length === 0) {
        message += " - " + invoiceLabels['MedicalFiles'] as string + " - ";
        errored = true;
    }
    // check if at least one record is full
    if (validateRecord(invoice.records[0]).errored) {
        message += " - reshte - ";
        errored = true;
    }

    if (!errored) {
        errored = validateAmount(invoice);
        if (errored) {
            message = "- Shuma e të gjitha regjistrimeve është e ndryshme nga totali i faturës!";
        }
    }

    return { message, errored };
}

export const validateAmount = (invoice: any) => {
    var errored = false;

    const totalRecords = invoice.records.reduce((accumulator: any, record: any) => {
        if (!record.invoicedAmountV) {
            return 0;
        }
        return accumulator + parseInt(record.invoicedAmountV);
    }, 0);

    if (!!invoice.invoicedAmountV && parseInt(invoice.invoicedAmountV) !== totalRecords) {
        errored = true;
    }

    return errored;
}

export const validateRecord = (record: any) => {
    var message: string = "Mungon fushë e domosdoshme ";
    var errored = false;
    if (record.covercategory === '') {
        message += " - " + recordLabels['CoverCategory'] as string + " - ";
        errored = true;
    }
    if (record.invoicedAmountV === '') {
        message += " - " + recordLabels['RecordInvoicedAmountV'] as string + " - ";
        errored = true;
    }
    if (record.treating === '') {
        message += " - " + recordLabels['Treating'] as string + " - ";
        errored = true;
    }
    return { message, errored };
}

export const validateInvoices = (invoices: HealthPreClaimInvoice[]) => {
    var message: string = '';
    var errored = false;

    let totalInvoicedAmount = invoices.reduce(function (prev, current) {
        return prev + +current.invoicedAmountV
    }, 0);

    if (totalInvoicedAmount < 30) {
        message += "- Vlera totale per rimbursim nuk mundet te jete me e vogel se 30 €!";
        errored = true;
    }
    if (totalInvoicedAmount > 500) {
        message += "- Vlera totale per rimbursim e tejkalon limitin prej 500 €!";
        errored = true;
    }

    if (!errored) {
        invoices.forEach(invoice => {
            errored = validateAmount(invoice);
            if (errored) {
                message += "- Shuma e të gjitha regjistrimeve është e ndryshme nga totali i faturës!";
                return;
            }
        });
    }

    return { message, errored };
}

const invoiceLabels = {
    'InvoiceNr': 'Numri i kuponit',
    'InvoicedAmountV': 'Shuma e faturës',
    'HospitalName': 'Emri i spitalit',
    'InvoiceFiscalNr': 'Numri i kuponit fiskal',
    'HospitalId': 'Numri Fiskal/Nipt i spitalit',
    'InvoiceDate': 'Data e faturës',
    'CountryEvent': 'Vendi',
    'FiscalFiles': 'Kuponi fiskal',
    'MedicalFiles': 'Raportet mjeksore'
}

const recordLabels = {
    'CoverCategory': 'Mbulimi',
    'RecordInvoicedAmountV': 'Shuma (€)',
    'CoverGroup': 'Grupi i mbulimit',
    'Treating': 'Duke trajtuar',
}

export const prepareHealthClaimAppeal = (customerId: string, claimId: string, appealDescription?: string) => {
    let appeal: any = {
        customerId: customerId,
        claimId: claimId,
        appealDescription: appealDescription ? appealDescription : ''
    }

    return appeal;
}

export const getFileExtension = (mimeType: string) => {
    if (mimeType === "text/plain") {
        return ".txt";
    }

    if (mimeType === "application/vnd.ms-excel") {
        return ".xls";
    }

    if (mimeType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        return ".xlsx";
    }

    if (mimeType === "application/msword") {
        return ".doc";
    }

    if (mimeType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        return ".docx";
    }

    if (mimeType === "application/pdf") {
        return ".pdf";
    }

    if (mimeType === "image/png") {
        return ".png";
    }

    if (mimeType === "image/jpeg") {
        return ".jpg";
    }

    if (mimeType === "image/jpeg") {
        return ".jpeg";
    }
}
