import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { DropzoneArea } from 'material-ui-dropzone'
import { useFormikContext } from 'formik';
import { AppState } from '../../../redux/reducers/rootReducer';
import * as actionTypes from '../../../redux/actionTypes/claimActionTypes';
import { Loading } from '../../Layout/Loading';

const UploadDocuments = () => {
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.REGISTER_PROPERTY_PRE_CLAIM]);
    const { values }: any = useFormikContext();

    const {
        setFieldValue,
    } = useFormikContext();

    const handleSave = (files: any) => {
        setFieldValue('claimFiles', files);
    }

    return (<>
        {isLoading
            ? <Loading />
            :
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                <div className="mb-10 font-weight-bold text-dark">Dokumente <a href="https://eurosig.al/sq/kompania/d%C3%ABmet" target="_blank">   (Dokumentet qe kërkohen)</a></div>
                <div>
                    <DropzoneArea
                        onChange={handleSave}
                        onDelete={handleSave}
                        acceptedFiles={['application/pdf', 'application/msword', 
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
                                    'application/vnd.rar', 'text/plain', '	application/zip', 
                                    'application/x-7z-compressed', 'image/jpeg', 'image/png', 'image/jpeg']}
                        filesLimit={20}
                        maxFileSize={10485760}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        showFileNamesInPreview={true}
                        showFileNames={true}
                        initialFiles={values.claimFiles}
                    />
                </div>
            </div>
        }
    </>);
}

export default connect()(UploadDocuments);
