import { put, call, takeEvery, fork, all } from 'redux-saga/effects';
import { registerPreClaim, searchClaim, searchPreClaim, getPreClaim, fetchVehicleFromPolicy, fetchInsuredPersonFromPolicy, registerHealthPreClaim, registerPropertyPreClaim, searchClaimHistory, downloadClaimHistoryDocument, downloadBonusMalusDocument, sendClaimHistoryMail, exportSearchPreClaim, getPersonById, getCompanyById, getVehicleByPlateNumber, getVehicleByChassis, fetchKaskoDataFromPolicy, previewClaim, downloadPreviewClaimDocument } from "../services/claimServices";
import * as claimActionCreators from "../actionCreators/claimActionCreators";
import * as claimActionTypes from "../actionTypes/claimActionTypes";
import * as generalActionCreators from "../actionCreators/generalActionCreators";
import { history } from '../configureStore';
import moment from 'moment';

function* onRegisterPreClaim({ preClaim }: claimActionTypes.RegisterPreClaimAction): any {
    try {
        yield put(claimActionCreators.registerPreClaimRequest());

        const response = yield call(registerPreClaim, createFormData(preClaim));
        if (response.data.succeeded) {
            yield put(claimActionCreators.registerPreClaimSuccess(response.data.data));
            history.push('/preclaim/success');
        }
        else {
            yield put(claimActionCreators.registerPreClaimFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));

        }
    } catch (error: any) {
        yield put(claimActionCreators.registerPreClaimFailure(error));
    }
}


function* watchOnRegisterPreClaim() {
    yield takeEvery(claimActionTypes.REGISTER_PRE_CLAIM, onRegisterPreClaim);
}

function* onSearchPreClaim({ filter }: claimActionTypes.SearchPreClaimAction): any {
    try {
        yield put(claimActionCreators.searchPreClaimRequest());
        let response = yield call(searchPreClaim, filter);
        if (response.data.succeeded) {
            yield put(claimActionCreators.searchPreClaimSuccess(response.data.data, response.data.count));
        }
        else {
            yield put(claimActionCreators.searchPreClaimFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(claimActionCreators.searchPreClaimFailure('Ndodhi nje gabim I papritur!'));
    }
}

function* watchOnSearchPreClaim() {
    yield takeEvery(claimActionTypes.SEARCH_PRECLAIM, onSearchPreClaim);
}

function* onSearchClaim({ filter }: claimActionTypes.SearchClaimAction): any {
    try {
        yield put(claimActionCreators.searchClaimRequest());
        let response = yield call(searchClaim, filter);
        if (response.data.succeeded) {
            yield put(claimActionCreators.searchClaimSuccess(response.data.data, response.data.count));
        }
        else {
            yield put(claimActionCreators.searchClaimFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(claimActionCreators.searchClaimFailure('Ndodhi nje gabim I papritur!'));
    }
}

function* watchOnSearchClaim() {
    yield takeEvery(claimActionTypes.SEARCH_CLAIM, onSearchClaim);
}

function* onGetPreClaim({ code }: claimActionTypes.PreClaimPreviewAction): any {
    try {
        yield put(claimActionCreators.showPreClaimModal());
        yield put(claimActionCreators.preClaimPreviewRequest());
        const response = yield call(getPreClaim, code);
        if (response.data.succeeded) {
            yield put(claimActionCreators.preClaimPreviewSuccess(response.data.data));
        }
        else {
            yield put(claimActionCreators.preClaimPreviewFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(claimActionCreators.preClaimPreviewFailure(error));
    }
}

function* watchOnGetPreClaim() {
    yield takeEvery(claimActionTypes.PRECLAIM_PREVIEW, onGetPreClaim)
}

function* onFetchVehicleFromPolicy({ policyType, policyCode }: claimActionTypes.FetchVehicleFromPolicyAction): any {
    try {
        yield put(claimActionCreators.fetchVehicleFromPolicyRequest());
        const response = yield call(fetchVehicleFromPolicy, policyType, policyCode);
        if (response.data.succeeded) {
            yield put(claimActionCreators.fetchVehicleFromPolicySuccess(response.data.data));
        }
        else {
            yield put(claimActionCreators.fetchVehicleFromPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(claimActionCreators.fetchVehicleFromPolicyFailure(error));
    }
}

function* watchOnFetchVehicleFromPolicy() {
    yield takeEvery(claimActionTypes.FETCH_VEHICLE_FROM_POLICY, onFetchVehicleFromPolicy)
}

function* onFetchInsuredPersonFromPolicy({ policyType, policyCode, customerId }: claimActionTypes.FetchInsuredPersonFromPolicyAction): any {
    try {
        yield put(claimActionCreators.fetchInsuredPersonFromPolicyRequest());
        const response = yield call(fetchInsuredPersonFromPolicy, policyType, policyCode, customerId);
        if (response.data.succeeded) {
            yield put(claimActionCreators.fetchInsuredPersonFromPolicySuccess(response.data.data));
        }
        else {
            yield put(claimActionCreators.fetchInsuredPersonFromPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(claimActionCreators.fetchInsuredPersonFromPolicyFailure(error));
    }
}

function* watchOnFetchInsuredPersonFromPolicy() {
    yield takeEvery(claimActionTypes.FETCH_INSURED_PERSON_FROM_POLICY, onFetchInsuredPersonFromPolicy)
}

function* onRegisterHealthPreClaim({ preClaim }: claimActionTypes.RegisterHealthPreClaimAction): any {
    try {
        yield put(claimActionCreators.registerHealthPreClaimRequest());

        const response = yield call(registerHealthPreClaim, createFormData(preClaim));
        if (response.data.succeeded) {
            yield put(claimActionCreators.registerHealthPreClaimSuccess(response.data.data));
            history.push('/preclaim-health/success');
        }
        else {
            yield put(claimActionCreators.registerHealthPreClaimFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));

        }
    } catch (error: any) {
        yield put(claimActionCreators.registerHealthPreClaimFailure(error));
    }
}

function* watchOnRegisterHealthPreClaim() {
    yield takeEvery(claimActionTypes.REGISTER_HEALTH_PRE_CLAIM, onRegisterHealthPreClaim);
}

function* onRegisterPropertyPreClaim({ preClaim }: claimActionTypes.RegisterPropertyPreClaimAction): any {
    try {
        yield put(claimActionCreators.registerPropertyPreClaimRequest());

        const response = yield call(registerPropertyPreClaim, createFormData(preClaim));
        if (response.data.succeeded) {
            yield put(claimActionCreators.registerPropertyPreClaimSuccess(response.data.data));
            history.push('/preclaim-property/success');
        }
        else {
            yield put(claimActionCreators.registerPropertyPreClaimFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));

        }
    } catch (error: any) {
        yield put(claimActionCreators.registerPropertyPreClaimFailure(error));
    }
}


function* watchOnRegisterPropertyPreClaim() {
    yield takeEvery(claimActionTypes.REGISTER_PROPERTY_PRE_CLAIM, onRegisterPropertyPreClaim);
}

function* onSearchClaimHistory({ filter }: claimActionTypes.SearchClaimHistoryAction): any {
    try {
        yield put(claimActionCreators.searchClaimHistoryRequest());
        let response = yield call(searchClaimHistory, filter);
        if (response.data.succeeded) {
            yield put(claimActionCreators.searchClaimHistorySuccess(response.data.data, response.data.count));
        }
        else {
            yield put(claimActionCreators.searchClaimHistoryFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(claimActionCreators.searchClaimHistoryFailure('Ndodhi nje gabim I papritur!'));
    }
}

function* watchOnSearchClaimHistory() {
    yield takeEvery(claimActionTypes.SEARCH_CLAIM_HISTORY, onSearchClaimHistory);
}

function* onDownloadClaimHistoryDocument({ filter }: claimActionTypes.DownloadClaimHistoryDocumentAction): any {
    try {
        yield put(claimActionCreators.downloadClaimHistoryDocumentRequest());
        const response = yield call(downloadClaimHistoryDocument, filter);
        if (response !== null && response.status === 200 && response.data !== "" && response.data !== null) {
            yield put(claimActionCreators.downloadClaimHistoryDocumentSuccess());
            let url = window.URL.createObjectURL(new Blob([response.data]));
            saveAs(url, "Historiku i dëmeve.pdf");
        }
        else {
            yield put(claimActionCreators.downloadClaimHistoryDocumentFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(claimActionCreators.downloadClaimHistoryDocumentFailure(error));
        yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
    }
}

function* watchOnDownloadClaimHistoryDocument() {
    yield takeEvery(claimActionTypes.DOWNLOAD_CLAIM_HISTORY_DOCUMENT, onDownloadClaimHistoryDocument)
}

function* onDownloadBonusMalusDocument({ customerId, plateNumber, chassis }: claimActionTypes.DownloadBonusMalusDocumentAction): any {
    try {
        yield put(claimActionCreators.downloadBonusMalusDocumentRequest());
        const response = yield call(downloadBonusMalusDocument, customerId, plateNumber, chassis);
        if (response !== null && response.status === 200 && response.data !== "" && response.data !== null) {
            yield put(claimActionCreators.downloadBonusMalusDocumentSuccess());
            let url = window.URL.createObjectURL(new Blob([response.data]));
            saveAs(url, "Bonus/Malus.pdf");
        }
        else {
            yield put(claimActionCreators.downloadBonusMalusDocumentFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(claimActionCreators.downloadBonusMalusDocumentFailure(error));
        yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
    }
}

function* watchOnDownloadBonusMalusDocument() {
    yield takeEvery(claimActionTypes.DOWNLOAD_BONUS_MALUS_DOCUMENT, onDownloadBonusMalusDocument)
}

function* onSendMail({ data }: claimActionTypes.SendClaimHistoryMailAction): any {
    try {
        yield put(claimActionCreators.sendClaimHistoryMailRequest());
        const response = yield call(sendClaimHistoryMail, data);
        if (response.data.succeeded) {
            yield put(claimActionCreators.sendClaimHistoryMailSuccess());
            yield put(generalActionCreators.showModal("Sukses", "Email u dergua me sukses!", ""));
        }
        else {
            yield put(claimActionCreators.sendClaimHistoryMailFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(claimActionCreators.sendClaimHistoryMailFailure(error));
        yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
    }
}


function* watchOnSendMail() {
    yield takeEvery(claimActionTypes.SEND_CLAIM_HISTORY_MAIL, onSendMail);
}


function* onExportSearchPreClaim({ filter }: claimActionTypes.ExportSearchPreClaimAction): any {
    try {
        yield put(claimActionCreators.exportSearchPreClaimRequest());
        const response = yield call(exportSearchPreClaim, filter);
        if (response !== null && response.status === 200 && response.data !== "" && response.data !== null) {
            yield put(claimActionCreators.exportSearchPreClaimSuccess());
            let url = window.URL.createObjectURL(new Blob([response.data]));
            saveAs(url, "Kerkesen per Dem.xlsx");
        }
        else {
            yield put(claimActionCreators.exportSearchPreClaimFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(claimActionCreators.exportSearchPreClaimFailure(error));
        yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
    }
}

function* watchOnExportSearchPreClaim() {
    yield takeEvery(claimActionTypes.EXPORT_SEARCH_PRECLAIM, onExportSearchPreClaim)
}

function* onGetInsuredPersonById({ id }: claimActionTypes.GetInsuredPersonByIdAction): any {
    try {
        yield put(claimActionCreators.getInsuredPersonByIdRequest());
        const response = yield call(getPersonById, id);
        if (response.data.succeeded) {
            yield put(claimActionCreators.getInsuredPersonByIdSuccess(response.data.data));
        }
        else {
            yield put(claimActionCreators.getInsuredPersonByIdFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(claimActionCreators.getInsuredPersonByIdFailure(error));
    }
}

function* watchGetInsuredPersonById() {
    yield takeEvery(claimActionTypes.GET_INSURED_PERSON_BY_ID, onGetInsuredPersonById)
}

function* onGetDamagedPersonById({ id }: claimActionTypes.GetDamagedPersonByIdAction): any {
    try {
        yield put(claimActionCreators.getDamagedPersonByIdRequest());
        const response = yield call(getPersonById, id);
        if (response.data.succeeded) {
            yield put(claimActionCreators.getDamagedPersonByIdSuccess(response.data.data));
        }
        else {
            yield put(claimActionCreators.getDamagedPersonByIdFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(claimActionCreators.getDamagedPersonByIdFailure(error));
    }
}

function* watchGetDamagedPersonById() {
    yield takeEvery(claimActionTypes.GET_DAMAGED_PERSON_BY_ID, onGetDamagedPersonById)
}

function* onGetInsuredCompanyById({ id }: claimActionTypes.GetInsuredCompanyByIdAction): any {
    try {
        yield put(claimActionCreators.getInsuredCompanyByIdRequest());
        const response = yield call(getCompanyById, id);
        if (response.data.succeeded) {
            yield put(claimActionCreators.getInsuredCompanyByIdSuccess(response.data.data));
        }
        else {
            yield put(claimActionCreators.getInsuredCompanyByIdFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(claimActionCreators.getInsuredCompanyByIdFailure(error));
    }
}

function* watchGetInsuredCompanyById() {
    yield takeEvery(claimActionTypes.GET_INSURED_COMPANY_BY_ID, onGetInsuredCompanyById)
}

function* onGetDamagedCompanyById({ id }: claimActionTypes.GetDamagedCompanyByIdAction): any {
    try {
        yield put(claimActionCreators.getDamagedCompanyByIdRequest());
        const response = yield call(getCompanyById, id);
        if (response.data.succeeded) {
            yield put(claimActionCreators.getDamagedCompanyByIdSuccess(response.data.data));
        }
        else {
            yield put(claimActionCreators.getDamagedCompanyByIdFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(claimActionCreators.getDamagedCompanyByIdFailure(error));
    }
}

function* watchGetDamagedCompanyById() {
    yield takeEvery(claimActionTypes.GET_DAMAGED_COMPANY_BY_ID, onGetDamagedCompanyById)
}

function* onGetVehicleByPlateNumber({ plateNumber }: claimActionTypes.GetVehicleByPlateNumberAction): any {
    try {
        yield put(claimActionCreators.getVehicleByPlateNumberRequest());
        const response = yield call(getVehicleByPlateNumber, plateNumber);
        if (response.data.succeeded) {
            yield put(claimActionCreators.getVehicleByPlateNumberSuccess(response.data.data));
        }
        else {
            yield put(claimActionCreators.getVehicleByPlateNumberFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(claimActionCreators.getVehicleByPlateNumberFailure(error));
    }
}

function* watchGetVehicleByPlateNumber() {
    yield takeEvery(claimActionTypes.GET_VEHICLE_BY_PLATE_NUMBER, onGetVehicleByPlateNumber)
}

function* onGetVehicleByChassis({ chassis }: claimActionTypes.GetVehicleByChassisAction): any {
    try {
        yield put(claimActionCreators.getVehicleByChassisRequest());
        const response = yield call(getVehicleByChassis, chassis);
        if (response.data.succeeded) {
            yield put(claimActionCreators.getVehicleByChassisSuccess(response.data.data));
        }
        else {
            yield put(claimActionCreators.getVehicleByChassisFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(claimActionCreators.getVehicleByChassisFailure(error));
    }
}

function* watchGetVehicleByChassis() {
    yield takeEvery(claimActionTypes.GET_VEHICLE_BY_CHASSIS, onGetVehicleByChassis)
}

function* onFetchKaskoDataFromPolicy({ policyCode }: claimActionTypes.FetchKaskoDataFromPolicyAction): any {
    try {
        yield put(claimActionCreators.fetchKaskoDataFromPolicyRequest());
        const response = yield call(fetchKaskoDataFromPolicy, policyCode);
        if (response.data.succeeded) {
            yield put(claimActionCreators.fetchKaskoDataFromPolicySuccess(response.data.data));
        }
        else {
            yield put(claimActionCreators.fetchKaskoDataFromPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(claimActionCreators.fetchKaskoDataFromPolicyFailure(error));
    }
}

function* watchOnFetchKaskoDataFromPolicy() {
    yield takeEvery(claimActionTypes.FETCH_KASKO_DATA_FROM_POLICY, onFetchKaskoDataFromPolicy)
}

function* onPreviewClaim({ claimId }: claimActionTypes.ClaimPreviewAction): any {
    try {
        yield put(claimActionCreators.showClaimModal());
        yield put(claimActionCreators.claimPreviewRequest());
        const response = yield call(previewClaim, claimId);
        if (response.data.succeeded) {
            yield put(claimActionCreators.claimPreviewSuccess(response.data.data));
        }
        else {
            yield put(claimActionCreators.claimPreviewFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(claimActionCreators.claimPreviewFailure(error));
    }
}

function* watchOnPreviewClaim() {
    yield takeEvery(claimActionTypes.CLAIM_PREVIEW, onPreviewClaim)
}


function* onDownloadPreviewClaimDocument({ claimId }: claimActionTypes.DownloadPreviewClaimDocumentAction): any {
    try {
        yield put(claimActionCreators.downloadPreviewClaimDocumentRequest());
        const response = yield call(downloadPreviewClaimDocument, claimId);
        if (response !== null && response.status === 200 && response.data !== "" && response.data !== null) {
            yield put(claimActionCreators.downloadPreviewClaimDocumentSuccess());
            let url = window.URL.createObjectURL(new Blob([response.data]));
            saveAs(url, "Claim.pdf");
        }
        else {
            yield put(claimActionCreators.downloadPreviewClaimDocumentFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(claimActionCreators.downloadPreviewClaimDocumentFailure(error));
        yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
    }
}

function* watchOnDownloadPreviewClaimDocument() {
    yield takeEvery(claimActionTypes.DOWNLOAD_PREVIEW_CLAIM_DOCUMENT, onDownloadPreviewClaimDocument)
}

function* onLocationChange() {
    yield put(claimActionCreators.fetchVehicleFromPolicyReset());
    yield put(claimActionCreators.fetchInsuredPersonFromPolicyReset());
    yield put(claimActionCreators.fetchKaskoDataFromPolicyReset());
    yield put(claimActionCreators.getInsuredPersonByIdReset());
    yield put(claimActionCreators.getDamagedPersonByIdReset());
    yield put(claimActionCreators.getInsuredCompanyByIdReset());
    yield put(claimActionCreators.getDamagedCompanyByIdReset());
    yield put(claimActionCreators.getVehicleByChassisReset());
    yield put(claimActionCreators.searchClaimReset());
}

function* watchOnLocationChange() {
    yield takeEvery('@@router/LOCATION_CHANGE', onLocationChange);
}

export default function* claimSaga() {
    yield all([
        fork(watchOnRegisterPreClaim),
        fork(watchOnSearchPreClaim),
        fork(watchOnSearchClaim),
        fork(watchOnGetPreClaim),
        fork(watchOnFetchVehicleFromPolicy),
        fork(watchOnFetchInsuredPersonFromPolicy),
        fork(watchOnRegisterHealthPreClaim),
        fork(watchOnRegisterPropertyPreClaim),
        fork(watchOnSearchClaimHistory),
        fork(watchOnDownloadClaimHistoryDocument),
        fork(watchOnDownloadBonusMalusDocument),
        fork(watchOnSendMail),
        fork(watchOnExportSearchPreClaim),
        fork(watchGetInsuredPersonById),
        fork(watchGetDamagedPersonById),
        fork(watchGetInsuredCompanyById),
        fork(watchGetDamagedCompanyById),
        fork(watchGetVehicleByPlateNumber),
        fork(watchGetVehicleByChassis),
        fork(watchOnFetchKaskoDataFromPolicy),
        fork(watchOnLocationChange),
        fork(watchOnPreviewClaim),
        fork(watchOnDownloadPreviewClaimDocument)
    ]);
}

function createFormData(object: any, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();
    for (let property in object) {
        if (!object.hasOwnProperty(property) || !object[property]) {
            continue;
        }
        const formKey = namespace ? `${namespace}[${property}]` : property;
        if (object[property] instanceof Date) {
            formData.append(formKey, moment(object[property]).format('YYYY-MM-DD'));
        } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
            createFormData(object[property], formData, formKey);
        } else {
            formData.append(formKey, object[property]);
        }
    }
    return formData;
}