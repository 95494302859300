import { FormikWizardStepType } from '../../../utils/wizardTypes';
import { object, string, date, bool, number, array, StringSchema } from 'yup';
import store from '../../../redux/configureStore';
import moment from 'moment';
import { validateDriver } from '../../../utils/policyTypes';
import * as generalActions from '../../../redux/actionCreators/generalActionCreators';
import * as claimActions from '../../../redux/actionCreators/claimActionCreators';
import { CheckProfileDto } from '../../../utils';
import KaskoPolicyMain from './KaskoPolicyMain';
import InsuredVehicle from './InsuredVehicle';
import Drivers from './Drivers';
import UploadDocuments from './UploadDocuments';
import Verification from './Verification';

export default [
    {
        id: 'kaskoMain',
        component: KaskoPolicyMain,
        name: 'Primi',
        description: '',
        initialValues: {
            vehicleInsuredAmount: '',
            couponAnswer: '',
            coupon: '',
            personalDataConsent: false,
            kaskoCovers: [],
            coversFetched: false
        },
        validationSchema: object().shape({
            vehicleInsuredAmount: number().required('E domosdoshme')
                .test(
                    'validAreaValue',
                    'Vlera duhet të jetë midis 1 dhe 20 000',
                    function (this: any, value: any) {
                        return value >= 1 && value <= 20000;
                    }),
            coupon: string().when('couponAnswer', (couponAnswer: string, schema: StringSchema<string | undefined>) => {
                return couponAnswer === '1' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            personalDataConsent: bool().oneOf([true], 'E domosdoshme')
        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
        }
    },
    {
        id: 'insuredVehicle',
        component: InsuredVehicle,
        name: 'I Siguruari - Automjeti',
        description: '',
        initialValues: {
            regnum: '',
            chassis: '',
            type_vehicle: '',
            mark: '',
            tip: '',
            power: '',
            prodYear: '',
            placenr: '',
            capacity: '',
            colour: '',
            volume: '',
            vehicleLicence: ''
        },
        validationSchema: object().shape({
            regnum: string().required('E domosdoshme'),
            chassis: string().required('E domosdoshme')
                .test(
                    'validChassisValue',
                    'Gjatesia e shasise duhet te jete 17 karaktere',
                    function (this: any, value: any) {
                        return value.length === 17;
                    }),
            type_vehicle: string().required('E domosdoshme'),
            mark: string().required('E domosdoshme'),
            tip: string().required('E domosdoshme'),
            power: number(),
            prodYear: number().required('E domosdoshme').test(
                'validYear',
                'Viti i prodhimit nuk mund të jetë pas vitit aktual',
                function (this: any, value: any) {
                    const current = moment().year();
                    if (value !== '')
                        return parseInt(value) <= current;
                    return true;
                }),
            placenr: number().required('E domosdoshme'),
            capacity: number().required('E domosdoshme'),
            colour: string().required('E domosdoshme'),
            volume: number(),
            vehicleLicence: string(),
        }),
        actionLabel: 'Vazhdo',
        onAction: () => {
            store.dispatch(claimActions.getVehicleByChassisReset());
            store.dispatch(claimActions.getVehicleByPlateNumberReset());
        }
    },
    {
        id: 'insertDrivers',
        component: Drivers,
        name: 'Kontraktor/Shofer',
        description: '',
        initialValues: {
            drivers: [
                {
                    tipKind: '3',
                    clientNo: '',
                    nipt: '',
                    title: '',
                    lastName: '',
                    sex: '',
                    address: '',
                    place: '',
                    email: '',
                    celphone: '+383',
                    birthDate: ''
                }
            ]
        },
        validationSchema: object().shape({
            drivers: array()
                .of(
                    object().shape({
                        tipKind: string().required('E domosdoshme'),
                        clientNo: string()
                            .when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                                return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
                            }),
                        nipt: string()
                            .when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                                return tipKind === '2' ? schema.required('E domosdoshme') : schema.notRequired();
                            }),
                        title: string().required('E domosdoshme'),
                        lastName: string()
                            .when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                                return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
                            }),
                        sex: string()
                            .when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                                return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
                            }),
                        address: string().required('E domosdoshme'),
                        place: string().required('E domosdoshme'),
                        email: string()
                            .email("Format I gabuar I email-it")
                            .min(3, "Minimumi 3 simbole")
                            .max(50, "Maksimumi 50 simbole")
                            .required("E domosdoshme"),
                        celphone: string().required('E domosdoshme').min(10, "Minimumi 10 simbole"),
                        birthDate: date()
                            .when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                                return tipKind === '3' ? schema.required('E domosdoshme').typeError('E domosdoshme').test(
                                    'validBirthDate',
                                    'Data e lindjes nuk mund të jetë pas ditës së sotme',
                                    function (this: any, value: any) {
                                        return moment(value, 'dd/MM/yyyy').isBefore(moment());
                                    }) : schema.notRequired();
                            })
                    })
                )
        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
            const state = store.getState();

            //check if first is valid
            var validDriver = sectionValues.drivers[0];
            var isValid = validateDriver(validDriver, sectionValues.drivers);

            if (isValid.errored) {
                store.dispatch(generalActions.showModal("Dështimi", isValid.message, ""));
                return;
            }

            const firstDriver = sectionValues.drivers[sectionValues.drivers.length - 1];
            const checkProfile = state.auth.checkProfile as CheckProfileDto;
            const user = state.auth.user;

            const continueWithoutAccount = state.general.continueWithoutAccount;
            if (continueWithoutAccount)
                return;

            if (!user && checkProfile.hasProfile && checkProfile.confirmedMail) {
                store.dispatch(generalActions.showLoginModal(firstDriver.email));
                return;
            }

            if (!user && checkProfile.hasProfile && !checkProfile.confirmedMail) {
                store.dispatch(generalActions.showVerificationModal());
                return;
            }

            if (!user && !checkProfile.hasProfile) {
                store.dispatch(generalActions.showCreateAccountQuestionModal(firstDriver));
                return;
            }
        }
    },
    {
        id: 'uploadDocuments',
        component: UploadDocuments,
        name: 'Dokumente',
        description: '',
        initialValues: {
            policyFiles: []
        },
        validationSchema: object().shape({
        }),
        actionLabel: 'Vazhdo'
    },
    {
        id: 'verification',
        component: Verification,
        name: 'Verifiko',
        description: '',
        initialValues: {
            acceptTerms: false,
            captchaGenerated: '',
            captchaInputted: ''
        },
        validationSchema: object().shape({
            acceptTerms: bool().oneOf([true], 'E domosdoshme'),
            captchaGenerated: string(),
            captchaInputted: string().required('E domosdoshme')
                .test(
                    'validCaptcha',
                    'Captcha eshte gabim',
                    function (this: any, value: any) {
                        if (value !== '')
                            return this.parent.captchaGenerated === value;

                        return true;
                    })
        }),
        actionLabel: 'Vazhdo',

    },
] as FormikWizardStepType[];
