import React, { FunctionComponent, useState } from 'react';
import { useField, useFormikContext, } from 'formik';

interface Props {
    title: string;
    subtitle: string | null;
    content: any;
    active: boolean;
}

const Accordion: FunctionComponent<Props> = (props) => {
    const [,
    ] = useField({
        name: props.title,
        type: props.title
    });
    const {
    } = useFormikContext();

    const [isActive, setIsActive] = useState(props.active);

    return (
        <div className="accordion-item">
            <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                <div className="accordion-icon">{isActive ? '▽' : '▷'}</div>
                <div className="accordion-sub-left">{props.title}</div>
                <div className="accordion-sub-right">{props.subtitle}</div>
            </div>
            {isActive && <div className="accordion-content">{props.content}</div>}
        </div>
    );
};

export default Accordion;