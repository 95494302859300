import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { useFormikWizard } from 'formik-wizard';
import { Loading } from '../../../Layout/Loading';
import moment from 'moment';
import CheckboxField from '../../../Custom/CheckboxField';
import * as authActionTypes from '../../../../redux/actionTypes/authActionTypes';
import * as tliActionTypes from '../../../../redux/actionTypes/tliPolicyActionTypes';
import { AppState } from '../../../../redux/reducers/rootReducer';
import { Nomen } from '../../../../utils';
import { Gender } from '../../../../utils/nomen';
import { ThInsQuotation } from '../../../../utils/policyTypes';
import PdfTermLife from "../../../../../src/InsuranceConditions/34-01.pdf";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import CaptchaGenerator from '../../../Custom/CaptchaGenerator';
import { useFormikContext } from "formik";
import InputField from '../../../Custom/InputField';

const Verification = () => {
    const { values: wizardValues }: any = useFormikWizard();
    const { setFieldValue } = useFormikContext();
    const isLoadingHasProfile = useSelector<AppState, boolean>((state: any) => state.isLoading[authActionTypes.HAS_PROFILE]);
    const isLoadingBookPolicy = useSelector<AppState, boolean>((state: any) => state.isLoading[tliActionTypes.BOOK_TLI_POLICY]);
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const chosenTLIQuotation = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.chosenTLIQuotation);
    const tliPackageQuotation = chosenTLIQuotation ? chosenTLIQuotation[0] : null;
    const insuredPerson = wizardValues.insuredPerson;
    const beneficiaryPerson = wizardValues.beneficiaryPerson;

    const useStyles = makeStyles(theme => ({
        customTooltip: {
            backgroundColor: '#3699FF',
            fontSize: '10px',
            opacity: '0.6 !important'
        }
    }));

    const classes = useStyles();

    const result = (text: string) => {
        setFieldValue('captchaGenerated', text);
    }

    return (<>
        {isLoadingHasProfile
            ? <Loading />
            : <>
                {isLoadingBookPolicy && <Loading />}

                <div className="text-dark-50 mb10 line-height-lg">
                    <div className="wizardTitle mb10">Verifikoni informacionin e personit të siguruar</div>
                    <div className="verifyTitle">Numri personal</div><div className="verifyValue"> {insuredPerson.clientNo}</div>
                    <div className="verifyTitle">Emri</div><div className="verifyValue">{insuredPerson.title}</div>
                    <div className="verifyTitle">Mbiemri</div><div className="verifyValue">{insuredPerson.lastName}</div>
                    <div className="verifyTitle">Gjinia</div><div className="verifyValue">{Gender.filter((g: Nomen) => g.code === insuredPerson.sex)[0]?.name}</div>
                    <div className="verifyTitle">Adresa</div><div className="verifyValue">{insuredPerson.address}</div>
                    <div className="verifyTitle">Vendi</div><div className="verifyValue">{places.filter((p: Nomen) => p.code === insuredPerson.place)[0]?.name}</div>
                    <div className="verifyTitle">Email</div><div className="verifyValue">{insuredPerson.email}</div>
                    <div className="verifyTitle">Celulari</div><div className="verifyValue">{insuredPerson.celphone}</div>
                    <div className="verifyTitle">Datëlindja</div><div className="verifyValue"> {moment(insuredPerson.birthDate).format('DD/MM/yyyy')}</div>
                    <div className="verifyTitle">PEP</div><div className="verifyValue"> {insuredPerson.pep === '1' ? 'Po' : 'Jo'}</div>
                </div>
                <hr />
                <div className="text-dark-50 mt20 line-height-lg">
                    <div className="wizardTitle mt20 mb10">Verifikoni informacionin e personit të përfitues</div>
                    <div className="verifyTitle">Numri personal</div><div className="verifyValue"> {beneficiaryPerson.clientNo}</div>
                    <div className="verifyTitle">Emri</div><div className="verifyValue">{beneficiaryPerson.title}</div>
                    <div className="verifyTitle">Mbiemri</div><div className="verifyValue">{beneficiaryPerson.lastName}</div>
                    <div className="verifyTitle">Gjinia</div><div className="verifyValue">{Gender.filter((g: Nomen) => g.code === beneficiaryPerson.sex)[0]?.name}</div>
                    <div className="verifyTitle">Adresa</div><div className="verifyValue">{beneficiaryPerson.address}</div>
                    <div className="verifyTitle">Vendi</div><div className="verifyValue">{places.filter((p: Nomen) => p.code === beneficiaryPerson.place)[0]?.name}</div>
                    <div className="verifyTitle">Email</div><div className="verifyValue">{beneficiaryPerson.email}</div>
                    <div className="verifyTitle">Celulari</div><div className="verifyValue">{beneficiaryPerson.celphone}</div>
                    <div className="verifyTitle">Datëlindja</div><div className="verifyValue"> {moment(beneficiaryPerson.birthDate).format('DD/MM/yyyy')}</div>
                </div>
                <hr />
                {tliPackageQuotation &&
                    <div className="text-dark-50 line-height-lg packageInfoMobile">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">{tliPackageQuotation.packageName}</h5>
                                <p className="card-text pSum"><small className="text-muted">Shuma e siguruar: {tliPackageQuotation.insuredAmountV.toLocaleString()}€</small></p>
                                {tliPackageQuotation.covers.map((cover: any, indexC: number) => (
                                    <p key={indexC + 1} className="card-text">
                                        <span className='pCheck'>✔</span> {cover}
                                    </p>
                                ))}
                            </div>
                            <div className="card-footer">
                                {tliPackageQuotation.packageDiscount > 0 &&
                                    <p className='pDiscount'>
                                        {tliPackageQuotation.totalVNoDiscount.toFixed(2)}{tliPackageQuotation.currencyName ? tliPackageQuotation.currencyName : '€'}

                                        <Tooltip
                                            arrow
                                            title={"Aplikohet zbritja prej " + (tliPackageQuotation.packageDiscount) + "% ne kete police"}
                                            placement="top"
                                            classes={{
                                                tooltip: classes.customTooltip
                                            }}>
                                            <IconButton className="blue">
                                                <Info />
                                            </IconButton>
                                        </Tooltip>
                                    </p>}
                                <p className='pTotal'>
                                    {parseFloat(tliPackageQuotation.totalV).toFixed(2)}  {tliPackageQuotation.currencyName ? tliPackageQuotation.currencyName : '€'}
                                </p>
                            </div>
                        </div>
                        <hr />
                    </div>}

                <div className="row">
                    <div className="col-xl-6">
                        <CheckboxField name="acceptTerms" type="checkbox" label="I kuptoj dhe I pranoj kushtet e sigurimit" />
                    </div>
                    <div className="col-xl-6">
                        <a href={PdfTermLife} target="_blank">Kushtet e përgjithshme të sigurimit</a>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <CaptchaGenerator result={result} />
                    </div>
                    <div className="col-xl-12">
                        <InputField name="captchaInputted" type="text" placeholder="Shkruaj kodim këtu" label="Kodi i sigurise" />
                    </div>
                </div>
            </>
        }
    </>
    );
}
export default connect()(Verification);
