import { User, Customer } from './../types';
import ApiClient from '../../infrastructure/ApiClient';

export async function login(
  email: string,
  password: string
): Promise<User> {
  return await ApiClient.post('/api/user/authenticate', { username: email, password: password });
}

export async function register(
  tipKind: string,
  clientno: string,
  nipt: string,
  title: string,
  lastname: string,
  sex: string,
  email: string,
  mob: string,
  address: string,
  place: string,
  birthdate: string,
  password: string
): Promise<boolean> {
  return await ApiClient.post('/api/user/register', { tipKind, clientno, nipt, title, lastname, sex, email, mob, address, place, birthdate, password, autoRegister: false });
}

export async function requestPassword(
  email: string
): Promise<boolean> {
  return await ApiClient.post('/api/user/requestPassword', { email });
}

export async function getCustomer(
  customerId: string
): Promise<Customer> {
  return await ApiClient.get('/api/user/getCustomer', { customerId });
}

export async function updateCustomer(
  customer: Customer
): Promise<boolean> {
  return await ApiClient.post('/api/user/updateCustomer', customer);
}

export async function changePassword(
  data: any
): Promise<boolean> {
  return await ApiClient.post('/api/user/changePassword', data);
}

export async function getCompanyNumber(
): Promise<any> {
  return await ApiClient.get('/api/config/getCompanyNumber');
}

export async function checkActiveSession(
  token: string
): Promise<any> {
  return await ApiClient.get('/api/user/checkActiveSession', { token });
}

export async function logoutUser(
  token: string
): Promise<any> {
  return await ApiClient.post('/api/user/logoutUser', { token });
}

export async function hasProfile(
  email: string
): Promise<any> {
  return await ApiClient.get('/api/user/hasProfile', { email });
}

export async function confirmVerificationCode(
  verificationCode: string,
  email: string
): Promise<any> {
  return await ApiClient.get('/api/user/confirmVerificationCode', { verificationCode, email });
}

export async function resendVerificationCode(
  email: string
): Promise<any> {
  return await ApiClient.get('/api/user/resendVerificationCode', { email });
}

export async function autoRegisterUser(
  tipKind: string,
  clientno: string,
  nipt: string,
  title: string,
  lastname: string,
  sex: string,
  email: string,
  mob: string,
  address: string,
  place: string,
  birthdate: string,
  password: string
): Promise<boolean> {
  return await ApiClient.post('/api/user/register', { tipKind, clientno, nipt, title, lastname, sex, email, mob, address, place, birthdate, password, autoRegister: true });
}

export async function autoLoginUser(
  email: string
): Promise<any> {
  return await ApiClient.get('/api/user/login', { email });
}