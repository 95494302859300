import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import InputFieldMain from '../../Custom/InputFieldMain';
import SelectField from '../../Custom/SelectField';
import { AppState } from "../../../redux/reducers/rootReducer";
import { Nomen } from "../../../utils";
import { MotorInsQuotation } from '../../../utils/policyTypes';
import DatePickerFieldMain from '../../Custom/DatePickerFieldMain';
import { useFormikWizard } from 'formik-wizard';

const VehicleDetails = () => {
    const { values }: any = useFormikWizard();
    const classTypes = useSelector<AppState, Nomen[]>((state: any) => state.nomen.BVehicleCategoryClassTypes);
    const marks = useSelector<AppState, Nomen[]>((state: any) => state.nomen.VehicleMarks);
    const periods = useSelector<AppState, Nomen[]>((state: any) => state.nomen.BPeriods);
    const motorInsQuotation = useSelector<AppState, MotorInsQuotation | null>((state: any) => state.policy.motorInsQuotation);

    return (<>
        {
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">

                {motorInsQuotation ? <div className="alert alert-custom alert-notice alert-light-primary fade show boxTotal" role="alert">
                    <div className="alert-icon"><i className="far fa-credit-card"></i></div>
                    <div className="alert-text">Total Primi + Taksa per Blerje Online <span className="spanTotal">{motorInsQuotation.total} €</span></div>

                </div> : ''}
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" newvalue={motorInsQuotation?.vehicle?.regnum || ''} name="plateNumber" label="Targa" placeholder="Targa" />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain type="text" newvalue={motorInsQuotation?.vehicle?.chassis || ''} name="chassis" label="Numri i shasisë" placeholder="Numri i shasisë" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-6">
                        <SelectField name="insuredPeriod" label="Periudha" items={periods} />
                    </div>
                    <div className="col-xl-6 addSpace" >
                        <DatePickerFieldMain name="startdate" type="text" placeholder="Fillon" newvalue={!!values?.vehicleDetails?.startdate ? new Date(values?.vehicleDetails?.startdate) : new Date() || new Date()} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-6">
                        <SelectField name="categoryClassTypeVehicle" newvalue={motorInsQuotation?.vehicle?.categoryClassTypeVehicle?.code || ''} label="Mjeti/Klasa" items={classTypes} />
                    </div>
                    <div className="col-xl-6">
                        <SelectField name="mark" label="Prodhuesi" newvalue={motorInsQuotation?.vehicle?.mark?.code || ''} items={marks} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="tip" newvalue={motorInsQuotation?.vehicle?.tip || ''} label="Modeli" placeholder="Modeli" />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="power" newvalue={motorInsQuotation?.vehicle?.power.toString() || ''} label="Fuqia " placeholder="Fuqia" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="prodYear" newvalue={motorInsQuotation?.vehicle?.prodyear.toString() || ''} label="Viti i prodhimit" placeholder="Viti i prodhimit	" />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="placenr" newvalue={motorInsQuotation?.vehicle?.placenr.toString() || ''} label="Nr. I ulëseve" placeholder="Nr. I ulëseve" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="capacity" newvalue={motorInsQuotation?.vehicle?.capacity.toString() || ''} label="Pesha e automjetit" placeholder="Pesha e automjetit" />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="colour" newvalue={motorInsQuotation?.vehicle?.colour || ''} label="Ngjyra e automjetit" placeholder="Ngjyra e automjetit" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="used" newvalue={motorInsQuotation?.vehicle?.used || ''} label="Qëllimi i përdorimit	" placeholder="Qëllimi i përdorimit	" />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="vehicleLicence" newvalue={motorInsQuotation?.vehicle?.vehiclelicence || ''} label="Leje qarkullimi" placeholder="Leje qarkullimi" />
                    </div>
                </div>
            </div>

        }
    </>
    );
}

export default connect()(VehicleDetails);
