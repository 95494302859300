import React from 'react';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import FormikWizard from 'formik-wizard';
import { HideModalAction } from '../../../../redux/actionTypes/generalActionTypes';
import steps from './steps';
import { FormikWizardWrapperProps } from '../../../../utils/wizardTypes';
import SimpleModal from '../../../General/SimpleModal';
import Nav from './Nav';
import { hideModal } from '../../../../redux/actionCreators/generalActionCreators';
import { RouteComponentProps } from '../../../Layout/Content';
import { AppState } from '../../../../redux/reducers/rootReducer';
import { prepareCliPolicy, ThInsQuotation, CliPolicy, Question, validateQuestions } from '../../../../utils/policyTypes';
import * as thActions from "../../../../redux/actionCreators/thPolicyActionCreators";
import * as cliActions from "../../../../redux/actionCreators/cliPolicyActionCreators";
import store from '../../../../redux/configureStore';
import * as nomenActions from '../../../../redux/actionCreators/nomenActionCreators';
import { BookCLIPolicyAction, HidePoliticalFormModalModalAction } from '../../../../redux/actionTypes/cliPolicyActionTypes';
import { User } from '../../../../redux/types';
import LoginModal from '../../../Auth/LoginModal';
import RegistrationModal from '../../../Auth/Registration/RegistrationModal';
import VerificationCodeModal from '../../../Auth/Registration/VerificationCodeModal';
import * as generalActions from "../../../../redux/actionCreators/generalActionCreators";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import PoliticalFormModal from './PoliticalFormModal';
import CreateAccountQuestionModal from '../../../Auth/CreateAccountQuestionModal';

interface Props {
    closeModal(): void;
    onBookCLIPolicy(cliPolicy: CliPolicy): void;
    showModal: boolean;
    header: string;
    description: string;
    renderAsHtml?: boolean;
    nextStep: string;
    history: History;
    user: User;
    chosenCLIQuotation: any;
    politicalFormQuestions: any;
}

interface PathProps {
    history: any;
}

function FormWrapper({
    children,
    isLastStep,
    goToPreviousStep,
    canGoBack,
    actionLabel,
    currentStep,
    values,
    wizard
}: FormikWizardWrapperProps<any, any>) {
    const dispatch = useDispatch();
    const cliQuotationErrored = useSelector<AppState, boolean | null>((state: any) => state.policy.cliQuotationErrored);
    const cliQuestionsErrored = useSelector<AppState, boolean | null>((state: any) => state.policy.cliQuestionsErrored);
    const policyQuotationChosen = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.policyQuotationChosen);
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const showLoginModal = useSelector<AppState, boolean | null>((state: any) => state.general.showLoginModal);
    const showRegistrationModal = useSelector<AppState, boolean | null>((state: any) => state.general.showRegistrationModal);
    const showVerificationModal = useSelector<AppState, boolean | null>((state: any) => state.general.showVerificationModal);
    const showPoliticalFormModal = useSelector<AppState, boolean | null>((state: any) => state.policy.showPoliticalFormModal);
    const chosenCLIQuotation = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.chosenCLIQuotation);
    const cliPackageQuotation = chosenCLIQuotation ? chosenCLIQuotation[0] : null;
    const continueWithoutAccount = useSelector<AppState, boolean | null>((state: any) => state.general.continueWithoutAccount);
    const showCreateAccountQuestionModal = useSelector<AppState, boolean | null>((state: any) => state.general.showCreateAccountQuestionModal);

    const hideNextBtn = currentStep === steps[1].id;

    const showSuccessToast = useSelector<AppState, boolean>((state: any) => state.general.showSuccessToast);
    const successToastText = useSelector<AppState, boolean>((state: any) => state.general.successToastText);

    const politicalFormQuestions = useSelector<AppState, Question[]>((state: any) => state.policy.politicalFormQuestions);
    var stepSkipped = false;

    if (!!showSuccessToast) {
        toast.success(successToastText);
        dispatch({ type: "generalActionTypes/SHOW_SUCCESS_TOAST_RESET" });
    }

    if (currentStep === steps[1].id && (cliQuotationErrored || cliQuestionsErrored)) {
        goToPreviousStep()
    };

    if (currentStep === steps[2].id && continueWithoutAccount) {
        wizard.next();
    }

    if (currentStep === steps[3].id && !user && !continueWithoutAccount) {
        stepSkipped = true;
        goToPreviousStep();
    }

    if (currentStep === steps[3].id && !stepSkipped && values.insuredPerson.pep === '1') {
        if (!politicalFormQuestions) {
            store.dispatch(generalActions.showModal("Dështimi", "Duhet të plotësoni formularin e identifikimit të personit të ekspozuar politikisht!", ""));
            goToPreviousStep();
        } else {
            var validation = validateQuestions(politicalFormQuestions);
            if (validation.errored) {
                store.dispatch(generalActions.showModal("Dështimi", validation.message, ""));
                goToPreviousStep();
            }
        }
    }

    if (currentStep === steps[4].id && values.uploadDocuments.idCardFiles.length === 0) {
        store.dispatch(generalActions.showModal("Dështimi", "Mungojnë skedarët e kërkuar!", ""));
        goToPreviousStep();
    }

    if (hideNextBtn && policyQuotationChosen) {
        store.dispatch(thActions.chooseQuotationReset());
        store.dispatch(nomenActions.getPlaces());
        store.dispatch(nomenActions.getBanksLife());

        wizard.next();
    }

    const handleCloseLoginModal = () => {
        store.dispatch(generalActions.showLoginModalReset());
    };

    const handleCloseRegistrationModal = () => {
        store.dispatch(generalActions.showRegistrationModalReset());
    };

    const handleCloseVerificationModal = () => {
        store.dispatch(generalActions.showVerificationModalReset());
    };

    const handleClosePoliticalModal = () => {
        store.dispatch(cliActions.hidePoliticalFormModal());
    };

    const handleCloseCreateAccountQuestionModal = () => {
        store.dispatch(generalActions.showCreateAccountQuestionModalReset());
    };

    const goBack = () => {
        if (currentStep === steps[3].id) {
            store.dispatch(generalActions.continueWithoutAccountReset());
        }

        goToPreviousStep();
    }

    const useStyles = makeStyles(theme => ({
        customTooltip: {
            backgroundColor: '#3699FF',
            fontSize: '10px',
            opacity: '0.6 !important'
        }
    }));

    const classes = useStyles();

    return (
        <>
            <LoginModal show={showLoginModal} onClose={handleCloseLoginModal} onHide={() => { return; }} />
            <RegistrationModal show={showRegistrationModal} onClose={handleCloseRegistrationModal} onHide={() => { return; }} />
            <VerificationCodeModal show={showVerificationModal} onClose={handleCloseVerificationModal} onHide={() => { return; }} />
            <PoliticalFormModal show={showPoliticalFormModal} onClose={handleClosePoliticalModal} onHide={() => { return; }} />
            <CreateAccountQuestionModal show={showCreateAccountQuestionModal} onClose={handleCloseCreateAccountQuestionModal} onHide={() => { return; }} />

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                theme="colored"
            />

            <div className="wizard wizard-4" id="kt_wizard_v4" data-wizard-state="step-first" data-wizard-clickable="true">
                <Nav currentStep={currentStep} />
                <div className="card card-custom card-shadowless rounded-top-0">
                    <div className="card-body p-0">
                        <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                            <div className="col-xl-12 col-xxl-7">
                                {children}
                                <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                    <div className="mr-2">
                                        {canGoBack && <button type="button" onClick={goBack} className="btn-wiz-back font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-prev">
                                            <i className="arrow left"></i>Kthehu
                                        </button>
                                        }
                                    </div>

                                    {!hideNextBtn &&
                                        <div>
                                            {isLastStep && <button type="submit" className="btn btn-wiz font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-submit">
                                                {actionLabel || 'Submit'}
                                            </button>}
                                            {!isLastStep && <button type="submit" className="btn btn-wiz font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-next">
                                                {actionLabel || 'Next'}
                                            </button>}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {cliPackageQuotation && <div className="col-xl-12 packageCard">
                <div className="card offcanvas offcanvas-right offcanvas-on card-c" >
                    <div className="card-body card-b-aside">
                        <h5 className="card-title">{cliPackageQuotation.packageName}</h5>
                        <p className="card-text pSum"><small>Shuma e siguruar: {cliPackageQuotation.insuredAmountV.toLocaleString()}€</small></p>
                        {cliPackageQuotation.covers.map((cover: any, indexC: number) => (
                            <p key={indexC + 1} className="card-text">
                                <span className='pCheck'>✔ </span>
                                <span dangerouslySetInnerHTML={{ __html: cover.replaceAll("#", "<br />") }}></span>
                            </p>
                        ))}
                    </div>

                    <div className="card-footer">
                        {cliPackageQuotation.packageDiscount > 0 &&
                            <p className='pDiscount'>
                                {cliPackageQuotation.totalVNoDiscount.toFixed(2)}{cliPackageQuotation.currencyName ? cliPackageQuotation.currencyName : '€'}

                                <Tooltip
                                    arrow
                                    title={"Aplikohet zbritja prej " + (cliPackageQuotation.packageDiscount) + "% ne kete police"}
                                    placement="top"
                                    classes={{
                                        tooltip: classes.customTooltip
                                    }}>
                                    <IconButton className="blue">
                                        <Info />
                                    </IconButton>
                                </Tooltip>
                            </p>}
                        <p className='pTotal'>
                            {parseFloat(cliPackageQuotation.totalV).toFixed(2)}  {cliPackageQuotation.currencyName ? cliPackageQuotation.currencyName : '€'}
                        </p>
                    </div>
                </div>
            </div>}
        </>
    );
}

class CreditLifePolicy extends React.Component<Props & RouteComponentProps<PathProps>, {}> {
    handleSubmit = (values: any) => {
        const customerId = this.props.user ? this.props.user.customerId : '';
        const packageId = this.props.chosenCLIQuotation && this.props.chosenCLIQuotation?.length > 0 ? this.props.chosenCLIQuotation[0].packageId : "";
        const politicalFormQuestions = this.props.politicalFormQuestions;
        const policy = prepareCliPolicy(customerId, values, packageId, politicalFormQuestions);

        this.props.onBookCLIPolicy(policy);
    };

    handleCloseModal = () => {
        if (this.props.nextStep === "3") this.props.history.push('/');
        this.props.closeModal();
    };

    render() {
        return (
            <div className="d-flex flex-column-fluid">
                <SimpleModal show={this.props.showModal} header={this.props.header} text={this.props.description} renderAsHtml={this.props.renderAsHtml} onClose={this.handleCloseModal} onHide={() => { return; }} />
                <div className=" container ">
                    <div className="card card-custom">
                        <div className="card-body p-0">
                            <FormikWizard steps={steps} onSubmit={this.handleSubmit} render={FormWrapper} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch<HideModalAction | BookCLIPolicyAction | HidePoliticalFormModalModalAction>) => ({
    onBookCLIPolicy: (cliPolicy: CliPolicy) => {
        dispatch(cliActions.bookCLIPolicy(cliPolicy));
    },
    closeModal: () => { dispatch(hideModal()); },
    closePoliticalModal: () => { dispatch(cliActions.hidePoliticalFormModal()); }
});

const mapStateToProps = (state: any) => ({
    user: state.auth.user,
    chosenCLIQuotation: state.policy.chosenCLIQuotation,
    showModal: state.general.showModal,
    header: state.general.header,
    description: state.general.text,
    nextStep: state.general.nextStep,
    renderAsHtml: state.general.renderAsHtml,
    politicalFormQuestions: state.policy.politicalFormQuestions
});

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)(CreditLifePolicy)));
