import { all, fork } from "redux-saga/effects";
import AuthSaga from "./authSaga";
import NomenSaga from "./nomenSaga";
import PolicySaga from './policySaga';
import GeneralSaga from './generalSaga';
import ClaimSaga from './claimSaga';
import HealthPreClaimSaga from './healthPreClaimSaga';

export default function* rootSaga() {
  yield all([fork(AuthSaga), fork(NomenSaga), fork(PolicySaga), fork(GeneralSaga), fork(ClaimSaga), fork(HealthPreClaimSaga)]);
}
