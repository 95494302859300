import React, { useState } from "react";
import { connect, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { AppState } from '../../../redux/reducers/rootReducer';
import * as nomenActionTypes from '../../../redux/actionTypes/nomenActionTypes';
import { Loading } from "../../Layout/Loading";
import { User } from "../../../redux/types";
import InputFieldMain from "../../Custom/InputFieldMain";
import SelectField from "../../Custom/SelectField";
import DatePickerFieldInline from "../../Custom/DatePickerFieldInline";
import { Gender } from '../../../utils/nomen';
import store from "../../../redux/configureStore";
import * as authActions from "../../../redux/actionCreators/authActionCreators";
import { Nomen } from "../../../utils";
import { useFormikWizard } from 'formik-wizard';

const InsuredPerson = () => {
    const { values, setValues }: any = useFormikContext();
    const { values: wizardValues }: any = useFormikWizard();

    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[nomenActionTypes.GET_PLACES]);
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const isLegalEntity = wizardValues.piMain.objectType === '087';

    const checkProfile = (value: any) => {
        const customerId = user?.customerId;

        if (!customerId) {
            store.dispatch(authActions.hasProfile(value));
        }
    };

    return (<>
        {isLoading
            ? <Loading />
            : <>
                <div className="wizardTitle">I siguruari</div>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            {!isLegalEntity && <InputFieldMain type="text" name="clientNo" label="Numri personal" placeholder="Numri personal" maxLength={16} newvalue={values?.clientNo || ''} inline />}
                            {isLegalEntity && <InputFieldMain type="text" name="nipt" placeholder="Nipt" label="Nipt" newvalue={values?.nipt || ''} maxLength={16} inline />}
                    
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="title" label="Emri" placeholder="Emri" newvalue={values?.title || ''} inline capitalized />
                        </div>
                    </div>

                    {!isLegalEntity && <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="lastName" label="Mbiemri" placeholder="Mbiemri" newvalue={values?.lastName || ''} inline capitalized />
                        </div>
                    </div>}

                    {!isLegalEntity && < div className="row">
                        <div className="col-xl-12 addSpace">
                            <SelectField name="sex" label="Gjinia" items={Gender} newvalue={values?.sex || ''} inline />
                        </div>
                    </div>}

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" newvalue={values?.address || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <SelectField name="place" label="Vendi" items={places} newvalue={values?.place || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="email" blur={checkProfile} label="Email" placeholder="Email" newvalue={values?.email || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="celphone" label="Celulari" placeholder="Celulari" newvalue={values?.celphone || ''} inline />
                        </div>
                    </div>

                    {!isLegalEntity && <div className="row">
                        <div className="col-xl-12 addSpace">
                            <DatePickerFieldInline name="birthDate" type="text" placeholder="Datëlindja" newvalue={values?.birthDate || ''} />
                        </div>
                    </div>}
                </div>
            </>
        }
    </>
    );
}
export default connect()(InsuredPerson);
