import React from "react";
import InputFieldMain from '../../Custom/InputFieldMain';
import Nav from './Nav';
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Customer } from "../../../redux/types";

const Contact = () => {
    const customer = useSelector<AppState, Customer | null>((state: any) => state.auth.customer);

    return (
        <>
            <div className="d-flex flex-column-auto flex-column px-10">
                <Nav step={1} />
            </div>
            <div className="login-form form px-10 fv-plugins-bootstrap fv-plugins-framework ">
               
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain name="address" type="text" newvalue={customer?.address} placeholder="Adresa" label="Adresa" />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain name="place" type="text" newvalue={customer?.place} placeholder="Qyteti" label="Qyteti" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain name="mob" type="text" newvalue={customer?.mob} placeholder="Telefon " label="Telefon" />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain name="email" type="email" newvalue={customer?.email} placeholder="Email" label="Email" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;
