import { Nomen } from "../../utils";
import { UploadHealthClaimFile } from "../../utils/claimTypes";
import { HealthClaimAppeal, HealthClaimFilter, HealthClaimGridItem, HealthClaimPreview } from "../../utils/healthPreClaimTypes";

export const SEARCH_HEALTH_CLAIM = "healthClaimActionTypes/SEARCH_HEALTH_CLAIM";
export interface SearchHealthClaimAction {
  type: typeof SEARCH_HEALTH_CLAIM;
  filter: HealthClaimFilter;
}

export const SEARCH_HEALTH_CLAIM_REQUEST = "healthClaimActionTypes/SEARCH_HEALTH_CLAIM_REQUEST";
export interface SearchHealthClaimRequestAction {
  type: typeof SEARCH_HEALTH_CLAIM_REQUEST;
}

export const SEARCH_HEALTH_CLAIM_SUCCESS = "healthClaimActionTypes/SEARCH_HEALTH_CLAIM_SUCCESS";
export interface SearchHealthClaimSuccessAction {
  type: typeof SEARCH_HEALTH_CLAIM_SUCCESS;
  data: HealthClaimGridItem[];
  count: number;
}

export const SEARCH_HEALTH_CLAIM_RESET = "healthClaimActionTypes/SEARCH_HEALTH_CLAIM_RESET";
export interface SearchHealthClaimResetAction {
  type: typeof SEARCH_HEALTH_CLAIM_RESET;
}

export const SEARCH_HEALTH_CLAIM_FAILURE = "healthClaimActionTypes/SEARCH_HEALTH_CLAIM_FAILURE";
export interface SearchHealthClaimFailureAction {
  type: typeof SEARCH_HEALTH_CLAIM_FAILURE;
  error: Error | string;
}

export const HEALTH_CLAIM_PREVIEW = "healthClaimActionTypes/HEALTH_CLAIM_PREVIEW";
export interface HealthClaimPreviewAction {
  type: typeof HEALTH_CLAIM_PREVIEW;
  code: string;
  fetchDocuments: boolean;
}

export const HEALTH_CLAIM_PREVIEW_REQUEST = "healthClaimActionTypes/HEALTH_CLAIM_PREVIEW_REQUEST";
export interface HealthClaimPreviewRequestAction {
  type: typeof HEALTH_CLAIM_PREVIEW_REQUEST;
}

export const HEALTH_CLAIM_PREVIEW_SUCCESS = "healthClaimActionTypes/HEALTH_CLAIM_PREVIEW_SUCCESS";
export interface HealthClaimPreviewSuccessAction {
  type: typeof HEALTH_CLAIM_PREVIEW_SUCCESS;
  data: HealthClaimPreview;
}

export const HEALTH_CLAIM_PREVIEW_FAILURE = "healthClaimActionTypes/HEALTH_CLAIM_PREVIEW_FAILURE";
export interface HealthClaimPreviewFailureAction {
  type: typeof HEALTH_CLAIM_PREVIEW_FAILURE;
  error: Error | string;
}

export const HEALTH_CLAIM_PREVIEW_RESET = "healthClaimActionTypes/HEALTH_CLAIM_PREVIEW_RESET";
export interface HealthClaimPreviewResetAction {
  type: typeof HEALTH_CLAIM_PREVIEW_RESET;
}

export const HEALTH_CLAIM_PREVIEW_FIRST_LEVEL = "healthClaimActionTypes/HEALTH_CLAIM_PREVIEW_FIRST_LEVEL";
export interface HealthClaimPreviewFirstLevelAction {
  type: typeof HEALTH_CLAIM_PREVIEW_FIRST_LEVEL;
  code: string;
}

export const HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_REQUEST = "healthClaimActionTypes/HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_REQUEST";
export interface HealthClaimPreviewFirstLevelRequestAction {
  type: typeof HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_REQUEST;
}

export const HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_SUCCESS = "healthClaimActionTypes/HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_SUCCESS";
export interface HealthClaimPreviewFirstLevelSuccessAction {
  type: typeof HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_SUCCESS;
  data: HealthClaimPreview;
}

export const HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_FAILURE = "healthClaimActionTypes/HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_FAILURE";
export interface HealthClaimPreviewFirstLevelFailureAction {
  type: typeof HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_FAILURE;
  error: Error | string;
}

export const HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_RESET = "healthClaimActionTypes/HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_RESET";
export interface HealthClaimPreviewFirstLevelResetAction {
  type: typeof HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_RESET;
}

export const HEALTH_CLAIM_PREVIEW_SECOND_LEVEL = "healthClaimActionTypes/HEALTH_CLAIM_PREVIEW_SECOND_LEVEL";
export interface HealthClaimPreviewSecondLevelAction {
  type: typeof HEALTH_CLAIM_PREVIEW_SECOND_LEVEL;
  code: string;
}

export const HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_REQUEST = "healthClaimActionTypes/HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_REQUEST";
export interface HealthClaimPreviewSecondLevelRequestAction {
  type: typeof HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_REQUEST;
}

export const HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_SUCCESS = "healthClaimActionTypes/HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_SUCCESS";
export interface HealthClaimPreviewSecondLevelSuccessAction {
  type: typeof HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_SUCCESS;
  data: HealthClaimPreview;
}

export const HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_FAILURE = "healthClaimActionTypes/HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_FAILURE";
export interface HealthClaimPreviewSecondLevelFailureAction {
  type: typeof HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_FAILURE;
  error: Error | string;
}

export const HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_RESET = "healthClaimActionTypes/HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_RESET";
export interface HealthClaimPreviewSecondLevelResetAction {
  type: typeof HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_RESET;
}

export const EXPORT_SEARCH_HEALTH_CLAIM = "healthClaimActionTypes/EXPORT_SEARCH_HEALTH_CLAIM";
export interface ExportSearchHealthClaimAction {
  type: typeof EXPORT_SEARCH_HEALTH_CLAIM;
  filter: HealthClaimFilter;
}

export const EXPORT_SEARCH_HEALTH_CLAIM_REQUEST = "healthClaimActionTypes/EXPORT_SEARCH_HEALTH_CLAIM_REQUEST";
export interface ExportSearchHealthClaimRequestAction {
  type: typeof EXPORT_SEARCH_HEALTH_CLAIM_REQUEST;
}

export const EXPORT_SEARCH_HEALTH_CLAIM_SUCCESS = "healthClaimActionTypes/EXPORT_SEARCH_HEALTH_CLAIM_SUCCESS";
export interface ExportSearchHealthClaimSuccessAction {
  type: typeof EXPORT_SEARCH_HEALTH_CLAIM_SUCCESS;
}

export const EXPORT_SEARCH_HEALTH_CLAIM_RESET = "healthClaimActionTypes/EXPORT_SEARCH_HEALTH_CLAIM_RESET";
export interface ExportSearchHealthClaimResetAction {
  type: typeof EXPORT_SEARCH_HEALTH_CLAIM_RESET;
}

export const EXPORT_SEARCH_HEALTH_CLAIM_FAILURE = "healthClaimActionTypes/EXPORT_SEARCH_HEALTH_CLAIM_FAILURE";
export interface ExportSearchHealthClaimFailureAction {
  type: typeof EXPORT_SEARCH_HEALTH_CLAIM_FAILURE;
  error: Error | string;
}

export const SHOW_HEALTH_CLAIM_MODAL = "healthClaimActionTypes/SHOW_HEALTH_CLAIM_MODAL";
export interface ShowHealthClaimModalAction {
  type: typeof SHOW_HEALTH_CLAIM_MODAL;
}

export const SHOW_HEALTH_CLAIM_FIRST_LEVEL_MODAL = "healthClaimActionTypes/SHOW_HEALTH_CLAIM_FIRST_LEVEL_MODAL";
export interface ShowHealthClaimFirstLevelModalAction {
  type: typeof SHOW_HEALTH_CLAIM_FIRST_LEVEL_MODAL;
}

export const SHOW_HEALTH_CLAIM_SECOND_LEVEL_MODAL = "healthClaimActionTypes/SHOW_HEALTH_CLAIM_SECOND_LEVEL_MODAL";
export interface ShowHealthClaimSecondLevelModalAction {
  type: typeof SHOW_HEALTH_CLAIM_SECOND_LEVEL_MODAL;
}

export const READ_FIRST_LEVEL_APPEAL = "healthClaimActionTypes/READ_FIRST_LEVEL_APPEAL";
export interface ReadFirstLevelAppealAction {
  type: typeof READ_FIRST_LEVEL_APPEAL;
  code: string;
}

export const READ_FIRST_LEVEL_APPEAL_REQUEST = "healthClaimActionTypes/READ_FIRST_LEVEL_APPEAL_REQUEST";
export interface ReadFirstLevelAppealRequestAction {
  type: typeof READ_FIRST_LEVEL_APPEAL_REQUEST;
}

export const READ_FIRST_LEVEL_APPEAL_SUCCESS = "healthClaimActionTypes/READ_FIRST_LEVEL_APPEAL_SUCCESS";
export interface ReadFirstLevelAppealSuccessAction {
  type: typeof READ_FIRST_LEVEL_APPEAL_SUCCESS;
  data: HealthClaimPreview;
}

export const READ_FIRST_LEVEL_APPEAL_FAILURE = "healthClaimActionTypes/READ_FIRST_LEVEL_APPEAL_FAILURE";
export interface ReadFirstLevelAppealFailureAction {
  type: typeof READ_FIRST_LEVEL_APPEAL_FAILURE;
  error: Error | string;
}

export const READ_FIRST_LEVEL_APPEAL_RESET = "healthClaimActionTypes/READ_FIRST_LEVEL_APPEAL_RESET";
export interface ReadFirstLevelAppealResetAction {
  type: typeof READ_FIRST_LEVEL_APPEAL_RESET;
}

export const READ_SECOND_LEVEL_APPEAL = "healthClaimActionTypes/READ_SECOND_LEVEL_APPEAL";
export interface ReadSecondLevelAppealAction {
  type: typeof READ_SECOND_LEVEL_APPEAL;
  code: string;
}

export const READ_SECOND_LEVEL_APPEAL_REQUEST = "healthClaimActionTypes/READ_SECOND_LEVEL_APPEAL_REQUEST";
export interface ReadSecondLevelAppealRequestAction {
  type: typeof READ_SECOND_LEVEL_APPEAL_REQUEST;
}

export const READ_SECOND_LEVEL_APPEAL_SUCCESS = "healthClaimActionTypes/READ_SECOND_LEVEL_APPEAL_SUCCESS";
export interface ReadSecondLevelAppealSuccessAction {
  type: typeof READ_SECOND_LEVEL_APPEAL_SUCCESS;
  data: HealthClaimPreview;
}

export const READ_SECOND_LEVEL_APPEAL_FAILURE = "healthClaimActionTypes/READ_SECOND_LEVEL_APPEAL_FAILURE";
export interface ReadSecondLevelAppealFailureAction {
  type: typeof READ_SECOND_LEVEL_APPEAL_FAILURE;
  error: Error | string;
}

export const READ_SECOND_LEVEL_APPEAL_RESET = "healthClaimActionTypes/READ_SECOND_LEVEL_APPEAL_RESET";
export interface ReadSecondLevelAppealResetAction {
  type: typeof READ_SECOND_LEVEL_APPEAL_RESET;
}

export const SHOW_READ_FIRST_LEVEL_APPEAL_MODAL = "healthClaimActionTypes/SHOW_READ_FIRST_LEVEL_APPEAL_MODAL";
export interface ShowReadFirstLevelAppealModalAction {
  type: typeof SHOW_READ_FIRST_LEVEL_APPEAL_MODAL;
}

export const SHOW_READ_SECOND_LEVEL_APPEAL_MODAL = "healthClaimActionTypes/SHOW_READ_SECOND_LEVEL_APPEAL_MODAL";
export interface ShowReadSecondLevelAppealModalAction {
  type: typeof SHOW_READ_SECOND_LEVEL_APPEAL_MODAL;
}

export const MARK_APPEAL_AS_READ = "healthClaimActionTypes/MARK_APPEAL_AS_READ";
export interface MarkAppealAsReadAction {
  type: typeof MARK_APPEAL_AS_READ;
  claimId: string;
}

export const MARK_APPEAL_AS_READ_REQUEST = "healthClaimActionTypes/MARK_APPEAL_AS_READ_REQUEST";
export interface MarkAppealAsReadRequestAction {
  type: typeof MARK_APPEAL_AS_READ_REQUEST;
}

export const MARK_APPEAL_AS_READ_SUCCESS = "healthClaimActionTypes/MARK_APPEAL_AS_READ_SUCCESS";
export interface MarkAppealAsReadSuccessAction {
  type: typeof MARK_APPEAL_AS_READ_SUCCESS;
}

export const MARK_APPEAL_AS_READ_RESET = "healthClaimActionTypes/MARK_APPEAL_AS_READ_RESET";
export interface MarkAppealAsReadResetAction {
  type: typeof MARK_APPEAL_AS_READ_RESET;
}

export const MARK_APPEAL_AS_READ_FAILURE = "healthClaimActionTypes/MARK_APPEAL_AS_READ_FAILURE";
export interface MarkAppealAsReadFailureAction {
  type: typeof MARK_APPEAL_AS_READ_FAILURE;
  error: Error | string;
}

export const PRINT_FIRST_LEVEL_APPEAL = "healthClaimActionTypes/PRINT_FIRST_LEVEL_APPEAL";
export interface PrintFirstLevelAppealAction {
  type: typeof PRINT_FIRST_LEVEL_APPEAL;
  claimId: string;
}

export const PRINT_FIRST_LEVEL_APPEAL_REQUEST = "healthClaimActionTypes/PRINT_FIRST_LEVEL_APPEAL_REQUEST";
export interface PrintFirstLevelAppealRequestAction {
  type: typeof PRINT_FIRST_LEVEL_APPEAL_REQUEST;
}

export const PRINT_FIRST_LEVEL_APPEAL_SUCCESS = "healthClaimActionTypes/PRINT_FIRST_LEVEL_APPEAL_SUCCESS";
export interface PrintFirstLevelAppealSuccessAction {
  type: typeof PRINT_FIRST_LEVEL_APPEAL_SUCCESS;
}

export const PRINT_FIRST_LEVEL_APPEAL_RESET = "healthClaimActionTypes/PRINT_FIRST_LEVEL_APPEAL_RESET";
export interface PrintFirstLevelAppealResetAction {
  type: typeof PRINT_FIRST_LEVEL_APPEAL_RESET;
}

export const PRINT_FIRST_LEVEL_APPEAL_FAILURE = "healthClaimActionTypes/PRINT_FIRST_LEVEL_APPEAL_FAILURE";
export interface PrintFirstLevelAppealFailureAction {
  type: typeof PRINT_FIRST_LEVEL_APPEAL_FAILURE;
  error: Error | string;
}


export const FILE_FIRST_LEVEL_APPEAL = "healthPreClaimActionTypes/FILE_FIRST_LEVEL_APPEAL";
export interface FileFirstLevelAppealAction {
  type: typeof FILE_FIRST_LEVEL_APPEAL;
  appeal: HealthClaimAppeal
}

export const FILE_FIRST_LEVEL_APPEAL_REQUEST = "healthPreClaimActionTypes/FILE_FIRST_LEVEL_APPEAL_REQUEST";
export interface FileFirstLevelAppealRequestAction {
  type: typeof FILE_FIRST_LEVEL_APPEAL_REQUEST;
}

export const FILE_FIRST_LEVEL_APPEAL_SUCCESS = "healthPreClaimActionTypes/FILE_FIRST_LEVEL_APPEAL_SUCCESS";
export interface FileFirstLevelAppealSuccessAction {
  type: typeof FILE_FIRST_LEVEL_APPEAL_SUCCESS;
}

export const FILE_FIRST_LEVEL_APPEAL_FAILURE = "healthPreClaimActionTypes/FILE_FIRST_LEVEL_APPEAL_FAILURE";
export interface FileFirstLevelAppealFailureAction {
  type: typeof FILE_FIRST_LEVEL_APPEAL_FAILURE;
  error: Error | string;
}

export const FILE_SECOND_LEVEL_APPEAL = "healthPreClaimActionTypes/FILE_SECOND_LEVEL_APPEAL";
export interface FileSecondLevelAppealAction {
  type: typeof FILE_SECOND_LEVEL_APPEAL;
  appeal: HealthClaimAppeal
}

export const FILE_SECOND_LEVEL_APPEAL_REQUEST = "healthPreClaimActionTypes/FILE_SECOND_LEVEL_APPEAL_REQUEST";
export interface FileSecondLevelAppealRequestAction {
  type: typeof FILE_SECOND_LEVEL_APPEAL_REQUEST;
}

export const FILE_SECOND_LEVEL_APPEAL_SUCCESS = "healthPreClaimActionTypes/FILE_SECOND_LEVEL_APPEAL_SUCCESS";
export interface FileSecondLevelAppealSuccessAction {
  type: typeof FILE_SECOND_LEVEL_APPEAL_SUCCESS;
}

export const FILE_SECOND_LEVEL_APPEAL_FAILURE = "healthPreClaimActionTypes/FILE_SECOND_LEVEL_APPEAL_FAILURE";
export interface FileSecondLevelAppealFailureAction {
  type: typeof FILE_SECOND_LEVEL_APPEAL_FAILURE;
  error: Error | string;
}

export const PRINT_SECOND_LEVEL_APPEAL = "healthClaimActionTypes/PRINT_SECOND_LEVEL_APPEAL";
export interface PrintSecondLevelAppealAction {
  type: typeof PRINT_SECOND_LEVEL_APPEAL;
  claimId: string;
}

export const PRINT_SECOND_LEVEL_APPEAL_REQUEST = "healthClaimActionTypes/PRINT_SECOND_LEVEL_APPEAL_REQUEST";
export interface PrintSecondLevelAppealRequestAction {
  type: typeof PRINT_SECOND_LEVEL_APPEAL_REQUEST;
}

export const PRINT_SECOND_LEVEL_APPEAL_SUCCESS = "healthClaimActionTypes/PRINT_SECOND_LEVEL_APPEAL_SUCCESS";
export interface PrintSecondLevelAppealSuccessAction {
  type: typeof PRINT_SECOND_LEVEL_APPEAL_SUCCESS;
}

export const PRINT_SECOND_LEVEL_APPEAL_RESET = "healthClaimActionTypes/PRINT_SECOND_LEVEL_APPEAL_RESET";
export interface PrintSecondLevelAppealResetAction {
  type: typeof PRINT_SECOND_LEVEL_APPEAL_RESET;
}

export const PRINT_SECOND_LEVEL_APPEAL_FAILURE = "healthClaimActionTypes/PRINT_SECOND_LEVEL_APPEAL_FAILURE";
export interface PrintSecondLevelAppealFailureAction {
  type: typeof PRINT_SECOND_LEVEL_APPEAL_FAILURE;
  error: Error | string;
}

export const DOWNLOAD_HEALTH_CLAIM_FILE = "healthClaimActionTypes/DOWNLOAD_HEALTH_CLAIM_FILE";
export interface DownloadHealthClaimFileAction {
  type: typeof DOWNLOAD_HEALTH_CLAIM_FILE;
  fileId: string;
  name: string;
}

export const DOWNLOAD_HEALTH_CLAIM_FILE_REQUEST = "healthClaimActionTypes/DOWNLOAD_HEALTH_CLAIM_FILE_REQUEST";
export interface DownloadHealthClaimFileRequestAction {
  type: typeof DOWNLOAD_HEALTH_CLAIM_FILE_REQUEST;
}

export const DOWNLOAD_HEALTH_CLAIM_FILE_SUCCESS = "healthClaimActionTypes/DOWNLOAD_HEALTH_CLAIM_FILE_SUCCESS";
export interface DownloadHealthClaimFileSuccessAction {
  type: typeof DOWNLOAD_HEALTH_CLAIM_FILE_SUCCESS;
}

export const DOWNLOAD_HEALTH_CLAIM_FILE_RESET = "healthClaimActionTypes/DOWNLOAD_HEALTH_CLAIM_FILE_RESET";
export interface DownloadHealthClaimFileResetAction {
  type: typeof DOWNLOAD_HEALTH_CLAIM_FILE_RESET;
}

export const DOWNLOAD_HEALTH_CLAIM_FILE_FAILURE = "healthClaimActionTypes/DOWNLOAD_HEALTH_CLAIM_FILE_FAILURE";
export interface DownloadHealthClaimFileFailureAction {
  type: typeof DOWNLOAD_HEALTH_CLAIM_FILE_FAILURE;
  error: Error | string;
}

export const GET_DOCUMENT_LIST = "healthClaimActionTypes/GET_DOCUMENT_LIST";
export interface GetDocumentListAction {
  type: typeof GET_DOCUMENT_LIST;
  claimId: string;
}

export const GET_DOCUMENT_LIST_REQUEST = "healthClaimActionTypes/GET_DOCUMENT_LIST_REQUEST";
export interface GetDocumentListRequestAction {
  type: typeof GET_DOCUMENT_LIST_REQUEST;
}

export const GET_DOCUMENT_LIST_SUCCESS = "healthClaimActionTypes/GET_DOCUMENT_LIST_SUCCESS";
export interface GetDocumentListSuccessAction {
  type: typeof GET_DOCUMENT_LIST_SUCCESS;
  data: Nomen[];
}

export const GET_DOCUMENT_LIST_RESET = "healthClaimActionTypes/GET_DOCUMENT_LIST_RESET";
export interface GetDocumentListResetAction {
  type: typeof GET_DOCUMENT_LIST_RESET;
}

export const GET_DOCUMENT_LIST_FAILURE = "healthClaimActionTypes/GET_DOCUMENT_LIST_FAILURE";
export interface GetDocumentListFailureAction {
  type: typeof GET_DOCUMENT_LIST_FAILURE;
  error: Error | string;
}

export const UPLOAD_HEALTH_CLAIM_FILE = "healthClaimActionTypes/UPLOAD_HEALTH_CLAIM_FILE";
export interface UploadHealthClaimFileAction {
  type: typeof UPLOAD_HEALTH_CLAIM_FILE;
  data: UploadHealthClaimFile;
}

export const UPLOAD_HEALTH_CLAIM_FILE_REQUEST = "healthClaimActionTypes/UPLOAD_HEALTH_CLAIM_FILE_REQUEST";
export interface UploadHealthClaimFileRequestAction {
  type: typeof UPLOAD_HEALTH_CLAIM_FILE_REQUEST;
}

export const UPLOAD_HEALTH_CLAIM_FILE_SUCCESS = "healthClaimActionTypes/UPLOAD_HEALTH_CLAIM_FILE_SUCCESS";
export interface UploadHealthClaimFileSuccessAction {
  type: typeof UPLOAD_HEALTH_CLAIM_FILE_SUCCESS;
  data: boolean;
}

export const UPLOAD_HEALTH_CLAIM_FILE_FAILURE = "healthClaimActionTypes/UPLOAD_HEALTH_CLAIM_FILE_FAILURE";
export interface UploadHealthClaimFileFailureAction {
  type: typeof UPLOAD_HEALTH_CLAIM_FILE_FAILURE;
  error: Error | string;
}

export type HealthClaimAction =
  | SearchHealthClaimAction
  | SearchHealthClaimRequestAction
  | SearchHealthClaimSuccessAction
  | SearchHealthClaimResetAction
  | SearchHealthClaimFailureAction
  | HealthClaimPreviewAction
  | HealthClaimPreviewRequestAction
  | HealthClaimPreviewSuccessAction
  | HealthClaimPreviewResetAction
  | HealthClaimPreviewFailureAction
  | ExportSearchHealthClaimAction
  | ExportSearchHealthClaimRequestAction
  | ExportSearchHealthClaimSuccessAction
  | ExportSearchHealthClaimResetAction
  | ExportSearchHealthClaimFailureAction
  | ShowHealthClaimModalAction
  | HealthClaimPreviewFirstLevelAction
  | HealthClaimPreviewFirstLevelRequestAction
  | HealthClaimPreviewFirstLevelSuccessAction
  | HealthClaimPreviewFirstLevelResetAction
  | HealthClaimPreviewFirstLevelFailureAction
  | HealthClaimPreviewSecondLevelAction
  | HealthClaimPreviewSecondLevelRequestAction
  | HealthClaimPreviewSecondLevelSuccessAction
  | HealthClaimPreviewSecondLevelResetAction
  | HealthClaimPreviewSecondLevelFailureAction
  | ShowHealthClaimFirstLevelModalAction
  | ShowHealthClaimSecondLevelModalAction
  | ReadFirstLevelAppealAction
  | ReadFirstLevelAppealRequestAction
  | ReadFirstLevelAppealSuccessAction
  | ReadFirstLevelAppealResetAction
  | ReadFirstLevelAppealFailureAction
  | ReadSecondLevelAppealAction
  | ReadSecondLevelAppealRequestAction
  | ReadSecondLevelAppealSuccessAction
  | ReadSecondLevelAppealResetAction
  | ReadSecondLevelAppealFailureAction
  | ShowReadFirstLevelAppealModalAction
  | ShowReadSecondLevelAppealModalAction
  | MarkAppealAsReadAction
  | MarkAppealAsReadRequestAction
  | MarkAppealAsReadSuccessAction
  | MarkAppealAsReadResetAction
  | MarkAppealAsReadFailureAction
  | PrintFirstLevelAppealAction
  | PrintFirstLevelAppealRequestAction
  | PrintFirstLevelAppealSuccessAction
  | PrintFirstLevelAppealResetAction
  | PrintFirstLevelAppealFailureAction
  | FileFirstLevelAppealAction
  | FileFirstLevelAppealRequestAction
  | FileFirstLevelAppealSuccessAction
  | FileFirstLevelAppealFailureAction
  | FileSecondLevelAppealAction
  | FileSecondLevelAppealRequestAction
  | FileSecondLevelAppealSuccessAction
  | FileSecondLevelAppealFailureAction
  | PrintSecondLevelAppealRequestAction
  | PrintSecondLevelAppealSuccessAction
  | PrintSecondLevelAppealResetAction
  | PrintSecondLevelAppealFailureAction
  | FileSecondLevelAppealFailureAction
  | DownloadHealthClaimFileAction
  | DownloadHealthClaimFileRequestAction
  | DownloadHealthClaimFileSuccessAction
  | DownloadHealthClaimFileResetAction
  | DownloadHealthClaimFileFailureAction
  | GetDocumentListAction
  | GetDocumentListRequestAction
  | GetDocumentListSuccessAction
  | GetDocumentListResetAction
  | GetDocumentListFailureAction
  | UploadHealthClaimFileAction
  | UploadHealthClaimFileRequestAction
  | UploadHealthClaimFileSuccessAction
  | UploadHealthClaimFileFailureAction;
