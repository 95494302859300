import React, { useEffect, useState } from "react";
import {
    Button,
    Modal,
    ModalProps
} from "react-bootstrap";
import { connect, useSelector } from 'react-redux';
import { AppState } from '../../../../redux/reducers/rootReducer';
import { Loading2 } from '../../../Layout/Loading2';
import * as actionTypes from '../../../../redux/actionTypes/healthClaimActionTypes';
import * as actions from '../../../../redux/actionCreators/healthPreClaimActionCreators';
import { HealthClaimPreview } from "../../../../utils/healthPreClaimTypes";
import { HealthPreClaimInvoice, HealthPreClaimInvoiceRecords } from "../../../../utils/healthPreClaimTypes";
import store from "../../../../redux/configureStore";
import { Nomen } from "../../../../utils";
import { Formik, FormikProps } from "formik";
import { makeStyles } from "@material-ui/core";
import { Dispatch } from "redux";
import { DropzoneArea } from "material-ui-dropzone";
import { prepareClaimFile } from "../../../../utils/claimTypes";

type Props = {
    header: string;
    text: string;
    onClose: () => void;
    onSubmit(claimId: string, files: any): void;
    onDownload(fileId: string, name: string): void;
}

interface FormValues {
    healthClaimFiles: any[] | null;
}

const initialValues = {
    healthClaimFiles: []
};

const HealthClaimModal = (props: FormikProps<FormValues> & ModalProps & Props) => {
    const healthClaim = useSelector<AppState, HealthClaimPreview | null>((state: any) => state.healthClaim.selectedHealthClaim);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.HEALTH_CLAIM_PREVIEW]
        || state.isLoading[actionTypes.GET_DOCUMENT_LIST]
        || state.isLoading[actionTypes.UPLOAD_HEALTH_CLAIM_FILE]
        || state.isLoading[actionTypes.DOWNLOAD_HEALTH_CLAIM_FILE]);

    const isLoadingMarkingAppeal = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.MARK_APPEAL_AS_READ]);
    const healthClaimDocuments = useSelector<AppState, Nomen[] | null>((state: any) => state.healthClaim.healthClaimDocuments);

    const [key, setKey] = useState(0);

    const formatAmount = (value: any) => {
        if (value !== null && value !== undefined && value !== '') {
            return value.toFixed(3);
        }
        return value;
    }

    const getDate = (value: any) => {
        if (!value || value === "01.01.0001") {
            return '';
        }
        return value;
    }

    const downloadDocument = (e: any, value: Nomen) => {
        e.preventDefault();
        props.onDownload(value.code, value.name);
    }

    const useStyles = makeStyles(() => ({
        dropZone: {
            minHeight: '60px',
        },
        previewContainer: {
            container: 'true',
            width: '100%',
            height: '100%',
        },
        preview: {
            height: '100%',
            xs: '12',
        }
    }));

    const classes = useStyles();

    const handleSubmit = async (values: any) => {
        if (!healthClaim || !values || !values.healthClaimFiles || values.healthClaimFiles.length === 0)
            return;

        props.onSubmit(healthClaim.claimId, values.healthClaimFiles);
        setKey(key + 1);
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {healthClaim !== null ? "Statusi dhe historiati i kerkesave për rimbursim    " + healthClaim.claimNumber : "Procesoj..."}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="claimModal">
                {isLoading || isLoadingMarkingAppeal ? <Loading2 />
                    :
                    <>
                        {healthClaim !== null && <>
                            <h6 className="font-weight-bolder mb-3">          {"Numri i policës: " + healthClaim.policy_code} </h6>
                            <div className="text-dark-50 line-height-lg">

                                <div className="reviewTitle">Data e aksidentit</div><div className="reviewValue"> {healthClaim.date_accident}</div>
                                <div className="reviewTitle">Klasa e demit</div><div className="reviewValue">{healthClaim.class}</div>

                                <br />
                                <div className="reviewTitle">Data e raportimit</div><div className="reviewValue"> {healthClaim.date_reported}</div>
                                <div className="reviewTitle">Statusi</div><div className="reviewValue">{healthClaim.status}</div>

                                <br />
                                <div className="reviewTitle">Data e refuzuar</div><div className="reviewValue"> {getDate(healthClaim.date_rejected)}</div>
                                <div className="reviewTitle">Shuma e kërkuar </div><div className="reviewValue">(€){formatAmount(healthClaim.amount_requestedv)}</div>

                                <br />
                                <div className="reviewTitle">Përshkrimi i aksidentit </div><div className="reviewValue"> {healthClaim.description_accident}</div>
                                <div className="reviewTitle">Shuma e miratuar </div><div className="reviewValue">(€){formatAmount(healthClaim.approved_amountv)}</div>

                                <br />
                                <div className="reviewTitle">Përshkrimi i dëmit </div><div className="reviewValue">{healthClaim.description_damage}</div>
                                <div className="reviewTitle">Shuma e paguar </div><div className="reviewValue">(€){formatAmount(healthClaim.paid_amountv)}</div>
                                <br />


                                <br />
                            </div>
                            {healthClaim.damagedPerson !== null && (healthClaim.damagedPerson.tipkind === "3" || healthClaim.damagedPerson.tipkind === "2")
                                && <><div className="separator separator-dashed my-5"></div>
                                    <h6 className="font-weight-bolder mb-3"> I Dëmtuari: </h6></>}
                            {healthClaim.damagedPerson !== null && healthClaim.damagedPerson.tipkind === "3" && <div className="text-dark-50 line-height-lg">

                                <div className="reviewTitle">Numri personal</div><div className="reviewValue"> {healthClaim.damagedPerson.clientno}</div>
                                <div className="reviewTitle">Adresa</div><div className="reviewValue"> {healthClaim.damagedPerson.address},{healthClaim.damagedPerson.place.name}</div>
                                <br />
                                <div className="reviewTitle">Emri Mbiemri</div><div className="reviewValue"> {healthClaim.damagedPerson.title} {healthClaim.damagedPerson.lastname}</div>
                                <div className="reviewTitle">Nr. telefonit</div><div className="reviewValue"> {healthClaim.damagedPerson.celphone}</div>
                                <br />
                                <div className="reviewTitle">Pasaporta</div><div className="reviewValue">{healthClaim.damagedPerson.passport}</div>
                                <div className="reviewTitle">Datëlindja</div><div className="reviewValue">{healthClaim.damagedPerson.birthdate}</div>
                            </div>}
                            {healthClaim.damagedPerson !== null && healthClaim.damagedPerson.tipkind === "2" && <div className="text-dark-50 line-height-lg">
                                <div className="reviewTitle">Nipti</div><div className="reviewValue"> {healthClaim.damagedPerson.nipt}</div>
                                <div className="reviewTitle">Adresa</div><div className="reviewValue"> {healthClaim.damagedPerson.address},{healthClaim.damagedPerson.place.name}</div>
                                <br />
                                <div className="reviewTitle">Emri</div><div className="reviewValue"> {healthClaim.damagedPerson.title} {healthClaim.damagedPerson.lastname}</div>
                            </div>}
                            {healthClaim.damagedVehicle !== null && healthClaim.damagedVehicle.regnum !== null && <><div className="separator separator-dashed my-5"></div>
                                <h6 className="font-weight-bolder mb-3">I Dëmtuari - Automjeti: </h6>
                                <div className="text-dark-50 line-height-lg">
                                    <div className="reviewTitle">Targa</div><div className="reviewValue"> {healthClaim.damagedVehicle.regnum}</div>
                                    <div className="reviewTitle">Prodhuesi/Modeli</div><div className="reviewValue"> {healthClaim.damagedVehicle.mark.name} - {healthClaim.damagedVehicle.tip}</div>

                                    <br />
                                    <div className="reviewTitle">Numri i shasisë</div><div className="reviewValue">{healthClaim.damagedVehicle.chassis}</div>
                                    <div className="reviewTitle">Viti i prodhimit</div><div className="reviewValue">{healthClaim.damagedVehicle.prodyear}</div>

                                    <br />
                                    <div className="reviewTitle">Pesha e automjetit</div><div className="reviewValue">{healthClaim.damagedVehicle.capacity}</div>
                                    <div className="reviewTitle">Ngjyra e automjetit</div><div className="reviewValue">  {healthClaim.damagedVehicle.colour}</div>
                                    <br />
                                    <div className="reviewTitle">Fuqia(kw)</div><div className="reviewValue"> {healthClaim.damagedVehicle.power}</div><br />
                                </div>
                            </>}

                            {healthClaim.insuredPerson !== null && (healthClaim.insuredPerson.tipkind === "3" || healthClaim.insuredPerson.tipkind === "2")
                                && <><div className="separator separator-dashed my-5"></div>
                                    <h6 className="font-weight-bolder mb-3"> I Siguruari: </h6></>}
                            {healthClaim.insuredPerson !== null && healthClaim.insuredPerson.tipkind === "3" && <div className="text-dark-50 line-height-lg">

                                <div className="reviewTitle">Numri personal</div><div className="reviewValue"> {healthClaim.insuredPerson.clientno}</div>
                                <div className="reviewTitle">Adresa</div><div className="reviewValue"> {healthClaim.insuredPerson.address},{healthClaim.insuredPerson.place.name}</div>
                                <br />
                                <div className="reviewTitle">Emri Mbiemri</div><div className="reviewValue"> {healthClaim.insuredPerson.title} {healthClaim.insuredPerson.lastname}</div>
                                <div className="reviewTitle">Nr. telefonit</div><div className="reviewValue"> {healthClaim.insuredPerson.celphone}</div>
                                <br />
                                <div className="reviewTitle">Pasaporta</div><div className="reviewValue">{healthClaim.insuredPerson.passport}</div>
                                <div className="reviewTitle">Datëlindja</div><div className="reviewValue">{healthClaim.insuredPerson.birthdate}</div>
                            </div>}
                            {healthClaim.insuredPerson !== null && healthClaim.insuredPerson.tipkind === "2" && <div className="text-dark-50 line-height-lg">
                                <div className="reviewTitle">Nipti</div><div className="reviewValue"> {healthClaim.insuredPerson.nipt}</div>
                                <div className="reviewTitle">Adresa</div><div className="reviewValue"> {healthClaim.insuredPerson.address},{healthClaim.insuredPerson.place.name}</div>
                                <br />
                                <div className="reviewTitle">Emri</div><div className="reviewValue"> {healthClaim.insuredPerson.title} {healthClaim.insuredPerson.lastname}</div>
                            </div>}
                            {healthClaim.insuredVehicle !== null && healthClaim.insuredVehicle.regnum !== null && <><div className="separator separator-dashed my-5"></div>
                                <h6 className="font-weight-bolder mb-3">I Siguruari - Automjeti: </h6>
                                <div className="text-dark-50 line-height-lg">
                                    <div className="reviewTitle">Targa</div><div className="reviewValue"> {healthClaim.insuredVehicle.regnum}</div>
                                    <div className="reviewTitle">Prodhuesi/Modeli</div><div className="reviewValue"> {healthClaim.insuredVehicle.mark.name} - {healthClaim.insuredVehicle.tip}</div>

                                    <br />
                                    <div className="reviewTitle">Numri i shasisë</div><div className="reviewValue">{healthClaim.insuredVehicle.chassis}</div>
                                    <div className="reviewTitle">Viti i prodhimit</div><div className="reviewValue">{healthClaim.insuredVehicle.prodyear}</div>

                                    <br />
                                    <div className="reviewTitle">Pesha e automjetit</div><div className="reviewValue">{healthClaim.insuredVehicle.capacity}</div>
                                    <div className="reviewTitle">Ngjyra e automjetit</div><div className="reviewValue">  {healthClaim.insuredVehicle.colour}</div>
                                    <br />
                                    <div className="reviewTitle">Fuqia(kw)</div><div className="reviewValue"> {healthClaim.insuredVehicle.power}</div><br />
                                </div>
                            </>}
                            {healthClaim.beneficiaryPerson !== null && (healthClaim.beneficiaryPerson.tipkind === "3" || healthClaim.beneficiaryPerson.tipkind === "2")
                                && <><div className="separator separator-dashed my-5"></div>
                                    <h6 className="font-weight-bolder mb-3"> Perfituesi: </h6></>}
                            {healthClaim.beneficiaryPerson !== null && healthClaim.beneficiaryPerson.tipkind === "3" && <div className="text-dark-50 line-height-lg">
                                <div className="reviewTitle">Numri personal</div><div className="reviewValue"> {healthClaim.beneficiaryPerson.clientno}</div>
                                <div className="reviewTitle">Adresa</div><div className="reviewValue"> {healthClaim.beneficiaryPerson.address},{healthClaim.beneficiaryPerson.place.name}</div>
                                <br />
                                <div className="reviewTitle">Emri Mbiemri</div><div className="reviewValue"> {healthClaim.beneficiaryPerson.title} {healthClaim.beneficiaryPerson.lastname}</div>
                                <div className="reviewTitle">Nr. telefonit</div><div className="reviewValue"> {healthClaim.beneficiaryPerson.celphone}</div>
                                <br />
                                <div className="reviewTitle">Email</div><div className="reviewValue">{healthClaim.beneficiaryPerson.email}</div>
                                <div className="reviewTitle">Datëlindja</div><div className="reviewValue">{getDate(healthClaim.beneficiaryPerson.birthdate)}</div>
                                <br />
                                <div className="reviewTitle">Banka</div><div className="reviewValue">{healthClaim.beneficiaryPerson.bank?.name}</div>
                                <div className="reviewTitle">Llogari bankare</div><div className="reviewValue">{healthClaim.beneficiaryPerson.bankAccount}</div>
                            </div>}
                            {healthClaim.beneficiaryPerson !== null && healthClaim.beneficiaryPerson.tipkind === "2" && <div className="text-dark-50 line-height-lg">
                                <div className="reviewTitle">Nipti</div><div className="reviewValue"> {healthClaim.beneficiaryPerson.nipt}</div>
                                <div className="reviewTitle">Adresa</div><div className="reviewValue"> {healthClaim.beneficiaryPerson.address},{healthClaim.beneficiaryPerson.place.name}</div>
                                <br />
                                <div className="reviewTitle">Emri</div><div className="reviewValue"> {healthClaim.beneficiaryPerson.title} {healthClaim.beneficiaryPerson.lastname}</div>
                                <br />
                                <div className="reviewTitle">Banka</div><div className="reviewValue">{healthClaim.beneficiaryPerson.bank?.name}</div>
                                <div className="reviewTitle">Llogari bankare</div><div className="reviewValue">{healthClaim.beneficiaryPerson.bankAccount}</div>
                            </div>}

                            {healthClaim.invoices !== null && healthClaim.invoices.length > 0
                                && <><div className="separator separator-dashed my-5"></div>
                                    <h6 className="font-weight-bolder mb-3"> Faturat: </h6>

                                    {healthClaim.invoices.map((invoice: HealthPreClaimInvoice, index: number) => (
                                        <div key={index} className="text-dark-50 line-height-lg">
                                            <div className="reviewTitle">Numri i fiskal</div><div className="reviewValue"> {invoice.invoicenr}</div>
                                            <div className="reviewTitle">Shuma e faturës</div><div className="reviewValue"> (€) {formatAmount(invoice.invoicedAmountV)}</div>
                                            <br />
                                            <div className="reviewTitle">Numri kuponit fiskal</div><div className="reviewValue"> {invoice.invoiceFiscalnr}</div>
                                            <div className="reviewTitle">Shuma e miratuar </div><div className="reviewValue">(€) {formatAmount(invoice.approvedAmountV)}</div>
                                            <br />
                                            <div className="reviewTitle">Data e faturës</div><div className="reviewValue"> {invoice.invoicedate}</div>
                                            <div className="reviewTitle">Spitali</div><div className="reviewValue"> {invoice.hospitalName}</div>
                                            <br />
                                            <div className="reviewTitle">Shteti</div><div className="reviewValue"> {invoice.countryEvent.name}</div>
                                            <div className="reviewTitle">Vrejtje</div><div className="reviewValue"> {invoice.comment}</div>

                                            <div className="invoiceRecords">
                                                {invoice.records.map((record: HealthPreClaimInvoiceRecords, indexRec: number) => (
                                                    <ul key={indexRec}>
                                                        <li>
                                                            <div className="reviewTitle">Mbulimi</div><div className="reviewValue"> {record.covercategory.name}</div>
                                                            <div className="reviewTitle">Shuma (€)</div><div className="reviewValue"> {formatAmount(record.invoicedAmountV)}</div>
                                                            <br />
                                                            <div className="reviewTitle"></div><div className="reviewValue"> {record.covergroup?.name}</div>
                                                            <div className="reviewTitle">Trajtimi</div><div className="reviewValue"> {record.treating}</div>
                                                        </li>
                                                    </ul>
                                                ))}
                                            </div>
                                            <div className="separator separator-dashed my-5"></div>
                                        </div>
                                    ))}</>}

                            {healthClaimDocuments !== null && healthClaimDocuments.length > 0
                                && <>
                                    <h6 className="font-weight-bolder mb-3"> Dokumentet e ngarkuar: </h6>

                                    {healthClaimDocuments.map((document: Nomen, index: number) => (
                                        <div key={index} className="text-dark-50 line-height-lg">
                                            <a href="#" title="Shiko" onClick={(e: any) => downloadDocument(e, document)}>{document.name}</a>
                                        </div>
                                    ))}</>}

                            <div className="separator separator-dashed my-5"></div>
                            <Formik
                                enableReinitialize
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                            >
                                {({ setFieldValue,
                                    handleSubmit }) => (
                                    <form onSubmit={(e: any) => {
                                        handleSubmit(e);
                                    }}>

                                        <DropzoneArea
                                            key={key}
                                            dropzoneClass={classes.dropZone}
                                            dropzoneText='Ngarko dokumente'
                                            onChange={(files: any) => { setFieldValue('healthClaimFiles', files) }}
                                            onDelete={(files: any) => { setFieldValue('healthClaimFiles', files) }}
                                            acceptedFiles={[
                                                'application/pdf',
                                                'application/msword',
                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                                'application/vnd.ms-excel',
                                                'text/plain',
                                                'image/jpeg',
                                                'image/png',
                                                'image/jpeg']}
                                            filesLimit={20}
                                            maxFileSize={10485760}
                                            showPreviews={true}
                                            showPreviewsInDropzone={false}
                                            showFileNamesInPreview={true}
                                            showFileNames={true}
                                            initialFiles={[]}
                                            previewGridClasses={{
                                                container: classes.previewContainer,
                                                item: classes.preview
                                            }}
                                            getPreviewIcon={(file: any, classes: any) => {
                                                return (
                                                    <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M14 0C16.7614 0 19 2.23858 19 5V17C19 20.866 15.866 24 12 24C8.13401 24 5 20.866 5 17V9H7V17C7 19.7614 9.23858 22 12 22C14.7614 22 17 19.7614 17 17V5C17 3.34315 15.6569 2 14 2C12.3431 2 11 3.34315 11 5V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V6H15V17C15 18.6569 13.6569 20 12 20C10.3431 20 9 18.6569 9 17V5C9 2.23858 11.2386 0 14 0Z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                );
                                            }}
                                        />

                                        <div className="pb-lg-0 pb-5 pull-right">
                                            <button
                                                id="kt_login_form_modal_submit_button"
                                                type="submit"
                                                disabled={false}
                                                className={`btn btn-light-primary px-6 font-weight-bold mt20 mb20`}
                                            >
                                                <span>Ruaj</span>
                                            </button>
                                        </div>
                                    </form >
                                )}
                            </Formik>

                        </>}
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onClose}>Dalje</Button>
            </Modal.Footer>
        </Modal >
    )
};

const mapDispatchToProps = (dispatch: Dispatch<actionTypes.UploadHealthClaimFileAction | actionTypes.DownloadHealthClaimFileAction>) => ({
    onSubmit: (claimId: string, files: any) => {
        if (!files || files.length === 0)
            return;

        const customerId = store.getState().auth.user?.customerId;
        const data = prepareClaimFile(customerId, claimId, files);
        dispatch(actions.uploadHealthClaimFile(data));
    },
    onDownload: (fileId: string, name: string) => {
        dispatch(actions.downloadHealthClaimFile(fileId, name));
    }
});

const mapStateToProps = (state: any) => ({
    isLoading: state.isLoading[actionTypes.UPLOAD_HEALTH_CLAIM_FILE],
    error: state.error[actionTypes.UPLOAD_HEALTH_CLAIM_FILE]
});

export default connect(mapStateToProps, mapDispatchToProps)(HealthClaimModal);