import * as React from 'react';

export function Loading2() {
    return (
        <>
            <div className="d-flex align-items-center">
                <div className="mr-2 text-muted">Procesoj...</div>
                <div className="spinner spinner-primary mr-10"></div>
            </div>
        </>
    );
}