import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import RadioButtonGroupFieldMain from '../../Custom/RadioButtonGroupFieldMain';
import InputFieldMain from '../../Custom/InputFieldMain';
import DatePickerFieldMain from '../../Custom/DatePickerFieldMain';
import SelectField from '../../Custom/SelectField';
import { Gender } from '../../../utils/nomen';
import { AppState } from '../../../redux/reducers/rootReducer';
import { Nomen } from '../../../utils';

const InsuredPerson = () => {
    const { values }: any = useFormikContext();
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const isPhysical = values.tipKind === '3';

    return (<>
        <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
            <div className="row">
                <div className="col-xl-6">
                    <RadioButtonGroupFieldMain name="tipKind" type="radio" placeholder="Tip Kind" label="Tip" items={[{ id: '3', value: 'Fizik' }, { id: '2', value: 'Juridik' }]} />
                </div>
                {!isPhysical && <div className="col-xl-6">
                    <InputFieldMain type="text" name="nipt" label="Nipti" placeholder="Nipti" />
                </div>}
                {isPhysical && <div className="col-xl-6">
                    <InputFieldMain type="text" name="clientNo" label="Numri personal" placeholder="Numri personal" maxLength={16} />
                </div>}
            </div>
            <div className="row">
                <div className="col-xl-6">
                    <InputFieldMain type="text" name="title" label="Emri" placeholder="Emri" capitalized />
                </div>
                {isPhysical && <div className="col-xl-6">
                    <InputFieldMain type="text" name="lastName" label="Mbiemri" placeholder="Mbiemri" capitalized />
                </div>}

                {!isPhysical && <div className="col-xl-6">
                    <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" />
                </div>}

            </div>

            <div className="row">
                {isPhysical && <div className="col-xl-6">
                    <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" />
                </div>}
                {!isPhysical &&
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="celphone" label="Nr. telefonit" placeholder="Nr. telefonit" />
                    </div>}
                <div className="col-xl-6">
                    <SelectField name="place" label="Vendi" items={places} />
                </div>
            </div>
            {isPhysical && <div className="row">
                <div className="col-xl-6">
                    <SelectField name="sex" label="Gjinia" items={Gender} />
                </div>
                <div className="col-xl-6">
                    <InputFieldMain type="text" name="celphone" label="Nr. telefonit" placeholder="Nr. telefonit" />
                </div>
            </div>}
            <div className="row">
                <div className="col-xl-6">
                </div>
                {isPhysical && <div className="col-xl-6 addSpace">
                    <DatePickerFieldMain name="birthDate" type="text" placeholder="Datëlindja" />
                </div>}
            </div>
        </div>
    </>
    );
}
export default connect()(InsuredPerson);
