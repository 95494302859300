import * as React from 'react';
import steps from './steps';

type Props = {
    currentStep: string;
}

const Nav = (props: Props) => {
    const currentIndex = steps.findIndex(step => step.id === props.currentStep);
    return (
        <div className="wizard-nav">
            <h3 className="menu-heading menu-toggle">
                <span className="menu-text card-text">Sigurimi i shëndetit</span>
            </h3>
            <div className="ks-nav">
                <div className="wizard-steps">
                    {steps.map((step, index) => {
                        return (<div className="wizard-step-ks" key={step.id} data-wizard-type="step" data-wizard-state={index === currentIndex ? "current" : index < currentIndex ? "done" : ""}>
                            <div className="wizard-wrapper">
                                <div className={"wizard-number"}>
                                    {index >= currentIndex ? index + 1 : <span>&#10003;</span>}
                                </div>
                                <div className="wizard-label">
                                    <div className="wizard-title">
                                        {step.name}
                                    </div>
                                    <div className="wizard-desc">
                                        {step.description}
                                        <hr style={{ width: "15%" }} />
                                    </div>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>
            </div>
        </div>
    );
}


export default Nav;