import { ClaimGridItem, PreClaim, PreClaimGridItem, PreClaimPerson, PreClaimPreview, PreClaimRegisterData, PreClaimVehicle } from './../../utils/claimTypes';
import ApiClient from '../../infrastructure/ApiClient';

export async function registerPreClaim(
  formData: FormData
): Promise<PreClaimRegisterData> {
  return await ApiClient.post('/api/claim/registerPreClaim', formData);
}

export async function searchPreClaim(
  data: any
): Promise<PreClaimGridItem[]> {
  return await ApiClient.post('/api/claim/searchPreClaim', data);
}

export async function searchClaim(
  data: any
): Promise<ClaimGridItem[]> {
  return await ApiClient.post('/api/claim/searchClaim', data);
}

export async function previewClaim(
  claimId: string
): Promise<ClaimGridItem> {
  return await ApiClient.get('/api/claim/previewClaim', { claimId });
}

export async function getPreClaim(
  code: string
): Promise<PreClaimPreview> {
  return await ApiClient.get('/api/claim/preClaimPreview', { code });
}

export async function fetchVehicleFromPolicy(
  policyType: string,
  policyCode: string
): Promise<PreClaimVehicle> {
  return await ApiClient.get('/api/claim/fetchVehicleFromPolicy', { policyType, policyCode });
}

export async function fetchInsuredPersonFromPolicy(
  policyType: string,
  policyCode: string,
  customerId: string
): Promise<PreClaimPerson> {
  return await ApiClient.get('/api/claim/fetchInsuredPersonFromPolicy', { policyType, policyCode, customerId });
}

export async function registerHealthPreClaim(
  formData: FormData
): Promise<PreClaimRegisterData> {
  return await ApiClient.post('/api/claim/registerHealthPreClaim', formData);
}

export async function registerPropertyPreClaim(
  formData: FormData
): Promise<PreClaimRegisterData> {
  return await ApiClient.post('/api/claim/registerPropertyPreClaim', formData);
}

export async function searchClaimHistory(
  data: any
): Promise<ClaimGridItem[]> {
  return await ApiClient.post('/api/claim/SearchClaimHistory', data);
}

export async function downloadClaimHistoryDocument(
  data: any
): Promise<any> {
  return await ApiClient.post('/api/claim/DownloadClaimHistoryDocument', data, true);
}

export async function downloadBonusMalusDocument(
  customerId: string,
  plateNumber: string,
  chassis: string
): Promise<any> {
  return await ApiClient.get('/api/claim/DownloadBonusMalusDocument', { customerId, plateNumber, chassis }, true);
}

export async function sendClaimHistoryMail(
  data: any
): Promise<any> {
  return await ApiClient.post('/api/claim/ClaimHistorySendMail', data);
}

export async function exportSearchPreClaim(
  data: any
): Promise<any> {
  return await ApiClient.post('/api/claim/ExportSearchPreClaim', data, true);
}

export async function getPersonById(
  id: string
): Promise<PreClaimPerson> {
  return await ApiClient.get('/api/claim/GetPersonById', { id });
}

export async function getCompanyById(
  id: string
): Promise<PreClaimPerson> {
  return await ApiClient.get('/api/claim/GetCompanyById', { id });
}

export async function getVehicleByPlateNumber(
  plateNumber: string
): Promise<PreClaimVehicle> {
  return await ApiClient.get('/api/claim/GetVehicleByPlateNumber', { plateNumber });
}

export async function getVehicleByChassis(
  chassis: string
): Promise<PreClaimVehicle> {
  return await ApiClient.get('/api/claim/GetVehicleByChassis', { chassis });
}

export async function fetchKaskoDataFromPolicy(
  policyCode: string
): Promise<PreClaim> {
  return await ApiClient.get('/api/claim/FetchKaskoDataFromPolicy', { policyCode });
}

export async function downloadPreviewClaimDocument(
  claimId: string,
): Promise<any> {
  return await ApiClient.get('/api/claim/DownloadPreviewClaim', { claimId }, true);
}