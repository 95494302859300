import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { useFormikWizard } from 'formik-wizard';
import { Loading } from '../../../Layout/Loading';
import moment from 'moment';
import CheckboxField from '../../../Custom/CheckboxField';
import * as authActionTypes from '../../../../redux/actionTypes/authActionTypes';
import * as policyActionTypes from '../../../../redux/actionTypes/thPolicyActionTypes';
import { AppState } from '../../../../redux/reducers/rootReducer';
import { Nomen } from '../../../../utils';
import { Gender } from '../../../../utils/nomen';
import { ThInsQuotation } from '../../../../utils/policyTypes';
import PdfTravel from "../../../../../src/InsuranceConditions/05.pdf";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import CaptchaGenerator from '../../../Custom/CaptchaGenerator';
import { useFormikContext } from "formik";
import InputField from '../../../Custom/InputField';

const Verification = () => {
    const { values: wizardValues }: any = useFormikWizard();
    const { setFieldValue } = useFormikContext();
    const isLoadingHasProfile = useSelector<AppState, boolean>((state: any) => state.isLoading[authActionTypes.HAS_PROFILE]);
    const isLoadingBookPolicy = useSelector<AppState, boolean>((state: any) => state.isLoading[policyActionTypes.BOOK_TH_POLICY]);
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const countries = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Countries);
    const chosenTHQuotation = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.chosenTHQuotation);
    const thPackageQuotation = chosenTHQuotation ? chosenTHQuotation[0] : null;
    const travelDetails = wizardValues.thMain;
    const travellers = wizardValues.insertTravelers.travellers;
    const totalTravellers = travellers.length;

    const useStyles = makeStyles(theme => ({
        customTooltip: {
            backgroundColor: '#3699FF',
            fontSize: '10px',
            opacity: '0.6 !important'
        }
    }));

    const classes = useStyles();

    const result = (text: string) => {
        setFieldValue('captchaGenerated', text);
    }

    return (<>
        {isLoadingHasProfile
            ? <Loading />
            : <>
                {isLoadingBookPolicy && <Loading />}
                {travelDetails &&
                    <div className="text-dark-50 line-height-lg">
                        <div className="wizardTitle mb10">Verifikoni detajet e udhëtimit</div>
                        <div className="verifyTitle">Fillon</div><div className="verifyValue"> {moment(travelDetails.startDate).format('DD/MM/yyyy')}</div>
                        <div className="verifyTitle">Mbaron</div><div className="verifyValue">{moment(travelDetails.endDate).format('DD/MM/yyyy')}</div>
                        <div className="verifyTitle">Zona e udhëtimit</div><div className="verifyValue"> {travelDetails.zone}</div>
                        <div className="verifyTitle">Vendi</div><div className="verifyValue"> {countries.filter((p: Nomen) => p.code === travelDetails.country)[0]?.name}</div>
                        <div className="verifyTitle">Numri i anetareve te familjes qe deshironi ti siguroni</div><div className="verifyValue"> {totalTravellers}</div>
                        {/*<div className="verifyTitle">Kupon</div><div className="verifyValue"> {travelDetails.coupon ? travelDetails.coupon : '/'}</div>*/}
                    </div>}

                {travellers && travellers.length > 0 &&
                    <div className="text-dark-50 line-height-lg">
                        <div className="wizardTitle mt20 mb10">Verifikoni të dhënat e udhëtarëve</div>

                        {travellers.map((traveller: any, indexT: number) => (
                            <div key={indexT + 1}>
                                <div className="verifyTitle">Numri personal</div><div className="verifyValue"> {traveller.clientNo}</div>
                                <div className="verifyTitle">Emri</div><div className="verifyValue">{traveller.title}</div>
                                <div className="verifyTitle">Mbiemri</div><div className="verifyValue">{traveller.lastName}</div>
                                <div className="verifyTitle">Gjinia</div><div className="verifyValue">{Gender.filter((g: Nomen) => g.code === traveller.sex)[0]?.name}</div>
                                <div className="verifyTitle">Adresa</div><div className="verifyValue">{traveller.address}</div>
                                <div className="verifyTitle">Vendi</div><div className="verifyValue">{places.filter((p: Nomen) => p.code === traveller.place)[0]?.name}</div>
                                <div className="verifyTitle">Email</div><div className="verifyValue">{traveller.email}</div>
                                <div className="verifyTitle">Nr. telefonit</div><div className="verifyValue">{traveller.celphone}</div>
                                <div className="verifyTitle">Passport</div><div className="verifyValue">{traveller.passport}</div>
                                <div className="verifyTitle">Datëlindja</div><div className="verifyValue"> {moment(traveller.birthDate).format('DD/MM/yyyy')}</div>
                                <hr />
                            </div>
                        ))}
                    </div>}
                {thPackageQuotation &&
                    <div className="text-dark-50 line-height-lg packageInfoMobile">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">{thPackageQuotation.packageName}</h5>
                                <p className="card-text pSum"><small>Shuma e siguruar: {thPackageQuotation.insuredAmountV.toLocaleString()}€</small></p>
                                {thPackageQuotation.covers.map((cover: any, indexC: number) => (
                                    <p key={indexC + 1} className="card-text">
                                        <span className='pCheck'>✔</span> {cover}
                                    </p>
                                ))}
                            </div>
                            <div className="card-footer">
                                {thPackageQuotation.packageDiscount > 0 &&
                                    <p className='pDiscount'>
                                        {thPackageQuotation.totalVNoDiscount.toFixed(2)}{thPackageQuotation.currencyName ? thPackageQuotation.currencyName : '€'}

                                        <Tooltip
                                            arrow
                                            title={"Aplikohet zbritja prej " + (thPackageQuotation.packageDiscount) + "% ne kete police"}
                                            placement="top"
                                            classes={{
                                                tooltip: classes.customTooltip
                                            }}>
                                            <IconButton className="blue">
                                                <Info />
                                            </IconButton>
                                        </Tooltip>
                                    </p>}
                                <p className='pTotal'>
                                    {parseFloat(thPackageQuotation.totalV).toFixed(2)}  {thPackageQuotation.currencyName ? thPackageQuotation.currencyName : '€'}
                                </p>
                            </div>
                        </div>
                        <hr />
                    </div>}
                <div className="row">
                    <div className="col-xl-6">
                        <CheckboxField name="acceptTerms" type="checkbox" label="I kuptoj dhe I pranoj kushtet e sigurimit" />
                    </div>
                    <div className="col-xl-6">
                        <a href={PdfTravel} target="_blank">Kushtet e përgjithshme të sigurimit</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <CaptchaGenerator result={result} />
                    </div>
                    <div className="col-xl-12">
                        <InputField name="captchaInputted" type="text" placeholder="Shkruaj kodim këtu" label="Kodi i sigurise" />
                    </div>
                </div>
            </>
        }
    </>
    );
}
export default connect()(Verification);
