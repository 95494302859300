import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import RadioButtonGroupFieldMain from '../../Custom/RadioButtonGroupFieldMain';
import InputFieldMain from '../../Custom/InputFieldMain';
import DatePickerFieldMain from '../../Custom/DatePickerFieldMain';
import SelectField from '../../Custom/SelectField';
import { Gender } from '../../../utils/nomen';
import { useFormikContext } from 'formik';
import { AppState } from '../../../redux/reducers/rootReducer';
import { MotorInsQuotation, Person } from '../../../utils/policyTypes';
import { useFormikWizard } from 'formik-wizard';

const Insured1 = () => {
    const { values: currentValues }: any = useFormikContext();
    const { values }: any = useFormikWizard();
    let insured1 = useSelector<AppState, Person | undefined>((state: any) => state.policy.motorInsQuotation?.insured1);
    const quotation = useSelector<AppState, MotorInsQuotation | undefined>((state: any) => state.policy.motorInsQuotation);
    const isPhysical = currentValues.tipKind === '3';
    const [shouldCopyContractor, setCopyContractor] = React.useState(false);

    const copyContractor = () => {
        setCopyContractor(true);
    }

    return (
        <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
            <div className="alert alert-custom alert-notice alert-light-primary fade show boxTotal" role="alert">
                <div className="alert-icon"><i className="far fa-credit-card"></i></div>
                <div className="alert-text">Total Primi + Taksa per Blerje Online <span className="spanTotal">{quotation?.total} €</span></div>
                {!insured1 && <button type="button" onClick={copyContractor} className="btn btn-success font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-submit">
                    Kontraktuesi = I Siguruari
                </button>}
            </div>
            <div className="row">
                <div className="col-xl-6">
                    <RadioButtonGroupFieldMain name="tipKind" type="radio" newvalue={!shouldCopyContractor ? insured1?.tipkind || '' : values.contractor.tipKind} placeholder="Tip Kind" label="Tip" items={[{ id: '3', value: 'Fizik' }, { id: '2', value: 'Juridik' }]} />
                </div>
                <div className="col-xl-6">
                    {!isPhysical
                        ? <InputFieldMain type="text" name="nipt" newvalue={!shouldCopyContractor ? insured1?.nipt || '' : values.contractor.nipt} label="Nipti" placeholder="Nipti" />
                        : <InputFieldMain type="text" name="clientNo" maxLength={16} newvalue={!shouldCopyContractor ? insured1?.clientno || '' : values.contractor.clientNo} label="Numri personal" placeholder="Numri personal" />}
                </div>
            </div>

            <div className="row">
                <div className="col-xl-6">
                    <InputFieldMain type="text" name="title" label="Emri" newvalue={!shouldCopyContractor ? insured1?.title || '' : values.contractor.title} placeholder="Emri" capitalized />
                </div>
                {isPhysical && <div className="col-xl-6">
                    <InputFieldMain type="text" name="lastName" label="Mbiemri" newvalue={!shouldCopyContractor ? insured1?.lastname || '' : values.contractor.lastName} placeholder="Mbiemri" capitalized />
                </div>}

                {!isPhysical && <div className="col-xl-6">
                    <InputFieldMain type="text" name="place" label="Vendi" placeholder="Vendi" newvalue={!shouldCopyContractor ? insured1?.place || '' : values.contractor.place} />
                </div>}
            </div>
            <div className="row">
                <div className="col-xl-6">
                    <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" newvalue={!shouldCopyContractor ? insured1?.address || '' : values.contractor.address} />
                </div>
                {isPhysical && <div className="col-xl-6">
                    <InputFieldMain type="text" name="place" label="Vendi" placeholder="Vendi" newvalue={!shouldCopyContractor ? insured1?.place || '' : values.contractor.place} />
                </div>}
                {!isPhysical && <div className="col-xl-6">
                    <InputFieldMain type="text" name="celphone" label="Nr. telefonit" placeholder="Nr. telefonit" newvalue={!shouldCopyContractor ? insured1?.celphone || '' : values.contractor.celphone} />
                </div>}
            </div>
            <div className="row">
                {isPhysical && <div className="col-xl-6">
                    <SelectField name="sex" label="Gjinia" items={Gender} newvalue={!shouldCopyContractor ? insured1?.sex?.code || '' : values.contractor.sex} />
                </div>}
                {isPhysical && <div className="col-xl-6">
                    <InputFieldMain type="text" name="celphone" label="Nr. telefonit" placeholder="Nr. telefonit" newvalue={!shouldCopyContractor ? insured1?.celphone || '' : values.contractor.celphone} />
                </div>}
            </div>
            {isPhysical && <div className="row">
                <div className="col-xl-6">
                </div>
                <div className="col-xl-6 addSpace" >
                    <DatePickerFieldMain name="birthDate" type="text" newvalue={!shouldCopyContractor ? !!insured1?.birthdate ? new Date(insured1?.birthdate) : '' || '' : !!values.contractor.birthDate ? new Date(values.contractor.birthDate) : ''} placeholder="Datëlindja" />
                </div>
            </div>}

            <div className="row addSpace">
                <div className="col-xl-6">
                    <InputFieldMain type="text" name="drivingLicenceNo" label="Dëshmi aftësie" placeholder="Dëshmi aftësie" newvalue={insured1?.drivingLicenceNo || ''} />
                </div>
                <div className="col-xl-6">
                    <InputFieldMain type="text" name="drivingLicenceCat" label="Dëshmi aftësie - Klasa" placeholder="Dëshmi aftësie - Klasa " newvalue={insured1?.drivingLicenceCat || ''} />
                </div>
            </div>

        </div>
    );
}
export default connect()(Insured1);
