import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { useFormikContext } from "formik";
import RadioButtonGroupFieldMain from '../../Custom/RadioButtonGroupFieldMain';
import InputFieldMain from '../../Custom/InputFieldMain';
import DatePickerFieldMain from '../../Custom/DatePickerFieldMain';
import SelectField from '../../Custom/SelectField';
import { Gender } from '../../../utils/nomen';
import CheckboxField from '../../Custom/CheckboxField';
import { MotorInsQuotation } from '../../../utils/policyTypes';
import { AppState } from '../../../redux/reducers/rootReducer';

const Insured2 = () => {
    const { values }: any = useFormikContext();
    const motorInsQuotation = useSelector<AppState, MotorInsQuotation | null>((state: any) => state.policy.motorInsQuotation);
    const isPhysical = values.tipKind === '3';

    return (
        <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
            <div className="alert alert-custom alert-notice alert-light-primary fade show boxTotal" role="alert">
                <div className="alert-icon"><i className="far fa-credit-card"></i></div>
                <div className="alert-text">Total Primi + Taksa per Blerje Online <span className="spanTotal">{motorInsQuotation?.total} €</span></div>

            </div>
            <div className="row">
                <div className="col-xl-6">
                    <CheckboxField type="checkbox" name="hasSecondInsurer" label="Ka të siguruar të dytë" />
                </div>
            </div>
            {values.hasSecondInsurer && <><div className="row">
                <div className="col-xl-6">
                    <RadioButtonGroupFieldMain name="tipKind" type="radio" placeholder="Tip Kind" label="Tip" items={[{ id: '3', value: 'Fizik' }, { id: '2', value: 'Juridik' }]} />
                </div>
                <div className="col-xl-6">
                    {!isPhysical
                        ? <InputFieldMain type="text" name="nipt" label="Nipti" placeholder="Nipti" />
                        : <InputFieldMain type="text" name="clientNo" label="Numri personal" placeholder="Numri personal" maxLength={16} />}
                </div>
            </div>

                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="title" label="Emri" placeholder="Emri" capitalized />
                    </div>
                    {isPhysical && <div className="col-xl-6">
                        <InputFieldMain type="text" name="lastName" label="Mbiemri" placeholder="Mbiemri" capitalized />
                    </div>}

                    {!isPhysical && <div className="col-xl-6">
                        <InputFieldMain type="text" name="place" label="Vendi" placeholder="Vendi" />
                    </div>}
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" />
                    </div>
                    {isPhysical && <div className="col-xl-6">
                        <InputFieldMain type="text" name="place" label="Vendi" placeholder="Vendi" />
                    </div>}
                    {!isPhysical && <div className="col-xl-6">
                        <InputFieldMain type="text" name="celphone" label="Nr. telefonit" placeholder="Nr. telefonit" />
                    </div>}
                </div>
                <div className="row">
                    {isPhysical && <div className="col-xl-6">
                        <SelectField name="sex" label="Gjinia" items={Gender} />
                    </div>}
                    {isPhysical && <div className="col-xl-6">
                        <InputFieldMain type="text" name="celphone" label="Nr. telefonit" placeholder="Nr. telefonit" />
                    </div>}
                </div>
                {isPhysical && <div className="row">
                    <div className="col-xl-6">
                    </div>
                    <div className="col-xl-6 addSpace" >
                        <DatePickerFieldMain name="birthDate" type="text" placeholder="Datëlindja" />
                    </div>
                </div>}

                <div className="row addSpace">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="drivingLicenceNo" label="Dëshmi aftësie" placeholder="Dëshmi aftësie" />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="drivingLicenceCat" label="Dëshmi aftësie - Klasa" placeholder="Dëshmi aftësie - Klasa " />
                    </div>
                </div></>}
        </div>);
}

export default connect()(Insured2);
