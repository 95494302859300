import React from "react";
import { Provider } from "react-redux";
import Routes from "./router/Routes";
import store, { history } from "./redux/configureStore";
// import { ThemeProvider } from "./_metronic/layout";
import { persistor } from "./redux/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from 'connected-react-router';
import { LayoutSplashScreen, MaterialThemeProvider } from "./_metronic/layout";

export default function App() {
    return (
        <Provider store={store}>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor} loading={null}>
                <ConnectedRouter history={history}>
                    <MaterialThemeProvider>
                        <Routes />
                    </MaterialThemeProvider>
                </ConnectedRouter>
            </PersistGate>
        </Provider>
    );
}
