import { FormikWizardStepType } from '../../../../utils/wizardTypes';
import { object, string, date, bool, StringSchema } from 'yup';
import store from '../../../../redux/configureStore';
import * as phActions from "../../../../redux/actionCreators/phPolicyActionCreators";
import moment from 'moment';
import PrivateHealthPolicyMain from './PrivateHealthPolicyMain';
import Packages from './Packages';
import Verification from './Verification';
import { preparePhPolicyForQuotation } from '../../../../utils/policyTypes';
import * as generalActions from '../../../../redux/actionCreators/generalActionCreators';
import { CheckProfileDto } from '../../../../utils';
import InsuredPerson from './InsuredPerson';

export default [
    {
        id: 'phMain',
        component: PrivateHealthPolicyMain,
        name: 'Informata personale',
        description: '',
        initialValues: {
            insuredAge: '',
            firstAnswer: '',
            secondAnswer: '',
            thirdAnswer: '',
            couponAnswer: '',
            coupon: '',
            personalDataConsent: false
        },
        validationSchema: object().shape({
            insuredAge: string().required('E domosdoshme'),
            coupon: string().when('couponAnswer', (couponAnswer: string, schema: StringSchema<string | undefined>) => {
                return couponAnswer === '1' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            personalDataConsent: bool().oneOf([true], 'E domosdoshme')
        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
            store.dispatch(phActions.phQuestionsReset());

            if (sectionValues.firstAnswer !== "2"
                || sectionValues.secondAnswer !== "2"
                || sectionValues.thirdAnswer !== "2") {

                store.dispatch(phActions.phQuestionsFailure());
                store.dispatch(generalActions.showModal("Informacion", "Bazuar në të dhënat që keni vendosur, është e pamundur që ne t'ju bëjmë një ofertë online. <br /> <br /> Ju lutemi na kontaktoni përmes email adresës info@uniqa-ks.com apo tel +383 38 240 241", "", true));
                return;
            }

            const state = store.getState();
            const customerId = state.auth.user?.customerId;

            const thPolicy = preparePhPolicyForQuotation(customerId, sectionValues);
            store.dispatch(phActions.getPHQuotation(thPolicy));
        }
    },
    {
        id: 'package',
        component: Packages,
        name: 'Paketat',
        description: '',
        initialValues: {
            phPackageQuotation: ''
        },
        validationSchema: object().shape({

        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
        }
    },
    {
        id: 'insuredPerson',
        component: InsuredPerson,
        name: 'I siguruari',
        description: '',
        initialValues: {
            clientNo: '',
            title: '',
            lastName: '',
            sex: '',
            address: '',
            place: '',
            email: '',
            celphone: '+383',
            birthDate: '',
            // sourceOfIncome: '',
            // pep: ''
        },
        validationSchema: object().shape({
            clientNo: string().required('E domosdoshme'),
            title: string().required('E domosdoshme'),
            lastName: string().required('E domosdoshme'),
            sex: string().required('E domosdoshme'),
            address: string().required('E domosdoshme'),
            place: string().required('E domosdoshme'),
            email: string()
                .email("Format I gabuar I email-it")
                .min(3, "Minimumi 3 simbole")
                .max(50, "Maksimumi 50 simbole")
                .required("E domosdoshme"),
            celphone: string().required('E domosdoshme').min(10, "Minimumi 10 simbole"),
            birthDate: date().required('E domosdoshme').typeError('E domosdoshme')
                .test(
                    'validBirthDate',
                    'Data e lindjes nuk mund të jetë pas ditës së sotme',
                    function (this: any, value: any) {
                        return moment(value, 'dd/MM/yyyy').isBefore(moment());
                    }),
            // sourceOfIncome: string().required('E domosdoshme'),
            // pep: string().required('E domosdoshme')
            //     .test(
            //         'validPepValue',
            //         'Personat e Ekspozuar Politikisht duhe të paraqiten në zyren e SIGAL-it për të plotësuar formularin përkatës PEP.',
            //         function (this: any, value: any) {
            //             return value !== '1';
            //         }),

        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
            const state = store.getState();
            const checkProfile = state.auth.checkProfile as CheckProfileDto;
            const user = state.auth.user;

            const continueWithoutAccount = state.general.continueWithoutAccount;
            if (continueWithoutAccount)
                return;

            if (!user && checkProfile.hasProfile && checkProfile.confirmedMail) {
                store.dispatch(generalActions.showLoginModal(sectionValues.email));
                return;
            }

            if (!user && checkProfile.hasProfile && !checkProfile.confirmedMail) {
                store.dispatch(generalActions.showVerificationModal());
                return;
            }

            if (!user && !checkProfile.hasProfile) {
                store.dispatch(generalActions.showCreateAccountQuestionModal(sectionValues));
                return;
            }

        }
    },
    {
        id: 'verification',
        component: Verification,
        name: 'Verifiko',
        description: '',
        initialValues: {
            acceptTerms: false,
            captchaGenerated: '',
            captchaInputted: ''
        },
        validationSchema: object().shape({
            acceptTerms: bool().oneOf([true], 'E domosdoshme'),
            captchaGenerated: string(),
            captchaInputted: string().required('E domosdoshme')
                .test(
                    'validCaptcha',
                    'Captcha eshte gabim',
                    function (this: any, value: any) {
                        if (value !== '')
                            return this.parent.captchaGenerated === value;

                        return true;
                    })
        }),
        actionLabel: 'Vazhdo',

    },
] as FormikWizardStepType[];
