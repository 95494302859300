import { PolicyMenu } from './../../../redux/types';
import { prepareThPolicy, ThPolicy } from './../../../utils/policyTypes';
import { FormikWizardStepType } from '../../../utils/wizardTypes';
import { object, string, mixed, number, bool } from 'yup';
import store from "../../../redux/configureStore";
import * as thActions from "../../../redux/actionCreators/thPolicyActionCreators";
import * as generalActions from "../../../redux/actionCreators/generalActionCreators";
import moment from 'moment';
import ThInsMain from './ThInsMain';
import Insured from './Insured';
import Preview from './Preview';
import Confirmation from './Confirmation';

export default [
    {
        id: 'thInsMain',
        component: ThInsMain,
        name: 'Llogarit primin',
        description: '',
        initialValues: {
            startDate: '',
            insuredNumDays: '',
            endDate: '',
            territory: '',
            zone: '',
            isOld: false,
        },
        validationSchema: object().shape({
            startDate: mixed(),
            insuredNumDays: number().required('E domosdoshme').min(1).max(365),
            territory: string().required('E domosdoshme'),
            zone: string().required('E domosdoshme'),
            isOld: bool()
        }),
        actionLabel: 'Verifiko',
        onAction: (sectionValues, formValues) => {
            const fvalid = moment(sectionValues.startDate).format('YYYY-MM-DD');
            const submittedFValid = moment(formValues.thInsMain.startDate).format('YYYY-MM-DD');
            if (submittedFValid === "" 
                || fvalid !== submittedFValid 
                || formValues.thInsMain.insuredNumDays === ""
                || sectionValues.insuredNumDays !== formValues.thInsMain.insuredNumDays
                || sectionValues.territory !== formValues.thInsMain.territory
                || sectionValues.zone !== formValues.thInsMain.zone) {
                const state = store.getState();
                const customerId = state.auth.user?.customerId;
                const policy = { ...sectionValues } as ThPolicy;
                policy.customerId = customerId;
                // policy.subtypePolicy = state.router.location.pathname.includes("student") ? "02" : "01";
                policy.zone = { code: sectionValues.zone, name: "" };
                // policy.insuredNumDays = parseInt(sectionValues.insuredNumDays);
                // store.dispatch(generalActions.checkFValid(customerId!, "05", policy.subtypePolicy, fvalid, "", "", "", null, null, "", policy, true));
            }
        }
    },
    {
        id: 'insured',
        component: Insured,
        name: 'I Siguruari',
        description: '',
        initialValues: {
            clientNo: '',
            title: '',
            lastName: '',
            birthDate: '',
            email: '',
            celphone: '',
            address: '',
            place: '',
            passport: '',
            sex: ''
        },
        validationSchema: object().shape({
            clientNo: string().required('E domosdoshme')
                .test(
                    'validClientNo',
                    'Klientit nuk I lejohet te shese per te tjere',
                    function (this: any, value: any) {
                        const clientNo = store.getState().auth.user?.clientno;
                        const policyList = store.getState().auth.user?.policyList;
                        const currentScreen = store.getState().router.location.pathname.includes("student") ? "02" : "01"
                        const policyItem = policyList.find((p: PolicyMenu) => p.type === "05" && p.subtype === currentScreen);
                        const isAllowed = policyItem.allowToSellForOthers;
                        const isNotPhysical = this.parent.tipKind !== '3';
                        if (isAllowed || clientNo === null || value === undefined || isNotPhysical)
                            return true;

                        return (value as string).toLowerCase() === (clientNo as string).toLowerCase();
                    }),
            title: string()
                .min(3, "Minimumi 3 simbole")
                .max(50, "Maksimumi 50 simbole")
                .required("E domosdoshme"),
            lastName: string().required('E domosdoshme'),
            birthDate: mixed().required('E domosdoshme')
                .test(
                    'validBirthDate',
                    'Data e lindjes nuk mund te jete pas dates se sotshme',
                    function (this: any, value: any) {
                        if (moment(value, 'dd/MM/yyyy').isValid())
                            return moment(value, 'dd/MM/yyyy').isBefore(moment());
                        return true;
                    }),
            email: string().email().required('E domosdoshme'),
            celphone: string().required('E domosdoshme'),
            address: string().required('E domosdoshme'),
            place: string(),
            passport: string().required('E domosdoshme'),
            sex: string()
        }),
    actionLabel: 'Vazhdo'
    },
    {
        id: 'preview',
        component: Preview,
        name: 'Verifiko',
        description: '',
        initialValues: {
            payment: '1',
            acceptConditions: false,
            acceptTerms: false
        },
        validationSchema: object().shape({
            payment: string().required('E domosdoshme!'),
            acceptConditions: bool().oneOf([true], 'E domosdoshme'),
            acceptTerms: bool().oneOf([true], 'E domosdoshme')
        }),
        actionLabel: 'Vazhdo per pagese',
        onAction: (sectionValues, formValues) => {
            const state = store.getState();
            const customerId = state.auth.user?.customerId;
            if (customerId) {
                // const thPolicy = prepareThPolicy(customerId, formValues, state.router.location.pathname.includes("student") ? "02" : "01");
                // store.dispatch(thActions.bookTHPolicy(thPolicy, sectionValues.payment));
            }
        }
    },
    {
        id: 'confirmation',
        component: Confirmation,
        name: 'Pagesa',
        description: '',
        initialValues: {
        },
        validationSchema: object().shape({
        }),
        actionLabel: 'Vazhdo',
    }
] as FormikWizardStepType[];