import React from "react";
import { Link } from "react-router-dom";
import { useFormikContext } from "formik";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import DatePickerField from '../../Custom/DatePickerField';
import InputFieldMain from '../../Custom/InputFieldMain';
import RadioButtonGroupField from '../../Custom/RadioButtonGroupField';
import Nav from './Nav';
import CheckboxField from "../../Custom/CheckboxField";

const Account = () => {
    const { values }: any = useFormikContext();
    const isPhysical = values.tipKind === '3';

    return (
        <>
            <div className="d-flex flex-column-auto flex-column px-10">
                <Link to="/" className="login-logo pb-lg-4 pb-10">
                    <img
                        alt="Logo"
                        className="max-h-70px"
                        src={toAbsoluteUrl("/media/logos/sigal_new.png")}
                    />
                </Link>
                <Nav step={0} />
            </div>
            <div className="login-form form px-10 fv-plugins-bootstrap fv-plugins-framework">
                <div className="pb-10 pb-lg-15">
                    <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Regjistrohu  </h3>
                    <div className="text-muted font-weight-bold font-size-h4">
                        Ke një llogari?
                        <Link
                            to="/auth/login"
                            className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                            id="kt_login_forgot"
                        >Hyr
                        </Link>
                    </div>
                </div>
                <RadioButtonGroupField name="tipKind" type="radio" placeholder="Tip Kind" label="Tip" items={[{ id: '3', value: 'Fizik' }, { id: '2', value: 'Juridik' }]} />
                <div className="row">
                    <div className="col-xl-6">
                        {isPhysical && <InputFieldMain name="clientno" type="text" placeholder="Nr. Personal" label="Nr. Personal" maxLength={16} />}
                        {!isPhysical && <InputFieldMain name="nipt" type="text" placeholder="Nipt" label="Nipt" />}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain name="title" type="text" placeholder="Emër" label="Emër" />
                    </div>
                    <div className="col-xl-6">
                        {isPhysical && <InputFieldMain name="lastName" type="text" placeholder="Mbiemër" label="Mbiemër" />}
                    </div>
                </div>
                <div className="empty-row"></div>
                <div className="row">
                    <div className="col-xl-6">
                        {isPhysical && <RadioButtonGroupField name="sex" type="text" placeholder="Gjinia" label="Gjinia" items={[{ id: '1', value: 'Mashkull' }, { id: '2', value: 'Femër' }]} />}
                    </div>
                    <div className="col-xl-6">
                        {isPhysical && <DatePickerField name="birthdate" type="text" placeholder="Datëlindja" />}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain name="password" type="password" placeholder="Fjalëkalim" label="Fjalëkalim" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 checkboxCtrl">
                        <CheckboxField name="accept_terms" type="checkbox" label="Konfirmoj se kam lexuar dhe jam pajtuar e përcaktimet, kushtet, autorizimin dhe jap pëlqimin për marrjen dhe mbrojtjen e të dhënave personale" />
                    </div>
                    <div className="col-xl-12 checkboxCtrl">
                        <div className="mb-10 font-weight-bold text-dark"><a href="https://sigal-ks.com/wp-content/uploads/2022/08/POLITIKA-E-PRIVATESISE-SIGAL.pdf" target="_blank">Politika e privatesisë Sigal Uniqa Group Austria Kosovë</a></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Account;
