import React, { FunctionComponent } from 'react';
import { useField } from 'formik';

const getFieldCSSClasses = (touched: boolean, error: string | undefined) => {
    const classes = ["form-control"];
    if (touched && error) {
        classes.push("is-invalid");
    }

    if (touched && !error) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

interface Props {
    name: string;
    type: string;
    placeholder: string;
    label: string;
    ref?: React.RefObject<any> | null;
}

const InputField: FunctionComponent<Props> = (props) => {
    const [
        field,
        { error, touched },
    ] = useField({
        name: props.name,
        type: props.name,
    });
    return (
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center fv-plugins-icon-container">
            <label className="font-size-h6 font-weight-bolder text-dark pt-5">{props.label}</label>
            <input {...field} {...props} className={"form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6 " + getFieldCSSClasses(touched, error)} />
            {touched && error !== "" ? (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{error}</div>
                </div>
            ) : null}
        </div>
    );
};

export default InputField;