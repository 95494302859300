import React from 'react';
import { Link } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Notification, User } from '../redux/types';
import { AppState } from '../redux/reducers/rootReducer';
import { markAppealAsRead, readFirstLevelAppeal, readFirstLevelAppealReset, readSecondLevelAppeal, readSecondLevelAppealReset } from '../redux/actionCreators/healthPreClaimActionCreators';
import * as healthClaimActionTypes from '../redux/actionTypes/healthClaimActionTypes';
import * as generalActionTypes from '../redux/actionTypes/generalActionTypes';
import * as authActionTypes from '../redux/actionTypes/authActionTypes';
import { hideModal } from '../redux/actionCreators/generalActionCreators';
import { getCustomer } from '../redux/actionCreators/authActionCreators';
import { GetCustomerAction } from '../redux/actionTypes/authActionTypes';
import ReadFirstLevelAppealModal from './DamageClaim/Grid/HealthClaim/ReadFirstLevelAppealModal';
import ReadSecondLevelAppealModal from './DamageClaim/Grid/HealthClaim/ReadSecondLevelAppealModal';
import { Loading } from './Layout/Loading';

interface Props {
    onRead: (code: string) => void;
    readFirstLevelAppealReset(): void;
    onGetCustomer(customerId: string): void;
    triggerMark(code: string): void;
    onReadSecondLevel: (code: string) => void;
    readSecondLevelAppealReset(): void;
}

const Home = (props: Props) => {
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const header = useSelector<AppState, string>((state: any) => state.general.header);
    const text = useSelector<AppState, string>((state: any) => state.general.text);
    const showReadFirstLevelAppealModal = useSelector<AppState, boolean>((state: any) => state.healthClaim.showReadFirstLevelAppealModal);
    const showReadSecondLevelAppealModal = useSelector<AppState, boolean>((state: any) => state.healthClaim.showReadSecondLevelAppealModal);
    const customer = useSelector<AppState, User | null>((state: any) => state.auth.customer);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[authActionTypes.GET_CUSTOMER]);

    const pixelScript = `
        !function(f,b,e,v,n,t,s){
            if(f.fbq)return;n=f.fbq=function(){
                n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)
            };
            if(!f._fbq) f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)
        }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');

        fbq('init', '2016267805471451');
        fbq('track', 'PageView');    `;

    React.useEffect(() => {
        const script = document.createElement("script");
        script.innerHTML = pixelScript;
        document.body.appendChild(script);
        
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const emptyReadFirstLevelAppeal = () => {
        props.readFirstLevelAppealReset();
        props.onGetCustomer(user!.customerId);
    };

    const emptyReadSecondLevelAppeal = () => {
        props.readSecondLevelAppealReset();
        props.onGetCustomer(user!.customerId);
    };

    const readNotification = (notification: Notification) => {
        if (notification.appealLevel === '1') {
            openFirstLevelAppeal(notification.queryString);
        }

        if (notification.appealLevel === '2') {
            openSecondLevelAppeal(notification.queryString);
        }
    }

    const openFirstLevelAppeal = (code: string) => {
        props.onRead(code);
        props.triggerMark(code);
    };

    const openSecondLevelAppeal = (code: string) => {
        props.onReadSecondLevel(code);
        props.triggerMark(code);
    };

    return (
        <div>
            <ReadFirstLevelAppealModal show={showReadFirstLevelAppealModal} header={header} text={text} onClose={emptyReadFirstLevelAppeal} onHide={() => { return; }} />
            <ReadSecondLevelAppealModal show={showReadSecondLevelAppealModal} header={header} text={text} onClose={emptyReadSecondLevelAppeal} onHide={() => { return; }} />
            {isLoading ? <Loading />
                : <>
                    <div className="row">
                        <div className="col-xl-12" >
                            <div className="card card-custom bgi-no-repeat card-stretch gutter-b bcgimage">
                                <div className="card-body">
                                         <div className='bcgtext'> 
                                            Portali i Klientit
                                        </div>
                                        <div className='bcgtext_under'> 
                                            Regjistrohuni dhe blini sigurimet
                                        </div>
                                        <div className='bcgtext_under'> 
                                            tuaja në internet, shpejt dhe lehtësisht
                                        </div>


                                    {!!customer && customer.notifications && customer.notifications.length > 0 &&
                                        customer.notifications.map((notification: Notification, index: number) => {
                                            return <div key={index}>
                                                <Link to="" onClick={() => readNotification(notification)} className="text-primary font-size-h5 font-weight-bolder text-hover-primary pt-5" rel="noopener noreferrer">{notification.message}</Link>
                                            </div>;
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <br></br> */}
                    <h2 style={{ color: '#005ca9', textAlign: 'center', fontWeight: 'bold' }}>Produktet Online</h2><br></br>
                    <div className="row">
                        <div className="col-xl-4 d-flex justify-content-center align-items-center card_body_width" >
                            <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b " style={{ height: '240px', width: '405px', backgroundPosition: '85% 17%', backgroundSize: '8% auto', backgroundImage: 'url(/media/dashboard/car--md.svg)' }}>
                                <div className="card-body card_body_width">
                                        <div className="empty-row"></div>
                                        <div className="empty-row"></div>
                                        <div className="empty-row"></div>
                                        <div className="empty-row"></div>
                                        <div className="empty-row-mobile"></div>
                                        <a href="#" target='_blank' rel="noopener noreferrer" className=" font-weight-bolder line-height-lg mb-5 font-size-h3 card_title" >Automjet</a>
                                        <div  className="text-dark-50 cardbox_text" >SIGAL UNIQA Group Austria ofron sigurimin vullnetar të automjetit KASKO, i cili mbron makinën tuaj nga dëmet e pjesshme ose të plota
                                                që mund t'i ndodhin për shkaqe të ndryshme.</div>
                                    {/* <p></p> */}
                                    <Link to="kasko-policy" className="btn btn-primary position-absolute  start-0 translate-middle-y mb-3 ms-3 card_button">Blej Online</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 d-flex justify-content-center align-items-center card_body_width">
                            <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b" style={{ height: '240px', width: '405px', backgroundPosition: '85% 17%', backgroundSize: '8% auto', backgroundImage: 'url(/media/dashboard/house--md.svg)' }}>
                                <div className="card-body card_body_width">
                                    <a href="#" target='_blank' rel="noopener noreferrer" className=" font-weight-bolder line-height-lg mb-5 font-size-h3 card_title" >Pasuri</a>
                                        <div className="empty-row"></div>
                                        <div className="empty-row"></div>
                                        <div className="empty-row"></div>
                                        <div className="empty-row"></div>
                                        <div className="empty-row-mobile"></div>
                                        <div className="text-dark-50 cardbox_text" >Mbroni investimin tuaj më të rëndësishëm: shtëpi, banesë, apartament nga zjarri dhe katastrofat natyrore.
                                                Zgjidhni një nga paketat e sigurimit të pronës që ofron SIGAL UNIQA.</div>
                                    <Link to="property-policy" className="btn btn-primary position-absolute start-0 translate-middle-y mb-3 ms-3 card_button">Blej Online</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 d-flex justify-content-center align-items-center card_body_width">
                            <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b" style={{ height: '240px', width: '405px', color: '#55adb5', backgroundPosition: '85% 17%', backgroundSize: '8% auto', backgroundImage: 'url(/media/dashboard/health--md.svg)' }}>
                                <div className="card-body card_body_width">
                                    <a href="#" target='_blank' rel="noopener noreferrer" className=" font-weight-bolder line-height-lg mb-5 font-size-h3 card_title">Shëndet</a>
                                    <div className="empty-row"></div>
                                    <div className="empty-row"></div>
                                    <div className="empty-row"></div>
                                    <div className="empty-row"></div>
                                    <div className="empty-row-mobile"></div>
                                    <div className="text-dark-50 cardbox_text">SIGAL UNIQA Group Austria mbron të siguruarin nga ndikimi financiar dhe pasojat e faturave të papritura mjekësore duke ofruar përfitime 
                                            për të siguruarit për trajtimin e sëmundjeve, dëmtimeve ose paaftësisë.</div>
                                    <Link to="private-health-policy" className="btn btn-primary position-absolute start-0 translate-middle-y mb-3 ms-3 card_button">Blej Online</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-xl-4 d-flex justify-content-center align-items-center card_body_width">
                            <div className="card card-custom bgi-no-repeat gutter-b"
                                style={{ height: '240px', width: '405px', backgroundPosition: '85% 17%', backgroundSize: '8% auto', backgroundImage: 'url(/media/dashboard/life--md.svg)' }}>
                                <div className="card-body">
                                    <a href="#" target='_blank' rel="noopener noreferrer" className=" font-weight-bolder line-height-lg mb-5 font-size-h3 card_title">Jetë</a>
                                    <div className="empty-row"></div>
                                    <div className="empty-row"></div>
                                    <div className="empty-row"></div>
                                    <div className="empty-row"></div>
                                    <div className="empty-row-mobile"></div>
                                    <div className="text-dark-50 cardbox_text_other3">Qetësi mendore për jetë.</div>
                                    <div className="text-dark-50 cardbox_text_other3">Me sigurimin e jetës përfitoni mbrojtje financiare dhe qetësi mendjeje në rast fatkeqësie.</div>
                                    <Link to="term-life-policy" className="btn btn-primary position-absolute start-0 translate-middle-y mb-3 ms-3 card_button">Blej Online</Link>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 d-flex justify-content-center align-items-center card_body_width">
                            <div className="card card-custom bgi-no-repeat gutter-b" style={{ height: '240px', width: '405px', backgroundPosition: '85% 17%', backgroundSize: '8% auto', backgroundImage: 'url(/media/dashboard/travel--md.svg)' }}>
                                <div className="card-body">
                                    <a href="#" target='_blank' rel="noopener noreferrer" className=" font-weight-bolder line-height-lg mb-5 font-size-h3 card_title card_title_05">Shëndeti në udhëtim</a>
                                    <div className="empty-row"></div>
                                    <div className="empty-row"></div>
                                    <div className="empty-row"></div>
                                    <div className="empty-row"></div>
                                    <div className="empty-row-mobile"></div>
                                    <div className="text-dark-50 cardbox_text_other3">Gjatë udhëtimeve për punë, turizëm apo shkollim, kujdesuni që të keni gjithmonë Sigurimin e Shëndetit me vete. Përmes sigurimit SIGAL UNIQA mbulon shpenzimet për emergjenca mjekësore
                                        gjatë udhëtimit (jashtë Kosovës).</div>
                                    <Link to="travel-health-policy" className="btn btn-primary position-absolute start-0 translate-middle-y mb-3 ms-3 card_button">Blej Online</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 d-flex justify-content-center align-items-center card_body_width">
                            <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b" style={{ height: '240px', width: '405px', color: '#55adb5', backgroundPosition: '85% 17%', backgroundSize: '8% auto', backgroundImage: 'url(/media/dashboard/shield.svg)' }}>
                                <div className="card-body">
                                <a href="#" target='_blank' rel="noopener noreferrer" className=" font-weight-bolder line-height-lg mb-5 font-size-h3 card_title" >Lejeqëndrim</a>
                                    <div className="empty-row"></div>
                                    <div className="empty-row"></div>
                                    <div className="empty-row"></div>
                                    <div className="empty-row"></div>
                                    <div className="empty-row-mobile"></div>
                                    <div className="text-dark-50 cardbox_text_other3">Mbulon ngjarjet që ndodhin si pasojë e aksidentit, shpenzimet mjekësore, invaliditeti total dhe humbja e jetës, sipas 
                                        kufijve të përcaktuar në kushtet e kontratës dhe të policës së sigurimit. Zona e mbulimit është Kosova.</div>
                                    <Link to="foreigner-policy" className="btn btn-primary position-absolute start-0 translate-middle-y mb-3 ms-3 card_button">Blej Online</Link>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-xl-8">
                        <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b bckimg" >
                                <div className="card-body d-flex">
                                    <div className="d-flex py-5 flex-column align-items-start flex-grow-1">
                                        <div className="flex-grow-1">
                                            <a href="https://sigal-ks.com/" target='_blank' rel="noopener noreferrer" className="text-white font-weight-bolder font-size-h3">SIGAL UNIQA</a>
                                                                                <div className="empty-row"></div>
                                            <div  style={{ color: 'white' }}>

                                                Rr. "Ukshin Hoti", Nr.19, Pejton, Kat. 4
                                            </div>
                                            <div style={{ color: 'white' }}>
                                                Prishtinë, Kosovë <br />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4">
                            <div className="card card-custom bgi-no-repeat bgi-size-cover gutter-b" style={{ height: '145px', backgroundColor: '#4682B4' }}>
                                <div className="card-body">
                                    <a href="#" className=" text-hover-primary font-weight-bolder font-size-h4" style={{ fontSize: '0.9rem', color: 'white' }}>   Keni Pyetje?        </a>
                                    <div className="empty-row"></div>
                                    <div style={{ fontSize: '0.85rem', color: 'white' }}>  +381 (0) 38 240 241    </div>
                                    <div style={{ fontSize: '0.85rem', color: 'white' }}> Hëne - Premte: 8.00 - 16.00   </div>
                                    <div style={{ fontSize: '0.85rem', color: 'white' }}> <a href="mailto:info@uniqa-ks.com" target='_blank' rel="noopener noreferrer" className="text-white font-weight-bold  pt-2">info@uniqa-ks.com</a>     </div>
                                    <div style={{ fontSize: '0.85rem', color: 'white' }}> <a href="https://sigal-ks.com/" target='_blank' rel="noopener noreferrer" className="text-white font-weight-bold pt-2">uniqa-ks.com</a> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        <noscript>
                <img height="1" width="1" style={{ display: 'none' }} src="https://www.facebook.com/tr?id=2016267805471451&ev=PageView&noscript=1" />
        </noscript>
        </div >

        
    );
}

const mapDispatchToProps = (dispatch: Dispatch<healthClaimActionTypes.ReadFirstLevelAppealAction
    | generalActionTypes.HideModalAction | healthClaimActionTypes.ReadFirstLevelAppealResetAction
    | healthClaimActionTypes.ReadSecondLevelAppealAction | healthClaimActionTypes.ReadSecondLevelAppealResetAction
    | GetCustomerAction | healthClaimActionTypes.MarkAppealAsReadAction>) => ({
        closeModal: () => { dispatch(hideModal()); },
        onRead: (code: string) => {
            dispatch(readFirstLevelAppeal(code));
        },
        readFirstLevelAppealReset: () => { dispatch(readFirstLevelAppealReset()); },
        onGetCustomer: (customerId: string) => {
            dispatch(getCustomer(customerId));
        },
        triggerMark: (code: string) => {
            dispatch(markAppealAsRead(code));
        },
        onReadSecondLevel: (code: string) => {
            dispatch(readSecondLevelAppeal(code));
        },
        readSecondLevelAppealReset: () => { dispatch(readSecondLevelAppealReset()); },
    });

export default connect(null, mapDispatchToProps)(Home);