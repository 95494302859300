import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
import { AnimateLoading } from "../../../_partials/controls";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";

export function HeaderBottom() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      )
    };
  }, [uiService]);

  return (
    <>
      {/*begin::HeaderBottom*/}
      <div className="header-bottom">
        <div className="container">
          <HeaderMenuWrapper />
        </div>
	    </div>
      {/*end::HeaderBottom*/}
    </>
  );
}
