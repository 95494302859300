import { put, call, takeEvery, fork, all } from 'redux-saga/effects';
import { getNomen, getCoverCategoriesForPolicy, getCoverGroupsForPolicy, getCoversForPolicy, getNomenWithData, getCountries } from "../services/nomenServices";
import * as nomenActionCreators from "../actionCreators/nomenActionCreators";
import * as generalActionCreators from "../actionCreators/generalActionCreators";
import * as nomenActionTypes from "../actionTypes/nomenActionTypes";
import * as authActionTypes from "../actionTypes/authActionTypes";

function* onGetTplCategoryClassTypes(): any {
    try {
        yield put(nomenActionCreators.getTplVehicleCategoryClassTypeRequest());
        const response = yield call(getNomen, 'GetTPLVehicleCategoryClass');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getTplVehicleCategoryClassTypeSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getTplVehicleCategoryClassTypeFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getTplVehicleCategoryClassTypeFailure(error));
    }
}

function* watchOnGetTplCategoryClassTypes() {
    yield takeEvery(nomenActionTypes.GET_TPL_VCCT, onGetTplCategoryClassTypes);
}

function* onGetGCCategoryClassTypes(): any {
    try {
        yield put(nomenActionCreators.getGcVehicleCategoryClassTypeRequest());
        const response = yield call(getNomen, 'GetGCVehicleCategoryClass');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getGcVehicleCategoryClassTypeSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getGcVehicleCategoryClassTypeFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getGcVehicleCategoryClassTypeFailure(error));
    }
}

function* watchOnGetGcCategoryClassTypes() {
    yield takeEvery(nomenActionTypes.GET_GC_VCCT, onGetGCCategoryClassTypes);
}

function* onGetBCategoryClassTypes(): any {
    try {
        yield put(nomenActionCreators.getBVehicleCategoryClassTypeRequest());
        const response = yield call(getNomen, 'GetBVehicleCategoryClass');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getBVehicleCategoryClassTypeSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getBVehicleCategoryClassTypeFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getBVehicleCategoryClassTypeFailure(error));
    }
}

function* watchOnGetBCategoryClassTypes() {
    yield takeEvery(nomenActionTypes.GET_B_VCCT, onGetBCategoryClassTypes);
}

function* onGetVehicleMark(): any {
    try {
        yield put(nomenActionCreators.getVehicleMarkRequest());
        const response = yield call(getNomen, 'GetVehicleMark');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getVehicleMarkSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getVehicleMarkFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getVehicleMarkFailure(error));
    }
}

function* watchOnGetVehicleMark() {
    yield takeEvery(nomenActionTypes.GET_VEHICLE_MARK, onGetVehicleMark);
}

function* onGetThZone(): any {
    try {
        yield put(nomenActionCreators.getThZoneRequest());
        const response = yield call(getNomen, 'GetThZone');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getThZoneSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getThZoneFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getThZoneFailure(error));
    }
}

function* watchOnGetThZone() {
    yield takeEvery(nomenActionTypes.GET_TH_ZONE, onGetThZone);
}

function* onGetGcPeriod(): any {
    try {
        yield put(nomenActionCreators.getGcPeriodRequest());
        const response = yield call(getNomen, 'GetGcPeriod');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getGcPeriodSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getGcPeriodFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getGcPeriodFailure(error));
    }
}

function* watchOnGetGcPeriod() {
    yield takeEvery(nomenActionTypes.GET_GC_PERIOD, onGetGcPeriod);
}

function* onGetBPeriod(): any {
    try {
        yield put(nomenActionCreators.getBPeriodRequest());
        const response = yield call(getNomen, 'GetBPeriod');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getBPeriodSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getBPeriodFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getBPeriodFailure(error));
    }
}

function* watchOnGetBPeriod() {
    yield takeEvery(nomenActionTypes.GET_B_PERIOD, onGetBPeriod);
}

function* onGetPolicyStatus(): any {
    try {
        yield put(nomenActionCreators.getPolicyStatusRequest());
        const response = yield call(getNomen, 'GetPolicyStatus');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getPolicyStatusSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getPolicyStatusFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getPolicyStatusFailure(error));
    }
}

function* watchOnGetPolicyStatus() {
    yield takeEvery(nomenActionTypes.GET_POLICY_STATUS, onGetPolicyStatus);
}

function* onGetDeliveryLocations(): any {
    try {
        yield put(nomenActionCreators.getDeliveryBranchLocationsRequest());
        const response = yield call(getNomen, 'GetDeliveryBranchLocations');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getDeliveryBranchLocationsSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getDeliveryBranchLocationsFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getDeliveryBranchLocationsFailure(error));
    }
}

function* watchOnGetDeliveryLocations() {
    yield takeEvery(nomenActionTypes.GET_DELIVERY_BRANCH_LOCATIONS, onGetDeliveryLocations);
}

function* onGetPlaces(): any {
    try {
        yield put(nomenActionCreators.getPlacesRequest());
        const response = yield call(getNomen, 'GetPlaces');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getPlacesSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getPlacesFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getPlacesFailure(error));
    }
}

function* watchOnGetPlaces() {
    yield takeEvery(nomenActionTypes.GET_PLACES, onGetPlaces);
}

function* onGetNatures(): any {
    try {
        yield put(nomenActionCreators.getNaturesRequest());
        const response = yield call(getNomen, 'GetNatures');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getNaturesSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getNaturesFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getNaturesFailure(error));
    }
}

function* watchOnGetNatures() {
    yield takeEvery(nomenActionTypes.GET_NATURES, onGetNatures);
}

function* onGetHealthNatures(): any {
    try {
        yield put(nomenActionCreators.getHealthNaturesRequest());
        const response = yield call(getNomen, 'GetHealthNatures');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getHealthNaturesSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getHealthNaturesFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getHealthNaturesFailure(error));
    }
}

function* watchOnGetHealthNatures() {
    yield takeEvery(nomenActionTypes.GET_HEALTH_NATURES, onGetHealthNatures);
}

function* onGetPropertyNatures(): any {
    try {
        yield put(nomenActionCreators.getPropertyNaturesRequest());
        const response = yield call(getNomen, 'GetPropertyNatures');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getPropertyNaturesSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getPropertyNaturesFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getPropertyNaturesFailure(error));
    }
}

function* watchOnGetPropertyNatures() {
    yield takeEvery(nomenActionTypes.GET_PROPERTY_NATURES, onGetPropertyNatures);
}

function* onGetTypeVehicle(): any {
    try {
        yield put(nomenActionCreators.getTypeVehicleRequest());
        const response = yield call(getNomen, 'getTypeVehicle');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getTypeVehicleSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getTypeVehicleFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getTypeVehicleFailure(error));
    }
}

function* watchOnGetTypeVehicle() {
    yield takeEvery(nomenActionTypes.GET_TYPE_VEHICLE, onGetTypeVehicle);
}

function* resetOnLogout() {
    yield put(nomenActionCreators.resetNomen());
}

function* watchOnLogoutUser() {
    yield takeEvery(authActionTypes.LOGOUT_USER, resetOnLogout);
}

function* onGetBanks(): any {
    try {
        yield put(nomenActionCreators.getBanksRequest());
        const response = yield call(getNomen, 'GetBanks');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getBanksSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getBanksFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getBanksFailure(error));
    }
}

function* watchOnGetBanks() {
    yield takeEvery(nomenActionTypes.GET_BANKS, onGetBanks);
}

function* onGetBanksLife(): any {
    try {
        yield put(nomenActionCreators.getBanksLifeRequest());
        const response = yield call(getNomen, 'GetBanksLife');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getBanksLifeSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getBanksLifeFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getBanksLifeFailure(error));
    }
}

function* watchOnGetBanksLife() {
    yield takeEvery(nomenActionTypes.GET_BANKS_LIFE, onGetBanksLife);
}

function* onGetCountries({ zone }: nomenActionTypes.GetCountriesAction): any {
    try {
        yield put(nomenActionCreators.getCountriesRequest());
        const response = zone !== null && zone !== undefined && zone !== ""
            ? yield call(getCountries, zone)
            : yield call(getNomen, 'GetCountries');
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getCountriesSuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getCountriesFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getCountriesFailure(error));
    }
}

function* watchOnGetCountries() {
    yield takeEvery(nomenActionTypes.GET_COUNTRIES, onGetCountries);
}

function* onGetCoverCategoriesForPolicy({ policy, invoiceDate }: nomenActionTypes.GetCoverCategoriesForPolicyAction): any {
    try {
        yield put(nomenActionCreators.getCoverCategoriesForPolicyRequest());
        const response = yield call(getCoverCategoriesForPolicy, policy, invoiceDate);
        if (response.data.succeeded) {
            if (response.data.data.length === 0) {
                const error = "Data e aksidentit duhet te jete e vlefshme (Data duhet te jete ndermjet periudhes se sigurimit)";
                yield put(nomenActionCreators.getCoverCategoriesForPolicyFailure(error));
                yield put(generalActionCreators.showModal("Dështimi", error, ""));
            }
            else {
                yield put(nomenActionCreators.getCoverCategoriesForPolicySuccess(response.data.data));
            }
        }
        else {
            yield put(nomenActionCreators.getCoverCategoriesForPolicyFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getCoverCategoriesForPolicyFailure(error));
    }
}

function* watchOnGetCoverCategoriesForPolicy() {
    yield takeEvery(nomenActionTypes.GET_COVER_CATEGORIES_FOR_POLICY, onGetCoverCategoriesForPolicy);
}

function* onGetCoverGroupsForPolicy({ policy, invoiceDate, coverGroupType }: nomenActionTypes.GetCoverGroupsForPolicyAction): any {
    try {
        yield put(nomenActionCreators.getCoverGroupsForPolicyRequest());
        const response = yield call(getCoverGroupsForPolicy, policy, invoiceDate, coverGroupType);
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getCoverGroupsForPolicySuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getCoverGroupsForPolicyFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getCoverGroupsForPolicyFailure(error));
    }
}

function* watchOnGetCoverGroupsForPolicy() {
    yield takeEvery(nomenActionTypes.GET_COVER_GROUPS_FOR_POLICY, onGetCoverGroupsForPolicy);
}

function* onGetCoversForPolicy({ policy, invoiceDate, coverGroupType, coverGroup }: nomenActionTypes.GetCoversForPolicyAction): any {
    try {
        yield put(nomenActionCreators.getCoversForPolicyRequest());
        const response = yield call(getCoversForPolicy, policy, invoiceDate, coverGroupType, coverGroup);
        if (response.data.succeeded) {
            yield put(nomenActionCreators.getCoversForPolicySuccess(response.data.data));
        }
        else {
            yield put(nomenActionCreators.getCoversForPolicyFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(nomenActionCreators.getCoversForPolicyFailure(error));
    }
}

function* watchOnGetCoversForPolicy() {
    yield takeEvery(nomenActionTypes.GET_COVERS_FOR_POLICY, onGetCoversForPolicy);
}

export default function* nomenSaga() {
    yield all([fork(watchOnGetTplCategoryClassTypes),
    fork(watchOnGetGcCategoryClassTypes),
    fork(watchOnGetBCategoryClassTypes),
    fork(watchOnGetVehicleMark),
    fork(watchOnGetThZone),
    fork(watchOnGetGcPeriod),
    fork(watchOnGetBPeriod),
    fork(watchOnGetPolicyStatus),
    fork(watchOnGetPlaces),
    fork(watchOnGetNatures),
    fork(watchOnGetHealthNatures),
    fork(watchOnGetPropertyNatures),
    fork(watchOnGetTypeVehicle),
    fork(watchOnGetDeliveryLocations),
    fork(watchOnLogoutUser),
    fork(watchOnGetBanks),
    fork(watchOnGetBanksLife),
    fork(watchOnGetCountries),
    fork(watchOnGetCoverCategoriesForPolicy),
    fork(watchOnGetCoverGroupsForPolicy),
    fork(watchOnGetCoversForPolicy)
    ]);
}