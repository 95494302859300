import { put, call, takeEvery, fork, all } from 'redux-saga/effects';
import { getCustomer, login, logoutUser, register, requestPassword, updateCustomer, changePassword, checkActiveSession, hasProfile, confirmVerificationCode, resendVerificationCode, autoRegisterUser, autoLoginUser } from "../services/authServices";
import * as authActionCreators from "../actionCreators/authActionCreators";
import * as authActionTypes from "../actionTypes/authActionTypes";
import * as generalActionCreators from "../actionCreators/generalActionCreators";
import { history } from '../configureStore';

function* onLoginUser({ email, password, skipRedirect }: authActionTypes.LoginUserAction): any {
    try {
        yield put(authActionCreators.loginUserRequest());
        const response = yield call(login, email, password);
        if (response.data.succeeded) {
            yield put(authActionCreators.loginUserSuccess(response.data.data));
            yield put(generalActionCreators.showLoginModalReset());
            const toggle = document.getElementById("kt_quick_user_close");
            if (toggle) {
                toggle.click();
            }

            if (!skipRedirect) {
                history.push("/dashboard");
            }
        }
        else {
            if (response.data.responseCode === 11) {
                yield put(authActionCreators.resendMail(true));
            }

            yield put(authActionCreators.loginUserFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(authActionCreators.loginUserFailure(error));
    }
}


function* watchOnLoginUser() {
    yield takeEvery(authActionTypes.LOGIN_USER, onLoginUser);
}

function* onRegisterUser({ tipKind, clientno, nipt, title, lastname, sex, email, mob, address, place, birthdate, password }: authActionTypes.RegisterUserAction): any {
    try {
        yield put(authActionCreators.registerUserRequest());
        const response = yield call(register, tipKind, clientno, nipt, title, lastname, sex, email, mob, address, place, birthdate, password);
        if (response.data.succeeded) {
            yield put(authActionCreators.registerUserSuccess());
            yield put(generalActionCreators.showModal("Regjistrim I suksesshem", "Regjistrimi juaj ne portalin e klientit ishte I suksesshem. Do te njoftoheni me email kur llogaria juaj do te miratohet dhe aktivizohet.", ""));
        }
        else {
            yield put(authActionCreators.registerUserFailure(response.data.errorMessage));
        }
    } catch (error) {
        yield put(authActionCreators.registerUserFailure('Ndodhi nje gabim I papritur!'));
    }
}


function* watchOnRegisterUser() {
    yield takeEvery(authActionTypes.REGISTER_USER, onRegisterUser);
}

function* onRequestPasswordUser({ email }: authActionTypes.RequestPasswordAction): any {
    try {
        yield put(authActionCreators.requestPasswordRequest());
        const response = yield call(requestPassword, email);
        if (response.data.succeeded) {
            yield put(authActionCreators.requestPasswordSuccess());
            yield put(generalActionCreators.showModal("Rivendos Fjalekalimin", "Fjalekalimi juaj eshte rivendosur dhe u dergua me email. Ju lutem kontrolloni email-in juaj.", ""));
        }
        else {
            yield put(authActionCreators.requestPasswordFailure(response.data.errorMessage));
        }
    } catch (error: any) {
        yield put(authActionCreators.requestPasswordFailure(error));
    }
}


function* watchOnRequestPassword() {
    yield takeEvery(authActionTypes.REQUEST_PASSWORD, onRequestPasswordUser);
}

function* onGetCustomer({ customerId }: authActionTypes.GetCustomerAction): any {
    try {
        yield put(authActionCreators.getCustomerRequest());
        const response = yield call(getCustomer, customerId);
        if (response.data.succeeded) {
            yield put(authActionCreators.getCustomerSuccess(response.data.data));
        }
        else {
            yield put(authActionCreators.getCustomerFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(authActionCreators.getCustomerFailure(error));
    }
}

function* watchOnGetCustomer() {
    yield takeEvery(authActionTypes.GET_CUSTOMER, onGetCustomer)
}

function* onUpdateCustomer({ customer }: authActionTypes.UpdateCustomerAction): any {
    try {
        yield put(authActionCreators.updateCustomerRequest());
        let response = yield call(updateCustomer, customer);
        if (response.data.succeeded) {
            yield put(authActionCreators.updateCustomerSuccess());
            yield put(generalActionCreators.showModal("Perditesim I suksesshem", "Ndryshimi juaj eshte ruajtur", ""));
            response = yield call(getCustomer, customer.id);
            if (response.data.succeeded) {
                yield put(authActionCreators.getCustomerSuccess(response.data.data));
            }
        }
        else {
            yield put(authActionCreators.updateCustomerFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Perditesimi deshtoi", response.data.errorMessage, ""));
        }
    } catch (error) {
        yield put(authActionCreators.updateCustomerFailure('Ndodhi nje gabim I papritur!'));
    }
}


function* watchOnUpdateCustomer() {
    yield takeEvery(authActionTypes.UPDATE_CUSTOMER, onUpdateCustomer);
}

function* onChangePassword({ data }: authActionTypes.ChangePasswordAction): any {
    try {
        yield put(authActionCreators.changePasswordRequest());
        let response = yield call(changePassword, data);
        if (response.data.succeeded) {
            yield put(authActionCreators.changePasswordSuccess());
            //next action = 5, logout user after modal closed
            yield put(generalActionCreators.showModal("Perditesim I suksesshem", "Ndryshimi juaj eshte ruajtur", "5"));
        }
        else {
            yield put(authActionCreators.changePasswordFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Perditesimi deshtoi", response.data.errorMessage, ""));
        }
    } catch (error) {
        yield put(authActionCreators.changePasswordFailure('Ndodhi nje gabim I papritur'));
    }
}


function* watchOnChangePassword() {
    yield takeEvery(authActionTypes.CHANGE_PASSWORD, onChangePassword);
}

function* onLocationChange() {
    yield put(authActionCreators.requestPasswordFailure(""));
    yield put(authActionCreators.loginUserFailure(""));
    yield put(authActionCreators.registerUserFailure(""));
    yield put(authActionCreators.hasProfileReset());
    const toggle = document.getElementById("kt_quick_user_close");
    if (toggle) {
        toggle.click();
    }
}

function* watchOnLocationChange() {
    yield takeEvery('@@router/LOCATION_CHANGE', onLocationChange);
}

function* onCheckActiveSession({ token, customerId }: authActionTypes.CheckActiveSessionAction): any {
    try {
        yield put(authActionCreators.checkActiveSessionRequest());
        let response = yield call(checkActiveSession, token);
        if (response.data.succeeded) {
            yield put(authActionCreators.checkActiveSessionSuccess(response.data.data));
            //if not authenticated, remove user from store, else get customer
            if (response.data.data) {
                yield put(authActionCreators.getCustomer(customerId));
            }
            else
                yield put(authActionCreators.logoutUserSuccess());
        }
        else {
            yield put(authActionCreators.checkActiveSessionFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(authActionCreators.checkActiveSessionFailure(error));
    }
}

function* watchOnCheckActiveSession() {
    yield takeEvery(authActionTypes.CHECK_ACTIVE_SESSION, onCheckActiveSession)
}

function* onHasProfile({ email }: authActionTypes.HasProfileAction): any {
    try {
        yield put(authActionCreators.hasProfileRequest());
        let response = yield call(hasProfile, email);
        if (response.data.succeeded) {
            yield put(authActionCreators.hasProfileSuccess(response.data.data));
            yield put(generalActionCreators.continueWithoutAccountReset());

        }
        else {
            yield put(authActionCreators.hasProfileFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(authActionCreators.hasProfileFailure(error));
    }
}

function* watchOnHasProfile() {
    yield takeEvery(authActionTypes.HAS_PROFILE, onHasProfile)
}

function* onConfirmVerificationCode({ verificationCode, email }: authActionTypes.ConfirmVerificationCodeAction): any {
    try {
        yield put(authActionCreators.confirmVerificationCodeRequest());
        let response = yield call(confirmVerificationCode, verificationCode, email);
        if (response.data.succeeded) {
            yield put(authActionCreators.confirmVerificationCodeSuccess());
            yield put(authActionCreators.autoLoginUser(email));
        }
        else {
            yield put(authActionCreators.confirmVerificationCodeFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(authActionCreators.confirmVerificationCodeFailure(error));
    }
}

function* watchOnConfirmVerificationCode() {
    yield takeEvery(authActionTypes.CONFIRM_VERIFICATION_CODE, onConfirmVerificationCode)
}

function* onResendVerificationCode({ email }: authActionTypes.ResendVerificationCodeAction): any {
    try {
        yield put(authActionCreators.resendVerificationCodeRequest());
        let response = yield call(resendVerificationCode, email);
        if (response.data.succeeded) {
            yield put(authActionCreators.resendVerificationCodeSuccess());
            yield put(generalActionCreators.showSuccessToast("Kodi i ri i verifikimit është dërguar në adresën tuaj të emailit"));
        }
        else {
            yield put(authActionCreators.resendVerificationCodeFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(authActionCreators.resendVerificationCodeFailure(error));
    }
}

function* watchOnResendVerificationCode() {
    yield takeEvery(authActionTypes.RESEND_VERIFICATION_CODE, onResendVerificationCode)
}

function* onLogoutUser({ token }: authActionTypes.CheckActiveSessionAction): any {
    yield put(authActionCreators.logoutUserRequest());
    yield call(logoutUser, token);
    yield put(authActionCreators.logoutUserSuccess());
}

function* watchOnLogoutUser() {
    yield takeEvery(authActionTypes.LOGOUT_USER, onLogoutUser)
}

function* onAutoRegisterUser({ tipKind, clientno, nipt, title, lastname, sex, email, mob, address, place, birthdate, password }: authActionTypes.AutoRegisterUserAction): any {
    try {
        yield put(authActionCreators.autoRegisterUserRequest());
        const response = yield call(autoRegisterUser, tipKind, clientno, nipt, title, lastname, sex, email, mob, address, place, birthdate, password);
        if (response.data.succeeded) {
            yield put(authActionCreators.autoRegisterUserSuccess());
            yield put(generalActionCreators.showRegistrationModalReset());
            yield put(authActionCreators.hasProfile(email));
            yield put(generalActionCreators.showVerificationModal());
        }
        else {
            yield put(authActionCreators.autoRegisterUserFailure(response.data.errorMessage));
        }
    } catch (error) {
        yield put(authActionCreators.autoRegisterUserFailure('Ndodhi nje gabim I papritur!'));
    }
}

function* watchOnAutoRegisterUser() {
    yield takeEvery(authActionTypes.AUTO_REGISTER_USER, onAutoRegisterUser);
}

function* onAutoLoginUser({ email }: authActionTypes.AutoLoginUserAction): any {
    try {
        yield put(authActionCreators.autoLoginUserRequest());
        let response = yield call(autoLoginUser, email);
        if (response.data.succeeded) {
            yield put(authActionCreators.autoLoginUserSuccess(response.data.data));
            yield put(generalActionCreators.showVerificationModalReset());
        }
        else {
            yield put(authActionCreators.autoLoginUserFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(authActionCreators.autoLoginUserFailure(error));
    }
}

function* watchOnAutoLoginUser() {
    yield takeEvery(authActionTypes.AUTO_LOGIN_USER, onAutoLoginUser)
}

function* onLogoutUserSuccess({ }: authActionTypes.LoginUserSuccessAction): any {
    history.push("/auth/login");
}

function* watchOnLogoutUserSuccess() {
    yield takeEvery(authActionTypes.LOGOUT_USER_SUCCESS, onLogoutUserSuccess)
}

export default function* authSaga() {
    yield all([fork(watchOnLoginUser),
    fork(watchOnRegisterUser),
    fork(watchOnRequestPassword),
    fork(watchOnLocationChange),
    fork(watchOnGetCustomer),
    fork(watchOnUpdateCustomer),
    fork(watchOnChangePassword),
    fork(watchOnLogoutUser),
    fork(watchOnCheckActiveSession),
    fork(watchOnHasProfile),
    fork(watchOnConfirmVerificationCode),
    fork(watchOnResendVerificationCode),
    fork(watchOnAutoRegisterUser),
    fork(watchOnAutoLoginUser),
    fork(watchOnLogoutUserSuccess)
    ]);
}