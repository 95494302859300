import { MotorInsQuotation, BookingData, MotorPolicy } from '../../utils/policyTypes';
import * as actions from "../actionTypes/gcPolicyActionTypes";

export function getAMFGCQuotation(
    customerId: string,
    platenumber: string,
    chassis: string,
    insuredPeriod: string,
    isCustomerVehicleOwner: boolean
): actions.GetAMFGCQuotationAction {
    return {
        type: actions.GET_AMFGC_QUOTATION,
        customerId, platenumber, chassis, insuredPeriod, isCustomerVehicleOwner
    };
}

export function getAMFGCQuotationRequest(
): actions.GetAMFGCQuotationRequestAction {
    return {
        type: actions.GET_AMFGC_QUOTATION_REQUEST
    };
}

export function getAMFGCQuotationReset(
): actions.GetAMFGCQuotationResetAction {
    return {
        type: actions.GET_AMFGC_QUOTATION_RESET
    };
}

export function getAMFGCQuotationSuccess(
    values: MotorInsQuotation
): actions.GetAMFGCQuotationSuccessAction {
    return {
        type: actions.GET_AMFGC_QUOTATION_SUCCESS,
        data: values
    };
}

export function getAMFGCQuotationFailure(
    error: Error | string
): actions.GetAMFGCQuotationFailureAction {
    return {
        type: actions.GET_AMFGC_QUOTATION_FAILURE,
        error
    };
}

export function getGCQuotation(
    customerId: string,
    gcVehicleCategoryClass: string,
    prodyear: number,
    power: number,
    insuredPeriod: string
): actions.GetGCQuotationAction {
    return {
        type: actions.GET_GC_QUOTATION,
        customerId, gcVehicleCategoryClass, prodyear, power, insuredPeriod
    };
}

export function getGCQuotationRequest(
): actions.GetGCQuotationRequestAction {
    return {
        type: actions.GET_GC_QUOTATION_REQUEST
    };
}

export function getGCQuotationReset(
): actions.GetGCQuotationResetAction {
    return {
        type: actions.GET_GC_QUOTATION_RESET
    };
}

export function getGCQuotationSuccess(
    values: MotorInsQuotation
): actions.GetGCQuotationSuccessAction {
    return {
        type: actions.GET_GC_QUOTATION_SUCCESS,
        data: values
    };
}

export function getGCQuotationFailure(
    error: Error | string
): actions.GetGCQuotationFailureAction {
    return {
        type: actions.GET_GC_QUOTATION_FAILURE,
        error
    };
}

export function bookGCPolicy(
    policy: MotorPolicy,
    payment: string
): actions.BookGCPolicyAction {
    return {
        type: actions.BOOK_GC_POLICY,
        policy,
        payment
    };
}

export function bookGCPolicyRequest(
): actions.BookGCPolicyRequestAction {
    return {
        type: actions.BOOK_GC_POLICY_REQUEST
    };
}

export function bookGCPolicySuccess(
    values: BookingData
): actions.BookGCPolicySuccessAction {
    return {
        type: actions.BOOK_GC_POLICY_SUCCESS,
        data: values
    };
}

export function bookGCPolicyFailure(
    error: Error | string
): actions.BookGCPolicyFailureAction {
    return {
        type: actions.BOOK_GC_POLICY_FAILURE,
        error
    };
}