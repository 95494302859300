import React, { FunctionComponent } from 'react';
import { useField, useFormikContext } from 'formik';
import { Nomen } from '../../utils';

const getFieldCSSClasses = (touched: boolean, error: string | undefined) => {
    const classes = ["form-control"];
    if (touched && error) {
        classes.push("is-invalid");
    }

    if (touched && !error) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

interface Props {
    name: string;
    label: string;
    items: Nomen[];
    newvalue?: any;
    disabled?: boolean;
    change: (value: any) => void;
    inline?: boolean;
}

const CustomSelectField: FunctionComponent<Props> = (props) => {
    const [
        field,
        { error, touched },
    ] = useField({
        name: props.name,
        type: props.name
    });
    const {
        setFieldValue,
    } = useFormikContext();

    React.useEffect(() => {
        if (!!props.newvalue && !field.value) {
            setFieldValue(props.name, props.newvalue);
        }
    }, [setFieldValue, props.newvalue]);

    const handleChange = (value: any) => {
        setFieldValue(props.name, value);
        props.change(value);
    }

    return (<>
        {!props.inline ?
            <div className="form-group">
                <label>{props.label}</label>
                <select {...field} {...props} disabled={props.disabled} onChange={(event: any) => handleChange(event.target.value)} className={"form-control form-control-solid form-control-lg" + getFieldCSSClasses(touched, error)}>
                    <option key={-1} value="">Zgjidh</option>
                    {props.items.map(item => (<option key={item.code + "_" + item.name} value={item.code}>{item.name}</option>))}
                </select>
                {error !== "" && (touched || !field.value) ? (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{error}</div>
                    </div>
                ) : null}
            </div>
            :
            <div className="form-group">
                <label className="inlineLbl">{props.label}</label>
                <div className="inlineField">
                    <select {...field} {...props} disabled={props.disabled} onChange={(event: any) => handleChange(event.target.value)} className={"b-dark form-control form-control-solid form-control-lg" + getFieldCSSClasses(touched, error)}>
                        <option key={-1} value="">Zgjidh</option>
                        {props.items.map(item => (<option key={item.code + "_" + item.name} value={item.code}>{item.name}</option>))}
                    </select>
                    {error !== "" && (touched || !field.value) ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{error}</div>
                        </div>
                    ) : null}
                </div>
            </div>
        }

    </>

    );
};

export default CustomSelectField;