import * as React from 'react';
import { useSelector } from 'react-redux';
import InputFieldMain from '../../Custom/InputFieldMain';
import SelectField from '../../Custom/SelectField';
import { AppState } from "../../../redux/reducers/rootReducer";
import { Nomen } from "../../../utils";
import { MotorInsQuotation } from '../../../utils/policyTypes';
import * as tplActionTypes from "../../../redux/actionTypes/tplPolicyActionTypes";
import * as generalActionTypes from "../../../redux/actionTypes/generalActionTypes";
import { Loading } from '../../Layout/Loading';

const VehicleDetails = () => {
    const classTypes = useSelector<AppState, Nomen[]>((state: any) => state.nomen.TPLVehicleCategoryClassTypes);
    const marks = useSelector<AppState, Nomen[]>((state: any) => state.nomen.VehicleMarks);
    const motorInsQuotation = useSelector<AppState, MotorInsQuotation | null>((state: any) => state.policy.motorInsQuotation);
    const isGetAmftplQuotLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[tplActionTypes.GET_AMFTPL_QUOTATION]);
    const isCheckFValidLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[generalActionTypes.CHECK_FVALID]);

    return (<>
        {isGetAmftplQuotLoading || isCheckFValidLoading
            ? <Loading />
            :
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
              
                {motorInsQuotation ? <div className="alert alert-custom alert-notice alert-light-primary fade show boxTotal" role="alert">
                    <div className="alert-icon"><i className="far fa-credit-card"></i></div>
                    <div className="alert-text">Total Primi + Taksa per Blerje Online <span className="spanTotal">{motorInsQuotation.total} LEK</span></div>

                </div> : ''}


                <div className="row">
                    <div className="col-xl-6">
                        <SelectField name="categoryClassTypeVehicle" newvalue={motorInsQuotation?.vehicle?.categoryClassTypeVehicle.code || ''} label="Mjeti/Klasa" items={classTypes} />
                    </div>
                    <div className="col-xl-6">
                        <SelectField name="mark" label="Prodhuesi" newvalue={motorInsQuotation?.vehicle?.mark.code || ''} items={marks} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="tip" newvalue={motorInsQuotation?.vehicle?.tip || ''} label="Modeli" placeholder="Modeli" />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="power" newvalue={motorInsQuotation?.vehicle?.power.toString() || ''} label="Fuqia " placeholder="Fuqia" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="prodYear" newvalue={motorInsQuotation?.vehicle?.prodyear.toString() || ''} label="Viti i prodhimit" placeholder="Viti i prodhimit	" />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="placenr" newvalue={motorInsQuotation?.vehicle?.placenr.toString() || ''} label="Nr. I ulëseve" placeholder="Nr. I ulëseve" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="capacity" newvalue={motorInsQuotation?.vehicle?.capacity.toString() || ''} label="Pesha e automjetit" placeholder="Pesha e automjetit" />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="colour" newvalue={motorInsQuotation?.vehicle?.colour || ''} label="Ngjyra e automjetit" placeholder="Ngjyra e automjetit" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="used" newvalue={motorInsQuotation?.vehicle?.used || ''} label="Qëllimi i përdorimit	" placeholder="Qëllimi i përdorimit	" />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="vehicleLicence" newvalue={motorInsQuotation?.vehicle?.vehiclelicence || ''} label="Leje qarkullimi" placeholder="Leje qarkullimi" />
                    </div>
                </div>


               
            </div>
            
        }
    </>
    );
}

export default VehicleDetails;
