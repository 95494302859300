import * as React from 'react';
import steps from './steps';

type Props = {
    currentStep: string;
}

const Nav = (props: Props) => {
    const currentIndex = steps.findIndex(step => step.id === props.currentStep);
    return (
        <div className="wizard-nav">
            <div className="wizard-steps">
                {steps.map((step, index) => {
                    return (<div className="wizard-step" key={step.id} data-wizard-type="step" data-wizard-state={index === currentIndex ? "current" : index > currentIndex ? "done" : ""}>
                        <div className="wizard-wrapper">
                            <div className="wizard-number">
                                {index + 1}
                            </div>
                            <div className="wizard-label">
                                <div className="wizard-title">
                                    {step.name}
                                </div>
                                <div className="wizard-desc">
                                    {step.description}
                                </div>
                            </div>
                        </div>
                    </div>)
                })}
            </div>
        </div>
    );
}


export default Nav;