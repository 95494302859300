import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers/rootReducer';
import { preparePiPolicy, ThInsQuotation } from '../../../utils/policyTypes';
import * as piActionTypes from "../../../redux/actionTypes/piPolicyActionTypes";
import { Loading } from '../../Layout/Loading';
import Button from 'react-bootstrap/Button';
import * as thActions from "../../../redux/actionCreators/thPolicyActionCreators";
import * as piActions from "../../../redux/actionCreators/piPolicyActionCreators";
import store from '../../../redux/configureStore';
import { useFormikWizard } from 'formik-wizard';
import PdfProperty from "../../../../src/InsuranceConditions/09_new.pdf";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";

const Packages = () => {
    const { values: wizardValues }: any = useFormikWizard();
    const piQuotation = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.piQuotation);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[piActionTypes.GET_PI_QUOTATION]);

    const choosePackageQuotation = (packageQuotation: any) => {
        store.dispatch(piActions.getPackagePIQuotationReset());
        store.dispatch(thActions.chooseQuotationSuccess());

        const state = store.getState();
        const customerId = state.auth.user?.customerId;

        const piPolicy = preparePiPolicy(customerId, wizardValues, packageQuotation.packageId);

        store.dispatch(piActions.getPackagePIQuotation(piPolicy));
    }

    const useStyles = makeStyles(theme => ({
        customTooltip: {
            backgroundColor: '#3699FF',
            fontSize: '10px',
            opacity: '0.6 !important'
        }
    }));

    const classes = useStyles();

    return (<>
        {isLoading
            ? <Loading />
            :
            <>
                <div className="wizardTitle">Paketa</div>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    {piQuotation != null && <div className="card-deck">
                        {piQuotation.map((quotation: any, index: number) => (
                            <div className="card" key={index + 1}>
                                <div className="card-body">
                                    <h5 className="card-title">{quotation.packageName}</h5>
                                    <p className="card-text pSum-pkg">Shuma e siguruar: {quotation.insuredAmountV.toLocaleString()}€</p>
                                    {quotation.covers.map((cover: any, indexC: number) => (
                                        <p key={indexC + 1} className="card-text">
                                            <span className='pCheck'>✔ </span>
                                            <span dangerouslySetInnerHTML={{ __html: cover.replaceAll("#", "<br />") }}></span>
                                        </p>
                                    ))}
                                </div>
                                <div className="card-footer">
                                    {quotation.packageDiscount > 0 && <p className='pDiscount'>
                                        {quotation.totalVNoDiscount.toFixed(2)}  {quotation.currencyName ? quotation.currencyName : '€'}

                                        <Tooltip
                                            arrow
                                            title="Aplikohet zbritja prej 10% ne kete police"
                                            placement="top"
                                            classes={{
                                                tooltip: classes.customTooltip
                                            }}>
                                            <IconButton className="blue">
                                                <Info />
                                            </IconButton>
                                        </Tooltip>
                                    </p>}
                                    <p className='pTotal'>
                                        {parseFloat(quotation.totalV).toFixed(2)}  {quotation.currencyName ? quotation.currencyName : '€'}
                                    </p>
                                    <Button variant="primary" className='btn btn-wiz font-weight-bold pBtn' onClick={() => choosePackageQuotation(quotation)} >Vazhdo</Button>
                                </div>
                            </div>
                        ))}
                    </div>}
                    <hr />
                    <a href={PdfProperty} target="_blank">Kushtet e përgjithshme të sigurimit</a>
                </div>
            </>}
    </>

    );
}
export default connect()(Packages);
