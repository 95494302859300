import { MotorInsQuotation, BookingData, MotorPolicy } from '../../utils/policyTypes';
import * as actions from "../actionTypes/bPolicyActionTypes";

export function getBQuotation(
    customerId: string,
    bVehicleCategoryClass: string,
    prodyear: number,
    power: number,
    insuredPeriod: string
): actions.GetBQuotationAction {
    return {
        type: actions.GET_B_QUOTATION,
        customerId, bVehicleCategoryClass, prodyear, power, insuredPeriod
    };
}

export function getBQuotationRequest(
): actions.GetBQuotationRequestAction {
    return {
        type: actions.GET_B_QUOTATION_REQUEST
    };
}

export function getBQuotationReset(
): actions.GetBQuotationResetAction {
    return {
        type: actions.GET_B_QUOTATION_RESET
    };
}

export function getBQuotationSuccess(
    values: MotorInsQuotation
): actions.GetBQuotationSuccessAction {
    return {
        type: actions.GET_B_QUOTATION_SUCCESS,
        data: values
    };
}

export function getBQuotationFailure(
    error: Error | string
): actions.GetBQuotationFailureAction {
    return {
        type: actions.GET_B_QUOTATION_FAILURE,
        error
    };
}

export function bookBPolicy(
    policy: MotorPolicy,
    payment: string
): actions.BookBPolicyAction {
    return {
        type: actions.BOOK_B_POLICY,
        policy,
        payment
    };
}

export function bookBPolicyRequest(
): actions.BookBPolicyRequestAction {
    return {
        type: actions.BOOK_B_POLICY_REQUEST
    };
}

export function bookBPolicySuccess(
    values: BookingData
): actions.BookBPolicySuccessAction {
    return {
        type: actions.BOOK_B_POLICY_SUCCESS,
        data: values
    };
}

export function bookBPolicyFailure(
    error: Error | string
): actions.BookBPolicyFailureAction {
    return {
        type: actions.BOOK_B_POLICY_FAILURE,
        error
    };
}