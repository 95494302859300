import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import InputFieldMain from '../../Custom/InputFieldMain';
import InputAreaFieldMain from '../../Custom/InputAreaFieldMain';
import TimeInput from '../../Custom/TimeInput';
import SelectField from '../../Custom/SelectField';
import DatePickerFieldMain from '../../Custom/DatePickerFieldMain';
import { AppState } from '../../../redux/reducers/rootReducer';
import { Nomen } from '../../../utils';
import { Loading } from '../../Layout/Loading';
import store from '../../../redux/configureStore';
import * as actions from '../../../redux/actionCreators/claimActionCreators';
import * as claimActionTypes from '../../../redux/actionTypes/claimActionTypes';
import { PreClaim } from '../../../utils/claimTypes';

const PreClaimMain = () => {
    //show only Material demtim ne Mjet
    const natures = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Natures).filter((n: Nomen) => n.code === "1");
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[claimActionTypes.FETCH_KASKO_DATA_FROM_POLICY]);

    const kasko = useSelector<AppState, PreClaim | null>((state: any) => state.claim.kasko);

    const getKaskoData = (value: any) => {
        store.dispatch(actions.fetchKaskoDataFromPolicyReset());
        store.dispatch(actions.fetchKaskoDataFromPolicy(value));
    };

    return (<>
        {isLoading
            ? <Loading />
            :
            <>
                <div className="wizardTitle">Detajet e Dëmtimit / Shërbimit</div>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div className="mb-10 font-weight-bold text-dark"></div>
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="policy_code" label="Numri i policës" placeholder="Numri i policës" blur={getKaskoData} />
                        </div>
                        <div className="col-xl-6">
                            <SelectField name="nature" label="Natyra e dëmit" items={natures} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 addSpace">
                            <DatePickerFieldMain name="date_accident" type="text" placeholder="Data e aksidentit" />
                        </div>
                        <div className="col-xl-6">
                            <InputAreaFieldMain type="text" name="description_damage" label="Përshkrimi i dëmit" placeholder="Përshkrimi i dëmit" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <SelectField name="place_accident" label="Vendi i ngjarjes" items={places} />
                        </div>
                        <div className="col-xl-6 ">
                            <InputAreaFieldMain type="text" name="description_accident" label="Përshkrimi i aksidentit" placeholder="Përshkrimi i aksidentit" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="europeanreportnr" label="Nr. i raportit europian" placeholder="Nr. i raportit europian" />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="policyreportnr" label="Nr. i raportit të Policisë" placeholder="Nr. i raportit të Policisë" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            {/* time */}
                            <TimeInput type="text" name="hour_accident" label="Ora e aksidentit" />
                        </div>

                        {kasko != null && kasko.objectCover &&
                            <div className="col-xl-6">
                                {/* <label>Mbulimi</label>
                                {kasko.objectCover.map((cover: any, index: number) => (
                                    <CheckboxField key={index + 1} name={"cover[" + cover.coverGroupCode + "]"} type="checkbox" label={cover.coverGroupName} />
                                ))} */}

                                <SelectField name="covercategory" label="Mbulimi" items={kasko.objectCover.map((cover: any) => { return { code: cover.coverGroupCode, name: cover.coverGroupName } as Nomen })} />
                            </div>}
                    </div>
                </div>
            </>}
    </>
    );
}
export default connect()(PreClaimMain);
