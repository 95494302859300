import { put, call, takeEvery, fork, all } from 'redux-saga/effects';
import { searchPolicy, getPolicy, confirmPolicy, getPaymentResponse, checkFValid, downloadPolicy, exportSearchPolicy, getAppInfo } from "../services/generalServices";
import * as generalActionCreators from "../actionCreators/generalActionCreators";
import * as tplPolicyActionCreators from "../actionCreators/tplPolicyActionCreators";
import * as gcPolicyActionCreators from "../actionCreators/gcPolicyActionCreators";
import * as bPolicyActionCreators from "../actionCreators/bPolicyActionCreators";
import * as thPolicyActionCreators from "../actionCreators/thPolicyActionCreators";
import * as generalActionTypes from "../actionTypes/generalActionTypes";
import { saveAs } from 'file-saver';

function* onSearchPolicy({ filter }: generalActionTypes.SearchPolicyAction): any {
    try {
        yield put(generalActionCreators.searchPolicyRequest());
        let response = yield call(searchPolicy, filter);
        if (response.data.succeeded) {
            yield put(generalActionCreators.searchPolicySuccess(response.data.data, response.data.count));
        }
        else {
            yield put(generalActionCreators.searchPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Kerkimi I polices deshtoi", response.data.errorMessage, ""));
        }
    } catch (error) {
        yield put(generalActionCreators.searchPolicyFailure('Ndodhi nje gabim I papritur!'));
    }
}


function* watchOnSearchPolicy() {
    yield takeEvery(generalActionTypes.SEARCH_POLICY, onSearchPolicy);
}

function* onGetPolicy({ policyId }: generalActionTypes.PolicyPreviewAction): any {
    try {
        yield put(generalActionCreators.showPolicyModal());
        yield put(generalActionCreators.policyPreviewRequest());
        const response = yield call(getPolicy, policyId);
        if (response.data.succeeded) {
            yield put(generalActionCreators.policyPreviewSuccess(response.data.data));
        }
        else {
            yield put(generalActionCreators.policyPreviewFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim ne marrjen e polices", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(generalActionCreators.policyPreviewFailure(error));
    }
}

function* watchOnGetPolicy() {
    yield takeEvery(generalActionTypes.POLICY_PREVIEW, onGetPolicy)
}

function* onConfirmPolicy({ confirmData }: generalActionTypes.ConfirmPolicyAction): any {
    try {
        yield put(generalActionCreators.confirmPolicyRequest());
        let response = yield call(confirmPolicy, confirmData);
        if (response.data.succeeded) {
            yield put(generalActionCreators.confirmPolicySuccess(response.data.data));
            yield put(generalActionCreators.bookingDataReset());
        }
        else {
            yield put(generalActionCreators.confirmPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.bookingDataReset());
            if (response.data.action !== "2") {
                yield put(generalActionCreators.showModal("Konfirmimi I polices deshtoi", response.data.errorMessage, ""));
            }
        }
    } catch (error) {
        yield put(generalActionCreators.confirmPolicyFailure('Ndodhi nje gabim I papritur!'));
        yield put(generalActionCreators.bookingDataReset());
    }
}


function* watchOnConfirmPolicy() {
    yield takeEvery(generalActionTypes.CONFIRM_POLICY, onConfirmPolicy);
}

function* onGetPaymentResponse({ bankOrderId }: generalActionTypes.GetPaymentResponseAction): any {
    try {
        yield put(generalActionCreators.getPaymentResponseRequest());
        const response = yield call(getPaymentResponse, bankOrderId);
        if (response.data.succeeded) {
            yield put(generalActionCreators.getPaymentResponseSuccess(response.data.data));
        }
        else {
            yield put(generalActionCreators.getPaymentResponseFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Deshtimi i pageses", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(generalActionCreators.getPaymentResponseFailure(error));
    }
}

function* watchOnGetPaymentResponse() {
    yield takeEvery(generalActionTypes.GET_PAYMENT_RESPONSE, onGetPaymentResponse)
}

function* onCheckFValid({ customerId, typePolicy, subtype, fvalid, plateNumber, chassis, categoryClassTypeVehicle, prodYear, power, insuredPeriod, thPolicy, isCustomerVehicleOwner }: generalActionTypes.CheckFValidAction): any {
    try {
        yield put(generalActionCreators.checkFValidRequest());
        yield put(generalActionCreators.checkFValidReset());
        const response = yield call(checkFValid, customerId, typePolicy, subtype, fvalid);
        if (response.data.succeeded) {
            yield put(generalActionCreators.checkFValidSuccess(response.data.data));

            if (response.data.data) {
                if (typePolicy === "01" && subtype === "01")
                    yield put(tplPolicyActionCreators.getAMFTPLQuotation(customerId, plateNumber, chassis, isCustomerVehicleOwner));
                if (typePolicy === "01" && subtype === "02")
                    yield put(gcPolicyActionCreators.getAMFGCQuotation(customerId, plateNumber, chassis, insuredPeriod, isCustomerVehicleOwner));
                if (typePolicy === "01" && subtype === "03")
                    yield put(bPolicyActionCreators.getBQuotation(customerId, categoryClassTypeVehicle, prodYear!, power!, insuredPeriod));
                if (typePolicy === "05")
                    yield put(thPolicyActionCreators.getTHQuotation(thPolicy!));
            }
            else {
                yield put(generalActionCreators.checkFValidFailure(response.data.errorMessage));
                yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, ""));
            }
        }
        else {
            yield put(generalActionCreators.checkFValidFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Gabim", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(generalActionCreators.checkFValidFailure(error));
    }
}

function* watchOnCheckFValid() {
    yield takeEvery(generalActionTypes.CHECK_FVALID, onCheckFValid)
}

function* onDownloadPolicy({ policyId }: generalActionTypes.DownloadPolicyAction): any {
    try {
        yield put(generalActionCreators.downloadPolicyRequest());
        const response = yield call(downloadPolicy, policyId);
        if (response !== null && response.status === 200 && response.data !== "" && response.data !== null) {
            yield put(generalActionCreators.downloadPolicySuccess());
            let url = window.URL.createObjectURL(new Blob([response.data]));
            saveAs(url, "polica.pdf");
        }
        else {
            yield put(generalActionCreators.downloadPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(generalActionCreators.downloadPolicyFailure(error));
        yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
    }
}

function* watchOnDownloadPolicy() {
    yield takeEvery(generalActionTypes.DOWNLOAD_POLICY, onDownloadPolicy)
}


function* onExportSearchPolicy({ filter }: generalActionTypes.ExportSearchPolicyAction): any {
    try {
        yield put(generalActionCreators.exportSearchPolicyRequest());
        const response = yield call(exportSearchPolicy, filter);
        if (response !== null && response.status === 200 && response.data !== "" && response.data !== null) {
            yield put(generalActionCreators.exportSearchPolicySuccess());
            let url = window.URL.createObjectURL(new Blob([response.data]));
            saveAs(url, "Kerko polica.xlsx");
        }
        else {
            yield put(generalActionCreators.exportSearchPolicyFailure(response.data.errorMessage));
            yield put(generalActionCreators.showModal("Dështimi", response.data.errorMessage, ""));
        }
    } catch (error: any) {
        yield put(generalActionCreators.exportSearchPolicyFailure(error));
        yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
    }
}

function* watchOnExportSearchPolicy() {
    yield takeEvery(generalActionTypes.EXPORT_SEARCH_POLICY, onExportSearchPolicy)
}

function* onLocationChange() {
    yield put(generalActionCreators.showLoginModalReset());
    yield put(generalActionCreators.showRegistrationModalReset());
    yield put(generalActionCreators.showVerificationModalReset());
    yield put(generalActionCreators.continueWithoutAccountReset());
    yield put(generalActionCreators.showCreateAccountQuestionModalReset());
}

function* watchOnLocationChange() {
    yield takeEvery('@@router/LOCATION_CHANGE', onLocationChange);
}

function* onGetAppInfo({ }: generalActionTypes.GetAppInfoAction): any {
    try {
        const response = yield call(getAppInfo);
        if (response.data.succeeded) {
            yield put(generalActionCreators.getAppInfoSuccess(response.data.data));
        }
        else {
            yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
        }
    } catch (error: any) {
        yield put(generalActionCreators.showModal("Dështimi", "Per shkak te problemeve teknike nuk jemi ne gjendje ta ekzekutojme kete veprim ne kete moment. Ju lutemi rifreskoni faqen!", ""));
    }
}

function* watchOnGetAppInfo() {
    yield takeEvery(generalActionTypes.GET_APP_INFO, onGetAppInfo)
}

export default function* generalSaga() {
    yield all([fork(watchOnSearchPolicy),
    fork(watchOnGetPolicy),
    fork(watchOnGetPaymentResponse),
    fork(watchOnConfirmPolicy),
    fork(watchOnCheckFValid),
    fork(watchOnDownloadPolicy),
    fork(watchOnExportSearchPolicy),
    fork(watchOnLocationChange),
    fork(watchOnGetAppInfo)
    ]);
}