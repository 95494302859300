import React from 'react';
import { withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import FormikWizard from 'formik-wizard';
import { HideModalAction } from '../../../../redux/actionTypes/generalActionTypes';
import steps from './steps';
import { FormikWizardWrapperProps } from '../../../../utils/wizardTypes';
import SimpleModal from '../../../General/SimpleModal';
import Nav from './Nav';
import { hideModal } from '../../../../redux/actionCreators/generalActionCreators';
import { RouteComponentProps } from '../../../Layout/Content';
import { AppState } from '../../../../redux/reducers/rootReducer';
import { preparePrivateHealthPolicy, ThInsQuotation, PhPolicy } from '../../../../utils/policyTypes';
import * as thActions from "../../../../redux/actionCreators/thPolicyActionCreators";
import * as phActions from "../../../../redux/actionCreators/phPolicyActionCreators";
import store from '../../../../redux/configureStore';
import * as nomenActions from '../../../../redux/actionCreators/nomenActionCreators';
import { BookPHPolicyAction } from '../../../../redux/actionTypes/phPolicyActionTypes';
import * as phPolicyActionTypes from '../../../../redux/actionTypes/phPolicyActionTypes';
import { User } from '../../../../redux/types';
import LoginModal from '../../../Auth/LoginModal';
import RegistrationModal from '../../../Auth/Registration/RegistrationModal';
import VerificationCodeModal from '../../../Auth/Registration/VerificationCodeModal';
import * as generalActions from "../../../../redux/actionCreators/generalActionCreators";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import CreateAccountQuestionModal from '../../../Auth/CreateAccountQuestionModal';

interface Props {
    closeModal(): void;
    onBookPHPolicy(phPolicy: PhPolicy): void;
    showModal: boolean;
    header: string;
    description: string;
    nextStep: string;
    renderAsHtml?: boolean;
    history: History;
    user: User;
    chosenPHQuotation: any;
}

interface PathProps {
    history: any;
}

function FormWrapper({
    children,
    isLastStep,
    goToPreviousStep,
    canGoBack,
    actionLabel,
    currentStep,
    values,
    wizard
}: FormikWizardWrapperProps<any, any>) {
    const dispatch = useDispatch();
    const phQuotationErrored = useSelector<AppState, boolean | null>((state: any) => state.policy.phQuotationErrored);
    const phQuestionsErrored = useSelector<AppState, boolean | null>((state: any) => state.policy.phQuestionsErrored);
    const phPackageQuotationErrored = useSelector<AppState, null | Error | string>((state: any) => state.error[phPolicyActionTypes.GET_PACKAGE_PH_QUOTATION]);

    const policyQuotationChosen = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.policyQuotationChosen);
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const showLoginModal = useSelector<AppState, boolean | null>((state: any) => state.general.showLoginModal);
    const showRegistrationModal = useSelector<AppState, boolean | null>((state: any) => state.general.showRegistrationModal);
    const showVerificationModal = useSelector<AppState, boolean | null>((state: any) => state.general.showVerificationModal);
    const continueWithoutAccount = useSelector<AppState, boolean | null>((state: any) => state.general.continueWithoutAccount);
    const showCreateAccountQuestionModal = useSelector<AppState, boolean | null>((state: any) => state.general.showCreateAccountQuestionModal);

    const chosenPHQuotation = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.chosenPHQuotation);
    const phPackageQuotation = chosenPHQuotation ? chosenPHQuotation[0] : null;

    const hideNextBtn = currentStep === steps[1].id;

    const showSuccessToast = useSelector<AppState, boolean>((state: any) => state.general.showSuccessToast);
    const successToastText = useSelector<AppState, boolean>((state: any) => state.general.successToastText);

    if (!!showSuccessToast) {
        toast.success(successToastText);
        dispatch({ type: "generalActionTypes/SHOW_SUCCESS_TOAST_RESET" });
    }

    if (currentStep === steps[1].id && (phQuestionsErrored || phQuotationErrored)) {
        goToPreviousStep();
    }

    if (currentStep === steps[2].id && continueWithoutAccount) {
        wizard.next();
    }

    if (currentStep === steps[3].id && ((!user && !continueWithoutAccount) || !!phPackageQuotationErrored)) {
        goToPreviousStep();
    }

    if (hideNextBtn && policyQuotationChosen) {
        store.dispatch(thActions.chooseQuotationReset());
        store.dispatch(nomenActions.getPlaces());

        wizard.next();
    }

    const handleCloseLoginModal = () => {
        store.dispatch(generalActions.showLoginModalReset());
    };

    const handleCloseRegistrationModal = () => {
        store.dispatch(generalActions.showRegistrationModalReset());
    };

    const handleCloseVerificationModal = () => {
        store.dispatch(generalActions.showVerificationModalReset());
    };

    const handleCloseCreateAccountQuestionModal = () => {
        store.dispatch(generalActions.showCreateAccountQuestionModalReset());
    };

    const goBack = () => {
        if (isLastStep) {
            store.dispatch(generalActions.continueWithoutAccountReset());
        }

        goToPreviousStep();
    }

    const useStyles = makeStyles(theme => ({
        customTooltip: {
            backgroundColor: '#3699FF',
            fontSize: '10px',
            opacity: '0.6 !important'
        }
    }));

    const classes = useStyles();

    return (
        <>
            <LoginModal show={showLoginModal} onClose={handleCloseLoginModal} onHide={() => { return; }} />
            <RegistrationModal show={showRegistrationModal} onClose={handleCloseRegistrationModal} onHide={() => { return; }} />
            <VerificationCodeModal show={showVerificationModal} onClose={handleCloseVerificationModal} onHide={() => { return; }} />
            <CreateAccountQuestionModal show={showCreateAccountQuestionModal} onClose={handleCloseCreateAccountQuestionModal} onHide={() => { return; }} />
            
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                theme="colored"
            />

            <div className="wizard wizard-4" id="kt_wizard_v4" data-wizard-state="step-first" data-wizard-clickable="true">
                <Nav currentStep={currentStep} />
                <div className="card card-custom card-shadowless rounded-top-0">
                    <div className="card-body p-0">
                        <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                            <div className="col-xl-12 col-xxl-7">
                                {children}
                                <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                    <div className="mr-2">
                                        {canGoBack && <button type="button" onClick={goBack} className="btn-wiz-back font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-prev">
                                            <i className="arrow left"></i>Kthehu
                                        </button>
                                        }
                                    </div>

                                    {!hideNextBtn &&
                                        <div>
                                            {isLastStep && <button type="submit" className="btn btn-wiz font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-submit">
                                                {actionLabel || 'Submit'}
                                            </button>}
                                            {!isLastStep && <button type="submit" className="btn btn-wiz font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-next">
                                                {actionLabel || 'Next'}
                                            </button>}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {phPackageQuotation && <div className="col-xl-12 packageCard">
                <div className="card offcanvas offcanvas-right offcanvas-on card-c" >
                    <div className="card-body card-b-aside">
                        <h5 className="card-title">{phPackageQuotation.packageName}</h5>
                        <p className="card-text pSum"><small>Shuma e siguruar: {phPackageQuotation.insuredAmountV.toLocaleString()}€</small></p>
                        {phPackageQuotation.covers.map((cover: any, indexC: number) => (
                            <p key={indexC + 1} className="card-text">
                                <span className='pCheck'>✔ </span>
                                <span dangerouslySetInnerHTML={{ __html: cover.replaceAll("#", "<br />") }}></span>
                            </p>
                        ))}
                    </div>

                    <div className="card-footer">
                        {phPackageQuotation.packageDiscount > 0 &&
                            <p className='pDiscount'>
                                {phPackageQuotation.totalVNoDiscount.toFixed(2)}{phPackageQuotation.currencyName ? phPackageQuotation.currencyName : '€'}

                                <Tooltip
                                    arrow
                                    title={"Aplikohet zbritja prej " + (phPackageQuotation.packageDiscount) + "% ne kete police"}
                                    placement="top"
                                    classes={{
                                        tooltip: classes.customTooltip
                                    }}>
                                    <IconButton className="blue">
                                        <Info />
                                    </IconButton>
                                </Tooltip>
                            </p>}
                        <p className='pTotal'>
                            {parseFloat(phPackageQuotation.totalV).toFixed(2)}  {phPackageQuotation.currencyName ? phPackageQuotation.currencyName : '€'}
                        </p>
                    </div>
                </div>
            </div>}
        </>
    );
}

class PrivateHealthPolicy extends React.Component<Props & RouteComponentProps<PathProps>, {}> {
    handleSubmit = (values: any) => {
        const customerId = this.props.user ? this.props.user.customerId : '';
        const packageId = this.props.chosenPHQuotation && this.props.chosenPHQuotation?.length > 0 ? this.props.chosenPHQuotation[0].packageId : "";
        const policy = preparePrivateHealthPolicy(customerId, values, packageId);
        this.props.onBookPHPolicy(policy);
    };

    handleCloseModal = () => {
        if (this.props.nextStep === "3") this.props.history.push('/');
        this.props.closeModal();
    };

    render() {
        return (
            <div className="d-flex flex-column-fluid">
                <SimpleModal show={this.props.showModal} header={this.props.header} text={this.props.description} renderAsHtml={this.props.renderAsHtml} onClose={this.handleCloseModal} onHide={() => { return; }} />
                <div className=" container ">
                    <div className="card card-custom">
                        <div className="card-body p-0">
                            <FormikWizard steps={steps} onSubmit={this.handleSubmit} render={FormWrapper} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch<HideModalAction | BookPHPolicyAction>) => ({
    onBookPHPolicy: (phPolicy: PhPolicy) => {
        dispatch(phActions.bookPHPolicy(phPolicy));
    },
    closeModal: () => { dispatch(hideModal()); }
});

const mapStateToProps = (state: any) => ({
    user: state.auth.user,
    chosenPHQuotation: state.policy.chosenPHQuotation,
    showModal: state.general.showModal,
    header: state.general.header,
    description: state.general.text,
    nextStep: state.general.nextStep,
    renderAsHtml: state.general.renderAsHtml
});

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateHealthPolicy)));
