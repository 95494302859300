import { MotorInsQuotation, BookingData, ThInsQuotation, Question } from './../../utils/policyTypes';
import * as tplActions from "../actionTypes/tplPolicyActionTypes";
import * as gcActions from "../actionTypes/gcPolicyActionTypes";
import * as bActions from "../actionTypes/bPolicyActionTypes";
import * as thActions from "../actionTypes/thPolicyActionTypes";
import * as generalActions from "../actionTypes/generalActionTypes";
import * as phActions from "../actionTypes/phPolicyActionTypes";
import * as piActions from "../actionTypes/piPolicyActionTypes";
import * as aifActions from "../actionTypes/aifPolicyActionTypes";
import * as kaskoActions from "../actionTypes/kaskoPolicyActionTypes";
import * as cliActions from "../actionTypes/cliPolicyActionTypes";
import * as tliActions from "../actionTypes/tliPolicyActionTypes";

export interface PolicyState {
  motorInsQuotation: MotorInsQuotation | ThInsQuotation | null;
  bookingData: BookingData | null;
  isConfirmed: boolean;
  motorInsQuotationErrored: boolean | null;
  chosenTHQuotation: MotorInsQuotation | ThInsQuotation | null;
  policyQuotationChosen: boolean | null;
  phQuestionsErrored: boolean | null;
  phQuotation: MotorInsQuotation | ThInsQuotation | null;
  phQuotationErrored: boolean | null;
  chosenPHQuotation: MotorInsQuotation | ThInsQuotation | null;
  piQuotation: MotorInsQuotation | ThInsQuotation | null;
  piQuotationErrored: boolean | null;
  chosenPIQuotation: MotorInsQuotation | ThInsQuotation | null;
  aifQuotation: MotorInsQuotation | ThInsQuotation | null;
  aifQuotationErrored: boolean | null;
  kaskoQuotation: MotorInsQuotation | ThInsQuotation | null;
  kaskoQuotationErrored: boolean | null;
  showPoliticalFormModal: boolean | null;
  politicalFormQuestions: Question[] | null;
  cliQuestionsErrored: boolean | null;
  cliQuotation: MotorInsQuotation | ThInsQuotation | null;
  cliQuotationErrored: boolean | null;
  chosenCLIQuotation: MotorInsQuotation | ThInsQuotation | null;
  tliQuestionsErrored: boolean | null;
  tliQuotation: MotorInsQuotation | ThInsQuotation | null;
  tliQuotationErrored: boolean | null;
  chosenTLIQuotation: MotorInsQuotation | ThInsQuotation | null;
}

const initialState: PolicyState = {
  motorInsQuotation: null,
  bookingData: null,
  isConfirmed: false,
  motorInsQuotationErrored: false,
  chosenTHQuotation: null,
  policyQuotationChosen: false,
  phQuestionsErrored: false,
  phQuotation: null,
  phQuotationErrored: false,
  chosenPHQuotation: null,
  piQuotation: null,
  piQuotationErrored: false,
  chosenPIQuotation: null,
  aifQuotation: null,
  aifQuotationErrored: false,
  kaskoQuotation: null,
  kaskoQuotationErrored: false,
  showPoliticalFormModal: false,
  politicalFormQuestions: null,
  cliQuestionsErrored: false,
  cliQuotation: null,
  cliQuotationErrored: false,
  chosenCLIQuotation: null,
  tliQuestionsErrored: false,
  tliQuotation: null,
  tliQuotationErrored: false,
  chosenTLIQuotation: null
};

export default function policyReducer(
  state: PolicyState = initialState,
  action: tplActions.PolicyAction | gcActions.PolicyAction | bActions.PolicyAction | thActions.PolicyAction | generalActions.BookingDataResetAction | phActions.PolicyAction | piActions.PolicyAction | aifActions.PolicyAction | kaskoActions.PolicyAction | cliActions.PolicyAction | tliActions.PolicyAction
): PolicyState {
  switch (action.type) {
    case tplActions.GET_AMFTPL_QUOTATION_SUCCESS:
      return {
        ...state,
        motorInsQuotation: action.data
      };
    case tplActions.GET_AMFTPL_QUOTATION_RESET:
      return {
        ...state,
        motorInsQuotation: null
      };
    case tplActions.GET_TPL_QUOTATION_SUCCESS:
      return {
        ...state,
        motorInsQuotation: action.data
      };
    case tplActions.GET_TPL_QUOTATION_RESET:
      return {
        ...state,
        motorInsQuotation: null
      };
    case tplActions.BOOK_TPL_POLICY_SUCCESS:
      return {
        ...state,
        bookingData: action.data
      };
    case gcActions.GET_AMFGC_QUOTATION_SUCCESS:
      return {
        ...state,
        motorInsQuotation: action.data
      };
    case gcActions.GET_AMFGC_QUOTATION_RESET:
      return {
        ...state,
        motorInsQuotation: null
      };
    case gcActions.GET_GC_QUOTATION_SUCCESS:
      return {
        ...state,
        motorInsQuotation: action.data
      };
    case gcActions.GET_GC_QUOTATION_RESET:
      return {
        ...state,
        motorInsQuotation: null
      };
    case gcActions.BOOK_GC_POLICY_SUCCESS:
      return {
        ...state,
        bookingData: action.data
      };
    case bActions.GET_B_QUOTATION_SUCCESS:
      return {
        ...state,
        motorInsQuotation: action.data
      };
    case bActions.GET_B_QUOTATION_RESET:
      return {
        ...state,
        motorInsQuotation: null
      };
    case bActions.BOOK_B_POLICY_SUCCESS:
      return {
        ...state,
        bookingData: action.data
      };
    case thActions.GET_TH_QUOTATION_SUCCESS:
      return {
        ...state,
        motorInsQuotation: action.data,
        motorInsQuotationErrored: false
      };
    case thActions.GET_TH_QUOTATION_FAILURE:
      return {
        ...state,
        motorInsQuotationErrored: true
      };
    case thActions.GET_TH_QUOTATION_RESET:
      return {
        ...state,
        motorInsQuotation: null,
        motorInsQuotationErrored: false
      };
    case thActions.BOOK_TH_POLICY_SUCCESS:
      return {
        ...state,
        bookingData: action.data
      };
    case generalActions.BOOKING_DATA_RESET:
      return {
        ...state,
        bookingData: null
      };
    case thActions.GET_PACKAGE_TH_QUOTATION_SUCCESS:
      return {
        ...state,
        chosenTHQuotation: action.data
      };
    case thActions.GET_PACKAGE_TH_QUOTATION_RESET:
      return {
        ...state,
        chosenTHQuotation: null
      };
    case thActions.CHOOSE_QUOTATION_FAILURE:
      return {
        ...state,
        policyQuotationChosen: false
      };
    case thActions.CHOOSE_QUOTATION_SUCCESS:
      return {
        ...state,
        policyQuotationChosen: true
      };
    case thActions.CHOOSE_QUOTATION_RESET:
      return {
        ...state,
        policyQuotationChosen: false
      };
    case phActions.PH_QUESTIONS_FAILURE:
      return {
        ...state,
        phQuestionsErrored: true
      };
    case phActions.PH_QUESTIONS_RESET:
      return {
        ...state,
        phQuestionsErrored: false
      };
    case phActions.GET_PH_QUOTATION_SUCCESS:
      return {
        ...state,
        phQuotation: action.data,
        phQuotationErrored: false
      };
    case phActions.GET_PH_QUOTATION_FAILURE:
      return {
        ...state,
        phQuotationErrored: true
      };
    case phActions.GET_PH_QUOTATION_RESET:
      return {
        ...state,
        phQuotation: null,
        phQuotationErrored: false
      };
    case phActions.GET_PACKAGE_PH_QUOTATION_SUCCESS:
      return {
        ...state,
        chosenPHQuotation: action.data
      };
    case phActions.GET_PACKAGE_PH_QUOTATION_RESET:
      return {
        ...state,
        chosenPHQuotation: null
      };
    case phActions.BOOK_PH_POLICY_SUCCESS:
      return {
        ...state,
        bookingData: action.data
      };
    case piActions.GET_PI_QUOTATION_SUCCESS:
      return {
        ...state,
        piQuotation: action.data,
        piQuotationErrored: false
      };
    case piActions.GET_PI_QUOTATION_FAILURE:
      return {
        ...state,
        piQuotationErrored: true
      };
    case piActions.GET_PI_QUOTATION_RESET:
      return {
        ...state,
        piQuotation: null,
        piQuotationErrored: false
      };
    case piActions.GET_PACKAGE_PI_QUOTATION_SUCCESS:
      return {
        ...state,
        chosenPIQuotation: action.data
      };
    case piActions.GET_PACKAGE_PI_QUOTATION_RESET:
      return {
        ...state,
        chosenPIQuotation: null
      };
    case piActions.BOOK_PI_POLICY_SUCCESS:
      return {
        ...state,
        bookingData: action.data
      };
    case aifActions.GET_AIF_QUOTATION_SUCCESS:
      return {
        ...state,
        aifQuotation: action.data,
        aifQuotationErrored: false
      };
    case aifActions.GET_AIF_QUOTATION_FAILURE:
      return {
        ...state,
        aifQuotationErrored: true
      };
    case aifActions.GET_AIF_QUOTATION_RESET:
      return {
        ...state,
        aifQuotation: null,
        aifQuotationErrored: false
      };
    case aifActions.BOOK_AIF_POLICY_SUCCESS:
      return {
        ...state,
        bookingData: action.data
      };
    case kaskoActions.GET_KASKO_QUOTATION_SUCCESS:
      return {
        ...state,
        kaskoQuotation: action.data,
        kaskoQuotationErrored: false
      };
    case kaskoActions.GET_KASKO_QUOTATION_FAILURE:
      return {
        ...state,
        kaskoQuotationErrored: true
      };
    case kaskoActions.GET_KASKO_QUOTATION_RESET:
      return {
        ...state,
        kaskoQuotation: null,
        kaskoQuotationErrored: false
      };
    case kaskoActions.BOOK_KASKO_POLICY_SUCCESS:
      return {
        ...state,
        bookingData: action.data
      };
    case cliActions.SHOW_POLITICAL_FORM_MODAL:
      return {
        ...state,
        showPoliticalFormModal: true,
      };
    case cliActions.HIDE_POLITICAL_FORM_MODAL:
      return {
        ...state,
        showPoliticalFormModal: false,
      };
    case cliActions.SUBMIT_POLITICAL_FORM:
      return {
        ...state,
        politicalFormQuestions: action.data,
        showPoliticalFormModal: false
      };
    case cliActions.RESET_POLITICAL_FORM:
      return {
        ...state,
        politicalFormQuestions: null
      };
    case cliActions.CLI_QUESTIONS_FAILURE:
      return {
        ...state,
        cliQuestionsErrored: true
      };
    case cliActions.CLI_QUESTIONS_RESET:
      return {
        ...state,
        cliQuestionsErrored: false
      };
    case cliActions.GET_CLI_QUOTATION_SUCCESS:
      return {
        ...state,
        cliQuotation: action.data,
        cliQuotationErrored: false
      };
    case cliActions.GET_CLI_QUOTATION_FAILURE:
      return {
        ...state,
        cliQuotationErrored: true
      };
    case cliActions.GET_CLI_QUOTATION_RESET:
      return {
        ...state,
        cliQuotation: null,
        cliQuotationErrored: false
      };
    case cliActions.GET_PACKAGE_CLI_QUOTATION_SUCCESS:
      return {
        ...state,
        chosenCLIQuotation: action.data
      };
    case cliActions.GET_PACKAGE_CLI_QUOTATION_RESET:
      return {
        ...state,
        chosenCLIQuotation: null
      };
    case cliActions.BOOK_CLI_POLICY_SUCCESS:
      return {
        ...state,
        bookingData: action.data
      };
    case tliActions.TLI_QUESTIONS_FAILURE:
      return {
        ...state,
        tliQuestionsErrored: true
      };
    case tliActions.TLI_QUESTIONS_RESET:
      return {
        ...state,
        tliQuestionsErrored: false
      };
    case tliActions.GET_TLI_QUOTATION_SUCCESS:
      return {
        ...state,
        tliQuotation: action.data,
        tliQuotationErrored: false
      };
    case tliActions.GET_TLI_QUOTATION_FAILURE:
      return {
        ...state,
        tliQuotationErrored: true
      };
    case tliActions.GET_TLI_QUOTATION_RESET:
      return {
        ...state,
        tliQuotation: null,
        tliQuotationErrored: false
      };
    case tliActions.GET_PACKAGE_TLI_QUOTATION_SUCCESS:
      return {
        ...state,
        chosenTLIQuotation: action.data
      };
    case tliActions.GET_PACKAGE_TLI_QUOTATION_RESET:
      return {
        ...state,
        chosenTLIQuotation: null
      };
    case tliActions.BOOK_TLI_POLICY_SUCCESS:
      return {
        ...state,
        bookingData: action.data
      };
    default:
      return state;
  }
}
