/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { useHistory, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.authenticated);
  const history = useHistory();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {isAuthenticated && <>
          {/* Dëmet */}
          {/* begin::section */}
          <li className="menu-section ">
            <h4 className="menu-text">Dëmet</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}

          {/* REGISTER PRECLAIMS */}
          {/*begin::1 Level*/}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/register", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <div className="menu-link menu-toggle">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
              </span>
              <span className="menu-text">Dëmet shendetsore</span>
              <i className="menu-arrow" />
            </div>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Dëmet shendetsore</span>
                  </span>
                </li>

                {/* Parakërkesë për dëm (Shëndet) */}
                {/*begin::2 Level*/}
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/register-health-preclaim-ks", true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/register-health-preclaim-ks">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Parakërkesë për dëm (Shëndet)</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                {/* Parakerkese per dem (Shëndetësor/Shendet) */}
                {/*begin::2 Level*/}
                <li
                  className={`menu-item menu-item-submenu  ${getMenuItemActive(
                    "/register-health-preclaim", true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/register-health-preclaim">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Parakërkesë për dëm (Shëndeti në udhëtim/Aksidente personale)</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                {/* Statusi dhe historiati i kerkesave për rimbursim */}
                {/*begin::2 Level*/}
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/health-claim-search", true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/health-claim-search">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Statusi dhe historiati i kerkesave për rimbursim</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

              </ul>
            </div>
          </li>
          {/*end::1 Level*/}

          {/* KERKO KERKESEN, TE GJITHA, HISTORIKU */}
          {/*begin::1 Level*/}
          {user?.enableMotorClaims && <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/search", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
              </span>
              <span className="menu-text">Dëmet motorike</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Dëmet motorike</span>
                    </span>
                  </li>

                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/register-preclaim"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/register-preclaim">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Parakërkesë për dëm (TPL/TPL Plus)</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/register-kasko-preclaim"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/register-kasko-preclaim">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Parakërkesë për dëm (Kasko)</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </ul>
              </ul>
            </div>
          </li>}
          {/*end::1 Level*/}


          {/* Kërko */}
          {/* begin::section */}
          <li className="menu-section ">
            <h4 className="menu-text">Kërko</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}

          {/*Kërko*/}
          {/*begin::1 Level*/}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/preclaim-search", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/preclaim-search">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
              </span>
              <span className="menu-text">Kërko kërkesën për dëm</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*Kërko të gjitha dëmet*/}
          {/*begin::1 Level*/}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/claim-search", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/claim-search">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
              </span>
              <span className="menu-text">Kërko të gjitha dëmet</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*Kërko polica*/}
          {/*begin::1 Level*/}
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/policy-search", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/policy-search">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
              </span>
              <span className="menu-text">Kërko polica</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
        </>}

        {/* Sigurimi */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Sigurimi</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/register", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <div className="menu-link menu-toggle">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-3.svg")} />
            </span>
            <span className="menu-text">Automjete</span>
            <i className="menu-arrow" />
          </div>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Automjete</span>
                </span>
              </li>

              {/* Kasko */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/kasko-policy", true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/kasko-policy">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Kasko</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

            </ul>
          </div>
        </li>

        {/* Pasuri */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/register", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <div className="menu-link menu-toggle">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-3.svg")} />
            </span>
            <span className="menu-text">Pasuri</span>
            <i className="menu-arrow" />
          </div>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Pasuri</span>
                </span>
              </li>

              {/* Sigurimi i pasurisë */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/property-policy", true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/property-policy">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Sigurimi i pasurisë</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

            </ul>
          </div>
        </li>

        {/* Shëndet dhe Aksidente */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/register", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <div className="menu-link menu-toggle">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-3.svg")} />
            </span>
            <span className="menu-text">Shëndet & Aksidente</span>
            <i className="menu-arrow" />
          </div>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Shëndet & Aksidente</span>
                </span>
              </li>

              {/* Sigurimi i shëndetit në udhëtim */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/travel-health-policy", true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/travel-health-policy">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Sigurimi i shëndetit në udhëtim</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Sigurimi i shëndetit */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/private-health-policy", true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/private-health-policy">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Sigurimi i shëndetit</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Leje qëndrimi */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/foreigner-policy", true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/foreigner-policy">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Lejeqëndrim</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>

        {/* Jetë */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/register", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <div className="menu-link menu-toggle">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-3.svg")} />
            </span>
            <span className="menu-text">Jetë</span>
            <i className="menu-arrow" />
          </div>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Jetë</span>
                </span>
              </li>

              {/* Sigurimet e Jetës së Kredimarrësit */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/credit-life-policy", true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/credit-life-policy">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Sigurimet e Jetës së Kredimarrësit</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Sigurimi i Jetës */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/term-life-policy", true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/term-life-policy">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Sigurimi i Jetës</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* Profili */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Profili</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}


        {/*Update profile*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/update-profile", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/update-profile">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
            </span>
            <span className="menu-text">Përditëso profilin</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*Password change*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/change-password", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/change-password">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
            </span>
            <span className="menu-text">Ndrysho fjalëkalimin</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        <div className="menu-section">
          <div className="menu-item menu-item-submenu ">
            <div className="d-flex flex-column">
              <a
                href="#"
                className="font-weight-bold font-size-h5 text-hover-primary"
              >
                {user?.name + " " + user?.surname}
              </a>
              <div className="text-muted mt-1">{user?.address}</div>
              <div className="navi mt-2">
                <a href="#" className="navi-item">
                  <span className="navi-link p-0 pb-2">
                    <span className="navi-icon mr-1">
                      <span className="svg-icon-lg svg-icon-primary">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail-notification.svg"
                          )}
                        ></SVG>
                      </span>
                    </span>
                    <span className="navi-text text-muted text-hover-primary">
                      {user?.username}
                    </span>
                  </span>
                </a>
              </div>
              {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
              <button className="btn btn-light-primary btn-bold mb20" onClick={logoutClick}>Dil</button>
            </div>
          </div>
        </div>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
