/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../Layout/ContentRoute";
import Login from "./Login";
import Registration from "./Registration/Registration";
import ForgotPassword from "./ForgotPassword";
import "../../_metronic/_assets/sass/pages/login/login-4.scss";
import ConfirmEmail from "./Registration/ConfirmEmail";
import { Layout } from "../../_metronic/layout";
import BasePage from "../BasePage";

export function LandingPage() {
  return (
    <>
          {/*begin::Content*/}
          {/* begin::Content body */}
          <Switch>
            <ContentRoute path="/confirmation" component={ConfirmEmail} />
            <ContentRoute path="/auth/login" component={Login} />
            <ContentRoute path="/auth/registration" component={Registration} />
            <ContentRoute path="/auth/forgot-password" component={ForgotPassword} />
            <Redirect from="/auth" exact={true} to="/auth/login" />
            <Layout>
              <BasePage />
            </Layout>
          </Switch>
          {/*end::Content body*/}
          {/*end::Content*/}
    </>
  );
}
