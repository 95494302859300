import React from "react";
import { useFormikWizard } from 'formik-wizard';
import Nav from './Nav';
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import * as actionTypes from "../../../redux/actionTypes/authActionTypes";
import { Loading } from "../../Layout/Loading";

const Info = () => {
    const { values }: any = useFormikWizard();
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.UPDATE_CUSTOMER]);

    return (
        <>
            {isLoading
                ? <Loading />
                :
                <>
                    <div className="d-flex flex-column-auto flex-column px-10">
                        <Nav step={2} />
                    </div>
                    <div className="login-form form px-10 fv-plugins-bootstrap fv-plugins-framework">
                        <div className="row">
                            <div className="col-xl-2">
                            </div>
                            <div className="col-xl-5">
                                <h4 className="font-weight-bolder mb-3">                                    Detajet e llogarisë:                        </h4>
                                <div className="text-dark-50 font-weight-bold line-height-lg mb-8">
                                    <div>{values.account.clientno}{values.account.nipt}</div>
                                    <div>{values.account.title} {values.account.lastName}</div>
                                    <div>{values.account.sex === "1" ? 'Mashkull' : values.account.sex === "2" ? 'Femer' : ""}</div>
                                    <div>{values.account.birthdate ? ((values.account.birthdate.getDate() > 9) ? values.account.birthdate.getDate() : ('0' + values.account.birthdate.getDate())) + '/' + ((values.account.birthdate.getMonth() > 8) ? (values.account.birthdate.getMonth() + 1) : ('0' + (values.account.birthdate.getMonth() + 1))) + '/' + values.account.birthdate.getFullYear() : ""}</div>
                                </div>
                            </div>
                            <div className="col-xl-5">
                                <h4 className="font-weight-bolder mb-3">                                   Detajet e kontaktit:                        </h4>
                                <div className="text-dark-50 font-weight-bold line-height-lg mb-8">
                                    <div>{values.contact.address}</div>
                                    <div>{values.contact.place}</div>
                                    <div>{values.contact.mob}</div>
                                    <div>{values.contact.email}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default Info;