import * as React from 'react';
import { connect } from 'react-redux';
import { DropzoneArea } from 'material-ui-dropzone'
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core';

const UploadDocuments = () => {
    const { values }: any = useFormikContext();

    const {
        setFieldValue,
    } = useFormikContext();

    const saveIdCardFiles = (files: any) => {
        setFieldValue('idCardFiles', files);
    }

    const saveLoanFiles = (files: any) => {
        setFieldValue('loanFiles', files);
    }

    const useStyles = makeStyles(() => ({
        dropZone: {
            minHeight: '60px',
        },
        previewContainer: {
            container: 'true',
            width: '100%',
            height: '100%',
        },
        preview: {
            height: '100%',
            xs: '12',
        }
    }));

    const classes = useStyles();

    return (<>
        <div className="wizardTitle">Dokumente</div>
        <div className="pb-15" data-wizard-type="step-content" data-wizard-state="current">
            <div className="font-weight-bold text-dark">
                Karta e Identifikimit (të dyja palët)
            </div>
            <div>
                <DropzoneArea
                    dropzoneClass={classes.dropZone}
                    onChange={saveIdCardFiles}
                    onDelete={saveIdCardFiles}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/jpeg']}
                    filesLimit={2}
                    maxFileSize={10485760}
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    showFileNamesInPreview={true}
                    showFileNames={true}
                    initialFiles={values.idCardFiles}
                    previewGridClasses={{
                        container: classes.previewContainer,
                        item: classes.preview
                    }}
                    getPreviewIcon={(file: any, classes: any) => {
                        return (
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14 0C16.7614 0 19 2.23858 19 5V17C19 20.866 15.866 24 12 24C8.13401 24 5 20.866 5 17V9H7V17C7 19.7614 9.23858 22 12 22C14.7614 22 17 19.7614 17 17V5C17 3.34315 15.6569 2 14 2C12.3431 2 11 3.34315 11 5V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V6H15V17C15 18.6569 13.6569 20 12 20C10.3431 20 9 18.6569 9 17V5C9 2.23858 11.2386 0 14 0Z"
                                    fill="currentColor"
                                />
                            </svg>
                        );
                    }}
                />
            </div>
        </div>

        <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
            <div className="font-weight-bold text-dark">
                Kontrata e kredisë/Plani i amortizimit
            </div>
            <div>
                <DropzoneArea
                    dropzoneClass={classes.dropZone}
                    onChange={saveLoanFiles}
                    onDelete={saveLoanFiles}
                    acceptedFiles={['application/pdf', 'application/msword',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        'application/vnd.rar', 'text/plain', '	application/zip',
                        'application/x-7z-compressed', 'image/jpeg', 'image/png', 'image/jpeg']}
                    filesLimit={1}
                    maxFileSize={10485760}
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    showFileNamesInPreview={true}
                    showFileNames={true}
                    initialFiles={values.loanFiles}
                    previewGridClasses={{
                        container: classes.previewContainer,
                        item: classes.preview
                    }}
                    getPreviewIcon={(file: any, classes: any) => {
                        return (
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14 0C16.7614 0 19 2.23858 19 5V17C19 20.866 15.866 24 12 24C8.13401 24 5 20.866 5 17V9H7V17C7 19.7614 9.23858 22 12 22C14.7614 22 17 19.7614 17 17V5C17 3.34315 15.6569 2 14 2C12.3431 2 11 3.34315 11 5V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V6H15V17C15 18.6569 13.6569 20 12 20C10.3431 20 9 18.6569 9 17V5C9 2.23858 11.2386 0 14 0Z"
                                    fill="currentColor"
                                />
                            </svg>
                        );
                    }}
                />
            </div>
        </div>
    </>);
}

export default connect()(UploadDocuments);
