import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { useFormikWizard } from 'formik-wizard';
import { Loading } from '../../Layout/Loading';
import moment from 'moment';
import CheckboxField from '../../Custom/CheckboxField';
import * as authActionTypes from '../../../redux/actionTypes/authActionTypes';
import * as policyActionTypes from '../../../redux/actionTypes/kaskoPolicyActionTypes';
import { AppState } from '../../../redux/reducers/rootReducer';
import { Nomen } from '../../../utils';
import { Gender } from '../../../utils/nomen';
import { ThInsQuotation } from '../../../utils/policyTypes';
import PdfKasko from "../../../../src/InsuranceConditions/04.pdf";
import CaptchaGenerator from '../../Custom/CaptchaGenerator';
import { useFormikContext } from "formik";
import InputField from '../../Custom/InputField';

const Verification = () => {
    const { values: wizardValues }: any = useFormikWizard();
    const { setFieldValue } = useFormikContext();

    const isLoadingHasProfile = useSelector<AppState, boolean>((state: any) => state.isLoading[authActionTypes.HAS_PROFILE]);
    const isLoadingBookPolicy = useSelector<AppState, boolean>((state: any) => state.isLoading[policyActionTypes.BOOK_KASKO_POLICY]);
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const chosenKaskoQuotation = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.kaskoQuotation);
    const kaskoQuotation = chosenKaskoQuotation ? chosenKaskoQuotation[0] : null;
    const marks = useSelector<AppState, Nomen[]>((state: any) => state.nomen.VehicleMarks);
    const types = useSelector<AppState, Nomen[]>((state: any) => state.nomen.TypeVehicle);

    const drivers = wizardValues.insertDrivers.drivers;
    const vehicleDetails = wizardValues.insuredVehicle;

    const result = (text: string) => {
        setFieldValue('captchaGenerated', text);
    }

    return (<>
        {isLoadingHasProfile
            ? <Loading />
            : <>
                {isLoadingBookPolicy && <Loading />}

                {vehicleDetails &&
                    <div className="text-dark-50 line-height-lg">
                        <div className="wizardTitle mb10">Verifikoni informacionin e automjetit të siguruar</div>
                        <div className="verifyTitle">Targa</div><div className="verifyValue"> {vehicleDetails.regnum}</div>
                        <div className="verifyTitle">Numri i shasisë</div><div className="verifyValue">{vehicleDetails.chassis}</div>
                        <div className="verifyTitle">Mjeti/Klasa</div><div className="verifyValue"> {types.filter((p: Nomen) => p.code === vehicleDetails.type_vehicle)[0]?.name}</div>
                        <div className="verifyTitle">Prodhuesi</div><div className="verifyValue"> {marks.filter((p: Nomen) => p.code === vehicleDetails.mark)[0]?.name}</div>
                        <div className="verifyTitle">Modeli</div><div className="verifyValue"> {vehicleDetails.tip}</div>
                        <div className="verifyTitle">Fuqia(kw)</div><div className="verifyValue"> {vehicleDetails.power}</div>
                        <div className="verifyTitle">Viti i prodhimit</div><div className="verifyValue"> {vehicleDetails.prodYear}</div>
                        <div className="verifyTitle">Nr. I ulëseve</div><div className="verifyValue"> {vehicleDetails.placenr}</div>
                        <div className="verifyTitle">Pesha e automjetit</div><div className="verifyValue"> {vehicleDetails.capacity}</div>
                        <div className="verifyTitle">Ngjyra e automjetit</div><div className="verifyValue"> {vehicleDetails.colour}</div>
                        <div className="verifyTitle">Kapaciteti (cm3)</div><div className="verifyValue"> {vehicleDetails.volume}</div>
                        <div className="verifyTitle">Leje qarkullimi</div><div className="verifyValue"> {vehicleDetails.vehicleLicence}</div>
                    </div>}

                {drivers && drivers.length > 0 &&
                    <div className="text-dark-50 line-height-lg">
                        <div className="wizardTitle mt20 mb10">Verifikoni detajet e shoferit</div>

                        {drivers.map((driver: any, indexT: number) => (
                            <div key={indexT + 1}>
                                <div className="verifyTitle">Tip</div><div className="verifyValue"> {driver.tipKind === '3' ? 'Fizik' : 'Juridik'}</div>
                                {driver.tipKind === '3' && <><div className="verifyTitle">Numri personal</div><div className="verifyValue"> {driver.clientNo}</div></>}
                                {driver.tipKind === '2' && <><div className="verifyTitle">Nipti</div><div className="verifyValue"> {driver.nipt}</div></>}
                                <div className="verifyTitle">Emri</div><div className="verifyValue">{driver.title}</div>
                                {driver.tipKind === '3' && <><div className="verifyTitle">Mbiemri</div><div className="verifyValue">{driver.lastName}</div></>}
                                {driver.tipKind === '3' && <><div className="verifyTitle">Gjinia</div><div className="verifyValue">{Gender.filter((g: Nomen) => g.code === driver.sex)[0]?.name}</div></>}
                                <div className="verifyTitle">Adresa</div><div className="verifyValue">{driver.address}</div>
                                <div className="verifyTitle">Vendi</div><div className="verifyValue">{places.filter((p: Nomen) => p.code === driver.place)[0]?.name}</div>
                                <div className="verifyTitle">Email</div><div className="verifyValue">{driver.email}</div>
                                <div className="verifyTitle">Nr. telefonit</div><div className="verifyValue">{driver.celphone}</div>
                                {driver.tipKind === '3' && <><div className="verifyTitle">Nr. i patentës</div><div className="verifyValue">{driver.drivingLicenceNo ? driver.drivingLicenceNo : '/'}</div></>}
                                {driver.tipKind === '3' && <><div className="verifyTitle">Datëlindja</div><div className="verifyValue"> {moment(driver.birthDate).format('DD/MM/yyyy')}</div></>}
                                <hr />
                            </div>
                        ))}
                    </div>}
                {kaskoQuotation &&
                    <div className="text-dark-50 line-height-lg packageInfoMobile">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">{kaskoQuotation.packageName}</h5>
                                <p className="card-text pSum"><small>Shuma e siguruar: {kaskoQuotation.insuredAmountV.toLocaleString()}€</small></p>
                                {/* covers */}
                                {kaskoQuotation.covers.map((cover: any, indexC: number) => (
                                    <p key={indexC + 1} className="card-text">
                                        <span className='pCheck'>✔</span> {cover}
                                    </p>
                                ))}

                                {/* kaskoCovers */}
                                {kaskoQuotation.kaskoCovers.map((kaskoCover: any, index: number) => (
                                    <>{kaskoCover.selected && <p key={index + 1} className="card-text">
                                        <span className='pCheck'>✔ </span>
                                        <span>{kaskoCover.name}</span>
                                    </p>}</>
                                ))}
                            </div>
                            <div className="card-footer">
                                <p className='pTotal'>
                                    {parseFloat(kaskoQuotation.totalV).toFixed(2)}  {kaskoQuotation.currencyName ? kaskoQuotation.currencyName : '€'}
                                </p>
                            </div>
                        </div>
                        <hr />
                    </div>}
                <div className="row">
                    <div className="col-xl-6">
                        <CheckboxField name="acceptTerms" type="checkbox" label="I kuptoj dhe I pranoj kushtet e sigurimit" />
                    </div>
                    <div className="col-xl-6">
                        <a href={PdfKasko} target="_blank">Kushtet e përgjithshme të sigurimit</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <CaptchaGenerator result={result} />
                    </div>
                    <div className="col-xl-12">
                        <InputField name="captchaInputted" type="text" placeholder="Shkruaj kodim këtu" label="Kodi i sigurise" />
                    </div>
                </div>

            </>
        }
    </>
    );
}
export default connect()(Verification);
