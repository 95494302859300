import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import FormikWizard from 'formik-wizard';
import { HideModalAction } from '../../../redux/actionTypes/generalActionTypes';
import { FormikWizardWrapperProps } from '../../../utils/wizardTypes';
import SimpleModal from '../../General/SimpleModal';
import steps from './steps';
import Nav from './Nav';
import {
    getVehicleMark,
    getNatures,
    getPlaces,
    getTypeVehicle
} from '../../../redux/actionCreators/nomenActionCreators';
import * as nomenActionTypes from '../../../redux/actionTypes/nomenActionTypes';
import * as claimActionTypes from '../../../redux/actionTypes/claimActionTypes';
import { hideModal } from '../../../redux/actionCreators/generalActionCreators';
import { RouteComponentProps } from '../../Layout/Content';
import { Loading } from '../../Layout/Loading';
import { PreClaim, preparePreClaim } from '../../../utils/claimTypes';
import { registerPreClaim } from '../../../redux/actionCreators/claimActionCreators';
import { User } from '../../../redux/types';
import { AppState } from '../../../redux/reducers/rootReducer';

interface Props {
    onGetTypeVehicle(): void;
    onGetVehicleMark(): void;
    onGetNatures(): void;
    onGetPlaces(): void;
    onRegisterPreClaim(preClaim: PreClaim): void;
    closeModal(): void;
    showModal: boolean;
    description: string;
    header: string;
    nextStep: string;
    history: History;
    isLoading: boolean;
    nomenFetched: boolean;
    user: User | null;
}

interface PathProps {
    history: any;
}

function FormWrapper({
    children,
    isLastStep,
    status,
    goToPreviousStep,
    canGoBack,
    actionLabel,
    currentStep,
    values,
    wizard
}: FormikWizardWrapperProps<any, any>) {
    //if nature is different from 1 in first step,from second go directly to fourth step, similar for back
    const history = wizard.history as any;
    const isNext = history.entries.length === 3;
    const isBack = history.entries.length >= 4;
    
    // const errorFetchVehicle = useSelector<AppState, null | Error | string>((state: any) => state.error[claimActionTypes.FETCH_VEHICLE_FROM_POLICY]);

    if (currentStep === steps[2].id && values.claim.nature !== "1" && isNext)
        wizard.next();
    if (currentStep === steps[2].id && values.claim.nature !== "1" && isBack)
        wizard.previous();

    // if (currentStep === steps[1].id && !!errorFetchVehicle) {
    //     goToPreviousStep()
    // };

    return (
        <div className="wizard wizard-4" id="kt_wizard_v4" data-wizard-state="step-first" data-wizard-clickable="true">
            <Nav currentStep={currentStep} />
            <div className="card card-custom card-shadowless rounded-top-0">
                <div className="card-body p-0">
                    <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                        <div className="col-xl-12 col-xxl-7">
                            {children}
                            <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                <div className="mr-2">
                                    {canGoBack && <button type="button" onClick={goToPreviousStep} className="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-prev">
                                        Kthehu
                                    </button>
                                    }
                                </div>
                                <div>
                                    {isLastStep && <button type="submit" className="btn btn-success font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-submit">
                                        {actionLabel || 'Submit'}
                                    </button>}
                                    {!isLastStep && <button type="submit" className="btn btn-primary font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-next">
                                        {actionLabel || 'Next'}
                                    </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}


class RegisterPreClaim extends React.Component<Props & RouteComponentProps<PathProps>, {}> {

    componentDidMount() {
        if (!this.props.nomenFetched) {
            this.props.onGetTypeVehicle();
            this.props.onGetVehicleMark();
            this.props.onGetNatures();
            this.props.onGetPlaces();
        }
    }

    handleSubmit = (values: any) => {
        const customerId = this.props.user!.customerId;
        const preClaim = preparePreClaim(customerId, values);
        this.props.onRegisterPreClaim(preClaim);
    };

    handleCloseModal = () => {
        if (this.props.nextStep === "3") this.props.history.push('/');
        this.props.closeModal();
    };

    render() {
        return (
            <div className="d-flex flex-column-fluid">
                {this.props.isLoading
                    ? <Loading />
                    : <>
                        <SimpleModal show={this.props.showModal} header={this.props.header} text={this.props.description} onClose={this.handleCloseModal} onHide={() => { return; }} />
                        <div className=" container ">
                            <div className="card card-custom">
                                <div className="card-body p-0">
                                    <FormikWizard steps={steps} onSubmit={this.handleSubmit} render={FormWrapper} />
                                </div>
                            </div>
                        </div>
                    </>}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch<nomenActionTypes.NomenAction | HideModalAction | claimActionTypes.RegisterPreClaimAction>) => ({
    onGetTypeVehicle: () => {
        dispatch(getTypeVehicle());
    },
    onGetVehicleMark: () => {
        dispatch(getVehicleMark());
    },
    onGetNatures: () => {
        dispatch(getNatures());
    },
    onGetPlaces: () => {
        dispatch(getPlaces());
    },
    onRegisterPreClaim: (preClaim: PreClaim) => {
        dispatch(registerPreClaim(preClaim));
    },
    closeModal: () => { dispatch(hideModal()); }
});

const mapStateToProps = (state: any) => ({
    user: state.auth.user,
    showModal: state.general.showModal,
    header: state.general.header,
    description: state.general.text,
    nextStep: state.general.nextStep,
    isLoading: state.isLoading[nomenActionTypes.GET_TYPE_VEHICLE]
        || state.isLoading[nomenActionTypes.GET_VEHICLE_MARK]
        || state.isLoading[nomenActionTypes.GET_NATURES]
        || state.isLoading[nomenActionTypes.GET_PLACES],
    nomenFetched: state.nomen.TypeVehicle.length > 0
        && state.nomen.VehicleMarks.length > 0
        && state.nomen.Natures.length > 0
        && state.nomen.Places.length > 0
});

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterPreClaim)));
