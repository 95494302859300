import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import InputFieldMain from '../../Custom/InputFieldMain';
import InputAreaFieldMain from '../../Custom/InputAreaFieldMain';
import TimeInput from '../../Custom/TimeInput';
import SelectField from '../../Custom/SelectField';
import DatePickerFieldMain from '../../Custom/DatePickerFieldMain';
import { AppState } from '../../../redux/reducers/rootReducer';
import { Nomen } from '../../../utils';
import { PropertyPolicyTypes } from '../../../utils/nomen';

const PropertyPreClaimMain = () => {
    const natures = useSelector<AppState, Nomen[]>((state: any) => state.nomen.PropertyNatures);
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);

    return (<div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
        <div className="mb-10 font-weight-bold text-dark"></div>
        <div className="row">
            <div className="col-xl-6">
                <SelectField name="type_policy" label="Tipi i policës" items={PropertyPolicyTypes} />
            </div>
            <div className="col-xl-6">
                <SelectField name="nature" label="Natyra e dëmit" items={natures} />
            </div>
        </div>
        <div className="row">
            <div className="col-xl-6">
                <InputFieldMain type="text" name="policy_code" label="Numri i policës" placeholder="Numri i policës" />
            </div>
            <div className="col-xl-6">
                <SelectField name="place_accident" label="Vendi i aksidentit/shërbimit" items={places} />
            </div>
        </div>
        <div className="row">
            <div className="col-xl-6 addSpace">
                <DatePickerFieldMain name="date_accident" type="text" placeholder="Data e aksidentit/policës" />
            </div>
        </div>
        <div className="row">
            <div className="col-xl-6">
                <InputAreaFieldMain type="text" name="description_damage" label="Përshkrimi i dëmit" placeholder="Përshkrimi i dëmit" />
            </div>
            <div className="col-xl-6 ">
                <InputAreaFieldMain type="text" name="description_accident" label="Vendi i Marrjes së Shërbimit" placeholder="Vendi i Marrjes së Shërbimit" />
            </div>
        </div>
    </div>
    );
}
export default connect()(PropertyPreClaimMain);
