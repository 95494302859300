import { BookingData, AifPolicy, ThInsQuotation } from "../../utils/policyTypes";
import * as actions from "../actionTypes/aifPolicyActionTypes";

export function getAIFQuotation(
    policy: AifPolicy
): actions.GetAIFQuotationAction {
    return {
        type: actions.GET_AIF_QUOTATION,
        policy
    };
}

export function getAIFQuotationRequest(
): actions.GetAIFQuotationRequestAction {
    return {
        type: actions.GET_AIF_QUOTATION_REQUEST
    };
}

export function getAIFQuotationReset(
): actions.GetAIFQuotationResetAction {
    return {
        type: actions.GET_AIF_QUOTATION_RESET
    };
}

export function getAIFQuotationSuccess(
    values: ThInsQuotation
): actions.GetAIFQuotationSuccessAction {
    return {
        type: actions.GET_AIF_QUOTATION_SUCCESS,
        data: values
    };
}

export function getAIFQuotationFailure(
    error: Error | string
): actions.GetAIFQuotationFailureAction {
    return {
        type: actions.GET_AIF_QUOTATION_FAILURE,
        error
    };
}

export function bookAIFPolicy(
    policy: AifPolicy,
): actions.BookAIFPolicyAction {
    return {
        type: actions.BOOK_AIF_POLICY,
        policy
    };
}

export function bookAIFPolicyRequest(
): actions.BookAIFPolicyRequestAction {
    return {
        type: actions.BOOK_AIF_POLICY_REQUEST
    };
}

export function bookAIFPolicySuccess(
    values: BookingData
): actions.BookAIFPolicySuccessAction {
    return {
        type: actions.BOOK_AIF_POLICY_SUCCESS,
        data: values
    };
}

export function bookAIFPolicyFailure(
    error: Error | string
): actions.BookAIFPolicyFailureAction {
    return {
        type: actions.BOOK_AIF_POLICY_FAILURE,
        error
    };
}