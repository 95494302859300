import { PolicyGridItem, PolicyPreview } from './../../utils/generalTypes';
import ApiClient from '../../infrastructure/ApiClient';

export async function searchPolicy(
  data: any
): Promise<PolicyGridItem[]> {
  return await ApiClient.post('/api/policy/searchPolicy', data);
}

export async function getPolicy(
  policyId: string
): Promise<PolicyPreview> {
  return await ApiClient.get('/api/policy/policyPreview', { policyId });
}

export async function confirmPolicy(
  data: any
): Promise<PolicyGridItem[]> {
  return await ApiClient.post('/api/policy/confirmPolicy', data);
}

export async function getPaymentResponse(
  bankOrderId: string
): Promise<string> {
  return await ApiClient.get('/api/policy/getPaymentResponse', { bankOrderId });
}

export async function checkFValid(
  customerId: string,
  typePolicy: string,
  subtype: string,
  fvalid: string
): Promise<string> {
  return await ApiClient.get('/api/policy/checkFValid', { customerId, typePolicy, subtype, fvalid });
}

export async function downloadPolicy(
  policyId: string
): Promise<any> {
  return await ApiClient.get('/api/policy/downloadPolicy', { policyId }, true);
}

export async function exportSearchPolicy(
  data: any
): Promise<any> {
  return await ApiClient.post('/api/policy/exportSearchPolicy', data, true);
}

export async function getAppInfo(
): Promise<any> {
  return await ApiClient.get('/api/config/getAppInfo');
}
