import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import InputFieldMain from '../../../Custom/InputFieldMain';
import CustomSelectField from '../../../Custom/CustomSelectField';
import { useFormikContext } from 'formik';
import { AppState } from '../../../../redux/reducers/rootReducer';
import * as nomenActionTypes from '../../../../redux/actionTypes/nomenActionTypes';
import { Loading } from '../../../Layout/Loading';
import { Nomen } from '../../../../utils';
import ApiClient from '../../../../infrastructure/ApiClient';
import { useFormikWizard } from 'formik-wizard';
import moment from 'moment';

type Props = {
    index: number;
    invoiceIndex: number;
    data?: any | null;
    removeRecord?: (index: number) => void;
    setRecords?: () => void;
    enableDelete: boolean;
}

const Record = (props: Props) => {
    const { values, setValues }: any = useFormikContext();
    const { values: wizardValues }: any = useFormikWizard();
    const coverCategories = useSelector<AppState, Nomen[]>((state: any) => state.nomen.CoverCategories);
    const [coverGroups, setCoverGroups] = React.useState([] as any);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (values.invoices[props.invoiceIndex].records[props.index].covercategory !== '') {
            changeCategory(values.invoices[props.invoiceIndex].records[props.index].covercategory);
        }
    }, [props.data.covercategory]);

    const changeCategory = (value: any) => {
        setCoverGroups([] as any);
        fetchData(value);
    };

    const changeGroup = (value: any) => {
        fetchCovers(value);
    };

    const setCategoryLimit = (covercategory: any, limitValue: any,) => {
        values.invoices[props.invoiceIndex].records[props.index].covercategory = covercategory;
        var currentValues = { ...values };
        currentValues.invoices[props.invoiceIndex].records[props.index].categoryLimit = limitValue;

        setValues(currentValues);
    };

    const fetchData = async (value: any) => {
        setLoading(true);

        try {
            const policyCode = !!wizardValues.claim.policy_code
                ? wizardValues.claim.policy_code
                : wizardValues.beneficiaryPerson.policyCode;

            const { data: response } = await ApiClient.get('/api/nomen/getCoverGroupsForPolicy',
                { policy: policyCode, invoiceDate: moment(wizardValues.claim.date_accident).format('YYYY-MM-DD'), coverGroupType: value });

            if (response.succeeded) {
                setCategoryLimit(value, response.limitDescription);
                setCoverGroups(response.data);
            }
            else {
                setCategoryLimit(value, '');
                dispatch({ type: nomenActionTypes.GET_COVER_GROUPS_FOR_POLICY_FAILURE, error: response.errorMessage });
            }
        } catch (error: any) {
            dispatch({ type: nomenActionTypes.GET_COVER_GROUPS_FOR_POLICY_FAILURE, error: error.message });
        }
        setLoading(false);
    }

    const setGroupLimit = (covergroup: any, limitValue: any,) => {
        values.invoices[props.invoiceIndex].records[props.index].covergroup = covergroup;
        var currentValues = { ...values };
        currentValues.invoices[props.invoiceIndex].records[props.index].groupLimit = limitValue;

        setValues(currentValues);
    };

    const fetchCovers = async (value: any) => {
        if (!values.invoices[props.invoiceIndex].records[props.index].covercategory) {
            return;
        }

        setLoading(true);

        try {
            const policyCode = !!wizardValues.claim.policy_code
                ? wizardValues.claim.policy_code
                : wizardValues.beneficiaryPerson.policyCode;

            const coverGroupType = values.invoices[props.invoiceIndex].records[props.index].covercategory;

            const { data: response } = await ApiClient.get('/api/nomen/getCoversForPolicy',
                { policy: policyCode, invoiceDate: moment(wizardValues.claim.date_accident).format('YYYY-MM-DD'), coverGroupType: coverGroupType, coverGroup: value });

            if (response.succeeded) {
                setGroupLimit(value, response.limitDescription);
            }
            else {
                setGroupLimit(value, '');
                dispatch({ type: nomenActionTypes.GET_COVERS_FOR_POLICY_FAILURE, error: response.errorMessage });
            }
        } catch (error: any) {
            dispatch({ type: nomenActionTypes.GET_COVERS_FOR_POLICY_FAILURE, error: error.message });
        }
        setLoading(false);
    }

    const handleClick: React.MouseEventHandler<
        HTMLButtonElement | HTMLAnchorElement
    > = e => {
        if (props.removeRecord) props.removeRecord(props.index);
    }

    const addRecord = () => {
        if (props.setRecords) {
            props.setRecords();
        }
    }

    const CoverCategory: string = `invoices[${props.invoiceIndex}].records[${props.index}].covercategory`;
    const RecordInvoicedAmountV: string = `invoices[${props.invoiceIndex}].records[${props.index}].invoicedAmountV`;
    const CoverGroup: string = `invoices[${props.invoiceIndex}].records[${props.index}].covergroup`;
    const Treating: string = `invoices[${props.invoiceIndex}].records[${props.index}].treating`;

    return (<>
        {loading
            ? <Loading />
            :
            <>
                {props.index === 0 &&
                    <div className="row">
                        <div className="col-xl-9"></div>
                        <div className="col-xl-3"><button type="button" onClick={() => addRecord()} className="btn btn-wiz right-btn" data-wizard-type="action-submit">
                            + Shto reshte
                        </button>
                        </div>
                    </div>}
                <div className="invoiceRecord">
                    {props.index !== -1 && !!props.removeRecord && props.enableDelete && <a>
                        <i className="flaticon2-rubbish-bin-delete-button text-muted icon-1x pull-right"
                            onClick={handleClick} title="Hiq"></i>
                    </a>}

                    <div className="mt20">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="limitDesc">{props.data?.categoryLimit || ''}</div>
                                <CustomSelectField name={CoverCategory} change={changeCategory} label="Mbulimi" items={coverCategories} newvalue={props.data?.covercategory || ''} />
                            </div>
                            <div className="col-xl-6">
                                <InputFieldMain type="text" name={RecordInvoicedAmountV} label="Shuma (€)" placeholder="Shuma" newvalue={props.data?.invoicedAmountV || ''} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="limitDesc">{props.data?.groupLimit || ''}</div>
                                <CustomSelectField name={CoverGroup} change={changeGroup} label="Grupi i mbulimit" items={coverGroups} newvalue={props.data?.covergroup || ''} />
                            </div>
                            <div className="col-xl-6">
                                <InputFieldMain type="text" name={Treating} label="Trajtimi mjeksor (Përshkrimi i trajtimit mjeksor)" placeholder="Trajtimi mjeksor (Përshkrimi i trajtimit mjeksor)" newvalue={props.data?.treating || ''} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
    </>);
}

export default connect()(Record);
