import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import * as claimActionTypes from '../../../redux/actionTypes/claimActionTypes';
import RadioButtonGroupFieldMain from '../../Custom/RadioButtonGroupFieldMain';
import InputFieldMain from '../../Custom/InputFieldMain';
import DatePickerFieldMain from '../../Custom/DatePickerFieldMain';
import SelectField from '../../Custom/SelectField';
import { Gender } from '../../../utils/nomen';
import { useFormikContext } from 'formik';
import { AppState } from "../../../redux/reducers/rootReducer";
import { Nomen } from '../../../utils';
import { Loading } from '../../Layout/Loading';
import store from '../../../redux/configureStore';
import * as actions from '../../../redux/actionCreators/claimActionCreators';
import { PreClaimPerson } from '../../../utils/claimTypes';

const DamagedPerson = () => {
    const { values, setValues }: any = useFormikContext();
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const isPhysical = values.tipKind === '3';
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[claimActionTypes.FETCH_VEHICLE_FROM_POLICY]
        || state.isLoading[claimActionTypes.GET_DAMAGED_PERSON_BY_ID]
        || state.isLoading[claimActionTypes.GET_DAMAGED_COMPANY_BY_ID]);
    const damagedPerson = useSelector<AppState, PreClaimPerson | null>((state: any) => state.claim.damagedPersonById);

    const getPerson = (value: any) => {
        blankModel.clientNo = value;
        setValues(blankModel);

        store.dispatch(actions.getDamagedPersonByIdReset());
        store.dispatch(actions.getDamagedPersonById(value));
    };

    const getCompany = (value: any) => {
        blankModel.nipt = value;
        setValues(blankModel);

        store.dispatch(actions.getDamagedCompanyByIdReset());
        store.dispatch(actions.getDamagedCompanyById(value));
    };

    const changeTipKind = (value: any) => {
        setValues(blankModel);

        if (value === '2') {
            store.dispatch(actions.getDamagedPersonByIdReset());
        }

        if (value === '3') {
            store.dispatch(actions.getDamagedCompanyByIdReset());
        }
    };

    const blankModel = {
        tipKind: values.tipKind,
        nipt: '',
        clientNo: '',
        title: '',
        lastName: '',
        birthdate: '',
        email: '',
        celphone: '',
        address: '',
        place: '',
        drivingLicenceCat: '',
        drivingLicenceNo: '',
        sex: '',
        driverLicenseIssued: '',
    };

    return (<>
        {isLoading
            ? <Loading />
            :
            <>
                <div className="wizardTitle">I Dëmtuari / Marrësi i Shërbimit</div>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div className="row">
                        <div className="col-xl-6">
                            <RadioButtonGroupFieldMain name="tipKind" type="radio" change={changeTipKind} placeholder="Tip Kind" label="Tip" items={[{ id: '3', value: 'Fizik' }, { id: '2', value: 'Juridik' }]} newvalue={values?.tipkind || damagedPerson?.tipkind} />
                        </div>
                        {!isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="nipt" label="Nipti" placeholder="Nipti" blur={getCompany} newvalue={values?.nipt || damagedPerson?.nipt} />
                        </div>}
                        {isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="clientNo" label="Numri personal" placeholder="Numri personal" maxLength={16} blur={getPerson} newvalue={values?.clientno || damagedPerson?.clientno} />
                        </div>}
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="title" label="Emri" placeholder="Emri" newvalue={values?.title || damagedPerson?.title} capitalized />
                        </div>
                        {isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="lastName" label="Mbiemri" placeholder="Mbiemri" newvalue={values?.lastname || damagedPerson?.lastname} capitalized />
                        </div>}

                        {!isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" newvalue={values?.address || damagedPerson?.address} />
                        </div>}
                    </div>
                    <div className="row">
                        {isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" newvalue={values?.address || damagedPerson?.address} />
                        </div>}
                        {!isPhysical &&
                            <div className="col-xl-6">
                                <InputFieldMain type="text" name="celphone" label="Nr. telefonit" placeholder="Nr. telefonit" newvalue={values?.celphone || damagedPerson?.celphone} />
                            </div>}
                        <div className="col-xl-6">
                            <SelectField name="place" label="Vendi" items={places} newvalue={values?.place || (damagedPerson?.place?.code ? damagedPerson?.place?.code : '')} />
                        </div>
                    </div>
                    {isPhysical && <div className="row">
                        <div className="col-xl-6">
                            <SelectField name="sex" label="Gjinia" items={Gender} newvalue={values?.sex || damagedPerson?.sex?.code} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="celphone" label="Nr. telefonit" placeholder="Nr. telefonit" newvalue={values?.celphone || damagedPerson?.celphone} />
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="email" label="Email" placeholder="Email" newvalue={values?.email || damagedPerson?.email} />
                        </div>
                        <div className="col-xl-6">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 addSpace">
                            <DatePickerFieldMain name="driverLicenseIssued" type="text" placeholder="Data e lëshimit të Patentë shoferit" newvalue={values?.driverLicenseIssued || (damagedPerson?.driverLicenseIssued ? new Date(damagedPerson?.driverLicenseIssued) : '')} />
                        </div>
                        {isPhysical && <div className="col-xl-6 addSpace">
                            <DatePickerFieldMain name="birthdate" type="text" placeholder="Datëlindja" newvalue={values?.birthdate || (damagedPerson?.birthdate ? new Date(damagedPerson?.birthdate) : '')} />
                        </div>}
                    </div>
                    <div className="row addSpace">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="drivingLicenceNo" label="Nr. i patentës" placeholder="Nr. i patentës" newvalue={values?.drivingLicenceNo || damagedPerson?.drivingLicenceNo} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="drivingLicenceCat" label="Kategoria e patentë shoferit" placeholder="Kategoria e patentë shoferit" newvalue={values?.drivingLicenceCat || damagedPerson?.drivingLicenceCat} />
                        </div>
                    </div>
                </div>
            </>}
    </>
    );
}
export default connect()(DamagedPerson);
