import React, { useEffect, useMemo } from "react";
import { Redirect, Switch, useLocation } from "react-router-dom";
import { ContentRoute } from "./Layout/ContentRoute";
import Home from "./Home";
import TplPolicy from "./Policy/TplPolicy/TplPolicy";
import GCPolicy from "./Policy/GCPolicy/GCPolicy";
import BorderPolicy from "./Policy/BorderPolicy/BorderPolicy";
import THPolicy from "./Policy/THPolicy/THPolicy";
import UpdateCustomer from "./Auth/UpdateCustomer/UpdateCustomer";
import ChangePassword from "./Auth/ChangePassword";
import PolicyGrid from "./Policy/Grid/PolicyGrid";
import SuccessfulPayment from "./Payment/SuccessfulPayment";
import FailedPayment from "./Payment/FailedPayment";
import FailedPPPayment from "./Payment/FailedPPPayment";
import { RegisterPreClaim, PreClaimGrid, ClaimGrid, ClaimHistoryGrid, Information, SuccessfulRegistration, RegisterHealthPreClaim, RegisterHealthPreClaimKS, SuccessfulHealthRegistration, RegisterPropertyPreClaim, SuccessfulPropertyRegistration } from "./DamageClaim";
import HealthClaimGrid from "./DamageClaim/Grid/HealthClaim/HealthClaimGrid";
import RegisterKaskoPreClaim from "./DamageClaim/Kasko/RegisterKaskoPreClaim";
import SuccessfulHealthRegistrationKS from "./DamageClaim/Health/HealthPreClaimKS/SuccessfulHealthRegistrationKS";
import KaskoPolicy from "./Sale/Kasko/KaskoPolicy";
import PropertyPolicy from "./Sale/Property/PropertyPolicy";
import TravelHealthPolicy from "./Sale/Health/TravelHealth/TravelHealthPolicy";
import PrivateHealthPolicy from "./Sale/Health/PrivateHealth/PrivateHealthPolicy";
import CreditLifePolicy from "./Sale/Life/CreditLife/CreditLifePolicy";
import TermLifePolicy from "./Sale/Life/TermLife/TermLifePolicy";
import { Logout } from "./Auth";
import { connect, useSelector } from "react-redux";
import ForeignerPolicy from "./Sale/Foreigner/ForeignerPolicy";
import { AppState } from "../redux/reducers/rootReducer";
import { AppInfo } from "../utils";
import { User } from "../redux/types";
import { getAppInfo } from "../redux/actionCreators/generalActionCreators";
import { checkActiveSession } from "../redux/actionCreators/authActionCreators";
import * as generalActionTypes from '../redux/actionTypes/generalActionTypes';
import * as authActionTypes from '../redux/actionTypes/authActionTypes';
import { Dispatch } from "redux";
import { Loading } from "./Layout/Loading";

interface Props {
    onCheckActiveSession(token: string, customerId: string): void;
    onGetAppInfo(): void;
}

const BasePage = (props: Props) => {
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const isAuthenticated = useSelector((state: any) => state.auth.authenticated);
    const appInfo = useSelector<AppState, AppInfo | null>((state: any) => state.general.appInfo);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[authActionTypes.CHECK_ACTIVE_SESSION] || state.isLoading[generalActionTypes.GET_APP_INFO]);
    // const location = useLocation();

    useEffect(() => {
        if (user && user.token) {
            props.onCheckActiveSession(user!.token, user!.customerId);
        }
    }, []);

    useEffect(() => {
        if (appInfo == null) props.onGetAppInfo();
    }, []);

    return (<>
        {isLoading ? <Loading />
            : <>
                <Switch>
                    {
                        /* Redirect from root URL to /dashboard. */
                        <Redirect exact from="/" to="/dashboard" />
                    }
                    <ContentRoute path="/dashboard" component={Home} />
                    <ContentRoute path="/kasko-policy" component={KaskoPolicy} />
                    <ContentRoute path="/property-policy" component={PropertyPolicy} />
                    <ContentRoute path="/travel-health-policy" component={TravelHealthPolicy} />
                    <ContentRoute path="/private-health-policy" component={PrivateHealthPolicy} />
                    <ContentRoute path="/foreigner-policy" component={ForeignerPolicy} />
                    <ContentRoute path="/credit-life-policy" component={CreditLifePolicy} />
                    <ContentRoute path="/term-life-policy" component={TermLifePolicy} />
                    <ContentRoute path="/payment/success" component={SuccessfulPayment} />
                    <ContentRoute path="/payment/failure" component={FailedPayment} />
                    <ContentRoute path="/payment/ppfailure" component={FailedPPPayment} />

                    {isAuthenticated ? <>
                        <ContentRoute path="/tpl" component={TplPolicy} />
                        <ContentRoute path="/green-card" component={GCPolicy} />
                        <ContentRoute path="/border-ins" component={BorderPolicy} />
                        <ContentRoute path="/travel-health-ins" component={THPolicy} key="travel-health" />
                        <ContentRoute path="/student-ins" component={THPolicy} key="student" />
                        <ContentRoute path="/policy-search" component={PolicyGrid} />
                        <ContentRoute path="/register-preclaim" component={RegisterPreClaim} />
                        <ContentRoute path="/preclaim-search" component={PreClaimGrid} />
                        <ContentRoute path="/claim-search" component={ClaimGrid} />
                        <ContentRoute path="/claim-history-search" component={ClaimHistoryGrid} />
                        <ContentRoute path="/info-bonus-malus" component={Information} />
                        <ContentRoute path="/update-profile" component={UpdateCustomer} />
                        <ContentRoute path="/change-password" component={ChangePassword} />

                        <ContentRoute path="/preclaim/success" component={SuccessfulRegistration} />
                        <ContentRoute path="/register-health-preclaim" component={RegisterHealthPreClaim} />
                        <ContentRoute path="/register-health-preclaim-ks" component={RegisterHealthPreClaimKS} />
                        <ContentRoute path="/preclaim-health/success" component={SuccessfulHealthRegistration} />
                        <ContentRoute path="/register-property-preclaim" component={RegisterPropertyPreClaim} />
                        <ContentRoute path="/preclaim-property/success" component={SuccessfulPropertyRegistration} />
                        <ContentRoute path="/preclaim-health-ks/success" component={SuccessfulHealthRegistrationKS} />
                        <ContentRoute path="/health-claim-search" component={HealthClaimGrid} />
                        <ContentRoute path="/register-kasko-preclaim" component={RegisterKaskoPreClaim} />
                        <ContentRoute path="/logout" component={Logout} /></>
                        : <Redirect to="/auth/login" />}
                </Switch>
            </>}
    </>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<generalActionTypes.GetAppInfoAction | authActionTypes.CheckActiveSessionAction>) => ({
    onCheckActiveSession: (token: string, customerId: string) => {
        dispatch(checkActiveSession(token, customerId));
    },
    onGetAppInfo: () => {
        dispatch(getAppInfo());
    }
});

export default connect(null, mapDispatchToProps)(BasePage);