import React from "react";
import { useSelector } from "react-redux";
import { PreClaimRegisterData } from '../../../utils/claimTypes';
import { AppState } from '../../../redux/reducers/rootReducer';

const SuccessfulHealthRegistration = () => {
    const preClaimRegisterData = useSelector<AppState, PreClaimRegisterData | null>((state: any) => state.claim.propertyPreClaimRegisterData);

    return (
        <div>
            <div className="card card-custom overflow-hidden">
                <div className="card-body p-0">
                    <div className="row justify-content-center bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0" style={{ backgroundImage: "url(../media/bg/bg-8.jpg)" }}>
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                                <h1 className="display-4 text-white font-weight-boldest mb-10">
                                    Keni regjistuar me sukses parakerkesën e dëmit.
                                </h1>
                            </div>
                            <div className="border-bottom w-100 opacity-20"></div>
                            {preClaimRegisterData !== null &&
                                <>
                                    <div className="d-flex align-items-center">
                                        <div className="display-4 text-white">
                                           Numri i kërkesës:  <b>{preClaimRegisterData.preClaimId}</b>
                                    </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuccessfulHealthRegistration;