import { BookingData, PiPolicy, ThInsQuotation } from "../../utils/policyTypes";
import * as actions from "../actionTypes/piPolicyActionTypes";

export function getPIQuotation(
    policy: PiPolicy
): actions.GetPIQuotationAction {
    return {
        type: actions.GET_PI_QUOTATION,
        policy
    };
}

export function getPIQuotationRequest(
): actions.GetPIQuotationRequestAction {
    return {
        type: actions.GET_PI_QUOTATION_REQUEST
    };
}

export function getPIQuotationReset(
): actions.GetPIQuotationResetAction {
    return {
        type: actions.GET_PI_QUOTATION_RESET
    };
}

export function getPIQuotationSuccess(
    values: ThInsQuotation
): actions.GetPIQuotationSuccessAction {
    return {
        type: actions.GET_PI_QUOTATION_SUCCESS,
        data: values
    };
}

export function getPIQuotationFailure(
    error: Error | string
): actions.GetPIQuotationFailureAction {
    return {
        type: actions.GET_PI_QUOTATION_FAILURE,
        error
    };
}

export function getPackagePIQuotation(
    policy: PiPolicy
): actions.GetPackagePIQuotationAction {
    return {
        type: actions.GET_PACKAGE_PI_QUOTATION,
        policy
    };
}

export function getPackagePIQuotationRequest(
): actions.GetPackagePIQuotationRequestAction {
    return {
        type: actions.GET_PACKAGE_PI_QUOTATION_REQUEST
    };
}

export function getPackagePIQuotationReset(
): actions.GetPackagePIQuotationResetAction {
    return {
        type: actions.GET_PACKAGE_PI_QUOTATION_RESET
    };
}

export function getPackagePIQuotationSuccess(
    values: ThInsQuotation
): actions.GetPackagePIQuotationSuccessAction {
    return {
        type: actions.GET_PACKAGE_PI_QUOTATION_SUCCESS,
        data: values
    };
}

export function getPackagePIQuotationFailure(
    error: Error | string
): actions.GetPackagePIQuotationFailureAction {
    return {
        type: actions.GET_PACKAGE_PI_QUOTATION_FAILURE,
        error
    };
}

export function bookPIPolicy(
    policy: PiPolicy,
): actions.BookPIPolicyAction {
    return {
        type: actions.BOOK_PI_POLICY,
        policy
    };
}

export function bookPIPolicyRequest(
): actions.BookPIPolicyRequestAction {
    return {
        type: actions.BOOK_PI_POLICY_REQUEST
    };
}

export function bookPIPolicySuccess(
    values: BookingData
): actions.BookPIPolicySuccessAction {
    return {
        type: actions.BOOK_PI_POLICY_SUCCESS,
        data: values
    };
}

export function bookPIPolicyFailure(
    error: Error | string
): actions.BookPIPolicyFailureAction {
    return {
        type: actions.BOOK_PI_POLICY_FAILURE,
        error
    };
}