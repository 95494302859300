import { PreClaimGridItem, PreClaimRegisterData, ClaimGridItem, PreClaimPreview, PreClaimVehicle, PreClaimPerson, PreClaim } from './../../utils/claimTypes';
import * as actions from "../actionTypes/claimActionTypes";

export interface ClaimState {
  preClaimRegisterData: PreClaimRegisterData | null;
  healthPreClaimRegisterData: PreClaimRegisterData | null;
  propertyPreClaimRegisterData: PreClaimRegisterData | null;
  preclaims: PreClaimGridItem[];
  claims: ClaimGridItem[];
  preclaimsCount: number;
  claimsCount: number;
  selectedPreClaim: PreClaimPreview | null;
  selectedClaim: ClaimGridItem | null;
  showPreClaimModal: boolean;
  showClaimModal: boolean;
  vehicle: PreClaimVehicle | null;
  claimsHistory: ClaimGridItem[];
  claimsHistoryCount: number;
  insuredPerson: PreClaimPerson | null;
  insuredPersonById: PreClaimPerson | null;
  damagedPersonById: PreClaimPerson | null;
  vehicleByChassisOrPlate: PreClaimVehicle | null;
  kasko: PreClaim | null;
}

const initialState: ClaimState = {
  preClaimRegisterData: null,
  healthPreClaimRegisterData: null,
  propertyPreClaimRegisterData: null,
  preclaims: [],
  claims: [],
  preclaimsCount: 0,
  claimsCount: 0,
  selectedPreClaim: null,
  selectedClaim: null,
  showPreClaimModal: false,
  showClaimModal: false,
  vehicle: null,
  claimsHistory: [],
  claimsHistoryCount: 0,
  insuredPerson: null,
  insuredPersonById: null,
  damagedPersonById: null,
  vehicleByChassisOrPlate: null,
  kasko: null
};

export default function claimReducer(
  state: ClaimState = initialState,
  action: actions.ClaimAction
): ClaimState {
  switch (action.type) {
    case actions.REGISTER_PRE_CLAIM_SUCCESS:
      return {
        ...state,
        preClaimRegisterData: action.data
      };
    case actions.SEARCH_PRECLAIM_SUCCESS:
      return {
        ...state,
        preclaims: action.data,
        preclaimsCount: action.count
      };
    case actions.SEARCH_PRECLAIM_RESET:
      return {
        ...state,
        preclaims: [],
        preclaimsCount: 0
      };
    case actions.SEARCH_CLAIM_SUCCESS:
      return {
        ...state,
        claims: action.data,
        claimsCount: action.count
      };
    case actions.SEARCH_CLAIM_RESET:
      return {
        ...state,
        claims: [],
        claimsCount: 0
      };
    case actions.PRECLAIM_PREVIEW_SUCCESS:
      return {
        ...state,
        selectedPreClaim: action.data,
      };
    case actions.PRECLAIM_PREVIEW_RESET:
      return {
        ...state,
        selectedPreClaim: null,
        showPreClaimModal: false
      };
    case actions.SHOW_PRECLAIM_MODAL:
      return {
        ...state,
        showPreClaimModal: true
      };
    case actions.CLAIM_PREVIEW_SUCCESS:
      return {
        ...state,
        selectedClaim: action.data,
      };
    case actions.CLAIM_PREVIEW_RESET:
      return {
        ...state,
        selectedClaim: null,
        showClaimModal: false
      };
    case actions.SHOW_CLAIM_MODAL:
      return {
        ...state,
        showClaimModal: true
      };
    case actions.FETCH_VEHICLE_FROM_POLICY_SUCCESS:
      return {
        ...state,
        vehicle: action.data
      };
    case actions.FETCH_VEHICLE_FROM_POLICY_RESET:
      return {
        ...state,
        vehicle: null
      };
    case actions.FETCH_INSURED_PERSON_FROM_POLICY_SUCCESS:
      return {
        ...state,
        insuredPerson: action.data
      };
    case actions.FETCH_INSURED_PERSON_FROM_POLICY_RESET:
      return {
        ...state,
        insuredPerson: null
      };
    case actions.REGISTER_HEALTH_PRE_CLAIM_SUCCESS:
      return {
        ...state,
        healthPreClaimRegisterData: action.data
      };
    case actions.REGISTER_PROPERTY_PRE_CLAIM_SUCCESS:
      return {
        ...state,
        propertyPreClaimRegisterData: action.data
      };
    case actions.SEARCH_CLAIM_HISTORY_SUCCESS:
      return {
        ...state,
        claimsHistory: action.data,
        claimsHistoryCount: action.count
      };
    case actions.SEARCH_CLAIM_HISTORY_RESET:
      return {
        ...state,
        claimsHistory: [],
        claimsHistoryCount: 0
      };
    case actions.GET_INSURED_PERSON_BY_ID_SUCCESS:
      return {
        ...state,
        insuredPersonById: action.data
      };
    case actions.GET_INSURED_PERSON_BY_ID_RESET:
      return {
        ...state,
        insuredPersonById: null
      };
    case actions.GET_DAMAGED_PERSON_BY_ID_SUCCESS:
      return {
        ...state,
        damagedPersonById: action.data
      };
    case actions.GET_DAMAGED_PERSON_BY_ID_RESET:
      return {
        ...state,
        damagedPersonById: null
      };

    case actions.GET_INSURED_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        insuredPersonById: action.data
      };
    case actions.GET_INSURED_COMPANY_BY_ID_RESET:
      return {
        ...state,
        insuredPersonById: null
      };
    case actions.GET_DAMAGED_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        damagedPersonById: action.data
      };
    case actions.GET_DAMAGED_COMPANY_BY_ID_RESET:
      return {
        ...state,
        damagedPersonById: null
      };
    case actions.GET_VEHICLE_BY_PLATE_NUMBER_SUCCESS:
      return {
        ...state,
        vehicleByChassisOrPlate: action.data
      };
    case actions.GET_VEHICLE_BY_PLATE_NUMBER_RESET:
      return {
        ...state,
        vehicleByChassisOrPlate: null
      };
    case actions.GET_VEHICLE_BY_CHASSIS_SUCCESS:
      return {
        ...state,
        vehicleByChassisOrPlate: action.data
      };
    case actions.GET_VEHICLE_BY_CHASSIS_RESET:
      return {
        ...state,
        vehicleByChassisOrPlate: null
      };
    case actions.FETCH_KASKO_DATA_FROM_POLICY_SUCCESS:
      return {
        ...state,
        kasko: action.data
      };
    case actions.FETCH_KASKO_DATA_FROM_POLICY_RESET:
      return {
        ...state,
        kasko: null
      };
    default:
      return state;
  }
}
