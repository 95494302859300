import React, { useEffect } from 'react';
import { Modal, ModalProps } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { Dispatch } from "redux";
import { connect, useSelector } from "react-redux";
import { autoRegisterUser } from "../../../redux/actionCreators/authActionCreators";
import * as actionTypes from "../../../redux/actionTypes/authActionTypes";
import { Link } from "react-router-dom";
import { Loading2 } from "../../Layout/Loading2";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Nomen } from "../../../utils";
import moment from 'moment';
import { Gender } from '../../../../src/utils/nomen';
import DatePickerFieldInline from "../../Custom/DatePickerFieldInline";
import SelectField from "../../Custom/SelectField";
import InputFieldMain from "../../Custom/InputFieldMain";
import CheckboxField from '../../Custom/CheckboxField';

interface FormValues {
    clientno: string,
    title: string,
    lastname: string,
    sex: string,
    email: string;
    mob: string,
    address: string,
    place: string,
    birthdate: string,
    password: string,
    acceptTerms: boolean;
}

const initialValues = {
    clientno: '',
    title: '',
    lastname: '',
    sex: '',
    email: '',
    mob: '',
    address: '',
    place: '',
    birthdate: '',
    password: '',
    acceptTerms: false
};

type Props = {
    onAutoRegister(tipKind: string,
        clientno: string,
        nipt: string,
        title: string,
        lastname: string,
        sex: string,
        email: string,
        mob: string,
        address: string,
        place: string,
        birthdate: string | null,
        password: string): void;
    isLoading: boolean;
    error: null | Error | string;
    onClose: () => void;
}
const RegistrationModal = (props: FormikProps<FormValues> & Props & ModalProps) => {
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const insuredPerson = useSelector<AppState, any | null>((state: any) => state.general.insuredPerson);

    const RegistrationSchema = Yup.object().shape({
        clientno: Yup.string().required('E domosdoshme'),
        title: Yup.string()
            .min(3, "Minimumi 3 simbole")
            .max(50, "Maksimumi 50 simbole")
            .required("E domosdoshme"),
        lastname: Yup.string().required('E domosdoshme'),
        sex: Yup.string().required('E domosdoshme'),
        address: Yup.string().required('E domosdoshme'),
        place: Yup.string().required('E domosdoshme'),
        email: Yup.string()
            .email("Format I gabuar I email-it")
            .min(3, "Minimumi 3 simbole")
            .max(50, "Maksimumi 50 simbole")
            .required('E domosdoshme'),
        mob: Yup.string().required('E domosdoshme'),
        birthdate: Yup.date().required('E domosdoshme').typeError('E domosdoshme')
            .test(
                'validBirthDate',
                'Data e lindjes nuk mund te jete pas dates se sotshme',
                function (this: any, value: any) {
                    if (moment(value, 'dd/MM/yyyy').isValid())
                        return moment(value, 'dd/MM/yyyy').isBefore(moment());
                    return true;
                }),
        password: Yup.string()
            .min(6, "Minimum 6 symbols")
            .max(50, "Maksimumi 50 simbole")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
                "Duhet te permbaje 6 karaktere, nje shkronje te madhe, nje shkronje te vogel, nje numer dhe nje karakter"
            )
            .required('E domosdoshme'),
        acceptTerms: Yup.bool().oneOf([true], 'E domosdoshme')
    });

    const handleSubmit = async (values: any) => {
        props.onAutoRegister('3',
            values.clientno,
            '',
            values.title,
            values.lastname,
            values.sex,
            values.email,
            values.mob,
            values.address,
            values.place,
            values.birthdate !== "" ? moment(values.birthdate).format('YYYY-MM-DD') : null,
            values.password);
    };

    useEffect(() => {
        handleSubmit(initialValues);
    }, []);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton style={{ justifyContent: "center" }}>
                <Modal.Title id="contained-modal-title-vcenter">
                    <Link to="/" className="login-logo">
                        <img
                            alt="Logo"
                            className="max-h-70px"
                            src={toAbsoluteUrl("/media/logos/sigal_new.png")}
                        />
                    </Link>

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.isLoading ? <Loading2 />
                    :
                    <>
                        <div className="t-centered">
                            Shkruni te dhenat
                        </div>
                        <hr />
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={RegistrationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ getFieldProps, handleSubmit }) => (
                                <form onSubmit={(e: any) => {
                                    handleSubmit(e);
                                }}>
                                    {/* clientno */}
                                    <div className="form-group addSpace">
                                        <InputFieldMain {...getFieldProps("clientno")} type="text" label="Numri personal" placeholder="Numri personal" maxLength={16} inline newvalue={insuredPerson?.clientNo} />
                                    </div>

                                    {/* title */}
                                    <div className="form-group addSpace">
                                        <InputFieldMain {...getFieldProps("title")} type="text" label="Emri" placeholder="Emri" inline newvalue={insuredPerson?.title} capitalized />
                                    </div>

                                    {/* lastname */}
                                    <div className="form-group addSpace">
                                        <InputFieldMain {...getFieldProps("lastname")} type="text" label="Mbiemri" placeholder="Mbiemri" inline newvalue={insuredPerson?.lastName} capitalized />
                                    </div>

                                    {/* sex */}
                                    <div className="form-group addSpace">
                                        <SelectField  {...getFieldProps("sex")} label="Gjinia" items={Gender} inline newvalue={insuredPerson?.sex} />
                                    </div>

                                    {/* address */}
                                    <div className="form-group addSpace">
                                        <InputFieldMain {...getFieldProps("address")} type="text" label="Adresa" placeholder="Adresa" inline newvalue={insuredPerson?.address} />
                                    </div>

                                    {/* place */}
                                    <div className="form-group addSpace">
                                        <InputFieldMain {...getFieldProps("place")} type="text" label="Vendi" placeholder="Vendi" inline
                                            newvalue={insuredPerson?.place ? places.find((place: Nomen) => place.code === insuredPerson?.place)?.name : ""} />
                                    </div>

                                    {/* email */}
                                    <div className="form-group addSpace">
                                        <InputFieldMain  {...getFieldProps("email")} type="text" label="Email" placeholder="Email" inline newvalue={insuredPerson?.email} />
                                    </div>

                                    {/* mob */}
                                    <div className="form-group addSpace">
                                        <InputFieldMain {...getFieldProps("mob")} type="text" label="Nr. telefonit" placeholder="Nr. telefonit" inline newvalue={insuredPerson?.celphone} />
                                    </div>

                                    {/* birthdate */}
                                    <div className="form-group addSpace">
                                        <DatePickerFieldInline {...getFieldProps("birthdate")} type="text" placeholder="Datëlindja" newvalue={insuredPerson?.birthDate ? new Date(insuredPerson?.birthDate) : ''} />
                                    </div>

                                    {/* password */}
                                    <div className="form-group addSpace">
                                        <InputFieldMain {...getFieldProps("password")} type="password" label="Fjalëkalim" placeholder="Fjalëkalim" inline />
                                    </div>

                                    {/* accept terms */}
                                    <div className="row">
                                        <div className="col-xl-12 checkboxCtrl">
                                            <CheckboxField name="acceptTerms" type="checkbox" label="Konfirmoj se kam lexuar dhe jam pajtuar e përcaktimet, kushtet, autorizimin dhe jap pëlqimin për marrjen dhe mbrojtjen e të dhënave personale" />
                                        </div>
                                        <div className="col-xl-12 checkboxCtrl">
                                            <div className="mb-10 font-weight-bold text-dark"><a href="https://sigal-ks.com/wp-content/uploads/2022/08/POLITIKA-E-PRIVATESISE-SIGAL.pdf" target="_blank">Politika e privatesisë Sigal Uniqa Group Austria Kosovë</a></div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="pb-lg-0 pb-5 t-centered">
                                        <button className="btn btn-wiz btn-default font-weight-bolder font-size-h6 px-6 py-3 my-3 mr-3 modalBtn" onClick={props.onClose}>Anulo</button>
                                        <button
                                            id="kt_singin_form_modal_submit_button"
                                            type="submit"
                                            disabled={props.isLoading}
                                            className={`btn btn-wiz font-weight-bolder font-size-h6 px-6 py-3 my-3 mr-3 modalBtn`}
                                        >
                                            <span>Regjistro</span>
                                            {props.isLoading && <span className="ml-3 spinner spinner-white"></span>}
                                        </button>
                                    </div>
                                </form >
                            )}
                        </Formik>
                    </>
                }
            </Modal.Body>
        </Modal>
    )
};

const mapDispatchToProps = (dispatch: Dispatch<actionTypes.AutoRegisterUserAction>) => ({
    onAutoRegister: (tipKind: string,
        clientno: string,
        nipt: string,
        title: string,
        lastname: string,
        sex: string,
        email: string,
        mob: string,
        address: string,
        place: string,
        birthdate: string,
        password: string) => {
        dispatch(autoRegisterUser(tipKind, clientno, nipt, title, lastname, sex, email, mob, address, place, birthdate, password));
    }
});

const mapStateToProps = (state: any) => ({
    isLoading: state.isLoading[actionTypes.AUTO_REGISTER_USER],
    error: state.error[actionTypes.AUTO_REGISTER_USER]
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationModal);