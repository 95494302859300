import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from "redux";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { AllPolicyTypes } from '../../../../utils/nomen';
import { AppState } from '../../../../redux/reducers/rootReducer';
import * as generalActionTypes from "../../../../redux/actionTypes/generalActionTypes";
import * as claimActionTypes from "../../../../redux/actionTypes/claimActionTypes";
import { hideModal } from "../../../../redux/actionCreators/generalActionCreators";
import { preClaimPreviewReset, searchPreClaim, exportSearchPreClaim } from "../../../../redux/actionCreators/claimActionCreators";
import SimpleModal from "../../../General/SimpleModal";
import { User } from '../../../../redux/types';
import DatePickerFieldMain from '../../../Custom/DatePickerFieldMain';
import { preparePreClaimFilter } from '../../../../utils/claimTypes';
import PreClaimModal from './PreClaimModal';
import PreClaimDataDisplay from './PreClaimDataDisplay';

interface FormValues {
    pageSize: number;
    pageCount: number;
    dateAccidentFrom: Date | null;
    dateAccidentTo: Date | null;
    dateReportedFrom: Date | null;
    dateReportedTo: Date | null;
    policyType: string;
    shownum: string;
    personName: string;
    vehicleRegnum: string;
}

const initialValues = {
    pageSize: 5,
    pageCount: 1,
    dateAccidentFrom: null,
    dateAccidentTo: null,
    dateReportedFrom: null,
    dateReportedTo: null,
    policyType: "",
    shownum: "",
    personName: "",
    vehicleRegnum: ""
};

interface Props {
    onSearch(filter: any): void;
    closeModal(): void;
    resetSelectedPreClaim(): void;
    onExport(filter: any): void;
}

const PreClaimGrid = (props: FormikProps<FormValues> & Props) => {
    const showModal = useSelector<AppState, boolean>((state: any) => state.general.showModal);
    const header = useSelector<AppState, string>((state: any) => state.general.header);
    const text = useSelector<AppState, string>((state: any) => state.general.text);
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const showPreClaimModal = useSelector<AppState, boolean>((state: any) => state.claim.showPreClaimModal);

    const PreClaimGridSchema = Yup.object().shape({
        dateAccidentFrom: Yup.mixed(),
        dateAccidentTo: Yup.mixed(),
        dateReportedFrom: Yup.mixed(),
        dateReportedTo: Yup.mixed(),
        policyType: Yup.string(),
        shownum: Yup.string(),
        personName: Yup.string(),
        vehicleRegnum: Yup.string(),
        pageSize: Yup.number(),
        pageCount: Yup.number()
    });

    const handleSubmit = (values: any) => {
        const filter = preparePreClaimFilter(values, user!.customerId);
        props.onSearch(filter);
    };

    useEffect(() => {
        handleSubmit(initialValues);
    }, []);

    const handleExport = (values: any) => {
        const filter = preparePreClaimFilter(values, user!.customerId);
        props.onExport(filter);
    };

    const handleCloseModal = () => {
        props.closeModal();
    };

    const emptySelectedPreClaim = () => {
        props.resetSelectedPreClaim();
    };

    return (
        <div className="card card-custom">
            <SimpleModal show={showModal} header={header} text={text} onClose={handleCloseModal} onHide={() => { return; }} />
            <PreClaimModal show={showPreClaimModal} header={header} text={text} onClose={emptySelectedPreClaim} onHide={() => { return; }} />
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={PreClaimGridSchema}
                onSubmit={handleSubmit}
            >
                {({ getFieldProps, handleSubmit, setFieldValue, values }) => (
                    <form onSubmit={(e: any) => {
                        setFieldValue('pageSize', 5);
                        setFieldValue('pageCount', 1);
                        handleSubmit(e);
                    }}>
                        <div className="card-header flex-wrap border-0 pt-6 pb-0 bottom-line">
                            <div className="card-title">
                                <h3 className="card-label">
                                    Kërko kërkesën për dëm
                                    <span className="text-muted pt-2 font-size-sm d-block">
                                        Kërkimi dhe shikimi i kërkesave për dëmshpërblim
                                    </span>
                                </h3>
                            </div>
                        </div>
                        <div className="card-body ">
                            {/* <!--begin::Search Form--> */}
                            <div className="mb-7">
                                <div className="row align-items-center">
                                    <div className="col-md-4 my-2 my-md-0">
                                        <div className="d-flex align-items-center">
                                            <label className="mr-3 mb-0 d-none d-md-block">Numri i policës:</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Numri i policës"
                                                {...getFieldProps("shownum")}
                                                id="kt_datatable_search_query" />
                                        </div>
                                    </div>
                                    <div className="col-md-2 my-2 my-md-0">
                                        <div className="d-flex align-items-center">
                                            <label className="mr-3 mb-0 d-none d-md-block">Lloji i policës:</label>
                                            <select {...getFieldProps("policyType")} className="form-control" id="kt_datatable_search_status">
                                                <option key={-1} value="">-</option>
                                                {AllPolicyTypes.map(item => (<option key={item.code + "_" + item.name} value={item.code}>{item.name}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2 my-2 my-md-0">
                                        <DatePickerFieldMain name="dateAccidentFrom" type="text" hideHelper placeholder="Data e aksidentit prej" />
                                    </div>
                                    <div className="col-md-2 my-2 my-md-0">
                                        <DatePickerFieldMain name="dateAccidentTo" hideHelper type="text" placeholder="deri" />
                                    </div>

                                </div>
                            </div>
                            <div className="mb-7">
                                <div className="row align-items-center">
                                    <div className="col-md-4 my-2 my-md-0">
                                        <div className="d-flex align-items-center">
                                            <label className="mr-3 mb-0 d-none d-md-block">Emri Mbiemri :</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Emri / Numri personal"
                                                {...getFieldProps("personName")}
                                                id="kt_datatable_search_query" />
                                        </div>
                                    </div>
                                    <div className="col-md-2 my-2 my-md-0">
                                        <div className="d-flex align-items-center">
                                            <label className="mr-3 mb-0 d-none d-md-block">Targa:</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Targa"
                                                {...getFieldProps("vehicleRegnum")}
                                                id="kt_datatable_search_query" />
                                        </div>
                                    </div>

                                    <div className="col-md-2 my-2 my-md-0">
                                        <DatePickerFieldMain name="dateReportedFrom" type="text" hideHelper placeholder="Data e raportimit prej" />

                                    </div>
                                    <div className="col-md-2 my-2 my-md-0">
                                        <DatePickerFieldMain name="dateReportedTo" hideHelper type="text" placeholder="deri" />
                                    </div>

                                </div>
                            </div>
                            <div className="mb-7  bottom-line">
                                <div className="row align-items-center">


                                    <div className="col-md-3 my-2 my-md-0">
                                        <button type="submit" className="btn btn-light-primary px-6 font-weight-bold">
                                            Kërko
                                        </button>
                                    </div>
                                    <div className="col-md-3 my-2 my-md-0">
                                        <button type="button" onClick={() => handleExport(values)} className="btn btn-light-primary px-6 font-weight-bold">
                                            Shtype raportin në Excel
                                        </button>
                                    </div>
                                </div>
                                <div className="empty-row "></div>
                            </div>
                            {/* </div> */}
                            {/* <!--end::Search Form--> */}

                            {/* <!--begin: Datatable--> */}
                            <PreClaimDataDisplay onSubmit={handleSubmit} />
                            {/* <!--end: Datatable--> */}
                        </div>
                    </form >
                )}
            </Formik>
        </div >);
}

const mapDispatchToProps = (dispatch: Dispatch<claimActionTypes.SearchPreClaimAction
    | generalActionTypes.HideModalAction | claimActionTypes.PreClaimPreviewResetAction
    | claimActionTypes.ExportSearchPreClaimAction>) => ({
        onSearch: (filter: any) => {
            dispatch(searchPreClaim(filter));
        },
        closeModal: () => { dispatch(hideModal()); },
        resetSelectedPreClaim: () => { dispatch(preClaimPreviewReset()); },
        onExport: (filter: any) => {
            dispatch(exportSearchPreClaim(filter));
        },
    });

export default connect(null, mapDispatchToProps)(PreClaimGrid);