import { BookingData, PhPolicy, Question, ThInsQuotation } from "../../utils/policyTypes";
import * as actions from "../actionTypes/phPolicyActionTypes";

export function phQuestionsFailure(
): actions.PhQuestionsFailureAction {
    return {
        type: actions.PH_QUESTIONS_FAILURE
    };
}

export function phQuestionsReset(
): actions.PhQuestionsResetAction {
    return {
        type: actions.PH_QUESTIONS_RESET
    };
}

export function getPHQuotation(
    policy: PhPolicy
): actions.GetPHQuotationAction {
    return {
        type: actions.GET_PH_QUOTATION,
        policy
    };
}

export function getPHQuotationRequest(
): actions.GetPHQuotationRequestAction {
    return {
        type: actions.GET_PH_QUOTATION_REQUEST
    };
}

export function getPHQuotationReset(
): actions.GetPHQuotationResetAction {
    return {
        type: actions.GET_PH_QUOTATION_RESET
    };
}

export function getPHQuotationSuccess(
    values: ThInsQuotation
): actions.GetPHQuotationSuccessAction {
    return {
        type: actions.GET_PH_QUOTATION_SUCCESS,
        data: values
    };
}

export function getPHQuotationFailure(
    error: Error | string
): actions.GetPHQuotationFailureAction {
    return {
        type: actions.GET_PH_QUOTATION_FAILURE,
        error
    };
}

export function getPackagePHQuotation(
    policy: PhPolicy
): actions.GetPackagePHQuotationAction {
    return {
        type: actions.GET_PACKAGE_PH_QUOTATION,
        policy
    };
}

export function getPackagePHQuotationRequest(
): actions.GetPackagePHQuotationRequestAction {
    return {
        type: actions.GET_PACKAGE_PH_QUOTATION_REQUEST
    };
}

export function getPackagePHQuotationReset(
): actions.GetPackagePHQuotationResetAction {
    return {
        type: actions.GET_PACKAGE_PH_QUOTATION_RESET
    };
}

export function getPackagePHQuotationSuccess(
    values: ThInsQuotation
): actions.GetPackagePHQuotationSuccessAction {
    return {
        type: actions.GET_PACKAGE_PH_QUOTATION_SUCCESS,
        data: values
    };
}

export function getPackagePHQuotationFailure(
    error: Error | string
): actions.GetPackagePHQuotationFailureAction {
    return {
        type: actions.GET_PACKAGE_PH_QUOTATION_FAILURE,
        error
    };
}

export function bookPHPolicy(
    policy: PhPolicy,
): actions.BookPHPolicyAction {
    return {
        type: actions.BOOK_PH_POLICY,
        policy
    };
}

export function bookPHPolicyRequest(
): actions.BookPHPolicyRequestAction {
    return {
        type: actions.BOOK_PH_POLICY_REQUEST
    };
}

export function bookPHPolicySuccess(
    values: BookingData
): actions.BookPHPolicySuccessAction {
    return {
        type: actions.BOOK_PH_POLICY_SUCCESS,
        data: values
    };
}

export function bookPHPolicyFailure(
    error: Error | string
): actions.BookPHPolicyFailureAction {
    return {
        type: actions.BOOK_PH_POLICY_FAILURE,
        error
    };
}