import React, { FunctionComponent } from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";

const getFieldCSSClasses = (touched: boolean, error: string | undefined) => {
    const classes = ["form-control"];
    if (touched && error) {
        classes.push("is-invalid");
    }

    if (touched && !error) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

interface Props {
    name: string;
    type: string;
    placeholder: string;
}


const DatePickerField: FunctionComponent<Props> = ({ ...props }) => {
    const [
        field,
        { error, touched },
    ] = useField({
        name: props.name,
        type: props.name,
    });
    const { setFieldValue } = useFormikContext();

    return (
        <div>
            {props.placeholder !== "" && <label>{props.placeholder} </label>}
            <DatePicker
                showMonthDropdown
                showYearDropdown
                className={"form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6 " + getFieldCSSClasses(touched, error)}
                {...field}
                {...props}
                dateFormat="dd/MM/yyyy"
                selected={(field.value && new Date(field.value)) || null}
                onChange={(val: any) => {
                    setFieldValue(field.name, val);
                }}
            />
            {error && touched ? (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{error}</div>
                </div>
            )
                : <div className="feedback">
                    Ju lutem shkruani <b> {props.placeholder} </b> ne formatin 'dd/mm/yyyy'
        </div>
            }
        </div>
    );
}

export default DatePickerField;