import React from "react";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import { connect, useDispatch } from "react-redux";
import { useFormik, FormikProps } from "formik";
import * as Yup from "yup";
import { loginUser } from "../../redux/actionCreators/authActionCreators";
import * as actionTypes from "../../redux/actionTypes/authActionTypes";
import config from "../../config.json";
import ApiClient from '../../infrastructure/ApiClient';
import SimpleModal from '../General/SimpleModal';
import { Loading } from '../Layout/Loading';
import { toAbsoluteUrl } from "../../_metronic/_helpers";

interface FormValues {
    email: string;
    password: string;
}

const initialValues = {
    email: "",
    password: "",
};

interface Props {
    onLogin(email: string, password: string): void;
    email: string;
    isLoading: boolean;
    error: null | Error | string;
    closeModal(): void;
    showResendMail: boolean;
    withoutAside: boolean;
}

const Login = (props: FormikProps<FormValues> & Props) => {
    const { onLogin } = props;

    const [loading, setLoading] = React.useState(false);

    const [showModal, setModal] = React.useState(false);
    const [header, setHeader] = React.useState('');
    const [text, setText] = React.useState('');

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email("Format I gabuar I email-it")
            .min(3, "Minimumi 3 simbole")
            .max(50, "Maksimumi 50 simbole")
            .required('E domosdoshme'),
        password: Yup.string()
            .min(3, "Minimumi 3 simbole")
            .max(50, "Maksimumi 50 simbole")
            .required('E domosdoshme'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values) => {
            onLogin(values.email, values.password);
        }
    });

    const emailClass = 'form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 '
        + (formik.touched.email && ((formik.errors.email && 'is-invalid') || (!formik.errors.email && 'is-valid')));

    const passwordClass = 'form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 '
        + (formik.touched.password && ((formik.errors.password && 'is-invalid') || (!formik.errors.password && 'is-valid')));

    const resendConfirmationMail = async () => {
        setLoading(true);

        try {
            const { data: response } = await ApiClient.post('/api/user/resendEmailConfirmation?email=' + formik.getFieldProps("email").value);
            if (response.succeeded) {
                setLoading(false);

                setModal(true);
                setHeader('Sukses');
                setText('Email-i i ri konfirmimi është dërguar në ' + formik.getFieldProps("email").value);
                return;
            }
        } catch (error: any) {
            setLoading(false);
        }
    }

    const handleCloseModal = () => {
        setModal(false);
    };

    return (
        <>
            <div className="d-flex flex-column flex-root">
                <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid" id="kt_login">
                    <>
                        {
                            loading
                                ? <Loading />
                                :
                                <>
                                    <div className="login-container order-2 order-lg-1 d-flex flex-center flex-row-fluid px-7 pt-lg-0 pb-lg-0 pt-4 pb-6 bg-white">
                                        <SimpleModal show={showModal} header={header} text={text} onClose={handleCloseModal} onHide={() => { return; }} />
                                        <div className="login-content d-flex flex-column pt-lg-0">
                                            <div className="login-form" id="kt_login_signin_form">
                                                {/*begin::Form*/}
                                                <form
                                                    onSubmit={formik.handleSubmit}
                                                    className="form fv-plugins-bootstrap fv-plugins-framework"
                                                >
                                                    {!props.withoutAside && <Link to="/" className="login-logo pb-xl-20 pb-15">
                                                        <img
                                                            alt="Logo"
                                                            className="max-h-70px"
                                                            src={toAbsoluteUrl("/media/logos/sigal_new.png")}
                                                        />
                                                    </Link>}
                                                    <div className="pb-5 pb-lg-15">
                                                        <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Hyr</h3>
                                                        <div className="text-muted font-weight-bold font-size-h4">
                                                            Nuk ke një llogari?
                                                            <Link to="/auth/registration" className="text-primary font-weight-bolder text-hover-primary"> Regjistrohu </Link>


                                                        </div>
                                                        {props.error && props.error !== "" && <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                                                            <div className="alert-text font-weight-bold">{props.error}</div>
                                                        </div>}

                                                        {!formik.errors.email && props.error && props.showResendMail
                                                            && <a className="font-weight-bolder" onClick={resendConfirmationMail}> Ridergo email konfirmimi </a>}
                                                    </div>

                                                    <div className="form-group fv-plugins-icon-container">
                                                        <label className="font-size-h6 font-weight-bolder text-dark">Email</label>
                                                        <input
                                                            placeholder="Email"
                                                            type="email"
                                                            className={emailClass}
                                                            {...formik.getFieldProps("email")}
                                                        />
                                                        {formik.touched.email && formik.errors.email ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div className="fv-help-block">{formik.errors.email}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center fv-plugins-icon-container">
                                                        <label className="font-size-h6 font-weight-bolder text-dark pt-5">Fjalëkalim</label>
                                                        <Link
                                                            to="/auth/forgot-password"
                                                            className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                                                            id="kt_login_forgot"
                                                        >
                                                            Harruat fjalëkalimin?
                                                        </Link>
                                                        <input
                                                            placeholder="Fjalëkalim"
                                                            type="password"
                                                            className={passwordClass}
                                                            {...formik.getFieldProps("password")}
                                                        />
                                                        {formik.touched.password && formik.errors.password ? (
                                                            <div className="fv-plugins-message-container">
                                                                <div className="fv-help-block">{formik.errors.password}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <div className="pb-lg-0 pb-5">
                                                        <button
                                                            id="kt_login_singin_form_submit_button"
                                                            type="submit"
                                                            disabled={props.isLoading}
                                                            className={`btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3`}
                                                        >
                                                            <span>Hyr</span>
                                                            {props.isLoading && <span className="ml-3 spinner spinner-white"></span>}
                                                        </button>

                                                        <Link to="/dashboard" className="text-primary font-weight-bolder text-hover-primary l-anonymous"> Vazhdo pa u identifikuar </Link>

                                                    </div>
                                                    <div className="pb-lg-0 pb-5">
                                                        <Link
                                                            to={{ pathname: "https://youtu.be/dbOAbgIthFg" }}
                                                            target="_blank"
                                                            className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5 pull-right"
                                                            id="kt_login_forgot"
                                                        >
                                                            Tutorial:    Hyrja në portalin e klientit
                                                        </Link>
                                                    </div>
                                                </form>
                                                {/*end::Form*/}
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </>

                    {/*begin::Aside*/}
                    {!props.withoutAside && <div className="login-aside order-1 order-lg-2 bgi-no-repeat bgi-position-x-right">
                        <div className="login-conteiner bgi-no-repeat bgi-position-x-right bgi-position-y-bottom">
                            {/*begin::Aside title*/}
                            <h3 className="pt-lg-40 pl-lg-20 pb-lg-0 pl-10 py-20 m-0 d-flex justify-content-lg-start font-weight-boldest display5 display1-lg text-white">
                                {/* Ju krijoni familje<br />
                                Ne e sigurojmë atë */}
                            </h3>
                            {/*end::Aside title*/}
                        </div>
                    </div>}
                </div>
                {/*end::Aside*/}
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<actionTypes.LoginUserAction>) => ({
    onLogin: (email: string, password: string) => {
        dispatch(loginUser(email, password, false));
    }
});

const mapStateToProps = (state: any) => ({
    isLoading: state.isLoading[actionTypes.LOGIN_USER],
    error: state.error[actionTypes.LOGIN_USER],
    showResendMail: state.auth.resend
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);