import { BookingData, AifPolicy, ThInsQuotation } from "../../utils/policyTypes";

export const GET_AIF_QUOTATION = "policyActionTypes/GET_AIF_QUOTATION";
export interface GetAIFQuotationAction {
  type: typeof GET_AIF_QUOTATION;
  policy: AifPolicy;
}

export const GET_AIF_QUOTATION_REQUEST = "policyActionTypes/GET_AIF_QUOTATION_REQUEST";
export interface GetAIFQuotationRequestAction {
  type: typeof GET_AIF_QUOTATION_REQUEST;
}

export const GET_AIF_QUOTATION_RESET = "policyActionTypes/GET_AIF_QUOTATION_RESET";
export interface GetAIFQuotationResetAction {
  type: typeof GET_AIF_QUOTATION_RESET;
}

export const GET_AIF_QUOTATION_SUCCESS = "policyActionTypes/GET_AIF_QUOTATION_SUCCESS";
export interface GetAIFQuotationSuccessAction {
  type: typeof GET_AIF_QUOTATION_SUCCESS;
  data: ThInsQuotation;
}

export const GET_AIF_QUOTATION_FAILURE = "policyActionTypes/GET_AIF_QUOTATION_FAILURE";
export interface GetAIFQuotationFailureAction {
  type: typeof GET_AIF_QUOTATION_FAILURE;
  error: Error | string;
}

export const BOOK_AIF_POLICY = "policyActionTypes/BOOK_AIF_POLICY";
export interface BookAIFPolicyAction {
  type: typeof BOOK_AIF_POLICY;
  policy: AifPolicy;
}

export const BOOK_AIF_POLICY_REQUEST = "policyActionTypes/BOOK_AIF_POLICY_REQUEST";
export interface BookAIFPolicyRequestAction {
  type: typeof BOOK_AIF_POLICY_REQUEST;
}

export const BOOK_AIF_POLICY_SUCCESS = "policyActionTypes/BOOK_AIF_POLICY_SUCCESS";
export interface BookAIFPolicySuccessAction {
  type: typeof BOOK_AIF_POLICY_SUCCESS;
  data: BookingData;
}

export const BOOK_AIF_POLICY_FAILURE = "policyActionTypes/BOOK_AIF_POLICY_FAILURE";
export interface BookAIFPolicyFailureAction {
  type: typeof BOOK_AIF_POLICY_FAILURE;
  error: Error | string;
}


export type PolicyAction =
  | GetAIFQuotationAction
  | GetAIFQuotationRequestAction
  | GetAIFQuotationResetAction
  | GetAIFQuotationSuccessAction
  | GetAIFQuotationFailureAction
  | BookAIFPolicyAction
  | BookAIFPolicyRequestAction
  | BookAIFPolicySuccessAction
  | BookAIFPolicyFailureAction;
