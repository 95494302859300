import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import RadioButtonGroupFieldMain from '../../Custom/RadioButtonGroupFieldMain';
import InputFieldMain from '../../Custom/InputFieldMain';
import DatePickerFieldMain from '../../Custom/DatePickerFieldMain';
import SelectField from '../../Custom/SelectField';
import { Gender } from '../../../utils/nomen';
import { AppState } from '../../../redux/reducers/rootReducer';
import { Nomen } from '../../../utils';
import * as claimActionTypes from '../../../redux/actionTypes/claimActionTypes';
import { PreClaimPerson } from '../../../utils/claimTypes';
import { Loading } from '../../Layout/Loading';

const InsuredPerson = () => {
    const { values }: any = useFormikContext();
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const isPhysical = values.tipKind === '3';
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[claimActionTypes.FETCH_INSURED_PERSON_FROM_POLICY]);
    const insuredPerson = useSelector<AppState, PreClaimPerson | null>((state: any) => state.claim.insuredPerson);

    return (<>
        {isLoading
            ? <Loading />
            :
            <>
                <div className="wizardTitle">I Siguruari / Shkaktari</div>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div className="row">
                        <div className="col-xl-6">
                            <RadioButtonGroupFieldMain name="tipKind" type="radio" placeholder="Tip Kind" label="Tip" items={[{ id: '3', value: 'Fizik' }, { id: '2', value: 'Juridik' }]} newvalue={values?.tipkind || insuredPerson?.tipkind} />
                        </div>
                        {!isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="nipt" label="Nipti" placeholder="Nipti" newvalue={values?.nipt || insuredPerson?.nipt} />
                        </div>}
                        {isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="clientNo" label="Numri personal" placeholder="Numri personal" maxLength={16} newvalue={values?.clientno || insuredPerson?.clientno} />
                        </div>}
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="title" label="Emri" placeholder="Emri" newvalue={values?.title || insuredPerson?.title} capitalized />
                        </div>
                        {isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="lastName" label="Mbiemri" placeholder="Mbiemri" newvalue={values?.lastname || insuredPerson?.lastname} capitalized />
                        </div>}

                        {!isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" newvalue={values?.address || insuredPerson?.address} />
                        </div>}

                    </div>

                    <div className="row">
                        {isPhysical && <div className="col-xl-6">
                            <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" newvalue={values?.address || insuredPerson?.address} />
                        </div>}
                        {!isPhysical &&
                            <div className="col-xl-6">
                                <InputFieldMain type="text" name="celphone" label="Nr. telefonit" placeholder="Nr. telefonit" newvalue={values?.celphone || insuredPerson?.celphone} />
                            </div>}
                        <div className="col-xl-6">
                            <SelectField name="place" label="Vendi" items={places} newvalue={values?.place || (insuredPerson?.place?.code ? insuredPerson?.place?.code : '')} />
                        </div>
                    </div>
                    {isPhysical && <div className="row">
                        <div className="col-xl-6">
                            <SelectField name="sex" label="Gjinia" items={Gender} newvalue={values?.sex || insuredPerson?.sex?.code} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="celphone" label="Nr. telefonit" placeholder="Nr. telefonit" newvalue={values?.celphone || insuredPerson?.celphone} />
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="email" label="Email" placeholder="Email" newvalue={values?.email || insuredPerson?.email} />
                        </div>
                        {isPhysical && <div className="col-xl-6 addSpace">
                            <DatePickerFieldMain name="birthDate" type="text" placeholder="Datëlindja" newvalue={values?.birthdate || (insuredPerson?.birthdate ? new Date(insuredPerson?.birthdate) : '')} />
                        </div>}
                    </div>
                </div>
            </>}
    </>
    );
}
export default connect()(InsuredPerson);
