import { User, Customer } from './../types';
import * as actions from "../actionTypes/authActionTypes";
import { CheckProfileDto } from '../../utils';

export interface AuthState {
  user: User | null;
  customer: Customer | null;
  resend: boolean;
  authenticated: boolean;
  checkProfile: CheckProfileDto | null;
}

const initialState: AuthState = {
  user: null,
  customer: null,
  resend: false,
  authenticated: false,
  checkProfile: null
};

export default function authReducer(
  state: AuthState = initialState,
  action: actions.AuthAction
): AuthState {
  switch (action.type) {
    case actions.SET_USER:
    case actions.LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
        customer: null,
        authenticated: true
      };
    case actions.LOGOUT_USER_SUCCESS:
      return {
        user: null,
        customer: null,
        resend: false,
        authenticated: false,
        checkProfile: null
      };
    case actions.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.data
      };
    case actions.RESEND_MAIL:
      return {
        ...state,
        resend: action.resend
      };
    case actions.CHECK_ACTIVE_SESSION_SUCCESS:
      return {
        ...state,
        authenticated: action.authenticated
      };
    case actions.HAS_PROFILE_SUCCESS:
      return {
        ...state,
        checkProfile: action.checkProfile
      };
    case actions.HAS_PROFILE_RESET:
      return {
        ...state,
        checkProfile: null,
      };
    case actions.AUTO_LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
        customer: null,
        authenticated: true
      };
    default:
      return state;
  }
}
