import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { useFormikWizard } from 'formik-wizard';
import SelectField from '../../Custom/SelectField';
import { Payment } from '../../../utils/nomen';
import CheckboxField from '../../Custom/CheckboxField';
import RadioButtonGroupFieldMain from '../../Custom/RadioButtonGroupFieldMain';
import { useFormikContext } from 'formik';
import InputFieldMain from '../../Custom/InputFieldMain';
import { AppState } from '../../../redux/reducers/rootReducer';
import { Nomen } from '../../../utils';
import { MotorInsQuotation } from '../../../utils/policyTypes';
import Pdf from "../../../InsuranceConditions/01_01.pdf";

const Preview = () => {
    const { values: wizValues }: any = useFormikWizard();
    const { values: formikValues }: any = useFormikContext();
    const locations = useSelector<AppState, Nomen[]>((state: any) => state.nomen.DeliveryLocations);
    const motorInsQuotation = useSelector<AppState, MotorInsQuotation | null>((state: any) => state.policy.motorInsQuotation);
    const isContractorPhysical = wizValues.contractor.tipKind === '3';
    const isInsured1Physical = wizValues.insured1.tipKind === '3';
    const isInsured2Physical = wizValues.insured2.tipKind === '3';
    const hasSecondInsurer = wizValues.insured2.hasSecondInsurer;
    const typeFil = formikValues.typeDelivery === '1';
    const typeHome = formikValues.typeDelivery === '2';

    return (<div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
        <h4 className="mb-10 font-weight-bold text-dark">Verifiko</h4>
        <h6 className="font-weight-bolder mb-3">             </h6>
        <div className="text-dark-50 line-height-lg">
            <div className="reviewTitle">Primi</div><div className="reviewValue">{motorInsQuotation?.total1} LEK</div>
            <div className="reviewTitle">Taksa</div><div className="reviewValue">{motorInsQuotation?.total2} LEK</div>
            <div className="reviewTitle">Primi + Taksa</div><div className="reviewValue">{motorInsQuotation?.total} LEK</div>
        </div>
        <br />
        <br />
        <h6 className="font-weight-bolder mb-3">            Automjeti: </h6>
        <div className="text-dark-50 line-height-lg">
            <div className="reviewTitle">Targa</div><div className="reviewValue"> {wizValues.vehicleMain.plateNumber}</div>
            <div className="reviewTitle">Numri i shasisë</div><div className="reviewValue">{wizValues.vehicleMain.chassis}</div><br />
            <div className="reviewTitle">Prodhuesi/Modeli</div><div className="reviewValue"> {wizValues.vehicleDetails.mark} - {wizValues.vehicleDetails.tip}</div>
            <div className="reviewTitle">Ngjyra e automjetit</div><div className="reviewValue">  {wizValues.vehicleDetails.colour}</div><br />
            <div className="reviewTitle">Pesha e automjetit</div><div className="reviewValue">{wizValues.vehicleDetails.capacity}</div>
            <div className="reviewTitle">Fuqia</div><div className="reviewValue"> {wizValues.vehicleDetails.power}</div><br />
            <div className="reviewTitle">Viti i prodhimit</div><div className="reviewValue">{wizValues.vehicleDetails.prodYear}</div>
        </div>
        <div className="separator separator-dashed my-5"></div>
        <h6 className="font-weight-bolder mb-3">            Kontraktuesi: </h6>
        {isContractorPhysical && <div className="text-dark-50 line-height-lg">
            <div className="reviewTitle">Numri personal</div><div className="reviewValue"> {wizValues.contractor.clientNo}</div>
            <div className="reviewTitle">Emri Mbiemri</div><div className="reviewValue"> {wizValues.contractor.title} {wizValues.contractor.lastName}</div><br />
            <div className="reviewTitle">Nr. telefonit</div><div className="reviewValue"> {wizValues.contractor.celphone}</div>
            <div className="reviewTitle">Adresa</div><div className="reviewValue"> {wizValues.contractor.address}</div><br />
            <div className="reviewTitle">Vendi</div><div className="reviewValue"> {wizValues.contractor.place}</div>
        </div>}
        {!isContractorPhysical && <div className="text-dark-50 line-height-lg">
            <div className="reviewTitle">Nipti</div><div className="reviewValue"> {wizValues.contractor.nipt}</div>
            <div className="reviewTitle">Emri</div><div className="reviewValue"> {wizValues.contractor.title} {wizValues.contractor.lastName}</div><br />
            <div className="reviewTitle">Adresa</div><div className="reviewValue"> {wizValues.contractor.address}</div>
            <div className="reviewTitle">Vendi</div><div className="reviewValue"> {wizValues.contractor.place}</div>
        </div>}

        <div className="separator separator-dashed my-5"></div>
        <h6 className="font-weight-bolder mb-3">I Siguruari: </h6>
        {isInsured1Physical && <div className="text-dark-50 line-height-lg">
            <div className="reviewTitle">Numri personal</div><div className="reviewValue">{wizValues.insured1.clientNo}</div>
            <div className="reviewTitle">Emri Mbiemri</div><div className="reviewValue"> {wizValues.insured1.title} {wizValues.contractor.lastName}</div><br />
            <div className="reviewTitle">Nr. telefonit</div><div className="reviewValue"> {wizValues.insured1.celphone}</div>
            <div className="reviewTitle">Adresa</div><div className="reviewValue"> {wizValues.insured1.address}</div><br />
            <div className="reviewTitle">Vendi</div><div className="reviewValue"> {wizValues.insured1.place}</div>
            <div className="reviewTitle">Dëshmi aftësie</div><div className="reviewValue"> {wizValues.insured1.drivingLicenceNo}</div><br />
            <div className="reviewTitle">Dëshmi aftësie - Klasa</div><div className="reviewValue"> {wizValues.insured1.drivingLicenceCat}</div>
        </div>}
        {!isInsured1Physical && <div className="text-dark-50 line-height-lg">
            <div className="reviewTitle">Nipti</div><div className="reviewValue"> {wizValues.insured1.nipt}</div>
            <div className="reviewTitle">Emri</div><div className="reviewValue"> {wizValues.insured1.title} {wizValues.insured1.lastName}</div><br />
            <div className="reviewTitle">Adresa</div><div className="reviewValue"> {wizValues.insured1.address}</div>
            <div className="reviewTitle">Vendi</div><div className="reviewValue"> {wizValues.insured1.place}</div><br />
            <div className="reviewTitle">Dëshmi aftësie</div><div className="reviewValue"> {wizValues.insured1.drivingLicenceNo}</div>
            <div className="reviewTitle">Dëshmi aftësie - Klasa</div><div className="reviewValue"> {wizValues.insured1.drivingLicenceCat}</div>
        </div>}
        {hasSecondInsurer &&
            <><div className="separator separator-dashed my-5"></div>
                <h6 className="font-weight-bolder mb-3">I Siguruari 2:</h6>
                {isInsured2Physical && <div className="text-dark-50 line-height-lg">
                    <div className="reviewTitle">Numri personal</div><div className="reviewValue"> {wizValues.insured2.clientNo}</div>
                    <div className="reviewTitle">Emri Mbiemri</div><div className="reviewValue"> {wizValues.insured2.title} {wizValues.contractor.lastName}</div><br />
                    <div className="reviewTitle">Nr. telefonit</div><div className="reviewValue"> {wizValues.insured2.celphone}</div>
                    <div className="reviewTitle">Adresa</div><div className="reviewValue"> {wizValues.insured2.address}</div><br />
                    <div className="reviewTitle">Vendi</div><div className="reviewValue"> {wizValues.insured1.place}</div>
                    <div className="reviewTitle">Dëshmi aftësie</div><div className="reviewValue"> {wizValues.insured2.drivingLicenceNo}</div><br />
                    <div className="reviewTitle">Dëshmi aftësie - Klasa</div><div className="reviewValue"> {wizValues.insured2.drivingLicenceCat}</div>
                </div>}
                {!isInsured2Physical && <div className="text-dark-50 line-height-lg">
                    <div className="reviewTitle">Nipti</div><div className="reviewValue"> {wizValues.insured2.nipt}</div>
                    <div className="reviewTitle">Emri</div><div className="reviewValue"> {wizValues.insured2.title} {wizValues.insured1.lastName}</div><br />
                    <div className="reviewTitle">Adresa</div><div className="reviewValue"> {wizValues.insured2.address}</div>
                    <div className="reviewTitle">Vendi</div><div className="reviewValue"> {wizValues.insured2.place}</div><br />
                    <div className="reviewTitle">Dëshmi aftësie</div><div className="reviewValue"> {wizValues.insured2.drivingLicenceNo}</div>
                    <div className="reviewTitle">Dëshmi aftësie - Klasa</div><div className="reviewValue"> {wizValues.insured2.drivingLicenceCat}</div>
                </div>}
            </>}
        <div className="separator separator-dashed my-5"></div>
        <div className="row">
            <div className="col-xl-6">
                <h6 className="font-weight-bolder mb-3">Delivery method: </h6>
            </div>
        </div>
        <div className="row">
            <div className="col-xl-6">
                <RadioButtonGroupFieldMain name="typeDelivery" type="radio" placeholder="Delivery method" label="Delivery method" items={[{
                    id: '1', value: 'Merr nga zyra e degës SIGAL'
                }, { id: '2', value: 'Dorëzuar në adresën' }]} />
            </div>
            <div className="col-xl-6">
                {typeFil && <SelectField name="deliveryAddressFil" label="Zyra e degës" items={locations} />}
                {typeHome && <InputFieldMain name="deliveryAddressHome" type="text" placeholder="Adresa e dorëzimit" label="Adresa e dorëzimit" />}
            </div>
        </div>
        <div className="separator separator-dashed my-5"></div>
        <div className="row">
            <div className="col-xl-6">
                <CheckboxField type="checkbox" name="acceptConditions" label="I kuptoj dhe I pranoj kushtet e sigurimit" />
            </div>
            <div className="col-xl-6">
                <a href={Pdf} target="_blank">Kushtet e përgjithshme të sigurimit</a>
            </div>
        </div>
        <div className="separator separator-dashed my-5"></div>
        <CheckboxField type="checkbox" name="acceptTerms" label="Autorizoj shoqërine “Sigal” sha që të përpunojë dhe të përdorë të dhënat e mia personale në përputhje me ligjin nr. 9887, datë 10.03.2008, ndryshuar me ligjin Nr. 48/2012, ndryshuar me ligjin nr.120/2014 “Për mbrojtjen e të dhënave personale”. Nëpërmjet kësaj deklarate autorizoj Shoqërinë “Sigal” sha që të vendosi në dipozicion të Autoriteteve Mbikqyrëse të dhënat e mia personale, të cilat gjithashtu janë subjekt i përdorimit të këtyre të dhënave në përputhje me ligjin Nr. 48/2012, ndryshuar me ligjin nr.120/2014 “Për mbrojtjen e të dhënave personale“" />
        <div className="separator separator-dashed my-5"></div>
        <div className="row">
            <div className="col-xl-3">
                <SelectField name="payment" label="Payment" items={Payment.filter(p => p.code !== '2')} />
            </div>
            <div className="col-xl-9" style={{ height: '60px', width: '491px', backgroundRepeat: 'no-repeat', backgroundPosition: 'center bottom', backgroundImage: 'url(/media/dashboard/cards.png)' }}>
            </div>
        </div>
    </div>
    );
}

export default connect()(Preview);
