import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from "redux";
import { useFormikContext } from 'formik';
import { AppState } from '../../../../redux/reducers/rootReducer';
import { healthClaimPreview, markAppealAsRead } from '../../../../redux/actionCreators/healthPreClaimActionCreators';
import { healthClaimPreviewFirstLevel, healthClaimPreviewSecondLevel, readFirstLevelAppeal, readSecondLevelAppeal } from '../../../../redux/actionCreators/healthPreClaimActionCreators';
import * as healthClaimActionTypes from '../../../../redux/actionTypes/healthClaimActionTypes';
import { Loading2 } from '../../../Layout/Loading2';
import { getPages, getPagesCount } from '../../../../_metronic/_assets/ts/Pagination';
import { HealthClaimGridItem } from '../../../../utils/healthPreClaimTypes';
import { Notification, User } from '../../../../redux/types';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";

interface Props {
	onSubmit: (values: any) => void;
	onPreview: (code: string) => void;
	onFileFirstLevelAppeal: (code: string) => void;
	onFileSecondLevelAppeal: (code: string) => void;
	onReadFirstLevelAppeal: (code: string) => void;
	onReadSecondLevelAppeal: (code: string) => void;
	triggerMarkAppealAsRead: (code: string) => void;
}

const HealthClaimDataDisplay = (props: Props) => {
	const { values }: any = useFormikContext();
	const { setFieldValue } = useFormikContext();
	const healthClaims = useSelector<AppState, HealthClaimGridItem[]>((state: any) => state.healthClaim.healthClaims);
	const healthClaimsCount = useSelector<AppState, number>((state: any) => state.healthClaim.healthClaimsCount);

	const isLoadingSearch = useSelector<AppState, boolean>((state: any) => state.isLoading[healthClaimActionTypes.SEARCH_HEALTH_CLAIM]);
	const isLoadingExport = useSelector<AppState, boolean>((state: any) => state.isLoading[healthClaimActionTypes.EXPORT_SEARCH_HEALTH_CLAIM]);
	const currentPage = values.pageCount;
	const pageSize = values.pageSize;
	const pagesCount = getPagesCount(healthClaimsCount, pageSize);
	const pages = getPages(currentPage, pagesCount, pageSize);

	const customer = useSelector<AppState, User | null>((state: any) => state.auth.customer);

	const from = (pageSize * (currentPage - 1)) + 1;
	const to = pageSize * currentPage;

	const sizePerPageList = [
		{ text: "5", value: 5 },
		{ text: "10", value: 10 },
		{ text: "20", value: 20 },
		{ text: "30", value: 30 },
		{ text: "50", value: 50 },
		{ text: "100", value: 100 }
	];

	const handleFirstPage = () => {
		setFieldValue('pageCount', 1);
		props.onSubmit(values);
	}

	const handlePrevPage = () => {
		const prevPage = currentPage > 1 ? currentPage - 1 : 1;
		setFieldValue('pageCount', prevPage);
		props.onSubmit(values);
	}

	const handleNextPage = () => {
		const nextPage = currentPage < pagesCount ? currentPage + 1 : pagesCount;
		setFieldValue('pageCount', nextPage);
		props.onSubmit(values);
	}

	const handleLastPage = () => {
		setFieldValue('pageCount', pagesCount);
		props.onSubmit(values);
	}

	const handleSelectedPage = (page: number) => {
		setFieldValue('pageCount', page);
		props.onSubmit(values);
	};

	const onSizeChange = (event: any) => {
		const newSize = +event.target.value;
		setFieldValue('pageSize', newSize);
		setFieldValue('pageCount', 1);
		props.onSubmit(values);
	};

	const openHealthClaim = (code: string) => {
		props.onPreview(code);
	};

	const openAppeal = (event: any, action: string, code: string) => {
		if (action == 'Allow_first_level_appeal') {
			props.onFileFirstLevelAppeal(code);
		}

		if (action == 'Allow_second_level_appeal') {
			props.onFileSecondLevelAppeal(code);
		}

		if (action == 'Read_first_level_appeal') {
			props.onReadFirstLevelAppeal(code);
			if (customer?.notifications?.some((n: Notification) => n.queryString === code)) {
				props.triggerMarkAppealAsRead(code);
			}
		}

		if (action == 'Read_second_level_appeal') {
			props.onReadSecondLevelAppeal(code);
			if (customer?.notifications?.some((n: Notification) => n.queryString === code)) {
				props.triggerMarkAppealAsRead(code);
			}
		}
	};

	const disabledClass = pagesCount > 1 ? "" : "datatable-pager-link-disabled";

	const formatAmount = (value: any) => {
		if (value !== null && value !== undefined && value !== '') {
			return value.toFixed(3);
		}
		return value;
	}

	return (
		<>
			{isLoadingSearch || isLoadingExport ?
				<Loading2 />
				: <div className="datatable datatable-bordered 
						datatable-head-custom datatable-default 
						datatable-primary datatable-loaded" id="kt_datatable">
					<table className="datatable-table" style={{ display: 'block' }}>
						<thead className="datatable-head">
							<tr className="datatable-row" style={{ left: '0px' }}>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "105px" }}>Numri i Dëmit/ Numri I kërkeses</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "95px" }}>Polica</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "80px" }}>Përfituesi</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "106px" }}>Kontraktuesi</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "80px" }}>I siguruar</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "91px" }}> Data e raportimit/ aksidentit</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "75px" }}>Shuma e faturës (€)</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "75px" }}>Shuma e miratuar (€)</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "85px" }}>Statusi</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "150px" }}></span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "30px" }}></span>
								</th>
							</tr>
						</thead>
						<tbody className="datatable-body">
							{healthClaims.map((healthClaim: HealthClaimGridItem, index: number) => (
								<tr key={index} className="datatable-row" style={{ left: "0px" }}>
									<td aria-label={healthClaim.claimNumber} className="datatable-cell">
										<span style={{ width: "105px" }}><b>{healthClaim.claimNumber}</b> {healthClaim.preClaimNumber}</span>
									</td>
									<td aria-label={healthClaim.policyCode} className="datatable-cell">
										<span style={{ width: "115px" }}>{healthClaim.policyCode}</span>
									</td>
									<td aria-label={healthClaim.beneficiary} className="datatable-cell">
										<span style={{ width: "100px" }}>{healthClaim.beneficiary}</span>
									</td>
									<td aria-label={healthClaim.contractor} className="datatable-cell">
										<span style={{ width: "100px" }}>{healthClaim.contractor}</span>
									</td>
									<td aria-label={healthClaim.insured} className="datatable-cell">
										<span style={{ width: "100px" }}>{healthClaim.insured}</span>
									</td>
									<td aria-label={healthClaim.dateAccident} className="datatable-cell">
										<span style={{ width: "85px" }}>{healthClaim.dateReported} {healthClaim.dateAccident}</span>
									</td>
									<td aria-label={healthClaim.invoicedAmount} className="datatable-cell">
										<span style={{ width: "75px" }}>{formatAmount(healthClaim.invoicedAmount)}</span>
									</td>
									<td aria-label={healthClaim.approvedAmount} className="datatable-cell">
										<span style={{ width: "75px" }}>{formatAmount(healthClaim.approvedAmount)}</span>
									</td>
									<td aria-label={healthClaim.claimStatus} className="datatable-cell">
										<span style={{ width: "85px" }}>{healthClaim.claimStatus}</span>
									</td>
									{healthClaim.customerServiceActions.length > 0 &&
										<td aria-label={healthClaim.claimStatus} className="datatable-cell">
											<Dropdown>
												<Dropdown.Toggle id="dropdown-button-drop-down" variant="success">
													Sherbimi ndaj Klientit
												</Dropdown.Toggle>
												<Dropdown.Menu popperConfig={{ strategy: "fixed" }}>
													{healthClaim.customerServiceActions.map(option => {
														return (
															<Dropdown.Item key={option.code} onClick={(e) => { openAppeal(e, option.code, healthClaim.claimId); }}>{option.name}</Dropdown.Item>
														);
													})}
												</Dropdown.Menu>
											</Dropdown>
										</td>}
									{healthClaim.customerServiceActions.length == 0 &&
										<td aria-label={healthClaim.claimStatus} className="datatable-cell">
											<span style={{ width: "165px" }}></span>
										</td>}
									<td className="datatable-cell">
										<span style={{ overflow: "visible", position: "relative", width: "30px" }}>
											<a onClick={() => openHealthClaim(healthClaim.claimId)} className="btn btn-sm btn-clean btn-icon mr-2" title="Shiko">
												<span className="svg-icon svg-icon-md">
													<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
														<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
															<rect x="0" y="0" width="24" height="24"></rect>
															<path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
															<rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
														</g>
													</svg>
												</span>
											</a>
										</span>
									</td>
								</tr>))}
						</tbody>
					</table >
					<div className="datatable-pager datatable-paging-loaded">
						{pagesCount < 2 && <></>}
						{pagesCount > 1 && (<>
							<ul className="datatable-pager-nav mb-5 mb-sm-0">
								<li><a title="First" onClick={() => handleFirstPage()}
									className={"datatable-pager-link datatable-pager-link-first" + disabledClass}>
									<i className="flaticon2-fast-back"></i></a></li>
								<li><a title="Previous" onClick={() => handlePrevPage()}
									className={"datatable-pager-link datatable-pager-link-prev" + disabledClass}>
									<i className="flaticon2-back"></i></a></li>
								{currentPage > 1 && (
									<li>
										<div className="datatable-pager-link datatable-pager-link-number">
											...
										</div>
									</li>
								)}
								{pages.map((p) => (
									<li key={p}><a
										title={p.toString()}
										key={p}
										onClick={() => handleSelectedPage(p)}
										className={`datatable-pager-link datatable-pager-link-number ${currentPage === p ? " datatable-pager-link-active" : ""}`}>
										{p}
									</a></li>
								))}
								{currentPage < pagesCount && (
									<li><div className="datatable-pager-link datatable-pager-link-number">
										...
									</div></li>
								)}
								<li>
									<a onClick={() => handleNextPage()}
										title="Next"
										className="datatable-pager-link datatable-pager-link-next">
										<i className="flaticon2-next"></i>
									</a>
								</li>
								<li>
									<a onClick={() => handleLastPage()}
										title="Last"
										className="datatable-pager-link datatable-pager-link-last">
										<i className="flaticon2-fast-next"></i>
									</a>
								</li>
							</ul>
						</>
						)}

						<div className="datatable-pager-info">
							<div className="dropdown bootstrap-select datatable-pager-size" style={{ width: "60px", float: 'left' }}>
								<select
									disabled={healthClaimsCount === 0}
									className={`form-control form-control-sm font-weight-bold mr-4 border-0 bg-light ${healthClaimsCount ===
										0 && "disabled"}`}
									onChange={onSizeChange}
									value={pageSize}
									style={{ width: "62px" }}
								>
									{sizePerPageList.map(option => {
										const isSelect = pageSize === `${option.value}`;
										return (
											<option
												key={option.text}
												value={option.value}
												className={`btn ${isSelect ? "active" : ""}`}
											>
												{option.text}
											</option>
										);
									})}
								</select>
							</div>
							<span className="datatable-pager-detail">Duke shfaqur {from} - {to} nga {healthClaimsCount}</span>
						</div>
					</div>
				</div >
			}
		</>);
}

const mapDispatchToProps = (dispatch: Dispatch<healthClaimActionTypes.HealthClaimPreviewAction
	| healthClaimActionTypes.HealthClaimPreviewFirstLevelAction
	| healthClaimActionTypes.HealthClaimPreviewSecondLevelAction
	| healthClaimActionTypes.ReadFirstLevelAppealAction
	| healthClaimActionTypes.ReadSecondLevelAppealAction
	| healthClaimActionTypes.MarkAppealAsReadAction>) => ({
		onPreview: (code: string) => {
			dispatch(healthClaimPreview(code, true));
		},
		onFileFirstLevelAppeal: (code: string) => {
			dispatch(healthClaimPreviewFirstLevel(code));
		},
		onFileSecondLevelAppeal: (code: string) => {
			dispatch(healthClaimPreviewSecondLevel(code));
		},
		onReadFirstLevelAppeal: (code: string) => {
			dispatch(readFirstLevelAppeal(code));
		},
		onReadSecondLevelAppeal: (code: string) => {
			dispatch(readSecondLevelAppeal(code));
		},
		triggerMarkAppealAsRead: (code: string) => {
			dispatch(markAppealAsRead(code));
		}
	});

export default connect(null, mapDispatchToProps)(HealthClaimDataDisplay);