import React from "react";
import { connect, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { HideModalAction } from "../../../redux/actionTypes/generalActionTypes";
import { withRouter } from 'react-router-dom';
import { AppState } from "../../../redux/reducers/rootReducer";
import steps from './steps';
import FormikWizard from 'formik-wizard';
import { FormikWizardWrapperProps } from "../../../utils/wizardTypes";
import SimpleModal from '../../General/SimpleModal';
import Nav from './Nav';
import {
    getTplVehicleCategoryClassType,
    getVehicleMark,
    getDeliveryBranchLocations
} from "../../../redux/actionCreators/nomenActionCreators";
import * as nomenActionTypes from "../../../redux/actionTypes/nomenActionTypes";
import * as actionTypes from "../../../redux/actionTypes/tplPolicyActionTypes";
import { hideModal } from "../../../redux/actionCreators/generalActionCreators";
import { RouteComponentProps } from "../../Layout/Content";
import { Loading } from "../../Layout/Loading";

interface Props {
    onGetTplVehicleCategoryClassType(): void;
    onGetVehicleMark(): void;
    onGetDeliveryBranchLocations(): void;
    closeModal(): void;
    showModal: boolean;
    description: string;
    header: string;
    nextStep: string;
    history: History;
    isLoading: boolean;
    nomenFetched: boolean;
}

interface PathProps {
    history: any;
}

function FormWrapper({
    children,
    isLastStep,
    status,
    goToPreviousStep,
    canGoBack,
    actionLabel,
    currentStep
}: FormikWizardWrapperProps<any, any>) {
    const policyStartDateValidity = useSelector<AppState, boolean | null>((state: any) => state.general.policyStartDateValidity);
    const erroredStep1 = useSelector<AppState, null | Error | string>((state: any) => state.error[actionTypes.GET_AMFTPL_QUOTATION]);
    const erroredStep2 = useSelector<AppState, null | Error | string>((state: any) => state.error[actionTypes.GET_TPL_QUOTATION]);
    const erroredStep6 = useSelector<AppState, null | Error | string>((state: any) => state.error[actionTypes.BOOK_TPL_POLICY]);
    const action = useSelector<AppState, string>((state: any) => state.general.nextStep);

    if (currentStep === steps[1].id && ((erroredStep1 !== null && action === null) || (policyStartDateValidity !== null && !policyStartDateValidity))) {
        goToPreviousStep()
    };
    if (currentStep === steps[2].id && erroredStep2 !== null && erroredStep2 !== "" && erroredStep2 !== undefined) {
        goToPreviousStep()
    };
    if (currentStep === steps[6].id && erroredStep6 !== null && erroredStep6 !== "" && erroredStep6 !== undefined) {
        goToPreviousStep()
    };


    return (
        <div className="wizard wizard-4" id="kt_wizard_v4" data-wizard-state="step-first" data-wizard-clickable="true">
            <Nav currentStep={currentStep} />
            <div className="card card-custom card-shadowless rounded-top-0">
                <div className="card-body p-0">
                    <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                        <div className="col-xl-12 col-xxl-7">
                            {children}
                            <div className="d-flex justify-content-between border-top mt-5 pt-10">
                                <div className="mr-2">
                                    {canGoBack && <button type="button" onClick={goToPreviousStep} className="btn-wiz-back font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-prev">
                                    <i className="arrow left"></i>Kthehu
                                        </button>
                                    }
                                </div>
                                <div>
                                    {isLastStep && <button type="submit" className="btn btn-wiz font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-submit">
                                        {actionLabel || 'Submit'}
                                    </button>}
                                    {!isLastStep && <button type="submit" className="btn btn-wiz font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-next">
                                        {actionLabel || 'Next'}
                                    </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}


class TplPolicy extends React.Component<Props & RouteComponentProps<PathProps>, {}> {

    componentDidMount() {
        if (!this.props.nomenFetched) {
            this.props.onGetTplVehicleCategoryClassType();
            this.props.onGetVehicleMark();
            this.props.onGetDeliveryBranchLocations();
        }
    }

    handleCloseModal = () => {
        if (this.props.nextStep === "3") this.props.history.push('/');
        this.props.closeModal();
    };

    render() {
        return (
            <div className="d-flex flex-column-fluid">
                {this.props.isLoading
                    ? <Loading />
                    : <>
                        <SimpleModal show={this.props.showModal} header={this.props.header} text={this.props.description} onClose={this.handleCloseModal} onHide={() => { return; }} />
                        <div className=" container ">
                            <div className="card card-custom">
                                <div className="card-body p-0">
                                    <FormikWizard steps={steps} onSubmit={() => { }} render={FormWrapper} />
                                </div>
                            </div>
                        </div>
                    </>}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch<nomenActionTypes.NomenAction | HideModalAction>) => ({
    onGetTplVehicleCategoryClassType: () => {
        dispatch(getTplVehicleCategoryClassType());
    },
    onGetVehicleMark: () => {
        dispatch(getVehicleMark());
    },
    onGetDeliveryBranchLocations: () => {
        dispatch(getDeliveryBranchLocations());
    },
    closeModal: () => { dispatch(hideModal()); }
});

const mapStateToProps = (state: any) => ({
    showModal: state.general.showModal,
    header: state.general.header,
    description: state.general.text,
    nextStep: state.general.nextStep,
    isLoading: state.isLoading[nomenActionTypes.GET_TPL_VCCT]
        || state.isLoading[nomenActionTypes.GET_VEHICLE_MARK]
        || state.isLoading[nomenActionTypes.GET_DELIVERY_BRANCH_LOCATIONS],
    nomenFetched: state.nomen.TPLVehicleCategoryClassTypes.length > 0
        && state.nomen.VehicleMarks.length > 0
        && state.nomen.DeliveryLocations.length > 0
});

export default (withRouter(connect(mapStateToProps, mapDispatchToProps)(TplPolicy)));
