import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from "redux";
import { AppState } from '../../../redux/reducers/rootReducer';
import { PolicyGridItem } from '../../../utils';
import { policyPreview, downloadPolicy } from '../../../redux/actionCreators/generalActionCreators';
import * as actionTypes from '../../../redux/actionTypes/generalActionTypes';
import { Loading2 } from '../../Layout/Loading2';
import { useFormikContext } from 'formik';
import { getPages, getPagesCount } from '../../../_metronic/_assets/ts/Pagination';

interface Props {
	onSubmit: (values: any) => void;
	onPreview: (policyId: any) => void;
	onDownload: (policyId: any) => void;
}

const DataDisplay = (props: Props) => {
	const { values }: any = useFormikContext();
	const { setFieldValue } = useFormikContext();

	const policies = useSelector<AppState, PolicyGridItem[]>((state: any) => state.general.policies);
	const policiesCount = useSelector<AppState, number>((state: any) => state.general.policiesCount);

	const isLoadingSearch = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.SEARCH_POLICY]);
	const isLoadingExport = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.EXPORT_SEARCH_POLICY]);
	const currentPage = values.pageCount;
	const pageSize = values.pageSize;
	const pagesCount = getPagesCount(policiesCount, pageSize);
	const pages = getPages(currentPage, pagesCount, pageSize);

	const from = (pageSize * (currentPage - 1)) + 1;
	const to = pageSize * currentPage;

	const sizePerPageList = [
		{ text: "5", value: 5 },
		{ text: "10", value: 10 },
		{ text: "20", value: 20 },
		{ text: "30", value: 30 },
		{ text: "50", value: 50 },
		{ text: "100", value: 100 }
	];

	const handleFirstPage = () => {
		setFieldValue('pageCount', 1);
		props.onSubmit(values);
	}

	const handlePrevPage = () => {
		const prevPage = currentPage > 1 ? currentPage - 1 : 1;
		setFieldValue('pageCount', prevPage);
		props.onSubmit(values);
	}

	const handleNextPage = () => {
		const nextPage = currentPage < pagesCount ? currentPage + 1 : pagesCount;
		setFieldValue('pageCount', nextPage);
		props.onSubmit(values);
	}

	const handleLastPage = () => {
		setFieldValue('pageCount', pagesCount);
		props.onSubmit(values);
	}

	const handleSelectedPage = (page: number) => {
		setFieldValue('pageCount', page);
		props.onSubmit(values);
	};

	const onSizeChange = (event: any) => {
		const newSize = +event.target.value;
		setFieldValue('pageSize', newSize);
		setFieldValue('pageCount', 1);
		props.onSubmit(values);
	};

	const openPolicy = (policyId: any) => {
		props.onPreview(policyId);
	};

	const download = (policyId: any) => {
		props.onDownload(policyId);
	};

	const disabledClass = pagesCount > 1 ? "" : "datatable-pager-link-disabled";

	return (
		<>
			{isLoadingSearch || isLoadingExport ?
				<Loading2 />
				: <div className="datatable datatable-bordered 
						datatable-head-custom datatable-default 
						datatable-primary datatable-loaded" id="kt_datatable">
					<table className="datatable-table" style={{ display: 'block' }}>
						<thead className="datatable-head">
							<tr className="datatable-row" style={{ left: '0px' }}>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "30px" }}>  </span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "80px" }}>Numri i policës</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "80px" }}> Data e shitjes</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "80px" }}> Fillon Mbaron </span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "105px" }}>Kontraktuesi</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "105px" }}>I Siguruari</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "80px" }}>Targa Shasia</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "72px" }}>Statusi</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "60px" }}>Primi</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "60px" }}>Paketë</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "60px" }}>Kupon</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "60px" }}>Shiko</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "20px" }}></span>
								</th>
							</tr>
						</thead>
						<tbody className="datatable-body">
							{policies.map((policy: PolicyGridItem, index: number) => (
								<tr key={index} className="datatable-row" style={{ left: "0px" }}>

									<td aria-label={policy.policyType} className="datatable-cell">
										<span style={{ width: "30px" }}>{policy.policyType}</span>
									</td>
									<td aria-label={policy.shownum} className="datatable-cell">
										<span style={{ width: "80px" }}>{policy.shownum}</span>
									</td>
									<td aria-label={policy.datesale} className="datatable-cell">
										<span style={{ width: "80px" }}>{policy.datesale}</span>
									</td>
									<td aria-label={policy.fvalid} className="datatable-cell">
										<span style={{ width: "80px" }}>{policy.fvalid} {policy.tvalid}</span>
									</td>

									<td aria-label={policy.dog} className="datatable-cell">
										<span style={{ width: "105px" }}>{policy.dog}</span>
									</td>
									<td aria-label={policy.osi} className="datatable-cell">
										<span style={{ width: "105px" }}>{policy.osi}</span>
									</td>
									<td aria-label={policy.regnum} className="datatable-cell">
										<span style={{ width: "80px" }}>{policy.regnum} {policy.chassis}</span>
									</td>
									<td aria-label={policy.policyStatus} className="datatable-cell">
										<span style={{ width: "72px" }}>{policy.policyStatus}</span>
									</td>
									<td aria-label={policy.totalV} className="datatable-cell">
										<span style={{ width: "60px" }}>{policy.totalV} {policy.valuta}</span>
									</td>
									<td aria-label={policy.packageName} className="datatable-cell">
										<span style={{ width: "60px" }}>{policy.packageName}</span>
									</td>
									<td aria-label={policy.coupon} className="datatable-cell">
										<span style={{ width: "60px" }}>{policy.coupon}</span>
									</td>
									<td className="datatable-cell">
										<span style={{ overflow: "visible", position: "relative", width: "60px" }}>
											{policy.policyStatus === "E Realizuar" && <a onClick={() => download(policy.policyId)} className="btn btn-sm btn-clean btn-icon mr-2" title="Shiko">
												<i className="flaticon2-shelter text-muted icon-1x" />
											</a>}
										</span>
									</td>
									<td className="datatable-cell">
										<span style={{ overflow: "visible", position: "relative", width: "20px" }}>
											<a onClick={() => openPolicy(policy.policyId)} className="btn btn-sm btn-clean btn-icon mr-2" title="Shiko">
												<span className="svg-icon svg-icon-md">
													<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
														<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
															<rect x="0" y="0" width="24" height="24"></rect>
															<path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
															<rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
														</g>
													</svg>
												</span>
											</a>
										</span>
									</td>
								</tr>))}
						</tbody>
					</table >
					<div className="datatable-pager datatable-paging-loaded">
						{pagesCount < 2 && <></>}
						{pagesCount > 1 && (<>
							<ul className="datatable-pager-nav mb-5 mb-sm-0">
								<li><a title="First" onClick={() => handleFirstPage()}
									className={"datatable-pager-link datatable-pager-link-first" + disabledClass}>
									<i className="flaticon2-fast-back"></i></a></li>
								<li><a title="Previous" onClick={() => handlePrevPage()}
									className={"datatable-pager-link datatable-pager-link-prev" + disabledClass}>
									<i className="flaticon2-back"></i></a></li>
								{currentPage > 1 && (
									<li>
										<div className="datatable-pager-link datatable-pager-link-number">
											...
										</div>
									</li>
								)}
								{pages.map((p) => (
									<li key={p}><a
										title={p.toString()}
										key={p}
										onClick={() => handleSelectedPage(p)}
										className={`datatable-pager-link datatable-pager-link-number ${currentPage === p ? " datatable-pager-link-active" : ""}`}>
										{p}
									</a></li>
								))}
								{currentPage < pagesCount && (
									<li><div className="datatable-pager-link datatable-pager-link-number">
										...
									</div></li>
								)}
								<li>
									<a onClick={() => handleNextPage()}
										title="Next"
										className="datatable-pager-link datatable-pager-link-next">
										<i className="flaticon2-next"></i>
									</a>
								</li>
								<li>
									<a onClick={() => handleLastPage()}
										title="Last"
										className="datatable-pager-link datatable-pager-link-last">
										<i className="flaticon2-fast-next"></i>
									</a>
								</li>
							</ul>
						</>
						)}

						<div className="datatable-pager-info">
							<div className="dropdown bootstrap-select datatable-pager-size" style={{ width: "60px", float: 'left' }}>
								<select
									disabled={policiesCount === 0}
									className={`form-control form-control-sm font-weight-bold mr-4 border-0 bg-light ${policiesCount ===
										0 && "disabled"}`}
									onChange={onSizeChange}
									value={pageSize}
									style={{ width: "62px" }}
								>
									{sizePerPageList.map(option => {
										const isSelect = pageSize === `${option.value}`;
										return (
											<option
												key={option.text}
												value={option.value}
												className={`btn ${isSelect ? "active" : ""}`}
											>
												{option.text}
											</option>
										);
									})}
								</select>
							</div>
							<span className="datatable-pager-detail">Duke shfaqur {from} - {to} nga {policiesCount}</span>
						</div>
					</div>
				</div >
			}
		</>);
}

const mapDispatchToProps = (dispatch: Dispatch<actionTypes.PolicyPreviewAction | actionTypes.DownloadPolicyAction>) => ({
	onPreview: (policyId: any) => {
		dispatch(policyPreview(policyId));
	},
	onDownload: (policyId: any) => {
		dispatch(downloadPolicy(policyId));
	},
});

export default connect(null, mapDispatchToProps)(DataDisplay);