import { PreClaimPreview } from './../../utils/claimTypes';
import * as actions from "../actionTypes/healthClaimActionTypes";
import { HealthClaimGridItem } from '../../utils/healthPreClaimTypes';
import { Nomen } from '../../utils';

export interface HealthClaimState {
  healthClaims: HealthClaimGridItem[];
  healthClaimsCount: number;
  selectedHealthClaim: PreClaimPreview | null;
  showHealthClaimModal: boolean;
  showHealthClaimFirstLevelModal: boolean;
  showHealthClaimSecondLevelModal: boolean;
  showReadFirstLevelAppealModal: boolean;
  showReadSecondLevelAppealModal: boolean;
  healthClaimDocuments: Nomen[] | null;
}

const initialState: HealthClaimState = {
  healthClaims: [],
  healthClaimsCount: 0,
  selectedHealthClaim: null,
  showHealthClaimModal: false,
  showHealthClaimFirstLevelModal: false,
  showHealthClaimSecondLevelModal: false,
  showReadFirstLevelAppealModal: false,
  showReadSecondLevelAppealModal: false,
  healthClaimDocuments: [],
};

export default function healthClaimReducer(
  state: HealthClaimState = initialState,
  action: actions.HealthClaimAction
): HealthClaimState {
  switch (action.type) {
    case actions.SEARCH_HEALTH_CLAIM_SUCCESS:
      return {
        ...state,
        healthClaims: action.data,
        healthClaimsCount: action.count
      };
    case actions.SEARCH_HEALTH_CLAIM_RESET:
      return {
        ...state,
        healthClaims: [],
        healthClaimsCount: 0
      };
    case actions.HEALTH_CLAIM_PREVIEW_SUCCESS:
      return {
        ...state,
        selectedHealthClaim: action.data,
      };
    case actions.HEALTH_CLAIM_PREVIEW_RESET:
      return {
        ...state,
        selectedHealthClaim: null,
        showHealthClaimModal: false,
        healthClaimDocuments: null
      };
    case actions.SHOW_HEALTH_CLAIM_MODAL:
      return {
        ...state,
        showHealthClaimModal: true
      };
    case actions.HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_SUCCESS:
      return {
        ...state,
        selectedHealthClaim: action.data,
      };
    case actions.HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_RESET:
      return {
        ...state,
        selectedHealthClaim: null,
        showHealthClaimFirstLevelModal: false
      };
    case actions.SHOW_HEALTH_CLAIM_FIRST_LEVEL_MODAL:
      return {
        ...state,
        showHealthClaimFirstLevelModal: true
      };
    case actions.HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_SUCCESS:
      return {
        ...state,
        selectedHealthClaim: action.data,
      };
    case actions.HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_RESET:
      return {
        ...state,
        selectedHealthClaim: null,
        showHealthClaimSecondLevelModal: false
      };
    case actions.SHOW_HEALTH_CLAIM_SECOND_LEVEL_MODAL:
      return {
        ...state,
        showHealthClaimSecondLevelModal: true
      };
    case actions.READ_FIRST_LEVEL_APPEAL_SUCCESS:
      return {
        ...state,
        selectedHealthClaim: action.data,
      };
    case actions.READ_FIRST_LEVEL_APPEAL_RESET:
      return {
        ...state,
        selectedHealthClaim: null,
        showReadFirstLevelAppealModal: false
      };
    case actions.SHOW_READ_FIRST_LEVEL_APPEAL_MODAL:
      return {
        ...state,
        showReadFirstLevelAppealModal: true
      };
    case actions.READ_SECOND_LEVEL_APPEAL_SUCCESS:
      return {
        ...state,
        selectedHealthClaim: action.data,
      };
    case actions.READ_SECOND_LEVEL_APPEAL_RESET:
      return {
        ...state,
        selectedHealthClaim: null,
        showReadSecondLevelAppealModal: false
      };
    case actions.SHOW_READ_SECOND_LEVEL_APPEAL_MODAL:
      return {
        ...state,
        showReadSecondLevelAppealModal: true
      };
    case actions.FILE_FIRST_LEVEL_APPEAL_SUCCESS:
      return {
        ...state,
        selectedHealthClaim: null,
        showHealthClaimFirstLevelModal: false
      };
    case actions.FILE_SECOND_LEVEL_APPEAL_SUCCESS:
      return {
        ...state,
        selectedHealthClaim: null,
        showHealthClaimSecondLevelModal: false
      };
    case actions.GET_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        healthClaimDocuments: action.data
      };
    case actions.GET_DOCUMENT_LIST_RESET:
      return {
        ...state,
        healthClaimDocuments: null
      };
    default:
      return state;
  }
}
