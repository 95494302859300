import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { AppState } from '../../../../redux/reducers/rootReducer';
import * as actionTypes from '../../../../redux/actionTypes/claimActionTypes';
import { Loading2 } from '../../../Layout/Loading2';
import { getPages, getPagesCount } from '../../../../_metronic/_assets/ts/Pagination';
import { ClaimGridItem } from '../../../../utils/claimTypes';

interface Props {
	onSubmit: (values: any) => void;
}

const ClaimHistoryDataDisplay = (props: Props) => {
	const { values }: any = useFormikContext();
	const { setFieldValue } = useFormikContext();

	const claimsHistory = useSelector<AppState, ClaimGridItem[]>((state: any) => state.claim.claimsHistory);
	const claimsHistoryCount = useSelector<AppState, number>((state: any) => state.claim.claimsHistoryCount);

	const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.SEARCH_CLAIM_HISTORY]);
	const currentPage = values.pageCount;
	const pageSize = values.pageSize;
	const pagesCount = getPagesCount(claimsHistoryCount, pageSize);
	const pages = getPages(currentPage, pagesCount, pageSize);

	const from = (pageSize * (currentPage - 1)) + 1;
	const to = pageSize * currentPage;

	const sizePerPageList = [
		{ text: "5", value: 5 },
		{ text: "10", value: 10 },
		{ text: "20", value: 20 },
		{ text: "30", value: 30 },
		{ text: "50", value: 50 },
		{ text: "100", value: 100 }
	];

	const handleFirstPage = () => {
		setFieldValue('pageCount', 1);
		props.onSubmit(values);
	}

	const handlePrevPage = () => {
		const prevPage = currentPage > 1 ? currentPage - 1 : 1;
		setFieldValue('pageCount', prevPage);
		props.onSubmit(values);
	}

	const handleNextPage = () => {
		const nextPage = currentPage < pagesCount ? currentPage + 1 : pagesCount;
		setFieldValue('pageCount', nextPage);
		props.onSubmit(values);
	}

	const handleLastPage = () => {
		setFieldValue('pageCount', pagesCount);
		props.onSubmit(values);
	}

	const handleSelectedPage = (page: number) => {
		setFieldValue('pageCount', page);
		props.onSubmit(values);
	};

	const onSizeChange = (event: any) => {
		const newSize = +event.target.value;
		setFieldValue('pageSize', newSize);
		setFieldValue('pageCount', 1);
		props.onSubmit(values);
	};

	const disabledClass = pagesCount > 1 ? "" : "datatable-pager-link-disabled";

	return (
		<>
			{isLoading ?
				<Loading2 />
				: <div className="datatable datatable-bordered 
						datatable-head-custom datatable-default 
						datatable-primary datatable-loaded" id="kt_datatable">
					<table className="datatable-table" style={{ display: 'block' }}>
						<thead className="datatable-head">
							<tr className="datatable-row" style={{ left: '0px' }}>

								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "140px" }}>Numri i Dëmit</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "80px" }}>Statusi</span>
								</th>
								
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "80px" }}>Polica</span>
								</th>

								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "80px" }}> Data e aksidentit</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "85px" }}>Data e raportimit</span>
								</th>

								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "110px" }}>I Siguruari</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "110px" }}>I Siguruari - Automjeti</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "110px" }}>I Dëmtuari</span>
								</th>
								<th className="datatable-cell datatable-cell-sort">
									<span style={{ width: "110px" }}>I Dëmtuari - Automjeti</span>
								</th>
							</tr>
						</thead>
						<tbody className="datatable-body">
							{claimsHistory.map((claim: ClaimGridItem, index: number) => (
								<tr key={index} className="datatable-row" style={{ left: "0px" }}>

									<td aria-label={claim.claimStatus} className="datatable-cell">
										<span style={{ width: "140px" }}>{claim.claimNumber} {claim.nature}</span>
									</td>
									<td aria-label={claim.claimStatus} className="datatable-cell">
										<span style={{ width: "80px" }}>{claim.claimStatus}</span>
									</td>
									<td aria-label={claim.typePolicy} className="datatable-cell">
										<span style={{ width: "80px" }}>{claim.typePolicy} {claim.policyCode}</span>
									</td>

									<td aria-label={claim.dateAccident} className="datatable-cell">
										<span style={{ width: "80px" }}>{claim.dateAccident}</span>
									</td>
									<td aria-label={claim.dateReported} className="datatable-cell">
										<span style={{ width: "85px" }}>{claim.dateReported}</span>
									</td>
									<td aria-label={claim.insuredName} className="datatable-cell">
										<span style={{ width: "110px" }}>{claim.insuredId} {claim.insuredName}, {claim.insuredAddress}, {claim.insuredPhone}</span>
									</td>
									<td aria-label={claim.insuredVehicleRegnum} className="datatable-cell">
										<span style={{ width: "110px" }}>{claim.insuredVehicleRegnum} {claim.insuredVehicleChassis}</span>
									</td>
									<td aria-label={claim.damagedName} className="datatable-cell">
										<span style={{ width: "110px" }}>{claim.damagedId} {claim.damagedName}, {claim.damagedAddress}, {claim.damagedPhone}</span>
									</td>
									<td aria-label={claim.damagedVehicleRegnum} className="datatable-cell">
										<span style={{ width: "110px" }}>{claim.damagedVehicleRegnum} {claim.damagedVehicleChassis}</span>
									</td>
								
								</tr>))}
						</tbody>
					</table >
					<div className="datatable-pager datatable-paging-loaded">
						{pagesCount < 2 && <></>}
						{pagesCount > 1 && (<>
							<ul className="datatable-pager-nav mb-5 mb-sm-0">
								<li><a title="First" onClick={() => handleFirstPage()}
									className={"datatable-pager-link datatable-pager-link-first" + disabledClass}>
									<i className="flaticon2-fast-back"></i></a></li>
								<li><a title="Previous" onClick={() => handlePrevPage()}
									className={"datatable-pager-link datatable-pager-link-prev" + disabledClass}>
									<i className="flaticon2-back"></i></a></li>
								{currentPage > 1 && (
									<li>
										<div className="datatable-pager-link datatable-pager-link-number">
											...
									</div>
									</li>
								)}
								{pages.map((p) => (
									<li key={p}><a
										title={p.toString()}
										key={p}
										onClick={() => handleSelectedPage(p)}
										className={`datatable-pager-link datatable-pager-link-number ${currentPage === p ? " datatable-pager-link-active" : ""}`}>
										{p}
									</a></li>
								))}
								{currentPage < pagesCount && (
									<li><div className="datatable-pager-link datatable-pager-link-number">
										...
								</div></li>
								)}
								<li>
									<a onClick={() => handleNextPage()}
										title="Next"
										className="datatable-pager-link datatable-pager-link-next">
										<i className="flaticon2-next"></i>
									</a>
								</li>
								<li>
									<a onClick={() => handleLastPage()}
										title="Last"
										className="datatable-pager-link datatable-pager-link-last">
										<i className="flaticon2-fast-next"></i>
									</a>
								</li>
							</ul>
						</>
						)}

						<div className="datatable-pager-info">
							<div className="dropdown bootstrap-select datatable-pager-size" style={{ width: "60px", float: 'left' }}>
								<select
									disabled={claimsHistoryCount === 0}
									className={`form-control form-control-sm font-weight-bold mr-4 border-0 bg-light ${claimsHistoryCount ===
										0 && "disabled"}`}
									onChange={onSizeChange}
									value={pageSize}
									style={{ width: "62px" }}
								>
									{sizePerPageList.map(option => {
										const isSelect = pageSize === `${option.value}`;
										return (
											<option
												key={option.text}
												value={option.value}
												className={`btn ${isSelect ? "active" : ""}`}
											>
												{option.text}
											</option>
										);
									})}
								</select>
							</div>
							<span className="datatable-pager-detail">Duke shfaqur {from} - {to} nga {claimsHistoryCount}</span>
						</div>
					</div>
				</div >
			}
		</>);
}

export default ClaimHistoryDataDisplay;