import { ClaimHistoryPolicyTypes } from './nomen';
import { Nomen } from './generalTypes';
import moment from 'moment';
import { HealthPreClaimInvoice } from './healthPreClaimTypes';

export interface PreClaim {
    customerId: string;
    type_policy: Nomen;
    policy_code: string;
    date_accident: string | null;
    hour_accident: string;
    place_accident: Nomen;
    nature: Nomen;
    remark: string;
    description_accident: string;
    description_damage: string;
    policyreportnr: string;
    europeanreportnr: string;
    damagedPerson: PreClaimPerson;
    damagedVehicle: PreClaimVehicle | null;
    insuredPerson: PreClaimPerson | null;
    insuredVehicle: PreClaimVehicle | null;
    claimFiles: any[];
    objectCover: any[] | null;
}


export interface PreClaimVehicle {
    regnum: string;
    type_vehicle: Nomen;
    mark: Nomen;
    tip: string;
    chassis: string;
    prodyear: number;
    placenr: number;
    colour: string;
    capacity: number;
    power: number;
    volume: number;
    used: string;
    vehiclelicence: string;
    vehicle_price: number;
    damaged_parts: string;
}

export interface PreClaimPerson {
    tipkind: string;
    nipt: string | null;
    clientno: string;
    title: string;
    lastname: string;
    fathername: string;
    birthdate: string | null;
    email: string;
    celphone: string;
    passport: string;
    address: string;
    place: Nomen;
    drivingLicenceCat: string;
    drivingLicenceNo: string;
    driverLicenseIssued: string | null;
    remark: string;
    sex: Nomen;
    bank: Nomen | null;
    bankAccount: string;
    policyCode: string | null;
}

export interface PreClaimRegisterData {
    customerId: string;
    policyId: string;
    preClaimId: string;
}

export const preparePreClaim = (customerId: string, formValues: any): PreClaim => {
    const preClaim = {
        customerId: customerId,
        type_policy: { code: formValues.claim.type_policy, name: "" },
        policy_code: formValues.claim.policy_code,
        date_accident: moment(formValues.claim.date_accident).format('YYYY-MM-DD'),
        hour_accident: formValues.claim.hour_accident,
        place_accident: { code: formValues.claim.place_accident, name: "" },
        nature: { code: formValues.claim.nature, name: "" },
        remark: '', // ??
        description_accident: formValues.claim.description_accident,
        description_damage: formValues.claim.description_damage,
        policyreportnr: formValues.claim.policyreportnr,
        europeanreportnr: formValues.claim.europeanreportnr,
        damagedPerson: {
            tipkind: formValues.damagedPerson.tipKind,
            nipt: formValues.damagedPerson.nipt,
            clientno: formValues.damagedPerson.clientNo,
            title: formValues.damagedPerson.title,
            lastname: formValues.damagedPerson.lastName,
            fathername: formValues.damagedPerson.fatherName,
            birthdate: formValues.damagedPerson.birthDate === '' || !formValues.damagedPerson.birthDate || formValues.damagedPerson.tipKind === '2' ? null : moment(formValues.damagedPerson.birthDate).format('YYYY-MM-DD'),
            email: formValues.damagedPerson.email,
            celphone: formValues.damagedPerson.celphone,
            passport: formValues.damagedPerson.passport,
            address: formValues.damagedPerson.address,
            place: { code: formValues.damagedPerson.place, name: "" },
            drivingLicenceCat: formValues.damagedPerson.drivingLicenceCat,
            drivingLicenceNo: formValues.damagedPerson.drivingLicenceNo,
            sex: { code: formValues.damagedPerson.sex, name: "" },
            driverLicenseIssued: formValues.damagedPerson.driverLicenseIssued === '' || !formValues.damagedPerson.driverLicenseIssued ? null : moment(formValues.damagedPerson.driverLicenseIssued).format('YYYY-MM-DD'),
            remark: '',
            bank: null,
            bankAccount: '',
            policyCode: ''
        },
        damagedVehicle: {
            regnum: formValues.damagedVehicle.regnum,
            type_vehicle: { code: formValues.damagedVehicle.type_vehicle, name: "" },
            mark: { code: formValues.damagedVehicle.mark, name: "" },
            tip: formValues.damagedVehicle.tip,
            chassis: formValues.damagedVehicle.chassis,
            prodyear: parseInt(formValues.damagedVehicle.prodYear),
            placenr: parseInt(formValues.damagedVehicle.placenr),
            colour: formValues.damagedVehicle.colour,
            capacity: parseInt(formValues.damagedVehicle.capacity),
            power: parseInt(formValues.damagedVehicle.power),
            volume: parseInt(formValues.damagedVehicle.volume),
            vehicle_price: 0,
            used: formValues.damagedVehicle.used,
            vehiclelicence: formValues.damagedVehicle.vehicleLicence,
            damaged_parts: formValues.damagedVehicle.damaged_parts
        },
        insuredPerson: prepareInsuredPerson(formValues),
        insuredVehicle: prepareInsuredVehicle(formValues),
        claimFiles: formValues.uploadDocuments.claimFiles,
        objectCover: prepareCovers(formValues)
    }

    return preClaim;
}

export const prepareInsuredPerson = (formValues: any): PreClaimPerson => {
    if (formValues.claim.covercategory && formValues.claim.covercategory !== '355') {
        return {} as PreClaimPerson;
    }

    return {
        tipkind: formValues.insuredPerson.tipKind,
        nipt: formValues.insuredPerson.nipt,
        clientno: formValues.insuredPerson.clientNo,
        title: formValues.insuredPerson.title,
        lastname: formValues.insuredPerson.lastName,
        fathername: formValues.insuredPerson.fatherName,
        birthdate: formValues.insuredPerson.birthDate === '' || !formValues.insuredPerson.birthDate || formValues.insuredPerson.tipKind === '2' ? null : moment(formValues.insuredPerson.birthDate).format('YYYY-MM-DD'),
        email: formValues.insuredPerson.email,
        celphone: formValues.insuredPerson.celphone,
        passport: formValues.insuredPerson.passport,
        address: formValues.insuredPerson.address,
        place: { code: formValues.insuredPerson.place, name: "" },
        drivingLicenceCat: formValues.insuredPerson.drivingLicenceCat,
        drivingLicenceNo: formValues.insuredPerson.drivingLicenceNo,
        sex: { code: formValues.insuredPerson.sex, name: "" },
        driverLicenseIssued: formValues.insuredPerson.driverLicenseIssued === '' || !formValues.insuredPerson.driverLicenseIssued ? null : moment(formValues.insuredPerson.driverLicenseIssued).format('YYYY-MM-DD'),
        remark: '',
        bank: null,
        bankAccount: '',
        policyCode: ''
    }
}

export const prepareInsuredVehicle = (formValues: any): PreClaimVehicle => {
    if (formValues.claim.covercategory && formValues.claim.covercategory !== '355') {
        return {} as PreClaimVehicle;
    }

    return {
        regnum: formValues.insuredVehicle.regnum,
        type_vehicle: { code: formValues.insuredVehicle.type_vehicle, name: "" },
        mark: { code: formValues.insuredVehicle.mark, name: "" },
        tip: formValues.insuredVehicle.tip,
        chassis: formValues.insuredVehicle.chassis,
        prodyear: parseInt(formValues.insuredVehicle.prodYear),
        placenr: parseInt(formValues.insuredVehicle.placenr),
        colour: formValues.insuredVehicle.colour,
        capacity: parseInt(formValues.insuredVehicle.capacity),
        power: parseInt(formValues.insuredVehicle.power),
        volume: parseInt(formValues.insuredVehicle.volume),
        vehicle_price: 0,
        used: formValues.insuredVehicle.used,
        vehiclelicence: formValues.insuredVehicle.vehicleLicence,
        damaged_parts: formValues.insuredVehicle.damaged_parts
    }
}

export const prepareCovers = (formValues: any): any[] => {
    if (!formValues.claim.covercategory) {
        return []
    }

    return [{ 'coverGroupCode': formValues.claim.covercategory }]
}

export const prepareHealthOrPropertyPreClaim = (customerId: string, formValues: any): PreClaim => {
    return {
        customerId: customerId,
        type_policy: { code: formValues.claim.type_policy, name: "" },
        policy_code: formValues.claim.policy_code,
        date_accident: moment(formValues.claim.date_accident).format('YYYY-MM-DD'),
        hour_accident: '',
        place_accident: { code: formValues.claim.place_accident, name: "" },
        nature: { code: formValues.claim.nature, name: "" },
        remark: '', // ??
        description_accident: formValues.claim.description_accident,
        description_damage: formValues.claim.description_damage,
        policyreportnr: '',
        europeanreportnr: '',
        damagedPerson: {
            tipkind: formValues.damagedPerson.tipKind,
            nipt: formValues.damagedPerson.nipt,
            clientno: formValues.damagedPerson.clientNo,
            title: formValues.damagedPerson.title,
            lastname: formValues.damagedPerson.lastName,
            fathername: formValues.damagedPerson.fatherName,
            birthdate: formValues.damagedPerson.birthDate === '' || !formValues.damagedPerson.birthDate || formValues.damagedPerson.tipKind === '2' ? null : moment(formValues.damagedPerson.birthDate).format('YYYY-MM-DD'),
            email: formValues.damagedPerson.email,
            celphone: formValues.damagedPerson.celphone,
            passport: formValues.damagedPerson.passport,
            address: formValues.damagedPerson.address,
            place: { code: formValues.damagedPerson.place, name: "" },
            drivingLicenceCat: '',
            drivingLicenceNo: '',
            sex: { code: formValues.damagedPerson.sex, name: "" },
            driverLicenseIssued: null,
            remark: '',
            bank: null,
            bankAccount: '',
            policyCode: ''
        },
        damagedVehicle: null,
        insuredPerson: {
            tipkind: formValues.insuredPerson.tipKind,
            nipt: formValues.insuredPerson.nipt,
            clientno: formValues.insuredPerson.clientNo,
            title: formValues.insuredPerson.title,
            lastname: formValues.insuredPerson.lastName,
            fathername: formValues.insuredPerson.fatherName,
            birthdate: formValues.insuredPerson.birthDate === '' || !formValues.insuredPerson.birthDate || formValues.insuredPerson.tipKind === '2' ? null : moment(formValues.insuredPerson.birthDate).format('YYYY-MM-DD'),
            email: formValues.insuredPerson.email,
            celphone: formValues.insuredPerson.celphone,
            passport: formValues.insuredPerson.passport,
            address: formValues.insuredPerson.address,
            place: { code: formValues.insuredPerson.place, name: "" },
            drivingLicenceCat: '',
            drivingLicenceNo: '',
            sex: { code: formValues.insuredPerson.sex, name: "" },
            driverLicenseIssued: null,
            remark: '',
            bank: null,
            bankAccount: '',
            policyCode: ''
        },
        insuredVehicle: null,
        claimFiles: formValues.uploadDocuments.claimFiles,
        objectCover: []
    }
}

export interface PreClaimFilter {
    customerId: string;
    pageSize: number;
    pageCount: number;
    dateAccidentFrom: string | null;
    dateAccidentTo: string | null;
    dateReportedFrom: string | null;
    dateReportedTo: string | null;
    policyType: string;
    shownum: string;
    personName: string;
    vehicleRegnum: string;
}

export interface ClaimFilter {
    customerId: string;
    pageSize: number;
    pageCount: number;
    dateAccidentFrom: string | null;
    dateAccidentTo: string | null;
    policyType: string;
    roleType: string;
    shownum: string;
}

export interface PreClaimGridItem {
    preClaimId: string;
    claimStatus: string;
    preClaimNumber: string;
    typePolicy: string;
    policyCode: string;
    dateAccident: string;
    dateReported: string;
    nature: string;
    insured: string;
    insuredVehicle: string;
    damaged: string;
    damagedVehicle: string;
    beneficiary: string;
}

export interface ClaimGridItem {
    claimId: string;
    claimStatus: string;
    claimNumber: string;
    typePolicy: string;
    policyCode: string;
    dateAccident: string;
    dateReported: string;
    dateRejected: string;
    nature: string;
    descriptionAccident: string;
    reportedCountry: string;
    className: string;
    reasonName: string;
    policyReportNr: string;
    insuredName: string;
    insuredId: string;
    insuredAddress: string;
    insuredPhone: string;
    insuredPersonPolicy: string;
    insuredVehicleRegnum: string;
    insuredVehicleChassis: string;
    insuredTypeVehicle: string;
    damagedName: string;
    damagedId: string;
    damagedAddress: string;
    damagedPhone: string;
    damagedVehicleRegnum: string;
    damagedVehicleChassis: string;
    paymentAmount: string;
    invoicedAmount: string;
    approvedAmount: string;
    damagedTypeVehicle: string;
}

export interface PreClaimPreview {
    policy_code: string;
    class: string;
    nature: string;
    date_reported: string;
    date_accident: string;
    hour_accident: string;
    place_accident: string;
    description_accident: string;
    description_damage: string;
    policyreportnr: string;
    europeanreportnr: string;
    preClaimNumber: string;
    damagedPerson: PreClaimPerson;
    damagedVehicle: PreClaimVehicle;
    insuredPerson: PreClaimPerson;
    insuredVehicle: PreClaimVehicle;
    beneficiaryPerson: PreClaimPerson;
    invoices: HealthPreClaimInvoice[];
}

export const preparePreClaimFilter = (values: any, customerId: string) => {
    let filter: PreClaimFilter = {
        customerId: customerId,
        pageSize: values.pageSize,
        pageCount: values.pageCount,
        dateAccidentFrom: values.dateAccidentFrom !== '' && !!values.dateAccidentFrom ? moment(values.dateAccidentFrom).format('YYYY-MM-DD') : null,
        dateAccidentTo: values.dateAccidentTo !== '' && !!values.dateAccidentTo ? moment(values.dateAccidentTo).format('YYYY-MM-DD') : null,
        dateReportedFrom: values.dateReportedFrom !== '' && !!values.dateReportedFrom ? moment(values.dateReportedFrom).format('YYYY-MM-DD') : null,
        dateReportedTo: values.dateReportedTo !== '' && !!values.dateReportedTo ? moment(values.dateReportedTo).format('YYYY-MM-DD') : null,
        policyType: values.policyType,
        shownum: values.shownum,
        personName: values.personName,
        vehicleRegnum: values.vehicleRegnum,
    }

    return filter;
}

export const prepareClaimFilter = (values: any, customerId: string) => {
    let filter: ClaimFilter = {
        customerId: customerId,
        pageSize: values.pageSize,
        pageCount: values.pageCount,
        dateAccidentFrom: values.dateAccidentFrom !== '' && !!values.dateAccidentFrom ? moment(values.dateAccidentFrom).format('YYYY-MM-DD') : null,
        dateAccidentTo: values.dateAccidentTo !== '' && !!values.dateAccidentTo ? moment(values.dateAccidentTo).format('YYYY-MM-DD') : null, //values.dateAccidentTo,
        policyType: values.policyType,
        shownum: values.shownum,
        roleType: values.roleType
    }

    return filter;
}

export const prepareClaimSendMailData = (name: string, surname: string, identificator: string, address: string, from: any, to: any, policyType: string) => {
    const fromDate = from !== '' && !!from ? moment(from).format('DD/MM/yyyy') : "/";
    const toDate = to !== '' && !!to ? moment(to).format('DD/MM/yyyy') : "/";
    const type = ClaimHistoryPolicyTypes.find(t => t.code === policyType);
    let data: any = { name, surname, identificator, address, from: fromDate, to: toDate, type: type?.name || "/" };
    return data;
}

export interface UploadHealthClaimFile {
    claimId: string;
    customerId: string;
    healthClaimFiles: any[]
}

export const prepareClaimFile = (customerId: string, claimId: string, files: any[]): UploadHealthClaimFile => {
    const result = {
        claimId: claimId,
        customerId: customerId,
        healthClaimFiles: files
    };

    return result;
}