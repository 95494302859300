import * as React from 'react';
import { useSelector } from 'react-redux';
import InputFieldMain from '../../Custom/InputFieldMain';
import SelectField from '../../Custom/SelectField';
import { AppState } from '../../../redux/reducers/rootReducer';
import { Nomen } from '../../../utils';
import InputAreaFieldMain from '../../Custom/InputAreaFieldMain';
import { Loading } from '../../Layout/Loading';
import { PreClaimVehicle } from '../../../utils/claimTypes';
import { useFormikContext } from 'formik';
import * as claimActionTypes from '../../../redux/actionTypes/claimActionTypes';

const DamagedVehicle = () => {
    const { values }: any = useFormikContext();
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[claimActionTypes.FETCH_KASKO_DATA_FROM_POLICY]);
    const marks = useSelector<AppState, Nomen[]>((state: any) => state.nomen.VehicleMarks);
    const types = useSelector<AppState, Nomen[]>((state: any) => state.nomen.TypeVehicle);
    const vehicle = useSelector<AppState, PreClaimVehicle | null>((state: any) => state.claim.kasko?.damagedVehicle);

    return (<>
        {isLoading
            ? <Loading />
            :
            <>
                <div className="wizardTitle">I Dëmtuari - Automjeti</div>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="regnum" label="Targa" placeholder="Targa" newvalue={values?.regnum || vehicle?.regnum} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="chassis" label="Numri i shasisë" placeholder="Numri i shasisë" newvalue={values?.chassis || vehicle?.chassis} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <SelectField name="type_vehicle" label="Mjeti/Klasa" items={types} newvalue={values?.type_vehicle || (vehicle?.type_vehicle?.code ? vehicle?.type_vehicle?.code : '')} />
                        </div>
                        <div className="col-xl-6">
                            <SelectField name="mark" label="Prodhuesi" items={marks} newvalue={values?.mark || (vehicle?.mark?.code ? vehicle?.mark?.code : '')} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="tip" label="Modeli" placeholder="Modeli" newvalue={values?.tip || vehicle?.tip} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="power" label="Fuqia(kw) " placeholder="Fuqia(kw)" newvalue={values?.power || vehicle?.power} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="prodyear" label="Viti i prodhimit" placeholder="Viti i prodhimit" newvalue={values?.prodyear || vehicle?.prodyear} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="placenr" label="Nr. I ulëseve" placeholder="Nr. I ulëseve" newvalue={values?.placenr || vehicle?.placenr} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="capacity" label="Pesha e automjetit" placeholder="Pesha e automjetit" newvalue={values?.capacity || vehicle?.capacity} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="colour" label="Ngjyra e automjetit" placeholder="Ngjyra e automjetit" newvalue={values?.colour || vehicle?.colour} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="volume" label="Kapaciteti (cm3)" placeholder="Kapaciteti (cm3)" newvalue={values?.volume || vehicle?.volume} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="vehiclelicence" label="Leje qarkullimi" placeholder="Leje qarkullimi" newvalue={values?.vehiclelicence || vehicle?.vehiclelicence} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                        </div>
                        <div className="col-xl-6">
                            <InputAreaFieldMain type="text" name="damaged_parts" label="Mjeti Dëmtime" placeholder="Mjeti Dëmtime" newvalue={values?.damaged_parts || vehicle?.damaged_parts} />
                        </div>
                    </div>
                </div>
            </>}
    </>
    );
}

export default DamagedVehicle;
