import React, { Dispatch, useEffect } from "react";
import { getPaymentResponse, hideModal } from "../../redux/actionCreators/generalActionCreators";
import { User } from "../../redux/types";
import { BookingData } from "../../utils/policyTypes";
import SimpleModal from "../General/SimpleModal";
import * as actionTypes from '../../redux/actionTypes/generalActionTypes';
import { connect } from "react-redux";
import { PaymentError } from "../../utils";
import { useHistory } from "react-router";

interface Props {
    getResponse(bankOrderId: string): void;
    closeModal(): void;
    showModal: boolean;
    description: string;
    header: string;
    nextStep: string;
    isLoading: boolean;
    dataToConfirm: BookingData | null,
    user: User | null,
    paymentErrorReceived: boolean,
    paymentError: string,
    erroredGet: string
}

const FailedPayment = (props: Props) => {
    let history = useHistory();

    useEffect(() => {
        if (props.dataToConfirm === null) {
            history.push('/');
        }

        if (props.dataToConfirm !== null) {
            props.getResponse(props.dataToConfirm.bankOrderId);
        }
    }, []);

    const handleCloseModal = () => {
        props.closeModal();
    };

    // take policy id and send to api to get error for payment
    return (
        <div>
            <SimpleModal show={props.showModal} header={props.header} text={props.description} onClose={handleCloseModal} onHide={() => { return; }} />

            <div className="card card-custom overflow-hidden">
                <div className="card-body p-0">
                    <div className="row justify-content-center bg-danger bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0">
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                                <h1 className="display-4 text-white font-weight-boldest mb-10">
                                    Deshtim
                                </h1>
                            </div>
                            <div className="border-bottom w-100 opacity-20"></div>
                            {props.isLoading && <>
                                <div className="d-flex align-items-center">
                                    <div className="display-4 text-white">Getting the error...</div>
                                    <div className="spinner spinner-white spinner-lg mr-10"></div>
                                </div></>
                            }
                            {!props.isLoading && props.paymentErrorReceived && props.paymentError !== "" &&
                                <>
                                    <div className="d-flex align-items-center">
                                        <div className="display-4 text-white">{PaymentError[props.paymentError]}
                                            <br />  <br />  <br />
                                            Kontakte   <br />
                                            info@uniqa-ks.com         <br />
                                            +381 38 240 241           <br />
                                        </div>
                                    </div>
                                </>
                            }
                            {!props.isLoading && props.paymentErrorReceived && props.paymentError === "" &&
                                <>
                                    <div className="d-flex align-items-center">
                                        <div className="display-4 text-white">
                                            Unknown error via payment. Please contact administrator for more info!

                                            <br />  <br />  <br />
                                            Kontakte   <br />
                                            info@uniqa-ks.com         <br />
                                            +381 38 240 241           <br />
                                        </div>
                                    </div>
                                </>
                            }
                            {!props.isLoading && !props.paymentErrorReceived && props.paymentError === "" && props.erroredGet !== "" &&
                                <>
                                    <div className="d-flex align-items-center">
                                        <div className="display-4 text-white">
                                            {props.erroredGet}
                                            <br />  <br />  <br />
                                            Kontakte   <br />
                                            info@uniqa-ks.com         <br />
                                            +381 38 240 241           <br />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<actionTypes.GetPaymentResponseAction | actionTypes.HideModalAction>) => ({
    getResponse: (bankOrderId: string) => {
        dispatch(getPaymentResponse(bankOrderId));
    },
    closeModal: () => { dispatch(hideModal()); },
});

const mapStateToProps = (state: any) => ({
    showModal: state.general.showModal,
    header: state.general.header,
    description: state.general.text,
    nextStep: state.general.nextStep,
    isLoading: state.isLoading[actionTypes.GET_PAYMENT_RESPONSE],
    erroredGet: state.error[actionTypes.GET_PAYMENT_RESPONSE],
    user: state.auth.user,
    dataToConfirm: state.policy.bookingData,
    paymentErrorReceived: state.general.paymentErrorReceived,
    paymentError: state.general.paymentError
});

export default connect(mapStateToProps, mapDispatchToProps)(FailedPayment);