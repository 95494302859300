import { Nomen } from './../../utils/generalTypes';
import * as actions from "../actionTypes/nomenActionTypes";

export function getTplVehicleCategoryClassType(
): actions.GetTplVcctAction {
    return {
        type: actions.GET_TPL_VCCT
    };
}

export function getTplVehicleCategoryClassTypeRequest(
): actions.GetTplVcctRequestAction {
    return {
        type: actions.GET_TPL_VCCT_REQUEST
    };
}

export function getTplVehicleCategoryClassTypeSuccess(
    values: Nomen[]
): actions.GetTplVcctSuccessAction {
    return {
        type: actions.GET_TPL_VCCT_SUCCESS,
        data: values
    };
}

export function getTplVehicleCategoryClassTypeFailure(
    error: Error | string
): actions.GetTplVcctFailureAction {
    return {
        type: actions.GET_TPL_VCCT_FAILURE,
        error
    };
}

export function getGcVehicleCategoryClassType(
): actions.GetGcVcctAction {
    return {
        type: actions.GET_GC_VCCT
    };
}

export function getGcVehicleCategoryClassTypeRequest(
): actions.GetGcVcctRequestAction {
    return {
        type: actions.GET_GC_VCCT_REQUEST
    };
}

export function getGcVehicleCategoryClassTypeSuccess(
    values: Nomen[]
): actions.GetGcVcctSuccessAction {
    return {
        type: actions.GET_GC_VCCT_SUCCESS,
        data: values
    };
}

export function getGcVehicleCategoryClassTypeFailure(
    error: Error | string
): actions.GetGcVcctFailureAction {
    return {
        type: actions.GET_GC_VCCT_FAILURE,
        error
    };
}

export function getBVehicleCategoryClassType(
): actions.GetBVcctAction {
    return {
        type: actions.GET_B_VCCT
    };
}

export function getBVehicleCategoryClassTypeRequest(
): actions.GetBVcctRequestAction {
    return {
        type: actions.GET_B_VCCT_REQUEST
    };
}

export function getBVehicleCategoryClassTypeSuccess(
    values: Nomen[]
): actions.GetBVcctSuccessAction {
    return {
        type: actions.GET_B_VCCT_SUCCESS,
        data: values
    };
}

export function getBVehicleCategoryClassTypeFailure(
    error: Error | string
): actions.GetBVcctFailureAction {
    return {
        type: actions.GET_B_VCCT_FAILURE,
        error
    };
}

export function getVehicleMark(
): actions.GetVehicleMarkAction {
    return {
        type: actions.GET_VEHICLE_MARK
    };
}

export function getVehicleMarkRequest(
): actions.GetVehicleMarkRequestAction {
    return {
        type: actions.GET_VEHICLE_MARK_REQUEST
    };
}

export function getVehicleMarkSuccess(
    values: Nomen[]
): actions.GetVehicleMarkSuccessAction {
    return {
        type: actions.GET_VEHICLE_MARK_SUCCESS,
        data: values
    };
}

export function getVehicleMarkFailure(
    error: Error | string
): actions.GetVehicleMarkFailureAction {
    return {
        type: actions.GET_VEHICLE_MARK_FAILURE,
        error
    };
}

export function getThZone(
): actions.GetThZoneAction {
    return {
        type: actions.GET_TH_ZONE
    };
}

export function getThZoneRequest(
): actions.GetThZoneRequestAction {
    return {
        type: actions.GET_TH_ZONE_REQUEST
    };
}

export function getThZoneSuccess(
    values: Nomen[]
): actions.GetThZoneSuccessAction {
    return {
        type: actions.GET_TH_ZONE_SUCCESS,
        data: values
    };
}

export function getThZoneFailure(
    error: Error | string
): actions.GetThZoneFailureAction {
    return {
        type: actions.GET_TH_ZONE_FAILURE,
        error
    };
}

export function getGcPeriod(
): actions.GetGcPeriodAction {
    return {
        type: actions.GET_GC_PERIOD
    };
}

export function getGcPeriodRequest(
): actions.GetGcPeriodRequestAction {
    return {
        type: actions.GET_GC_PERIOD_REQUEST
    };
}

export function getGcPeriodSuccess(
    values: Nomen[]
): actions.GetGcPeriodSuccessAction {
    return {
        type: actions.GET_GC_PERIOD_SUCCESS,
        data: values
    };
}

export function getGcPeriodFailure(
    error: Error | string
): actions.GetGcPeriodFailureAction {
    return {
        type: actions.GET_GC_PERIOD_FAILURE,
        error
    };
}

export function getBPeriod(
): actions.GetBPeriodAction {
    return {
        type: actions.GET_B_PERIOD
    };
}

export function getBPeriodRequest(
): actions.GetBPeriodRequestAction {
    return {
        type: actions.GET_B_PERIOD_REQUEST
    };
}

export function getBPeriodSuccess(
    values: Nomen[]
): actions.GetBPeriodSuccessAction {
    return {
        type: actions.GET_B_PERIOD_SUCCESS,
        data: values
    };
}

export function getBPeriodFailure(
    error: Error | string
): actions.GetBPeriodFailureAction {
    return {
        type: actions.GET_B_PERIOD_FAILURE,
        error
    };
}

export function getPolicyStatus(
): actions.GetPolicyStatusAction {
    return {
        type: actions.GET_POLICY_STATUS
    };
}

export function getPolicyStatusRequest(
): actions.GetPolicyStatusRequestAction {
    return {
        type: actions.GET_POLICY_STATUS_REQUEST
    };
}

export function getPolicyStatusSuccess(
    values: Nomen[]
): actions.GetPolicyStatusSuccessAction {
    return {
        type: actions.GET_POLICY_STATUS_SUCCESS,
        data: values
    };
}

export function getPolicyStatusFailure(
    error: Error | string
): actions.GetPolicyStatusFailureAction {
    return {
        type: actions.GET_POLICY_STATUS_FAILURE,
        error
    };
}

export function getDeliveryBranchLocations(
): actions.GetDeliveryBranchLocationsAction {
    return {
        type: actions.GET_DELIVERY_BRANCH_LOCATIONS
    };
}

export function getDeliveryBranchLocationsRequest(
): actions.GetDeliveryBranchLocationsRequestAction {
    return {
        type: actions.GET_DELIVERY_BRANCH_LOCATIONS_REQUEST
    };
}

export function getDeliveryBranchLocationsSuccess(
    values: Nomen[]
): actions.GetDeliveryBranchLocationsSuccessAction {
    return {
        type: actions.GET_DELIVERY_BRANCH_LOCATIONS_SUCCESS,
        data: values
    };
}

export function getDeliveryBranchLocationsFailure(
    error: Error | string
): actions.GetDeliveryBranchLocationsFailureAction {
    return {
        type: actions.GET_DELIVERY_BRANCH_LOCATIONS_FAILURE,
        error
    };
}

export function getPlaces(
): actions.GetPlacesAction {
    return {
        type: actions.GET_PLACES
    };
}

export function getPlacesRequest(
): actions.GetPlacesRequestAction {
    return {
        type: actions.GET_PLACES_REQUEST
    };
}

export function getPlacesSuccess(
    values: Nomen[]
): actions.GetPlacesSuccessAction {
    return {
        type: actions.GET_PLACES_SUCCESS,
        data: values
    };
}

export function getPlacesFailure(
    error: Error | string
): actions.GetPlacesFailureAction {
    return {
        type: actions.GET_PLACES_FAILURE,
        error
    };
}

export function getNatures(
): actions.GetNaturesAction {
    return {
        type: actions.GET_NATURES
    };
}

export function getNaturesRequest(
): actions.GetNaturesRequestAction {
    return {
        type: actions.GET_NATURES_REQUEST
    };
}

export function getNaturesSuccess(
    values: Nomen[]
): actions.GetNaturesSuccessAction {
    return {
        type: actions.GET_NATURES_SUCCESS,
        data: values
    };
}

export function getNaturesFailure(
    error: Error | string
): actions.GetNaturesFailureAction {
    return {
        type: actions.GET_NATURES_FAILURE,
        error
    };
}

export function getTypeVehicle(
): actions.GetTypeVehicleAction {
    return {
        type: actions.GET_TYPE_VEHICLE
    };
}

export function getTypeVehicleRequest(
): actions.GetTypeVehicleRequestAction {
    return {
        type: actions.GET_TYPE_VEHICLE_REQUEST
    };
}

export function getTypeVehicleSuccess(
    values: Nomen[]
): actions.GetTypeVehicleSuccessAction {
    return {
        type: actions.GET_TYPE_VEHICLE_SUCCESS,
        data: values
    };
}

export function getTypeVehicleFailure(
    error: Error | string
): actions.GetTypeVehicleFailureAction {
    return {
        type: actions.GET_TYPE_VEHICLE_FAILURE,
        error
    };
}

export function resetNomen(
): actions.ResetNomenAction {
    return {
        type: actions.RESET_NOMEN,
    };
}

export function getHealthNatures(
): actions.GetHealthNaturesAction {
    return {
        type: actions.GET_HEALTH_NATURES
    };
}

export function getHealthNaturesRequest(
): actions.GetHealthNaturesRequestAction {
    return {
        type: actions.GET_HEALTH_NATURES_REQUEST
    };
}

export function getHealthNaturesSuccess(
    values: Nomen[]
): actions.GetHealthNaturesSuccessAction {
    return {
        type: actions.GET_HEALTH_NATURES_SUCCESS,
        data: values
    };
}

export function getHealthNaturesFailure(
    error: Error | string
): actions.GetHealthNaturesFailureAction {
    return {
        type: actions.GET_HEALTH_NATURES_FAILURE,
        error
    };
}

export function getPropertyNatures(
): actions.GetPropertyNaturesAction {
    return {
        type: actions.GET_PROPERTY_NATURES
    };
}

export function getPropertyNaturesRequest(
): actions.GetPropertyNaturesRequestAction {
    return {
        type: actions.GET_PROPERTY_NATURES_REQUEST
    };
}

export function getPropertyNaturesSuccess(
    values: Nomen[]
): actions.GetPropertyNaturesSuccessAction {
    return {
        type: actions.GET_PROPERTY_NATURES_SUCCESS,
        data: values
    };
}

export function getPropertyNaturesFailure(
    error: Error | string
): actions.GetPropertyNaturesFailureAction {
    return {
        type: actions.GET_PROPERTY_NATURES_FAILURE,
        error
    };
}

export function getBanks(
): actions.GetBanksAction {
    return {
        type: actions.GET_BANKS
    };
}

export function getBanksRequest(
): actions.GetBanksRequestAction {
    return {
        type: actions.GET_BANKS_REQUEST
    };
}

export function getBanksSuccess(
    values: Nomen[]
): actions.GetBanksSuccessAction {
    return {
        type: actions.GET_BANKS_SUCCESS,
        data: values
    };
}

export function getBanksFailure(
    error: Error | string
): actions.GetBanksFailureAction {
    return {
        type: actions.GET_BANKS_FAILURE,
        error
    };
}

export function getBanksLife(
    ): actions.GetBanksLifeAction {
        return {
            type: actions.GET_BANKS_LIFE
        };
    }
    
    export function getBanksLifeRequest(
    ): actions.GetBanksLifeRequestAction {
        return {
            type: actions.GET_BANKS_LIFE_REQUEST
        };
    }
    
    export function getBanksLifeSuccess(
        values: Nomen[]
    ): actions.GetBanksLifeSuccessAction {
        return {
            type: actions.GET_BANKS_LIFE_SUCCESS,
            data: values
        };
    }
    
    export function getBanksLifeFailure(
        error: Error | string
    ): actions.GetBanksLifeFailureAction {
        return {
            type: actions.GET_BANKS_LIFE_FAILURE,
            error
        };
    }

export function getCountries(
    zone: string | null
): actions.GetCountriesAction {
    return {
        type: actions.GET_COUNTRIES,
        zone
    };
}

export function getCountriesRequest(
): actions.GetCountriesRequestAction {
    return {
        type: actions.GET_COUNTRIES_REQUEST
    };
}

export function getCountriesSuccess(
    values: Nomen[]
): actions.GetCountriesSuccessAction {
    return {
        type: actions.GET_COUNTRIES_SUCCESS,
        data: values
    };
}

export function getCountriesFailure(
    error: Error | string
): actions.GetCountriesFailureAction {
    return {
        type: actions.GET_COUNTRIES_FAILURE,
        error
    };
}


export function getCoverCategoriesForPolicy(
    policy: string,
    invoiceDate: string
): actions.GetCoverCategoriesForPolicyAction {
    return {
        type: actions.GET_COVER_CATEGORIES_FOR_POLICY,
        policy,
        invoiceDate
    };
}

export function getCoverCategoriesForPolicyRequest(
): actions.GetCoverCategoriesForPolicyRequestAction {
    return {
        type: actions.GET_COVER_CATEGORIES_FOR_POLICY_REQUEST
    };
}

export function getCoverCategoriesForPolicySuccess(
    values: Nomen[]
): actions.GetCoverCategoriesForPolicySuccessAction {
    return {
        type: actions.GET_COVER_CATEGORIES_FOR_POLICY_SUCCESS,
        data: values
    };
}

export function getCoverCategoriesForPolicyFailure(
    error: Error | string
): actions.GetCoverCategoriesForPolicyFailureAction {
    return {
        type: actions.GET_COVER_CATEGORIES_FOR_POLICY_FAILURE,
        error
    };
}

export function getCoverGroupsForPolicy(
    policy: string,
    invoiceDate: string,
    coverGroupType: string
): actions.GetCoverGroupsForPolicyAction {
    return {
        type: actions.GET_COVER_GROUPS_FOR_POLICY,
        policy,
        invoiceDate,
        coverGroupType
    };
}

export function getCoverGroupsForPolicyRequest(
): actions.GetCoverGroupsForPolicyRequestAction {
    return {
        type: actions.GET_COVER_GROUPS_FOR_POLICY_REQUEST
    };
}

export function getCoverGroupsForPolicySuccess(
    values: Nomen[]
): actions.GetCoverGroupsForPolicySuccessAction {
    return {
        type: actions.GET_COVER_GROUPS_FOR_POLICY_SUCCESS,
        data: values
    };
}

export function getCoverGroupsForPolicyFailure(
    error: Error | string
): actions.GetCoverGroupsForPolicyFailureAction {
    return {
        type: actions.GET_COVER_GROUPS_FOR_POLICY_FAILURE,
        error
    };
}

export function getCoversForPolicy(
    policy: string,
    invoiceDate: string,
    coverGroupType: string,
    coverGroup: string
): actions.GetCoversForPolicyAction {
    return {
        type: actions.GET_COVERS_FOR_POLICY,
        policy,
        invoiceDate,
        coverGroupType,
        coverGroup
    };
}

export function getCoversForPolicyRequest(
): actions.GetCoversForPolicyRequestAction {
    return {
        type: actions.GET_COVERS_FOR_POLICY_REQUEST
    };
}

export function getCoversForPolicySuccess(
    values: Nomen[]
): actions.GetCoversForPolicySuccessAction {
    return {
        type: actions.GET_COVERS_FOR_POLICY_SUCCESS,
        data: values
    };
}

export function getCoversForPolicyFailure(
    error: Error | string
): actions.GetCoversForPolicyFailureAction {
    return {
        type: actions.GET_COVERS_FOR_POLICY_FAILURE,
        error
    };
}