import { Nomen } from './../../utils/generalTypes';
import ApiClient from '../../infrastructure/ApiClient';

export async function getNomen(
  action: string
): Promise<Nomen[]> {
  return await ApiClient.get('/api/nomen/' + action, {});
}

export async function getNomenWithData(
  action: string,
  data: any
): Promise<Nomen[]> {
  return await ApiClient.get('/api/nomen/' + action, data);
}

export async function getCountries(
  zone: string,
): Promise<string> {
  return await ApiClient.get('/api/nomen/getCountries', { zone });
}

export async function getCoverCategoriesForPolicy(
  policy: string,
  invoiceDate: string
): Promise<string> {
  return await ApiClient.get('/api/nomen/getCoverCategoriesForPolicy', { policy, invoiceDate });
}

export async function getCoverGroupsForPolicy(
  policy: string,
  invoiceDate: string,
  coverGroupType: string
): Promise<string> {
  return await ApiClient.get('/api/nomen/getCoverGroupsForPolicy', { policy, invoiceDate, coverGroupType });
}

export async function getCoversForPolicy(
  policy: string,
  invoiceDate: string,
  coverGroupType: string,
  coverGroup: string
): Promise<string> {
  return await ApiClient.get('/api/nomen/GetCoversForPolicy', { policy, invoiceDate, coverGroupType, coverGroup });
}