import { FormikWizardStepType } from '../../../utils/wizardTypes';
import { object, string, mixed, StringSchema, number, date } from 'yup';
import store from '../../../redux/configureStore';
import * as actions from '../../../redux/actionCreators/claimActionCreators';

import PropertyPreClaimMain from './PropertyPreClaimMain';
import DamagedPerson from './DamagedPerson';
import InsuredPerson from './InsuredPerson';
import UploadDocuments from './UploadDocuments';

import moment from 'moment';

export default [
    {
        id: 'claim',
        component: PropertyPreClaimMain,
        name: 'Detajet e Dëmtimit / Shërbimit',
        description: '',
        initialValues: {
            type_policy: '',
            policy_code: '',
            date_accident: '',
            nature: '',
            description_accident: '',
            description_damage: '',
            place_accident:''
        },
        validationSchema: object().shape({
            type_policy: string().required('E domosdoshme'),
            policy_code: string().required('E domosdoshme'),
            date_accident: date().required('E domosdoshme').typeError('E domosdoshme')
                .test(
                    'validDateAccident',
                    'Date accident cannot be after today',
                    function (this: any, value: any) {
                        return moment(value, 'dd/MM/yyyy').isBefore(moment());
                    }),
            nature: string().required('E domosdoshme'),
            description_accident: string().required('E domosdoshme'),
            description_damage: string().required('E domosdoshme'),
            place_accident: string().required('E domosdoshme')
        }),
        actionLabel: 'Vazhdo'
    },
    {
        id: 'damagedPerson',
        component: DamagedPerson,
        name: 'I Dëmtuari / Marrësi i Shërbimit',
        description: '',
        initialValues: {
            tipKind: '3',
            nipt: '',
            clientNo: '',
            title: '',
            lastName: '',
            birthDate: '',
            email: '',
            celphone: '',
            address: '',
            place: '',
            sex: ''
        },
        validationSchema: object().shape({
            tipKind: string().required('E domosdoshme'),
            clientNo: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme').max(16, "Maximum 16 symbols") : schema.notRequired();
            }),
            nipt: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '2' ? schema.required('E domosdoshme').max(16, "Maximum 16 symbols") : schema.notRequired();
            }),
            title: string()
                .min(3, "Minimumi 3 simbole")
                .max(50, "Maksimumi 50 simbole")
                .required("E domosdoshme"),
            lastName: string()
                .when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                    return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
                }),
            birthDate: mixed()
                .test(
                    'validBirthDate',
                    'Data e lindjes nuk mund te jete pas dates se sotshme',
                    function (this: any, value: any) {
                        if (moment(value, 'dd/MM/yyyy').isValid())
                            return moment(value, 'dd/MM/yyyy').isBefore(moment());
                        return true;
                    }),
            email: string().email().required('E domosdoshme'),
            celphone: string().required('E domosdoshme'),
            address: string(),
            place: string(),
            sex: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
            })
        }),
        actionLabel: 'Vazhdo'
    },
    {
        id: 'insuredPerson',
        component: InsuredPerson,
        name: 'I Siguruari / Shkaktari',
        description: '',
        initialValues: {
            tipKind: '3',
            nipt: '',
            clientNo: '',
            title: '',
            lastName: '',
            birthDate: '',
            email: '',
            celphone: '',
            address: '',
            place: '',
            sex: ''
        },
        validationSchema: object().shape({
            tipKind: string().required('E domosdoshme'),
            clientNo: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme').max(16, "Maximum 16 symbols") : schema.notRequired();
            }),
            nipt: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '2' ? schema.required('E domosdoshme').max(16, "Maximum 16 symbols") : schema.notRequired();
            }),
            title: string()
                .min(3, "Minimumi 3 simbole")
                .max(50, "Maksimumi 50 simbole")
                .required("E domosdoshme"),
            lastName: string()
                .when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                    return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
                }),
            birthDate: mixed()
                .test(
                    'validBirthDate',
                    'Data e lindjes nuk mund te jete pas dates se sotshme',
                    function (this: any, value: any) {
                        if (moment(value, 'dd/MM/yyyy').isValid())
                            return moment(value, 'dd/MM/yyyy').isBefore(moment());
                        return true;
                    }),
            email: string().email(),
            celphone: string().required('E domosdoshme'),
            address: string(),
            place: string(),
            sex: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
            })
        }),
        actionLabel: 'Vazhdo'
    },
    {
        id: 'uploadDocuments',
        component: UploadDocuments,
        name: 'Dokumente',
        description: '',
        initialValues: {
            claimFiles: []
        },
        validationSchema: object().shape({
        }),
        actionLabel: 'Regjistro'
    }
] as FormikWizardStepType[];
