import React from 'react';

type Props = {
    result: (text: string) => void;
}

type State = {
    height: number,
    width: number,
    textColor: boolean,
    fontFamily: string,
    fontSize: string,
    paddingLeft: number,
    paddingTop: number,
    lenght: number,
    background: string,
}

class CaptchaGenerator extends React.Component<Props, State> {

    private text: any[] = [];
    private originText: any[];
    private possible: string;

    constructor(props: Props) {
        super(props)
        this.state = {
            height: 100,
            width: 100,
            textColor: false,
            fontFamily: 'Verdana',
            fontSize: '30',
            paddingLeft: 20,
            paddingTop: 60,
            lenght: 5,
            background: 'none'
        };
        this.text = [];
        this.originText = [];
        this.possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    }

    componentWillMount() {
        this.setData();
    }

    setData = () => {
        this.setState({
            height: 85,
            width: 110,
            textColor: false,
            fontFamily: 'Verdana',
            fontSize: '30',
            paddingLeft: 30,
            paddingTop: 60,
            lenght: 5,
            background: 'none',
        })

        this.text = []
        this.originText = []
        this.possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < this.state.lenght; i++) {
            let char = this.possible.charAt(Math.floor(Math.random() * this.possible.length))
            this.text.push(
                `<text 
                    font-family="${this.state.fontFamily}" 
                    font-size="${this.state.fontSize}" 
                    x="${this.state.paddingLeft * i}" 
                    y="${this.state.paddingTop}"
                    fill="${"#" + ((1 << 24) * Math.random() | 0).toString(16)}"
                    transform="rotate(${Math.random() * (5 - 0) + 0})"
                >${char}</text>`
            )
            this.originText.push(
                char
            )
        }
        this.props.result(this.originText.join(''));
    }

    render() {

        return (
            <img
                style={{ background: this.state.background }}
                src={
                    'data:image/svg+xml;base64,' +
                    btoa('<svg ' +
                        'xmlns="http://www.w3.org/2000/svg" ' +
                        'height="' + this.state.height + '" ' +
                        'width="' + this.state.width + '">' +
                        this.text.join() +
                        '</svg>')
                }
                alt="" />
        );
    }
}

export default CaptchaGenerator;