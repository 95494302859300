import React, { FunctionComponent } from 'react';
import { useField, useFormikContext } from 'formik';
import ReactInputMask from 'react-input-mask';

const getFieldCSSClasses = (touched: boolean, error: string | undefined) => {
    const classes = ["form-control"];
    if (touched && error) {
        classes.push("is-invalid");
    }

    if (touched && !error) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

interface Props {
    name: string;
    type: string;
    label: string;
}

const TimeInput: FunctionComponent<Props> = (props) => {
    const [
        field,
        { error, touched }
    ] = useField({
        name: props.name,
        type: props.type
    });

    const {
        setFieldValue,
    } = useFormikContext();

    const handleInput = ({ target: { value } }: any) => { setFieldValue(props.name, value); }

    return (
        <div className="form-group">
            <label>{props.label}</label>
            <ReactInputMask mask={"99:99"} {...field} {...props} value={field.value} alwaysShowMask onChange={handleInput} className={"form-control form-control-solid form-control-lg" + getFieldCSSClasses(touched, error)} />
            {touched && error !== "" ? (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{error}</div>
                </div>
            ) : null}
        </div>
    );
};

export default TimeInput;