import { HealthClaimAppeal, HealthPreClaim } from "../../utils/healthPreClaimTypes";
import { HealthClaimFilter, HealthClaimGridItem, HealthClaimPreview } from "../../utils/healthPreClaimTypes";
import { PreClaimPerson, PreClaimRegisterData, UploadHealthClaimFile } from "../../utils/claimTypes";
import * as actions from "../actionTypes/healthPreClaimActionTypes";
import * as claimActions from "../actionTypes/healthClaimActionTypes";
import { Nomen } from "../../utils";

export function checkPolicy(
  policyCode: string,
): actions.CheckPolicyAction {
  return {
    type: actions.CHECK_POLICY,
    policyCode
  };
}

export function checkPolicyRequest(
): actions.CheckPolicyRequestAction {
  return {
    type: actions.CHECK_POLICY_REQUEST
  };
}

export function checkPolicySuccess(
): actions.CheckPolicySuccessAction {
  return {
    type: actions.CHECK_POLICY_SUCCESS
  };
}

export function checkPolicyFailure(
  error: Error | string
): actions.CheckPolicyFailureAction {
  return {
    type: actions.CHECK_POLICY_FAILURE,
    error
  };
}

export function registerHealthPreClaim(
  healthPreClaim: HealthPreClaim
): actions.RegisterHealthPreClaimAction {
  return {
    type: actions.REGISTER_HEALTH_PRECLAIM,
    healthPreClaim
  };
}

export function registerHealthPreClaimRequest(
): actions.RegisterHealthPreClaimRequestAction {
  return {
    type: actions.REGISTER_HEALTH_PRECLAIM_REQUEST
  };
}

export function registerHealthPreClaimSuccess(
  values: PreClaimRegisterData
): actions.RegisterHealthPreClaimSuccessAction {
  return {
    type: actions.REGISTER_HEALTH_PRECLAIM_SUCCESS,
    data: values
  };
}

export function registerHealthPreClaimFailure(
  error: Error | string
): actions.RegisterHealthPreClaimFailureAction {
  return {
    type: actions.REGISTER_HEALTH_PRECLAIM_FAILURE,
    error
  };
}

export function searchHealthClaim(
  filter: HealthClaimFilter
): claimActions.SearchHealthClaimAction {
  return {
    type: claimActions.SEARCH_HEALTH_CLAIM,
    filter
  };
}

export function searchHealthClaimRequest(
): claimActions.SearchHealthClaimRequestAction {
  return {
    type: claimActions.SEARCH_HEALTH_CLAIM_REQUEST
  };
}

export function searchHealthClaimReset(
): claimActions.SearchHealthClaimResetAction {
  return {
    type: claimActions.SEARCH_HEALTH_CLAIM_RESET
  };
}

export function searchHealthClaimSuccess(
  items: HealthClaimGridItem[],
  count: number
): claimActions.SearchHealthClaimSuccessAction {
  return {
    type: claimActions.SEARCH_HEALTH_CLAIM_SUCCESS,
    data: items,
    count
  };
}

export function searchHealthClaimFailure(
  error: Error | string
): claimActions.SearchHealthClaimFailureAction {
  return {
    type: claimActions.SEARCH_HEALTH_CLAIM_FAILURE,
    error
  };
}

export function healthClaimPreview(
  code: string,
  fetchDocuments: boolean
): claimActions.HealthClaimPreviewAction {
  return {
    type: claimActions.HEALTH_CLAIM_PREVIEW,
    code,
    fetchDocuments
  };
}

export function healthClaimPreviewRequest(
): claimActions.HealthClaimPreviewRequestAction {
  return {
    type: claimActions.HEALTH_CLAIM_PREVIEW_REQUEST
  };
}

export function healthClaimPreviewSuccess(
  healthClaim: HealthClaimPreview
): claimActions.HealthClaimPreviewSuccessAction {
  return {
    type: claimActions.HEALTH_CLAIM_PREVIEW_SUCCESS,
    data: healthClaim
  };
}

export function healthClaimPreviewFailure(
  error: Error | string
): claimActions.HealthClaimPreviewFailureAction {
  return {
    type: claimActions.HEALTH_CLAIM_PREVIEW_FAILURE,
    error
  };
}

export function healthClaimPreviewReset(
): claimActions.HealthClaimPreviewResetAction {
  return {
    type: claimActions.HEALTH_CLAIM_PREVIEW_RESET
  };
}

export function healthClaimPreviewFirstLevel(
  code: string
): claimActions.HealthClaimPreviewFirstLevelAction {
  return {
    type: claimActions.HEALTH_CLAIM_PREVIEW_FIRST_LEVEL,
    code
  };
}

export function healthClaimPreviewFirstLevelRequest(
): claimActions.HealthClaimPreviewFirstLevelRequestAction {
  return {
    type: claimActions.HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_REQUEST
  };
}

export function healthClaimPreviewFirstLevelSuccess(
  healthClaim: HealthClaimPreview
): claimActions.HealthClaimPreviewFirstLevelSuccessAction {
  return {
    type: claimActions.HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_SUCCESS,
    data: healthClaim
  };
}

export function healthClaimPreviewFirstLevelFailure(
  error: Error | string
): claimActions.HealthClaimPreviewFirstLevelFailureAction {
  return {
    type: claimActions.HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_FAILURE,
    error
  };
}

export function healthClaimPreviewFirstLevelReset(
): claimActions.HealthClaimPreviewFirstLevelResetAction {
  return {
    type: claimActions.HEALTH_CLAIM_PREVIEW_FIRST_LEVEL_RESET
  };
}

export function healthClaimPreviewSecondLevel(
  code: string
): claimActions.HealthClaimPreviewSecondLevelAction {
  return {
    type: claimActions.HEALTH_CLAIM_PREVIEW_SECOND_LEVEL,
    code
  };
}

export function healthClaimPreviewSecondLevelRequest(
): claimActions.HealthClaimPreviewSecondLevelRequestAction {
  return {
    type: claimActions.HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_REQUEST
  };
}

export function healthClaimPreviewSecondLevelSuccess(
  healthClaim: HealthClaimPreview
): claimActions.HealthClaimPreviewSecondLevelSuccessAction {
  return {
    type: claimActions.HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_SUCCESS,
    data: healthClaim
  };
}

export function healthClaimPreviewSecondLevelFailure(
  error: Error | string
): claimActions.HealthClaimPreviewSecondLevelFailureAction {
  return {
    type: claimActions.HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_FAILURE,
    error
  };
}

export function healthClaimPreviewSecondLevelReset(
): claimActions.HealthClaimPreviewSecondLevelResetAction {
  return {
    type: claimActions.HEALTH_CLAIM_PREVIEW_SECOND_LEVEL_RESET
  };
}

export function exportSearchHealthClaim(
  filter: HealthClaimFilter
): claimActions.ExportSearchHealthClaimAction {
  return {
    type: claimActions.EXPORT_SEARCH_HEALTH_CLAIM,
    filter
  };
}

export function exportSearchHealthClaimRequest(
): claimActions.ExportSearchHealthClaimRequestAction {
  return {
    type: claimActions.EXPORT_SEARCH_HEALTH_CLAIM_REQUEST
  };
}

export function exportSearchHealthClaimReset(
): claimActions.ExportSearchHealthClaimResetAction {
  return {
    type: claimActions.EXPORT_SEARCH_HEALTH_CLAIM_RESET
  };
}

export function exportSearchHealthClaimSuccess(
): claimActions.ExportSearchHealthClaimSuccessAction {
  return {
    type: claimActions.EXPORT_SEARCH_HEALTH_CLAIM_SUCCESS,
  };
}

export function exportSearchHealthClaimFailure(
  error: Error | string
): claimActions.ExportSearchHealthClaimFailureAction {
  return {
    type: claimActions.EXPORT_SEARCH_HEALTH_CLAIM_FAILURE,
    error
  };
}

export function showHealthClaimModal(
): claimActions.ShowHealthClaimModalAction {
  return {
    type: claimActions.SHOW_HEALTH_CLAIM_MODAL,
  };
}

export function showHealthClaimFirstLevelModal(
): claimActions.ShowHealthClaimFirstLevelModalAction {
  return {
    type: claimActions.SHOW_HEALTH_CLAIM_FIRST_LEVEL_MODAL,
  };
}

export function showReadFirstLevelAppealModal(
): claimActions.ShowReadFirstLevelAppealModalAction {
  return {
    type: claimActions.SHOW_READ_FIRST_LEVEL_APPEAL_MODAL,
  };
}

export function showReadSecondLevelAppealModal(
): claimActions.ShowReadSecondLevelAppealModalAction {
  return {
    type: claimActions.SHOW_READ_SECOND_LEVEL_APPEAL_MODAL,
  };
}

export function showHealthClaimSecondLevelModal(
): claimActions.ShowHealthClaimSecondLevelModalAction {
  return {
    type: claimActions.SHOW_HEALTH_CLAIM_SECOND_LEVEL_MODAL,
  };
}

export function validateHealthClaimPolicy(
  accidentdate: string,
  customerId: string,
  policy: string,
): actions.ValidateHealthClaimPolicyAction {
  return {
    type: actions.VALIDATE_HEALTH_CLAIM_POLICY,
    accidentdate, customerId, policy
  };
}

export function validateHealthClaimPolicyRequest(
): actions.ValidateHealthClaimPolicyRequestAction {
  return {
    type: actions.VALIDATE_HEALTH_CLAIM_POLICY_REQUEST
  };
}

export function validateHealthClaimPolicyReset(
): actions.ValidateHealthClaimPolicyResetAction {
  return {
    type: actions.VALIDATE_HEALTH_CLAIM_POLICY_RESET
  };
}

export function validateHealthClaimPolicySuccess(
  validateHealthClaimPolicy: PreClaimPerson
): actions.ValidateHealthClaimPolicySuccessAction {
  return {
    type: actions.VALIDATE_HEALTH_CLAIM_POLICY_SUCCESS,
    data: validateHealthClaimPolicy
  };
}

export function validateHealthClaimPolicyFailure(
  error: Error | string
): actions.ValidateHealthClaimPolicyFailureAction {
  return {
    type: actions.VALIDATE_HEALTH_CLAIM_POLICY_FAILURE,
    error
  };
}

export function validateHealthClaimInsured(
  accidentdate: string,
  customerId: string,
  insurePersonId: string,
): actions.ValidateHealthClaimInsuredAction {
  return {
    type: actions.VALIDATE_HEALTH_CLAIM_INSURED,
    accidentdate, customerId, insurePersonId
  };
}

export function validateHealthClaimInsuredRequest(
): actions.ValidateHealthClaimInsuredRequestAction {
  return {
    type: actions.VALIDATE_HEALTH_CLAIM_INSURED_REQUEST
  };
}

export function validateHealthClaimInsuredReset(
): actions.ValidateHealthClaimInsuredResetAction {
  return {
    type: actions.VALIDATE_HEALTH_CLAIM_INSURED_RESET
  };
}

export function validateHealthClaimInsuredSuccess(
  validateHealthClaimInsured: PreClaimPerson
): actions.ValidateHealthClaimInsuredSuccessAction {
  return {
    type: actions.VALIDATE_HEALTH_CLAIM_INSURED_SUCCESS,
    data: validateHealthClaimInsured
  };
}

export function validateHealthClaimInsuredFailure(
  error: Error | string
): actions.ValidateHealthClaimInsuredFailureAction {
  return {
    type: actions.VALIDATE_HEALTH_CLAIM_INSURED_FAILURE,
    error
  };
}

export function fileFirstLevelAppeal(
  appeal: HealthClaimAppeal
): claimActions.FileFirstLevelAppealAction {
  return {
    type: claimActions.FILE_FIRST_LEVEL_APPEAL,
    appeal
  };
}

export function fileFirstLevelAppealRequest(
): claimActions.FileFirstLevelAppealRequestAction {
  return {
    type: claimActions.FILE_FIRST_LEVEL_APPEAL_REQUEST
  };
}

export function fileFirstLevelAppealSuccess(
): claimActions.FileFirstLevelAppealSuccessAction {
  return {
    type: claimActions.FILE_FIRST_LEVEL_APPEAL_SUCCESS
  };
}

export function fileFirstLevelAppealFailure(
  error: Error | string
): claimActions.FileFirstLevelAppealFailureAction {
  return {
    type: claimActions.FILE_FIRST_LEVEL_APPEAL_FAILURE,
    error
  };
}

export function fileSecondLevelAppeal(
  appeal: HealthClaimAppeal
): claimActions.FileSecondLevelAppealAction {
  return {
    type: claimActions.FILE_SECOND_LEVEL_APPEAL,
    appeal
  };
}

export function fileSecondLevelAppealRequest(
): claimActions.FileSecondLevelAppealRequestAction {
  return {
    type: claimActions.FILE_SECOND_LEVEL_APPEAL_REQUEST
  };
}

export function fileSecondLevelAppealSuccess(
): claimActions.FileSecondLevelAppealSuccessAction {
  return {
    type: claimActions.FILE_SECOND_LEVEL_APPEAL_SUCCESS
  };
}

export function fileSecondLevelAppealFailure(
  error: Error | string
): claimActions.FileSecondLevelAppealFailureAction {
  return {
    type: claimActions.FILE_SECOND_LEVEL_APPEAL_FAILURE,
    error
  };
}

export function readFirstLevelAppeal(
  code: string
): claimActions.ReadFirstLevelAppealAction {
  return {
    type: claimActions.READ_FIRST_LEVEL_APPEAL,
    code
  };
}

export function readFirstLevelAppealRequest(
): claimActions.ReadFirstLevelAppealRequestAction {
  return {
    type: claimActions.READ_FIRST_LEVEL_APPEAL_REQUEST
  };
}

export function readFirstLevelAppealSuccess(
  healthClaim: HealthClaimPreview
): claimActions.ReadFirstLevelAppealSuccessAction {
  return {
    type: claimActions.READ_FIRST_LEVEL_APPEAL_SUCCESS,
    data: healthClaim
  };
}

export function readFirstLevelAppealFailure(
  error: Error | string
): claimActions.ReadFirstLevelAppealFailureAction {
  return {
    type: claimActions.READ_FIRST_LEVEL_APPEAL_FAILURE,
    error
  };
}

export function readFirstLevelAppealReset(
): claimActions.ReadFirstLevelAppealResetAction {
  return {
    type: claimActions.READ_FIRST_LEVEL_APPEAL_RESET
  };
}

export function readSecondLevelAppeal(
  code: string
): claimActions.ReadSecondLevelAppealAction {
  return {
    type: claimActions.READ_SECOND_LEVEL_APPEAL,
    code
  };
}

export function readSecondLevelAppealRequest(
): claimActions.ReadSecondLevelAppealRequestAction {
  return {
    type: claimActions.READ_SECOND_LEVEL_APPEAL_REQUEST
  };
}

export function readSecondLevelAppealSuccess(
  healthClaim: HealthClaimPreview
): claimActions.ReadSecondLevelAppealSuccessAction {
  return {
    type: claimActions.READ_SECOND_LEVEL_APPEAL_SUCCESS,
    data: healthClaim
  };
}

export function readSecondLevelAppealFailure(
  error: Error | string
): claimActions.ReadSecondLevelAppealFailureAction {
  return {
    type: claimActions.READ_SECOND_LEVEL_APPEAL_FAILURE,
    error
  };
}

export function readSecondLevelAppealReset(
): claimActions.ReadSecondLevelAppealResetAction {
  return {
    type: claimActions.READ_SECOND_LEVEL_APPEAL_RESET
  };
}

export function markAppealAsRead(
  claimId: string
): claimActions.MarkAppealAsReadAction {
  return {
    type: claimActions.MARK_APPEAL_AS_READ,
    claimId
  };
}

export function markAppealAsReadRequest(
): claimActions.MarkAppealAsReadRequestAction {
  return {
    type: claimActions.MARK_APPEAL_AS_READ_REQUEST
  };
}

export function markAppealAsReadSuccess(
): claimActions.MarkAppealAsReadSuccessAction {
  return {
    type: claimActions.MARK_APPEAL_AS_READ_SUCCESS,
  };
}

export function markAppealAsReadFailure(
  error: Error | string
): claimActions.MarkAppealAsReadFailureAction {
  return {
    type: claimActions.MARK_APPEAL_AS_READ_FAILURE,
    error
  };
}

export function markAppealAsReadReset(
): claimActions.MarkAppealAsReadResetAction {
  return {
    type: claimActions.MARK_APPEAL_AS_READ_RESET
  };
}

export function printFirstLevelAppeal(
  claimId: string
): claimActions.PrintFirstLevelAppealAction {
  return {
    type: claimActions.PRINT_FIRST_LEVEL_APPEAL,
    claimId
  };
}

export function printFirstLevelAppealRequest(
): claimActions.PrintFirstLevelAppealRequestAction {
  return {
    type: claimActions.PRINT_FIRST_LEVEL_APPEAL_REQUEST
  };
}

export function printFirstLevelAppealSuccess(
): claimActions.PrintFirstLevelAppealSuccessAction {
  return {
    type: claimActions.PRINT_FIRST_LEVEL_APPEAL_SUCCESS,
  };
}

export function printFirstLevelAppealFailure(
  error: Error | string
): claimActions.PrintFirstLevelAppealFailureAction {
  return {
    type: claimActions.PRINT_FIRST_LEVEL_APPEAL_FAILURE,
    error
  };
}

export function printFirstLevelAppealReset(
): claimActions.PrintFirstLevelAppealResetAction {
  return {
    type: claimActions.PRINT_FIRST_LEVEL_APPEAL_RESET
  };
}

export function printSecondLevelAppeal(
  claimId: string
): claimActions.PrintSecondLevelAppealAction {
  return {
    type: claimActions.PRINT_SECOND_LEVEL_APPEAL,
    claimId
  };
}

export function printSecondLevelAppealRequest(
): claimActions.PrintSecondLevelAppealRequestAction {
  return {
    type: claimActions.PRINT_SECOND_LEVEL_APPEAL_REQUEST
  };
}

export function printSecondLevelAppealSuccess(
): claimActions.PrintSecondLevelAppealSuccessAction {
  return {
    type: claimActions.PRINT_SECOND_LEVEL_APPEAL_SUCCESS,
  };
}

export function printSecondLevelAppealFailure(
  error: Error | string
): claimActions.PrintSecondLevelAppealFailureAction {
  return {
    type: claimActions.PRINT_SECOND_LEVEL_APPEAL_FAILURE,
    error
  };
}

export function printSecondLevelAppealReset(
): claimActions.PrintSecondLevelAppealResetAction {
  return {
    type: claimActions.PRINT_SECOND_LEVEL_APPEAL_RESET
  };
}

export function downloadHealthClaimFile(
  fileId: string,
  name: string
): claimActions.DownloadHealthClaimFileAction {
  return {
    type: claimActions.DOWNLOAD_HEALTH_CLAIM_FILE,
    fileId,
    name
  };
}

export function downloadHealthClaimFileRequest(
): claimActions.DownloadHealthClaimFileRequestAction {
  return {
    type: claimActions.DOWNLOAD_HEALTH_CLAIM_FILE_REQUEST
  };
}

export function downloadHealthClaimFileSuccess(
): claimActions.DownloadHealthClaimFileSuccessAction {
  return {
    type: claimActions.DOWNLOAD_HEALTH_CLAIM_FILE_SUCCESS,
  };
}

export function downloadHealthClaimFileFailure(
  error: Error | string
): claimActions.DownloadHealthClaimFileFailureAction {
  return {
    type: claimActions.DOWNLOAD_HEALTH_CLAIM_FILE_FAILURE,
    error
  };
}

export function downloadHealthClaimFileReset(
): claimActions.DownloadHealthClaimFileResetAction {
  return {
    type: claimActions.DOWNLOAD_HEALTH_CLAIM_FILE_RESET
  };
}

export function getDocumentList(
  claimId: string
): claimActions.GetDocumentListAction {
  return {
    type: claimActions.GET_DOCUMENT_LIST,
    claimId
  };
}

export function getDocumentListRequest(
): claimActions.GetDocumentListRequestAction {
  return {
    type: claimActions.GET_DOCUMENT_LIST_REQUEST
  };
}

export function getDocumentListSuccess(
  values: Nomen[]
): claimActions.GetDocumentListSuccessAction {
  return {
    type: claimActions.GET_DOCUMENT_LIST_SUCCESS,
    data: values
  };
}

export function getDocumentListReset(
): claimActions.GetDocumentListResetAction {
  return {
    type: claimActions.GET_DOCUMENT_LIST_RESET
  };
}

export function getDocumentListFailure(
  error: Error | string
): claimActions.GetDocumentListFailureAction {
  return {
    type: claimActions.GET_DOCUMENT_LIST_FAILURE,
    error
  };
}

export function uploadHealthClaimFile(
  data: UploadHealthClaimFile
): claimActions.UploadHealthClaimFileAction {
  return {
    type: claimActions.UPLOAD_HEALTH_CLAIM_FILE,
    data
  };
}

export function uploadHealthClaimFileRequest(
): claimActions.UploadHealthClaimFileRequestAction {
  return {
    type: claimActions.UPLOAD_HEALTH_CLAIM_FILE_REQUEST
  };
}

export function uploadHealthClaimFileSuccess(
  data: boolean
): claimActions.UploadHealthClaimFileSuccessAction {
  return {
    type: claimActions.UPLOAD_HEALTH_CLAIM_FILE_SUCCESS,
    data
  };
}

export function uploadHealthClaimFileFailure(
  error: Error | string
): claimActions.UploadHealthClaimFileFailureAction {
  return {
    type: claimActions.UPLOAD_HEALTH_CLAIM_FILE_FAILURE,
    error
  };
}