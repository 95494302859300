/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";

export function HeaderMenuRight({ layoutProps }) {
    const location = useLocation();
    const saleMenus = useSelector((state) => state.general.appInfo?.saleMenus);

    const [selectedVehicle, setSelectedVehicle] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState(false);
    const [selectedHealth, setSelectedHealth] = useState(false);
    const [selectedBe, setSelectedBe] = useState(false);

    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };

    // Vehicle
    const selectMenuVehicle = () => {
        setSelectedVehicle(!selectedVehicle);
        deselectMenuProperty();
        deselectMenuHealth();
        deselectMenuBe();
    };

    const deselectMenuVehicle = () => {
        setSelectedVehicle(false);
    };

    // Property
    const selectMenuProperty = () => {
        setSelectedProperty(!selectedProperty);
        deselectMenuVehicle();
        deselectMenuHealth();
        deselectMenuBe();
    };

    const deselectMenuProperty = () => {
        setSelectedProperty(false);
    };

    // Health
    const selectMenuHealth = () => {
        setSelectedHealth(!selectedHealth);
        deselectMenuVehicle();
        deselectMenuProperty();
        deselectMenuBe();
    };

    const deselectMenuHealth = () => {
        setSelectedHealth(false);
    };

    // BE
    const selectMenuBe = () => {
        setSelectedBe(!selectedBe);
        deselectMenuVehicle();
        deselectMenuProperty();
        deselectMenuHealth();
    };

    const deselectMenuBe = () => {
        setSelectedBe(false);
    };

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*Classic submenu*/}

            {/* 04 Vehicle */}
            {!!saleMenus && saleMenus.enableSale04 && <li onClick={selectMenuVehicle} className={`menu-item menu-item-submenu menu-item-rel ${selectedVehicle && 'menu-item-hover'} b
                ${getMenuItemActive('/claims', true)}`} data-menu-toggle="click" aria-haspopup="true" onPointerLeave={deselectMenuVehicle} >

                <div className="menu-link menu-toggle">
                    <span className="menu-text menu-ipad-a">Automjete</span><span
                        className="menu-desc"></span><i className="menu-arrow"></i>
                </div>
                <div className="menu-submenu  menu-submenu-fixed menu-submenu-center" style={{ width: '220px' }} >
                    <div className="menu-subnav">
                        <ul className="menu-content">
                            <li className="menu-item ">
                                <h3 className="menu-heading menu-toggle">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span></span></i>
                                    <span className="menu-text">Automjete</span>
                                    <i className="menu-arrow"></i>
                                </h3>
                                <ul className="menu-inner">
                                    <li className="menu-item " aria-haspopup="true">
                                        <NavLink to="/kasko-policy"
                                            className="menu-link ">
                                            <i className="menu-bullet menu-bullet-line">
                                                <span></span>
                                            </i>
                                            <span className="menu-text">Kasko</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>}

            {/* 09 Property */}
            {!!saleMenus && saleMenus.enableSale09 && <li onClick={selectMenuProperty} className={`menu-item menu-item-submenu menu-item-rel ${selectedProperty && 'menu-item-hover'} 
                ${getMenuItemActive('/claims', true)}`} data-menu-toggle="click" aria-haspopup="true" onPointerLeave={deselectMenuProperty}>

                <div className="menu-link menu-toggle">
                    <span className="menu-text menu-ipad-p">Pasuri</span><span
                        className="menu-desc"></span><i className="menu-arrow"></i>
                </div>
                <div className="menu-submenu  menu-submenu-fixed menu-submenu-center" style={{ width: '220px' }} >
                    <div className="menu-subnav">
                        <ul className="menu-content">
                            <li className="menu-item ">
                                <h3 className="menu-heading menu-toggle">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span></span></i>
                                    <span className="menu-text">Pasuri</span>
                                    <i className="menu-arrow"></i>
                                </h3>
                                <ul className="menu-inner">
                                    <li className="menu-item " aria-haspopup="true">
                                        <NavLink to="/property-policy"
                                            className="menu-link ">
                                            <i className="menu-bullet menu-bullet-line">
                                                <span></span>
                                            </i>
                                            <span className="menu-text">Sigurimi i pasurisë</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>}

            {/* Health 05 34 61 */}
            {!!saleMenus && (saleMenus.enableSale05 || saleMenus.enableSale34 || saleMenus.enableSale61) && <li onClick={selectMenuHealth} className={`menu-item menu-item-submenu menu-item-rel ipadmenu ${selectedHealth && 'menu-item-hover'} 
                ${getMenuItemActive('/health', true)}`} data-menu-toggle="click" aria-haspopup="true"  onPointerLeave={deselectMenuHealth} >

                <div className="menu-link menu-toggle">
                    <span className="menu-text menu-ipad-sha">Shëndet & Aksidente</span><span
                        className="menu-desc"></span><i className="menu-arrow"></i>
                </div>
                <div className="menu-submenu  menu-submenu-fixed menu-submenu-center" style={{ width: '220px' }} >
                    <div className="menu-subnav">
                        <ul className="menu-content">
                            <li className="menu-item ">
                                <h3 className="menu-heading menu-toggle">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span></span></i>
                                    <span className="menu-text">Shëndet & Aksidente</span>
                                    <i className="menu-arrow"></i>
                                </h3>
                                <ul className="menu-inner">
                                    {saleMenus.enableSale05 && <li className="menu-item " aria-haspopup="true">
                                        <NavLink to="/travel-health-policy"
                                            className="menu-link "><i className="menu-bullet menu-bullet-line"><span></span></i><span
                                                className="menu-text">Sigurimi i shëndetit në udhëtim</span>
                                        </NavLink>
                                    </li>}
                                    {saleMenus.enableSale34 && <li className="menu-item " aria-haspopup="true">
                                        <NavLink to="/private-health-policy"
                                            className="menu-link "><i className="menu-bullet menu-bullet-line"><span></span></i><span
                                                className="menu-text">Sigurimi i shëndetit</span>
                                        </NavLink>
                                    </li>}
                                    {saleMenus.enableSale61 && <li className="menu-item " aria-haspopup="true">
                                        <NavLink to="/foreigner-policy"
                                            className="menu-link "><i className="menu-bullet menu-bullet-line"><span></span></i><span
                                                className="menu-text">Lejeqëndrim</span>
                                        </NavLink>
                                    </li>}
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>}

            {/* BE 55 34-01 */}
            {!!saleMenus && (saleMenus.enableSale55 || saleMenus.enableSale34_01) && <li onClick={selectMenuBe} className={`menu-item menu-item-submenu menu-item-rel ipadmenu ${selectedBe && 'menu-item-hover'} 
                ${getMenuItemActive('/health', true)}`} data-menu-toggle="click" aria-haspopup="true" style={{ width: '140px' }} onPointerLeave={deselectMenuBe} >

                <div className="menu-link menu-toggle" style={{right: '-35px'}}>
                    <span className="menu-text" >Jetë</span><span
                        className="menu-desc"></span><i className="menu-arrow"></i>
                </div>
                <div className="menu-submenu  menu-submenu-fixed menu-submenu-center l-menu-item" style={{ width: '220px' }} >
                    <div className="menu-subnav">
                        <ul className="menu-content">
                            <li className="menu-item ">
                                <h3 className="menu-heading menu-toggle">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span></span></i>
                                    <span className="menu-text">Jetë</span>
                                    <i className="menu-arrow"></i>
                                </h3>
                                <ul className="menu-inner">
                                    {saleMenus.enableSale55 && <li className="menu-item " aria-haspopup="true">
                                        <NavLink to="/credit-life-policy"
                                            className="menu-link "><i className="menu-bullet menu-bullet-line"><span></span></i><span
                                                className="menu-text">Sigurimet e Jetës së Kredimarrësit</span>
                                        </NavLink>
                                    </li>}
                                    {saleMenus.enableSale34_01 && <li className="menu-item " aria-haspopup="true">
                                        <NavLink to="/term-life-policy"
                                            className="menu-link "><i className="menu-bullet menu-bullet-line"><span></span></i><span
                                                className="menu-text">Sigurimi i Jetës</span>
                                        </NavLink>
                                    </li>}
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>}

        </ul>
        {/*begin::Header Nav*/}
        {/*end::Header Nav*/}
    </div>;
}
