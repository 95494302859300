import React, { Dispatch, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { logoutUser } from "../../redux/actionCreators/authActionCreators";
import { LogoutUserAction } from "../../redux/actionTypes/authActionTypes";
import { AppState } from "../../redux/reducers/rootReducer";
import { User } from "../../redux/types";
import { Loading } from "../Layout/Loading";

interface Props {
  logout(token: string): void;
  authenticated: boolean;
}

const Logout = (props: Props) => {
  const user = useSelector<AppState, User | null>((state: any) => state.auth.user);

  useEffect(() => {
    if (!!user) props.logout(user!.token);
  }, []);

  return(<>
     {props.authenticated ? <Loading /> : <Redirect to="/dashboard" />}
     </>
  )
}


const mapDispatchToProps = (dispatch: Dispatch<LogoutUserAction>) => ({
  logout: (token: string) => { dispatch(logoutUser(token)); }
});

const mapStateToProps = (state: any) => ({
  authenticated: state.auth.authenticated,
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
