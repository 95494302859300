import React from "react";
import {
    Button,
    Modal,
    ModalProps
} from "react-bootstrap";
import { connect, useSelector } from 'react-redux';
import { AppState } from '../../../../redux/reducers/rootReducer';
import { Loading2 } from '../../../Layout/Loading2';
import * as actionTypes from '../../../../redux/actionTypes/healthClaimActionTypes';
import { HealthClaimPreview, prepareHealthClaimAppeal } from "../../../../utils/healthPreClaimTypes";
import { User } from '../../../../redux/types';
import * as actions from '../../../../redux/actionCreators/healthPreClaimActionCreators';
import { Dispatch } from "redux";
import * as authActionTypes from '../../../../redux/actionTypes/authActionTypes';
import * as authActions from '../../../../redux/actionCreators/authActionCreators';

type Props = {
    header: string;
    text: string;
    onClose: () => void;
    onSubmit: (appeal: any) => void;
    onGetCustomer(customerId: string): void;
}
const SecondLevelAppealModal = (props: ModalProps & Props) => {
    const healthClaim = useSelector<AppState, HealthClaimPreview | null>((state: any) => state.healthClaim.selectedHealthClaim);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.HEALTH_CLAIM_PREVIEW_SECOND_LEVEL]
        || state.isLoading[actionTypes.FILE_SECOND_LEVEL_APPEAL]);
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);

    const formatAmount = (value: any) => {
        if (value !== null && value !== undefined && value !== '') {
            return value.toFixed(3);
        }
        return value;
    }

    const getDate = (value: any) => {
        if (!value || value === "01.01.0001") {
            return '';
        }
        return value;
    }

    const submitAppeal = () => {
        if (!user || !healthClaim)
            return;

        const appeal = prepareHealthClaimAppeal(user.customerId, healthClaim.claimId)
        props.onSubmit(appeal)
        props.onGetCustomer(user!.customerId);
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {healthClaim !== null ? "Ankese ndaj vendimit te shkalles se dyte    " + healthClaim.claimNumber : "Procesoj..."}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="claimModal">
                {isLoading ? <Loading2 />
                    :
                    <>
                        {healthClaim !== null && <>
                            <h6 className="font-weight-bolder mb-3">          {"Numri i policës: " + healthClaim.policy_code} </h6>
                            <div className="text-dark-50 line-height-lg">

                                <div className="reviewTitle">Data e aksidentit</div><div className="reviewValue"> {healthClaim.date_accident}</div>
                                <div className="reviewTitle">Klasa e demit</div><div className="reviewValue">{healthClaim.class}</div>

                                <br />
                                <div className="reviewTitle">Data e raportimit</div><div className="reviewValue"> {healthClaim.date_reported}</div>
                                <div className="reviewTitle">Statusi</div><div className="reviewValue">{healthClaim.status}</div>

                                <br />
                                <div className="reviewTitle">Data e refuzuar</div><div className="reviewValue"> {getDate(healthClaim.date_rejected)}</div>
                                <div className="reviewTitle">Shuma e kërkuar </div><div className="reviewValue">(€){formatAmount(healthClaim.amount_requestedv)}</div>

                                <br />
                                <div className="reviewTitle">Përshkrimi i aksidentit </div><div className="reviewValue"> {healthClaim.description_accident}</div>
                                <div className="reviewTitle">Shuma e miratuar </div><div className="reviewValue">(€){formatAmount(healthClaim.approved_amountv)}</div>

                                <br />
                                <div className="reviewTitle">Përshkrimi i dëmit </div><div className="reviewValue">{healthClaim.description_damage}</div>
                                <div className="reviewTitle">Shuma e paguar </div><div className="reviewValue">(€){formatAmount(healthClaim.paid_amountv)}</div>
                                <br />
                            </div>

                            <div className="separator separator-dashed my-5"></div>
                            <h6 className="font-weight-bolder mb-3"></h6>
                            <div className="text-dark-50 line-height-lg appeal-question">A jeni i sigurt që dëshironi të paraqisni një apel për sigurimin shëndetësor për këtë kërkesë?
                                <div>
                                    Duke klikuar në butonin dërgo do të nisë një proces dhe do të njoftoheni për rezultatin.
                                </div>
                            </div>
                        </>}
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                {healthClaim && !isLoading && <>
                    <Button onClick={submitAppeal}>Paraqisni</Button>
                </>}

                <Button onClick={props.onClose}>Dalje</Button>
            </Modal.Footer>
        </Modal >
    )
};

const mapDispatchToProps = (dispatch: Dispatch<actionTypes.FileSecondLevelAppealAction
    | authActionTypes.GetCustomerAction>) => ({
        onSubmit: (appeal: any) => {
            dispatch(actions.fileSecondLevelAppeal(appeal));
        },
        onGetCustomer: (customerId: string) => {
            dispatch(authActions.getCustomer(customerId));
        }
    });

export default connect(null, mapDispatchToProps)(SecondLevelAppealModal);