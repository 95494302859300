import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers/rootReducer';
import InputFieldMain from '../../Custom/InputFieldMain';
import SelectField from '../../Custom/SelectField';
import DatePickerFieldInline from '../../Custom/DatePickerFieldInline';
import { Nomen } from '../../../utils';
import { Gender } from '../../../utils/nomen';
import { useFormikContext } from 'formik';
import store from "../../../redux/configureStore";
import { User } from '../../../redux/types';
import * as authActions from "../../../redux/actionCreators/authActionCreators";
import RadioButtonGroupFieldMain from '../../Custom/RadioButtonGroupFieldMain';

type Props = {
    data?: any | null;
    index: number;
    enableDelete: boolean;
    removeDriver: (driverIndex: any) => void;
    tipKindChanged: (tipKind: any, driverIndex: any) => void;
    dropkey: number;
}

const Driver = (props: Props) => {
    const { setFieldValue, values }: any = useFormikContext();
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const isPhysical = props.data?.tipKind === '3'

    const handleClick: React.MouseEventHandler<
        HTMLButtonElement | HTMLAnchorElement
    > = e => {
        if (!!props.removeDriver && props.index >= 0) props.removeDriver(props.index);
    }

    const checkProfile = (value: any) => {
        var currentValues = { ...values };

        if (props.index !== currentValues.drivers.length - 1)
            return;

        const customerId = user?.customerId;

        if (!customerId) {
            store.dispatch(authActions.hasProfile(value));
        }
    };

    const tipKindChanged = (value: any) => {
        props.tipKindChanged(value, props.index);
    }

    const isContractor = () => {
        var currentValues = { ...values };
        return (props.index == 0 && currentValues.drivers.length == 1) || props.index === currentValues.drivers.length - 1;
    };

    const TipKind: string = `drivers[${props.index}].tipKind`;
    const ClientNo: string = `drivers[${props.index}].clientNo`;
    const Nipt: string = `drivers[${props.index}].nipt`;
    const Title: string = `drivers[${props.index}].title`;
    const LastName: string = `drivers[${props.index}].lastName`;
    const Sex: string = `drivers[${props.index}].sex`;
    const Address: string = `drivers[${props.index}].address`;
    const Place: string = `drivers[${props.index}].place`;
    const Email: string = `drivers[${props.index}].email`;
    const Celphone: string = `drivers[${props.index}].celphone`;
    const BirthDate: string = `drivers[${props.index}].birthDate`;
    const DrivingLicenceNo: string = `drivers[${props.index}].drivingLicenceNo`;

    return (<>
        <>
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                {props.index !== -1 && !!props.removeDriver && props.enableDelete
                    && <div className="row">
                        <div className="col-xl-9"></div>
                        <div className="col-xl-3">
                            <a>
                                <i className="flaticon2-rubbish-bin-delete-button text-muted icon-1x pull-right"
                                    onClick={handleClick} title="Hiq"></i>
                            </a></div></div>}

                {isContractor() && <div className="row">
                    <div className="col-xl-12 addSpace">
                        <label className="inlineLbl">Tip</label>
                        <RadioButtonGroupFieldMain name={TipKind} type="radio" placeholder="" label="" items={[{ id: '3', value: 'Fizik' }, { id: '2', value: 'Juridik' }]} newvalue={props.data?.tipKind || ''} change={tipKindChanged} inline />
                    </div>
                </div>}

                <div className="row">
                    <div className="col-xl-12 addSpace">
                        {!isPhysical && <InputFieldMain type="text" name={Nipt} label="Nipt" placeholder="Nipt" maxLength={16} newvalue={props.data?.nipt || ''} inline />}
                        {isPhysical && <InputFieldMain type="text" name={ClientNo} label="Numri personal" placeholder="Numri personal" maxLength={16} newvalue={props.data?.clientNo || ''} inline />}
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <InputFieldMain type="text" name={Title} label="Emri" placeholder="Emri" newvalue={props.data?.title || ''} inline capitalized />
                    </div>
                </div>

                {isPhysical && < div className="row">
                    <div className="col-xl-12 addSpace">
                        <InputFieldMain type="text" name={LastName} label="Mbiemri" placeholder="Mbiemri" newvalue={props.data?.lastName || ''} inline capitalized />
                    </div>
                </div>}

                {isPhysical && <div className="row">
                    <div className="col-xl-12 addSpace">
                        <SelectField name={Sex} label="Gjinia" items={Gender} newvalue={props.data?.sex || ''} inline />
                    </div>
                </div>}

                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <InputFieldMain type="text" name={Address} label="Adresa" placeholder="Adresa" newvalue={props.data?.address || ''} inline />
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <SelectField name={Place} label="Vendi" items={places} newvalue={props.data?.place || ''} inline />
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <InputFieldMain type="text" name={Email} blur={checkProfile} label="Email" placeholder="Email" newvalue={props.data?.email || ''} inline />
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 addSpace">
                        <InputFieldMain type="text" name={Celphone} label="Nr. telefonit" placeholder="Nr. telefonit" newvalue={props.data?.celphone || ''} inline />
                    </div>
                </div>

                {isPhysical && <div className="row">
                    <div className="col-xl-12 addSpace">
                        <InputFieldMain type="text" name={DrivingLicenceNo} label="Nr. i patentës" placeholder="Nr. i patentës" maxLength={10} newvalue={props.data?.drivingLicenceNo || ''} inline />
                    </div>
                </div>}

                {isPhysical && <div className="row">
                    <div className="col-xl-12 addSpace">
                        <DatePickerFieldInline name={BirthDate} type="text" placeholder="Datëlindja" newvalue={props.data?.birthDate || ''} />
                    </div>
                </div>}

            </div>
        </>
    </>);
}

export default connect()(Driver);
