import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import * as actionTypes from '../../../redux/actionTypes/bPolicyActionTypes';
import { AppState } from '../../../redux/reducers/rootReducer';
import { Loading } from '../../Layout/Loading';

const Confirmation = () => {
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.BOOK_B_POLICY]);

    return (<>
        {isLoading
            ? <Loading />
            :
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                <div className="mb-10 font-weight-bold text-dark"></div>
                   Redirecting to the payment provider...
            </div>}
    </>);
}

export default connect()(Confirmation);
