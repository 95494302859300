import React from "react";
import {
    Button,
    Modal,
    ModalProps
} from "react-bootstrap";

type Props = {
    header: string;
    text: string;
    className?: string;
    renderAsHtml?: boolean;
    onClose: () => void;
}
const SimpleModal = (props: ModalProps & Props) => (
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {props.header}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className={props.className ? props.className : ''}>
            {!props.renderAsHtml && <p>{props.text}</p>}
            {props.renderAsHtml && <p><span dangerouslySetInnerHTML={{ __html: props.text }}></span></p>}

        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.onClose}>Dalje</Button>
        </Modal.Footer>
    </Modal>
);

export default SimpleModal;