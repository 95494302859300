import React, { useState } from "react";
import { connect, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { AppState } from '../../../../redux/reducers/rootReducer';
import * as nomenActionTypes from '../../../../redux/actionTypes/nomenActionTypes';
import { Loading } from "../../../Layout/Loading";
import { prepareCliPolicy, ThInsQuotation } from "../../../../utils/policyTypes";
import { useFormikWizard } from 'formik-wizard';
import { User } from "../../../../redux/types";
import InputFieldMain from "../../../Custom/InputFieldMain";
import SelectField from "../../../Custom/SelectField";
import DatePickerFieldInline from "../../../Custom/DatePickerFieldInline";
import { Gender } from '../../../../utils/nomen';
import { Pep } from '../../../../utils/nomen';
import moment from 'moment';
import store from "../../../../redux/configureStore";
import * as authActions from "../../../../redux/actionCreators/authActionCreators";
import { Nomen } from "../../../../utils";
import * as cliActions from "../../../../redux/actionCreators/cliPolicyActionCreators";
import CustomSelectField from "../../../Custom/CustomSelectField";
import { Tooltip } from "@material-ui/core";
import { IncomeSources } from '../../../../utils/nomen';

const InsuredPerson = () => {
    const { values, setValues }: any = useFormikContext();
    const { values: wizardValues }: any = useFormikWizard();

    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[nomenActionTypes.GET_PLACES]);
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const banksLife = useSelector<AppState, Nomen[]>((state: any) => state.nomen.BanksLife);
    const chosenCLIQuotation = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.chosenCLIQuotation);
    const cliPackageQuotation = chosenCLIQuotation ? chosenCLIQuotation[0] : null;
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const showPoliticalModalLink = values.pep === '1';
    const showOtherSourcesField = values.sourceOfIncome === 'Te tjera specifiko';

    const recalculateQuotation = (date: any) => {
        const value = moment(date, "DD/MM/yyyy");
        if (value && isValidAge(value)) {
            const state = store.getState();
            const customerId = state.auth.user?.customerId;

            const packageId = cliPackageQuotation ? cliPackageQuotation.packageId : '';
            wizardValues.insuredPerson.birthDate = value.toDate();

            const cliPolicy = prepareCliPolicy(customerId, wizardValues, packageId);
            store.dispatch(cliActions.getPackageCLIQuotation(cliPolicy));
        }
    }

    const isValidAge = (value: any) => {
        if (value.isValid() && value.isBefore(moment())) {
            const age = moment().diff(value, 'years');
            return age >= 20 && age <= 65;
        }

        return false;
    };

    const checkProfile = (value: any) => {
        const customerId = user?.customerId;

        if (!customerId) {
            store.dispatch(authActions.hasProfile(value));
        }
    };

    const showPoliticalFormModal = () => {
        store.dispatch(cliActions.showPoliticalFormModal());
    }

    const pepChanged = () => {
        store.dispatch(cliActions.resetPoliticalForm());
    }

    const sourceOfIncomeChanged = (value: any) => {
        if (value !== 'Te tjera specifiko') {
            wizardValues.insuredPerson.otherSources = '';
        }
    }

    return (<>
        {isLoading
            ? <Loading />
            : <>
                <div className="wizardTitle">I siguruari</div>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="clientNo" label="Numri personal" placeholder="Numri personal" maxLength={16} newvalue={values?.clientNo || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="title" label="Emri" placeholder="Emri" newvalue={values?.title || ''} inline capitalized />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="lastName" label="Mbiemri" placeholder="Mbiemri" newvalue={values?.lastName || ''} inline capitalized />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <SelectField name="sex" label="Gjinia" items={Gender} newvalue={values?.sex || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" newvalue={values?.address || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <SelectField name="place" label="Vendi" items={places} newvalue={values?.place || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="email" blur={checkProfile} label="Email" placeholder="Email" newvalue={values?.email || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="celphone" label="Celulari" placeholder="Celulari" newvalue={values?.celphone || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <DatePickerFieldInline name="birthDate" type="text" placeholder="Datëlindja" blur={recalculateQuotation} newvalue={values?.birthDate || ''} />
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <Tooltip
                                arrow
                                title="Personi i ekspozuar politikisht"
                                placement="top-end">
                                <span>
                                    <CustomSelectField name="pep" label="PEP" items={Pep} change={pepChanged} newvalue={values?.pep || ''} inline />
                                </span>
                            </Tooltip>
                        </div>
                    </div>

                    {showPoliticalModalLink && <div className="row">
                        <div className="col-xl-4 addSpace"></div>
                        <div className="col-xl-8 addSpace">
                            <a className="link" onClick={() => showPoliticalFormModal()}>Formular identifikimi i personit të ekspozuar politikisht</a>
                        </div>
                    </div>}

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <CustomSelectField name="sourceOfIncome" label="Burimi i të ardhurave" items={IncomeSources} change={sourceOfIncomeChanged} newvalue={values?.sourceOfIncome || ''} inline />
                        </div>
                    </div>

                    {showOtherSourcesField && <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="otherSources" label="" placeholder="Burime të tjera" newvalue={values?.otherSources || ''} inline />
                        </div>
                    </div>}

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="loanId" label="Numri i identifikimit të kredisë" placeholder="Numri i identifikimit të kredisë" newvalue={values?.loanId || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <DatePickerFieldInline name="loanPeriodFrom" type="text" placeholder="Data e fillimit të kredisë" newvalue={values?.loanPeriodFrom || ''} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <DatePickerFieldInline name="loanPeriodTo" type="text" placeholder="Data e mbarimit të kredisë" newvalue={values?.loanPeriodTo || ''} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <SelectField name="bank" label="Banka" items={banksLife} newvalue={values?.bank || ''} inline />
                        </div>
                    </div>
                </div>
            </>
        }
    </>
    );
}
export default connect()(InsuredPerson);
