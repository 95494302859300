import { HealthPreClaim, HealthClaimAppeal } from "../../utils/healthPreClaimTypes";
import { PreClaimPerson, PreClaimRegisterData } from "../../utils/claimTypes";

export const CHECK_POLICY = "healthPreClaimActionTypes/CHECK_POLICY";
export interface CheckPolicyAction {
  type: typeof CHECK_POLICY;
  policyCode: string;
}

export const CHECK_POLICY_REQUEST = "healthPreClaimActionTypes/CHECK_POLICY_REQUEST";
export interface CheckPolicyRequestAction {
  type: typeof CHECK_POLICY_REQUEST;
}

export const CHECK_POLICY_SUCCESS = "healthPreClaimActionTypes/CHECK_POLICY_SUCCESS";
export interface CheckPolicySuccessAction {
  type: typeof CHECK_POLICY_SUCCESS;
}

export const CHECK_POLICY_FAILURE = "healthPreClaimActionTypes/CHECK_POLICY_FAILURE";
export interface CheckPolicyFailureAction {
  type: typeof CHECK_POLICY_FAILURE;
  error: Error | string;
}

export const REGISTER_HEALTH_PRECLAIM = "healthPreClaimActionTypes/REGISTER_HEALTH_PRECLAIM";
export interface RegisterHealthPreClaimAction {
  type: typeof REGISTER_HEALTH_PRECLAIM;
  healthPreClaim: HealthPreClaim;
}

export const REGISTER_HEALTH_PRECLAIM_REQUEST = "healthPreClaimActionTypes/REGISTER_HEALTH_PRECLAIM_REQUEST";
export interface RegisterHealthPreClaimRequestAction {
  type: typeof REGISTER_HEALTH_PRECLAIM_REQUEST;
}

export const REGISTER_HEALTH_PRECLAIM_SUCCESS = "healthPreClaimActionTypes/REGISTER_HEALTH_PRECLAIM_SUCCESS";
export interface RegisterHealthPreClaimSuccessAction {
  type: typeof REGISTER_HEALTH_PRECLAIM_SUCCESS;
  data: PreClaimRegisterData;
}

export const REGISTER_HEALTH_PRECLAIM_FAILURE = "healthPreClaimActionTypes/REGISTER_HEALTH_PRECLAIM_FAILURE";
export interface RegisterHealthPreClaimFailureAction {
  type: typeof REGISTER_HEALTH_PRECLAIM_FAILURE;
  error: Error | string;
}

export const VALIDATE_HEALTH_CLAIM_POLICY = "healthPreClaimActionTypes/VALIDATE_HEALTH_CLAIM_POLICY";
export interface ValidateHealthClaimPolicyAction {
  type: typeof VALIDATE_HEALTH_CLAIM_POLICY;
  accidentdate: string;
  customerId: string;
  policy: string;
}

export const VALIDATE_HEALTH_CLAIM_POLICY_REQUEST = "healthPreClaimActionTypes/VALIDATE_HEALTH_CLAIM_POLICY_REQUEST";
export interface ValidateHealthClaimPolicyRequestAction {
  type: typeof VALIDATE_HEALTH_CLAIM_POLICY_REQUEST;
}

export const VALIDATE_HEALTH_CLAIM_POLICY_RESET = "healthPreClaimActionTypes/VALIDATE_HEALTH_CLAIM_POLICY_RESET";
export interface ValidateHealthClaimPolicyResetAction {
  type: typeof VALIDATE_HEALTH_CLAIM_POLICY_RESET;
}

export const VALIDATE_HEALTH_CLAIM_POLICY_SUCCESS = "healthPreClaimActionTypes/VALIDATE_HEALTH_CLAIM_POLICY_SUCCESS";
export interface ValidateHealthClaimPolicySuccessAction {
  type: typeof VALIDATE_HEALTH_CLAIM_POLICY_SUCCESS;
  data: PreClaimPerson;
}

export const VALIDATE_HEALTH_CLAIM_POLICY_FAILURE = "healthPreClaimActionTypes/VALIDATE_HEALTH_CLAIM_POLICY_FAILURE";
export interface ValidateHealthClaimPolicyFailureAction {
  type: typeof VALIDATE_HEALTH_CLAIM_POLICY_FAILURE;
  error: Error | string;
}

export const VALIDATE_HEALTH_CLAIM_INSURED = "healthPreClaimActionTypes/VALIDATE_HEALTH_CLAIM_INSURED";
export interface ValidateHealthClaimInsuredAction {
  type: typeof VALIDATE_HEALTH_CLAIM_INSURED;
  accidentdate: string;
  customerId: string;
  insurePersonId: string;
}

export const VALIDATE_HEALTH_CLAIM_INSURED_REQUEST = "healthPreClaimActionTypes/VALIDATE_HEALTH_CLAIM_INSURED_REQUEST";
export interface ValidateHealthClaimInsuredRequestAction {
  type: typeof VALIDATE_HEALTH_CLAIM_INSURED_REQUEST;
}

export const VALIDATE_HEALTH_CLAIM_INSURED_RESET = "healthPreClaimActionTypes/VALIDATE_HEALTH_CLAIM_INSURED_RESET";
export interface ValidateHealthClaimInsuredResetAction {
  type: typeof VALIDATE_HEALTH_CLAIM_INSURED_RESET;
}

export const VALIDATE_HEALTH_CLAIM_INSURED_SUCCESS = "healthPreClaimActionTypes/VALIDATE_HEALTH_CLAIM_INSURED_SUCCESS";
export interface ValidateHealthClaimInsuredSuccessAction {
  type: typeof VALIDATE_HEALTH_CLAIM_INSURED_SUCCESS;
  data: PreClaimPerson;
}

export const VALIDATE_HEALTH_CLAIM_INSURED_FAILURE = "healthPreClaimActionTypes/VALIDATE_HEALTH_CLAIM_INSURED_FAILURE";
export interface ValidateHealthClaimInsuredFailureAction {
  type: typeof VALIDATE_HEALTH_CLAIM_INSURED_FAILURE;
  error: Error | string;
}

export type HealthPreClaimAction =
  | CheckPolicyAction
  | CheckPolicyRequestAction
  | CheckPolicySuccessAction
  | CheckPolicyFailureAction
  | RegisterHealthPreClaimAction
  | RegisterHealthPreClaimRequestAction
  | RegisterHealthPreClaimSuccessAction
  | RegisterHealthPreClaimFailureAction
  | ValidateHealthClaimPolicyAction
  | ValidateHealthClaimPolicyRequestAction
  | ValidateHealthClaimPolicyResetAction
  | ValidateHealthClaimPolicySuccessAction
  | ValidateHealthClaimPolicyFailureAction
  | ValidateHealthClaimInsuredAction
  | ValidateHealthClaimInsuredRequestAction
  | ValidateHealthClaimInsuredResetAction
  | ValidateHealthClaimInsuredSuccessAction
  | ValidateHealthClaimInsuredFailureAction;