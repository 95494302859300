import React from "react";
import {
    Button,
    Modal,
    ModalProps
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppState } from '../../../redux/reducers/rootReducer';
import { Nomen, PolicyPreview } from "../../../utils/generalTypes";
import { Loading2 } from '../../Layout/Loading2';
import * as actionTypes from '../../../redux/actionTypes/generalActionTypes';
import moment from 'moment';

type Props = {
    header: string;
    text: string;
    onClose: () => void;
}
const PolicyModal = (props: ModalProps & Props) => {
    const policy = useSelector<AppState, PolicyPreview | null>((state: any) => state.general.selectedPolicy);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.POLICY_PREVIEW]);
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {policy !== null ? "Polica    " + policy.policyType : "Procesoj..."}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? <Loading2 />
                    :
                    <>
                        {policy !== null && <>
                            <h6 className="font-weight-bolder mb-3">          {" Numri i serise: " + policy.shownum} </h6>
                            <div className="text-dark-50 line-height-lg">

                                <div className="reviewTitle">Data e shitjes</div><div className="reviewValue"> {policy.datesale}</div>
                                <div className="reviewTitle">Primi</div><div className="reviewValue">{policy.totalV1 + "  " + policy.valuta}</div>
                                <br />

                                <div className="reviewTitle">Fillon    </div><div className="reviewValue">{policy.fvalid}</div>
                                <div className="reviewTitle">Taksa</div><div className="reviewValue">{policy.totalV2 + "  " + policy.valuta}</div>
                                <br />

                                <div className="reviewTitle">Mbaron </div><div className="reviewValue"> {policy.tvalid}</div>
                                <div className="reviewTitle">Primi + Taksa</div><div className="reviewValue">{policy.totalV + "  " + policy.valuta}</div>
                                <br />

                                <div className="reviewTitle">Paketë </div><div className="reviewValue"> {policy.packageName}</div>
                                <div className="reviewTitle">Kupon</div><div className="reviewValue">{policy.coupon}</div>

                                {policy.zone !== null
                                    ? <> <br /> <div className="reviewTitle">Nr. i ditëve të siguruara</div><div className="reviewValue">{policy.insdays}</div></>
                                    : <><div className="reviewTitle"></div><div className="reviewValue"></div></>}
                                <div className="reviewTitle">Lloji I transakcionit</div><div className="reviewValue">{policy.paytype}</div>
                                {policy.category !== null
                                    ? <> <br /> <div className="reviewTitle">Periudha</div><div className="reviewValue">{policy.period}</div></>
                                    : <><div className="reviewTitle"></div><div className="reviewValue"></div></>}
                                <div className="reviewTitle">Statusi</div><div className="reviewValue">{policy.policyStatus}</div>
                                {policy.zone !== null && <> <br />
                                    <div className="reviewTitle"></div><div className="reviewValue"></div>
                                    <div className="reviewTitle">Zona e udhëtimit</div><div className="reviewValue">{policy.zone}</div><br />
                                    <div className="reviewTitle"></div><div className="reviewValue"></div>
                                    <div className="reviewTitle">Vendi i udhëtimit</div><div className="reviewValue">{policy.teritory}</div></>}

                                <br />
                                {policy.category !== null && <> <div className="reviewTitle">Kategoria</div><div className="reviewValue">{policy.category}</div></>}
                                <br />
                                {policy.category !== null && <> <div className="reviewTitle">Amf Numri</div><div className="reviewValue">{policy.amfnumber}</div></>}

                            </div>
                            {policy.contractor !== null && (policy.contractor.tipkind === "3" || policy.contractor.tipkind === "2")
                                && <><div className="separator separator-dashed my-5"></div>
                                    <h6 className="font-weight-bolder mb-3">            Kontraktuesi: </h6></>}
                            {policy.contractor !== null && policy.contractor.tipkind === "3" && <div className="text-dark-50 line-height-lg">

                                <div className="reviewTitle">Numri personal</div><div className="reviewValue"> {policy.contractor.clientno}</div>
                                <div className="reviewTitle">Adresa</div><div className="reviewValue"> {policy.contractor.address}, {policy.contractor.place}</div>
                                <br />
                                <div className="reviewTitle">Emri Mbiemri</div><div className="reviewValue"> {policy.contractor.title} {policy.contractor.lastname}</div>
                                <div className="reviewTitle">Celulari</div><div className="reviewValue"> {policy.contractor.celphone}</div>
                                <br />
                                <div className="reviewTitle">Pasaporta</div><div className="reviewValue">{policy.contractor.passport}</div>
                                <div className="reviewTitle">Datëlindja</div><div className="reviewValue">{moment(policy.contractor.birthdate).format('DD/MM/YYYY')}</div>
                            </div>}
                            {policy.contractor !== null && policy.contractor.tipkind === "2" && <div className="text-dark-50 line-height-lg">
                                <div className="reviewTitle">Nipti</div><div className="reviewValue"> {policy.contractor.nipt}</div>
                                <div className="reviewTitle">Adresa</div><div className="reviewValue"> {policy.contractor.address}, {policy.contractor.place}</div>
                                <br />
                                <div className="reviewTitle">Emri</div><div className="reviewValue"> {policy.contractor.title} {policy.contractor.lastname}</div>
                            </div>}
                            {policy.vehicle != null && policy.vehicle.regnum !== null && <><div className="separator separator-dashed my-5"></div>
                                <h6 className="font-weight-bolder mb-3">            Automjeti: </h6>
                                <div className="text-dark-50 line-height-lg">
                                    <div className="reviewTitle">Targa</div><div className="reviewValue"> {policy.vehicle.regnum}</div>
                                    <div className="reviewTitle">Prodhuesi/Modeli</div><div className="reviewValue"> {policy.vehicle.mark} - {policy.vehicle.tip}</div>

                                    <br />
                                    <div className="reviewTitle">Numri i shasisë</div><div className="reviewValue">{policy.vehicle.chassis}</div>
                                    <div className="reviewTitle">Viti i prodhimit</div><div className="reviewValue">{policy.vehicle.prodyear}</div>

                                    <br />
                                    <div className="reviewTitle">Pesha e automjetit</div><div className="reviewValue">{policy.vehicle.capacity}</div>
                                    <div className="reviewTitle">Ngjyra e automjetit</div><div className="reviewValue">  {policy.vehicle.colour}</div>
                                    <br />
                                    <div className="reviewTitle">Fuqia(kw)</div><div className="reviewValue"> {policy.vehicle.power}</div><br />
                                </div>
                            </>}
                            <div className="separator separator-dashed my-5"></div>
                            {policy.insured.map((insured, index) => (<>
                                <h6 className="font-weight-bolder mb-3 mt-3"> I Siguruari: </h6>
                                {insured !== null && insured.tipkind === "3" && <div className="text-dark-50 line-height-lg">
                                    <div className="reviewTitle">Numri personal</div><div className="reviewValue">{insured.clientno}</div>
                                    <div className="reviewTitle">Adresa</div><div className="reviewValue"> {insured.address}, {insured.place}</div>

                                    <br />
                                    <div className="reviewTitle">Emri Mbiemri</div><div className="reviewValue"> {insured.title} {insured.lastname}</div>
                                    <div className="reviewTitle">Celulari</div><div className="reviewValue"> {insured.celphone}</div>

                                    <br />
                                    <div className="reviewTitle">Pasaporta</div><div className="reviewValue">{insured.passport}</div>
                                    <div className="reviewTitle">Dëshmi aftësie</div><div className="reviewValue"> {insured.drivingLicenceNo}</div>

                                    <br />
                                    <div className="reviewTitle">Datëlindja</div><div className="reviewValue">{moment(insured.birthdate).format('DD/MM/YYYY')}</div>
                                    <div className="reviewTitle"> Klasa</div><div className="reviewValue"> {insured.drivingLicenceCat}</div>

                                </div>}
                                {insured !== null && insured.tipkind === "2" && <div className="text-dark-50 line-height-lg">
                                    <div className="reviewTitle">Nipti</div><div className="reviewValue"> {insured.nipt}</div>
                                    <div className="reviewTitle">Adresa</div><div className="reviewValue"> {insured.address}, {insured.place}</div>

                                    <br />
                                    <div className="reviewTitle">Emri</div><div className="reviewValue"> {insured.title} {insured.lastname}</div>
                                    <div className="reviewTitle">Dëshmi aftësie</div><div className="reviewValue"> {insured.drivingLicenceNo}</div>

                                    <br />
                                    <div className="reviewTitle">Pasaporta</div><div className="reviewValue">{insured.passport}</div>
                                    <div className="reviewTitle">Dëshmi aftësie - Klasa</div><div className="reviewValue"> {insured.drivingLicenceCat}</div>
                                </div>}
                            </>))}
                        </>}
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onClose}>Dalje</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default PolicyModal;