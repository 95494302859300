import * as React from 'react';
import { connect } from 'react-redux';
import CheckboxFieldMain from '../../../Custom/CheckboxFieldMain';
import InputFieldMain from '../../../Custom/InputFieldMain';
import RadioButtonGroupFieldMain from '../../../Custom/RadioButtonGroupFieldMain';

type Props = {
    data?: any | null;
    index: number;
}

const Question = (props: Props) => {

    const questionChanged = (value: any) => { }

    return (<>
        {props.data?.type === 0 && <div className="row r-2">
            <div className="col-xl-1 l-2"></div>
            <div className="col-xl-9 q-2"><b>{props.data?.text}</b></div>
            <div className="col-xl-2 a-2"></div>
        </div>}

        {props.data?.type === 1 &&
            <div className="row rem-1">
                <div className="col-xl-12">{props.data?.text}</div>
                <div className="col-xl-12">
                    <RadioButtonGroupFieldMain
                        type="radio"
                        name={props.index + ""}
                        label=""
                        placeholder=""
                        items={[{ id: '1', value: 'Po' }, { id: '2', value: 'Jo' }]}
                        newvalue={props.data?.value || ''} />
                </div>
            </div>
        }

        {props.data?.type === 2 && <div className="row r-2">
            <div className="col-xl-1 l-2">{props.data?.listing}</div>
            <div className="col-xl-9 q-2">{props.data?.text}</div>
            <div className="col-xl-2 a-2">
                <CheckboxFieldMain
                    type="checkbox"
                    name={props.index + ""}
                    label=""
                    index={props.index}
                    change={questionChanged}
                    newvalue={props.data?.selected}
                />
            </div>
        </div>}

        {props.data?.type === 3 && <div className="row r-4">
            <div className="col-xl-1">{props.data?.listing}</div>
            <div className="col-xl-11">
                <div>{props.data?.text}</div>
                <div className="it" >Ju lutem specifikoni lidhjen sipas definicionit të mësipërm</div>
                <InputFieldMain
                    type="text"
                    name={props.index + ""}
                    label=""
                    placeholder=""
                    newvalue={props.data?.value || ''} />
            </div>
        </div>}
    </>);
}

export default connect()(Question);
