import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { useFormikWizard } from 'formik-wizard';
import SelectField from '../../Custom/SelectField';
import { Payment } from '../../../utils/nomen';
import { AppState } from '../../../redux/reducers/rootReducer';
import { Nomen } from '../../../utils';
import CheckboxField from '../../Custom/CheckboxField';
import PdfStudent from "../../../InsuranceConditions/05_02.pdf";
import PdfTravel from "../../../InsuranceConditions/05_01.pdf";

const Preview = () => {
    const { values: wizValues }: any = useFormikWizard();
    const zones = useSelector<AppState, Nomen[]>((state: any) => state.nomen.THZones);
    const zone = zones.find(z => z.code === wizValues.thInsMain.zone);
    const currentPath = useSelector<AppState, string>((state: any) => state.router.location.pathname);
    const isStudent = currentPath.includes("student");

    return (<div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
        <h4 className="mb-10 font-weight-bold text-dark">Verifiko</h4>
        <div className="text-dark-50 line-height-lg">
            <div className="reviewTitle">Fillon</div><div className="reviewValue"> {wizValues.thInsMain.startDate.toLocaleDateString("en-GB")}</div>
            <div className="reviewTitle">Mbaron</div><div className="reviewValue">{wizValues.thInsMain.endDate.toLocaleDateString("en-GB")}</div><br />
            <div className="reviewTitle">Zona e udhëtimit</div><div className="reviewValue"> {zone?.name}</div>
            <div className="reviewTitle">Vendi i udhëtimit</div><div className="reviewValue">  {wizValues.thInsMain.territory}</div><br />
        </div>

        <div className="separator separator-dashed my-5"></div>
        <h6 className="font-weight-bolder mb-3">I Siguruari: </h6>
        <div className="text-dark-50 line-height-lg">
            <div className="reviewTitle">Numri personal</div><div className="reviewValue">{wizValues.insured.clientNo}</div>
            <div className="reviewTitle">Emri Mbiemri</div><div className="reviewValue"> {wizValues.insured.title} {wizValues.insured.lastName}</div><br />
            <div className="reviewTitle">Celulari</div><div className="reviewValue"> {wizValues.insured.celphone}</div>
            <div className="reviewTitle">Adresa</div><div className="reviewValue"> {wizValues.insured.address}</div><br />
            <div className="reviewTitle">Vendi</div><div className="reviewValue"> {wizValues.insured.place}</div>
            <div className="reviewTitle">Email</div><div className="reviewValue"> {wizValues.insured.email}</div><br />
            <div className="reviewTitle">Pasaporta</div><div className="reviewValue"> {wizValues.insured.passport}</div>
        </div>
        <div className="separator separator-dashed my-5"></div>
        <div className="row">
            <div className="col-xl-6">
                <CheckboxField type="checkbox" name="acceptConditions" label="I kuptoj dhe I pranoj kushtet e sigurimit" />
            </div>
            <div className="col-xl-6">
            {isStudent && <a href={PdfStudent} target="_blank">Kushtet e përgjithshme të sigurimit</a>}
            {!isStudent && <a href={PdfTravel} target="_blank">Kushtet e përgjithshme të sigurimit</a>}
            </div>
        </div>
        <div className="separator separator-dashed my-5"></div>
        <CheckboxField type="checkbox" name="acceptTerms" label="Autorizoj shoqërine “Sigal” sha që të përpunojë dhe të përdorë të dhënat e mia personale në përputhje me ligjin nr. 9887, datë 10.03.2008, ndryshuar me ligjin Nr. 48/2012, ndryshuar me ligjin nr.120/2014 “Për mbrojtjen e të dhënave personale”. Nëpërmjet kësaj deklarate autorizoj Shoqërinë “Sigal” sha që të vendosi në dipozicion të Autoriteteve Mbikqyrëse të dhënat e mia personale, të cilat gjithashtu janë subjekt i përdorimit të këtyre të dhënave në përputhje me ligjin Nr. 48/2012, ndryshuar me ligjin nr.120/2014 “Për mbrojtjen e të dhënave personale“" />
        <div className="separator separator-dashed my-5"></div>
        <div className="row">
            <div className="col-xl-3">
                <SelectField name="payment" label="Payment" items={Payment} />
            </div>
            <div className="col-xl-9" style={{ height: '60px', width: '491px', backgroundRepeat: 'no-repeat', backgroundPosition:'center bottom', backgroundImage: 'url(/media/dashboard/cards.png)' }}>
            </div>
        </div>
    </div>
    );
}

export default connect()(Preview);
