import { AppInfo, PolicyGridItem, PolicyPreview } from './../../utils/generalTypes';
import * as actions from "../actionTypes/generalActionTypes";

export interface GeneralState {
    showModal: boolean;
    header: string;
    text: string;
    nextStep: string;
    renderAsHtml?: boolean;
    policies: PolicyGridItem[];
    selectedPolicy: PolicyPreview | null;
    policiesCount: number;
    showPolicyModal: boolean;
    confirmPolicyResponse: any;
    isConfirmed: boolean;
    paymentErrorReceived: boolean;
    paymentError: string;
    policyStartDateValidity: boolean | null;
    showSuccessToast: boolean;
    successToastText: string;
    showFailureToast: boolean;
    failureToastText: string;
    showLoginModal: boolean;
    autoLoginEmail: string;
    showRegistrationModal: boolean;
    insuredPerson: any;
    showVerificationModal: boolean;
    appInfo: AppInfo | null;
    continueWithoutAccount: boolean;
    showCreateAccountQuestionModal: boolean;
}

const initialState: GeneralState = {
    showModal: false,
    header: "",
    text: "",
    nextStep: "",
    renderAsHtml: false,
    policies: [],
    selectedPolicy: null,
    policiesCount: 0,
    showPolicyModal: false,
    confirmPolicyResponse: null,
    isConfirmed: false,
    paymentErrorReceived: false,
    paymentError: "",
    policyStartDateValidity: null,
    showSuccessToast: false,
    successToastText: "",
    showFailureToast: false,
    failureToastText: "",
    showLoginModal: false,
    autoLoginEmail: "",
    showRegistrationModal: false,
    insuredPerson: "",
    showVerificationModal: false,
    appInfo: null,
    continueWithoutAccount: false,
    showCreateAccountQuestionModal: false
};

export default function generalReducer(
    state: GeneralState = initialState,
    action: actions.GeneralAction
): GeneralState {
    switch (action.type) {
        case actions.SHOW_MODAL:
            return {
                ...state,
                showModal: true,
                header: action.header,
                text: action.text,
                nextStep: action.next,
                renderAsHtml: action.renderAsHtml
            };
        case actions.HIDE_MODAL:
            return {
                ...state,
                showModal: false,
                header: "",
                text: "",
                nextStep: "",
                renderAsHtml: false
            };
        case actions.SEARCH_POLICY_SUCCESS:
            return {
                ...state,
                policies: action.data,
                policiesCount: action.count
            };
        case actions.SEARCH_POLICY_RESET:
            return {
                ...state,
                policies: [],
                policiesCount: 0
            };
        case actions.POLICY_PREVIEW_SUCCESS:
            return {
                ...state,
                selectedPolicy: action.data,
            };
        case actions.POLICY_PREVIEW_RESET:
            return {
                ...state,
                selectedPolicy: null,
                showPolicyModal: false
            };
        case actions.SHOW_POLICY_MODAL:
            return {
                ...state,
                showPolicyModal: true,
            };
        case actions.CONFIRM_POLICY_SUCCESS:
            return {
                ...state,
                confirmPolicyResponse: action.data,
                isConfirmed: true
            };
        case actions.CONFIRM_POLICY_RESET:
            return {
                ...state,
                confirmPolicyResponse: null,
                isConfirmed: false
            };
        case actions.GET_PAYMENT_RESPONSE_SUCCESS:
            return {
                ...state,
                paymentErrorReceived: true,
                paymentError: action.data
            };
        case actions.GET_PAYMENT_RESPONSE_RESET:
            return {
                ...state,
                paymentErrorReceived: false,
                paymentError: ""
            };
        case actions.CHECK_FVALID_SUCCESS:
            return {
                ...state,
                policyStartDateValidity: action.data,
            };
        case actions.CHECK_FVALID_RESET:
            return {
                ...state,
                policyStartDateValidity: null
            };
        case actions.SHOW_SUCCESS_TOAST:
            return {
                ...state,
                showSuccessToast: true,
                successToastText: action.text,
            };
        case actions.SHOW_SUCCESS_TOAST_RESET:
            return {
                ...state,
                showSuccessToast: false,
                successToastText: "",
            };
        case actions.SHOW_FAILURE_TOAST:
            return {
                ...state,
                showFailureToast: true,
                failureToastText: action.text,
            };
        case actions.SHOW_FAILURE_TOAST_RESET:
            return {
                ...state,
                showFailureToast: false,
                failureToastText: "",
            };
        case actions.SHOW_LOGIN_MODAL:
            return {
                ...state,
                showLoginModal: true,
                autoLoginEmail: action.email
            };
        case actions.SHOW_LOGIN_MODAL_RESET:
            return {
                ...state,
                showLoginModal: false,
            };
        case actions.SHOW_REGISTRATION_MODAL:
            return {
                ...state,
                showRegistrationModal: true,
                insuredPerson: action.insuredPerson
            };
        case actions.SHOW_REGISTRATION_MODAL_RESET:
            return {
                ...state,
                showRegistrationModal: false,
                insuredPerson: null
            };
        case actions.SHOW_VERIFICATION_MODAL:
            return {
                ...state,
                showVerificationModal: true,
            };
        case actions.SHOW_VERIFICATION_MODAL_RESET:
            return {
                ...state,
                showVerificationModal: false,
            };
        case actions.GET_APP_INFO_SUCCESS:
            return {
                ...state,
                appInfo: action.data,
            };
        case actions.CONTINUE_WITHOUT_ACCOUNT:
            return {
                ...state,
                continueWithoutAccount: true,
            };
        case actions.CONTINUE_WITHOUT_ACCOUNT_RESET:
            return {
                ...state,
                continueWithoutAccount: false,
            };
        case actions.SHOW_CREATE_ACCOUNT_QUESTION_MODAL:
            return {
                ...state,
                showCreateAccountQuestionModal: true,
                insuredPerson: action.insuredPerson
            };
        case actions.SHOW_CREATE_ACCOUNT_QUESTION_MODAL_RESET:
            return {
                ...state,
                showCreateAccountQuestionModal: false
            };
        default:
            return state;
    }
}
