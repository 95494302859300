import React from "react";
import {
    Button,
    Modal,
    ModalProps
} from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { AppState } from '../../../../redux/reducers/rootReducer';
import { Loading2 } from '../../../Layout/Loading2';
import * as actionTypes from '../../../../redux/actionTypes/claimActionTypes';
import { ClaimGridItem } from "../../../../utils/claimTypes";
import * as actions from '../../../../redux/actionCreators/claimActionCreators';
import { Dispatch } from "redux";

type Props = {
    header: string;
    text: string;
    onClose: () => void;
    onDownload(claimId: string): void;
}
const ClaimModal = (props: ModalProps & Props) => {
    const healthClaim = useSelector<AppState, ClaimGridItem | null>((state: any) => state.claim.selectedClaim);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.CLAIM_PREVIEW]
        || state.isLoading[actionTypes.DOWNLOAD_PREVIEW_CLAIM_DOCUMENT]);

    const formatAmount = (value: any) => {
        if (value !== null && value !== undefined && value !== '') {
            return value.toFixed(3);
        }
        return value;
    }

    const getDate = (value: any) => {
        if (!value || value === "01.01.0001") {
            return '';
        }
        return value;
    }

    const downloadPreviewClaim = () => {
        if (!healthClaim)
            return;

        props.onDownload(healthClaim.claimId)
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {healthClaim ? "Kërko të gjitha dëmet    " + healthClaim.claimNumber : "Procesoj..."}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="claimModal">
                {isLoading ? <Loading2 />
                    :
                    <>
                        {healthClaim && <>
                            <h6 className="font-weight-bolder mb-3">          {"Numri i policës: " + healthClaim.policyCode} </h6>
                            <div className="text-dark-50 line-height-lg">
                                <div className="reviewTitle">Data e aksidentit</div><div className="reviewValue"> {healthClaim.dateAccident}</div>
                                <div className="reviewTitle">Klasa e demit</div><div className="reviewValue">{healthClaim.className}</div>
                                <br />
                                <div className="reviewTitle">Data e raportimit</div><div className="reviewValue"> {healthClaim.dateReported}</div>
                                <div className="reviewTitle">Vendi raportues</div><div className="reviewValue"> {healthClaim.reportedCountry}</div>
                                <br />
                                <div className="reviewTitle">Polica</div><div className="reviewValue">{healthClaim.nature}, {healthClaim.typePolicy}</div>
                                <div className="reviewTitle">Statusi</div><div className="reviewValue">{healthClaim.claimStatus}</div>
                                <br />
                                <div className="reviewTitle">Përshkrimi i dëmit </div><div className="reviewValue">{healthClaim.reasonName}</div>
                                <div className="reviewTitle">Shuma e kërkuar </div><div className="reviewValue">(€){formatAmount(healthClaim.paymentAmount)}</div>
                                <br />
                                <div className="reviewTitle">Shuma e paguar </div><div className="reviewValue">(€){formatAmount(healthClaim.invoicedAmount)}</div>
                                <div className="reviewTitle">Shuma e miratuar </div><div className="reviewValue">(€){formatAmount(healthClaim.approvedAmount)}</div>
                                <br />
                                <div className="reviewTitle">Përshkrimi i aksidentit </div><div className="reviewValue"> {healthClaim.descriptionAccident}</div>
                                <br />
                                <br />
                            </div>
                            {healthClaim.damagedId !== null
                                && <><div className="separator separator-dashed my-5"></div>
                                    <h6 className="font-weight-bolder mb-3"> I Dëmtuari: </h6>
                                    <div className="text-dark-50 line-height-lg">
                                        <div className="reviewTitle">Emri Mbiemri</div><div className="reviewValue"> {healthClaim.damagedName}</div>
                                        <div className="reviewTitle">Adresa i celurari</div><div className="reviewValue"> {healthClaim.damagedAddress}, {healthClaim.damagedPhone}</div>
                                    </div>

                                    <div className="separator separator-dashed my-5"></div>

                                    <h6 className="font-weight-bolder mb-3"> I Dëmtuari - Automjeti: </h6>
                                    <div className="text-dark-50 line-height-lg">
                                        <div className="reviewTitle">Targa</div><div className="reviewValue"> {healthClaim.damagedVehicleRegnum}</div>
                                        <div className="reviewTitle">Numri i shasisë</div><div className="reviewValue"> {healthClaim.damagedVehicleChassis}</div>
                                        <br />
                                        <div className="reviewTitle">Mjeti/Klasa</div><div className="reviewValue">{healthClaim.damagedTypeVehicle}</div>
                                    </div></>}

                            {healthClaim.insuredId !== null
                                && <><div className="separator separator-dashed my-5"></div>
                                    <h6 className="font-weight-bolder mb-3"> I Siguruari: </h6>
                                    <div className="text-dark-50 line-height-lg">
                                        <div className="reviewTitle">Emri Mbiemri</div><div className="reviewValue"> {healthClaim.insuredName}</div>
                                        <div className="reviewTitle">Adresa i celurari</div><div className="reviewValue"> {healthClaim.insuredAddress}, {healthClaim.insuredPhone}</div>
                                    </div>

                                    <div className="separator separator-dashed my-5"></div>

                                    <h6 className="font-weight-bolder mb-3"> I Siguruari - Automjeti: </h6>
                                    <div className="text-dark-50 line-height-lg">
                                        <div className="reviewTitle">Targa</div><div className="reviewValue"> {healthClaim.insuredVehicleRegnum}</div>
                                        <div className="reviewTitle">Numri i shasisë</div><div className="reviewValue"> {healthClaim.insuredVehicleChassis}</div>
                                        <br />
                                        <div className="reviewTitle">Mjeti/Klasa</div><div className="reviewValue">{healthClaim.insuredTypeVehicle}</div>
                                    </div></>}
                        </>}
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                {healthClaim && !isLoading && < Button onClick={downloadPreviewClaim}>Relacion i demit</Button>}
                <Button onClick={props.onClose}>Dalje</Button>
            </Modal.Footer>
        </Modal >
    )
};

const mapDispatchToProps = (dispatch: Dispatch<actionTypes.DownloadPreviewClaimDocumentAction>) => ({
    onDownload: (claimId: any) => {
        dispatch(actions.downloadPreviewClaimDocument(claimId));
    }
});

export default connect(null, mapDispatchToProps)(ClaimModal);