import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import InputField from '../../Custom/InputField';
import Nav from './Nav';

const Contact = () => {

    return (
        <>
            <div className="d-flex flex-column-auto flex-column px-10">
                <Link to="/" className="login-logo pb-lg-4 pb-10">
                    <img
                        alt="Logo"
                        className="max-h-70px"
                        src={toAbsoluteUrl("/media/logos/sigal_new.png")}
                    />
                </Link>
                <Nav step={1} />
            </div>
            <div className="login-form form px-10 fv-plugins-bootstrap fv-plugins-framework">
                <div className="pb-10 pb-lg-15">
                    <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Kontakt</h3>
                    <div className="text-muted font-weight-bold font-size-h4">
                    Ju lutem vendosni te dhenat e kontaktit me poshte
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputField name="address" type="text" placeholder="Adresa" label="Adresa" />
                    </div>
                    <div className="col-xl-6">
                        <InputField name="place" type="text" placeholder="Qyteti" label="Qyteti" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputField name="mob" type="text" placeholder="Telefon " label="Telefon" />
                    </div>
                    <div className="col-xl-6">
                        <InputField name="email" type="email" placeholder="Email" label="Email" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;
