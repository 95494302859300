import * as React from 'react';
import { connect } from 'react-redux';
import { DropzoneArea } from 'material-ui-dropzone'
import { useFormikContext } from 'formik';

const UploadDocuments = () => {
    const { values }: any = useFormikContext();

    const {
        setFieldValue,
    } = useFormikContext();

    const handleSave = (files: any) => {
        setFieldValue('objectInventoryFiles', files);
    }

    return (<>
        <div className="wizardTitle">Dokumente</div>
        <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
            <div className="font-weight-bold text-dark">
                Ngarko foto: Leterjnoftimi nga dy anet
            </div>
            <div className="mb-10 font-weight-bold text-dark">
                Te Tjera: (Foto brenda shtëpis dhe fotot inventarit)
            </div>
            <div>
                <DropzoneArea
                    onChange={handleSave}
                    onDelete={handleSave}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/jpeg']}
                    filesLimit={20}
                    maxFileSize={10485760}
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    showFileNamesInPreview={true}
                    showFileNames={true}
                    initialFiles={values.objectInventoryFiles}
                />
            </div>
        </div>
    </>);
}

export default connect()(UploadDocuments);
