import { FormikWizardStepType } from '../../../utils';
import { object, string, mixed, StringSchema, number } from 'yup';
import moment from 'moment';
import Account from './Account';
import Contact from './Contact';
import Info from './Info';

export default [
    {
        id: 'account',
        component: Account,
        name: 'Llogaria',
        description: 'Enter account data',
        initialValues: {
            tipKind: '3',
            clientno: '',
            nipt: '',
            title: '',
            lastName: '',
            sex: '',
            birthdate: ''
        },
        validationSchema: object().shape({
            tipKind: string().required('E domosdoshme'),
            clientno: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            nipt: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '2' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            title: string()
                .min(3, "Minimumi 3 simbole")
                .max(50, "Maksimumi 50 simbole")
                .required("E domosdoshme"),
            lastName: string()
                .when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                    return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
                }),
            sex: string(),
            birthdate: mixed().test(
                'validBirthDate',
                'Data e lindjes nuk mund te jete pas dates se sotshme',
                function (this: any, value: any) {
                    if (moment(value, 'dd/MM/yyyy').isValid())
                        return moment(value, 'dd/MM/yyyy').isBefore(moment());
                    return true;
                }),
        }),
        actionLabel: 'Vazhdo',
    },
    {
        id: 'contact',
        component: Contact,
        name: 'Kontakt',
        description: 'Ju lutem vendosni te dhenat e kontaktit me poshte',
        initialValues: {
            address: '',
            place: '',
            mob: '',
            email: ''
        },
        validationSchema: object().shape({
            address: string(),
            place: string().required(),
            mob: number().required('E domosdoshme').typeError("Lejohen vetem numrat"),
            email: string()
                .email("Email jo I vlefshem")
                .required("E domosdoshme"),
        }),
        actionLabel: 'Vazhdo',
    },
    {
        id: 'info',
        component: Info,
        name: 'Info',
        description: 'Check data and submit'
    },
] as FormikWizardStepType[];