import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import InputFieldMain from '../../Custom/InputFieldMain';
import DatePickerFieldMain from '../../Custom/DatePickerFieldMain';
import SelectField from '../../Custom/SelectField';
import { Gender } from '../../../utils/nomen';
import { AppState } from '../../../redux/reducers/rootReducer';
import { ThInsQuotation } from '../../../utils/policyTypes';
import { Loading } from '../../Layout/Loading';
import * as thActionTypes from "../../../redux/actionTypes/thPolicyActionTypes";
import * as generalActionTypes from "../../../redux/actionTypes/generalActionTypes";
import { PolicyMenu, User } from '../../../redux/types';

const Insured = () => {
    const thInsQuotation = useSelector<AppState, ThInsQuotation | null>((state: any) => state.policy.motorInsQuotation);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[thActionTypes.GET_TH_QUOTATION]);
    const isLoadingCheckFValid = useSelector<AppState, boolean>((state: any) => state.isLoading[generalActionTypes.CHECK_FVALID]);
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const currentScreen = useSelector<AppState, string>((state: any) => state.router.location.pathname);
    const subType = currentScreen.includes("student") ? "02" : "01";
    const policyItem = user?.policyList.find((p: PolicyMenu) => p.type === "05" && p.subtype === subType);
    const isAllowed = policyItem!.allowToSellForOthers;

    return (<>
        {isLoading || isLoadingCheckFValid
            ? <Loading />
            :
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                <div className="alert alert-custom alert-notice alert-light-primary fade show boxTotal" role="alert">
                    <div className="alert-icon"><i className="far fa-credit-card"></i></div>
                    <div className="alert-text">Total Primi + Taksa per Blerje Online <span className="spanTotal">{thInsQuotation?.totalV} €</span></div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="clientNo" label="Numri personal" placeholder="Numri personal" maxLength={16} newvalue={!isAllowed ? user?.clientno || '' : ''} />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="passport" label="Pasaporta" placeholder="Pasaporta" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="title" label="Emri" placeholder="Emri" newvalue={!isAllowed ? user?.name || '' : ''} capitalized />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="lastName" label="Mbiemri" placeholder="Mbiemri" newvalue={!isAllowed ? user?.surname || '' : ''} capitalized />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" newvalue={!isAllowed ? user?.address || '' : ''} />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="place" label="Vendi" placeholder="Vendi" newvalue={!isAllowed ? user?.place || '' : ''} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <SelectField name="sex" label="Gjinia" items={Gender} newvalue={!isAllowed ? user?.sex || '' : ''} />
                    </div>
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="celphone" label="Nr. telefonit" placeholder="Nr. telefonit" newvalue={!isAllowed ? user?.celphone || '' : ''} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="email" label="Email" placeholder="Email" newvalue={!isAllowed ? user?.username || '' : ''} />
                    </div>
                    <div className="col-xl-6 addSpace" >
                        <DatePickerFieldMain name="birthDate" type="text" placeholder="Datëlindja" newvalue={!isAllowed && !!user?.birthDate ? new Date(user?.birthDate) : ''} />
                    </div>
                </div>

            </div>}
    </>
    );
}
export default connect()(Insured);
