import { FormikWizardStepType } from '../../../../utils/wizardTypes';
import { object, string, date, bool, StringSchema } from 'yup';
import store from '../../../../redux/configureStore';
import * as tliActions from "../../../../redux/actionCreators/tliPolicyActionCreators";
import moment from 'moment';
import TermLifePolicyMain from './TermLifePolicyMain';
import Packages from './Packages';
import Verification from './Verification';
import { prepareCliPolicyForQuotation, validateQuestions } from '../../../../utils/policyTypes';
import * as generalActions from '../../../../redux/actionCreators/generalActionCreators';
import { CheckProfileDto } from '../../../../utils';
import InsuredPerson from './InsuredPerson';
import BeneficiaryPerson from './BeneficiaryPerson';

export default [
    {
        id: 'phMain',
        component: TermLifePolicyMain,
        name: 'Informata personale',
        description: '',
        initialValues: {
            insuredAge: '',
            firstAnswer: '',
            secondAnswer: '',
            thirdAnswer: '',
            couponAnswer: '',
            coupon: '',
            personalDataConsent: false
        },
        validationSchema: object().shape({
            insuredAge: string().required('E domosdoshme')
                .test(
                    'validAgeValue',
                    'Vlera duhet të jetë midis 20 dhe 65',
                    function (this: any, value: any) {
                        return value >= 20 && value <= 65;
                    }),
            coupon: string().when('couponAnswer', (couponAnswer: string, schema: StringSchema<string | undefined>) => {
                return couponAnswer === '1' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            personalDataConsent: bool().oneOf([true], 'E domosdoshme')
        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
            store.dispatch(tliActions.tliQuestionsReset());

            if (sectionValues.firstAnswer !== "2"
                || sectionValues.secondAnswer !== "2"
                || sectionValues.thirdAnswer !== "2") {

                store.dispatch(tliActions.tliQuestionsFailure());
                store.dispatch(generalActions.showModal("Informacion", "Bazuar në të dhënat që keni vendosur, është e pamundur që ne t'ju bëjmë një ofertë online. <br /> <br /> Ju lutemi na kontaktoni përmes email adresës info.life@uniqa-ks.com apo tel +383 38 240 241", "", true));
                return;
            }

            const state = store.getState();
            const customerId = state.auth.user?.customerId;

            const tliPolicy = prepareCliPolicyForQuotation(customerId, sectionValues);
            store.dispatch(tliActions.getTLIQuotation(tliPolicy));
        }
    },
    {
        id: 'package',
        component: Packages,
        name: 'Paketat',
        description: '',
        initialValues: {
        },
        validationSchema: object().shape({

        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
        }
    },
    {
        id: 'insuredPerson',
        component: InsuredPerson,
        name: 'I siguruari',
        description: '',
        initialValues: {
            clientNo: '',
            title: '',
            lastName: '',
            sex: '',
            address: '',
            place: '',
            email: '',
            sourceOfIncome: '',
            otherSources: '',
            celphone: '+383',
            birthDate: '',
            pep: '',
            idCardPhoto: []
        },
        validationSchema: object().shape({
            clientNo: string().required('E domosdoshme'),
            title: string().required('E domosdoshme'),
            lastName: string().required('E domosdoshme'),
            sex: string().required('E domosdoshme'),
            address: string().required('E domosdoshme'),
            place: string().required('E domosdoshme'),
            email: string()
                .email("Format I gabuar I email-it")
                .min(3, "Minimumi 3 simbole")
                .max(50, "Maksimumi 50 simbole")
                .required("E domosdoshme"),
            celphone: string().required('E domosdoshme').min(10, "Minimumi 10 simbole"),
            birthDate: date().required('E domosdoshme').typeError('E domosdoshme')
                .test(
                    'validBirthDate',
                    'Data e lindjes nuk mund të jetë pas ditës së sotme',
                    function (this: any, value: any) {
                        return moment(value, 'dd/MM/yyyy').isBefore(moment());
                    })
                .test(
                    'validBirthDateValue',
                    'Mosha duhet të jetë midis 20 dhe 65',
                    function (this: any, value: any) {
                        const age = moment().diff(value, 'years');
                        return age >= 20 && age <= 65;
                    }),
            pep: string().required('E domosdoshme'),
            sourceOfIncome: string().required('E domosdoshme'),
            otherSources: string().when('sourceOfIncome', (sourceOfIncome: string, schema: StringSchema<string | undefined>) => {
                return sourceOfIncome === 'Te tjera specifiko' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
            const state = store.getState();

            if (sectionValues.pep === '1') {
                const politicalFormQuestions = state.policy.politicalFormQuestions;
                if (!politicalFormQuestions) {
                    store.dispatch(generalActions.showModal("Dështimi", "Duhet të plotësoni formularin e identifikimit të personit të ekspozuar politikisht!", ""));
                    return;
                } else {
                    var validation = validateQuestions(politicalFormQuestions);
                    if (validation.errored) {
                        store.dispatch(generalActions.showModal("Dështimi", validation.message, ""));
                        return;
                    }
                }
            }

            if (sectionValues.idCardPhoto.length === 0) {
                store.dispatch(generalActions.showModal("Dështimi", "Duhet të ngarkoni një foto të kartës së identitetit!", ""));
                return;
            }

            const checkProfile = state.auth.checkProfile as CheckProfileDto;
            const user = state.auth.user;

            const continueWithoutAccount = state.general.continueWithoutAccount;
            if (continueWithoutAccount)
                return;

            if (!user && checkProfile.hasProfile && checkProfile.confirmedMail) {
                store.dispatch(generalActions.showLoginModal(sectionValues.email));
                return;
            }

            if (!user && checkProfile.hasProfile && !checkProfile.confirmedMail) {
                store.dispatch(generalActions.showVerificationModal());
                return;
            }

            if (!user && !checkProfile.hasProfile) {
                store.dispatch(generalActions.showCreateAccountQuestionModal(sectionValues));
                return;
            }

        }
    },
    {
        id: 'beneficiaryPerson',
        component: BeneficiaryPerson,
        name: 'Përfitues',
        description: '',
        initialValues: {
            clientNo: '',
            title: '',
            lastName: '',
            sex: '',
            // sourceOfIncome: '',
            // otherSources: '',
            address: '',
            place: '',
            email: '',
            celphone: '+383',
            birthDate: '',
            // idCardBeneficiaryPhoto: []
        },
        validationSchema: object().shape({
            clientNo: string().required('E domosdoshme'),
            title: string().required('E domosdoshme'),
            lastName: string().required('E domosdoshme'),
            sex: string().required('E domosdoshme'),
            address: string().required('E domosdoshme'),
            place: string().required('E domosdoshme'),
            email: string()
                .email("Format I gabuar I email-it")
                .min(3, "Minimumi 3 simbole")
                .max(50, "Maksimumi 50 simbole")
                .required("E domosdoshme"),
            celphone: string().required('E domosdoshme').min(10, "Minimumi 10 simbole"),
            birthDate: date().required('E domosdoshme').typeError('E domosdoshme')
                .test(
                    'validBirthDate',
                    'Data e lindjes nuk mund të jetë pas ditës së sotme',
                    function (this: any, value: any) {
                        return moment(value, 'dd/MM/yyyy').isBefore(moment());
                    })
                .test(
                    'validBirthDateValue',
                    'Mosha duhet të jetë midis 20 dhe 65',
                    function (this: any, value: any) {
                        const age = moment().diff(value, 'years');
                        return age >= 20 && age <= 65;
                    }),
            // sourceOfIncome: string().required('E domosdoshme'),
            // otherSources: string().when('sourceOfIncome', (sourceOfIncome: string, schema: StringSchema<string | undefined>) => {
            //     return sourceOfIncome === 'Te tjera specifiko' ? schema.required('E domosdoshme') : schema.notRequired();
            // }),
        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
            // if (sectionValues.idCardBeneficiaryPhoto.length === 0) {
            //     store.dispatch(generalActions.showModal("Dështimi", "Duhet të ngarkoni një foto të kartës së identitetit!", ""));
            //     return;
            // }
        }
    },
    {
        id: 'verification',
        component: Verification,
        name: 'Verifiko',
        description: '',
        initialValues: {
            acceptTerms: false,
            captchaGenerated: '',
            captchaInputted: ''
        },
        validationSchema: object().shape({
            acceptTerms: bool().oneOf([true], 'E domosdoshme'),
            captchaGenerated: string(),
            captchaInputted: string().required('E domosdoshme')
                .test(
                    'validCaptcha',
                    'Captcha eshte gabim',
                    function (this: any, value: any) {
                        if (value !== '')
                            return this.parent.captchaGenerated === value;

                        return true;
                    })
        }),
        actionLabel: 'Vazhdo',

    },
] as FormikWizardStepType[];
