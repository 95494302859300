import * as React from 'react';
import { connect } from 'react-redux';
import { DropzoneArea } from 'material-ui-dropzone'
import { useFormikContext } from 'formik';
import { IconButton, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { toAbsoluteUrl } from '../../../_metronic/_helpers';

const UploadDocuments = () => {
    const { values }: any = useFormikContext();

    const {
        setFieldValue,
    } = useFormikContext();

    const handleSave = (files: any) => {
        setFieldValue('policyFiles', files);
    }

    return (<>
        <div className="wizardTitle">Dokumente</div>
        <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
            <div>
                <DropzoneArea
                    onChange={handleSave}
                    onDelete={handleSave}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/jpeg']}
                    filesLimit={20}
                    maxFileSize={10485760}
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    showFileNamesInPreview={true}
                    showFileNames={true}
                    initialFiles={values.policyFiles}
                />
            </div>
            <div className="font-weight-bold text-dark">
                <br /> Dokumentat te cilat duhet te ngarkohen jane:     <br />
                <br />- Libreza e automjetit
                <br />- Leternjoftimi
                <br />- Patentshoferi
                <br />- Fotot e Automjetit
                <Tooltip title={
                    <React.Fragment>
                        <img
                            alt="Logo"
                            className="max-h-500px"
                            src={toAbsoluteUrl("/media/logos/Kasko template.png")}
                        />
                    </React.Fragment>
                }>
                    <IconButton>
                        <Info />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    </>);
}

export default connect()(UploadDocuments);
