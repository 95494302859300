import React from "react";
import { Switch } from "react-router-dom";
import { LandingPage } from "../components/Auth";

const Routes = (): JSX.Element => {
    return (
        <Switch>
            <LandingPage />
        </Switch>
    );
}

export default Routes;