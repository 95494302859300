import { BookingData, CliPolicy, Question, ThInsQuotation } from "../../utils/policyTypes";
import * as actions from "../actionTypes/cliPolicyActionTypes";

export function cliQuestionsFailure(
): actions.CLIQuestionsFailureAction {
    return {
        type: actions.CLI_QUESTIONS_FAILURE
    };
}

export function cliQuestionsReset(
): actions.CLIQuestionsResetAction {
    return {
        type: actions.CLI_QUESTIONS_RESET
    };
}

export function getCLIQuotation(
    policy: CliPolicy
): actions.GetCLIQuotationAction {
    return {
        type: actions.GET_CLI_QUOTATION,
        policy
    };
}

export function getCLIQuotationRequest(
): actions.GetCLIQuotationRequestAction {
    return {
        type: actions.GET_CLI_QUOTATION_REQUEST
    };
}

export function getCLIQuotationReset(
): actions.GetCLIQuotationResetAction {
    return {
        type: actions.GET_CLI_QUOTATION_RESET
    };
}

export function getCLIQuotationSuccess(
    values: ThInsQuotation
): actions.GetCLIQuotationSuccessAction {
    return {
        type: actions.GET_CLI_QUOTATION_SUCCESS,
        data: values
    };
}

export function getCLIQuotationFailure(
    error: Error | string
): actions.GetCLIQuotationFailureAction {
    return {
        type: actions.GET_CLI_QUOTATION_FAILURE,
        error
    };
}

export function getPackageCLIQuotation(
    policy: CliPolicy
): actions.GetPackageCLIQuotationAction {
    return {
        type: actions.GET_PACKAGE_CLI_QUOTATION,
        policy
    };
}

export function getPackageCLIQuotationRequest(
): actions.GetPackageCLIQuotationRequestAction {
    return {
        type: actions.GET_PACKAGE_CLI_QUOTATION_REQUEST
    };
}

export function getPackageCLIQuotationReset(
): actions.GetPackageCLIQuotationResetAction {
    return {
        type: actions.GET_PACKAGE_CLI_QUOTATION_RESET
    };
}

export function getPackageCLIQuotationSuccess(
    values: ThInsQuotation
): actions.GetPackageCLIQuotationSuccessAction {
    return {
        type: actions.GET_PACKAGE_CLI_QUOTATION_SUCCESS,
        data: values
    };
}

export function getPackageCLIQuotationFailure(
    error: Error | string
): actions.GetPackageCLIQuotationFailureAction {
    return {
        type: actions.GET_PACKAGE_CLI_QUOTATION_FAILURE,
        error
    };
}

export function bookCLIPolicy(
    policy: CliPolicy,
): actions.BookCLIPolicyAction {
    return {
        type: actions.BOOK_CLI_POLICY,
        policy
    };
}

export function bookCLIPolicyRequest(
): actions.BookCLIPolicyRequestAction {
    return {
        type: actions.BOOK_CLI_POLICY_REQUEST
    };
}

export function bookCLIPolicySuccess(
    values: BookingData
): actions.BookCLIPolicySuccessAction {
    return {
        type: actions.BOOK_CLI_POLICY_SUCCESS,
        data: values
    };
}

export function bookCLIPolicyFailure(
    error: Error | string
): actions.BookCLIPolicyFailureAction {
    return {
        type: actions.BOOK_CLI_POLICY_FAILURE,
        error
    };
}

export function showPoliticalFormModal(
    ): actions.ShowPoliticalFormModalModalAction {
        return {
            type: actions.SHOW_POLITICAL_FORM_MODAL
        };
    }
    
    export function hidePoliticalFormModal(
    ): actions.HidePoliticalFormModalModalAction {
        return {
            type: actions.HIDE_POLITICAL_FORM_MODAL,
        };
    }
    
    export function submitPoliticalForm(
        questions: Question[]
    ): actions.SubmitPoliticalFormAction {
        return {
            type: actions.SUBMIT_POLITICAL_FORM,
            data: questions
        };
    }
    
    export function resetPoliticalForm(
    ): actions.ResetPoliticalFormAction {
        return {
            type: actions.RESET_POLITICAL_FORM
        };
    }