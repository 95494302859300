import React, { useState } from "react";
import { connect, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { AppState } from '../../../../redux/reducers/rootReducer';
import * as nomenActionTypes from '../../../../redux/actionTypes/nomenActionTypes';
import { Loading } from "../../../Layout/Loading";
import { preparePrivateHealthPolicy, ThInsQuotation } from "../../../../utils/policyTypes";
import { useFormikWizard } from 'formik-wizard';
import { User } from "../../../../redux/types";
import InputFieldMain from "../../../Custom/InputFieldMain";
import SelectField from "../../../Custom/SelectField";
import DatePickerFieldInline from "../../../Custom/DatePickerFieldInline";
import { Gender } from '../../../../utils/nomen';
import moment from 'moment';
import store from "../../../../redux/configureStore";
import * as authActions from "../../../../redux/actionCreators/authActionCreators";
import { Nomen } from "../../../../utils";
import * as phActions from "../../../../redux/actionCreators/phPolicyActionCreators";
import { Tooltip } from "@material-ui/core";

const InsuredPerson = () => {
    const { values, setValues }: any = useFormikContext();
    const { values: wizardValues }: any = useFormikWizard();

    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[nomenActionTypes.GET_PLACES]);
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const chosenPHQuotation = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.chosenPHQuotation);
    const phPackageQuotation = chosenPHQuotation ? chosenPHQuotation[0] : null;
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);

    const recalculateQuotation = (date: any) => {
        const value = moment(date, "DD/MM/yyyy");
        if (value && value.isValid() && value.isBefore(moment())) {
            const state = store.getState();
            const customerId = state.auth.user?.customerId;

            const packageId = phPackageQuotation ? phPackageQuotation.packageId : '';
            wizardValues.insuredPerson.birthDate = value.toDate();

            const phPolicy = preparePrivateHealthPolicy(customerId, wizardValues, packageId);
            store.dispatch(phActions.getPackagePHQuotation(phPolicy));
        }
    }

    const checkProfile = (value: any) => {
        const customerId = user?.customerId;

        if (!customerId) {
            store.dispatch(authActions.hasProfile(value));
        }
    };

    return (<>
        {isLoading
            ? <Loading />
            : <>
                <div className="wizardTitle">I siguruari</div>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="clientNo" label="Numri personal" placeholder="Numri personal" maxLength={16} newvalue={values?.clientNo || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="title" label="Emri" placeholder="Emri" newvalue={values?.title || ''} inline capitalized />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="lastName" label="Mbiemri" placeholder="Mbiemri" newvalue={values?.lastName || ''} inline capitalized />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <SelectField name="sex" label="Gjinia" items={Gender} newvalue={values?.sex || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="address" label="Adresa" placeholder="Adresa" newvalue={values?.address || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <SelectField name="place" label="Vendi" items={places} newvalue={values?.place || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="email" blur={checkProfile} label="Email" placeholder="Email" newvalue={values?.email || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="celphone" label="Celulari" placeholder="Celulari" newvalue={values?.celphone || ''} inline />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12 addSpace">
                            <DatePickerFieldInline name="birthDate" type="text" placeholder="Datëlindja" blur={recalculateQuotation} newvalue={values?.birthDate || ''} />
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-xl-12 addSpace">
                            <InputFieldMain type="text" name="sourceOfIncome" label="Burim i të ardhurave" placeholder="Burim i të ardhurave" newvalue={values?.sourceOfIncome || ''} inline />
                        </div>
                    </div> */}

                    {/* <div className="row">
                        <div className="col-xl-12 addSpace">
                            <Tooltip
                                arrow
                                title="Personi i ekspozuar politikisht"
                                placement="top-end">
                                <span>
                                    <SelectField name="pep" label="PEP" items={Pep} newvalue={values?.pep || ''} inline />
                                </span>
                            </Tooltip>
                        </div>
                    </div> */}
                </div>
            </>
        }
    </>
    );
}
export default connect()(InsuredPerson);
