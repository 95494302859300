import React, { FunctionComponent } from 'react';
import { useField, useFormikContext } from 'formik';

const getFieldCSSClasses = (touched: boolean, error: string | undefined) => {
    const classes = ["form-control"];
    if (touched && error) {
        classes.push("is-invalid");
    }

    if (touched && !error) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

interface Props {
    name: string;
    type: string;
    placeholder: string;
    label: string;
    newvalue?: string;
    disabled?: boolean;
}

const InputAreaFieldMain: FunctionComponent<Props> = (props) => {
    const [
        field,
        { error, touched }
    ] = useField({
        name: props.name,
        type: props.type
    });

    const {
        setFieldValue,
      } = useFormikContext();
    
      React.useEffect(() => {
        if (!!props.newvalue) {
          setFieldValue(props.name, props.newvalue);
        }
      }, [setFieldValue, props.name, props.newvalue]);

    return (
        <div className="form-group">
            <label>{props.label}</label>
            <textarea rows={3} {...field} {...props} disabled={props.disabled} className={"form-control form-control-solid form-control-lg" + getFieldCSSClasses(touched, error)} />
            {touched && error !== "" ? (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{error}</div>
                </div>
            ) : null}
        </div>
    );
};

export default InputAreaFieldMain;