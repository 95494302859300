import { MotorInsQuotation, BookingData, MotorPolicy, ConfirmData } from '../../utils/policyTypes';
import * as actions from "../actionTypes/tplPolicyActionTypes";

export function getAMFTPLQuotation(
    customerId: string,
    platenumber: string,
    chassis: string,
    isCustomerVehicleOwner: boolean
): actions.GetAMFTPLQuotationAction {
    return {
        type: actions.GET_AMFTPL_QUOTATION,
        customerId, platenumber, chassis, isCustomerVehicleOwner
    };
}

export function getAMFTPLQuotationRequest(
): actions.GetAMFTPLQuotationRequestAction {
    return {
        type: actions.GET_AMFTPL_QUOTATION_REQUEST
    };
}

export function getAMFTPLQuotationReset(
): actions.GetAMFTPLQuotationResetAction {
    return {
        type: actions.GET_AMFTPL_QUOTATION_RESET
    };
}

export function getAMFTPLQuotationSuccess(
    values: MotorInsQuotation
): actions.GetAMFTPLQuotationSuccessAction {
    return {
        type: actions.GET_AMFTPL_QUOTATION_SUCCESS,
        data: values
    };
}

export function getAMFTPLQuotationFailure(
    error: Error | string
): actions.GetAMFTPLQuotationFailureAction {
    return {
        type: actions.GET_AMFTPL_QUOTATION_FAILURE,
        error
    };
}

export function getTPLQuotation(
    customerId: string,
    tplVehicleCategoryClass: string,
    prodyear: number,
    power: number
): actions.GetTplQuotationAction {
    return {
        type: actions.GET_TPL_QUOTATION,
        customerId, tplVehicleCategoryClass, prodyear, power
    };
}

export function getTPLQuotationRequest(
): actions.GetTplQuotationRequestAction {
    return {
        type: actions.GET_TPL_QUOTATION_REQUEST
    };
}

export function getTPLQuotationReset(
): actions.GetTplQuotationResetAction {
    return {
        type: actions.GET_TPL_QUOTATION_RESET
    };
}

export function getTPLQuotationSuccess(
    values: MotorInsQuotation
): actions.GetTplQuotationSuccessAction {
    return {
        type: actions.GET_TPL_QUOTATION_SUCCESS,
        data: values
    };
}

export function getTPLQuotationFailure(
    error: Error | string
): actions.GetTplQuotationFailureAction {
    return {
        type: actions.GET_TPL_QUOTATION_FAILURE,
        error
    };
}

export function bookTPLPolicy(
    policy: MotorPolicy,
    payment: string
): actions.BookTplPolicyAction {
    return {
        type: actions.BOOK_TPL_POLICY,
        policy,
        payment
    };
}

export function bookTPLPolicyRequest(
): actions.BookTplPolicyRequestAction {
    return {
        type: actions.BOOK_TPL_POLICY_REQUEST
    };
}

export function bookTPLPolicySuccess(
    values: BookingData
): actions.BookTplPolicySuccessAction {
    return {
        type: actions.BOOK_TPL_POLICY_SUCCESS,
        data: values
    };
}

export function bookTPLPolicyFailure(
    error: Error | string
): actions.BookTplPolicyFailureAction {
    return {
        type: actions.BOOK_TPL_POLICY_FAILURE,
        error
    };
}