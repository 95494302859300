import { Nomen } from './generalTypes';
import moment from 'moment';

export interface MotorInsQuotation {
    total: number;
    total1: number;
    total2: number;
    currency: string;
    vehicle: Vehicle;
    contractor: Person;
    insured1: Person;
    insured2: Person;
}

export interface MotorPolicy {
    customerId: string;
    startDate: string;
    vehicle: Vehicle;
    contractor: Person;
    insured1: Person;
    insured2: Person | null;
    insuredPeriod: string;
    deliveryAddress: string;
}

export interface ConfirmData {
    customerId: string | null;
    policyNumber: number;
    policyId: string;
    bankOrderId: string;
    lifePolicy: boolean;
}

export interface Vehicle {
    regnum: string;
    type_vehicle: Nomen;
    mark: Nomen;
    tip: string;
    chassis: string;
    prodyear: number;
    placenr: number;
    colour: string;
    capacity: number;
    power: number;
    used: string;
    vehiclelicence: string;
    vehicle_price: number;
    categoryClassTypeVehicle: Nomen;
}

export interface Person {
    tipkind: string;
    nipt: string;
    clientno: string;
    title: string;
    lastname: string;
    fathername: string;
    birthdate: string | null;
    email: string;
    celphone: string;
    passport: string;
    address: string;
    place: string;
    drivingLicenceCat: string;
    drivingLicenceNo: string;
    isForeigner: boolean;
    sex: Nomen;
}

export interface BookingData {
    total: number;
    currency: string;
    policyId: string;
    policyNumber: number;
    bankOrderId: string;
    lifePolicy: boolean;
}

export const preparePolicy = (customerId: string, formValues: any, sectionValues: any, insuredPeriod: string): MotorPolicy => {
    const startdate = formValues?.vehicleMain?.startdate || formValues?.vehicleDetails?.startdate;
    return {
        customerId: customerId,
        startDate: moment(startdate).format('YYYY-MM-DD'),
        vehicle: {
            regnum: formValues?.vehicleMain?.plateNumber || formValues?.vehicleDetails?.plateNumber || '',
            type_vehicle: { code: formValues.vehicleDetails.type_vehicle, name: "" },
            mark: { code: formValues.vehicleDetails.mark, name: "" },
            tip: formValues.vehicleDetails.tip,
            chassis: formValues?.vehicleMain?.chassis || formValues?.vehicleDetails?.chassis || '',
            prodyear: parseInt(formValues.vehicleDetails.prodYear),
            placenr: parseInt(formValues.vehicleDetails.placenr),
            colour: formValues.vehicleDetails.colour,
            capacity: parseInt(formValues.vehicleDetails.capacity),
            power: parseInt(formValues.vehicleDetails.power),
            used: formValues.vehicleDetails.used,
            vehiclelicence: formValues.vehicleDetails.vehicleLicence,
            vehicle_price: 0,
            categoryClassTypeVehicle: { code: formValues.vehicleDetails.categoryClassTypeVehicle, name: "" }
        },
        contractor: {
            tipkind: formValues.contractor.tipKind,
            nipt: formValues.contractor.nipt,
            clientno: formValues.contractor.clientNo,
            title: formValues.contractor.title,
            lastname: formValues.contractor.lastName,
            fathername: formValues.contractor.fatherName,
            birthdate: formValues.contractor.birthDate === '' || formValues.contractor.tipKind === '2' ? null : moment(formValues.contractor.birthDate).format('YYYY-MM-DD'),
            email: formValues.contractor.email,
            celphone: formValues.contractor.celphone,
            passport: "",
            address: formValues.contractor.address,
            place: formValues.contractor.place,
            drivingLicenceCat: "",
            drivingLicenceNo: "",
            isForeigner: false,
            sex: { code: formValues.contractor.sex, name: "" }
        },
        insured1: {
            tipkind: formValues.insured1.tipKind,
            nipt: formValues.insured1.nipt,
            clientno: formValues.insured1.clientNo,
            title: formValues.insured1.title,
            lastname: formValues.insured1.lastName,
            fathername: formValues.insured1.fatherName,
            birthdate: formValues.insured1.birthDate === '' || formValues.insured1.tipKind === '2' ? null : moment(formValues.insured1.birthDate).format('YYYY-MM-DD'),
            email: formValues.insured1.email,
            celphone: formValues.insured1.celphone,
            passport: formValues.insured1.passport,
            address: formValues.insured1.address,
            place: formValues.insured1.place,
            drivingLicenceCat: formValues.insured1.drivingLicenceCat,
            drivingLicenceNo: formValues.insured1.drivingLicenceNo,
            isForeigner: false,
            sex: { code: formValues.insured1.sex, name: "" }
        },
        insured2: formValues.insured2.hasSecondInsurer
            ? {
                tipkind: formValues.insured2.tipKind,
                nipt: formValues.insured2.nipt,
                clientno: formValues.insured2.clientNo,
                title: formValues.insured2.title,
                lastname: formValues.insured2.lastName,
                fathername: formValues.insured2.fatherName,
                birthdate: formValues.insured2.birthDate === '' || formValues.insured2.tipKind === '2' ? null : moment(formValues.insured2.birthDate).format('YYYY-MM-DD'),
                email: formValues.insured2.email,
                celphone: formValues.insured2.celphone,
                passport: formValues.insured2.passport,
                address: formValues.insured2.address,
                place: formValues.insured2.place,
                drivingLicenceCat: formValues.insured2.drivingLicenceCat,
                drivingLicenceNo: formValues.insured2.drivingLicenceNo,
                isForeigner: formValues.insured2.isForeigner === "false",
                sex: { code: formValues.insured2.sex, name: "" }
            }
            : null,
        insuredPeriod: insuredPeriod,
        deliveryAddress: sectionValues.typeDelivery === "1" ? sectionValues.deliveryAddressFil : sectionValues.deliveryAddressHome
    }
}

export interface ThPolicy {
    customerId: string;
    startDate: string;
    endDate: string;
    zone: Nomen;
    insuredPersons: number;
    insuredPersonsList: Person[];
    packageId: string | null;
    passports: any[];
    coupon: string | null;
    country: Nomen;
}

export interface ThInsQuotation {
    packageId: string;
    packageName: string;
    currencyCode: string;
    currencyName: string;
    totalV: string;
    totalVNoDiscount: number;
    insuredAmountV: string;
    packageDiscount: number;
    covers: string[]
    kaskoCovers: Cover[];
}

export interface PhPolicy {
    customerId: string;
    insuredAge: number;
    coupon: string | null;
    insuredPerson: any;
    packageId: string | null;
    politicalFormQuestions: Question[] | null
}

export interface CliPolicy {
    customerId: string;
    loanAmount: string | null;
    insuredAge: number;
    coupon: string | null;
    insuredPerson: any;
    packageId: string | null;
    subtypePolicy: string;
    politicalFormQuestions: Question[] | null
}

export interface PiPolicy {
    customerId: string;
    startDate: string | null;
    objectType: Nomen;
    area: string;
    buildYear: number;
    objectAddress: string;
    insuredPerson: any | null;
    packageId: string | null;
    coupon: string | null;
    insuredPersons: number | null;
    includeAccident: boolean | false;
    inventoryInsuredAmount: number | null;
    objectInventoryFiles: any[];
}

export interface AifPolicy {
    customerId: string;
    startDate: string | null;
    insuredPerson: any | null;
    packageId: string | null;
    coupon: string | null;
    insurersPassportFiles: any[];
}

export interface KaskoPolicy {
    customerId: string;
    startDate: string | null;
    kaskoCovers: Cover[];
    vehicleInsuredAmount: string;
    insuredPersonsList: Person[];
    packageId: string | null;
    coupon: string | null;
    vehicle: Vehicle | null;
}

export interface Cover {
    code: string;
    name: string;
    required: boolean;
    selected: boolean;
};

export interface Question {
    listing: string;
    text: string;
    value: string;
    type: number;
    selected: boolean;
};

export const prepareThPolicyForQuotation = (customerId: string, sectionValues: any, packageId?: string): ThPolicy => {
    return {
        customerId: customerId ? customerId : '',
        startDate: moment(sectionValues.startDate).format('YYYY-MM-DD'),
        endDate: moment(sectionValues.endDate).format('YYYY-MM-DD'),
        zone: { code: sectionValues.zone, name: "" },
        country: { code: sectionValues.countr, name: "" },
        packageId: sectionValues.packageId ? sectionValues.packageId : packageId,
        coupon: '',
        insuredPersons: parseInt(sectionValues.insuredPersons),
        insuredPersonsList: !packageId ? [] : sectionValues.insuredPersonsList && sectionValues.insuredPersonsList.length > 0
            ? sectionValues.insuredPersonsList.map((insuredPerson: any, index: number) => {
                return {
                    tipkind: "3",
                    nipt: null,
                    clientno: insuredPerson.clientNo,
                    title: insuredPerson.title,
                    lastname: insuredPerson.lastName,
                    fathername: "",
                    birthdate: moment(insuredPerson.birthDate).format('YYYY-MM-DD'),
                    email: insuredPerson.email,
                    celphone: insuredPerson.celphone,
                    passport: insuredPerson.passport,
                    address: insuredPerson.address,
                    place: insuredPerson.place,
                    sex: {
                        "Code": insuredPerson.sex
                    }
                }
            }) : [],
        passports: []
    }
}

export const prepareThPolicy = (customerId: string, formValues: any, packageId: string | null): ThPolicy => {
    const result = {
        customerId: customerId ? customerId : '',
        startDate: moment(formValues.thMain.startDate).format('YYYY-MM-DD'),
        endDate: moment(formValues.thMain.endDate).format('YYYY-MM-DD'),
        zone: { code: formValues.thMain.zone, name: "" },
        country: { code: formValues.thMain.country, name: "" },
        packageId: packageId,
        coupon: formValues.thMain.coupon,
        insuredPersons: formValues.insertTravelers.travellers.length,
        insuredPersonsList: formValues.insertTravelers.travellers && formValues.insertTravelers.travellers.length > 0
            ? formValues.insertTravelers.travellers.map((insuredPerson: any, index: number) => {
                return {
                    tipkind: "3",
                    nipt: null,
                    clientno: insuredPerson.clientNo,
                    title: insuredPerson.title,
                    lastname: insuredPerson.lastName,
                    fathername: "",
                    birthdate: moment(insuredPerson.birthDate).format('YYYY-MM-DD'),
                    email: insuredPerson.email,
                    celphone: insuredPerson.celphone,
                    passport: insuredPerson.passport,
                    address: insuredPerson.address,
                    place: insuredPerson.place,
                    sex: {
                        "Code": insuredPerson.sex
                    }
                }
            }) : [],
        passports: getRenamedPassports(formValues.insertTravelers.travellers)
    }

    return result;
}

export const preparePhPolicyForQuotation = (customerId: string, sectionValues: any): PhPolicy => {
    const result = {
        customerId: customerId ? customerId : '',
        insuredAge: sectionValues.insuredAge,
        coupon: '',
        packageId: '',
        insuredPerson: null,
        politicalFormQuestions: null
    };

    return result;
}

export const preparePhPolicy = (customerId: string, formValues: any, packageId?: string, politicalFormQuestions?: any): PhPolicy => {
    const result = {
        customerId: customerId ? customerId : '',
        insuredAge: formValues.phMain.insuredAge,
        coupon: formValues.phMain.coupon,
        packageId: packageId ? packageId : '',
        insuredPerson: formValues.insuredPerson && moment(formValues.insuredPerson.birthDate, "dd/MM/yyyy").isValid() ? {
            tipkind: "3",
            nipt: null,
            clientno: formValues.insuredPerson.clientNo,
            title: formValues.insuredPerson.title,
            lastname: formValues.insuredPerson.lastName,
            fathername: "",
            birthdate: moment(formValues.insuredPerson.birthDate).format('YYYY-MM-DD'),
            email: formValues.insuredPerson.email,
            celphone: formValues.insuredPerson.celphone,
            passport: formValues.insuredPerson.passport ? formValues.insuredPerson.passport : '',
            address: formValues.insuredPerson.address,
            place: formValues.insuredPerson.place,
            sex: {
                "Code": formValues.insuredPerson.sex
            },
            pep: {
                "Code": formValues.insuredPerson.pep
            },
            sourceOfIncome: formValues.insuredPerson.sourceOfIncome ? formValues.insuredPerson.sourceOfIncome : '',
            otherSources: formValues.insuredPerson.otherSources,
        } : null,
        politicalFormQuestions: politicalFormQuestions && politicalFormQuestions.length > 0
            ? politicalFormQuestions.filter((x: Question) => x.type !== 0)
            : null,
        idCardPhoto: [...formValues.insuredPerson.idCardPhoto ],
        beneficiary: formValues.beneficiaryPerson && moment(formValues.beneficiaryPerson.birthDate, "dd/MM/yyyy").isValid() ? {
            tipkind: "3",
            nipt: null,
            clientno: formValues.beneficiaryPerson.clientNo,
            title: formValues.beneficiaryPerson.title,
            lastname: formValues.beneficiaryPerson.lastName,
            fathername: "",
            birthdate: moment(formValues.beneficiaryPerson.birthDate).format('YYYY-MM-DD'),
            email: formValues.beneficiaryPerson.email,
            celphone: formValues.beneficiaryPerson.celphone,
            passport: formValues.beneficiaryPerson.passport ? formValues.beneficiaryPerson.passport : '',
            address: formValues.beneficiaryPerson.address,
            place: formValues.beneficiaryPerson.place,
            sex: {
                "Code": formValues.beneficiaryPerson.sex
            },
            // sourceOfIncome: formValues.beneficiaryPerson.sourceOfIncome ? formValues.beneficiaryPerson.sourceOfIncome : '',
            // otherSources: formValues.beneficiaryPerson.otherSources,
        } : null,
    };

    return result;
}

export const preparePrivateHealthPolicy = (customerId: string, formValues: any, packageId?: string, politicalFormQuestions?: any): PhPolicy => {
    const result = {
        customerId: customerId ? customerId : '',
        insuredAge: formValues.phMain.insuredAge,
        coupon: formValues.phMain.coupon,
        packageId: packageId ? packageId : '',
        insuredPerson: formValues.insuredPerson && moment(formValues.insuredPerson.birthDate, "dd/MM/yyyy").isValid() ? {
            tipkind: "3",
            nipt: null,
            clientno: formValues.insuredPerson.clientNo,
            title: formValues.insuredPerson.title,
            lastname: formValues.insuredPerson.lastName,
            fathername: "",
            birthdate: moment(formValues.insuredPerson.birthDate).format('YYYY-MM-DD'),
            email: formValues.insuredPerson.email,
            celphone: formValues.insuredPerson.celphone,
            passport: formValues.insuredPerson.passport ? formValues.insuredPerson.passport : '',
            address: formValues.insuredPerson.address,
            place: formValues.insuredPerson.place,
            sex: {
                "Code": formValues.insuredPerson.sex
            },
        } : null,
        politicalFormQuestions: politicalFormQuestions && politicalFormQuestions.length > 0
            ? politicalFormQuestions.filter((x: Question) => x.type !== 0)
            : null,
        idCardPhoto: [],
        beneficiary: formValues.beneficiaryPerson && moment(formValues.beneficiaryPerson.birthDate, "dd/MM/yyyy").isValid() ? {
            tipkind: "3",
            nipt: null,
            clientno: formValues.beneficiaryPerson.clientNo,
            title: formValues.beneficiaryPerson.title,
            lastname: formValues.beneficiaryPerson.lastName,
            fathername: "",
            birthdate: moment(formValues.beneficiaryPerson.birthDate).format('YYYY-MM-DD'),
            email: formValues.beneficiaryPerson.email,
            celphone: formValues.beneficiaryPerson.celphone,
            passport: formValues.beneficiaryPerson.passport ? formValues.beneficiaryPerson.passport : '',
            address: formValues.beneficiaryPerson.address,
            place: formValues.beneficiaryPerson.place,
            sex: {
                "Code": formValues.beneficiaryPerson.sex
            },
            // sourceOfIncome: formValues.beneficiaryPerson.sourceOfIncome ? formValues.beneficiaryPerson.sourceOfIncome : '',
            // otherSources: formValues.beneficiaryPerson.otherSources,
        } : null,
    };

    return result;
}

export const prepareCliPolicyForQuotation = (customerId: string, sectionValues: any): CliPolicy => {
    const result = {
        customerId: customerId ? customerId : '',
        loanAmount: sectionValues.loanAmount,
        insuredAge: sectionValues.insuredAge,
        coupon: sectionValues.coupon || '',
        packageId: '',
        subtypePolicy: sectionValues.subtypePolicy ? sectionValues.subtypePolicy : '01',
        insuredPerson: null,
        politicalFormQuestions: null,
        loanId: '',
        loanPeriodFrom: null,
        loanPeriodTo: null
    };

    return result;
}

export const prepareCliPolicy = (customerId: string, formValues: any, packageId?: string, politicalFormQuestions?: any): CliPolicy => {
    const result = {
        customerId: customerId ? customerId : '',
        loanAmount: formValues.lifeMain.loanAmount,
        insuredAge: formValues.lifeMain.insuredAge,
        coupon: formValues.lifeMain.coupon,
        packageId: packageId ? packageId : '',
        subtypePolicy: formValues.lifeMain.subtypePolicy ? formValues.lifeMain.subtypePolicy : '01',
        insuredPerson: formValues.insuredPerson && moment(formValues.insuredPerson.birthDate, "dd/MM/yyyy").isValid() ? {
            tipkind: "3",
            nipt: null,
            clientno: formValues.insuredPerson.clientNo,
            title: formValues.insuredPerson.title,
            lastname: formValues.insuredPerson.lastName,
            fathername: "",
            birthdate: moment(formValues.insuredPerson.birthDate).format('YYYY-MM-DD'),
            email: formValues.insuredPerson.email,
            celphone: formValues.insuredPerson.celphone,
            passport: formValues.insuredPerson.passport,
            address: formValues.insuredPerson.address,
            place: formValues.insuredPerson.place,
            sex: {
                "Code": formValues.insuredPerson.sex
            },
            sourceOfIncome: formValues.insuredPerson.sourceOfIncome,
            otherSources: formValues.insuredPerson.otherSources,
            bank: {
                "Code": formValues.insuredPerson.bank
            },
            pep: {
                "Code": formValues.insuredPerson.pep
            }
        } : null,
        politicalFormQuestions: politicalFormQuestions && politicalFormQuestions.length > 0
            ? politicalFormQuestions.filter((x: Question) => x.type !== 0)
            : null,
        loanId: formValues.insuredPerson.loanId,
        loanPeriodFrom: formValues.insuredPerson.loanPeriodFrom ? moment(formValues.insuredPerson.loanPeriodFrom).format('YYYY-MM-DD') : null,
        loanPeriodTo: formValues.insuredPerson.loanPeriodTo ? moment(formValues.insuredPerson.loanPeriodTo).format('YYYY-MM-DD') : null,
        policyFiles: getRenamedPolicyFiles(formValues.uploadDocuments.idCardFiles, formValues.uploadDocuments.loanFiles)
    };

    return result;
}

export const getRenamedPolicyFiles = (idCardFiles: any, loanFiles: any): any[] => {
    if (!idCardFiles) {
        return []
    }

    const renamed: any[] = [];

    idCardFiles.forEach((idFile: any, index: number) => {
        const extension = idFile.name.split('.')[1];
        const newName = "IdCard" + '.' + extension;

        var renamedIdFile = new File([idFile], newName, { lastModified: new Date().getTime(), type: idFile.type })
        renamed.push(renamedIdFile);
    });

    loanFiles.forEach((loanFile: any, index: number) => {
        const extension = loanFile.name.split('.')[1];
        const newName = "LoanFile" + '.' + extension;

        var renamedLoanFile = new File([loanFile], newName, { lastModified: new Date().getTime(), type: loanFile.type })
        renamed.push(renamedLoanFile);
    });

    return renamed;
}

export const getRenamedPassports = (travellers: any): any[] => {
    if (!travellers) {
        return []
    }

    const renamed: any[] = [];

    travellers.forEach((traveller: any, index: number) => {
        const passport = traveller.passportPhoto[0];
        const extension = passport.name.split('.')[1];
        const newName = index + '.' + extension;

        var renamedPhoto = new File([passport], newName, { lastModified: new Date().getTime(), type: passport.type })
        renamed.push(renamedPhoto);
    });

    return renamed;
}

export const preparePiPolicyForQuotation = (customerId: string, sectionValues: any, insuredPersons?: any, includeAccident?: any, packageId?: any): PiPolicy => {
    const result = {
        customerId: customerId ? customerId : '',
        startDate: null,
        objectType: { code: sectionValues.objectType, name: "" },
        area: sectionValues.area,
        buildYear: sectionValues.buildYear,
        objectAddress: '',
        coupon: '',
        packageId: packageId ? packageId : '',
        insuredPersons: insuredPersons ? parseInt(insuredPersons) : 0,
        includeAccident: false,// includeAccident ? includeAccident === '1' : false,
        inventoryInsuredAmount: sectionValues.inventoryInsuredAmount !== 'Jo' ? parseInt(sectionValues.inventoryInsuredAmount) : 0,
        insuredPerson: null,
        objectInventoryFiles: []
    };

    return result;
}

export const preparePiPolicy = (customerId: string, formValues: any, packageId?: string): PiPolicy => {
    const result = {
        customerId: customerId ? customerId : '',
        startDate: null,
        objectType: { code: formValues.piMain.objectType, name: "" },
        area: formValues.piMain.area,
        buildYear: formValues.piMain.buildYear,
        objectAddress: formValues.piMain.objectAddress,
        coupon: formValues.piMain.coupon,
        packageId: packageId ? packageId : '',
        insuredPersons: 0,
        // (formValues.includeAccident.includeAccident === '1' && formValues.includeAccident.insuredPersons)
        //     ? parseInt(formValues.includeAccident.insuredPersons)
        // : 0,
        includeAccident: false,//formValues.includeAccident.includeAccident === '1',
        inventoryInsuredAmount: formValues.piMain.inventoryInsuredAmount !== 'Jo' ? parseInt(formValues.piMain.inventoryInsuredAmount) : 0,
        insuredPerson: formValues.insuredPerson && ((formValues.insuredPerson.tipKind === '2') || (formValues.insuredPerson.tipKind === '3' && moment(formValues.insuredPerson.birthDate, "dd/MM/yyyy").isValid())) ? {
            tipkind: formValues.insuredPerson.tipKind,
            nipt: formValues.insuredPerson.tipKind === '2' ? formValues.insuredPerson.nipt : '',
            clientno: formValues.insuredPerson.tipKind === '3' ? formValues.insuredPerson.clientNo : '',
            title: formValues.insuredPerson.title,
            lastname: formValues.insuredPerson.lastName,
            fathername: "",
            birthdate: formValues.insuredPerson.tipKind === '3' ? moment(formValues.insuredPerson.birthDate).format('YYYY-MM-DD') : null,
            email: formValues.insuredPerson.email,
            celphone: formValues.insuredPerson.celphone,
            passport: formValues.insuredPerson.passport,
            address: formValues.insuredPerson.address,
            place: formValues.insuredPerson.place,
            sex: {
                "Code": formValues.insuredPerson.sex
            }
        } : null,
        objectInventoryFiles: formValues.uploadDocuments.objectInventoryFiles
    };

    return result;
}

export const prepareAifPolicyForQuotation = (customerId: string): AifPolicy => {
    const result = {
        customerId: customerId ? customerId : '',
        startDate: null,
        coupon: '',
        packageId: '',
        insuredPerson: null,
        insurersPassportFiles: []
    };

    return result;
}

export const prepareAifPolicy = (customerId: string, formValues: any, packageId?: string): AifPolicy => {
    const result = {
        customerId: customerId ? customerId : '',
        startDate: null,
        coupon: formValues.insuredPerson ? formValues.insuredPerson.coupon : '',
        packageId: packageId ? packageId : '',
        insuredPerson: formValues.insuredPerson && moment(formValues.insuredPerson.birthDate, "dd/MM/yyyy").isValid() ? {
            tipkind: "3",
            nipt: null,
            clientno: formValues.insuredPerson.clientNo,
            title: formValues.insuredPerson.title,
            lastname: formValues.insuredPerson.lastName,
            fathername: "",
            birthdate: moment(formValues.insuredPerson.birthDate).format('YYYY-MM-DD'),
            email: formValues.insuredPerson.email,
            celphone: formValues.insuredPerson.celphone,
            passport: formValues.insuredPerson.passport,
            address: formValues.insuredPerson.address,
            place: formValues.insuredPerson.place,
            sex: {
                "Code": formValues.insuredPerson.sex
            }
        } : null,
        insurersPassportFiles: formValues.insuredPerson ? formValues.insuredPerson.passportPhoto : []
    };

    return result;
}

export const prepareKaskoPolicyForQuotation = (customerId: string, sectionValues: any, packageId?: string): KaskoPolicy => {
    const result = {
        customerId: customerId ? customerId : '',
        packageId: packageId ? packageId : '',
        startDate: null,
        vehicleInsuredAmount: sectionValues.vehicleInsuredAmount,
        kaskoCovers: sectionValues.kaskoCovers ? sectionValues.kaskoCovers : [],
        coupon: '',
        insuredPersonsList: [],
        vehicle: null,
        policyFiles: []
    };

    return result;
}

export const prepareKaskoPolicy = (customerId: string, formValues: any, packageId?: string): KaskoPolicy => {
    const result = {
        customerId: customerId ? customerId : '',
        packageId: packageId ? packageId : '',
        startDate: null,
        vehicleInsuredAmount: formValues.kaskoMain.vehicleInsuredAmount,
        kaskoCovers: formValues.kaskoMain.kaskoCovers ? formValues.kaskoMain.kaskoCovers : [],
        coupon: formValues.kaskoMain.coupon,
        insuredPersonsList: formValues.insertDrivers.drivers && formValues.insertDrivers.drivers.length > 0
            ? formValues.insertDrivers.drivers.map((driver: any, index: number) => {
                return {
                    tipkind: driver.tipKind,
                    clientno: driver.clientNo,
                    nipt: driver.nipt,
                    title: driver.title,
                    lastname: driver.lastName,
                    sex: {
                        "Code": driver.sex
                    },
                    address: driver.address,
                    place: driver.place,
                    email: driver.email,
                    celphone: driver.celphone,
                    drivingLicenceNo: driver.drivingLicenceNo,
                    birthdate: driver.tipKind === '3' ? moment(driver.birthDate).format('YYYY-MM-DD') : null
                }
            }) : [],
        vehicle: {
            regnum: formValues.insuredVehicle.regnum || '',
            chassis: formValues.insuredVehicle.chassis || '',
            type_vehicle: { code: formValues.insuredVehicle.type_vehicle, name: '' },
            mark: { code: formValues.insuredVehicle.mark, name: '' },
            tip: formValues.insuredVehicle.tip,
            power: parseInt(formValues.insuredVehicle.power),
            prodyear: parseInt(formValues.insuredVehicle.prodYear),
            placenr: parseInt(formValues.insuredVehicle.placenr),
            capacity: parseInt(formValues.insuredVehicle.capacity),
            colour: formValues.insuredVehicle.colour,
            volume: parseInt(formValues.insuredVehicle.volume),
            vehiclelicence: formValues.insuredVehicle.vehicleLicence,
            used: '',
            vehicle_price: 0,
            categoryClassTypeVehicle: { code: '', name: '' }
        },
        policyFiles: formValues.uploadDocuments.policyFiles
    };

    return result;
}

export function getInputField(name: string, value: string, type: string) {
    var input = document.createElement("input");
    input.setAttribute("value", value);
    input.setAttribute("name", name);
    input.setAttribute("type", "hidden");

    return input;
}

//to do make appropriate type
export function getPaymentReadyForm(paymentData: any) {
    let formElement = document.createElement("form");
    formElement.setAttribute("id", "PostForm");
    formElement.setAttribute("method", "POST");
    formElement.setAttribute("action", "" + paymentData.url + "");

    formElement.appendChild(getInputField("clientid", paymentData.clientId, "hidden"));
    formElement.appendChild(getInputField("storetype", paymentData.storetype, "hidden")); //"3d"
    formElement.appendChild(getInputField("currency", paymentData.currency, "hidden"));
    formElement.appendChild(getInputField("okUrl", paymentData.okUrl, "hidden"));
    formElement.appendChild(getInputField("failUrl", paymentData.failUrl, "hidden"));
    formElement.appendChild(getInputField("oid", paymentData.oid, "hidden"));
    formElement.appendChild(getInputField("amount", paymentData.amount, "hidden"));
    formElement.appendChild(getInputField("trantype", paymentData.txnType, "hidden"));
    formElement.appendChild(getInputField("lang", paymentData.lang, "hidden"));
    formElement.appendChild(getInputField("rnd", paymentData.req_rnd, "hidden"));
    formElement.appendChild(getInputField("hash", paymentData.req_hash, "hidden"));
    formElement.appendChild(getInputField("refreshtime", "0", "hidden"));
    formElement.appendChild(getInputField("encoding", "utf-8", "hidden"));

    let subm = document.createElement("input");
    subm.setAttribute("type", "submit");
    formElement.appendChild(subm);
    document.body.appendChild(formElement);

    return formElement;
}

export function getUPCPaymentReadyForm(paymentData: any) {
    let formElement = document.createElement("form");
    formElement.setAttribute("id", "PostForm");
    formElement.setAttribute("method", "POST");
    formElement.setAttribute("action", "" + paymentData.url + "");

    formElement.appendChild(getInputField("Version", paymentData.version, "hidden"));
    formElement.appendChild(getInputField("MerchantID", paymentData.merchantID, "hidden"));
    formElement.appendChild(getInputField("TerminalID", paymentData.terminalID, "hidden"));
    formElement.appendChild(getInputField("TotalAmount", paymentData.amount, "hidden"));
    formElement.appendChild(getInputField("Currency", paymentData.currency, "hidden"));
    formElement.appendChild(getInputField("locale", paymentData.lang, "hidden"));
    formElement.appendChild(getInputField("PurchaseTime", paymentData.purchaseTime, "hidden"));
    formElement.appendChild(getInputField("OrderID", paymentData.orderID, "hidden"));
    formElement.appendChild(getInputField("PurchaseDesc", paymentData.purchaseDesc, "hidden"));
    formElement.appendChild(getInputField("Signature", paymentData.signature, "hidden"));
    // formElement.appendChild(getInputField("encoding", "utf-8", "hidden"));

    let subm = document.createElement("input");
    subm.setAttribute("type", "submit");
    formElement.appendChild(subm);
    document.body.appendChild(formElement);

    return formElement;
}

const travellerLabels = {
    'TipKind': 'TipKind',
    'ClientNo': 'Numri personal',
    'Nipt': 'Nipti',
    'Title': 'Emri',
    'LastName': 'Mbiemri',
    'Sex': 'Gjinia',
    'Address': 'Adresa',
    'Place': 'Vendi',
    'Email': 'Email',
    'Celphone': 'Nr. telefonit',
    'BirthDate': 'Datëlindja',
    'Passport': 'Pasaporta',
    'PassportPhoto': 'Foto e pasaportës'
}

export const validateTraveller = (traveller: any, travellers: any) => {
    var message: string = "Mungon fushë e domosdoshme ";
    var errored = false;
    if (traveller.clientNo === '') {
        message += " - " + travellerLabels['ClientNo'] as string + " - ";
        errored = true;
    }
    if (traveller.title === '') {
        message += " - " + travellerLabels['Title'] as string + " - ";
        errored = true;
    }
    if (traveller.lastName === '') {
        message += " - " + travellerLabels['LastName'] as string + " - ";
        errored = true;
    }
    if (traveller.sex === '') {
        message += " - " + travellerLabels['Sex'] as string + " - ";
        errored = true;
    }
    if (traveller.address === '') {
        message += " - " + travellerLabels['Address'] as string + " - ";
        errored = true;
    }
    if (traveller.place === '') {
        message += " - " + travellerLabels['Place'] as string + " - ";
        errored = true;
    }
    if (traveller.email === '') {
        message += " - " + travellerLabels['Email'] as string + " - ";
        errored = true;
    }
    if (traveller.celphone === '' || traveller.celphone.length < 10) {
        message += " - " + travellerLabels['Celphone'] as string + " - ";
        errored = true;
    }
    if (traveller.birthDate === '') {
        message += " - " + travellerLabels['BirthDate'] as string + " - ";
        errored = true;
    }

    if (traveller.passport === '') {
        message += " - " + travellerLabels['Passport'] as string + " - ";
        errored = true;
    }
    if (traveller.passportPhoto.length === 0) {
        message += " - " + travellerLabels['PassportPhoto'] as string + " - ";
        errored = true;
    }

    if (travellers && travellers.filter((t: any) => t.clientNo === traveller.clientNo).length > 1) {
        message += " - Udhëtari me atë ID personale ekziston tashm - ";
        errored = true;
    }

    if (moment(traveller.birthDate, "dd/MM/yyyy").isValid()
        && moment(traveller.birthDate, 'dd/MM/yyyy').isBefore(moment())
        && moment().diff(traveller.birthDate, 'years') > 68) {

        message += "- Të gjitha primet janë për personat nën 68 vjeç. Personat mbi moshën 68 vjeçare duhet të paraqiten në zyrat e SIGAL-it për t\'u pajisur me policën e sigurimit Shëndetit në udhëtim - ";
        errored = true;
    }

    return { message, errored };
}

export const validateDriver = (driver: any, drivers: any) => {
    var message: string = "Mungon fushë e domosdoshme ";
    var errored = false;

    if (driver.tipKind === '') {
        message += " - " + travellerLabels['TipKind'] as string + " - ";
        errored = true;
    }
    if (driver.tipKind === '3') {
        if (driver.clientNo === '') {
            message += " - " + travellerLabels['ClientNo'] as string + " - ";
            errored = true;
        }
        if (driver.lastName === '') {
            message += " - " + travellerLabels['LastName'] as string + " - ";
            errored = true;
        }
        if (driver.sex === '') {
            message += " - " + travellerLabels['Sex'] as string + " - ";
            errored = true;
        }
        if (driver.birthDate === '') {
            message += " - " + travellerLabels['BirthDate'] as string + " - ";
            errored = true;
        }
    }
    if (driver.tipKind === '2' && driver.nipt === '') {
        message += " - " + travellerLabels['Nipt'] as string + " - ";
        errored = true;
    }
    if (driver.title === '') {
        message += " - " + travellerLabels['Title'] as string + " - ";
        errored = true;
    }
    if (driver.address === '') {
        message += " - " + travellerLabels['Address'] as string + " - ";
        errored = true;
    }
    if (driver.place === '') {
        message += " - " + travellerLabels['Place'] as string + " - ";
        errored = true;
    }
    if (driver.email === '') {
        message += " - " + travellerLabels['Email'] as string + " - ";
        errored = true;
    }
    if (driver.celphone === '' || driver.celphone.length < 10) {
        message += " - " + travellerLabels['Celphone'] as string + " - ";
        errored = true;
    }
    if (drivers && drivers.filter((t: any) => t.clientNo === driver.clientNo).length > 1) {
        message += " - Shoferi me atë ID personale ekziston tashmë";
        errored = true;
    }

    return { message, errored };
}

export const validateQuestions = (questions: Question[]) => {
    var errored = false;
    var message = '';

    if (questions.filter((x: Question) => x.type === 1 && !x.value).length > 0) {
        errored = true;
        message = "Ju duhet t'i përgjigjeni të gjitha pyetjeve me përgjigje të shumta të mundshme!";
    }

    return { message, errored };
}