import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import InputFieldMain from '../../../Custom/InputFieldMain';
import DatePickerFieldMain from '../../../Custom/DatePickerFieldMain';
import CheckboxField from '../../../Custom/CheckboxField';
import { AppState } from '../../../../redux/reducers/rootReducer';

const HealthPreClaimMain = () => {
    const healthPreClaimPolicyCode = useSelector<AppState, string | null>((state: any) => state.healthPreClaim.healthPreClaimPolicyCode);

    return (
        <>
            <div className="wizardTitle">Detajet e Dëmtimit / Shërbimit</div>
            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                <div className="mb-10 font-weight-bold text-dark"></div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="policy_code" label="Numri i policës" placeholder="Numri i policës" newvalue={healthPreClaimPolicyCode ? healthPreClaimPolicyCode : ''} disabled={!!healthPreClaimPolicyCode} />
                    </div>
                    <div className="col-xl-6 addSpace">
                        <DatePickerFieldMain name="date_accident" type="text" placeholder="Data e kuponit fiskal" showInfo imageName="ACC-Data e kuponit Fiskal.png" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6">
                        <InputFieldMain type="text" name="insure_person_id" label="Numri personal i ID-së" placeholder="Numri personal i ID-së" maxLength={16} disabled={!!healthPreClaimPolicyCode} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 checkboxCtrl">
                        <CheckboxField name="accept_terms" type="checkbox" label="Konfirmoj se kam lexuar dhe jam pajtuar e përcaktimet, kushtet, autorizimin dhe jap pëlqimin për marrjen dhe mbrojtjen e të dhënave personale" />
                    </div>
                    <div className="col-xl-12 checkboxCtrl">
                        <div className="mb-10 font-weight-bold text-dark"><a href="https://sigal-ks.com/wp-content/uploads/2022/08/POLITIKA-E-PRIVATESISE-SIGAL.pdf" target="_blank">Politika e privatesisë Sigal Uniqa Group Austria Kosovë</a></div>
                    </div>
                </div>
            </div>
        </>

    );
}
export default connect()(HealthPreClaimMain);
