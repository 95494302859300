import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { Formik } from "formik";
import { AppState } from '../../../../redux/reducers/rootReducer';
import { Dispatch } from "redux";
import { FormikProps } from "formik";
import * as Yup from "yup";
import * as claimActionTypes from "../../../../redux/actionTypes/claimActionTypes";
import * as generalActionTypes from "../../../../redux/actionTypes/generalActionTypes";
import SimpleModal from "../../../General/SimpleModal";
import { User } from '../../../../redux/types';
import { prepareClaimFilter, prepareClaimSendMailData } from '../../../../utils/claimTypes';
import ClaimHistoryDataDisplay from './ClaimHistoryDataDisplay';
import { hideModal } from '../../../../redux/actionCreators/generalActionCreators';
import { searchClaimHistory, downloadClaimHistoryDocument, sendClaimHistoryMail } from '../../../../redux/actionCreators/claimActionCreators';
import DatePickerFieldMain from '../../../Custom/DatePickerFieldMain';
import { ClaimHistoryPolicyTypes } from '../../../../utils/nomen';
import { Loading } from '../../../Layout/Loading';

interface FormValues {
    pageSize: number;
    pageCount: number;
    dateAccidentFrom: Date | null;
    dateAccidentTo: Date | null;
    policyType: string;
    roleType: string;
    shownum: string;
    address: string;
}

const initialValues = {
    pageSize: 5,
    pageCount: 1,
    dateAccidentFrom: null,
    dateAccidentTo: null,
    policyType: "",
    roleType: "",
    shownum: "",
    address: ""
};

interface Props {
    onSearch(filter: any): void;
    onDownload(filter: any): void;
    onSend(data: any): void;
    closeModal(): void;
}

const ClaimHistoryGrid = (props: FormikProps<FormValues> & Props) => {
    const showModal = useSelector<AppState, boolean>((state: any) => state.general.showModal);
    const header = useSelector<AppState, string>((state: any) => state.general.header);
    const text = useSelector<AppState, string>((state: any) => state.general.text);
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[claimActionTypes.DOWNLOAD_CLAIM_HISTORY_DOCUMENT]);
    const isLoadingSendMail = useSelector<AppState, boolean>((state: any) => state.isLoading[claimActionTypes.SEND_CLAIM_HISTORY_MAIL]);

    const ClaimGridSchema = Yup.object().shape({
        pageSize: Yup.number(),
        pageCount: Yup.number(),
        dateAccidentFrom: Yup.mixed(),
        dateAccidentTo: Yup.mixed(),
        policyType: Yup.string(),
        roleType: Yup.string(),
        shownum: Yup.string()
    });

    const handleSubmit = (values: any) => {
        const filter = prepareClaimFilter(values, user!.customerId);
        props.onSearch(filter);
    };

    const handleDownload = (values: any) => {
        const filter = prepareClaimFilter(values, user!.customerId);
        props.onDownload(filter);
    };

    const handleSend = (values: any) => {
        const iden = user!.tipKind === "3" ? user!.clientno : user!.nipt;
        const data = prepareClaimSendMailData(user!.name, user!.surname, iden, values.address, values.dateAccidentFrom, values.dateAccidentTo, values.policyType);
        props.onSend(data);
    };

    const handleCloseModal = () => {
        props.closeModal();
    };

    return (
        <div className="card card-custom">
            {(isLoading || isLoadingSendMail) && <Loading />}
            <SimpleModal show={showModal} header={header} text={text} onClose={handleCloseModal} onHide={() => { return; }} />
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={ClaimGridSchema}
                onSubmit={handleSubmit}
            >
                {({ getFieldProps, handleSubmit, setFieldValue, getFieldMeta, values }) => (
                    <form onSubmit={(e: any) => {
                        setFieldValue('pageSize', 5);
                        setFieldValue('pageCount', 1);
                        handleSubmit(e);
                    }}>
                        <div className="card-header flex-wrap border-0 pt-6 pb-0 bottom-line">
                            <div className="card-title">
                                <h3 className="card-label">
                                    Historiku i dëmeve
                                    <span className="text-muted pt-2 font-size-sm d-block">
                                        Kërkimi për historikun e dëmeve</span>
                                </h3>
                            </div>
                        </div>
                        <div className="card-body ">
                            {/* <!--begin::Search Form--> */}
                            <div className="mb-7  bottom-line">
                                <div className="row align-items-center">
                                    <div className="mb-7">
                                        <div className="row align-items-center">
                                            <div className="col-md-3 my-2 my-md-0">
                                                <label className="mr-3 mb-0 d-none d-md-block">Numri i policës:</label>
                                                <input type="text"
                                                    className="form-control"
                                                    placeholder="Numri i policës"
                                                    {...getFieldProps("shownum")}
                                                    id="kt_datatable_search_query" />
                                            </div>
                                            <div className="col-md-3 my-2 my-md-0">
                                                <label className="mr-3 mb-0 d-none d-md-block">Lloji i policës:</label>
                                                <select {...getFieldProps("policyType")} className="form-control" id="kt_datatable_search_status">
                                                    <option key={-1} value="">-</option>
                                                    {ClaimHistoryPolicyTypes.map(item => (<option key={item.code + "_" + item.name} value={item.code}>{item.name}</option>))}
                                                </select>
                                            </div>
                                            <div className="col-md-3 my-10">
                                                <DatePickerFieldMain name="dateAccidentFrom" type="text" hideHelper placeholder="Data e aksidentit prej" />
                                            </div>
                                            <div className="col-md-3 my-10">
                                                <DatePickerFieldMain name="dateAccidentTo" hideHelper type="text" placeholder="Data e aksidentit deri" />
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-md-2 my-2 my-md-0">
                                                <button type="submit" className="btn btn-light-primary px-6 font-weight-bold">
                                                    Kërko
                                                </button>
                                            </div>
                                            <div className="col-md-2 my-2 my-md-0">
                                                <button type="button" onClick={() => handleDownload(values)} className="btn btn-light-primary px-6 font-weight-bold">
                                                    Shiko
                                                </button>
                                            </div>
                                            <div className="col-md-3 my-2 my-md-0 markedBox">
                                                <label className="mr-3 mb-0 d-none d-md-block">Më dërgoni me postë dokumentin zyrtar në adresën::</label>
                                            </div>
                                            <div className="col-md-3 my-2 my-md-0 markedBox">
                                                <div className="d-flex align-items-center">

                                                    <input type="text"
                                                        className="form-control"
                                                        placeholder="Adresa"
                                                        {...getFieldProps("address")}
                                                        id="kt_datatable_search_query" />
                                                </div>
                                            </div>
                                            <div className="col-md-2 my-2 my-md-0 markedBox">
                                                <button type="button" disabled={getFieldMeta("address").value === ""} onClick={() => handleSend(values)} className="btn btn-light-primary px-6 font-weight-bold">
                                                    Dërgoj
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="empty-row "></div>
                            </div>
                            {/* </div> */}
                            {/* <!--end::Search Form--> */}

                            {/* <!--begin: Datatable--> */}
                            <ClaimHistoryDataDisplay onSubmit={handleSubmit} />
                            {/* <!--end: Datatable--> */}
                        </div>
                    </form >
                )}
            </Formik>
        </div >);
}

const mapDispatchToProps = (dispatch: Dispatch<claimActionTypes.SearchClaimHistoryAction | generalActionTypes.HideModalAction | claimActionTypes.DownloadClaimHistoryDocumentAction | claimActionTypes.SendClaimHistoryMailAction>) => ({
    onSearch: (filter: any) => {
        dispatch(searchClaimHistory(filter));
    },
    onDownload: (filter: any) => {
        dispatch(downloadClaimHistoryDocument(filter));
    },
    onSend: (data: any) => {
        dispatch(sendClaimHistoryMail(data));
    },
    closeModal: () => { dispatch(hideModal()); }
});

export default connect(null, mapDispatchToProps)(ClaimHistoryGrid);