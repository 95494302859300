import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import { useFormikWizard } from 'formik-wizard';
import { Loading } from '../../../Layout/Loading';
import moment from 'moment';
import CheckboxField from '../../../Custom/CheckboxField';
import * as authActionTypes from '../../../../redux/actionTypes/authActionTypes';
import * as blActionTypes from '../../../../redux/actionTypes/cliPolicyActionTypes';
import { AppState } from '../../../../redux/reducers/rootReducer';
import { Nomen } from '../../../../utils';
import { CreditLifeTypes, Gender } from '../../../../utils/nomen';
import { ThInsQuotation } from '../../../../utils/policyTypes';
import PdfCreditLife from "../../../../../src/InsuranceConditions/55.pdf";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import CaptchaGenerator from '../../../Custom/CaptchaGenerator';
import { useFormikContext } from "formik";
import InputField from '../../../Custom/InputField';
import { IncomeSources } from '../../../../utils/nomen';

const Verification = () => {
    const { values: wizardValues }: any = useFormikWizard();
    const { setFieldValue } = useFormikContext();
    const isLoadingHasProfile = useSelector<AppState, boolean>((state: any) => state.isLoading[authActionTypes.HAS_PROFILE]);
    const isLoadingBookPolicy = useSelector<AppState, boolean>((state: any) => state.isLoading[blActionTypes.BOOK_CLI_POLICY]);
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const banksLife = useSelector<AppState, Nomen[]>((state: any) => state.nomen.BanksLife);
    const chosenCLIQuotation = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.chosenCLIQuotation);
    const cliPackageQuotation = chosenCLIQuotation ? chosenCLIQuotation[0] : null;
    const insuredPerson = wizardValues.insuredPerson;
    const lifeMain = wizardValues.lifeMain;

    const useStyles = makeStyles(theme => ({
        customTooltip: {
            backgroundColor: '#3699FF',
            fontSize: '10px',
            opacity: '0.6 !important'
        }
    }));

    const classes = useStyles();

    const result = (text: string) => {
        setFieldValue('captchaGenerated', text);
    }

    return (<>
        {isLoadingHasProfile
            ? <Loading />
            : <>
                {isLoadingBookPolicy && <Loading />}

                {lifeMain &&
                    <div className="text-dark-50 line-height-lg">
                        <div className="wizardTitle mb10">Verifikoni informacionin personal</div>
                        <div className="verifyTitle">Lloji i policës</div><div className="verifyValue">{CreditLifeTypes.filter((t: Nomen) => t.code === lifeMain.subtypePolicy)[0]?.name}</div>
                        <div className="verifyTitle">Zgjidhni llojin e kredisë</div><div className="verifyValue"> {lifeMain.loanAmount}</div>
                        <div className="verifyTitle">Mosha juaj</div><div className="verifyValue">{lifeMain.insuredAge}</div>
                        <div className="verifyTitle">Kupon</div><div className="verifyValue"> {lifeMain.coupon ? lifeMain.coupon : '/'}</div>
                    </div>}

                <div className="text-dark-50 line-height-lg">
                    <div className="wizardTitle mt20 mb10">Verifikoni informacionin e personit të siguruar</div>
                    <div className="verifyTitle">Numri personal</div><div className="verifyValue"> {insuredPerson.clientNo}</div>
                    <div className="verifyTitle">Emri</div><div className="verifyValue">{insuredPerson.title}</div>
                    <div className="verifyTitle">Mbiemri</div><div className="verifyValue">{insuredPerson.lastName}</div>
                    <div className="verifyTitle">Gjinia</div><div className="verifyValue">{Gender.filter((g: Nomen) => g.code === insuredPerson.sex)[0]?.name}</div>
                    <div className="verifyTitle">Adresa</div><div className="verifyValue">{insuredPerson.address}</div>
                    <div className="verifyTitle">Vendi</div><div className="verifyValue">{places.filter((p: Nomen) => p.code === insuredPerson.place)[0]?.name}</div>
                    <div className="verifyTitle">Email</div><div className="verifyValue">{insuredPerson.email}</div>
                    <div className="verifyTitle">Celulari</div><div className="verifyValue">{insuredPerson.celphone}</div>
                    <div className="verifyTitle">Datëlindja</div><div className="verifyValue"> {moment(insuredPerson.birthDate).format('DD/MM/yyyy')}</div>
                    <div className="verifyTitle">PEP</div><div className="verifyValue"> {insuredPerson.pep === '1' ? 'Po' : 'Jo'}</div>
                    <div className="verifyTitle">Burimi i të ardhurave</div><div className="verifyValue">{IncomeSources.filter((g: Nomen) => g.code === insuredPerson.sourceOfIncome)[0]?.name}</div>
                    {insuredPerson.sourceOfIncome === 'Te tjera specifiko' && <><div className="verifyTitle">Burime të tjera</div><div className="verifyValue">{insuredPerson.otherSources}</div></>}
                    <div className="verifyTitle">Numri i identifikimit të kredisë</div><div className="verifyValue">{insuredPerson.loanId}</div>
                    <div className="verifyTitle">Data e fillimit të kredisë</div><div className="verifyValue"> {moment(insuredPerson.loanPeriodFrom).format('DD/MM/yyyy')}</div>
                    <div className="verifyTitle">Data e mbarimit të kredisë</div><div className="verifyValue"> {moment(insuredPerson.loanPeriodTo).format('DD/MM/yyyy')}</div>
                    <div className="verifyTitle">Banka</div><div className="verifyValue">{banksLife.filter((p: Nomen) => p.code === insuredPerson.bank)[0]?.name}</div>

                </div>
                <hr />
                {cliPackageQuotation &&
                    <div className="text-dark-50 line-height-lg packageInfoMobile">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">{cliPackageQuotation.packageName}</h5>
                                <p className="card-text pSum"><small>Shuma e siguruar: {cliPackageQuotation.insuredAmountV.toLocaleString()}€</small></p>
                                {cliPackageQuotation.covers.map((cover: any, indexC: number) => (
                                    <p key={indexC + 1} className="card-text">
                                        <span className='pCheck'>✔</span> {cover}
                                    </p>
                                ))}
                            </div>
                            <div className="card-footer">
                                {cliPackageQuotation.packageDiscount > 0 &&
                                    <p className='pDiscount'>
                                        {cliPackageQuotation.totalVNoDiscount.toFixed(2)}{cliPackageQuotation.currencyName ? cliPackageQuotation.currencyName : '€'}

                                        <Tooltip
                                            arrow
                                            title={"Aplikohet zbritja prej " + (cliPackageQuotation.packageDiscount) + "% ne kete police"}
                                            placement="top"
                                            classes={{
                                                tooltip: classes.customTooltip
                                            }}>
                                            <IconButton className="blue">
                                                <Info />
                                            </IconButton>
                                        </Tooltip>
                                    </p>}
                                <p className='pTotal'>
                                    {parseFloat(cliPackageQuotation.totalV).toFixed(2)}  {cliPackageQuotation.currencyName ? cliPackageQuotation.currencyName : '€'}
                                </p>
                            </div>
                        </div>
                        <hr />
                    </div>}

                <div className="row">
                    <div className="col-xl-6">
                        <CheckboxField name="acceptTerms" type="checkbox" label="I kuptoj dhe I pranoj kushtet e sigurimit" />
                    </div>
                    <div className="col-xl-6">
                        <a href={PdfCreditLife} target="_blank">Kushtet e përgjithshme të sigurimit</a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <CaptchaGenerator result={result} />
                    </div>
                    <div className="col-xl-12">
                        <InputField name="captchaInputted" type="text" placeholder="Shkruaj kodim këtu" label="Kodi i sigurise" />
                    </div>
                </div>
            </>
        }
    </>
    );
}
export default connect()(Verification);
