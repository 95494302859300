import React from "react";

const FailedPPPayment = () => {
    return (
        <div>
            <div className="card card-custom overflow-hidden">
                <div className="card-body p-0">
                    <div className="row justify-content-center bg-danger bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0">
                        <div className="col-md-9">
                            <div className="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                                <h1 className="display-4 text-white font-weight-boldest mb-10">
                                    Deshtim
                                </h1>
                            </div>
                            <div className="border-bottom w-100 opacity-20"></div>
                            <>
                                <div className="d-flex align-items-center">
                                    <div className="display-4 text-white">Cancellation during paypal payment please try again or contact system administrators!
                                        <br />  <br />  <br />
                                        Kontakte   <br />
                                        info@uniqa-ks.com         <br />
                                        +381 38 240 241           <br />
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FailedPPPayment;