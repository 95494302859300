import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Formik } from "formik";
import { AppState } from '../../../../redux/reducers/rootReducer';
import { Dispatch } from "redux";
import { FormikProps } from "formik";
import * as Yup from "yup";
import * as claimActionTypes from "../../../../redux/actionTypes/claimActionTypes";
import * as generalActionTypes from "../../../../redux/actionTypes/generalActionTypes";
import SimpleModal from "../../../General/SimpleModal";
import { User } from '../../../../redux/types';
import { prepareClaimFilter } from '../../../../utils/claimTypes';
import ClaimDataDisplay from './ClaimDataDisplay';
import { hideModal } from '../../../../redux/actionCreators/generalActionCreators';
import { searchClaim, claimPreviewReset } from '../../../../redux/actionCreators/claimActionCreators';
import DatePickerFieldMain from '../../../Custom/DatePickerFieldMain';
import { AllPolicyTypes, RoleTypes } from '../../../../utils/nomen';
import ClaimModal from './ClaimModal';

interface FormValues {
    pageSize: number;
    pageCount: number;
    dateAccidentFrom: Date | null;
    dateAccidentTo: Date | null;
    policyType: string;
    roleType: string;
    shownum: string;
}

const initialValues = {
    pageSize: 5,
    pageCount: 1,
    dateAccidentFrom: null,
    dateAccidentTo: null,
    policyType: "",
    roleType: "",
    shownum: ""
};

interface Props {
    onSearch(filter: any): void;
    closeModal(): void;
    resetSelectedClaim(): void;
}

const ClaimGrid = (props: FormikProps<FormValues> & Props) => {
    const showModal = useSelector<AppState, boolean>((state: any) => state.general.showModal);
    const header = useSelector<AppState, string>((state: any) => state.general.header);
    const text = useSelector<AppState, string>((state: any) => state.general.text);
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const showClaimModal = useSelector<AppState, boolean>((state: any) => state.claim.showClaimModal);

    const ClaimGridSchema = Yup.object().shape({
        pageSize: Yup.number(),
        pageCount: Yup.number(),
        dateAccidentFrom: Yup.mixed(),
        dateAccidentTo: Yup.mixed(),
        policyType: Yup.string(),
        shownum: Yup.string(),
        roleType: Yup.string()
    });

    const handleSubmit = (values: any) => {
        const filter = prepareClaimFilter(values, user!.customerId);
        props.onSearch(filter);
    };

    useEffect(() => {
        handleSubmit(initialValues);
    }, []);

    const handleCloseModal = () => {
        props.closeModal();
    };

    const emptySelectedClaim = () => {
        props.resetSelectedClaim();
    };

    return (
        <div className="card card-custom">
            <SimpleModal show={showModal} header={header} text={text} onClose={handleCloseModal} onHide={() => { return; }} />
            <ClaimModal show={showClaimModal} header={header} text={text} onClose={emptySelectedClaim} onHide={() => { return; }} />

            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={ClaimGridSchema}
                onSubmit={handleSubmit}
            >
                {({ getFieldProps, handleSubmit, setFieldValue }) => (
                    <form onSubmit={(e: any) => {
                        setFieldValue('pageSize', 5);
                        setFieldValue('pageCount', 1);
                        handleSubmit(e);
                    }}>
                        <div className="card-header flex-wrap border-0 pt-6 pb-0 bottom-line">
                            <div className="card-title">
                                <h3 className="card-label">
                                    Kërko të gjitha dëmet
                                    <span className="text-muted pt-2 font-size-sm d-block">
                                        Kërkimi i dëmeve</span>
                                </h3>
                            </div>
                        </div>
                        <div className="card-body ">
                            {/* <!--begin::Search Form--> */}
                            <div className="mb-7  bottom-line">
                                <div className="row align-items-center">
                                    <div className="col-md-3 my-2 my-md-0">
                                        <div className="d-flex align-items-center">
                                            <label className="mr-3 mb-0 d-none d-md-block">Numri i policës:</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Numri i policës"
                                                {...getFieldProps("shownum")}
                                                id="kt_datatable_search_query" />
                                        </div>
                                    </div>
                                    <div className="col-md-2 my-2 my-md-0">
                                        <div className="d-flex align-items-center">
                                            <label className="mr-3 mb-0 d-none d-md-block">Roli:</label>
                                            <select {...getFieldProps("roleType")} className="form-control" id="kt_datatable_search_status">
                                                <option key={-1} value="">-</option>
                                                {RoleTypes.map(item => (<option key={item.code + "_" + item.name} value={item.code}>{item.name}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3 my-10">
                                        <DatePickerFieldMain name="dateAccidentFrom" type="text" hideHelper placeholder="Data e aksidentit prej" />
                                    </div>
                                    <div className="col-md-3 my-10">
                                        <DatePickerFieldMain name="dateAccidentTo" hideHelper type="text" placeholder="Data e aksidentit deri" />
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-md-5 my-2 my-md-0">
                                        <div className="d-flex align-items-center">
                                            <label className="mr-3 mb-0 d-none d-md-block">Lloji i policës:</label>
                                            <select {...getFieldProps("policyType")} className="form-control" id="kt_datatable_search_status">
                                                <option key={-1} value="">-</option>
                                                {AllPolicyTypes.map(item => (<option key={item.code + "_" + item.name} value={item.code}>{item.name}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3 my-2 my-md-0">
                                        <button type="submit" className="btn btn-light-primary px-6 font-weight-bold">                                                    Kërko										</button>
                                    </div>
                                </div>
                                <div className="empty-row "></div>
                            </div>
                            {/* </div> */}
                            {/* <!--end::Search Form--> */}

                            {/* <!--begin: Datatable--> */}
                            <ClaimDataDisplay onSubmit={handleSubmit} />
                            {/* <!--end: Datatable--> */}
                        </div>
                    </form >
                )}
            </Formik >
        </div >);
}

const mapDispatchToProps = (dispatch: Dispatch<claimActionTypes.SearchClaimAction
    | generalActionTypes.HideModalAction
    | claimActionTypes.ClaimPreviewResetAction>) => ({
        onSearch: (filter: any) => {
            dispatch(searchClaim(filter));
        },
        resetSelectedClaim: () => { dispatch(claimPreviewReset()); },
        closeModal: () => { dispatch(hideModal()); }
    });

export default connect(null, mapDispatchToProps)(ClaimGrid);