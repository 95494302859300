import React from "react";
import { useFormikContext } from "formik";
import Nav from './Nav';
import { useSelector } from "react-redux";
import * as actionTypes from "../../../redux/actionTypes/authActionTypes";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Customer } from "../../../redux/types";
import { Loading } from "../../Layout/Loading";
import RadioButtonGroupFieldMain from "../../Custom/RadioButtonGroupFieldMain";
import InputFieldMain from "../../Custom/InputFieldMain";
import DatePickerFieldMain from "../../Custom/DatePickerFieldMain";

const Account = () => {
    const { values }: any = useFormikContext();
    const customer = useSelector<AppState, Customer | null>((state: any) => state.auth.customer);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.GET_CUSTOMER]);

    const isPhysical = values.tipKind === '3';
    const birthDate = values.birthdate !== '' ? new Date(values.birthdate) : customer?.birthdate ? new Date(customer?.birthdate) : '';

    return (
        <>
            {isLoading || customer === null ?
                <Loading />
                :
                <>
                    <div className="d-flex flex-column-auto flex-column px-10">
                        <Nav step={0} />

                    </div>
                    <div className="login-form form px-10 fv-plugins-bootstrap fv-plugins-framework " >

                        <div className="login-form form px-10 fv-plugins-bootstrap fv-plugins-framework">

                            <div className="row">
                                <div className="col-xl-6">
                                    <RadioButtonGroupFieldMain name="tipKind" newvalue={customer?.tipkind} type="radio" placeholder="Tip Kind" label="Tip" items={[{ id: '3', value: 'Fizik' }, { id: '2', value: 'Juridik' }]} />
                                </div>
                                <div className="col-xl-6">
                                    {isPhysical && <InputFieldMain name="clientno" newvalue={customer?.clientno} type="text" placeholder="Nr. Personal" label="Nr. Personal" maxLength={16} />}
                                    {!isPhysical && <InputFieldMain name="nipt" newvalue={customer?.nipt} type="text" placeholder="Nipt" label="Nipt" />}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-6">
                                    {isPhysical && <InputFieldMain name="title" newvalue={customer?.title} type="text" placeholder="Emër" label="Emër" />}
                                </div>
                                <div className="col-xl-6">
                                    {!isPhysical && <InputFieldMain name="title" newvalue={customer?.title} type="text" placeholder="Emër" label="Emër" />}
                                    {isPhysical && <InputFieldMain name="lastName" type="text" newvalue={customer?.lastname} placeholder="Mbiemër" label="Mbiemër" />}
                                </div>
                            </div>
                            <div className="empty-row"></div>
                            <div className="row">
                                <div className="col-xl-6">
                                    {isPhysical && <RadioButtonGroupFieldMain name="sex" type="text" newvalue={customer?.sex} placeholder="Gjinia" label="Gjinia" items={[{ id: '1', value: 'Mashkull' }, { id: '2', value: 'Femër' }]} />}
                                </div>
                                <div className="col-xl-6">
                                    {isPhysical && <DatePickerFieldMain name="birthdate" newvalue={birthDate} type="text" placeholder="Datëlindja" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
        </>
    );
}

export default Account;
