import React from "react";
import { Dispatch } from "redux";
import { connect, useSelector } from "react-redux";
import { useFormik, FormikProps } from "formik";
import * as Yup from "yup";
import { changePassword, logoutUser } from "../../redux/actionCreators/authActionCreators";
import { AppState } from "../../redux/reducers/rootReducer";
import * as actionTypes from "../../redux/actionTypes/authActionTypes";
import { HideModalAction } from "../../redux/actionTypes/generalActionTypes";
import { hideModal } from "../../redux/actionCreators/generalActionCreators";
import SimpleModal from "../General/SimpleModal";
import { User } from "../../redux/types";

interface FormValues {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
};

interface Props {
    onConfirm(id: string, oldPassword: string, newPassword: string, confirmNewPassword: string): void;
    closeModal(): void;
    logout(token: string): void;
}

const ChangePassword = (props: FormikProps<FormValues> & Props) => {
    const { onConfirm } = props;
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.CHANGE_PASSWORD]);
    const showModal = useSelector<AppState, boolean>((state: any) => state.general.showModal);
    const header = useSelector<AppState, string>((state: any) => state.general.header);
    const text = useSelector<AppState, string>((state: any) => state.general.text);
    const nextStep = useSelector<AppState, string>((state: any) => state.general.nextStep);
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);

    const ChangePasswordSchema = Yup.object().shape({
        oldPassword: Yup.string()
            .min(3, "Minimumi 3 simbole")
            .max(50, "Maksimumi 50 simbole")
            .required('E domosdoshme'),
        newPassword: Yup.string()
            .min(3, "Minimumi 3 simbole")
            .max(50, "Maksimumi 50 simbole")
            .required('E domosdoshme'),
        confirmNewPassword: Yup.string()
            .min(3, "Minimumi 3 simbole")
            .max(50, "Maksimumi 50 simbole")
            .required('E domosdoshme'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: ChangePasswordSchema,
        onSubmit: (values) => {
            onConfirm(user!.id, values.oldPassword, values.newPassword, values.confirmNewPassword)
        }
    });

    const handleCloseModal = () => {
        props.closeModal();
        if (nextStep === "5" && !!user) props.logout(user!.token);
    };

    const oldPasswordClass = 'form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 '
        + (formik.touched.oldPassword && ((formik.errors.oldPassword && 'is-invalid') || (!formik.errors.oldPassword && 'is-valid')));

    const newPasswordClass = 'form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 '
        + (formik.touched.newPassword && ((formik.errors.newPassword && 'is-invalid') || (!formik.errors.newPassword && 'is-valid')));

    const confirmNewPasswordClass = 'form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0 '
        + (formik.touched.confirmNewPassword && ((formik.errors.confirmNewPassword && 'is-invalid') || (!formik.errors.confirmNewPassword && 'is-valid')));


    return (
        <div className="login-container order-2 order-lg-1 d-flex flex-center flex-row-fluid px-7 pt-lg-0 pb-lg-0 pt-4 pb-6 bg-white simple-form-padding">
            <div className="login-content d-flex flex-column pt-lg-0 pt-12">
                <SimpleModal show={showModal} header={header} text={text} onClose={handleCloseModal} onHide={() => { return; }} />
                <div className="login-form" id="kt_login_signin_form">
                    {/*begin::Form*/}
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form fv-plugins-bootstrap fv-plugins-framework"
                    >
                        <div className="pb-5 pb-lg-15">
                            <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Ndrysho Fjalëkalimin</h3>
                            <div className="text-muted font-weight-bold font-size-h4 bottom-line">
                                Vendosni fjalëkalimin aktual dhe fjalëkalimin e ri
              </div>
                        </div>
                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center fv-plugins-icon-container">
                            <label className="font-size-h6 font-weight-bolder text-dark pt-5">Fjalëkalimi aktual</label>
                            <input
                                placeholder="Fjalëkalimi aktual"
                                type="password"
                                className={oldPasswordClass}
                                {...formik.getFieldProps("oldPassword")}
                            />
                            {formik.touched.oldPassword && formik.errors.oldPassword ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.oldPassword}</div>
                                </div>
                            ) : null}
                        </div>

                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center fv-plugins-icon-container">
                            <label className="font-size-h6 font-weight-bolder text-dark pt-5">Fjalëkalimi i ri</label>
                            <input
                                placeholder="Fjalëkalimi i ri"
                                type="password"
                                className={newPasswordClass}
                                {...formik.getFieldProps("newPassword")}
                            />
                            {formik.touched.newPassword && formik.errors.newPassword ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.newPassword}</div>
                                </div>
                            ) : null}
                        </div>

                        <div className="form-group d-flex flex-wrap justify-content-between align-items-center fv-plugins-icon-container">
                            <label className="font-size-h6 font-weight-bolder text-dark pt-5">Konfirmo fjalëkalimin</label>
                            <input
                                placeholder="Konfirmo fjalëkalimin"
                                type="password"
                                className={confirmNewPasswordClass}
                                {...formik.getFieldProps("confirmNewPassword")}
                            />
                            {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.confirmNewPassword}</div>
                                </div>
                            ) : null}
                        </div>

                        <div className="pb-lg-0 pb-5">
                            <button
                                id="kt_login_singin_form_submit_button"
                                type="submit"
                                disabled={isLoading}
                                className={`btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3`}
                            >
                                <span>Ndrysho Fjalëkalimin</span>
                                {isLoading && <span className="ml-3 spinner spinner-white"></span>}
                            </button>
                        </div>
                    </form>
                    {/*end::Form*/}
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<actionTypes.ChangePasswordAction | HideModalAction | actionTypes.LogoutUserAction>) => ({
    onConfirm: (id: string, oldPassword: string, newPassword: string, confirmNewPassword: string) => {
        dispatch(changePassword({ id, oldPassword, newPassword, confirmNewPassword }));
    },
    closeModal: () => { dispatch(hideModal()); },
    logout: (token: string) => { dispatch(logoutUser(token)); }
});

export default connect(null, mapDispatchToProps)(ChangePassword);
