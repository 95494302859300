import * as React from 'react';
import { CircularProgress } from '@material-ui/core';

export function Loading() {
    return (
        <>
            <div id="splash-screen">
                {/* <img
          src={toAbsoluteUrl("/media/logos/logo-mini-md.png")}
          alt="Metronic logo"
        /> */}
        PORTALI KLIENTIT
        <CircularProgress className="splash-screen-spinner" />
            </div>
        </>
    );
}