import { MotorInsQuotation, BookingData, MotorPolicy } from '../../utils/policyTypes';

export const GET_B_QUOTATION = "policyActionTypes/GET_B_QUOTATION";
export interface GetBQuotationAction {
  type: typeof GET_B_QUOTATION;
  customerId: string;
  bVehicleCategoryClass: string;
  prodyear: number;
  power: number;
  insuredPeriod: string;
}

export const GET_B_QUOTATION_REQUEST = "policyActionTypes/GET_B_QUOTATION_REQUEST";
export interface GetBQuotationRequestAction {
  type: typeof GET_B_QUOTATION_REQUEST;
}

export const GET_B_QUOTATION_RESET = "policyActionTypes/GET_B_QUOTATION_RESET";
export interface GetBQuotationResetAction {
  type: typeof GET_B_QUOTATION_RESET;
}

export const GET_B_QUOTATION_SUCCESS = "policyActionTypes/GET_B_QUOTATION_SUCCESS";
export interface GetBQuotationSuccessAction {
  type: typeof GET_B_QUOTATION_SUCCESS;
  data: MotorInsQuotation;
}

export const GET_B_QUOTATION_FAILURE = "policyActionTypes/GET_B_QUOTATION_FAILURE";
export interface GetBQuotationFailureAction {
  type: typeof GET_B_QUOTATION_FAILURE;
  error: Error | string;
}

export const BOOK_B_POLICY = "policyActionTypes/BOOK_B_POLICY";
export interface BookBPolicyAction {
  type: typeof BOOK_B_POLICY;
  policy: MotorPolicy;
  payment: string;
}

export const BOOK_B_POLICY_REQUEST = "policyActionTypes/BOOK_B_POLICY_REQUEST";
export interface BookBPolicyRequestAction {
  type: typeof BOOK_B_POLICY_REQUEST;
}

export const BOOK_B_POLICY_SUCCESS = "policyActionTypes/BOOK_B_POLICY_SUCCESS";
export interface BookBPolicySuccessAction {
  type: typeof BOOK_B_POLICY_SUCCESS;
  data: BookingData;
}

export const BOOK_B_POLICY_FAILURE = "policyActionTypes/BOOK_B_POLICY_FAILURE";
export interface BookBPolicyFailureAction {
  type: typeof BOOK_B_POLICY_FAILURE;
  error: Error | string;
}

export type PolicyAction =
  GetBQuotationAction
  | GetBQuotationRequestAction
  | GetBQuotationResetAction
  | GetBQuotationSuccessAction
  | GetBQuotationFailureAction
  | BookBPolicyAction
  | BookBPolicyRequestAction
  | BookBPolicySuccessAction
  | BookBPolicyFailureAction