import React, { FunctionComponent } from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import { IconButton, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { toAbsoluteUrl } from '../../_metronic/_helpers';

const getFieldCSSClasses = (touched: boolean, error: string | undefined) => {
    const classes = ["form-control"];
    if (error && error !== "") {
        classes.push("is-invalid");
    }

    if (!error) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

interface Props {
    name: string;
    type: string;
    placeholder: string;
    newvalue?: any;
    disabled?: boolean;
    hideHelper?: boolean;
    minDate?: any;
    showInfo?: boolean;
    imageName?: string;
    change?: (value: any) => void;
}

const DatePickerFieldMain: FunctionComponent<Props> = ({ ...props }) => {
    const [
        field,
        { error, touched },
    ] = useField({
        name: props.name,
        type: props.name,
    });
    const { setFieldValue } = useFormikContext();

    React.useEffect(() => {
        if (!!props.newvalue &&
            (!field.value ||
                (props.newvalue instanceof Date &&
                    field.value instanceof Date &&
                    props.newvalue.getDate() !== field.value.getDate()))) {
            setFieldValue(props.name, props.newvalue);
        }
    }, [setFieldValue, props.name, props.newvalue]);

    const handleChange = (value: any) => {
        setFieldValue(props.name, value);
        if (props.change) {
            props.change(value);
        }
    }

    return (
        <div className="form-group">
            {props.placeholder !== "" && <label>{props.placeholder} </label>}
            {props.showInfo && <Tooltip title={
                <React.Fragment>
                    <img
                        alt="Logo"
                        className="max-h-700px"
                        src={toAbsoluteUrl("/media/logos/" + props.imageName)}
                    />
                </React.Fragment>
            }>
                <IconButton>
                    <Info />
                </IconButton>
            </Tooltip>}
            <DatePicker
                showMonthDropdown
                showYearDropdown
                minDate={!props.minDate ? props.minDate : new Date(1950, 0, 1)}
                className={"form-control form-control-solid h-auto px-6 border-0 rounded-lg font-size-h6 " + getFieldCSSClasses(touched, error)}
                {...field}
                {...props}
                dateFormat="dd/MM/yyyy"
                selected={(field.value && new Date(field.value)) || null}
                onChange={(val: any) => {
                    handleChange(val);
                }}
            />
            {error && error !== "" ? (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{error.toString()}</div>
                </div>
            )
                : !props.hideHelper && <div className="feedback">
                    Ju lutem shkruani <b> {props.placeholder} </b> ne formatin 'dd/mm/yyyy'
                </div>

            }
        </div>
    );
}

export default DatePickerFieldMain;