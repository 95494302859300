import { PreClaimPerson, PreClaimRegisterData } from './../../utils/claimTypes';
import * as actions from "../actionTypes/healthPreClaimActionTypes";

export interface HealthPreClaimState {
  healthPreClaimPerson: PreClaimPerson | null;
  healthPreClaimPolicyCode: string | null;
  healthPreClaimRegisterData: PreClaimRegisterData | null;
}

const initialState: HealthPreClaimState = {
  healthPreClaimPerson: null,
  healthPreClaimPolicyCode: null,
  healthPreClaimRegisterData: null

};

export default function healthClaimReducer(
  state: HealthPreClaimState = initialState,
  action: actions.HealthPreClaimAction
): HealthPreClaimState {
  switch (action.type) {
    case actions.VALIDATE_HEALTH_CLAIM_POLICY_SUCCESS:
      return {
        ...state,
        healthPreClaimPerson: action.data,
        healthPreClaimPolicyCode: action.data.policyCode
      };
    case actions.VALIDATE_HEALTH_CLAIM_POLICY_RESET:
      return {
        ...state,
        healthPreClaimPerson: null,
        healthPreClaimPolicyCode: null
      };
    case actions.VALIDATE_HEALTH_CLAIM_INSURED_SUCCESS:
      return {
        ...state,
        healthPreClaimPerson: action.data,
        healthPreClaimPolicyCode: action.data.policyCode
      };
    case actions.VALIDATE_HEALTH_CLAIM_INSURED_RESET:
      return {
        ...state,
        healthPreClaimPerson: null
      };
    case actions.REGISTER_HEALTH_PRECLAIM_SUCCESS:
      return {
        ...state,
        healthPreClaimRegisterData: action.data
      };
    default:
      return state;
  }
}
