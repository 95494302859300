import { PolicyMenu } from './../../../redux/types';
import { preparePolicy } from './../../../utils/policyTypes';
import { FormikWizardStepType } from '../../../utils/wizardTypes';
import { object, string, mixed, StringSchema, number, bool, BooleanSchema } from 'yup';
import store from "../../../redux/configureStore";
import * as tplActions from "../../../redux/actionCreators/tplPolicyActionCreators";
import * as generalActions from "../../../redux/actionCreators/generalActionCreators";
import moment from 'moment';
import VehicleMain from './VehicleMain';
import VehicleDetails from './VehicleDetails';
import Contractor from './Contractor';
import Insured1 from './Insured1';
import Insured2 from './Insured2';
import Preview from './Preview';
import Confirmation from './Confirmation';

export default [
    {
        id: 'vehicleMain',
        component: VehicleMain,
        name: 'Llogarit primin',
        description: '',
        initialValues: {
            plateNumber: '',
            chassis: '',
            startdate: ''
        },
        validationSchema: object().shape({
            plateNumber: string().required('E domosdoshme'),
            chassis: string().required('E domosdoshme'),
            startdate: mixed().required('E domosdoshme')
        }),
        actionLabel: 'Verifiko Automjetin',
        onAction: (sectionValues, formValues) => {
            const fvalid = moment(sectionValues.startdate).format('YYYY-MM-DD');
            const submittedFValid = moment(formValues.vehicleMain.startdate).format('YYYY-MM-DD');
            if (submittedFValid === ""
                || fvalid !== submittedFValid
                || formValues.vehicleMain.chassis === ""
                || sectionValues.chassis !== formValues.vehicleMain.chassis
                || sectionValues.plateNumber !== formValues.vehicleMain.plateNumber) {
                const customerId = store.getState().auth.user?.customerId;
                store.dispatch(generalActions.checkFValid(customerId!, "01", "01", fvalid, sectionValues.plateNumber, sectionValues.chassis, "", null, null, "", null, true));
            }
        }
    },
    {
        id: 'vehicleDetails',
        component: VehicleDetails,
        name: 'Automjeti',
        description: '',
        initialValues: {
            categoryClassTypeVehicle: '',
            mark: '',
            tip: '',
            prodYear: '',
            placenr: '',
            colour: '',
            capacity: '',
            power: '',
            used: '',
            vehicleLicence: '',
        },
        validationSchema: object().shape({
            categoryClassTypeVehicle: string().required('E domosdoshme'),
            mark: string().required('E domosdoshme'),
            tip: string().required('E domosdoshme'),
            prodYear: number().required('E domosdoshme').test(
                'validYear',
                'Production year cannot be after current year',
                function (this: any, value: any) {
                    const current = moment().year();
                    if (value !== '')
                        return parseInt(value) <= current;
                    return true;
                }),
            placenr: number().required('E domosdoshme'),
            colour: string().required('E domosdoshme'),
            capacity: number().required('E domosdoshme'),
            power: number().required('E domosdoshme'),
            used: string(),
            vehicleLicence: string().required('E domosdoshme'),
        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
            const quotation = store.getState().policy.motorInsQuotation;
            if (!quotation || !quotation?.total || quotation?.total === 0.0) {
                const customerId = store.getState().auth.user?.customerId;
                store.dispatch(tplActions.getTPLQuotation(customerId!, sectionValues.categoryClassTypeVehicle, sectionValues.prodYear, sectionValues.power));
            }
        }
    },
    {
        id: 'contractor',
        component: Contractor,
        name: 'Kontraktuesi',
        description: '',
        initialValues: {
            tipKind: '3',
            nipt: '',
            clientNo: '',
            title: '',
            lastName: '',
            birthDate: '',
            celphone: '',
            address: '',
            place: '',
            sex: ''
        },
        validationSchema: object().shape({
            tipKind: string().required('E domosdoshme'),
            clientNo: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
            })
                .test(
                    'validClientNo',
                    'Klientit nuk I lejohet te shese per te tjere',
                    function (this: any, value: any) {
                        const clientNo = store.getState().auth.user?.clientno;
                        const policyList = store.getState().auth.user?.policyList;
                        const policyItem = policyList.find((p: PolicyMenu) => p.type === "01" && p.subtype === "01");
                        const isAllowed = policyItem.allowToSellForOthers;
                        const isNotPhysical = this.parent.tipKind !== '3';
                        if (isAllowed || clientNo === null || value === undefined || isNotPhysical)
                            return true;

                        return (value as string).toLowerCase() === (clientNo as string).toLowerCase();
                    }),
            nipt: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '2' ? schema.required('E domosdoshme') : schema.notRequired();
            })
                .test(
                    'validNipt',
                    'Klientit nuk I lejohet te shese per te tjere',
                    function (this: any, value: any) {
                        const nipt = store.getState().auth.user?.nipt;
                        const policyList = store.getState().auth.user?.policyList;
                        const policyItem = policyList.find((p: PolicyMenu) => p.type === "01" && p.subtype === "01");
                        const isAllowed = policyItem.allowToSellForOthers;
                        const isNotSubject = this.parent.tipKind !== '2';
                        if (isAllowed || nipt === null || value === undefined || isNotSubject)
                            return true;

                        return (value as string).toLowerCase() === (nipt as string).toLowerCase();
                    }),
            title: string()
                .min(3, "Minimumi 3 simbole")
                .max(50, "Maksimumi 50 simbole")
                .required("E domosdoshme"),
            lastName: string()
                .when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                    return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
                }),

            birthDate: mixed().test(
                'validBirthDate',
                'Data e lindjes nuk mund te jete pas dates se sotshme',
                function (this: any, value: any) {
                    if (moment(value, 'dd/MM/yyyy').isValid())
                        return moment(value, 'dd/MM/yyyy').isBefore(moment());
                    return true;
                }),
            celphone: string().required('E domosdoshme'),
            address: string().required('E domosdoshme'),
            place: string(),
            sex: string()
        }),
        actionLabel: 'Vazhdo',
    },
    {
        id: 'insured1',
        component: Insured1,
        name: 'I Siguruari',
        description: '',
        initialValues: {
            tipKind: '3',
            nipt: '',
            clientNo: '',
            title: '',
            lastName: '',
            birthDate: '',
            email: '',
            celphone: '',
            address: '',
            place: '',
            drivingLicenceCat: '',
            drivingLicenceNo: '',
            sex: ''
        },
        validationSchema: object().shape({
            tipKind: string().required('E domosdoshme'),
            clientNo: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            nipt: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '2' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            title: string()
                .min(3, "Minimumi 3 simbole")
                .max(50, "Maksimumi 50 simbole")
                .required("E domosdoshme"),
            lastName: string()
                .when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                    return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
                }),
            birthDate: mixed().test(
                'validBirthDate',
                'Data e lindjes nuk mund te jete pas dates se sotshme',
                function (this: any, value: any) {
                    if (moment(value, 'dd/MM/yyyy').isValid())
                        return moment(value, 'dd/MM/yyyy').isBefore(moment());
                    return true;
                }),
            email: string().email(),
            celphone: string(),
            address: string().required('E domosdoshme'),
            place: string(),
            drivingLicenceCat: string().required('E domosdoshme'),
            drivingLicenceNo: string().required('E domosdoshme'),
            sex: string()
        }),
        actionLabel: 'Vazhdo'
    },
    {
        id: 'insured2',
        component: Insured2,
        name: 'I Siguruari 2',
        description: '',
        initialValues: {
            hasSecondInsurer: false,
            tipKind: '3',
            nipt: '',
            clientNo: '',
            title: '',
            lastName: '',
            birthDate: '',
            email: '',
            celphone: '',
            address: '',
            place: '',
            drivingLicenceCat: '',
            drivingLicenceNo: '',
            sex: ''
        },
        validationSchema: object().shape({
            hasSecondInsurer: bool().required(),
            tipKind: string().when('hasSecondInsurer', (hasSecondInsurer: boolean, schema: BooleanSchema<boolean | undefined>) => {
                return hasSecondInsurer ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            clientNo: string().when(['hasSecondInsurer', 'tipKind'],
                (hasSecondInsurer: boolean, tipKind: string, schema: BooleanSchema<boolean | undefined>) => {
                    return hasSecondInsurer && tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
                }),
            nipt: string().when(['hasSecondInsurer', 'tipKind'],
                (hasSecondInsurer: boolean, tipKind: string, schema: BooleanSchema<boolean | undefined>) => {
                    return hasSecondInsurer && tipKind === '2' ? schema.required('E domosdoshme') : schema.notRequired();
                }),
            title: string()
                .min(3, "Minimumi 3 simbole")
                .max(50, "Maksimumi 50 simbole")
                .when('hasSecondInsurer', (hasSecondInsurer: boolean, schema: BooleanSchema<boolean | undefined>) => {
                    return hasSecondInsurer ? schema.required('E domosdoshme') : schema.notRequired();
                }),
            lastName: string()
                .when(['hasSecondInsurer', 'tipKind'],
                    (hasSecondInsurer: boolean, tipKind: string, schema: BooleanSchema<boolean | undefined>) => {
                        return hasSecondInsurer && tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
                    }),
            birthDate: mixed().test(
                'validBirthDate',
                'Data e lindjes nuk mund te jete pas dates se sotshme',
                function (this: any, value: any) {
                    if (moment(value, 'dd/MM/yyyy').isValid())
                        return moment(value, 'dd/MM/yyyy').isBefore(moment());
                    return true;
                }),
            email: string().email(),
            celphone: string(),
            address: string().when('hasSecondInsurer', (hasSecondInsurer: boolean, schema: BooleanSchema<boolean | undefined>) => {
                return hasSecondInsurer ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            place: string(),
            drivingLicenceCat: string().when('hasSecondInsurer', (hasSecondInsurer: boolean, schema: BooleanSchema<boolean | undefined>) => {
                return hasSecondInsurer ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            drivingLicenceNo: string().when('hasSecondInsurer', (hasSecondInsurer: boolean, schema: BooleanSchema<boolean | undefined>) => {
                return hasSecondInsurer ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            sex: string()
        }),
        actionLabel: 'Vazhdo',
    },
    {
        id: 'preview',
        component: Preview,
        name: 'Verifiko',
        description: '',
        initialValues: {
            payment: '1',
            acceptConditions: false,
            acceptTerms: false,
            deliveryAddressFil: '',
            deliveryAddressHome: '',
            typeDelivery: '1',

        },
        validationSchema: object().shape({
            payment: string().required('E domosdoshme!'),
            acceptConditions: bool().oneOf([true], 'E domosdoshme'),
            acceptTerms: bool().oneOf([true], 'E domosdoshme'),
            deliveryAddressFil: string().when(['typeDelivery'],
                (typeDelivery: string, schema: BooleanSchema<boolean | undefined>) => {
                    return typeDelivery === '1' ? schema.required('E domosdoshme') : schema.notRequired();
                }),
            deliveryAddressHome: string().when(['typeDelivery'],
                (typeDelivery: string, schema: BooleanSchema<boolean | undefined>) => {
                    return typeDelivery === '2' ? schema.required('E domosdoshme') : schema.notRequired();
                }),
            typeDelivery: string(),
        }),
        actionLabel: 'Vazhdo per pagese',
        onAction: (sectionValues, formValues) => {
            const customerId = store.getState().auth.user?.customerId;
            if (customerId) {
                const motorPolicy = preparePolicy(customerId, formValues, sectionValues, "");
                store.dispatch(tplActions.bookTPLPolicy(motorPolicy, sectionValues.payment));
            }
        }
    },
    {
        id: 'confirmation',
        component: Confirmation,
        name: 'Pagesa',
        description: '',
        initialValues: {
        },
        validationSchema: object().shape({
        }),
        actionLabel: 'Vazhdo',
    }
] as FormikWizardStepType[];