import { ThPolicy, ThInsQuotation, PhPolicy } from './../../utils/policyTypes';
import { BookingData } from '../../utils/policyTypes';
import * as actions from "../actionTypes/thPolicyActionTypes";

export function getTHQuotation(
    policy: ThPolicy
): actions.GetTHQuotationAction {
    return {
        type: actions.GET_TH_QUOTATION,
        policy
    };
}

export function getTHQuotationRequest(
): actions.GetTHQuotationRequestAction {
    return {
        type: actions.GET_TH_QUOTATION_REQUEST
    };
}

export function getTHQuotationReset(
): actions.GetTHQuotationResetAction {
    return {
        type: actions.GET_TH_QUOTATION_RESET
    };
}

export function getTHQuotationSuccess(
    values: ThInsQuotation
): actions.GetTHQuotationSuccessAction {
    return {
        type: actions.GET_TH_QUOTATION_SUCCESS,
        data: values
    };
}

export function getTHQuotationFailure(
    error: Error | string
): actions.GetTHQuotationFailureAction {
    return {
        type: actions.GET_TH_QUOTATION_FAILURE,
        error
    };
}

export function bookTHPolicy(
    policy: ThPolicy,
): actions.BookTHPolicyAction {
    return {
        type: actions.BOOK_TH_POLICY,
        policy
    };
}

export function bookTHPolicyRequest(
): actions.BookTHPolicyRequestAction {
    return {
        type: actions.BOOK_TH_POLICY_REQUEST
    };
}

export function bookTHPolicySuccess(
    values: BookingData
): actions.BookTHPolicySuccessAction {
    return {
        type: actions.BOOK_TH_POLICY_SUCCESS,
        data: values
    };
}

export function bookTHPolicyFailure(
    error: Error | string
): actions.BookTHPolicyFailureAction {
    return {
        type: actions.BOOK_TH_POLICY_FAILURE,
        error
    };
}

export function chooseQuotation(
): actions.ChooseQuotationAction {
    return {
        type: actions.CHOOSE_QUOTATION
    };
}

export function chooseQuotationFailure(
): actions.ChooseQuotationFailureAction {
    return {
        type: actions.CHOOSE_QUOTATION_FAILURE
    };
}

export function chooseQuotationSuccess(
): actions.ChooseQuotationSuccessAction {
    return {
        type: actions.CHOOSE_QUOTATION_SUCCESS
    };
}

export function chooseQuotationReset(
): actions.ChooseQuotationResetAction {
    return {
        type: actions.CHOOSE_QUOTATION_RESET
    };
}

export function getPackageTHQuotation(
    policy: ThPolicy
): actions.GetPackageTHQuotationAction {
    return {
        type: actions.GET_PACKAGE_TH_QUOTATION,
        policy
    };
}

export function getPackageTHQuotationRequest(
): actions.GetPackageTHQuotationRequestAction {
    return {
        type: actions.GET_PACKAGE_TH_QUOTATION_REQUEST
    };
}

export function getPackageTHQuotationReset(
): actions.GetPackageTHQuotationResetAction {
    return {
        type: actions.GET_PACKAGE_TH_QUOTATION_RESET
    };
}

export function getPackageTHQuotationSuccess(
    values: ThInsQuotation
): actions.GetPackageTHQuotationSuccessAction {
    return {
        type: actions.GET_PACKAGE_TH_QUOTATION_SUCCESS,
        data: values
    };
}

export function getPackageTHQuotationFailure(
    error: Error | string
): actions.GetPackageTHQuotationFailureAction {
    return {
        type: actions.GET_PACKAGE_TH_QUOTATION_FAILURE,
        error
    };
}
