import { FormikWizardStepType } from '../../../../utils/wizardTypes';
import { object, string, mixed, StringSchema, number, date, array, bool } from 'yup';
import store from '../../../../redux/configureStore';
import * as actions from '../../../../redux/actionCreators/claimActionCreators';
import * as healthClaimActions from '../../../../redux/actionCreators/healthPreClaimActionCreators';
import * as generalActions from '../../../../redux/actionCreators/generalActionCreators';
import * as nomenActions from '../../../../redux/actionCreators/nomenActionCreators';
import moment from 'moment';

import HealthPreClaimMain from './HealthPreClaimMain';
import BeneficiaryPerson from './BeneficiaryPerson';
import Invoices from './Invoices';
import UploadHealthDocuments from './UploadHealthDocuments';
import { validate } from '../../../../utils/healthPreClaimTypes';

export default [
    {
        id: 'claim',
        component: HealthPreClaimMain,
        name: 'Detajet e Dëmtimit / Shërbimit',
        description: '',
        initialValues: {
            type_policy: '34',
            policy_code: '',
            date_accident: '',
            insure_person_id: '',
            accept_terms: false
        },
        validationSchema: object().shape({
            type_policy: string().required('E domosdoshme'),
            policy_code: string()
                .test(
                    'validPolicyCode',
                    'E domosdoshme',
                    function (this: any, value: any) {
                        return !!this.parent.insure_person_id ? true : !!value;
                    }),
            date_accident: date().required('E domosdoshme').typeError('E domosdoshme')
                .test(
                    'validDateAccident',
                    'Date accident cannot be after today',
                    function (this: any, value: any) {
                        return moment(value, 'dd/MM/yyyy').isBefore(moment());
                    }),
            insure_person_id: string()
                .test(
                    'validPersonId',
                    'E domosdoshme',
                    function (this: any, value: any) {
                        return !!this.parent.policy_code ? true : !!value;
                    }),
            accept_terms: bool().oneOf([true], 'E domosdoshme')
        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
            if (sectionValues.type_policy !== formValues.claim.type_policy
                || sectionValues.policy_code !== formValues.claim.policy_code
                || sectionValues.insure_person_id !== formValues.claim.insure_person_id
                || sectionValues.date_accident !== formValues.claim.date_accident) {

                const customerId = store.getState().auth.user?.customerId;

                if (!sectionValues.insure_person_id) {
                    store.dispatch(healthClaimActions.validateHealthClaimPolicy(sectionValues.date_accident, customerId, sectionValues.policy_code));
                } else {
                    store.dispatch(healthClaimActions.validateHealthClaimInsured(sectionValues.date_accident, customerId, sectionValues.insure_person_id));
                }
            }

            if ((sectionValues.policy_code !== "" && !sectionValues.insure_person_id)
                && (sectionValues.policy_code !== formValues.claim.policy_code
                    || moment(sectionValues.date_accident).format('YYYY-MM-DD') !== moment(formValues.claim.date_accident).format('YYYY-MM-DD'))) {
                store.dispatch(nomenActions.getCoverCategoriesForPolicy(sectionValues.policy_code, moment(sectionValues.date_accident).format('YYYY-MM-DD')));
            }
        }
    },
    {
        id: 'beneficiaryPerson',
        component: BeneficiaryPerson,
        name: 'Përfituesi',
        description: '',
        initialValues: {
            tipKind: '3',
            nipt: '',
            clientNo: '',
            title: '',
            lastName: '',
            birthDate: '',
            email: '',
            celphone: '',
            address: '',
            place: '',
            sex: '',
            bankAccount: '',
            bank: ''
        },
        validationSchema: object().shape({
            tipKind: string().required('E domosdoshme'),
            clientNo: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme').max(16, "Maximum 16 symbols") : schema.notRequired();
            }),
            nipt: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '2' ? schema.required('E domosdoshme').max(16, "Maximum 16 symbols") : schema.notRequired();
            }),
            title: string()
                .min(3, "Minimumi 3 simbole")
                .max(50, "Maksimumi 50 simbole")
                .required("E domosdoshme"),
            lastName: string()
                .when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                    return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
                }),
            birthDate: mixed()
                .test(
                    'validBirthDate',
                    'Data e lindjes nuk mund te jete pas dates se sotshme',
                    function (this: any, value: any) {
                        if (moment(value, 'dd/MM/yyyy').isValid())
                            return moment(value, 'dd/MM/yyyy').isBefore(moment());
                        return true;
                    }),
            email: string().email().required('E domosdoshme'),
            celphone: string().required('E domosdoshme'),
            address: string(),
            place: string(),
            sex: string().when('tipKind', (tipKind: string, schema: StringSchema<string | undefined>) => {
                return tipKind === '3' ? schema.required('E domosdoshme') : schema.notRequired();
            }),
            bankAccount: string().required('E domosdoshme'),
            bank: string().required('E domosdoshme'),

        }),
        actionLabel: 'Vazhdo'
    },
    {
        id: 'insertInvoices',
        component: Invoices,
        name: 'Faturat',
        description: '',
        initialValues: {
            invoices: [
                {
                    invoicedate: '',
                    invoicenr: '',
                    invoiceFiscalnr: '',
                    invoicedAmountV: '',
                    countryEvent: '',
                    hospitalId: '',
                    hospitalName: '',
                    fiscalFiles: [],
                    medicalFiles: [],
                    records: [{
                        covercategory: '',
                        covergroup: '',
                        invoicedAmountV: '',
                        treating: ''
                    }]
                }
            ]
        },
        validationSchema: object().shape({
            invoices: array()
                .of(
                    object().shape({
                        invoicenr: string().required('E domosdoshme'),
                        invoiceFiscalnr: string().required('E domosdoshme'),
                        invoicedAmountV: number().required('E domosdoshme').typeError('Numri i kërkuar'),
                        countryEvent: string().required('E domosdoshme'),
                        hospitalId: string().required('E domosdoshme'),
                        hospitalName: string().required('E domosdoshme'),
                        invoicedate: date().required('E domosdoshme').typeError('E domosdoshme')
                            .test(
                                'validInvoiceDate',
                                'Invoice date cannot be after today',
                                function (this: any, value: any) {
                                    return moment(value, 'dd/MM/yyyy').isBefore(moment());
                                }),
                        records: array()
                            .of(
                                object().shape({
                                    covercategory: string().required('E domosdoshme'),
                                    covergroup: string(),
                                    invoicedAmountV: number().required('E domosdoshme').typeError('Numri i kërkuar'),
                                    treating: string().required('E domosdoshme'),
                                })
                            )
                    })
                )
        }),
        actionLabel: 'Vazhdo',
        onAction: (sectionValues, formValues) => {
            //check if first is valid
            var validInvoice = sectionValues.invoices[0];
            var isValid = validate(validInvoice);

            if (isValid.errored) {
                store.dispatch(generalActions.showModal("Dështimi", isValid.message, ""));
                return;
            }
        }
    },
    {
        id: 'uploadDocuments',
        component: UploadHealthDocuments,
        name: 'Dokumente',
        description: '',
        initialValues: {
            claimFiles: [],
            accept_docs_terms: false
        },
        validationSchema: object().shape({
            accept_docs_terms: bool().oneOf([true], 'E domosdoshme')
        }),
        actionLabel: 'Regjistro'
    }
] as FormikWizardStepType[];
