import { combineReducers } from "redux";
import authReducer from "./authReducer";
import isLoadingReducer from "./isLoadingReducer";
import errorReducer from "./errorReducer";
import generalReducer from "./generalReducer";
import nomenReducer from "./nomenReducer";
import policyReducer from "./policyReducer";
import claimReducer from "./claimReducer";
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import healthClaimReducer from "./healthClaimReducer";
import healthPreClaimReducer from "./healthPreClaimReducer";

const rootReducer = (history: History) => combineReducers({
  // const rootReducer = combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  isLoading: isLoadingReducer,
  error: errorReducer,
  general: generalReducer,
  nomen: nomenReducer,
  policy: policyReducer,
  claim: claimReducer,
  healthClaim: healthClaimReducer,
  healthPreClaim: healthPreClaimReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;