import React, { useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Dispatch } from "redux";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import { AppState } from '../../../../redux/reducers/rootReducer';
import * as generalActionTypes from "../../../../redux/actionTypes/generalActionTypes";
import * as healthClaimActionTypes from "../../../../redux/actionTypes/healthClaimActionTypes";
import { hideModal } from "../../../../redux/actionCreators/generalActionCreators";
import { healthClaimPreviewReset, searchHealthClaim, exportSearchHealthClaim, healthClaimPreviewFirstLevelReset, healthClaimPreviewSecondLevelReset, readFirstLevelAppealReset, readSecondLevelAppealReset } from "../../../../redux/actionCreators/healthPreClaimActionCreators";
import SimpleModal from "../../../General/SimpleModal";
import { User } from '../../../../redux/types';
import DatePickerFieldMain from '../../../Custom/DatePickerFieldMain';
import HealthClaimModal from './HealthClaimModal';
import HealthClaimDataDisplay from './HealthClaimDataDisplay';
import { prepareHealthClaimFilter } from '../../../../utils/healthPreClaimTypes';
import FirstLevelAppealModal from './FirstLevelAppealModal';
import SecondLevelAppealModal from './SecondLevelAppealModal';
import ReadFirstLevelAppealModal from './ReadFirstLevelAppealModal';
import ReadSecondLevelAppealModal from './ReadSecondLevelAppealModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetCustomerAction } from '../../../../redux/actionTypes/authActionTypes';
import { getCustomer } from '../../../../redux/actionCreators/authActionCreators';

interface FormValues {
    pageSize: number;
    pageCount: number;
    dateAccidentFrom: Date | null;
    dateAccidentTo: Date | null;
    policyType: string;
    shownum: string;
}

const initialValues = {
    pageSize: 5,
    pageCount: 1,
    dateAccidentFrom: null,
    dateAccidentTo: null,
    shownum: ""
};

interface Props {
    onSearch(filter: any): void;
    closeModal(): void;
    resetSelectedHealthClaim(): void;
    onExport(filter: any): void;
    resetFirstLevelAppealHealthClaim(): void;
    resetSecondLevelAppealHealthClaim(): void;
    resetReadFirstLevelAppeal(): void;
    resetReadSecondLevelAppeal(): void;
    onGetCustomer(customerId: string): void;
}

const HealthClaimGrid = (props: FormikProps<FormValues> & Props) => {
    const dispatch = useDispatch();

    const showModal = useSelector<AppState, boolean>((state: any) => state.general.showModal);
    const header = useSelector<AppState, string>((state: any) => state.general.header);
    const text = useSelector<AppState, string>((state: any) => state.general.text);
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);

    const showHealthClaimModal = useSelector<AppState, boolean>((state: any) => state.healthClaim.showHealthClaimModal);
    const showHealthClaimFirstLevelModal = useSelector<AppState, boolean>((state: any) => state.healthClaim.showHealthClaimFirstLevelModal);
    const showHealthClaimSecondLevelModal = useSelector<AppState, boolean>((state: any) => state.healthClaim.showHealthClaimSecondLevelModal);
    const showReadFirstLevelAppealModal = useSelector<AppState, boolean>((state: any) => state.healthClaim.showReadFirstLevelAppealModal);
    const showReadSecondLevelAppealModal = useSelector<AppState, boolean>((state: any) => state.healthClaim.showReadSecondLevelAppealModal);

    const showSuccessToast = useSelector<AppState, boolean>((state: any) => state.general.showSuccessToast);
    const successToastText = useSelector<AppState, boolean>((state: any) => state.general.successToastText);

    const showFailureToast = useSelector<AppState, boolean>((state: any) => state.general.showFailureToast);
    const failureToastText = useSelector<AppState, boolean>((state: any) => state.general.failureToastText);

    if (!!showSuccessToast) {
        toast.success(successToastText);
        dispatch({ type: "generalActionTypes/SHOW_SUCCESS_TOAST_RESET" });
    }

    if (!!showFailureToast) {
        toast.error(failureToastText);
        dispatch({ type: "generalActionTypes/SHOW_FAILURE_TOAST_RESET" });
    }

    const HealthClaimGridSchema = Yup.object().shape({
        dateAccidentFrom: Yup.mixed(),
        dateAccidentTo: Yup.mixed(),
        shownum: Yup.string(),
        pageSize: Yup.number(),
        pageCount: Yup.number()
    });

    const handleSubmit = (values: any) => {
        const filter = prepareHealthClaimFilter(values, user!.customerId);
        props.onSearch(filter);
    };

    useEffect(() => {
        handleSubmit(initialValues);
    }, []);

    const handleExport = (values: any) => {
        const filter = prepareHealthClaimFilter(values, user!.customerId);
        props.onExport(filter);
    };

    const handleCloseModal = () => {
        props.closeModal();
    };

    const emptySelectedHealthClaim = () => {
        props.resetSelectedHealthClaim();
        if (user?.customerId) props.onGetCustomer(user?.customerId);
    };

    const emptyFirstLevelAppealHealthClaim = () => {
        props.resetFirstLevelAppealHealthClaim();
    };

    const emptySecondLevelAppealHealthClaim = () => {
        props.resetSecondLevelAppealHealthClaim();
    };

    const emptyReadFirstLevelAppeal = () => {
        props.resetReadFirstLevelAppeal();
    };

    const emptyReadSecondLevelAppeal = () => {
        props.resetReadSecondLevelAppeal();
    };

    return (
        <div className="card card-custom">
            <SimpleModal show={showModal} header={header} text={text} onClose={handleCloseModal} onHide={() => { return; }} />
            <HealthClaimModal show={showHealthClaimModal} header={header} text={text} mark={emptySelectedHealthClaim} onClose={emptySelectedHealthClaim} onHide={() => { return; }} />
            <FirstLevelAppealModal show={showHealthClaimFirstLevelModal} header={header} text={text} onClose={emptyFirstLevelAppealHealthClaim} onHide={() => { return; }} />
            <SecondLevelAppealModal show={showHealthClaimSecondLevelModal} header={header} text={text} onClose={emptySecondLevelAppealHealthClaim} onHide={() => { return; }} />
            <ReadFirstLevelAppealModal show={showReadFirstLevelAppealModal} header={header} text={text} onClose={emptyReadFirstLevelAppeal} onHide={() => { return; }} />
            <ReadSecondLevelAppealModal show={showReadSecondLevelAppealModal} header={header} text={text} onClose={emptyReadSecondLevelAppeal} onHide={() => { return; }} />

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                theme="colored"
            />

            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={HealthClaimGridSchema}
                onSubmit={handleSubmit}
            >
                {({ getFieldProps, handleSubmit, setFieldValue, values }) => (
                    <form onSubmit={(e: any) => {
                        setFieldValue('pageSize', 5);
                        setFieldValue('pageCount', 1);
                        handleSubmit(e);
                    }}>
                        <div className="card-header flex-wrap border-0 pt-6 pb-0 bottom-line">
                            <div className="card-title">
                                <h3 className="card-label">
                                    Statusi dhe historiati i kerkesave për rimbursim
                                    <span className="text-muted pt-2 font-size-sm d-block">
                                        Statusi dhe historiati i kerkesave për rimbursim
                                    </span>
                                </h3>
                            </div>
                        </div>
                        <div className="card-body ">
                            {/* <!--begin::Search Form--> */}
                            <div className="mb-7">
                                <div className="row align-items-center">
                                    <div className="col-md-4 my-2 my-md-0">
                                        <div className="d-flex align-items-center">
                                            <label className="mr-3 mb-0 d-none d-md-block">Numri i policës:</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Numri i policës"
                                                {...getFieldProps("shownum")}
                                                id="kt_datatable_search_query" />
                                        </div>
                                    </div>
                                    <div className="col-md-2 my-2 my-md-0">
                                        <DatePickerFieldMain name="dateAccidentFrom" type="text" hideHelper placeholder="Data e aksidentit prej" minDate={new Date(2021, 0, 1)} />
                                    </div>
                                    <div className="col-md-2 my-2 my-md-0">
                                        <DatePickerFieldMain name="dateAccidentTo" hideHelper type="text" placeholder="deri" minDate={new Date(2021, 0, 1)} />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-7  bottom-line">
                                <div className="row align-items-center">
                                    <div className="col-md-3 my-2 my-md-0">
                                        <button type="submit" className="btn btn-light-primary px-6 font-weight-bold">
                                            Kërko
                                        </button>
                                    </div>
                                    <div className="col-md-3 my-2 my-md-0">
                                        <button type="button" onClick={() => handleExport(values)} className="btn btn-light-primary px-6 font-weight-bold">
                                            Shtype raportin në Excel
                                        </button>
                                    </div>
                                </div>
                                <div className="empty-row "></div>
                            </div>
                            {/* </div> */}
                            {/* <!--end::Search Form--> */}

                            {/* <!--begin: Datatable--> */}
                            <HealthClaimDataDisplay onSubmit={handleSubmit} />
                            {/* <!--end: Datatable--> */}
                        </div>
                    </form >
                )}
            </Formik>
        </div >);
}

const mapDispatchToProps = (dispatch: Dispatch<healthClaimActionTypes.SearchHealthClaimAction
    | generalActionTypes.HideModalAction | healthClaimActionTypes.HealthClaimPreviewResetAction
    | healthClaimActionTypes.ExportSearchHealthClaimAction
    | healthClaimActionTypes.HealthClaimPreviewFirstLevelResetAction
    | healthClaimActionTypes.HealthClaimPreviewSecondLevelResetAction
    | healthClaimActionTypes.ReadFirstLevelAppealResetAction
    | healthClaimActionTypes.ReadSecondLevelAppealResetAction
    | healthClaimActionTypes.GetDocumentListResetAction
    | GetCustomerAction>) => ({
        onSearch: (filter: any) => {
            dispatch(searchHealthClaim(filter));
        },
        closeModal: () => { dispatch(hideModal()); },
        resetSelectedHealthClaim: () => { dispatch(healthClaimPreviewReset()); },
        resetFirstLevelAppealHealthClaim: () => { dispatch(healthClaimPreviewFirstLevelReset()); },
        resetSecondLevelAppealHealthClaim: () => { dispatch(healthClaimPreviewSecondLevelReset()); },
        resetReadFirstLevelAppeal: () => { dispatch(readFirstLevelAppealReset()); },
        resetReadSecondLevelAppeal: () => { dispatch(readSecondLevelAppealReset()); },
        onExport: (filter: any) => {
            dispatch(exportSearchHealthClaim(filter));
        },
        onGetCustomer: (customerId: string) => {
            dispatch(getCustomer(customerId));
        }
    });

export default connect(null, mapDispatchToProps)(HealthClaimGrid);