import { BookingData, KaskoPolicy, ThInsQuotation } from "../../utils/policyTypes";
import * as actions from "../actionTypes/kaskoPolicyActionTypes";

export function getKaskoQuotation(
    policy: KaskoPolicy
): actions.GetKaskoQuotationAction {
    return {
        type: actions.GET_KASKO_QUOTATION,
        policy
    };
}

export function getKaskoQuotationRequest(
): actions.GetKaskoQuotationRequestAction {
    return {
        type: actions.GET_KASKO_QUOTATION_REQUEST
    };
}

export function getKaskoQuotationReset(
): actions.GetKaskoQuotationResetAction {
    return {
        type: actions.GET_KASKO_QUOTATION_RESET
    };
}

export function getKaskoQuotationSuccess(
    values: ThInsQuotation
): actions.GetKaskoQuotationSuccessAction {
    return {
        type: actions.GET_KASKO_QUOTATION_SUCCESS,
        data: values
    };
}

export function getKaskoQuotationFailure(
    error: Error | string
): actions.GetKaskoQuotationFailureAction {
    return {
        type: actions.GET_KASKO_QUOTATION_FAILURE,
        error
    };
}

export function bookKaskoPolicy(
    policy: KaskoPolicy,
): actions.BookKaskoPolicyAction {
    return {
        type: actions.BOOK_KASKO_POLICY,
        policy
    };
}

export function bookKaskoPolicyRequest(
): actions.BookKaskoPolicyRequestAction {
    return {
        type: actions.BOOK_KASKO_POLICY_REQUEST
    };
}

export function bookKaskoPolicySuccess(
    values: BookingData
): actions.BookKaskoPolicySuccessAction {
    return {
        type: actions.BOOK_KASKO_POLICY_SUCCESS,
        data: values
    };
}

export function bookKaskoPolicyFailure(
    error: Error | string
): actions.BookKaskoPolicyFailureAction {
    return {
        type: actions.BOOK_KASKO_POLICY_FAILURE,
        error
    };
}