import { Nomen } from '.';
import { Question } from './policyTypes';

export const Type_vehicle: Nomen[] = [
    { code: '2', name: 'AUTOVETURE' }
]

export const Mark: Nomen[] = [
    { code: '1', name: ' mark 1' }
]

export const CategoryClassTypeVehicle: Nomen[] = [
    { code: '1', name: 'class type 1' }
]

export const Gender: Nomen[] = [
    { code: '1', name: 'M' },
    { code: '2', name: 'F' }
]

export const Pep: Nomen[] = [
    { code: '1', name: 'Po' },
    { code: '2', name: 'Jo' }
]

export const Payment: Nomen[] = [
    { code: '1', name: 'BKT' },
    { code: '2', name: 'PayPal' }
]

export const PolicyTypes: Nomen[] = [
    { code: '01', name: 'TPL' },
    { code: '02', name: 'KJ' },
    { code: '03', name: 'Kufitare' },
    { code: '04', name: 'Kasko' }
]

export const TplPolicyTypes: Nomen[] = [
    { code: '01', name: 'TPL' },
    { code: '62', name: 'TPL Plus' }
]

export const ClaimHistoryPolicyTypes: Nomen[] = [
    { code: '01', name: 'TPL' },
    { code: '02', name: 'Karton Jeshil' },
    { code: '03', name: 'Sigurimi Kufitar' }
]

export const SearchPolicyTypes: Nomen[] = [
    { code: '01', name: 'TPL' },
    { code: '02', name: 'KJ' },
    { code: '03', name: 'Kufitare' },
    { code: '05', name: 'SHU' },
    { code: '34', name: 'Sigurimi i shëndetit' },
    { code: '09', name: 'Sigurimi i pasurisë' },
    { code: '61', name: 'Lejeqëndrim' },
    { code: '04', name: 'Kasko' },
    { code: '55', name: 'Sigurimi i Jetës së Kredimarrësit' },
    { code: '34-01', name: 'Sigurimi i Jetës' }
]

export const HealthPolicyTypes: Nomen[] = [
    { code: '0501', name: 'Shëndetit në Udhëtim' },
    { code: '08', name: 'Aksidente Personale' }
]

export const AllPolicyTypes: Nomen[] = [
    { code: '01', name: 'TPL' },
    { code: '02', name: 'Green card' },
    { code: '03', name: 'Border' },
    { code: '04', name: 'Kasko' },
    { code: '05', name: 'SHU' },
    { code: '34', name: 'Shëndetësor' },
    { code: '06', name: 'Sigurim Oferte' },
    { code: '07', name: 'Sigurim i Kontrates' },
    { code: '08', name: 'Aksidente Personale' },
    { code: '09', name: 'Zjarri dhe Reziqe Shtese' },
    { code: '10', name: 'Prona per efekte kredie' },
    { code: '11', name: 'Sigurimit të Mallit në Transport' },
    { code: '12', name: 'Pergjegjesia' },
    { code: '13', name: 'Sigurimit i parave ne kasaforte dhe tranzit' },
    { code: '16', name: 'Sigurimit për Shtëpi / Banesa' },
    { code: '17', name: 'CAR' },
    { code: '20', name: 'Sigurimi Parave (BBB & ECCP)' },
    { code: '30', name: 'Sigurimit të Pronës -Të Gjitha Rreziqet' }
]

export const PropertyPolicyTypes: Nomen[] = [
    { code: '06', name: 'Sigurim Oferte' },
    { code: '07', name: 'Sigurim i Kontrates' },
    { code: '08', name: 'Aksidente Personale' },
    { code: '09', name: 'Zjarri dhe Reziqe Shtese' },
    { code: '10', name: 'Prona per efekte kredie' },
    { code: '11', name: 'Sigurimit të Mallit në Transport' },
    { code: '12', name: 'Pergjegjesia' },
    { code: '13', name: 'Sigurimit i parave ne kasaforte dhe tranzit' },
    { code: '16', name: 'Sigurimit për Shtëpi / Banesa' },
    { code: '17', name: 'CAR' },
    { code: '20', name: 'Sigurimi Parave (BBB & ECCP)' },
    { code: '30', name: 'Sigurimit të Pronës -Të Gjitha Rreziqet' }
]

export const RoleTypes: Nomen[] = [
    { code: '1', name: 'Perfituesi' },
    { code: '2', name: 'Shkaktari' }
]

export const TypeObjects: Nomen[] = [
    { code: '122', name: 'Banesë' },
    { code: '123', name: 'Shtëpi' },
    { code: '087', name: 'Dyqane/Lokale' },
]

export const Questions: Question[] = [
    { listing: '1', text: 'Person politikisht i ekspozuar – nënkupton personat fizik vendor ose të huaj të cilat janë ose u janë besuar funksione të rëndësishme publike;', value: '', type: 1, selected: false },
    { listing: '2', text: 'Anëtarë të ngushtë të familjes – nënkupton prindërit, prindër adoptues, bashkëshort, fëmijë, fëmijë adoptues, motrat dhe vëllezërit  e personave të ekspozuar politikisht;', value: '', type: 1, selected: false },
    { listing: '3', text: 'Bashkëpunëtor të ngushtë – nënkupton ato biznese ose partner ose bashkëpunëtor të cilët e ndajnë pronësinë e entiteteve juridike me personin e ekspozuar politikisht, duke përfshirë anëtarët e bordit, pasi që së bashku ushtrojnë kontroll efektive mbi kompaninë. ', value: '', type: 1, selected: false },
    { listing: '', text: 'Shtetit të Huaj', value: '', type: 0, selected: false },
    { listing: 'a', text: 'Udhëheqës i shtetit ose qeverisë', value: '', type: 2, selected: false },
    { listing: 'b', text: 'Politikan i lartë (deputet i parlamentit, ministër, zëvendës ministër, apo pozitë ekuivalente)', value: '', type: 2, selected: false },
    { listing: 'c', text: 'Zyrtarë i lartë qeveritar, gjyqësor ose ushtarak (gjykatës apo anëtar i ndonjë institucioni gjyqësor të lartë ndaj vendimeve të cilit nuk mund të ushtrohet ankesë përveç në rrethana të jashtëzakonshme, anëtar i gjykatës së auditorëve, komitetit të auditorëve apo bordit të bankës qendrore, ambasador apo i ngarkuar me punë apo oficer i rangut të lartë ushtarak)', value: '', type: 2, selected: false },
    { listing: 'd', text: 'Drejtues i lartë i korporatave në pronësi të shtetit (anëtar i administratës, bordit apo menaxher i korporateve dhe ndërmarrjeve në pronësi të shtetit)', value: '', type: 2, selected: false },
    {
        listing: 'e', text: `Person politikisht i rëndësishëm në organizatë ndërkombëtare
    (personat të cilët kanë qenë ose u janë besuar funksione të rëndësishme në organizatat ndërkombëtare dhe kjo i referohet anëtarëve të menaxhmentit të lartë apo personave të cilëve u janë besuar funksione ekuivalente si p.sh drejtorët, zëvendës drejtorët dhe anëtarët e bordit ose pozitat ekuivalente  në Kosovë)
    Organizatat ndërkombëtare përfshijnë por pa u kufizuar si në vijim:
    •	Kombet e Bashkuara dhe organizatat e ndërlidhura me të 
    •	Organizata e Shteteve të Bashkuara të Amerikës
    •	Organizatat apo misionet e ndërlidhure me BE-në
    •	Organizata Ndërkombëtare e Punës
    •	Fondi Monetar Ndërkombëtar
    •	Banka Botërore
    `, value: '', type: 2, selected: false
    },
    { listing: '', text: 'Shtetit të Republikës së Kosovës', value: '', type: 0, selected: false },
    { listing: '1', text: 'President i Republikës së Kosovës, anëtarët i kabinetit të Presidentit, Sekretar si dhe Drejtorë i Departamenteve Profesionale në Zyrën e Presidentit të Republikës së Kosovës', value: '', type: 2, selected: false },
    { listing: '2', text: 'Deputet i Kuvendit të Kosovës, personi zgjedhur apo emëruar nga Kuvendi, Kryesia, Kryetari i Kuvendit si dhe Kabineti i Kryetarit të Kuvendit të Republikës së Kosovës', value: '', type: 2, selected: false },
    { listing: '3', text: 'Kryeministër, Zëvendës Kryeministër, Ministër, Zëvendës Ministër, Këshilltarë politik, Shef i kabinetit apo i emëruar nga ta;', value: '', type: 2, selected: false },
    { listing: '4', text: 'Sekretar i Përgjithshëm i Qeverisë, Kryeshef i Agjencive të themeluara me ligj apo me ndonjë akt tjetër, Drejtor dhe Zëvendës Drejtor, Drejtor regjional i Administratës Tatimore të Kosovës, Drejtor i përgjithshëm si dhe Drejtor i Departamenteve të Doganës;', value: '', type: 2, selected: false },
    { listing: '5', text: 'Auditorë në Zyrën e Auditorit Gjeneral apo auditorë i brendshëm në institucione;', value: '', type: 2, selected: false },
    { listing: '6', text: 'Anëtarë i Bordeve të Ndërmarrjeve Publike, anëtarë i Bordeve të Rregullatorëve, të Komisioneve apo Agjencive tjera të themeluara me ligj apo me ndonjë akt tjetër;', value: '', type: 2, selected: false },
    { listing: '7', text: 'Anëtarë i Bordit, Drejtor dhe Zëvendës Drejtori i Bankës Qendrore;', value: '', type: 2, selected: false },
    { listing: '8', text: 'Kryetarë i Komunës dhe Zëvendës Kryetarë, Kryesues, Zëvendës Kryesues, Këshilltarët i Asambleve Komunale si dhe Drejtorë i Drejtorive Komunale;', value: '', type: 2, selected: false },
    { listing: '9', text: 'Anëtarë i Këshillit Gjyqësor dhe Këshillit Prokurorial, Drejtor i Sekretariatit të Këshillit Gjyqësor, Drejtor i Sekretariatit të Këshillit Prokurorial, Auditor Gjyqësor, Prokuror Disiplinor;', value: '', type: 2, selected: false },
    { listing: '10', text: 'Gjyqtarë dhe Prokurorë, Gjyqtarë i Gjykatës Kushtetuese dhe Sekretari i Gjykatës Kushtetuese;', value: '', type: 2, selected: false },
    { listing: '11', text: 'Drejtor i Departamentit, Udhëheqës i Financave dhe Prokurimit Publik në institucionet publike;', value: '', type: 2, selected: false },
    { listing: '12', text: 'Ambasadorë, Konsull, Zëvendës Konsull, Sekretarë i Ambasadave apo Konsullatave të Republikës se Kosovës;', value: '', type: 2, selected: false },
    { listing: '13', text: 'Rektor dhe Prorektor i Universitetit Publik, Dekanë dhe Prodekanë, si dhe Sekretar i Universitetit Publik dhe Njësive Akademike;', value: '', type: 2, selected: false },
    { listing: '14', text: 'Drejtor i Përgjithshëm, Zëvendës Drejtor dhe Drejtor rajonal i Policisë së Kosovës, Kryeshef i Inspektoratit Policor të Kosovës;', value: '', type: 2, selected: false },
    { listing: '15', text: 'Komandant, Zëvendës Komandant i Forcës së Sigurisë së Kosovës;', value: '', type: 2, selected: false },
    { listing: '16', text: 'Drejtor, Zëvendës Drejtor si dhe Inspektor i Përgjithshëm i Agjencisë Kosovare të Inteligjencës;', value: '', type: 2, selected: false },
    { listing: '17', text: 'Avokat i Popullit dhe Zëvendës i tij; ', value: '', type: 2, selected: false },
    { listing: '18', text: 'Kryeinspektorë i Inspektorive të nivelit qendror dhe lokal. ', value: '', type: 2, selected: false },
    { listing: '19', text: 'Drejtori i Njësisë për Inteligjencë Financiare të Kosovës ', value: '', type: 2, selected: false },
    { listing: '20', text: 'Zyrtarë i rëndësishëm në parti politike (Kryetari dhe anëtarët e organit më të lartë ekzekutiv të partisë politike siç përkufizohet me legjislacionin në fuqi në Kosovë për regjistrimin dhe veprimtarinë e partive politike)', value: '', type: 2, selected: false },
    {
        listing: 'a.', text: `Anëtarë i ngushtë i familjes së personit të renditur në listën më sipër?
    •	Prind / prind adoptues
    •	Bashkëshort/e
    •	Fëmijë / fëmijë i adoptuar
    •	Vëlla / Motër
    `, value: '', type: 2, selected: false
    },
    { listing: 'b.', text: 'Bashkëpunëtor i ngushtë i ndonjë personi të renditur në listën më sipër? ', value: '', type: 3, selected: false },
]

export const CreditLifeTypes: Nomen[] = [
    { code: '01', name: 'Personale' },
    { code: '02', name: 'Biznesi' }
]

export const IncomeSources: Nomen[] = [
    { code: 'Page', name: 'Page' },
    { code: 'Te hyra nga biznesi', name: 'Te hyra nga biznesi' },
    { code: 'Te tjera specifiko', name: 'Te tjera specifiko' }
]