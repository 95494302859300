
import * as actions from "../actionTypes/nomenActionTypes";
import { Nomen } from './../../utils/generalTypes';

export interface GeneralState {
    TPLVehicleCategoryClassTypes: Nomen[];
    GCVehicleCategoryClassTypes: Nomen[];
    BVehicleCategoryClassTypes: Nomen[];
    VehicleMarks: Nomen[];
    THZones: Nomen[];
    GcPeriods: Nomen[];
    BPeriods: Nomen[];
    Statuses: Nomen[];
    DeliveryLocations: Nomen[];
    Places: Nomen[];
    Natures: Nomen[];
    HealthNatures: Nomen[];
    PropertyNatures: Nomen[];
    TypeVehicle: Nomen[];
    Banks: Nomen[];
    BanksLife: Nomen[];
    Countries: Nomen[];
    CoverCategories: Nomen[];
    CoverGroups: Nomen[];
    Covers: Nomen[];
}

const initialState: GeneralState = {
    TPLVehicleCategoryClassTypes: [],
    GCVehicleCategoryClassTypes: [],
    BVehicleCategoryClassTypes: [],
    VehicleMarks: [],
    THZones: [],
    GcPeriods: [],
    BPeriods: [],
    Statuses: [],
    DeliveryLocations: [],
    Natures: [],
    HealthNatures: [],
    PropertyNatures: [],
    Places: [],
    TypeVehicle: [],
    Banks: [],
    BanksLife: [],
    Countries: [],
    CoverCategories: [],
    CoverGroups: [],
    Covers: []
};

export default function nomenReducer(
    state: GeneralState = initialState,
    action: actions.NomenAction
): GeneralState {
    switch (action.type) {
        case actions.GET_TPL_VCCT_SUCCESS:
            return {
                ...state,
                TPLVehicleCategoryClassTypes: action.data
            };
        case actions.GET_GC_VCCT_SUCCESS:
            return {
                ...state,
                GCVehicleCategoryClassTypes: action.data
            };
        case actions.GET_B_VCCT_SUCCESS:
            return {
                ...state,
                BVehicleCategoryClassTypes: action.data
            };
        case actions.GET_VEHICLE_MARK_SUCCESS:
            return {
                ...state,
                VehicleMarks: action.data
            };
        case actions.GET_TH_ZONE_SUCCESS:
            return {
                ...state,
                THZones: action.data
            };
        case actions.GET_GC_PERIOD_SUCCESS:
            return {
                ...state,
                GcPeriods: action.data
            };
        case actions.GET_B_PERIOD_SUCCESS:
            return {
                ...state,
                BPeriods: action.data
            };
        case actions.GET_POLICY_STATUS_SUCCESS:
            return {
                ...state,
                Statuses: action.data
            };
        case actions.GET_DELIVERY_BRANCH_LOCATIONS_SUCCESS:
            return {
                ...state,
                DeliveryLocations: action.data
            };
        case actions.GET_PLACES_SUCCESS:
            return {
                ...state,
                Places: action.data
            };
        case actions.GET_NATURES_SUCCESS:
            return {
                ...state,
                Natures: action.data
            };
        case actions.GET_TYPE_VEHICLE_SUCCESS:
            return {
                ...state,
                TypeVehicle: action.data
            };
        case actions.RESET_NOMEN:
            return {
                TPLVehicleCategoryClassTypes: [],
                GCVehicleCategoryClassTypes: [],
                BVehicleCategoryClassTypes: [],
                VehicleMarks: [],
                THZones: [],
                GcPeriods: [],
                BPeriods: [],
                Statuses: [],
                DeliveryLocations: [],
                Natures: [],
                HealthNatures: [],
                PropertyNatures: [],
                Places: [],
                TypeVehicle: [],
                Banks: [],
                BanksLife: [],
                Countries: [],
                CoverCategories: [],
                CoverGroups: [],
                Covers: []
            };
        case actions.GET_HEALTH_NATURES_SUCCESS:
            return {
                ...state,
                HealthNatures: action.data
            };
        case actions.GET_PROPERTY_NATURES_SUCCESS:
            return {
                ...state,
                PropertyNatures: action.data
            };
        case actions.GET_BANKS_SUCCESS:
            return {
                ...state,
                Banks: action.data
            };
        case actions.GET_BANKS_LIFE_SUCCESS:
            return {
                ...state,
                BanksLife: action.data
            };
        case actions.GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                Countries: action.data
            };
        case actions.GET_COVER_CATEGORIES_FOR_POLICY_SUCCESS:
            return {
                ...state,
                CoverCategories: action.data
            };
        case actions.GET_COVER_GROUPS_FOR_POLICY_SUCCESS:
            return {
                ...state,
                CoverGroups: action.data
            };
        case actions.GET_COVERS_FOR_POLICY_SUCCESS:
            return {
                ...state,
                Covers: action.data
            };
        default:
            return state;
    }
}