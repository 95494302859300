/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { BreadCrumbs } from "./components/BreadCrumbs";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout"

const headerLinks = {
  "/preclaim-search": "Kërko kërkesën për dëm",
  "/claim-search": "Kërko të gjitha dëmet",
  "/health-claim-search": "Statusi dhe historiati i kerkesave për rimbursim",
  "/register-health-preclaim-ks": "Kërko kërkesën për dëm",
  "/dashboard": "Dashboard",
  "/update-profile": "Përditëso profilin",
  "/change-password": "Ndrysho fjalëkalimin",
  "/preclaim-health/success": "Kërko kërkesën për dëm",
  "/kasko-policy": "Kasko",
  "/property-policy": "Sigurimi i pasurisë",
  "/travel-health-policy": "Sigurimi i shëndetit në udhëtim",
  "/private-health-policy": "Sigurimi i shëndetit",
  "/foreigner-policy": "Leje qëndrimi",
  "/credit-life-policy": "Sigurimet e Jetës së Kredimarrësit",
  "/term-life-policy": "Sigurimi i Jetës"
}

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      )
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs = (aside && aside.breadcrumbs.length > 0) ? aside.breadcrumbs : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(headerLinks[location.pathname] || "Main");
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => { }, [subheader]);

  return (
    //<div className="subheader py-2 py-lg-6  subheader-transparent " id="kt_subheader">
      //<div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        //<div className="d-flex align-items-center flex-wrap mr-2">

          //<h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
          //</h5>
          //<BreadCrumbs items={subheader.breadcrumbs} />
          <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
        //</div>

      //</div>
    //</div>

    // <div
    //     id="kt_subheader"
    //     className={`subheader py-2 py-lg-6  subheader-transparent ${layoutProps.subheaderCssClasses}`}
    // >
    //   <div
    //       className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
    //   >
    //     {/* Info */}
    //     <div className="d-flex align-items-center flex-wrap mr-2">
    //       {layoutProps.subheaderMobileToggle && (
    //           <button
    //               className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
    //               id="kt_subheader_mobile_toggle"
    //           >
    //             <span/>
    //           </button>
    //       )}

    //         <h5 className="text-dark font-weight-bold my-2 mr-5">
    //           <>
    //             {subheader.title}
    //           </>
    //           {/*<small></small>*/}
    //         </h5>
    //       <BreadCrumbs items={subheader.breadcrumbs} />
    //     </div>
    //   </div>
    // </div>
  );
}
