import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import InputFieldMain from '../../Custom/InputFieldMain';
import InputAreaFieldMain from '../../Custom/InputAreaFieldMain';
import TimeInput from '../../Custom/TimeInput';
import SelectField from '../../Custom/SelectField';
import DatePickerFieldMain from '../../Custom/DatePickerFieldMain';
import { AppState } from '../../../redux/reducers/rootReducer';
import { Nomen } from '../../../utils';
import { TplPolicyTypes } from '../../../utils/nomen';
import * as claimActionTypes from '../../../redux/actionTypes/claimActionTypes';
import { Loading } from '../../Layout/Loading';

const PreClaimMain = () => {
    const natures = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Natures);
    const places = useSelector<AppState, Nomen[]>((state: any) => state.nomen.Places);
    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[claimActionTypes.FETCH_VEHICLE_FROM_POLICY]);

    return (<>
        {isLoading
            ? <Loading />
            :
            <>
                <div className="wizardTitle">Detajet e Dëmtimit / Shërbimit</div>
                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <div className="mb-10 font-weight-bold text-dark"></div>
                    <div className="row">
                        <div className="col-xl-6">
                            <SelectField name="type_policy" label="Tipi i policës" items={TplPolicyTypes} />
                        </div>
                        <div className="col-xl-6">
                            <SelectField name="nature" label="Natyra e dëmit" items={natures} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="policy_code" label="Numri i policës" placeholder="Numri i policës" />
                        </div>
                        <div className="col-xl-6 addSpace">
                            <InputAreaFieldMain type="text" name="description_damage" label="Përshkrimi i dëmit" placeholder="Përshkrimi i dëmit" />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 addSpace">
                            <DatePickerFieldMain name="date_accident" type="text" placeholder="Data e aksidentit" />
                        </div>
                        <div className="col-xl-6 ">
                            <InputAreaFieldMain type="text" name="description_accident" label="Përshkrimi i aksidentit" placeholder="Përshkrimi i aksidentit" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <SelectField name="place_accident" label="Vendi i ngjarjes" items={places} />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="europeanreportnr" label="Nr. i raportit europian" placeholder="Nr. i raportit europian" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            {/* time */}
                            <TimeInput type="text" name="hour_accident" label="Ora e aksidentit" />
                        </div>
                        <div className="col-xl-6">
                            <InputFieldMain type="text" name="policyreportnr" label="Nr. i raportit të Policisë" placeholder="Nr. i raportit të Policisë" />
                        </div>
                    </div>
                </div>
            </>}
    </>

    );
}
export default connect()(PreClaimMain);
