import React, { useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { AppState } from '../../../../redux/reducers/rootReducer';
import Accordion from '../../../Custom/Accordion';
import Traveller from './Traveller';
import * as nomenActionTypes from '../../../../redux/actionTypes/nomenActionTypes';
import { Loading } from "../../../Layout/Loading";
import { prepareThPolicyForQuotation, ThInsQuotation, validateTraveller } from "../../../../utils/policyTypes";
import { useFormikWizard } from 'formik-wizard';
import store from "../../../../redux/configureStore";
import * as thActions from "../../../../redux/actionCreators/thPolicyActionCreators";
import { User } from "../../../../redux/types";
import moment from "moment";
import * as authActions from "../../../../redux/actionCreators/authActionCreators";

const Travellers = () => {
    const { values, setValues }: any = useFormikContext();
    const { values: wizardValues }: any = useFormikWizard();
    const [key, setKey] = useState(0);

    const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[nomenActionTypes.GET_PLACES]);
    const chosenTHQuotation = useSelector<AppState, ThInsQuotation[] | null>((state: any) => state.policy.chosenTHQuotation);
    const thPackageQuotation = chosenTHQuotation ? chosenTHQuotation[0] : null;
    const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
    const [showDocsPreview, setShowDocsPreview] = useState(true);

    const dispatch = useDispatch();

    const manageTravellers = () => {
        setKey(key + 1);
        setShowDocsPreview(false);

        const validity: any = validateTraveller(values.travellers[0], values.travellers);
        if (validity.errored) {
            dispatch({
                type: "generalActionTypes/SHOW_MODAL",
                header: "Dështimi",
                text: validity.message,
                next: ""
            });
            return;
        }

        var currentValues = { ...values };
        currentValues.travellers.unshift(blankTraveller);
        setValues(currentValues);
        setShowDocsPreview(true);

        const customerId = user?.customerId;

        if (!customerId) {
            store.dispatch(authActions.hasProfile(currentValues.travellers[currentValues.travellers.length - 1].email));
        }
    }

    const removeTraveller = (index: any) => {
        setKey(key - 1);
        setShowDocsPreview(false);
        var currentValues = { ...values };

        const isFirstTraveller = index === currentValues.travellers.length - 1;

        currentValues.travellers.splice(index, 1);
        setValues(currentValues);
        setShowDocsPreview(true);

        wizardValues.thMain.insuredPersonsList = values.travellers;

        if (currentValues && currentValues.travellers
            && currentValues.travellers.length > 0 && currentValues.travellers
                .every((traveller: any) => traveller.birthDate != null
                    && traveller.birthDate != undefined && traveller.birthDate != ""
                    && moment(traveller.birthDate, 'dd/MM/yyyy').isValid()
                    && moment(traveller.birthDate, 'dd/MM/yyyy').isBefore(moment())
                    && moment().diff(traveller.birthDate, 'years') <= 64)) {

            const thPolicy = prepareThPolicyForQuotation(user?.customerId as any, wizardValues.thMain, thPackageQuotation ? thPackageQuotation.packageId : '');
            store.dispatch(thActions.getPackageTHQuotation(thPolicy));
        }


        if (isFirstTraveller) {
            const customerId = user?.customerId;

            if (!customerId) {
                store.dispatch(authActions.hasProfile(currentValues.travellers[currentValues.travellers.length - 1].email));
            }
        }
    }

    const blankTraveller = {
        clientNo: '',
        title: '',
        lastName: '',
        sex: '',
        address: '',
        place: '',
        email: '',
        celphone: '+383',
        birthDate: '',
        passport: '',
        passportPhoto: []
    }


    return (<>
        {isLoading
            ? <Loading />
            : <>
                <div className="wizardTitle">Udhëtarët</div>
                <div>
                    <div className="row">
                        <div className="col-xl-9"></div>
                        <div className="col-xl-3"><button type="button" onClick={manageTravellers} className="btn btn-wiz right-btn" data-wizard-type="action-submit">
                            +  Shto udhëtar
                        </button>
                        </div>
                    </div>
                    <div className="accordion mt5">
                        <Accordion title={"Udhëtar 1"} subtitle={''} active={true} content={
                            <Traveller key={0} data={values.travellers[0]} dropkey={key} index={0} enableDelete={values.travellers.length >= 2} removeTraveller={removeTraveller} />
                        } />
                    </div>
                    <div className="accordion">
                        {values.travellers.slice(1).map((traveller: any, index: number) => (
                            <Accordion title={"Udhëtar " + (index + 2)} subtitle={''} active={false} content={
                                <Traveller key={index + 1} index={index + 1} dropkey={key} data={traveller} enableDelete={values.travellers.length > 1} removeTraveller={removeTraveller} />
                            } />
                        ))}
                    </div>
                </div>
            </>
        }
    </>
    );
}
export default connect()(Travellers);
