import React, { FunctionComponent } from 'react';
import { useField } from 'formik';
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";

interface Props {
    name: string;
    type: string;
    placeholder: string;
    label: string;
    items: { id: string, value: string }[]
}

const RadioButtonGroupField: FunctionComponent<Props> = (props) => {
    const [
        field,
        { error, touched },
    ] = useField({
        name: props.name,
        type: props.name,
    });

    return (
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center fv-plugins-icon-container">
            <RadioGroup
                className="form-control form-control-solid h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                aria-label={props.label}
                {...props}
                {...field}
                row
            >
                {props.items.map((item) =>
                    <FormControlLabel
                        key={item.value}
                        control={<Radio />}
                        label={item.value}
                        value={item.id}
                    />
                )}
            </RadioGroup>
            {touched && error !== "" ? (
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{error}</div>
                </div>
            ) : null}
        </div>
    );
};

export default RadioButtonGroupField;