import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RegisterUserAction } from "../../../redux/actionTypes/authActionTypes";
import { HideModalAction } from "../../../redux/actionTypes/generalActionTypes";
import { logoutUser, registerUser } from "../../../redux/actionCreators/authActionCreators";
import { hideModal } from "../../../redux/actionCreators/generalActionCreators";
import moment from 'moment';
import { AppState } from "../../../redux/reducers/rootReducer";
import * as actionTypes from "../../../redux/actionTypes/authActionTypes";
import steps from './steps';
import FormikWizard from 'formik-wizard';
import { FormikWizardWrapperProps } from "../../../utils";
import SimpleModal from '../../General/SimpleModal';
import { User } from "../../../redux/types";

interface FormValues {
  email: string;
  password: string;
}

interface Props {
  onRegister(tipKind: string,
    clientno: string,
    nipt: string,
    title: string,
    lastname: string,
    sex: string,
    email: string,
    mob: string,
    address: string,
    place: string,
    birthdate: string | null,
    password: string): void;
  closeModal(): void;
  logout(token: string): void;
}

function FormWrapper({
  children,
  isLastStep,
  status,
  goToPreviousStep,
  canGoBack,
  actionLabel
}: FormikWizardWrapperProps<any, any>) {
  const isLoading = useSelector<AppState, boolean>((state: any) => state.isLoading[actionTypes.REGISTER_USER]);
 

  return (<>
    {children}
    <div className="d-flex justify-content-between pt-7">
      <div className="mr-2">
        {canGoBack && <button type="button" onClick={goToPreviousStep} className="btn btn-light-primary font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3 mr-3">
          <span className="svg-icon svg-icon-md mr-2"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24" />
              <rect fill="#000000" opacity="0.3" transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000) " x="14" y="7" width="2" height="10" rx="1" />
              <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) " />
            </g>
          </svg></span>
          Kthehu
        </button>}
      </div>
      <div>
        {isLastStep && <button className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3" disabled={isLoading} type="submit" id="kt_login_signup_form_submit_button" style={{ width: '150px' }}>
          <span>Regjistro</span>
          {isLoading
            ? <span className="ml-3 spinner spinner-white"></span>
            : <span className="svg-icon svg-icon-md ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24" />
                  <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000) " x="7.5" y="7.5" width="2" height="9" rx="1" />
                  <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) " />
                </g>
              </svg></span>}
        </button>}

        {!isLastStep && <button type="submit" className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3">
          {actionLabel || 'Next'}
          <span className="svg-icon svg-icon-md ml-2">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000) " x="7.5" y="7.5" width="2" height="9" rx="1" />
                <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) " />
              </g>
            </svg></span>
        </button>}
      </div>
    </div>
  </>);
}

const Registration = (props: Props) => {

  const error = useSelector<AppState, Error | string | null>((state: any) => state.error[actionTypes.REGISTER_USER]);
  const showModal = useSelector<AppState, boolean>((state: any) => state.general.showModal);
  const header = useSelector<AppState, string>((state: any) => state.general.header);
  const text = useSelector<AppState, string>((state: any) => state.general.text);
  const user = useSelector<AppState, User | null>((state: any) => state.auth.user);
  let history = useHistory();

  const handleSubmit = React.useCallback((values) => {
    props.onRegister(values.account.tipKind, values.account.clientno, values.account.nipt, values.account.title,
      values.account.lastName, values.account.sex, values.contact.email, values.contact.mob,
      values.contact.address, values.contact.place, values.account.birthdate !== "" ? moment(values.account.birthdate).format('YYYY-MM-DD') : null, values.account.password);
  }, []);

  const handleCloseModal = () => {
    props.closeModal();
    history.push('/auth/login')
  };

  useEffect(() => {
    if (!!user) props.logout(user!.token);
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid" id="kt_login">
          <>
            <div className="login-container d-flex flex-center flex-row flex-row-fluid order-2 order-lg-1 flex-row-fluid bg-white py-lg-0 pb-lg-0 pt-15 pb-12">
              <SimpleModal show={showModal} header={header} text={text} onClose={handleCloseModal} onHide={() => { return; }} />
              <div className="login-content login-content-signup d-flex flex-column">
                {error && <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">{error}</div>
                </div>}
                <FormikWizard steps={steps} onSubmit={handleSubmit} render={FormWrapper} />
              </div >
            </div >
          </>
          {/*begin::Aside*/}
          <div className="login-aside order-1 order-lg-2 bgi-no-repeat bgi-position-x-right">
            <div className="login-conteiner bgi-no-repeat bgi-position-x-right bgi-position-y-bottom">
              {/*begin::Aside title*/}
              <h3 className="pt-lg-40 pl-lg-20 pb-lg-0 pl-10 py-20 m-0 d-flex justify-content-lg-start font-weight-boldest display5 display1-lg text-white">
                Ju krijoni familje<br />
                Ne e sigurojmë atë
              </h3>
              {/*end::Aside title*/}
            </div>
          </div>
        </div>
        {/*end::Aside*/}
      </div>
    </>
  );
}


const mapDispatchToProps = (dispatch: Dispatch<RegisterUserAction | HideModalAction | actionTypes.LogoutUserAction>) => ({
  onRegister: (tipKind: string,
    clientno: string,
    nipt: string,
    title: string,
    lastname: string,
    sex: string,
    email: string,
    mob: string,
    address: string,
    place: string,
    birthdate: string,
    password: string) => {
    dispatch(registerUser(tipKind, clientno, nipt, title, lastname, sex, email, mob, address, place, birthdate, password));
  },
  logout: (token: string) => { dispatch(logoutUser(token)); },
  closeModal: () => { dispatch(hideModal()); }
});


export default connect(null, mapDispatchToProps)(Registration);
